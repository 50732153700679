import { Fragment, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, useTheme } from '@material-ui/core';

import { PaperLayout, RouteLayout } from 'shared/components/layouts';
import { Button, Modal, Typography } from 'shared/components/ui';
import { USER_SORT_INFO, FIELDS_CONFIG, SELECT_OPTION_ALL } from 'shared/constants';
import { SearchControl, SearchProvider } from 'shared/features/search';
import { useCurrentUser, usePageTitle, useResponsive } from 'shared/hooks';

import { CareerFilter, CareerServicesContent, SavedJobs, CreateJobPostModal } from '../components';
import { CAREERS_SORT_INFO } from '../constants';
import { SearchCareerFilter } from '../types';

const leftSideBoxCSS = css`
  @media (min-width: 800px) {
    width: 100%;
  }
`;

const actionsContainerCSS = (theme: any) =>
  css`
    padding: ${theme.spacing(3.125)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1)}px;
    }
  `;

const careerServicesCss = (isMobile: boolean) => (theme: any) =>
  css`
    ${isMobile && `font-size: ${theme.typography.fontSize + 6}px;`}
    font-weight: 400;
  `;

export function CareerServicesPages() {
  const [isJobPostModalOpen, setJobPostModalOpen] = useState(false);

  const [selectedFilters, setSelectedUserFilters] = useState<SearchCareerFilter>({
    type: 'Jobs',
    radius: '-',
  });

  const theme = useTheme();
  const title = usePageTitle({ fallback: 'Career Services' });

  const { isMobile } = useResponsive();
  const { isAdmin, isBusinessAdminOrManager } = useCurrentUser();

  const isFilterTypeAll = useMemo(() => {
    return selectedFilters.type === SELECT_OPTION_ALL;
  }, [selectedFilters]);

  const searchControlProps = useMemo(() => {
    if (selectedFilters.type === FIELDS_CONFIG.type.jobs) {
      return {
        placeholder: 'Search Careers',
        sortInfo: CAREERS_SORT_INFO,
        fullWidth: true,
      };
    }

    if (selectedFilters.type === FIELDS_CONFIG.type.jobSeekers) {
      return {
        placeholder: 'Search Job Seekers',
        sortInfo: USER_SORT_INFO,
        fullWidth: true,
      };
    }

    if (selectedFilters.type === FIELDS_CONFIG.type.internships) {
      return {
        placeholder: 'Search Internships',
        sortInfo: CAREERS_SORT_INFO,
        fullWidth: true,
      };
    }

    return {
      // None of the data-structures are compatible within their fields to be sorted by.
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45457
      disabled: true,
      placeholder: 'Search All',
      sortInfo: undefined,
      fullWidth: true,
    };
  }, [selectedFilters.type]);

  const onJobPostClose = () => {
    setJobPostModalOpen(false);
  };

  return (
    <SearchProvider>
      <RouteLayout
        withMaxWidth={!isMobile}
        maxWidth="100%"
        content={
          <Fragment>
            <Modal
              dialogProps={{
                open: isJobPostModalOpen,
                onClose: onJobPostClose,
                fullWidth: true,
                maxWidth: !isMobile ? 'xs' : 'xs',
              }}
              titleProps={{ title: 'Create Job Post' }}
              isVisibleDivider
              dialogContentWidth={280}
            >
              <CreateJobPostModal onModalClose={onJobPostClose} />
            </Modal>
            <Box css={leftSideBoxCSS} mb={4}>
              <PaperLayout>
                <Box css={actionsContainerCSS}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                      variant={isMobile ? 'subtitle3' : 'subtitle2'}
                      color="primary"
                      css={careerServicesCss(isMobile)}
                    >
                      {title}
                    </Typography>

                    {(isAdmin || isBusinessAdminOrManager) && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setJobPostModalOpen(true)}
                        disableElevation
                      >
                        Post
                      </Button>
                    )}
                  </Box>

                  <Box pt={isMobile ? 1 : 3.125}>
                    <SearchControl
                      withTextField
                      withSortField={!isFilterTypeAll}
                      withFilterModal={isMobile}
                      textFieldProps={searchControlProps}
                      sortFieldProps={searchControlProps}
                      elementsGap={theme.spacing(2.5)}
                      elementsWidth={
                        isMobile && !isFilterTypeAll ? ['1fr'] : ['1fr', 'max-content']
                      }
                    />
                  </Box>
                </Box>

                <CareerServicesContent
                  selectedFilters={selectedFilters}
                  setFilters={setSelectedUserFilters}
                />
              </PaperLayout>
            </Box>
          </Fragment>
        }
        sidebar={
          <Box display="grid" gridGap="1rem">
            <PaperLayout sx={{ py: 3.125, px: 3.75 }}>
              <CareerFilter selectedFilter={selectedFilters} setFilters={setSelectedUserFilters} />
            </PaperLayout>

            <SavedJobs />
          </Box>
        }
      />
    </SearchProvider>
  );
}
