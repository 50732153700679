import { useEffect } from 'react';
import { useQuery, DocumentNode, WatchQueryFetchPolicy, OperationVariables } from '@apollo/client';

import { createFilterBuilder } from '@jebel/utils';

import { FilterType, SortInfoOption, useSpreadsheetContext } from 'shared/features/spreadsheet';
import { searchFilterGenerator } from 'shared/utils/search';
import { GroupBySort } from 'shared/graphql';

import { useSearchContext } from '../providers';

export type UseSpreadsheetSearchProps = {
  query: DocumentNode;
  /** Overrides the given context and setup extra fields. */
  queryVariables?: {
    /** Overrides the given `filter` within context. */
    filter: FilterType;
    sort?: SortInfoOption | GroupBySort | (SortInfoOption | GroupBySort)[];
    first: number;
    skip: number;
    id?: string;
    startPointZip?: string;
    radius?: string;
    debtFilter?: any;
  };
  fetchPolicy?: WatchQueryFetchPolicy;
  searchingFields: string[];
};

export function useSpreadsheetSearch<Q = any, V extends OperationVariables = any>({
  query,
  queryVariables,
  searchingFields,
  fetchPolicy,
}: UseSpreadsheetSearchProps) {
  const { searchQuery } = useSearchContext();
  const { filter: contextFilter, setFilter } = useSpreadsheetContext();

  useEffect(() => {
    const filter = createFilterBuilder();
    const searchFilter = searchFilterGenerator(searchQuery, searchingFields);

    if (searchFilter) {
      filter.and(searchFilter);
    }

    setFilter({
      query: filter.build(),
      fields: contextFilter.fields,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const variables: OperationVariables = {
    ...queryVariables,
    filter: queryVariables?.filter ?? contextFilter.query,
  };

  const {
    data: tableData,
    loading: tableLoading,
    refetch: refetchTable,
    fetchMore: fetchMoreTable,
  } = useQuery<Q>(query, {
    fetchPolicy,
    variables: variables as V,
  });

  return {
    tableData,
    tableLoading: tableLoading && !tableData,
    refetchTable,
    fetchMoreTable,
    queryVariables: variables,
  };
}
