import { ContentLayout } from '../components/layouts/ContentLayout';
import { LegacyLayout } from '../components/layouts/LegacyLayout';
import { LegacyTimelineMonths } from '../components/LegacyTimelineMonths';

export function LegacyYearPage() {
  return (
    <LegacyLayout>
      <ContentLayout>
        <LegacyTimelineMonths />
      </ContentLayout>
    </LegacyLayout>
  );
}
