import React from 'react';

import { SearchProvider } from 'shared/features/search';

import { OrganizationsContent } from '../components/Organizations/OrganizationsContent';

const PERSIST_DISPLAY_LIST_KEY = 'ORGANIZATION_DISPLAY_LIST';

export const OrganizationsPage: React.FC = () => {
  return (
    <SearchProvider displayListPersistance={PERSIST_DISPLAY_LIST_KEY}>
      <OrganizationsContent />
    </SearchProvider>
  );
};
