import { useBusinessSearchQuery } from 'shared/graphql';

export const useBusinessSearch = (id: string) => {
  const { loading, data, ...rest } = useBusinessSearchQuery({
    variables: { filter: { id: { equals: id } } },
  });

  return {
    business: data?.organizationsList?.items[0],
    loading: loading && !data,
    ...rest,
  };
};
