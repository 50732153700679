import React from 'react';
import { useQuery, DocumentNode, OperationVariables } from '@apollo/client';

import { useStreamChatActivityQuery } from 'shared/hooks/useStreamChatActivity';

type UsePostsListProps = {
  listQuery: DocumentNode;
  itemsFieldName: string;
  variables: OperationVariables;
};

export function usePostsListItem<TListData = any>({
  listQuery,
  itemsFieldName,
  variables,
}: UsePostsListProps) {
  const {
    activities,
    loading: activitiesLoading,
    onListQueryComplete: rawOnQueryCompleted,
  } = useStreamChatActivityQuery();

  const onCompleted = React.useCallback(
    (data: TListData) => {
      const items: Record<string, any>[] | undefined = data?.[itemsFieldName].items;

      if (!items) {
        return;
      }

      rawOnQueryCompleted(items);
    },
    [itemsFieldName, rawOnQueryCompleted],
  );

  const {
    data: listsData,
    loading: listsLoading,
    fetchMore,
  } = useQuery<TListData, any>(listQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables,
  });

  React.useEffect(() => {
    if (listsData) {
      onCompleted(listsData);
    }
  }, [listsData, onCompleted]);

  return {
    listsData,
    loading: listsLoading && !listsData,
    fetchMore,
    activities,
    activitiesLoading,
  };
}
