import { useMemo } from 'react';

import { DISCOUNT_POST_TYPE, EVENT_POST_TYPE, SCHOOL_POST_TYPE } from '@jebel/constants';

import { HomeFeedItemFilter } from 'shared/graphql';
import { UsePostsFeedOptions, usePostsFeed } from 'shared/hooks';
import { createFilterBuilder } from 'shared/queries/filterBuilder';
import { useSearchContext } from 'shared/features/search';
import { PAGE_SIZE } from 'shared/constants';

export function useSchoolNewsFeed(options?: UsePostsFeedOptions) {
  const { searchQuery, sortOption } = useSearchContext();

  const filter = useMemo<HomeFeedItemFilter>(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>({
      postType: { in: [SCHOOL_POST_TYPE, EVENT_POST_TYPE, DISCOUNT_POST_TYPE] },
    });

    if (options?.variables?.filter) {
      filter.and(options.variables.filter);
    }

    if (searchQuery) {
      filter.and({ text: { contains: searchQuery } });
    }

    return filter.build();
  }, [options, searchQuery]);

  const { data, count, loading, refreshing, refetch, fetchMore } = usePostsFeed({
    ...options,

    withPinned: [SCHOOL_POST_TYPE],

    variables: {
      ...options?.variables,

      filter,
      sort: sortOption,
      first: PAGE_SIZE,
    },
  });

  return {
    data,
    count,
    hasMore: data.length < count,

    loading,
    refreshing,

    refetch,
    fetchMore,
  };
}
