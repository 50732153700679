import { useDiscountByIdQuery } from 'shared/graphql';

import { useHomeFeedCardData, useHomeFeedCardHaveBeenShown } from '../../providers';

/** Extract the discount from the current `HomeFeedItem` on the context. */
export function useHomeFeedDiscount() {
  const post = useHomeFeedCardData();
  const haveBeenShown = useHomeFeedCardHaveBeenShown();

  const discountID = post?.discount;

  const { data: response, loading } = useDiscountByIdQuery({
    skip: !discountID || !haveBeenShown,
    fetchPolicy: 'cache-first',

    variables: {
      id: discountID as string,
    },
  });

  const hasDiscount = Boolean(discountID);

  return {
    data: response?.discount,
    /** Checks whether the current `HomeFeedItem` has a discount. */
    has: hasDiscount,
    loading: loading || !haveBeenShown,
  };
}
