import styled from '@emotion/styled';
import { AvatarProps, Box } from '@mui/material';
import { FileUpload } from '@mui/icons-material';

export const StyledBackground = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props: any) => props.theme.palette.grey[800]};
  z-index: 1;
`;

export const StyledIcon = styled(FileUpload)`
  position: absolute;
  z-index: 2;
  margin: auto;
  color: white;
  opacity: 0;
`;

export const StyledContainer = styled(Box)<{ loading?: boolean; variant?: AvatarProps['variant'] }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${({ variant }) =>
    (variant === 'circular' && '50%') || (variant === 'rounded' && '5px') || 'none'};
  overflow: hidden;
  cursor: ${props => (props.loading ? 'node' : 'pointer')};
  ${StyledBackground} {
    opacity: ${props => (props.loading ? 0.6 : 0)};
  }
  * {
    transition: 0.2s ease;
  }
  :hover {
    ${StyledIcon} {
      opacity: ${props => !props.loading && 1};
    }
    ${StyledBackground} {
      opacity: 0.6;
    }
  }

  .MuiCircularProgress-root {
    position: absolute;
    z-index: 1;
  }

  .MuiCircularProgress-colorPrimary {
    color: white;
  }
`;
