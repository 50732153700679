import { gql } from '@apollo/client';

export const COLLECTIVE_INTELLIGENCE_ITEM_FRAGMENT = gql`
  fragment CollectiveIntelligenceItem on CollectiveIntelligenceThread {
    id
    createdAt
    text
    title
    views {
      items {
        id
      }
    }
    creator {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    images {
      items {
        id
        downloadUrl
      }
    }
  }
`;

export const COLLECTIVE_INTELLIGENCE_THREAD_CREATE_MUTATION = gql`
  mutation CollectiveIntelligenceThreadCreate($data: CollectiveIntelligenceThreadCreateInput!) {
    collectiveIntelligenceThreadCreate(data: $data) {
      ...CollectiveIntelligenceItem
    }
  }

  ${COLLECTIVE_INTELLIGENCE_ITEM_FRAGMENT}
`;

export const COLLECTIVE_INTELLIGENCE_THREAD_UPDATE_MUTATION = gql`
  mutation CollectiveIntelligenceThreadUpdate($data: CollectiveIntelligenceThreadUpdateInput!) {
    collectiveIntelligenceThreadUpdate(data: $data) {
      ...CollectiveIntelligenceItem
    }
  }

  ${COLLECTIVE_INTELLIGENCE_ITEM_FRAGMENT}
`;

export const COLLECTIVE_INTELLIGENCE_THREADS_LIST_QUERY = gql`
  query CollectiveIntelligenceThreadsList(
    $first: Int!
    $skip: Int
    $sort: [CollectiveIntelligenceThreadSort!]
    $filter: CollectiveIntelligenceThreadFilter
  ) {
    collectiveIntelligenceThreadsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...CollectiveIntelligenceItem
      }
    }
  }
  ${COLLECTIVE_INTELLIGENCE_ITEM_FRAGMENT}
`;

export const COLLECTIVE_INTELLIGENCE_THREADS_COUNT_QUERY = gql`
  query CollectiveIntelligenceThreadsCount($filter: CollectiveIntelligenceThreadFilter) {
    collectiveIntelligenceThreadsList(filter: $filter) {
      count
    }
  }
`;

export const COLLECTIVE_INTELLIGENCE_THREAD_DETAILS_QUERY = gql`
  query CollectiveIntelligenceThreadDetails($id: ID!) {
    collectiveIntelligenceThread(id: $id) {
      ...CollectiveIntelligenceItem
    }
  }
  ${COLLECTIVE_INTELLIGENCE_ITEM_FRAGMENT}
`;
