import React from 'react';

import { SortInfoField, SortInfoFieldProps } from 'shared/components/ui';

import { useSearchContext } from '../../providers/useSearchContext';

export type SortFieldProps = Pick<SortInfoFieldProps, 'sortInfo'> & { disabled?: boolean };

export const SortField: React.FC<SortFieldProps> = ({ sortInfo, disabled = false }) => {
  const { sortOption, setSortOption } = useSearchContext();

  return (
    <SortInfoField
      activeSortOption={sortOption}
      setSortOption={setSortOption}
      sortInfo={sortInfo}
      disabled={disabled}
    />
  );
};
