import { useCallback, useEffect, useMemo, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { Formik } from 'formik';
import { Box, Dialog, DialogTitle, Divider } from '@material-ui/core';

import { formatISO8601Date, HomeFeedItemURLToken, parseHomeFeedItemText } from '@jebel/utils';

import {
  Icon,
  Button,
  FormSwitch,
  FormCheckbox,
  Typography,
  MediaInput,
  DateTimePickerField,
  DatePickerField,
  LinkPreview,
} from 'shared/components/ui';
import { required } from 'shared/utils/form';
import { useCurrentUser, useToast } from 'shared/hooks';
import { ResultFile } from 'shared/types/files';
import { MentionsField } from 'shared/components/form';
import { MentionPosition } from 'shared/features/mentions';
import { recordError } from 'shared/utils/record';

import { Container } from './PostModalCreate.styles';
import { debounce } from 'lodash';

const MAX_FILES = 10;

const uploadFileBoxCSS = css`
  :hover {
    background-color: #abbeff;
  }
  cursor: pointer;
  background-color: #f6f8fa;
  border: 1px dashed #bbbbbb;
  border-radius: 5px;
  height: 150px;
  display: grid;
  grid-template-rows: min-content min-content;
  place-items: center;
  align-content: center;
`;

const uploadIconCSS = (theme: Theme) =>
  css`
    font-size: 3rem;
    fill: ${theme.palette.primary.light};
  `;

const uploadPictureCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.light};
  max-width: 40%;
  text-align: center;
`;

export interface FormValues {
  /** Using ISO8601 format. */
  legacyDate?: string | null;
  text?: string | undefined | null;
  commentsAllowed?: boolean | undefined | null;
  isPinned?: boolean | undefined | null;
  isScheduledPost?: boolean | undefined | null;
  /** Using ISO8601 format. */
  postDate?: string | undefined | null;
  media?: ResultFile[];
  mentions?: MentionPosition[];
}

interface ExtraFields {
  /**
   * Show the `legacyDate` in the form.
   * @deprecated Use `withLegacy` instead.
   * @default false
   */
  legacyDate?: boolean;

  /**
   * Show the `scheduleDate` in the form.
   * @deprecated Use `withSchedule` instead.
   * @default false
   */
  scheduleDate?: boolean;
}

export interface Props {
  isOpen: boolean;
  isEdit?: boolean;
  initialValues?: FormValues;
  /** @deprecated Use the `with...` (`withSchedule`, `withLegacyDate`, ...) fields instead. */
  extraFields?: ExtraFields;

  /**
   * Show the `legacyDate` in the form.
   * @default false
   */
  withLegacyDate?: boolean;

  /**
   * Show the `scheduleDate` in the form.
   * @default false
   */
  withSchedule?: boolean;

  /**
   * Show the `scheduleDate` in the form.
   * @default false
   */
  withPinned?: boolean;

  onClose(): void;

  onPostCreate(data: FormValues, isScheduled?: boolean): Promise<void>;
}

export function PostModalCreate({
  isOpen,
  initialValues,
  isEdit = false,
  onPostCreate,
  extraFields,
  ...props
}: Props) {
  const [isLoading, setLoading] = useState(false);
  const [mentions, setMentions] = useState<MentionPosition[]>([]);
  const [text, setText] = useState('');
  const [links, setLinks] = useState<string[]>([]);

  const { isAdmin } = useCurrentUser();
  const { showError } = useToast();

  const withPinned = Boolean(props.withPinned ?? isAdmin);
  const withLegacyDate = Boolean(props.withLegacyDate ?? extraFields?.legacyDate);
  const withScheduleDate = Boolean(props.withSchedule ?? extraFields?.scheduleDate);

  const initial = useMemo<FormValues>(() => {
    const payload: FormValues = {
      isPinned: false,
      commentsAllowed: true,
      legacyDate: null,
      postDate: formatISO8601Date(),
      media: undefined,

      ...initialValues,
    };

    if (withLegacyDate && initialValues?.legacyDate) {
      payload.legacyDate = formatISO8601Date(initialValues.legacyDate);
    }

    return payload;
  }, [initialValues, withLegacyDate]);

  useEffect(() => {
    const tokens = parseHomeFeedItemText(text || initial.text || '');

    const urls = tokens.filter(
      (section): section is HomeFeedItemURLToken => section.token === 'url',
    );

    setLinks(urls.map(section => section.url));
  }, [initial, text]);

  const submitLabel = useMemo(() => {
    if (isLoading && isEdit) {
      return 'Updating post...';
    }

    if (isLoading && !isEdit) {
      return 'Posting...';
    }

    if (isEdit) {
      return 'Update Post';
    }

    return 'Post';
  }, [isLoading, isEdit]);

  const close = () => {
    props.onClose();
  };

  const handleTextChange = useCallback(debounce(setText, 500), []);

  const handleSubmit = async (data: FormValues) => {
    setLoading(true);

    const isScheduled = Boolean(data.isScheduledPost);
    const isPinned = Boolean(data.isPinned);

    try {
      const text = (data.text && data.text.trim()) ?? '';

      if (isScheduled && !data.postDate) {
        throw new Error('Should define a post-date in schedule mode');
      }

      if (!text && data.media?.length === 0) {
        throw new Error('Either text or media must be defined');
      }

      const formattedData: FormValues = {
        text,
        media: data.media,
        commentsAllowed: !!data.commentsAllowed,
        isPinned,
        mentions,
      };

      if (withLegacyDate && data.legacyDate) {
        formattedData.legacyDate = data.legacyDate;
      }

      if (withScheduleDate && !isScheduled) {
        // Prevent to send the post date when was not checked.
        formattedData.postDate = formatISO8601Date();
      }

      if (isScheduled && data.postDate) {
        formattedData.postDate = data.postDate;
      }

      await onPostCreate(formattedData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <Dialog fullScreen={false} fullWidth={true} maxWidth="sm" open={isOpen} onClose={close}>
      <DialogTitle>{isEdit ? 'Edit Post' : 'Post'}</DialogTitle>

      <Divider />

      <Formik initialValues={initial} validateOnBlur onSubmit={handleSubmit}>
        {form => (
          <Container>
            {withScheduleDate && (
              <FormCheckbox
                fieldProps={{ name: 'isScheduledPost' }}
                checkboxProps={{
                  label: 'Schedule Post',
                  color: 'primary',
                }}
              />
            )}

            {form.values.isScheduledPost && (
              <DateTimePickerField
                label="POST DATE"
                disablePast
                name="postDate"
                inputVariant="outlined"
                validate={required}
              />
            )}

            {withLegacyDate && (
              <DatePickerField
                name="legacyDate"
                allowKeyboardControl
                inputVariant="outlined"
                label="Legacy Date"
                disableFuture
                validate={required}
              />
            )}

            <MentionsField
              name="text"
              multiline
              spellCheck
              allowSearchSpace
              placeholder="Type @ to mention some users, organizations or groups"
              onChange={handleTextChange}
              onChangeMentions={setMentions}
            />

            <Box display="flex" alignItems="center" flexWrap="wrap" gridGap="1rem">
              <FormSwitch
                fieldProps={{
                  name: 'commentsAllowed',
                }}
                switchProps={{
                  name: 'commentsAllowed',
                  disableRipple: false,
                  color: 'primary',
                  size: 'small',
                  label: 'Allow comments',
                }}
              />

              {withPinned && (
                <FormSwitch
                  fieldProps={{
                    name: 'isPinned',
                  }}
                  switchProps={{
                    name: 'isPinned',
                    disableRipple: false,
                    color: 'primary',
                    size: 'small',
                    label: 'Pin content',
                  }}
                />
              )}
            </Box>

            {links.map(link => (
              <LinkPreview key={link} url={link} />
            ))}

            <MediaInput
              descriptionPlacement="top"
              maxFiles={MAX_FILES}
              initialValue={initialValues?.media}
              pickerOptions={{
                accept: ['image/*', 'video/mp4', 'video/mpeg', 'video/webm'],
              }}
              onChange={files => form.setFieldValue('media', files)}
              showDescription={true}
            >
              <Box css={uploadFileBoxCSS}>
                <Icon css={uploadIconCSS} name="FileUpload" variant="filled" />
                <Typography css={uploadPictureCSS} variant="inherit">
                  Upload pictures or videos here
                </Typography>
              </Box>
            </MediaInput>

            <Box display="flex" gridGap="0.5rem" justifyContent="flex-end">
              <Button onClick={close} disabled={isLoading} color="primary" variant="text">
                Cancel
              </Button>

              <Button
                color="primary"
                disableElevation
                variant="contained"
                type="button"
                disabled={isLoading || form.isSubmitting}
                loading={isLoading}
                onClick={() => form.submitForm()}
              >
                {submitLabel}
              </Button>
            </Box>
          </Container>
        )}
      </Formik>
    </Dialog>
  );
}
