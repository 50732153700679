import React from 'react';
import { css } from '@emotion/react';

import { Box } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { useUserById } from 'shared/hooks/useUserById';
import { buildUrl } from 'shared/routes/routerUtils';

import { SettingsSidebarList } from './SettingsSidebarList';
import { SettingsSidebarUserProfile } from './SettingsSidebarUserProfile';

const containerCss = (theme: any) => css`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${theme.palette.border};
`;

const MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: APP_URL.user.settings.information,
  },
  {
    title: 'Sign-in & Security',
    icon: 'Lock',
    route: APP_URL.user.settings.security,
  },
];

const ADDITIONAL_SIDEBAR_LIST_DATA = [
  { title: 'Groups', icon: 'People', route: APP_URL.user.settings.groups },
  {
    title: 'Edit Public Profile',
    icon: 'Assignment',
    route: APP_URL.user.settings.profile,
  },
];

export const SettingsSidebar = ({
  isAdminRoute = false,
  userId = undefined,
}: {
  isAdminRoute: boolean;
  userId?: string | null | undefined;
}) => {
  const { user, loading: loadingUser } = useUserById({ id: userId });

  const ADMIN_MAIN_SIDEBAR_LIST_DATA = React.useMemo(() => {
    return [
      {
        title: 'Account Information',
        icon: 'AccountCircle',
        route: userId
          ? buildUrl(APP_URL.admin.settings.information, { pathParams: { id: userId } })
          : '',
      },
    ];
  }, [userId]);

  return (
    <Box css={containerCss}>
      <SettingsSidebarUserProfile user={user} loading={loadingUser} />
      <SettingsSidebarList
        listData={isAdminRoute ? ADMIN_MAIN_SIDEBAR_LIST_DATA : MAIN_SIDEBAR_LIST_DATA}
      />
      {!isAdminRoute && <SettingsSidebarList listData={ADDITIONAL_SIDEBAR_LIST_DATA} />}
    </Box>
  );
};
