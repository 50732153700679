import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { Theme, css } from '@emotion/react';
import { useHistory } from 'react-router-dom';

import { SxProp } from 'shared/types/styles';

import { PaperLayout } from './PaperLayout';
import { Button, ButtonProps, Typography } from '../ui';

const containerCSS = css`
  display: grid;
  gap: 2rem;
`;

const contentCSS = css`
  min-width: 0;
  padding: 3rem 2rem;
`;

const linkButtonStyles = theme => css`
  color: ${theme.palette.secondary.light};
  padding: ${theme.spacing(0.75)}px 0;
  text-transform: none;
  min-width: 0px;
`;

const sectionLayoutStyles: SxProp = {
  backgroundColor: '#fff',
  py: { xs: 1.75, md: 3.125 },
  px: { xs: 2.5, md: 3.75 },
};

const sectionTitleCss = theme => css`
  color: ${theme.palette.primary.icon};
  padding: ${theme.spacing(3.125)}px 0;
`;

type Section = {
  id: number;
  content: React.ReactNode | JSX.Element;
  title?: string;
  isDisplayed?: boolean;
  withWrapper?: boolean;
};

interface LayoutProps {
  sections?: Section[];
  linkProps?: ButtonProps;
  layoutBoxProps?: BoxProps;
}

/** @deprecated Use {@linkcode PaperLayout} directly instead. */
export const DetailsContentLayout: React.FC<LayoutProps> = ({
  children,
  sections,
  linkProps,
  layoutBoxProps,
}) => {
  const history = useHistory();
  const renderedSections = React.useMemo(() => {
    const sectionsToDisplay = sections?.filter(({ isDisplayed = true }) => isDisplayed) || [];
    return sectionsToDisplay.map(({ content, title, id, withWrapper = true }) =>
      withWrapper ? (
        <PaperLayout key={id} sx={sectionLayoutStyles}>
          {title && (
            <Typography variant="subtitle5" css={sectionTitleCss}>
              {title}
            </Typography>
          )}
          {content}
        </PaperLayout>
      ) : (
        <React.Fragment>{content}</React.Fragment>
      ),
    );
  }, [sections]);

  return (
    <Box css={containerCSS} {...layoutBoxProps}>
      <PaperLayout css={contentCSS}>
        <Button css={linkButtonStyles} onClick={history.goBack} variant="text" {...linkProps}>
          Go back
        </Button>

        {children}
      </PaperLayout>

      {renderedSections}
    </Box>
  );
};
