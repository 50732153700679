import { parse, unparse } from 'papaparse';
/**
 * Read a plain text with the CSV format and transform the data as an array of objects.
 * @param text Plain text
 * @param options Configurations to read and transform the data
 */
export function readCSV(text, options) {
    const { hasHeaders, columnSeparator, rowSeparator, skipEmpty } = options !== null && options !== void 0 ? options : {};
    const result = parse(text, {
        header: hasHeaders,
        delimiter: columnSeparator,
        newline: rowSeparator,
        skipEmptyLines: skipEmpty,
        transformHeader(name, position) {
            if ((options === null || options === void 0 ? void 0 : options.hasHeaders) && typeof (options === null || options === void 0 ? void 0 : options.transformHeader) === 'function') {
                return options.transformHeader(name, position);
            }
            return name;
        },
    });
    return result.data;
}
/**
 * Write a plain text CSV formatted value.
 * @param data Content of the CSV.
 * @param options Configurations to write and transform the data.
 */
export function writeCSV(data, options) {
    const { columnSeparator, rowSeparator, skipEmpty } = options !== null && options !== void 0 ? options : {};
    const headers = (options === null || options === void 0 ? void 0 : options.withHeader) ? options === null || options === void 0 ? void 0 : options.headers : undefined;
    return unparse(data, {
        columns: headers,
        header: options === null || options === void 0 ? void 0 : options.withHeader,
        delimiter: columnSeparator,
        newline: rowSeparator,
        skipEmptyLines: skipEmpty,
    });
}
/** */
export function csvToBase64(content) {
    return `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`;
}
