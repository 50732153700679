import { useMemo } from 'react';
import { isString } from 'lodash';
import { Box } from '@material-ui/core';

import { useContactOrganization } from 'features/home/hooks/useContactOrganization';
import {
  Button,
  Form,
  FormCheckbox,
  FormPhoneFieldV2,
  FormTextField,
  Modal,
} from 'shared/components/ui';
import { JobOffer } from 'shared/graphql';
import { composeValidators, isValidEmail, required } from 'shared/utils/form';
import { useCurrentUser, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { Container, Section, Terms } from './ContactOrganizationModal.styles';

interface ContactOrganizationModalForm {
  message: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export type ContactOrganizationModalProps = {
  isOpen: boolean;
  jobOffer?: JobOffer;

  onClose(): void;
};

const MESSAGE_SUBJECT = 'New user has shared the contacts.';
const NOT_PROVIDED_PLACEHOLDER = '(NOT PROVIDED)';

export const ContactOrganizationModal = (props: ContactOrganizationModalProps) => {
  const { user } = useCurrentUser();
  const { onContactOrganization } = useContactOrganization();
  const { showError } = useToast();

  const jobId = props.jobOffer?.id;
  const organization = props.jobOffer?.organization;

  const initials = useMemo<ContactOrganizationModalForm>(() => {
    return {
      message: '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      phoneNumber: '',
    };
  }, [user]);

  const handleSubmit = async (form: ContactOrganizationModalForm) => {
    const template = `
      Congratulations! You have received a new request to be contacted from a member of Columbus Connects. See contact information below:

      First Name: ${form.firstName || NOT_PROVIDED_PLACEHOLDER}
      Last Name: ${form.lastName || NOT_PROVIDED_PLACEHOLDER}
      Email: ${form.email || NOT_PROVIDED_PLACEHOLDER}
      Phone Number: ${form.phoneNumber || NOT_PROVIDED_PLACEHOLDER}
      Message: ${form.message || NOT_PROVIDED_PLACEHOLDER}
    `;

    const text = template.trim().replace(/^[ ]+/gm, '');

    try {
      const managers = organization?.managers?.items ?? [];

      const receivers = managers
        .map(manager => manager?.user?.id)
        .concat(organization?.owner?.id)
        .filter<string>(isString);

      await onContactOrganization(MESSAGE_SUBJECT, text, receivers, jobId ?? undefined);
      props.onClose();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <Modal
      dialogProps={{
        fullWidth: true,
        onClose: props.onClose,
        open: props.isOpen,
      }}
      isVisibleDivider
      titleProps={{ title: `Contact "${organization?.name}"` }}
    >
      <Form initialValues={initials} onSubmit={handleSubmit}>
        {({ values, isSubmitting }) => (
          <Container>
            <Section>
              <FormTextField
                inputProps={{
                  label: 'First Name',
                  variant: 'outlined',
                }}
                fieldProps={{
                  name: 'firstName',
                  validate: required,
                }}
              />
              <FormTextField
                inputProps={{
                  label: 'Last Name',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'lastName', validate: required }}
              />
            </Section>

            <Section>
              <FormPhoneFieldV2 fieldProps={{ name: 'phoneNumber' }} />

              <FormTextField
                inputProps={{
                  label: 'Email',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'email', validate: composeValidators(required, isValidEmail) }}
              />
            </Section>

            <FormTextField
              inputProps={{
                label: 'Message',
                multiline: true,
                rows: '7',
                rowsMax: '7',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'message', validate: required }}
            />

            <FormCheckbox
              checkboxProps={{ label: 'I agree to the contact information sharing terms.' }}
              fieldProps={{ name: 'termsCheckbox', validate: required }}
            />

            {values.termsCheckbox && (
              <Terms>
                By clicking the “Submit” button, I expressly authorize Attack Each Day Ventures, LLC
                dba Jebel, along with their advertisers, agents, and affiliates, to call me or send
                me recorded messages or texts about their products and/or services, using automated
                technology to the telephone/cellular number/email submitted, which I certify is my
                own. I understand that I am not required to consent to be contacted in order to make
                a purchase.
              </Terms>
            )}

            <Box display="flex" justifyContent="flex-end" gridGap="0.5rem">
              <Button onClick={props.onClose} variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={!values.termsCheckbox}
                disableElevation
              >
                submit
              </Button>
            </Box>
          </Container>
        )}
      </Form>
    </Modal>
  );
};
