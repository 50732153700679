export const NAVIGATION_LABEL_LIST = {
  explorer: 'Members',
  organizations: 'Business Registry',
  home: 'Home',
  collective: 'Forum',
  discounts: 'Special Offers',
  news: 'School Posts',
  services: 'Jobs & Internships',
  events: 'Events',
  groups: 'Groups',
  legacy: 'Legacy',
  scorecard: 'Class Scorecard',
  mail: 'Inbox',
};

export enum SiteAdminsHeaders {
  name = 'Name',
  email = 'Email Address',
  phone = 'Phone',
  dateOfBirth = 'Date Of Birth',
  status = 'Status',
}
