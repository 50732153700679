import { gql } from '@apollo/client';

export const FILE_FRAGMENT = gql`
  fragment File on File {
    id
    fileId
    filename
    downloadUrl
  }
`;

export const MEDIA_FRAGMENT = gql`
  fragment Media on FileListResponse {
    items {
      ...File
    }
  }
  ${FILE_FRAGMENT}
`;

export const UPLOAD_FILE_BY_URL_MUTATION = gql`
  mutation UploadFileByUrl($url: String!) {
    system {
      fileUploadByUrl(data: { url: $url }) {
        fileId
        meta
      }
    }
  }
`;
