import React from 'react';
import { List, RootRef } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SchoolConfigurationLayout } from 'shared/graphql';

import { Label } from './Label';

type LabelsListProps = {
  items: SchoolConfigurationLayout[];
  onDragEnd: (result: any, itemType?: unknown) => void;
  onItemUpdate: (item: SchoolConfigurationLayout) => void;
  disableOptions?: boolean;
  onModalOpen?: (item: SchoolConfigurationLayout) => void;
};

export const LabelsList = ({
  items,
  onDragEnd,
  onItemUpdate,
  disableOptions = false,
  onModalOpen,
}: LabelsListProps) => {
  const handleModalOpen = React.useCallback(
    (item: SchoolConfigurationLayout) => () => onModalOpen?.(item),
    [onModalOpen],
  );
  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <RootRef rootRef={provided.innerRef}>
              <List>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id as string} index={index}>
                    {(provided, snapshot) => (
                      <Label
                        item={item}
                        snapshot={snapshot}
                        provided={provided}
                        onItemUpdate={onItemUpdate}
                        disableOptions={disableOptions}
                        onModalOpen={handleModalOpen(item)}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
};
