import { useResponsive } from 'shared/hooks';

import { LegacyLayout } from '../components/layouts/LegacyLayout';
import { LegacyPostsContent } from '../components/LegacyPosts';
import { ContentLayout } from '../components/layouts/ContentLayout';
import { LegacyTimelineYears } from '../components/LegacyTimelineYears';

export function LegacyPage() {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <LegacyLayout>
        <LegacyPostsContent />
      </LegacyLayout>
    );
  }

  return (
    <LegacyLayout>
      <ContentLayout>
        <LegacyTimelineYears />
      </ContentLayout>
    </LegacyLayout>
  );
}
