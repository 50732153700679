import { Box } from '@material-ui/core';
import { MemberInfoFragment } from 'shared/graphql';

import { JobSeekersListItem } from './JobSeekersListItem';

interface Props {
  usersList: MemberInfoFragment[];
}

export function JobSeekersList(props: Props) {
  return (
    <Box display="flex" flexDirection="column" padding="1rem" gridGap="1rem">
      {props.usersList?.map(item => (
        <JobSeekersListItem key={item?.id} data={item} />
      ))}
    </Box>
  );
}
