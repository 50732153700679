import React from 'react';
import type { ApolloCache } from '@apollo/client';
import _ from 'lodash';

import {
  RoleAddonsDocument,
  useRoleAddonsQuery,
  useRoleAddonCreateMutation,
  useRoleAddonUpdateMutation,
} from 'shared/graphql';
import type {
  RoleAddonsQuery,
  RoleAddonsQueryVariables,
  RoleAddonInfoFragment,
  RoleAddonCreateInput,
  RoleAddonUpdateInput,
} from 'shared/graphql/__generated__';

const readRoleAddonCache = (cache: ApolloCache<unknown>) => {
  return cache.readQuery<RoleAddonsQuery, RoleAddonsQueryVariables>({
    query: RoleAddonsDocument,
  });
};

const updateRoleAddonCache = (
  cache: ApolloCache<unknown>,
  type: 'create' | 'update',
  newRoleAddonData: RoleAddonInfoFragment,
) => {
  const existingData = readRoleAddonCache(cache);

  if (_.isNil(existingData)) {
    return undefined;
  }

  const newData: RoleAddonsQuery =
    type === 'create'
      ? {
          ...existingData,
          roleAddonsList: {
            ...existingData.roleAddonsList,
            items: [...existingData.roleAddonsList.items, newRoleAddonData],
          },
        }
      : {
          ...existingData,
          roleAddonsList: {
            ...existingData.roleAddonsList,
            items: existingData.roleAddonsList.items.map(role => {
              return role.id === newRoleAddonData.id ? newRoleAddonData : role;
            }),
          },
        };

  cache.writeQuery<RoleAddonsQuery, RoleAddonsQueryVariables>({
    query: RoleAddonsDocument,
    data: newData,
  });
};

interface UserRolesAddonsHookResult {
  rolesAddons: RoleAddonInfoFragment[];
  createRoleAddon: (input: RoleAddonCreateInput) => Promise<string | null>;
  updateRoleAddon: (roleId: string, input: RoleAddonUpdateInput) => Promise<void>;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
}

export const useUserRolesAddons = (): UserRolesAddonsHookResult => {
  const { data, loading: isLoading } = useRoleAddonsQuery();

  const [roleAddonCreateMutation, { loading: isCreating }] = useRoleAddonCreateMutation({
    refetchQueries: ['CurrentUser', 'RoleAddons'],
    awaitRefetchQueries: true,
  });

  const [roleAddonUpdateMutation, { loading: isUpdating }] = useRoleAddonUpdateMutation({
    refetchQueries: ['CurrentUser', 'RoleAddons'],
    awaitRefetchQueries: true,
  });

  const rolesAddons = React.useMemo(() => {
    return data?.roleAddonsList.items || [];
  }, [data?.roleAddonsList.items]);

  const createRoleAddon = React.useCallback(
    async (input: RoleAddonCreateInput) => {
      const { data } = await roleAddonCreateMutation({
        variables: {
          input,
        },
        update: (cache, { data }) => {
          if (!_.isNil(data)) {
            updateRoleAddonCache(cache, 'create', data.roleAddonCreate);
          }
        },
      });

      return data?.roleAddonCreate.id || null;
    },
    [roleAddonCreateMutation],
  );

  const updateRoleAddon = React.useCallback(
    async (roleId: string, input: RoleAddonUpdateInput) => {
      await roleAddonUpdateMutation({
        variables: {
          roleId,
          input,
        },
        update: (cache, { data }) => {
          if (!_.isNil(data)) {
            updateRoleAddonCache(cache, 'update', data.roleAddonUpdate);
          }
        },
      });
    },
    [roleAddonUpdateMutation],
  );

  return {
    rolesAddons,
    createRoleAddon,
    updateRoleAddon,
    isLoading,
    isCreating,
    isUpdating,
  };
};
