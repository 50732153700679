import { Formik } from 'formik';

import { UsersFilter } from 'features/explorer/types';
import { Button } from 'shared/components/ui';

// import { EventsFilterForm } from '../EventsFilterForm';
import { Buttons, Container } from './ExplorerFilterModal.styles';
import { ExplorerFilterForm } from '../ExplorerFilterForm';

interface Props {
  initial: UsersFilter;

  onClose(): void;
  onChange(filter: UsersFilter): void;
}

export function ExplorerFilterModal(props: Props) {
  const handleClean = () => {
    props.onChange({
      affiliation: undefined,
      currentIndustry: '',
      graduatingYear: undefined,
      jobSeekersOnly: false,
    });

    props.onClose();
  };

  const handleSubmit = (filters: UsersFilter) => {
    props.onChange(filters);
    props.onClose();
  };

  return (
    <Formik initialValues={props.initial} enableReinitialize onSubmit={handleSubmit}>
      {form => (
        <Container>
          <ExplorerFilterForm />

          <Buttons>
            <Button disabled={!form.dirty} onClick={handleClean} size="medium">
              CLEAR
            </Button>

            <Button onClick={props.onClose} size="medium">
              CANCEL
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              disabled={!form.dirty}
              onClick={form.submitForm}
            >
              APPLY
            </Button>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
}
