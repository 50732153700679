import styled from '@emotion/styled';

// SkeletonContainer

export const SkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 20rem 10rem;
  gap: 0.25rem;

  & > :first-child {
    grid-column: span 3;
  }

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-rows: 30vw 20vw;
  }
`;
