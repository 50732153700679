import React from 'react';
import { Box } from '@mui/material';

import { CommentForm, CommentImage } from 'shared/features/posts';
import { CommentEnrichedReaction } from 'shared/types';
import {
  OnCommentAdd,
  OnCommentRemove,
  OnLikeAdd,
  OnReactionRemove,
} from 'shared/hooks/useStreamChatActivity';
import { ViewMoreButton } from 'shared/features/posts/components/ViewMoreButton';
import { MentionPosition } from 'shared/features/mentions/types';

import { EventComment } from './EventComment';

interface EventCommentProps {
  onCommentAdd: OnCommentAdd;
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  onCommentRemove: OnCommentRemove;
  activity: any;
}

export const EventComments: React.FC<EventCommentProps> = ({
  onCommentAdd,
  onLikeAdd,
  onCommentRemove,
  onReactionRemove,
  activity,
}) => {
  const [viewAllComments, setViewAllComments] = React.useState(false);
  const [comments, setComments] = React.useState<CommentEnrichedReaction[]>([]);
  const textFieldRef = React.useRef<any>(null);

  const topicAuthorId = React.useMemo(
    () => activity?.actor.toString().split(':')[1],
    [activity?.actor],
  );

  const onCommentPost = React.useCallback(
    async (text: string, image: CommentImage[], mentions: MentionPosition[] = []) => {
      if (!activity?.id) {
        return;
      }

      const comment = await onCommentAdd({
        activityId: activity?.id,
        text,
        media: image,
        parentEntityAuthorId: topicAuthorId,
        parentEntityType: 'topic',
        mentions,
      });

      if (!comment) {
        return;
      }

      setComments([...comments, comment]);
    },
    [activity?.id, comments, onCommentAdd, topicAuthorId],
  );

  React.useEffect(() => {
    const newComments = activity?.latest_reactions?.comment || [];

    setComments(newComments);
  }, [activity?.latest_reactions?.comment]);

  if (!activity?.id) {
    return null;
  }

  const commentsAmount = comments.length - 1;

  return (
    <React.Fragment>
      {comments[0] && (
        <Box key={comments[0].id}>
          <EventComment
            comment={comments[0]}
            onCommentRemove={onCommentRemove}
            onCommentAdd={onCommentAdd}
            onLikeAdd={onLikeAdd}
            onReactionRemove={onReactionRemove}
          />
        </Box>
      )}

      {viewAllComments &&
        comments.map(comment => (
          <Box key={comment.id}>
            <EventComment
              comment={comment}
              onCommentAdd={onCommentAdd}
              onCommentRemove={onCommentRemove}
              onLikeAdd={onLikeAdd}
              onReactionRemove={onReactionRemove}
            />
          </Box>
        ))}

      {!viewAllComments && commentsAmount > 1 && (
        <ViewMoreButton
          onViewMore={() => setViewAllComments(true)}
          type="comment"
          amount={commentsAmount}
        />
      )}
      <CommentForm type="comment" fieldRef={textFieldRef} onCommentPost={onCommentPost} />
    </React.Fragment>
  );
};
