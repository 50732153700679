import { MentionType } from '@jebel/constants';
/**
 * Create the structure of the identifier of the mention.
 * @example `user:IDENTIFIER`
 * @example `organization:IDENTIFIER`
 * @example `group:IDENTIFIER`
 * @returns A identifier string.
 */
export function createMentionIdentifier(type, id) {
    return `${type}:${id}`;
}
/**
 * Create the structure of the identifier of the user mention.
 * @example `user:IDENTIFIER`
 * @returns A identifier string.
 */
export function createUserMentionIdentifier(id) {
    return createMentionIdentifier(MentionType.User, id);
}
/**
 * Create the structure of the identifier of the group mention.
 * @example `group:IDENTIFIER`
 * @returns A identifier string.
 */
export function createGroupMentionIdentifier(id) {
    return createMentionIdentifier(MentionType.Group, id);
}
/**
 * Create the structure of the identifier of the organization mention.
 * @example `organization:IDENTIFIER`
 * @returns A identifier string.
 */
export function createOrganizationMentionIdentifier(id) {
    return createMentionIdentifier(MentionType.Organization, id);
}
