import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { JobEmployeeUpdateInput, useJobEmployeeUpdateMutation } from 'shared/graphql';

export function useProfessionalProfileUpdate() {
  const [mutate] = useJobEmployeeUpdateMutation({
    refetchQueries: ['UserPublicInfo'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Experience successfully updated',
      [SNACKBAR_ERROR_MESSAGE]: 'Experience was not updated',
    },
  });

  const updateExperience = async (data: JobEmployeeUpdateInput) => {
    return mutate({ variables: { data } });
  };

  return {
    updateExperience,
  };
}
