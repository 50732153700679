/* eslint-disable prefer-destructuring */
import { Tab, Tabs, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { KeyboardEvent, useEffect, useLayoutEffect } from 'react';

import { ROUTES } from 'shared/constants';
import { useQueryParams, useResponsive } from 'shared/hooks';
import { SearchTextField, useSearchContext } from 'shared/features/search';

import { Container, Content } from './SearchPageHeader.styles';

interface SearchParams {
  q?: string;
  /** @deprecated */
  postId?: string;
  post?: string;
}

export function SearchPageHeader() {
  const [searchParams, { setParam, removeParam }] = useQueryParams<SearchParams>();

  const { searchQuery, setSearchQuery } = useSearchContext();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();
  const { isMobile } = useResponsive();

  const postId = searchParams.post ?? searchParams.postId;
  const search = searchParams.q;

  useLayoutEffect(() => {
    if (search) {
      // Allow to set the search from params only initially.
      setSearchQuery(search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setParam('q', searchQuery);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const changeSearch = (search: string) => {
    setSearchQuery(search);
    setParam('q', search);
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { value } = event.target as HTMLInputElement;

      changeSearch(value);
    }
  };

  const handleChangeRoute = (_event: unknown, route: string) => {
    const url = `${route}?q=${search}`;

    if (postId) {
      removeParam('postId');
    }

    navigate(url);
  };

  return (
    <Container>
      <Content>
        <Typography variant="subtitle2" color="primary">
          Search Results
        </Typography>

        <SearchTextField
          autoFocus
          placeholder="Search..."
          variant="outlined"
          onKeyDown={handleSearchKeyDown}
        />
      </Content>

      <Tabs
        value={pathname}
        variant={isMobile ? 'scrollable' : 'fullWidth'}
        scrollButtons="auto"
        onChange={handleChangeRoute}
      >
        <Tab value={ROUTES.user.search.all} label="All" />
        <Tab value={ROUTES.user.search.people} label="People" />
        <Tab value={ROUTES.user.search.organizations} label="Organizations" />
        <Tab value={ROUTES.user.search.posts} label="Posts" />
      </Tabs>
    </Container>
  );
}
