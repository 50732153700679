import { MenuItem } from '@material-ui/core';
import { FilterAlt as IconFilter } from '@mui/icons-material';

import { Typography, FormTextField, FormSelect, FormCheckbox } from 'shared/components/ui';
import { useResponsive } from 'shared/hooks';
import { FIELDS_CONFIG } from 'shared/constants';

import { Container, Content, Header } from './ExplorerFilterForm.styles';

export function ExplorerFilterForm() {
  const { isMobile } = useResponsive();

  return (
    <Container>
      {!isMobile && (
        <Header>
          <IconFilter />

          <Typography variant="subtitle5">Filter by</Typography>
        </Header>
      )}

      <Content>
        <FormSelect
          selectProps={{
            variant: 'outlined',
            label: 'INDUSTRY',
            placeholder: 'Select a industry',
            children: FIELDS_CONFIG.industry.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )),
          }}
          fieldProps={{
            name: 'currentIndustry',
          }}
        />

        <FormSelect
          selectProps={{
            variant: 'outlined',
            label: 'CLASS YEAR',
            placeholder: 'Select a class year',
            children: FIELDS_CONFIG.classYear.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )),
          }}
          fieldProps={{
            name: 'graduatingYear',
          }}
        />

        <FormSelect
          selectProps={{
            variant: 'outlined',
            label: 'MEMBER TYPE',
            placeholder: 'Select a member type',
            children: FIELDS_CONFIG.affiliation.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )),
          }}
          fieldProps={{
            name: 'affiliation',
          }}
        />

        <FormTextField
          fieldProps={{ name: 'zipCode' }}
          inputProps={{
            label: 'ZIP Code',
            placeholder: 'ZIP Code',
            variant: 'outlined',
          }}
        />

        <FormCheckbox
          checkboxProps={{ label: 'Job Seekers Only' }}
          fieldProps={{ name: 'jobSeekersOnly' }}
        />
      </Content>
    </Container>
  );
}
