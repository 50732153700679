import { useCallback, useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/ui';
import { useGlobalState } from 'providers';
import { sortMobileLabels, useSchoolConfiguration, useToast } from 'shared/hooks';
import { Maybe, SchoolConfigurationLayout } from 'shared/graphql';
import { ROUTES } from 'shared/constants';

import { Button, Container } from './BottomNavigation.styles';

const FIRSTS_THREE_LABELS = 3;

export function BottomNavigation() {
  const [labels, setLabels] = useState<SchoolConfigurationLayout[]>([]);

  const { pathname } = useLocation();
  const { configuration } = useSchoolConfiguration();
  const { leftMenuDrawer: menu } = useGlobalState();
  const { showWarning } = useToast();
  const { push: navigate } = useHistory();

  const toggleMenu = () => {
    menu.toggle();
  };

  useEffect(() => {
    const pages = configuration?.layout?.items ?? [];

    const home = pages.find(page => page?.route === ROUTES.user.home.index);
    const routes = sortMobileLabels(pages).slice(0, FIRSTS_THREE_LABELS);

    setLabels([home as SchoolConfigurationLayout, ...routes]);
  }, [configuration]);

  const handleOpenRoute = useCallback(
    (route?: Maybe<string>) => () => {
      if (typeof route !== 'string') {
        showWarning('Route does not have a URL');
        return;
      }

      // https://8base-dev.atlassian.net/browse/JEB-1315
      menu.close();
      navigate(route);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu],
  );

  return (
    <Container>
      {labels.map((item, index) => {
        const isSelected = Boolean(matchPath(pathname, { path: item?.route as string }));

        return (
          <Button
            key={item?.id ?? index}
            onClick={handleOpenRoute(item?.route)}
            isSelected={isSelected}
          >
            <Icon name={item?.icon ?? 'Home'} />
          </Button>
        );
      })}

      <Button onClick={toggleMenu}>
        <Icon name={menu?.isOpened ? 'Close' : 'Menu'} color="inherit" />
      </Button>
    </Container>
  );
}
