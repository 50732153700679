import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  EducationalInstitutionUpdateInput,
  useEducationProfileUpdateMutation,
} from 'shared/graphql';

export function useEducationProfileUpdate() {
  const [mutate, { loading, error }] = useEducationProfileUpdateMutation({
    refetchQueries: ['UserPublicInfo'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your education profile has been updated.',
      [SNACKBAR_ERROR_MESSAGE]:
        'Error: There are one or more errors in your education profile. Please review carefully and try again.',
    },
  });

  const execute = (data: EducationalInstitutionUpdateInput) => {
    return mutate({ variables: { data } });
  };

  return {
    error,
    loading,
    execute,
  };
}
