import { useMemo } from 'react';

import { POST_REPORT_STATUS } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { useSearchContext } from 'shared/features/search';
import { LegacyFeedPostFilter, SortOrder, useLegacyTimelinePostsQuery } from 'shared/graphql';

import { LegacyFilterType } from '../types';

type Options = Parameters<typeof useLegacyTimelinePostsQuery>[0];

export function useLegacyTimelinePosts(options: Options) {
  const { sortOption, searchQuery } = useSearchContext<LegacyFilterType>();

  const direction = (sortOption.createdAt as SortOrder) ?? 'DESC';

  const filter = useMemo(() => {
    const filter = createFilterBuilder<LegacyFeedPostFilter>(options?.variables?.filter);

    filter.and({
      // Ensure only allowed post are being displayed.
      // https://8base-dev.atlassian.net/browse/JEB-1580?focusedCommentId=45490
      reportStatus: { equals: POST_REPORT_STATUS.postAllowed },
    });

    filter.and({
      // Ensure only post that have been posted are being displayed.
      // https://github.com/8base-services/jebel/issues/1449
      OR: [{ postDate: { lte: new Date().toISOString() } }, { postDate: { is_empty: true } }],
    });

    if (searchQuery) {
      filter.and({ _fullText: searchQuery });
    }

    return filter.build();
  }, [options?.variables?.filter, searchQuery]);

  const { data: response, loading } = useLegacyTimelinePostsQuery({
    ...options,

    variables: {
      ...options?.variables,

      sort: [{ createdAt: direction }],
      filter,
    },
  });

  const data = response?.feed.items ?? [];

  return {
    data,
    loading,
  };
}
