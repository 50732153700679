import styled from '@emotion/styled';
import { Avatar, Link, Typography } from '@mui/material';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

// HeaderSection

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

// Position

export const Position = styled(Typography)`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
  line-height: initial;
`;

// Subtitle

const HeaderText = styled(Typography)`
  color: ${p => p.theme.palette.grey[500]};
  line-height: initial;
`;

HeaderText.defaultProps = {
  variant: 'body2',
};

// HeaderContent

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

// OrganizationAvatar

export const OrganizationAvatar = styled(Avatar)`
  --size: 4rem;

  width: var(--size);
  height: var(--size);
  border: solid 1px;
  border-color: ${p => p.theme.palette.divider};
  border-radius: 0.25rem;
`;

// OrganizationName

export const OrganizationName = styled(HeaderText)``;

// OrganizationAddress

export const OrganizationAddress = styled(HeaderText)``;

// AuthorAvatar

export const AuthorAvatar = styled(Avatar)`
  --size: 2.5rem;

  width: var(--size);
  height: var(--size);
`;

// AuthorName

export const AuthorName = styled(HeaderText)``;

// AuthorLink

export const AuthorLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

// AuthorCreation

export const AuthorCreation = styled(HeaderText)`
  color: ${p => p.theme.palette.grey[600]};
`;

// Section

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// SectionTitle

export const SectionTitle = styled(Typography)`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
`;

// Description

export const Description = styled(Typography)``;

// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
