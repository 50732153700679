import { CardMedia } from '@mui/material';
import { css } from '@emotion/react';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Media } from './types';

type RenderProps = Media;

interface RenderImageProps extends RenderProps {
  size?: any;
}

const mediaCSS = css`
  height: 100%;
`;

const imageCSS = css`
  ${mediaCSS};

  object-fit: contain;
`;
const videoCSS = css`
  ${mediaCSS};
`;

export const renderImage = (props: RenderImageProps) => {
  const url = props.downloadUrl ?? undefined;
  const sourceSet = processFilestackUrlSrcSet(url as string, {
    resize: {
      height: props.size ?? 900,
    },
  });

  return <CardMedia key={props.id} component="img" css={imageCSS} srcSet={sourceSet} src={url} />;
};

export const renderVideo = (props: RenderProps) => {
  const url = props.downloadUrl ?? undefined;

  return (
    <CardMedia
      key={props.id}
      controls
      component="video"
      preload="metadata"
      css={videoCSS}
      src={url}
    >
      <source srcSet={url} type={props.mimetype} />
      <img src={url} alt="Video" />
    </CardMedia>
  );
};
