/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useRef, useState } from 'react';

interface Options {
  /**
   * Activate all the strict validations.
   * Check that all the content is being displayed in the viewport (from top-left to bottom-right).
   * @default false
   */
  strict?: boolean;

  /**
   * Only updates the visibility once the targe is on the screen.
   * @default true
   */
  once?: boolean;
}

const STRICT_THRESHOLD = 1.0;
const FALLBACK_THRESHOLD = 0.2;

/** Use the [Intersection Object API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API) to check the visibility of an element on the viewport. */
export function useIntersectionWatcher<E extends HTMLElement>(options?: Options) {
  const [visible, setVisible] = useState(false);
  const [haveBeenShown, setHaveBeenShown] = useState(false);

  const ref = useRef<E>(null);

  const isStrict = options?.strict ?? false;
  const isOnce = options?.once ?? true;

  const check = (entries: IntersectionObserverEntry[]) => {
    const [first] = entries;

    setVisible(first.isIntersecting);
    setHaveBeenShown(prev => prev || first.isIntersecting);
  };

  const observer = new IntersectionObserver(check, {
    threshold: isStrict ? STRICT_THRESHOLD : FALLBACK_THRESHOLD,
  });

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isOnce && visible) {
      // Allow to only check once is visible
      observer.unobserve(ref.current);
      return;
    }

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [visible, ref.current]);

  return {
    ref,
    visible,
    haveBeenShown,
  };
}
