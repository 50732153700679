/* eslint-disable consistent-return */
import { useQuery } from 'react-query';
import { ForeignIDTimes } from 'getstream';

import { StreamChatEnrichedActivity } from 'shared/types';
import { Maybe } from 'shared/graphql';
import { useStreamChat } from 'providers/StreamChatProvider';
import { sendToSentry } from 'shared/utils/sentry';

interface Post {
  id?: Maybe<string>;
  createdAt?: Maybe<any>;
}

interface Options {
  post: Post;
  skip?: boolean;
}

export const HOME_FEED_ITEM_ACTIVITY_QUERY_PREFIX_KEY = 'streamActivity';

/** Gets the `StreamChatEnrichedActivity` from `HomeFeedItemFragment`. */
export function useHomeFeedItemActivity(options: Options) {
  const { streamChatClient: client } = useStreamChat();

  const {
    data,
    isLoading: loading,
    refetch: refresh,
  } = useQuery({
    queryKey: [HOME_FEED_ITEM_ACTIVITY_QUERY_PREFIX_KEY, options.post.id],
    enabled: !options.skip || !client,
    onError: sendToSentry,

    async queryFn() {
      if (!options.post.id || !client) {
        return;
      }

      const foreign: ForeignIDTimes = {
        foreign_id: options.post.id,
        time: options.post.createdAt,
      };

      const response = await client.getActivities({
        reactions: { counts: true, recent: true, own: true },
        foreignIDTimes: [foreign],
      });

      const activities = (response?.results ?? []) as StreamChatEnrichedActivity[];
      const activity = activities.find(activity => activity.foreign_id === options.post.id);

      return activity;
    },
  });

  return {
    data,
    loading,
    refresh,
  };
}
