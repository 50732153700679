import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';

import { Box } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { useUserById } from 'shared/hooks/useUserById';
import { buildUrl } from 'shared/routes/routerUtils';

import { MemberSidebarList } from './MemberSidebarList';
import { MemberSidebarUserProfile } from './MemberSidebarUserProfile';

const containerCss = (theme: any) => css`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${theme.palette.border};
`;

export const MemberInformationSidebar = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { user } = useUserById({ id: userId });

  const SIDEBAR_LIST_DATA = React.useMemo(() => {
    return [
      {
        title: 'Account Information',
        icon: 'AccountCircle',
        route: userId
          ? buildUrl(APP_URL.admin.members.information, { pathParams: { id: userId } })
          : '',
      },
    ];
  }, [userId]);

  return (
    <Box css={containerCss}>
      <MemberSidebarUserProfile user={user} />
      <MemberSidebarList listData={SIDEBAR_LIST_DATA} />
    </Box>
  );
};
