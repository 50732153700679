import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { SearchProvider } from 'shared/features/search';
import { Box, Route, RouteLayout, Typography } from 'shared/components/ui';
import { ROUTES } from 'shared/constants';

import { CustomizationContent } from '../components/Customization';
import { Roles } from '../components/Roles';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { SiteAdmins } from '../components/SiteAdmins/SiteAdmins';
import { SiteSettings } from '../components/SiteSettings/SiteSettings';
import { TopBarSettings } from '../components/TopBar/TopBarSettings';
import { MainNavigationWrapper } from '../components/MainNavigation/MainNavigationWrapper/MainNavigationWrapper';
import { SettingsSchoolContentModeration } from '../components';

const routeLayoutCss = css`
  width: 100%;
  padding: 2rem;
`;

const boxCss = css`
  max-width: 100%;
`;

const titleCss = css`
  margin-bottom: 30px;
`;

const contentCss = css`
  display: grid;
  grid-template-columns: 1fr 4fr;
  min-height: 85vh;
`;

export function SettingsPage() {
  const location = useLocation();
  const isRootRoute = location.pathname === ROUTES.admin.settings.index;

  return (
    <RouteLayout css={routeLayoutCss}>
      <Box display="flex" flexDirection="column" flexGrow={1} css={boxCss}>
        <Typography css={titleCss} variant="subtitle2" color="primary" align="left">
          Settings
        </Typography>

        <Paper css={contentCss} elevation={1} square={false} variant="elevation">
          <SettingsSidebar />

          <Switch>
            <Route path={ROUTES.admin.settings.navigation} authAccess="any" exact>
              <MainNavigationWrapper />
            </Route>

            <Route path={ROUTES.admin.settings.siteSettings} authAccess="any" exact>
              <SiteSettings />
            </Route>

            <Route path={ROUTES.admin.settings.roles} authAccess="any" exact>
              <Roles />
            </Route>

            <Route path={ROUTES.admin.settings.siteAdmins} authAccess="any" exact>
              <SearchProvider>
                <SiteAdmins />
              </SearchProvider>
            </Route>

            <Route path={ROUTES.admin.settings.customization} authAccess="any" exact>
              <CustomizationContent />
            </Route>

            <Route path={ROUTES.admin.settings.news} authAccess="any" exact>
              <TopBarSettings />
            </Route>

            <Route path={ROUTES.admin.settings.contentModeration} authAccess="private" exact>
              <SettingsSchoolContentModeration />
            </Route>

            <Route path="*" authAccess="any">
              <Redirect to={ROUTES.admin.settings.siteSettings} />
            </Route>
          </Switch>

          {isRootRoute && <Redirect to={ROUTES.admin.settings.siteSettings} />}
        </Paper>
      </Box>
    </RouteLayout>
  );
}
