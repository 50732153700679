import { forwardRef } from 'react';
import styled from '@emotion/styled';
import {
  FormControlLabel,
  FormControlLabelProps,
  SwitchProps,
  FormHelperText,
} from '@material-ui/core';
import { Field, FieldProps, FieldValidator } from 'formik';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';
import { SwitchToggle } from '../SwitchToggle';

export type FormSwitchProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  switchProps: Omit<FormControlLabelProps, 'control'> & SwitchProps;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
};

const FormSwitchLabel = styled(FormControlLabel)`
  display: inline-flex;
  gap: 0.5rem;
  margin: 0;
`;

export const FormSwitch = forwardRef<HTMLDivElement, FormSwitchProps>(
  (
    {
      switchProps,
      fieldProps,
      labelProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const { disabled, label, labelPlacement = 'end', ...otherSwitchProps } = switchProps ?? {};

    return (
      <Field name={fieldProps.name} validate={fieldProps.validate}>
        {({ field, meta, form }: FieldProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid && meta.error;

          return (
            <div
              ref={ref}
              data-test={dataTest}
              data-node-id={dataNodeID}
              data-node-render-path={dataRenderPath}
            >
              <FormSwitchLabel
                disabled={disabled}
                label={label}
                labelPlacement={labelPlacement}
                {...labelProps}
                control={
                  <SwitchToggle
                    {...otherSwitchProps}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                }
              />

              {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
            </div>
          );
        }}
      </Field>
    );
  },
);
