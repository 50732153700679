import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  EventStatusUpdateMutation,
  EventStatusUpdateMutationVariables,
  EventDeleteMutation,
  EventDeleteMutationVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { EVENT_STATUS_UPDATE, EVENT_DELETE_MUTATION } from '../queries';

export const useEventStatusHandle = () => {
  const [changeEventStatus] = useMutation<
    EventStatusUpdateMutation,
    EventStatusUpdateMutationVariables
  >(EVENT_STATUS_UPDATE, {
    refetchQueries: ['EventsList', 'EventsCount'],
  });

  const onChangeEventStatus = React.useCallback(
    async (data: EventStatusUpdateMutationVariables['data']) => {
      try {
        await changeEventStatus({
          variables: {
            data,
          },
          context: data.isApproved
            ? {
                [SNACKBAR_SUCCESS_MESSAGE]: 'The event has been approved and published.',
                [SNACKBAR_ERROR_MESSAGE]: `The event hasn't been approved and published. Try again later.`,
              }
            : {
                [SNACKBAR_SUCCESS_MESSAGE]:
                  'The event has been rejected. The creator will be notified via email.',
                [SNACKBAR_ERROR_MESSAGE]: `The event hasn't been rejected. Try again later.`,
              },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [changeEventStatus],
  );
  return { onChangeEventStatus };
};

export const useEventDelete = () => {
  const [eventDelete] = useMutation<EventDeleteMutation, EventDeleteMutationVariables>(
    EVENT_DELETE_MUTATION,
    {
      refetchQueries: ['EventsCount', 'EventsList'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The event has been deleted.',
        [SNACKBAR_ERROR_MESSAGE]: `Error: The event hasn't been deleted. Try again later.`,
      },
    },
  );

  const onEventDelete = React.useCallback(
    (data: EventDeleteMutationVariables['data']) => {
      return eventDelete({
        variables: {
          data,
        },
      });
    },
    [eventDelete],
  );
  return { onEventDelete };
};
