import { RouteLayout } from 'shared/components/layouts';
import { SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';

import { ClassScorecardContent } from '../components/ClassScorecard';

export function ClassScorecardPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={<ClassScorecardContent />}
      sidebar={<CommunitySidebar items={[SUPPORTERS]} />}
    />
  );
}
