import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';

// Container

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// ZipCode

export const ZipCode = styled(TextField)`
  .MuiInputBase-input {
    font-size: 0.8rem;
    padding: 0.25rem 0.35rem;
    max-width: 4rem;
  }
`;

ZipCode.defaultProps = {
  placeholder: 'ZIP CODE',
  variant: 'outlined',
  inputProps: {
    minlength: 1,
    maxlength: 8,
  },
};
