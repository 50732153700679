import { IconButton, List, ListItem } from '@material-ui/core';
import { Edit, PlaylistRemove, MoreVert } from '@mui/icons-material';

import { formatExperienceRangeDates } from 'shared/utils/date';
import { useResponsive } from 'shared/hooks';

import { ExperienceCardActions, ExperienceCardData } from '../types';
import { Buttons, Container, Content, DateRange, Title } from './ExperienceCardContent.styles';
import { PopoverV2 } from '../../Popover';

interface Props extends ExperienceCardActions {
  data: ExperienceCardData;
}

export function ExperienceCardContent(props: Props) {
  const { isMobile } = useResponsive();

  const title = props.data.positionTitle;
  const allowEdit = Boolean(props.allowEdit);
  const allowRemove = Boolean(props.allowRemove);

  const showDropdownOptions = !isMobile;

  const dateRange = formatExperienceRangeDates(props.data.startDate, props.data.endDate, {
    isCurrent: Boolean(props.data.isCurrentlyWorking),
  });

  const onEdit = () => {
    if (allowEdit) {
      props.onEdit?.(props.data);
    }
  };

  const onRemove = () => {
    props.onRemove?.(props.data);
  };

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <DateRange>{dateRange}</DateRange>
      </Content>

      {showDropdownOptions && (
        <Buttons>
          {allowEdit && (
            <IconButton title="Edit this experience" color="secondary" onClick={onEdit}>
              <Edit />
            </IconButton>
          )}

          {allowRemove && (
            <IconButton title="Remove this experience" color="secondary" onClick={onRemove}>
              <PlaylistRemove />
            </IconButton>
          )}
        </Buttons>
      )}

      {!showDropdownOptions && (
        <PopoverV2
          triggerType="icon-button"
          tripperProps={{ children: <MoreVert /> }}
          popoverProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
        >
          <List>
            {allowEdit && (
              <ListItem button onClick={onEdit}>
                Edit
              </ListItem>
            )}

            {allowRemove && (
              <ListItem button onClick={onRemove}>
                Remove
              </ListItem>
            )}
          </List>
        </PopoverV2>
      )}
    </Container>
  );
}
