import { AvatarGroup, Skeleton } from '@mui/material';

import { GROUP_POST_TYPE, LEGACY_POST_TYPE, SCHOOL_POST_TYPE } from '@jebel/constants';

import { useHomeFeedCardData } from 'shared/features/posts/providers';
import { useGroupDetails } from 'features/groups/hooks';
import { GroupAvatar, SchoolAvatar, UserAvatar } from 'shared/components/symbols';

import {
  Container,
  Content,
  PostDate,
  AuthorName,
  AuthorAvatarSkeleton,
  GroupName,
  SchoolName,
  GroupAuthorName,
  GroupDetailsContainer,
} from './HomeFeedCardAuthor.styles';
import { useHomeFeedAuthor } from '../useHomeFeedAuthor';
import { useHomeFeedCreationAgo } from '../useHomeFeedCreationAgo';

export function HomeFeedCardAuthor() {
  const { type, groupId } = useHomeFeedCardData();
  const { data: author, loading: userLoading } = useHomeFeedAuthor();

  /**
   * Checks whether the current post is authored to the school.
   * @url https://8base-dev.atlassian.net/browse/JEB-1534
   */
  const isSchoolAuthored = type === SCHOOL_POST_TYPE || type === LEGACY_POST_TYPE;

  /**
   * Checks whether the current post is authored to the school.
   * @url https://8base-dev.atlassian.net/browse/JEB-1534
   */
  const isGroupPost = type === GROUP_POST_TYPE;

  const { data: group, loading: groupLoading } = useGroupDetails({
    id: groupId,
    skip: !isGroupPost,
  });

  const loading = userLoading || groupLoading;

  const creationDate = useHomeFeedCreationAgo();

  if (loading) {
    return (
      <Container>
        <AuthorAvatarSkeleton />

        <Content>
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="4rem" />
        </Content>
      </Container>
    );
  }

  if (type === GROUP_POST_TYPE) {
    return (
      <Container>
        <AvatarGroup max={1}>
          <UserAvatar user={author} />
          <GroupAvatar group={group} />
        </AvatarGroup>

        <Content>
          <GroupName group={group} />

          <GroupDetailsContainer>
            <GroupAuthorName user={author} />
            <PostDate>{creationDate}</PostDate>
          </GroupDetailsContainer>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      {isSchoolAuthored ? <SchoolAvatar /> : <UserAvatar user={author} />}

      <Content>
        {isSchoolAuthored ? <SchoolName /> : <AuthorName user={author} />}
        <PostDate>{creationDate}</PostDate>
      </Content>
    </Container>
  );
}
