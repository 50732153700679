import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { GroupMembersList } from '../GroupMembersList';

interface Props {
  /** ID of the group. */
  id: string;

  show: boolean;

  /**
   * Whether to show only active members.
   * @default false
   */
  onlyActive?: boolean;

  onClose(): void;
}

export function GroupDetailsMembersModal(props: Props) {
  return (
    <Dialog maxWidth="sm" fullWidth open={props.show} onClose={props.onClose}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pr={2}>
        <DialogTitle>Members</DialogTitle>

        <IconButton onClick={props.onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent dividers>
        <GroupMembersList group={{ id: props.id }} onlyActive={props.onlyActive} />
      </DialogContent>
    </Dialog>
  );
}
