import { MutationHookOptions } from '@apollo/client';

import {
  useInviteBusinessManagersMutation,
  UserRequestAccessInput,
  useSendUserInvitesMutation,
} from 'shared/graphql';

import { useCurrentUser } from './useCurrentUser';

type Options = Omit<MutationHookOptions, 'variables'>;

export function useUserInvitation(options?: Options) {
  const { isAdmin } = useCurrentUser();

  const [sendInvitations, { loading: isSendingInvites }] = useSendUserInvitesMutation(options);

  const [inviteBusinessManagersMutation, { loading: isInvitingBusinessManager }] =
    useInviteBusinessManagersMutation(options);

  const isInviting = isSendingInvites || isInvitingBusinessManager;

  return {
    isInviting,

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteUsers(users: UserRequestAccessInput[], isAdminInviting = false) {
      await sendInvitations({ variables: { users, isAdminInviting } });
    },

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteAdmins(users: UserRequestAccessInput[]) {
      await sendInvitations({
        variables: { users, isAdminInviting: isAdmin, withAdminRole: true },
      });
    },

    /** Invite users as business managers to a given `business` and approve their incorporation. */
    async inviteBusinessManagers(usersData: UserRequestAccessInput[], businessId: string) {
      return inviteBusinessManagersMutation({
        variables: {
          usersData,
          businessId,
        },
      });
    },

    /** Invite users as business administrators and approve their incorporation. */
    async inviteBusinessAdmins(users: UserRequestAccessInput[]) {
      await sendInvitations({
        variables: { users, withBusinessAdminRole: true, isAdminInviting: isAdmin },
      });
    },
  };
}
