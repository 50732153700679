import { gql } from '@apollo/client';

import { CAREER_INFO_FRAGMENT } from 'shared/features/search/queries';

export const JOB_OFFER_UPDATE_MUTATION = gql`
  mutation JobOfferUpdate($data: JobOfferUpdateInput!) {
    jobOfferUpdate(data: $data) {
      id
      organization {
        id
        name
      }
      position
      description
      employmentType
      interestedUsers {
        items {
          id
          jobsOfInterest {
            items {
              ...CareerInfo
            }
          }
        }
      }
    }
  }
  ${CAREER_INFO_FRAGMENT}
`;

export const JOB_OFFER_CREATE_MUTATION = gql`
  mutation JobOfferCreate($data: JobOfferCreateInput!) {
    jobOfferCreate(data: $data) {
      id
    }
  }
`;

export const ORGANIZATION_SEARCH_QUERY = gql`
  query OrganizationSearch($filter: OrganizationFilter!) {
    organizationsList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

export const JOB_OFFER_DELETE_MUTATION = gql`
  mutation JobOfferDelete($filter: JobOfferKeyFilter!) {
    jobOfferDelete(filter: $filter) {
      success
    }
  }
`;

export const JOB_OFFER_RENEW_MUTATION = gql`
  mutation JobOfferRenew($id: ID!) {
    renewJobOffer(id: $id) {
      success
    }
  }
`;
