import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  JobOfferUpdateMutation,
  JobOfferUpdateMutationVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { JOB_OFFER_UPDATE_MUTATION } from '../queries';

export const useJobPostEdit = () => {
  const [jobPostEdit] = useMutation<JobOfferUpdateMutation, JobOfferUpdateMutationVariables>(
    JOB_OFFER_UPDATE_MUTATION,
    {
      refetchQueries: [
        'CareerSearch',
        'CareersListCount',
        'CareersFilterByZipRequest',
        'InternshipsFilterByZipRequest',
      ],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Your Job Post has been updated',
        [SNACKBAR_ERROR_MESSAGE]: `Your Job Post hasn't been updated`,
      },
    },
  );
  const onJobPostEdit = React.useCallback(
    async (data: any, jobOfferId?: string | null) => {
      try {
        await jobPostEdit({
          variables: {
            data: {
              ...data,
              id: jobOfferId,
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [jobPostEdit],
  );
  return { onJobPostEdit };
};
