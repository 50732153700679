import { Theme, css } from '@emotion/react';
import { MenuItem, Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Avatar } from '@mui/material';
import { useMemo } from 'react';
import { School } from '@material-ui/icons';

import { DATE_YEAR_FORMAT } from '@jebel/constants';

import {
  Typography,
  Form,
  FormTextField,
  FormSelect,
  Button,
  ImageInput,
  DatePickerField,
  AutocompleteField,
} from 'shared/components/ui';
import { US_STATES_OPTIONS } from 'shared/constants';
import { required, isValidGPA } from 'shared/utils/form';
import { useResponsive } from 'shared/hooks';
import { FormSelectSchool } from 'shared/components/form';
import { Address, HighSchoolFragment, Maybe } from 'shared/graphql';
import { ResultFile } from 'shared/types/files';

const headerCSS = css`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`;

const uploadImageCSS = (theme: Theme) => css`
  --image-height: 8rem;

  height: var(--image-height);

  ${theme.breakpoints.up('sm')} {
    width: var(--image-height);
  }

  ${theme.breakpoints.down('sm')} {
    --image-height: 8rem;

    width: 100%;
  }
`;

const uploadContentCSS = (theme: Theme) => css`
  ${uploadImageCSS(theme)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.background.paper};
  border: 1px dashed ${theme.palette.divider};
  border-radius: 0.25rem;

  &:hover {
    background-color: ${theme.palette.background.light};
  }
`;

const uploadedLogoCSS = (theme: Theme) => css`
  ${uploadImageCSS(theme)};

  border-radius: 6px;
  border: 1px solid ${theme.palette.divider};

  img {
    object-fit: contain;
  }
`;

export type UserManageEducationValues = {
  degree?: Maybe<string>;
  fieldOfStudy?: Maybe<string>;
  gpa?: Maybe<string>;
  activities?: Maybe<string>;
  description?: Maybe<string>;
  startDate?: Maybe<string | DateTime>;
  endDate?: Maybe<string | DateTime>;
  highSchool?: HighSchoolFragment | string;
  image?: Maybe<ResultFile>;
  address?: Maybe<Address>;
};

interface Props {
  onCancel: () => void;
  onSave: (formData: UserManageEducationValues) => void;
  initialValues?: UserManageEducationValues;
  isEdit?: boolean;
}

const INITIAL_START_DATE = DateTime.now().startOf('year');
const INITIAL_END_DATE = DateTime.now().startOf('year');

export function UserManageEducationForm(props: Props) {
  const { isMobile } = useResponsive();

  const inputsGap = '1rem';
  const editOrAdd = props.isEdit ? 'EDIT' : 'ADD';

  const initial: UserManageEducationValues = useMemo(() => {
    const payload: UserManageEducationValues = {
      startDate: INITIAL_START_DATE,
      endDate: INITIAL_END_DATE,

      ...props.initialValues,
    };

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  return (
    <Box pt={!isMobile && 2}>
      <Form<UserManageEducationValues> onSubmit={props.onSave} initialValues={initial}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Box display="grid" gridTemplateRows="auto" gridGap="1rem">
            <Box css={headerCSS}>
              <Box display="grid" gridTemplateRows="min-content min-content" gridGap={inputsGap}>
                <FormSelectSchool
                  name="highSchool"
                  label="School"
                  placeholder="Choose or add the school name"
                  variant="outlined"
                  allowUnknown="allow"
                  validate={required}
                />

                <FormTextField
                  inputProps={{
                    label: 'City',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'address.city', validate: required }}
                />
              </Box>

              {!isMobile && (
                <Box display="flex" flexDirection="column" justifyContent="start">
                  <ImageInput
                    name="image"
                    maxFiles={1}
                    initialValue={initial.image}
                    onChange={file => setFieldValue('image', file)}
                    onDelete={() => setFieldValue('image', undefined)}
                    customPicker={
                      <Box css={uploadContentCSS}>
                        <School />

                        <Typography align="center" variant="body2">
                          Add Image
                        </Typography>
                      </Box>
                    }
                    customPreview={
                      <Avatar
                        css={uploadedLogoCSS}
                        src={values.image?.downloadUrl ?? undefined}
                        variant="square"
                      />
                    }
                  />
                </Box>
              )}
            </Box>

            <Box
              display="grid"
              gridTemplateRows={isMobile && 'auto auto'}
              gridTemplateColumns={!isMobile && '1fr 1fr'}
              gridGap={inputsGap}
            >
              <AutocompleteField
                label="State"
                name="address.state"
                options={US_STATES_OPTIONS}
                required
              />

              <FormTextField
                inputProps={{
                  label: 'ZIP Code',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'address.zip', validate: required }}
              />
            </Box>

            <FormTextField
              inputProps={{
                label: 'Degree',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'degree', validate: required }}
            />
            <FormTextField
              inputProps={{
                label: 'Field of Study',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'fieldOfStudy', validate: required }}
            />

            <FormTextField
              inputProps={{
                label: 'GPA',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'gpa', validate: isValidGPA }}
            />

            <FormTextField
              inputProps={{
                label: 'Activities and societies',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'activities' }}
            />

            <FormTextField
              inputProps={{
                color: 'primary',
                label: 'Description',
                multiline: true,
                rows: '3',
                rowsMax: '3',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'description' }}
            />
            <Box
              display="grid"
              gridTemplateRows={isMobile && 'auto auto'}
              gridTemplateColumns={!isMobile && 'auto auto'}
              gridGap={inputsGap}
            >
              <DatePickerField
                name="startDate"
                inputVariant="outlined"
                label="START YEAR"
                views={['year']}
                format={DATE_YEAR_FORMAT}
                validate={required}
              />

              <DatePickerField
                name="endDate"
                inputVariant="outlined"
                label="END YEAR (OR EXPECTED)"
                views={['year']}
                format={DATE_YEAR_FORMAT}
                validate={required}
              />

              {isMobile && (
                <ImageInput
                  name="image"
                  maxFiles={1}
                  initialValue={initial.image}
                  onChange={file => setFieldValue('image', file)}
                  onDelete={() => setFieldValue('image', undefined)}
                  customPicker={
                    <Box css={uploadContentCSS}>
                      <School />

                      <Typography align="center" variant="body2">
                        Add Image
                      </Typography>
                    </Box>
                  }
                  customPreview={
                    <Avatar
                      css={uploadedLogoCSS}
                      src={values.image?.downloadUrl ?? undefined}
                      variant="square"
                    />
                  }
                />
              )}
            </Box>

            <Box display="flex" justifyContent="flex-end" alignItems="center" gridGap={5}>
              <Button
                onClick={props.onCancel}
                color="primary"
                disabled={isSubmitting}
                size="medium"
              >
                Cancel
              </Button>

              <Button
                loading={isSubmitting}
                color="primary"
                size="small"
                disableElevation
                variant="contained"
                type="submit"
              >
                {editOrAdd} EDUCATION
              </Button>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
}
