import React from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';

import { DiscountsContent } from '../components/DiscountsContent';

export const DiscountsPage: React.FC = () => {
  return (
    <RouteLayout
      content={
        <SearchProvider>
          <DiscountsContent />
        </SearchProvider>
      }
    />
  );
};
