import styled from '@emotion/styled';
import { Avatar, Typography } from '@material-ui/core';
import { School } from '@material-ui/icons';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
  overflow-x: hidden;
  user-select: none;
`;

// Divided

export const Divided = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// Logo

export const Logo = styled(Avatar)`
  --size: 4rem;

  width: var(--size);
  height: var(--size);
  cursor: pointer;
`;

Logo.defaultProps = {
  variant: 'square',
};

// SupporterLogo

export const SupporterLogo = styled(Avatar)`
  --size: 1.25rem;

  width: var(--size);
  height: var(--size);
  font-size: calc(var(--size) * 0.6);
`;

SupporterLogo.defaultProps = {
  children: <School fontSize="inherit" />,
};

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 0.25rem;
  flex: 1;
`;

// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

// HeaderBanner

export const HeaderBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// Title

export const Title = styled(Typography)`
  max-width: 40vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
  overflow: hidden;
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
  line-height: normal;
  cursor: pointer;
`;

// Location

export const Location = styled(Typography)`
  color: ${p => p.theme.palette.grey[500]};
`;

Location.defaultProps = {
  variant: 'body2',
};

// Count

export const Count = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;

// Description

export const Description = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
