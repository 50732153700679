import { RouteLayout } from 'shared/components/layouts';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { SearchProvider } from 'shared/features/search';
import { useResponsive } from 'shared/hooks';

import { CollectiveIntellegenceContent } from '../components/CollectiveIntelligence';

export function CollectiveIntelligencePage() {
  const { isTablet } = useResponsive();

  return (
    <SearchProvider>
      <RouteLayout
        withMaxWidth
        content={<CollectiveIntellegenceContent />}
        sidebar={!isTablet && <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />}
      />
    </SearchProvider>
  );
}
