import { useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@emotion/react';

export function useResponsive() {
  const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const isLargeDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.down('xl'));

  const isBetweenMobile = useMediaQuery<Theme>(theme => theme.breakpoints.between('xs', 'sm'));
  const isBetweenTablet = useMediaQuery<Theme>(theme => theme.breakpoints.between('sm', 'md'));
  const isBetweenDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.between('md', 'lg'));

  const isAboveMobile = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
  const isAboveTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
  const isAboveDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const isAboveLargeDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('xl'));

  const getSizes = useCallback(
    (initial: any, { xs, sm }: { sm?: any; xs?: any }) => {
      if (isSmallMobile) {
        return typeof xs !== 'undefined' ? xs : sm;
      }

      if (isMobile) {
        return typeof sm !== 'undefined' ? sm : initial;
      }

      return initial;
    },
    [isMobile, isSmallMobile],
  );

  return {
    isBetweenMobile,
    isBetweenDesktop,
    isBetweenTablet,
    isMobile,
    isSmallMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isAboveMobile,
    isAboveTablet,
    isAboveDesktop,
    isAboveLargeDesktop,
    getSizes,
  };
}
