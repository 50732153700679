import { POST_REPORT_STATUS } from '@jebel/constants';
import { fileManyRelations, formatSQLDate } from '@jebel/utils';

import { PostModalCreate, PostModalCreateForm } from 'shared/features/posts';
import { prepareMentionsCreateInput } from 'shared/features/mentions/utils';
import { LegacyFeedPostCreateInput } from 'shared/graphql';
import { useToast } from 'shared/hooks';

import { useLegacyFeedPostCreate } from '../../hooks';

interface Props {
  initials?: PostModalCreateForm;

  isOpen: boolean;
  onClose: () => void;
}

export function LegacyFeedPostModalCreate(props: Props) {
  const { showError } = useToast();
  const { create: onPostCreate } = useLegacyFeedPostCreate();

  const handleSubmit = async (form: PostModalCreateForm) => {
    const text = form.text?.trim();

    if (!text) {
      showError('Should have a text', { reportable: false });
      return;
    }

    if (!form.media || form.media.length === 0) {
      showError('Should have a media', { reportable: false });
      return;
    }

    if (!form.legacyDate) {
      showError('Should have a legacy date', { reportable: false });
      return;
    }

    const media = fileManyRelations(form.media);

    const payload: LegacyFeedPostCreateInput = {
      text,
      media,
      legacyDate: formatSQLDate(form.legacyDate),
      isPinned: form?.isPinned,
      commentsAllowed: form?.commentsAllowed,
      postDate: form.postDate,
      reportStatus: POST_REPORT_STATUS.pendingReview,
    };

    if (form.mentions) {
      const createMentions = form.mentions?.map(prepareMentionsCreateInput);
      payload.mentions = { create: createMentions };
    }

    await onPostCreate(payload);
    props.onClose();
  };

  return (
    <PostModalCreate
      initialValues={props.initials}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPostCreate={handleSubmit}
      withLegacyDate
      withSchedule
    />
  );
}
