import { useMemo } from 'react';

import { SCHOOL_POST_TYPE } from '@jebel/constants';

import { PAGE_SIZE } from 'shared/constants';
import { HomeFeedItemFilter } from 'shared/graphql';
import { useCurrentUser, useGetUserGroups, usePostsFeed } from 'shared/hooks';
import { createFilterBuilder } from 'shared/queries/filterBuilder';

interface Props {
  skip?: boolean;
  searchFirst?: number;
  searchQuery?: string;
  /** @deprecated Use `searchQuery` instead. */
  formatSearch?: string;
  filter?: HomeFeedItemFilter;

  /**
   * Allow to auto-refresh the content every 2 minutes.
   * @default false
   */
  withSubscription?: boolean;
}

/** Refresh the content every 2 minutes. */
const SUBSCRIPTION_INTERVAL = 2 * 60 * 1000;

/** List all the home feed posts. */
export function useHomeFeed(props?: Props) {
  const { userId } = useCurrentUser();
  const { groupData } = useGetUserGroups(userId as string);

  const joinedGroups = useMemo(() => {
    return groupData?.user?.joinedGroups?.items ?? [];
  }, [groupData]);

  const pollInterval = props?.withSubscription ? SUBSCRIPTION_INTERVAL : undefined;
  const search = props?.searchQuery ?? props?.formatSearch;

  const filter = useMemo<HomeFeedItemFilter>(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>();

    if (props?.filter) {
      filter.and(props.filter);
    }

    if (joinedGroups.length > 0) {
      const joinedGroupsID = joinedGroups.map(item => item.id);

      filter.and({
        OR: [{ groupId: { equals: null } }, { groupId: { in: joinedGroupsID } }],
      });
    }

    if (joinedGroups.length === 0) {
      filter.and({ groupId: { equals: null } });
    }

    if (search) {
      const searchFilter = createFilterBuilder<HomeFeedItemFilter>();
      const words = search.split(/\s+/i);

      searchFilter.or({ text: { contains: search } });
      searchFilter.or({ authorEmail: { contains: search } });
      searchFilter.or({ authorFirstName: { contains: search } });
      searchFilter.or({ authorLastName: { contains: search } });
      searchFilter.or({ authorFullName: { starts_with: search } });

      for (const word of words) {
        searchFilter.or({ text: { contains: word } });
      }

      filter.and(searchFilter);
    }

    return filter.build();
  }, [props?.filter, search, joinedGroups]);

  const { data, count, loading, refreshing, refetch, fetchMore } = usePostsFeed({
    pollInterval,

    withPinned: [SCHOOL_POST_TYPE],

    variables: {
      filter,
      first: props?.searchFirst ?? PAGE_SIZE,
    },
  });

  return {
    data,
    count,
    hasMore: data.length < count,
    loading,
    refreshing,

    refetch,
    fetchMore,
  };
}
