import { JobOfferPresentationFragment, useCurrentUserSavedJobOffersQuery } from 'shared/graphql';

import { useJobOfferExpirationDate } from './useJobOfferExpirationDate';

/** List all the current user's saved job offers. */
export function useCurrentUserSavedOffers() {
  const nonExpiredJobsDate = useJobOfferExpirationDate();

  const { data: response, loading } = useCurrentUserSavedJobOffersQuery({
    variables: {
      // https://8base-dev.atlassian.net/browse/JEB-1303
      filter: { postDate: { gte: nonExpiredJobsDate } },
    },
  });

  const data: JobOfferPresentationFragment[] = response?.user?.preferences?.saved?.items ?? [];

  return {
    data,
    loading,
  };
}
