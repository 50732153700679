import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type SetQueryParamsOptions = {
  shouldReplace?: boolean;
};

export type SetQueryParamsHandler<T> = (
  queryParams: Partial<T>,
  options?: SetQueryParamsOptions,
) => void;

export interface Options<T> {
  initialValues?: T;
}

type Params = Record<string, string>;

export function useQueryParams<T = Params>(options?: Options<T>) {
  const { push: navigate } = useHistory();
  const { search, state } = useLocation();

  const searchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const params = useMemo(() => {
    const entries = searchParams.entries();
    const values: unknown = Object.fromEntries(entries);

    return values as T;
  }, [searchParams]);

  useEffect(() => {
    if (options?.initialValues) {
      setParams(options.initialValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.initialValues]);

  const pushParams = useCallback(
    (params: URLSearchParams) => {
      navigate({ pathname: window.location.pathname, search: `${params}` }, state);
    },
    [navigate, state],
  );

  /** Sets the given `name` on the search params. */
  const setParam = useCallback(
    <K extends keyof T>(name: K, value: T[K]) => {
      const params = new URLSearchParams(searchParams);

      params.set(name as string, String(value));
      pushParams(params);
    },
    [pushParams, searchParams],
  );

  /** Sets multiple values at the same time on the search params. */
  const setParams = useCallback(
    (values: Partial<T>, options?: { replacing?: boolean }) => {
      let params = new URLSearchParams(searchParams);
      const keys = Object.keys(values);

      if (options?.replacing) {
        params = new URLSearchParams();
      }

      for (const name of keys) {
        const value = values[name];

        if (value === undefined) {
          params.delete(name);
          continue;
        }

        params.set(name, value);
      }

      pushParams(params);
    },
    [pushParams, searchParams],
  );

  /** @deprecated Use `setParams` instead. */
  const setQueryParams = useCallback(
    (value: Partial<T>, options?: SetQueryParamsOptions) => {
      setParams(value, { replacing: options?.shouldReplace });
    },
    [setParams],
  );

  /** Removes the given `name` from the search params. */
  const removeParam = useCallback(
    (name: keyof T) => {
      const params = new URLSearchParams(searchParams);

      params.delete(name as string);
      pushParams(params);
    },
    [pushParams, searchParams],
  );

  return [
    params,
    {
      /** @deprecated Use `setParams` instead. */
      setQueryParams,
      setParams,
      setParam,
      removeParam,
    },
  ] as const;
}
