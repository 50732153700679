/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { makeStyles, Theme } from '@material-ui/core';
import { useLocation, matchPath } from 'react-router-dom';

import { Button, Modal, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { SENT_ROUTE, TRASH_ROUTE, INBOX_ROUTE } from 'features/inbox/constants';
import { useClearTrash } from 'features/inbox/hooks/useClearTrash';
import { PaperLayout } from 'shared/components/layouts';
import { usePageTitle } from 'shared/hooks';

import { InboxChats } from '../InboxChats';
import { InboxCreateMessageModal } from '../InboxCreate/InboxCreateMessageModal';
import { InboxSidebar } from './InboxSidebar';
import { InboxMessages } from '../InboxMessages';

const containerCss = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  flex: '1',
  marginBottom: '32px',
};

const createMessageButtonCss = theme => css`
  background-color: ${theme.palette.primary.light};
  height: 40px;
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 17px;
`;

const deleteButtonCss = theme => css`
  background: ${theme.palette.secondary.light};
  margin-left: ${theme.spacing(2)}px;

  &:hover {
    background: ${theme.palette.secondary.light};
    opacity: 1;
  }
`;

const useStyles = makeStyles<Theme>(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: '24px 8px',
        borderRadius: 0,
      },

      '& .MuiDialog-container .MuiDialogTitle-root': {
        fontSize: '18px',
      },
    },
  },
}));

export const MobileInboxContent = () => {
  const classes = useStyles();
  const { clearTrash, loading: clearLoading } = useClearTrash();
  const location = useLocation<any>();
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(
    location?.state?.isModalOpen || false,
  );
  const [isOpenDeleteModal, setIsOpenModal] = React.useState(false);
  const { onOpenDeleteModal, onCloseDeleteModal } = React.useMemo(() => {
    return {
      onOpenDeleteModal: () => setIsOpenModal(true),
      onCloseDeleteModal: () => setIsOpenModal(false),
    };
  }, []);

  const { onModalClose, onModalOpen } = React.useMemo(
    () => ({
      onModalClose: () => setIsCreateModalOpen(false),
      onModalOpen: () => setIsCreateModalOpen(true),
    }),
    [],
  );

  const pageTitle = usePageTitle({ pathname: APP_URL.user.mail.index, fallback: INBOX_ROUTE });

  const title = React.useMemo(() => {
    if (
      matchPath(location.pathname, {
        path: APP_URL.user.mail.sent,
      })
    ) {
      return SENT_ROUTE;
    }

    if (
      matchPath(location.pathname, {
        path: APP_URL.user.mail.trash,
      })
    ) {
      return TRASH_ROUTE;
    }

    return pageTitle;
  }, [location.pathname, pageTitle]);

  const onClearTrash = React.useCallback(async () => {
    await clearTrash();
    onCloseDeleteModal();
  }, [clearTrash, onCloseDeleteModal]);

  const renderMobileLayout = React.useCallback(() => {
    if (`${location.pathname}${location.search}` === APP_URL.user.mail.index) {
      return <InboxSidebar />;
    }

    if (
      `${location.pathname}${location.search}` === APP_URL.user.mail.inbox ||
      `${location.pathname}${location.search}` === APP_URL.user.mail.sent ||
      `${location.pathname}${location.search}` === APP_URL.user.mail.trash
    ) {
      return <InboxChats currentRoute={title} />;
    }

    return <InboxMessages currentRoute={title} />;
  }, [title, location]);

  return (
    <Box display="flex" flexDirection="column">
      <Modal
        dialogProps={{
          maxWidth: 'sm',
          fullWidth: true,
          open: isCreateModalOpen,
          onClose: onModalClose,
          className: classes.dialog,
        }}
        titleProps={{ title: 'New Message' }}
        isVisibleDivider
      >
        <InboxCreateMessageModal
          onModalClose={onModalClose}
          members={location?.state?.members || []}
          messageType={location?.state?.messageType || 'personal'}
        />
      </Modal>

      <Modal
        isVisibleDivider
        titleProps={{ title: 'Delete message - Confirmation' }}
        dialogProps={{
          open: isOpenDeleteModal,
          onClose: onCloseDeleteModal,
          className: classes.dialog,
          fullWidth: true,
        }}
      >
        <Typography variant="subtitle4" color="highlight">
          Are you sure you want to delete this message?
        </Typography>
        <Typography variant="subtitle5" color="textSecondary">
          This action can’t be undone.
        </Typography>
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Button onClick={onCloseDeleteModal} size="medium">
            GO BACK
          </Button>
          <Button
            css={deleteButtonCss}
            onClick={onClearTrash}
            size="medium"
            variant="contained"
            loading={clearLoading}
            color="primary"
          >
            YES, DELETE MESSAGE
          </Button>
        </Box>
      </Modal>

      <Box css={headerCss}>
        <Typography variant="subtitle2" color="primary">
          {title}
        </Typography>

        {title === TRASH_ROUTE ? (
          <Box display="flex" alignItems="center">
            <Button
              size="medium"
              variant="contained"
              css={deleteButtonCss}
              onClick={onOpenDeleteModal}
              color="primary"
            >
              Empty Trash
            </Button>
          </Box>
        ) : (
          <Button
            css={createMessageButtonCss}
            variant="contained"
            color="primary"
            onClick={onModalOpen}
          >
            Create message
          </Button>
        )}
      </Box>

      <PaperLayout sx={containerCss}>{renderMobileLayout()}</PaperLayout>
    </Box>
  );
};

const headerCss = theme => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacing(3.75)}px ${theme.spacing(3.125)}px;
  min-height: 80px;

  ${theme.breakpoints.down('sm')} {
    padding: 0 0 ${theme.spacing(3.125)}px;
  }
`;
