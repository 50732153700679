import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  z-index: 2;
`;

export const WrapperIcon = styled.div`
  width: 2rem;
`;
