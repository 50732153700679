import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 1rem;

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;
