import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { ActivityClubsUpdateMutation, ActivityClubsUpdateMutationVariables } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

import { ACTIVITY_CLUBS_UPDATE_MUTATION } from '../queries';

export function useActivityClubsUpdate() {
  const { userId } = useCurrentUser();
  const [activityClubsUpdate] = useMutation<
    ActivityClubsUpdateMutation,
    ActivityClubsUpdateMutationVariables
  >(ACTIVITY_CLUBS_UPDATE_MUTATION, {
    refetchQueries: ['CurrentUser'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your Clubs & Sports has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Your Clubs & Sports hasn't been updated. Try again later.`,
    },
  });

  const onActivityClubsUpdate = async (activityClubs: string[]) => {
    if (!userId) {
      return;
    }

    await activityClubsUpdate({
      variables: {
        data: {
          id: userId,
          activityClubs: activityClubs?.map(item => item ?? ''),
        },
      },
    });
  };

  return { onActivityClubsUpdate };
}
