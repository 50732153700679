import { useMemo } from 'react';
import { FormControl, TextFieldProps } from '@material-ui/core';
import {
  KeyboardDatePickerProps,
  KeyboardTimePickerProps,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { DATE_TABLE_FORMAT } from '@jebel/constants';
import {
  composeFieldValidators,
  currentDateTime,
  formatISO8601Date,
  parseDate,
  shouldBeFutureDateTimeField,
  shouldBePastDateTimeField,
} from '@jebel/utils';

import { getIsInvalid } from '../common';
import { Container } from './DateTimePickerField.styles';

type BaseProps = Omit<
  KeyboardDatePickerProps & KeyboardTimePickerProps & TextFieldProps,
  'value' | 'onChange' | 'renderInput' | 'views'
>;

export interface DateTimePickerFieldProps extends BaseProps {
  name: string;
  validate?: FieldValidator;

  timeLabel?: string;

  dateViews?: KeyboardDatePickerProps['views'];
  timeViews?: KeyboardTimePickerProps['views'];

  onChange?(value: unknown): void;
}

const DEFAULT_DATETIME = currentDateTime().startOf('day');

export function DateTimePickerField(props: DateTimePickerFieldProps) {
  const validate = useMemo(() => {
    return composeFieldValidators(
      props.disableFuture && shouldBePastDateTimeField,
      props.disablePast && shouldBeFutureDateTimeField,
      props.validate,
    );
  }, [props.disableFuture, props.disablePast, props.validate]);

  const [field, meta, helpers] = useField<DateTime | null>({
    name: props.name,
    validate,
  });

  const form = useFormikContext();
  const hasError = getIsInvalid({ meta, form });
  const message = hasError ? meta.error : undefined;
  const format = props.format ?? DATE_TABLE_FORMAT;

  const minDate = useMemo(() => {
    const datetime = parseDate(props.minDate);

    if (!datetime.isValid) {
      // Prevent to block the component with invalid date-time.
      // https://github.com/8base-services/jebel/issues/1440
      return undefined;
    }

    return formatISO8601Date(datetime);
  }, [props.minDate]);

  const maxDate = useMemo(() => {
    const datetime = parseDate(props.maxDate);

    if (!datetime.isValid) {
      // Prevent to block the component with invalid date-time.
      // https://github.com/8base-services/jebel/issues/1440
      return undefined;
    }

    return formatISO8601Date(datetime);
  }, [props.maxDate]);

  const value = useMemo(() => {
    const parsed = parseDate(field.value);

    if (parsed.isValid) {
      return formatISO8601Date(parsed);
    }

    return null;
  }, [field.value]);

  const setValue = (value: DateTime | null) => {
    helpers.setValue(value);
    props.onChange?.(value);
  };

  const handleDateChange = (datetime: unknown) => {
    const parsed = parseDate(datetime);

    if (!parsed.isValid) {
      setValue(null);
      return;
    }

    const current = parseDate(value ?? DEFAULT_DATETIME);
    setValue(current.set({ day: parsed.day, month: parsed.month, year: parsed.year }));
  };

  const handleTimeChange = (value: unknown) => {
    const parsed = parseDate(value);

    if (!parsed.isValid) {
      return;
    }

    const current = parseDate(field.value ?? DEFAULT_DATETIME);
    setValue(current.set({ hour: parsed.hour, minute: parsed.minute, second: parsed.second }));
  };

  return (
    <FormControl error={hasError}>
      <Container>
        <KeyboardDatePicker
          label={props.label}
          name={props.name}
          variant="dialog"
          placeholder={format}
          error={hasError}
          helperText={message}
          value={value}
          disabled={props.disabled}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          format={format}
          views={props.dateViews}
          minDate={minDate}
          maxDate={maxDate}
          inputVariant={props.inputVariant}
          onChange={handleDateChange}
        />

        <KeyboardTimePicker
          name={props.name}
          value={value}
          label={props.timeLabel}
          variant="dialog"
          format="HH:mm"
          placeholder="HH:mm"
          error={hasError}
          views={props.timeViews}
          disabled={props.disabled}
          inputVariant={props.inputVariant}
          onChange={handleTimeChange}
        />
      </Container>
    </FormControl>
  );
}
