import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_ISO8601_FORMAT, } from '@jebel/constants';
import { currentDateTime, parseDate } from './parseDates';
/**
 * Transform a date into a formatted string based on a template.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @param template String with the template to [follow the format](https://moment.github.io/luxon/#/formatting?id=toformat).
 */
export function formatDate(value, template, options) {
    const date = parseDate(value, options);
    const formatted = date.toFormat(template);
    if (date.isValid === false && date.invalidReason) {
        return date.invalidReason;
    }
    return formatted;
}
export function formatMilliseconds(value = currentDateTime(), options) {
    const date = parseDate(value, options);
    return date.toMillis();
}
export function formatShortDate(value, options) {
    var _a;
    const date = parseDate(value, options);
    const now = currentDateTime();
    const omitYear = (_a = options === null || options === void 0 ? void 0 : options.omitYear) !== null && _a !== void 0 ? _a : true;
    if (omitYear && date.year === now.year) {
        return formatDate(value, 'MMMM dd');
    }
    return formatDate(value, 'MMMM dd, yyyy');
}
export function formatSQLDate(value = currentDateTime(), options) {
    if (typeof value === 'string' && REGEX_SQL_FORMAT.test(value)) {
        return value;
    }
    return formatDate(value, DATE_SQL_FORMAT, options);
}
export function formatISO8601Date(value = currentDateTime(), options) {
    if (typeof value === 'string' && REGEX_ISO8601_FORMAT.test(value)) {
        return value;
    }
    const date = parseDate(value, options);
    if (date.isValid && date.invalidReason) {
        throw new Error(date.invalidReason);
    }
    return date.toISO({ format: 'extended' });
}
export function formatUSADate(value = currentDateTime(), options) {
    return formatDate(value, DATE_USA_FORMAT, options);
}
export function formatTableDate(value = currentDateTime(), options) {
    return formatDate(value, DATE_TABLE_FORMAT, options);
}
export function formatLongDate(value = currentDateTime(), options) {
    return formatDate(value, `MMMM dd, yyyy 'at' TTT`, options);
}
