import { css } from '@emotion/react';

import { EVENT_STATUSES_FILTER } from '@jebel/constants';

import { SortOrder } from 'shared/graphql/__generated__';
import { SpreadsheetFiltersType } from 'shared/features/spreadsheet';

export enum EventsReportHeaders {
  name = 'Name',
  date = 'Date And Time',
  location = 'Location',
  createdBy = 'Created By',
  createdOn = 'Created On',
  title = 'Title Sponsor',
  status = 'Status',
}
export const eventsListTableHeadlines = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: (order: SortOrder) => ({
      title: order,
    }),
    css: () =>
      css`
        width: 13.58%;
      `,
  },
  {
    name: 'date',
    label: 'DATE AND TIME',
    id: 'date',
    sortPath: (order: SortOrder) => ({
      date: order,
    }),
    css: () =>
      css`
        width: 13.58%;
      `,
  },
  {
    name: 'location',
    label: 'LOCATION',
    id: 'location',
    sortPath: (order: SortOrder) => ({ location: { street1: order } }),
    css: () =>
      css`
        width: 17.9%;
      `,
  },
  {
    name: 'createdBy',
    label: 'CREATED BY',
    id: 'createdBy',
    sortPath: (order: SortOrder) => ({ author: { firstName: order } }),
    css: () =>
      css`
        width: 12.35%;
      `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => ({ createdAt: order }),
    css: () =>
      css`
        width: 9.63%;
      `,
  },
  {
    name: 'titleSponsor',
    label: 'TITLE SPONSOR',
    id: 'titleSponsor',
    sortPath: (order: SortOrder) => ({ titleSponsor: { name: order } }),
    css: () =>
      css`
        width: 10.8%;
      `,
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => ({ status: order }),
    css: () =>
      css`
        width: 12.59%;
      `,
  },
];

export const eventsSpreadsheetFilters: SpreadsheetFiltersType = [
  {
    type: 'select',
    variants: Object.keys(EVENT_STATUSES_FILTER)?.map(value => ({
      text: EVENT_STATUSES_FILTER[value],
      value,
    })),
    label: 'Status',
    name: 'status',
    placeholder: 'Event status',
    filterPath: (value: string) => {
      if (value === '') return {};
      return { status: { equals: value } };
    },
  },
];
