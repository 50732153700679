import { useLayoutEffect, useRef, useState } from 'react';

import { parseDate } from '@jebel/utils';

import { formatPostDate } from 'shared/utils/date';

interface Options {
  /**
   * Refresh the relative time each second, minute or hour depending on the difference with now.
   * @default false
   */
  refresh?: boolean;

  /**
   * Sets the relative date to the given `value`.
   * @default DateTime.now()
   */
  relativeTo?: unknown;
}

const TO_SECONDS = 1000;
const TO_MINUTES = 60 * TO_SECONDS;

const NO_REFRESH_INTERVAL = 0;
const NO_LABEL = '';

/**
 * Transform the given `value` into relative date and format using `Intl`.
 * @param value Given date on the past, present or future on any accepted format (`Date`, ISO, SQL, ...).
 * @example ```ts
 * const publishedAt = useRelativeDate(post.publishedAt)
 * ```
 */
export function useRelativeDate(value: unknown, options?: Options) {
  const withRefresh = options?.refresh ?? false;

  const interval = useRef<unknown>();
  const datetime = parseDate(value);

  const refreshLabel = () => {
    const format = formatPostDate(datetime);
    setFormatted(format);
  };

  const [formatted, setFormatted] = useState<string>(NO_LABEL);
  const [every, setEvery] = useState(1 * TO_SECONDS);

  const refreshFormat = () => {
    const diff = datetime.diffNow();

    const minutes = Math.abs(diff.as('minutes'));
    const hours = Math.abs(diff.as('hours'));

    if (!withRefresh || hours > 1) {
      setEvery(NO_REFRESH_INTERVAL);
      return;
    }

    if (minutes > 1) {
      setEvery(1 * TO_MINUTES);
    }

    refreshLabel();
  };

  useLayoutEffect(() => {
    refreshLabel();

    if (!withRefresh || every === 0) {
      return;
    }

    interval.current = setInterval(refreshFormat, every);

    return () => {
      clearInterval(interval.current as number);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [every, withRefresh]);

  return formatted;
}
