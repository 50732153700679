import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  ContentCurationGroupPostStatusUpdateMutation,
  ContentCurationGroupPostStatusUpdateMutationVariables,
  ContentCurationHomeFeedPostStatusUpdateMutation,
  ContentCurationHomeFeedPostStatusUpdateMutationVariables,
  ContentCurationLegacyFeedPostStatusUpdateMutation,
  ContentCurationLegacyFeedPostStatusUpdateMutationVariables,
  ContentCurationSchoolNewsPostStatusUpdateMutation,
  ContentCurationSchoolNewsPostStatusUpdateMutationVariables,
  FlaggedPostApproveMutation,
  FlaggedPostApproveMutationVariables,
} from 'shared/graphql/__generated__';

import {
  CONTENT_CURATION_GROUP_POST_STATUS_UPDATE_MUTATION,
  CONTENT_CURATION_HOME_FEED_POST_STATUS_UPDATE_MUTATION,
  CONTENT_CURATION_LEGACY_FEED_POST_STATUS_UPDATE_MUTATION,
  CONTENT_CURATION_SCHOOL_NEWS_POST_STATUS_UPDATE_MUTATION,
  FLAGGED_POST_APPROVE_MUTATION,
} from '../queries';

export const useContentCurationGroupPostStatusUpdate = (withContext = true) => {
  const [groupPostStatusUpdate, { loading, data, ...rest }] = useMutation<
    ContentCurationGroupPostStatusUpdateMutation,
    ContentCurationGroupPostStatusUpdateMutationVariables
  >(
    CONTENT_CURATION_GROUP_POST_STATUS_UPDATE_MUTATION,
    withContext
      ? {
          refetchQueries: ['FullContentReportsList'],
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Group Post post has been updated.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: Group Post hasn't been updated. Try again.`,
          },
        }
      : {
          refetchQueries: ['GroupPostsList'],
        },
  );

  const onGroupPostStatusUpdate = React.useCallback(
    (data: ContentCurationGroupPostStatusUpdateMutationVariables['data']) => {
      return groupPostStatusUpdate({
        variables: {
          data,
        },
      });
    },
    [groupPostStatusUpdate],
  );

  return { onGroupPostStatusUpdate, loading, data, ...rest };
};

export const useContentCurationHomeFeedPostStatusUpdate = (withContext = true) => {
  const [homeFeedPostStatusUpdate, { loading, data, ...rest }] = useMutation<
    ContentCurationHomeFeedPostStatusUpdateMutation,
    ContentCurationHomeFeedPostStatusUpdateMutationVariables
  >(
    CONTENT_CURATION_HOME_FEED_POST_STATUS_UPDATE_MUTATION,
    withContext
      ? {
          refetchQueries: ['FullContentReportsList', 'UnreadSidebarElementsList'],
          awaitRefetchQueries: true,
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Home Feed Post status has been updated.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: Home Feed Post status hasn't been updated. Try again.`,
          },
        }
      : {
          refetchQueries: [
            'HomeFeedPostsList',
            'FullContentReportsList',
            'UnreadSidebarElementsList',
            'HomeFeedItems',
          ],
          awaitRefetchQueries: true,
        },
  );

  const onHomeFeedPostStatusUpdate = React.useCallback(
    async (data: ContentCurationHomeFeedPostStatusUpdateMutationVariables['data']) => {
      if (!data.id) {
        console.error('You should specify ID');
      }
      return homeFeedPostStatusUpdate({
        variables: {
          data,
          id: data.id ?? '',
        },
      });
    },
    [homeFeedPostStatusUpdate],
  );

  return { onHomeFeedPostStatusUpdate, loading, data, ...rest };
};

export const useContentCurationSchoolNewsPostStatusUpdate = (withContext = true) => {
  const [schoolNewsPostStatusUpdate, { loading, data, ...rest }] = useMutation<
    ContentCurationSchoolNewsPostStatusUpdateMutation,
    ContentCurationSchoolNewsPostStatusUpdateMutationVariables
  >(
    CONTENT_CURATION_SCHOOL_NEWS_POST_STATUS_UPDATE_MUTATION,
    withContext
      ? {
          refetchQueries: ['FullContentReportsList'],
          awaitRefetchQueries: true,
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: School News Post status has been updated.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: School News Post status hasn't been updated. Try again.`,
          },
        }
      : {
          refetchQueries: ['SchoolNewsPostsList', 'UnreadSidebarElementsList'],
          awaitRefetchQueries: true,
        },
  );

  const onSchoolNewsPostStatusUpdate = React.useCallback(
    async (data: ContentCurationSchoolNewsPostStatusUpdateMutationVariables['data']) => {
      if (!data.id) {
        console.error('You should specify ID');
      }
      return schoolNewsPostStatusUpdate({
        variables: {
          data,
          id: data.id ?? '',
        },
      });
    },
    [schoolNewsPostStatusUpdate],
  );

  return { onSchoolNewsPostStatusUpdate, loading, data, ...rest };
};

export const useContentCurationLegacyFeedPostStatusUpdate = (withContext = true) => {
  const [legacyFeedPostStatusUpdate, { loading, data, ...rest }] = useMutation<
    ContentCurationLegacyFeedPostStatusUpdateMutation,
    ContentCurationLegacyFeedPostStatusUpdateMutationVariables
  >(
    CONTENT_CURATION_LEGACY_FEED_POST_STATUS_UPDATE_MUTATION,
    withContext
      ? {
          refetchQueries: [
            'FullContentReportsList',
            // https://8base-dev.atlassian.net/browse/JEB-1351?focusedCommentId=39757
            'LegacyTimelineYears',
            'LegacyTimelineMonths',
            'LegacyTimelinePost',
          ],
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Legacy Feed Post status has been updated.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: Legacy Feed Post status hasn't been updated. Try again.`,
          },
        }
      : {
          refetchQueries: [
            'LegacyFeedPostsList',
            'LegacyFeedYearsList',
            'LegacyFeedMonthsList',
            'UnreadSidebarElementsList',
          ],
        },
  );

  const onLegacyFeedPostStatusUpdate = React.useCallback(
    async (data: ContentCurationLegacyFeedPostStatusUpdateMutationVariables['data']) => {
      return legacyFeedPostStatusUpdate({
        variables: {
          data,
        },
      });
    },
    [legacyFeedPostStatusUpdate],
  );

  return { onLegacyFeedPostStatusUpdate, loading, data, ...rest };
};

export const useContentCurationFlaggedPostApprove = () => {
  const [approvePost, { loading, data }] = useMutation<
    FlaggedPostApproveMutation,
    FlaggedPostApproveMutationVariables
  >(FLAGGED_POST_APPROVE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'FullContentReportsList',
      'LegacyFeedPostsList',
      'UnreadSidebarElementsList',
      // Refresh the sidebar bubble
      'ContentCurationCount',
      // Refresh the feed
      'HomeFeedItems',
    ],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Post has been updated and published.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Post status hasn't been updated. Try again later.`,
    },
  });

  const onApprovePost = React.useCallback(
    (data: FlaggedPostApproveMutationVariables['data']) => approvePost({ variables: { data } }),
    [approvePost],
  );

  return { onApprovePost, data, loading };
};
