/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from 'ramda';
import { createSimpleRange } from '../ranges';
const DEFAULT_CONFIG = {};
const escapeValue = (value) => {
    if (typeof value !== 'string') {
        return value;
    }
    if (value.indexOf('/') > -1 || value.indexOf(',') > -1) {
        return `"${value}"`;
    }
    return value;
};
const arrayToString = (arr) => {
    const result = arr.map(el => (Array.isArray(el) ? arrayToString(el) : escapeValue(el)));
    return `[${result}]`;
};
const transformProperty = (key, property) => {
    if (!property) {
        return '';
    }
    if (typeof property === 'boolean' || R.isEmpty(property)) {
        return key;
    }
    const propertyValuesAsStrings = Object.entries(property).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return [...acc, `${key}:${arrayToString(value)}`];
        }
        return [...acc, `${key}:${escapeValue(value)}`];
    }, []);
    return `${key}=${propertyValuesAsStrings.join(',')}`;
};
export const processFilestackUrl = (filestackUrl, config = {}) => {
    let url;
    try {
        url = new URL(filestackUrl);
    }
    catch (_a) {
        return filestackUrl;
    }
    const mergedConfig = R.mergeDeepRight(DEFAULT_CONFIG, config);
    const params = Object.keys(mergedConfig)
        // move `output` param at the beginning
        .sort((keyA, keyB) => (keyA === 'output' ? -1 : keyB === 'output' ? 1 : 0))
        // @ts-ignore
        .map(key => transformProperty(key, mergedConfig[key]))
        .join('/');
    url.pathname = (params ? `/${params}` : '') + url.pathname;
    return url.toString();
};
export const processFilestackUrlSrcSet = (url, config = {}) => {
    const { resize, ...restConfig } = config;
    if (!url) {
        return undefined;
    }
    if (!resize) {
        return processFilestackUrl(url, config);
    }
    return createSimpleRange(4)
        .map(num => {
        const resizeObject = {
            ...resize,
            width: resize.width ? Number(resize.width) * (num + 1) : undefined,
            height: resize.height ? Number(resize.height) * (num + 1) : undefined,
        };
        Object.keys(resizeObject).forEach(key => 
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        resizeObject[key] === undefined ? delete resizeObject[key] : {});
        const processedUrl = processFilestackUrl(url, {
            ...restConfig,
            resize: resizeObject,
        });
        return `${processedUrl} ${num + 1}x`;
    })
        .join(', ');
};
export const processAwsUrl = (shareUrl, config = {}) => {
    var _a;
    const localConfig = R.pick(['compress', 'resize'], config);
    const localFit = R.path(['resize', 'fit'], config);
    // adapter for https://sharp.pixelplumbing.com/api-resize
    if (localFit && ((_a = localConfig.resize) === null || _a === void 0 ? void 0 : _a.fit)) {
        if (localFit === 'crop') {
            localConfig.resize.fit = 'cover';
        }
        else if (localFit === 'clip') {
            localConfig.resize.fit = 'inside';
        }
        else {
            localConfig.resize.fit = undefined;
        }
    }
    const encodedParams = btoa(JSON.stringify(localConfig));
    const url = encodedParams
        ? `${shareUrl}/${encodedParams}?download=true`
        : `${shareUrl}?download=true`;
    return url;
};
