import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { ClassScorecardPage } from './pages/ClassScorecardPage';

export const getAdminScorecardRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.scorecard.index,
    exact: true,
    component: ClassScorecardPage,
  },
];
