import { School } from '@material-ui/icons';

import { useSchoolConfiguration } from 'shared/hooks';

import { AuthorAvatar } from './AuthorAvatar';

interface Props {}

export function SchoolAvatar(props: Props) {
  const { configuration: school } = useSchoolConfiguration();

  const name = school?.name;
  const source = school?.images?.logo?.downloadUrl ?? undefined;

  return (
    <AuthorAvatar source={source} name={name}>
      <School />
    </AuthorAvatar>
  );
}
