import { forwardRef } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { FieldValidator, useField, useFormik, useFormikContext } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';

import { composeValidators, required } from 'shared/utils/form';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';

export type FormTextFieldProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
    required?: boolean;
  };
  formik?: ReturnType<typeof useFormik>;
  disabled?: boolean;
  inputProps?: TextFieldProps & { css?: Interpolation<Theme> };
};

export const FormTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  (
    {
      fieldProps,
      inputProps = {},
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const validate = composeValidators(
      fieldProps.required === true ? required : undefined,
      fieldProps.validate,
    );

    const [field, meta] = useField({ ...fieldProps, validate });

    const form = useFormikContext();

    const label = inputProps.label;
    const hasError = getIsInvalid({ meta, form });
    const message = hasError ? meta.error : undefined;

    return (
      <TextField
        ref={ref}
        {...inputProps}
        label={label}
        name={fieldProps.name}
        value={field.value ?? ''}
        onChange={field.onChange}
        error={hasError}
        helperText={message ?? inputProps.helperText}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      />
    );
  },
);
