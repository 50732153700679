import React from 'react';
import { DateTime } from 'luxon';
import PostIcon from '@mui/icons-material/Description';
import { useTheme } from '@material-ui/core';

import { POST_TYPES, POST_TYPES_TITLES } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { formatNumberFriendly } from 'shared/utils/numbers';

import { usePostsCount } from '../../../hooks';
import { DashboardDateFiltersType } from '../../../types';
import { useDateFilterState } from '../../../hooks/useDateFilterState';
import { StreamChatEnrichedActivity } from '../../../../../shared/types';
import { DashboardFilters } from '../../DashboardFilters';
import { SelectMenu } from '../../../../../shared/components/ui';
import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';
import { useSchoolNewsPostsQuery } from '../../../../../features/news/hooks';
import { useLegacyFeedPostsQuery } from '../../../../../features/legacy/hooks';
import { useGroupPostsQuery } from '../../../../../features/groups/hooks/useGroupPostsQuery';
import { useHomeFeedPostsQuery } from '../../../../../features/home/hooks';

export function DashboardCountPostWrapper() {
  const theme = useTheme();
  const {
    totalEventsPostsCount,
    totalJobsPostsCount,
    totalEventsEngagement,
    loading: eventsAndJobsPostsLoading,
    onPostsCountSearch,
    totalJobsEngagementCount,
  } = usePostsCount();
  const [postsType, setPostsType] = React.useState<string>(POST_TYPES.all);
  const [postsDateFilter, setPartialDateFilter] = useDateFilterState();

  const postActivitiesCount = React.useCallback(
    (
      activities: StreamChatEnrichedActivity[],
      startDate: DashboardDateFiltersType['advertisingDate']['startDate'],
      endDate: DashboardDateFiltersType['advertisingDate']['endDate'],
    ): number => {
      return activities
        ?.map(({ time, reaction_counts }) => {
          const start = startDate.toFormat('yyyy-MM-dd');
          const end = endDate.toFormat('yyyy-MM-dd');
          const activityDate = DateTime.fromISO(time).toFormat('yyyy-MM-dd');
          if (start <= activityDate && activityDate <= end) {
            const totalComments = reaction_counts?.comment ?? 0;
            const totalLikes = reaction_counts?.like ?? 0;
            return totalComments + totalLikes;
          }
          return 0;
        })
        .reduce(
          (totalReactions, nextActivityReactions) => totalReactions + nextActivityReactions,
          0,
        );
    },
    [],
  );

  const startDatePosts = formatISO8601Date(postsDateFilter.startDate);
  const endDatePosts = formatISO8601Date(postsDateFilter.endDate);

  const {
    activities: schoolNewsActivities,
    activitiesLoading: schoolPostsActivitiesLoading,
    loading: schoolPostsLoading,
    count: schoolPostsCount,
  } = useSchoolNewsPostsQuery({
    customFilter: { startDate: startDatePosts, endDate: endDatePosts },
  });

  const {
    activities: legacyActivities,
    activitiesLoading: legacyActivitiesLoading,
    loading: legacyPostsLoading,
    count: legacyPostsCount,
  } = useLegacyFeedPostsQuery({}, { startDate: startDatePosts, endDate: endDatePosts });

  const {
    activities: groupActivities,
    activitiesLoading: groupActivitiesLoading,
    loading: groupPostsLoading,
    count: groupPostsCount,
  } = useGroupPostsQuery(null, { startDate: startDatePosts, endDate: endDatePosts });

  const {
    activities: userActivities,
    activitiesLoading: homePostsActivitiesLoading,
    loading: userPostsLoading,
    count: userPostsCount,
  } = useHomeFeedPostsQuery(null, { startDate: startDatePosts, endDate: endDatePosts });

  const filteredPostsCount = React.useMemo(() => {
    switch (postsType) {
      case POST_TYPES.all: {
        return {
          postsCount:
            totalEventsPostsCount +
            totalJobsPostsCount +
            schoolPostsCount +
            legacyPostsCount +
            groupPostsCount +
            userPostsCount,
          reactionsCount:
            postActivitiesCount(
              schoolNewsActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              legacyActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              groupActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              userActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            totalEventsEngagement +
            totalJobsEngagementCount,
        };
      }
      case POST_TYPES.events: {
        return { postsCount: totalEventsPostsCount, reactionsCount: totalEventsEngagement };
      }
      case POST_TYPES.groupPosts: {
        return {
          postsCount: groupPostsCount,
          reactionsCount: postActivitiesCount(
            groupActivities,
            postsDateFilter.startDate,
            postsDateFilter.endDate,
          ),
        };
      }
      case POST_TYPES.jobPosts: {
        return { postsCount: totalJobsPostsCount, reactionsCount: totalJobsEngagementCount };
      }
      case POST_TYPES.legacyPost: {
        return {
          postsCount: legacyPostsCount,
          reactionsCount: postActivitiesCount(
            legacyActivities,
            postsDateFilter.startDate,
            postsDateFilter.endDate,
          ),
        };
      }
      case POST_TYPES.memberPosts: {
        return {
          postsCount: userPostsCount,
          reactionsCount: postActivitiesCount(
            userActivities,
            postsDateFilter.startDate,
            postsDateFilter.endDate,
          ),
        };
      }
      case POST_TYPES.schoolPosts: {
        return {
          postsCount: schoolPostsCount,
          reactionsCount: postActivitiesCount(
            schoolNewsActivities,
            postsDateFilter.startDate,
            postsDateFilter.endDate,
          ),
        };
      }

      default: {
        return {
          postsCount:
            totalEventsPostsCount +
            totalJobsPostsCount +
            schoolPostsCount +
            legacyPostsCount +
            groupPostsCount +
            userPostsCount,
          reactionsCount:
            postActivitiesCount(
              schoolNewsActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              legacyActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              groupActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            postActivitiesCount(
              userActivities,
              postsDateFilter.startDate,
              postsDateFilter.endDate,
            ) +
            totalEventsEngagement +
            totalJobsEngagementCount,
        };
      }
    }
  }, [
    postsDateFilter.endDate,
    postsDateFilter.startDate,
    groupActivities,
    groupPostsCount,
    legacyActivities,
    legacyPostsCount,
    postActivitiesCount,
    postsType,
    schoolNewsActivities,
    schoolPostsCount,
    totalEventsPostsCount,
    totalJobsPostsCount,
    userActivities,
    userPostsCount,
  ]);

  const postsLoading =
    schoolPostsLoading ||
    schoolPostsActivitiesLoading ||
    legacyPostsLoading ||
    legacyActivitiesLoading ||
    groupPostsLoading ||
    groupActivitiesLoading ||
    userPostsLoading ||
    homePostsActivitiesLoading ||
    eventsAndJobsPostsLoading;

  React.useEffect(() => {
    const startDatePosts = formatISO8601Date(postsDateFilter.startDate.toString());
    const endDatePosts = formatISO8601Date(postsDateFilter.endDate.toString());
    onPostsCountSearch(startDatePosts, endDatePosts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsDateFilter]);

  return (
    <DashboardCountStatsItem
      title="Posts"
      icon={<PostIcon />}
      color={theme.palette.primary.main}
      counters={[
        {
          label: 'Total Posts',
          count: formatNumberFriendly(filteredPostsCount.postsCount),
        },
        {
          label: 'Total Engagement',
          count: formatNumberFriendly(filteredPostsCount.reactionsCount),
        },
      ]}
    >
      <DashboardFilters
        dateFilter={postsDateFilter}
        setStartDateFilter={setPartialDateFilter('startDate')}
        setEndDateFilter={setPartialDateFilter('endDate')}
        loading={postsLoading}
      />
      <SelectMenu
        label={POST_TYPES_TITLES[postsType]}
        value={postsType}
        onChange={setPostsType}
        height="20px"
        options={Object.entries(POST_TYPES_TITLES).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    </DashboardCountStatsItem>
  );
}
