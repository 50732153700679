export const EVENT_STATUSES = {
    active: 'active',
    inactive: 'inactive',
    expired: 'expired',
    pendingApproval: 'pendingApproval',
};
export const EVENT_STATUSES_FILTER = {
    active: 'Active',
    inactive: 'Inactive',
    expired: 'Expired',
    pendingApproval: 'Pending approval',
};
