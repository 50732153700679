import { useState } from 'react';
import { Box, Button, css } from '@mui/material';
import { Theme } from '@emotion/react';

import { LegacyFeedPostModalCreate } from 'features/legacy/components/LegacyFeedPostModal';
import { Typography } from 'shared/components/ui';
import { PaperLayout } from 'shared/components/layouts';
import { SearchControl, SearchProvider } from 'shared/features/search';

import { LEGACY_SORT_INFO } from '../constants';
import { LegacyPostsList } from './LegacyPostsList';

const paperStyles = {
  px: 3.75,
  py: 3.125,
  mb: 2,
};

const submitButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing(0.75)}px ${theme.spacing(2.5)}px;
`;

export function LegacyContent() {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onModalClose = () => {
    setShowCreateModal(false);
  };

  const onModalOpen = () => {
    setShowCreateModal(true);
  };

  return (
    <SearchProvider>
      <LegacyFeedPostModalCreate isOpen={showCreateModal} onClose={onModalClose} />

      <Box>
        <PaperLayout sx={paperStyles}>
          <Box display="flex" flexDirection="column" justifyContent="start">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography color="primary" variant="subtitle2">
                Legacy
              </Typography>

              <Box display="flex" columnGap={2}>
                <Button
                  css={submitButtonCss}
                  onClick={onModalOpen}
                  color="primary"
                  disableElevation
                  variant="contained"
                >
                  Post
                </Button>
              </Box>
            </Box>

            <Box mt={3}>
              <SearchControl
                withTextField
                withDateFilterField
                dateFilterFieldProps={{ label: 'Date' }}
                withSortField
                textFieldProps={{ placeholder: 'Search Posts' }}
                sortFieldProps={LEGACY_SORT_INFO}
                elementsWidth={['1fr', '0.8fr', '1fr']}
              />
            </Box>
          </Box>
        </PaperLayout>

        <LegacyPostsList />
      </Box>
    </SearchProvider>
  );
}
