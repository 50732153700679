import React from 'react';
import { Box } from '@mui/material';

import { formatMilliseconds } from '@jebel/utils';

import { CommentForm, CommentContentProps, CommentImage } from 'shared/features/posts/components';
import { ViewMoreButton } from 'shared/features/posts/components/ViewMoreButton';
import { OnCommentAdd, OnCustomReactionAdd } from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { useClickOutside } from 'shared/hooks';
import { MentionPosition } from 'shared/features/mentions/types';

import { CollectiveIntelligenceCommentContent } from './CollectiveIntelligenceCommentContent';

export type CommentProps = Pick<
  CommentContentProps,
  'comment' | 'onLikeAdd' | 'onReactionRemove' | 'onCommentRemove'
> & {
  postId: string;
  onCommentAdd: OnCommentAdd;
  onCustomReactionAdd: OnCustomReactionAdd;
};

const sortNestedComments = (comment: CommentEnrichedReaction) =>
  comment?.latest_children?.comment?.sort(
    (a, b) => formatMilliseconds(a.created_at) - formatMilliseconds(b.created_at),
  );

export const CollectiveIntelligenceComment: React.FC<CommentProps> = ({
  comment,
  postId,
  onCommentAdd: rawOnCommentAdd,
  onCommentRemove,
  onLikeAdd,
  onReactionRemove,
  onCustomReactionAdd,
}) => {
  const [viewAllReplies, setViewAllReplies] = React.useState(false);
  const [isCommentSectionOpened, setIsCommentSectionOpened] = React.useState(false);

  const [nestedComments, setNestedComments] = React.useState(sortNestedComments(comment) || []);

  const fieldRef = React.useRef<any>();
  const formRef = React.useRef<any>();
  const [formInitialValues, setFormInitialValues] = React.useState({ comment: '' });
  const onViewAllReplies = React.useCallback(() => setViewAllReplies(true), []);

  useClickOutside(formRef, () => setIsCommentSectionOpened(false));

  const onChildComments = React.useCallback(
    (commentAuthorName: string | undefined) => {
      setIsCommentSectionOpened(true);
      onViewAllReplies();

      if (fieldRef.current?.scrollIntoView) {
        setTimeout(() => fieldRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' }));
      }

      if (fieldRef.current?.focus) {
        setTimeout(() => fieldRef.current.focus());
      }

      const replyCommentMessage = `${commentAuthorName}, `;

      if (formRef.current && formRef.current.setFieldValue) {
        const { setFieldValue } = formRef.current;

        setFieldValue('comment', replyCommentMessage);
      } else {
        setFormInitialValues({ ...formInitialValues, comment: replyCommentMessage });
      }
    },
    [formInitialValues, onViewAllReplies],
  );

  const onCommentAdd = React.useCallback(
    async (text: string, media: CommentImage[], mentions: MentionPosition[] = []) => {
      const newComment = await rawOnCommentAdd({
        text,
        media,
        foreignId: postId,
        commentId: comment.id,
        parentEntityAuthorId: comment.user_id,
        parentEntityType: 'comment',
        mentions,
      });

      if (!newComment) {
        return;
      }

      setFormInitialValues({ ...formInitialValues, comment: '' });
      setNestedComments([...nestedComments, newComment]);
      onViewAllReplies();
    },
    [
      postId,
      formInitialValues,
      rawOnCommentAdd,
      comment.id,
      comment.user_id,
      nestedComments,
      onViewAllReplies,
    ],
  );

  return (
    <React.Fragment>
      <CollectiveIntelligenceCommentContent
        postId={postId}
        comment={comment}
        onCommentAdd={onChildComments}
        onCommentRemove={onCommentRemove}
        onLikeAdd={onLikeAdd}
        onReactionRemove={onReactionRemove}
        onCustomReactionAdd={onCustomReactionAdd}
      />

      <Box ml={5}>
        {!!nestedComments[0] && (
          <Box mt={1}>
            <CollectiveIntelligenceCommentContent
              postId={postId}
              comment={nestedComments[0]}
              onCommentAdd={onChildComments}
              onCommentRemove={onCommentRemove}
              onLikeAdd={onLikeAdd}
              onReactionRemove={onReactionRemove}
              onCustomReactionAdd={onCustomReactionAdd}
            />
          </Box>
        )}

        {viewAllReplies &&
          nestedComments.slice(1).map(childrenComment => (
            <Box key={childrenComment.id} mt={1}>
              <CollectiveIntelligenceCommentContent
                postId={postId}
                onCustomReactionAdd={onCustomReactionAdd}
                comment={childrenComment}
                onCommentAdd={onChildComments}
                onCommentRemove={onCommentRemove}
                onLikeAdd={onLikeAdd}
                onReactionRemove={onReactionRemove}
              />
            </Box>
          ))}

        {!viewAllReplies && nestedComments.length > 1 && (
          <ViewMoreButton
            onViewMore={onViewAllReplies}
            type="reply"
            amount={nestedComments.length - 1}
          />
        )}

        <Box display={isCommentSectionOpened ? 'block' : 'none'}>
          <CommentForm
            initialValues={formInitialValues}
            formRef={formRef}
            type="reply"
            withAutoFocus
            onCommentPost={onCommentAdd}
            fieldRef={fieldRef}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
