import { gql } from '@apollo/client';

export const USER_REQUEST_ACCESS_MUTATION = gql`
  mutation UserRequestAccess($data: UserRequestAccessInput!) {
    userRequestAccess(data: $data) {
      id
      success
    }
  }
`;
