import { Fragment, MouseEvent, ReactNode, forwardRef, useMemo } from 'react';
import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@material-ui/core';

import { isFn } from '@jebel/utils';

import { usePopoverState } from 'shared/hooks';

import { ComponentDataProps } from '../../types';

export interface PopoverRenderParams {
  isOpen: boolean;

  close(): void;
  /** @deprecated Use `close` instead. */
  onClose(): void;
}

export type PopoverRender = (params: PopoverRenderParams) => ReactNode;

export type PopoverProps = Omit<MuiPopoverProps, 'open' | 'anchorEl'> &
  ComponentDataProps & {
    /** Node who triggers the popover. */
    target: ReactNode;
    /** @deprecated Use `children` instead. */
    content?: ReactNode | PopoverRender;
    children?: ReactNode | PopoverRender;
    /** @deprecated */
    forceOpen?: boolean;

    /**
     * Show the popover on hover.
     * @default false
     */
    showOnHover?: boolean;
  };

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ showOnHover, target, content, forceOpen, ...props }, ref) => {
    const { show: isOpen, anchor, open, close } = usePopoverState<HTMLDivElement>();

    const children = useMemo(() => {
      const children = props.children ?? content;

      if (isFn<PopoverRender>(children)) {
        return children({ isOpen, onClose: close, close });
      }

      return children;
    }, [props.children, content, isOpen, close]);

    const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
      if (showOnHover) {
        open(event);
      }
    };

    const handleMouseLeave = () => {
      if (showOnHover) {
        close();
      }
    };

    return (
      <Fragment>
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={open}>
          {target}
        </span>

        <MuiPopover
          {...props}
          ref={ref}
          anchorEl={anchor}
          open={forceOpen || isOpen}
          onClose={close}
        >
          {children}
        </MuiPopover>
      </Fragment>
    );
  },
);
