import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { CurrentUserFragment, useHobbiesUpdateMutation } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

export const useHobbiesUpdate = () => {
  const { user } = useCurrentUser();

  const [updateHobbies] = useHobbiesUpdateMutation({
    refetchQueries: ['CurrentUser'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your Hobbies and Interests has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Your Hobbies and Interests hasn't been updated. Try again later.`,
    },
  });

  const onUpdateHobbies = async (hobbies: CurrentUserFragment['hobbies']) => {
    if (!user?.id) {
      return;
    }

    try {
      await updateHobbies({
        variables: {
          data: {
            id: user.id,
            hobbies: hobbies?.map(item => item ?? ''),
          },
        },
      });
    } catch (err) {
      sendToSentry(err);
    }
  };

  return {
    onUpdateHobbies,
  };
};
