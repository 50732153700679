import { GroupKeyFilter, useGroupMemberRequestUpsertMutation } from 'shared/graphql';
import { NormalizedGroupMember } from './useGroupMembers';
import { useToast } from 'shared/hooks';
import { GroupUserRequestStatus } from '@jebel/constants';
import { recordDebug, recordError } from 'shared/utils/record';

interface UseGroupMemberApproveOptions {
  group: GroupKeyFilter | undefined | null;
}

export function useGroupMemberApprove(options: UseGroupMemberApproveOptions) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();

  const [updateMemberStatus] = useGroupMemberRequestUpsertMutation({
    refetchQueries: ['GroupMembers'],
    awaitRefetchQueries: true,
  });

  const mutate = async (member: NormalizedGroupMember) => {
    if (!options.group) {
      recordDebug(`Group is not defined. Cannot approve member.`);
      return;
    }

    const messageApprovingKey = `APPROVE_GROUP_MEMBER_REQUEST_${member.id}`;

    try {
      showMessage(`Approving member into group.`, { id: messageApprovingKey });

      await updateMemberStatus({
        variables: {
          userId: member.user.id as string,
          groupId: options.group.id as string,
          status: GroupUserRequestStatus.Approved,
        },
      });

      showSuccess(`Member request has been approved.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    dismiss(messageApprovingKey);
  };

  return {
    action: mutate,
  };
}
