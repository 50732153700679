import { isFutureDate, isPastDate, parseDate } from '../dates';
import { isFn } from '../types';
/** Compose multiple {@linkcode FormFieldValidation} functions into a single one. */
export function composeFieldValidators(...validations) {
    const filtered = validations.filter(isFn);
    return value => {
        for (const fn of filtered) {
            const feedback = fn(value);
            if (feedback instanceof Promise) {
                return feedback;
            }
            if (typeof feedback === 'string') {
                return feedback;
            }
        }
        return undefined;
    };
}
/** Checks whether the given field is a date-time in the future. */
export const shouldBeDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    return undefined;
};
/** Checks whether the given field is a date-time in the future. */
export const shouldBeFutureDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    if (isPastDate(parsed)) {
        return `Should be a date in the future`;
    }
    return undefined;
};
/** Checks whether the given field is a date-time in the past. */
export const shouldBePastDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    if (isFutureDate(parsed)) {
        return `Should be a date in the past`;
    }
    return undefined;
};
