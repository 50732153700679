import { getAdminBusinessRoutes } from 'admin-features/business/getAdminBusinessRoutes';
import { getAdminScorecardRoutes } from 'admin-features/class-scorecard/getAdminScorecardRoutes';
import { getContentCurationRoutes } from 'admin-features/contentCuration/getContentCurationRoutes';
import { getDashboardRoutes } from 'admin-features/dashboard/getDashboardRoutes';
import { getAdminDiscountsRoutes } from 'admin-features/discounts/getAdminDiscountsRotes';
import { getAdminEventsRoutes } from 'admin-features/events/getAdminEventsRoutes';
import { getAdminGroupsRoutes } from 'admin-features/groups/getAdminGroupsRoutes';
import { getAdminLegacyRoutes } from 'admin-features/legacy/getAdminLegacyRoutes';
import { getAdminMembersRoutes } from 'admin-features/members/getAdminMembersRoutes';
import { getAdminPostsRoutes } from 'admin-features/posts/getAdminPostsRoutes';
import { getAdminReportsRoutes } from 'admin-features/reports/getAdminReportsRoutes';
import { getAdminSettingsRoutes } from 'admin-features/settings/getAdminSettingsRoutes';
import getAuthRoutes from 'features/auth/getAuthRoutes';
import { getCareerRoutes } from 'features/career/getCareerRoutes';
import { getCollectiveRoutes } from 'features/collective/getCollectiveRoutes';
import { getDiscountsRoutes } from 'features/discounts/getDiscountsRoutes';
import { getEventsRoutes } from 'features/events/getEventsRoutes';
import { getExplorerRoutes } from 'features/explorer/getExplorerRoutes';
import { getGroupsRoutes } from 'features/groups/getGroupsRoutes';
import { getHomeRoutes } from 'features/home/getHomeRoutes';
import { getInboxRoutes } from 'features/inbox/getInboxRoutes';
import { InboxPage } from 'features/inbox/pages/InboxPage';
import { getLegacyRoutes } from 'features/legacy/getLegacyRoutes';
import { getNewsRoutes } from 'features/news/getNewsRoutes';
import { getOnboardingRoutes } from 'features/onboarding/getOnboardingRoutes';
import { getOrganizationsRoutes } from 'features/organizations/getOrganizationsRoutes';
import { getScorecardRoutes } from 'features/scorecard/getScorecardRoutes';
import { getSearchRoutes } from 'features/search/pages/getSearchRoutes';
import { getSettingsRoutes } from 'features/settings/getSettingsRoutes';
import { ROUTES } from 'shared/constants';
import { AdminRoute } from 'shared/routes';
import { RouteConfigs } from 'shared/routes/types';

export const guestRoutes: RouteConfigs[] = [...getOnboardingRoutes(), ...getAuthRoutes()];

export const userRoutes: RouteConfigs[] = [
  ...getCareerRoutes(),
  ...getCollectiveRoutes(),
  ...getDiscountsRoutes(),
  ...getEventsRoutes(),
  ...getExplorerRoutes(),
  ...getGroupsRoutes(),
  ...getHomeRoutes(),
  ...getSearchRoutes(),
  ...getInboxRoutes(),
  ...getLegacyRoutes(),
  ...getNewsRoutes(),
  ...getOrganizationsRoutes(),
  ...getScorecardRoutes(),
  ...getSettingsRoutes(),
];

export const adminRoutes: RouteConfigs[] = [
  ...getAdminPostsRoutes(),
  ...getAdminMembersRoutes(),
  ...getContentCurationRoutes(),
  ...getDashboardRoutes(),
  ...getAdminBusinessRoutes(),
  ...getAdminEventsRoutes(),
  ...getAdminScorecardRoutes(),
  ...getAdminGroupsRoutes(),
  ...getAdminDiscountsRoutes(),
  ...getAdminLegacyRoutes(),
  ...getAdminReportsRoutes(),
  ...getAdminSettingsRoutes(),
  {
    routeComponent: AdminRoute,
    path: ROUTES.admin.mail.index,
    exact: false,
    component: InboxPage,
  },
];
