import React from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { useField } from 'formik';
import pluralize from 'pluralize';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Icon, ResultFile } from 'shared/components/ui';

interface UploadedImagesProps {
  name: string;
}

const StyledImage = styled('img')`
  width: 64px;
  height: 40px;
  object-fit: cover;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const ImageName = styled(Typography)`
  max-width: 200px;
  font-size: ${props => props.theme.typography.fontSize}px;
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
`;

export const UploadedImages: React.FC<UploadedImagesProps> = props => {
  const [{ value: files }, _, { setValue }] = useField<ResultFile[] | undefined | null>(props);

  const handleClearImage = React.useCallback(
    (file: ResultFile) => () => {
      const result = files?.filter(f => f.fileId !== file.fileId);
      setValue(result);
    },
    [files, setValue],
  );

  if (files?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography fontSize={14} height="auto" color={theme => theme.palette.text.disabled}>
        {`${files?.length} ${pluralize('file', files?.length)}`} uploaded
      </Typography>

      {files?.map(file => (
        <Box key={file.fileId} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <StyledImage
              srcSet={processFilestackUrlSrcSet(file?.downloadUrl || '', {
                resize: {
                  width: 64,
                  height: 40,
                },
              })}
              src={file?.downloadUrl || ''}
            />
            <ImageName>{file?.filename}</ImageName>
            <IconButton color="error" onClick={handleClearImage(file)}>
              <Icon name="Clear" />
            </IconButton>
          </Box>
        </Box>
      ))}
    </React.Fragment>
  );
};
