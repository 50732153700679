import { useMemo } from 'react';

import {
  isInboxSubjectFromSchool,
  isInboxSubjectFromSystem,
  processFilestackUrlSrcSet,
} from '@jebel/utils';
import { PLATFORM_INBOX_NAME } from '@jebel/constants';

import { InboxFragment, Maybe } from 'shared/graphql';
import { useCurrentUser, useQueryParams, useSchoolConfiguration } from 'shared/hooks';
import { getMessageTimeStamp } from 'features/inbox/utils';
import { AVATAR_SIZE } from 'features/inbox/constants';
import { formatUserName } from 'shared/utils/user';

import {
  Container,
  Content,
  Subject,
  Header,
  Name,
  TimeStamp,
  Message,
  Avatar,
  Indicator,
  HeaderName,
} from './InboxChatsListItem.styles';

type Props = InboxFragment & {
  onClick: () => void;
};

interface Params {
  inboxId: Maybe<string>;
}

export const InboxChatsListItem = ({ onClick, ...data }: Props) => {
  const [{ inboxId }] = useQueryParams<Params>();

  const { configuration: school } = useSchoolConfiguration();
  const { userId } = useCurrentUser();

  const { authorName, authorAvatarSource, message, subject } = useMemo(() => {
    const [message] = data?.messages?.items ?? [];

    let subject = data.subject;

    if (!message) {
      return {};
    }

    const authors = data?.users?.items ?? [];
    const author = authors.find(user => user.id === message.author?.id);

    let authorName: string | undefined = formatUserName(author);
    let authorAvatarSource: string | undefined = author?.avatar?.downloadUrl ?? undefined;

    if (isInboxSubjectFromSchool(data.subject)) {
      subject = data.subject.content;

      /** @todo IMPROVE THIS ON THE MULTI-TENANT MODEL. */
      authorName = school?.name ?? data.subject.school.name ?? undefined;
      authorAvatarSource = school?.images?.logo?.downloadUrl ?? undefined;
    }

    if (isInboxSubjectFromSystem(data.subject)) {
      subject = data.subject.content;

      authorName = PLATFORM_INBOX_NAME;
      authorAvatarSource = undefined;
    }

    return {
      message,
      author,
      subject,
      authorName,
      authorAvatarSource,
    };
  }, [data, school]);

  const timestamp = useMemo(() => getMessageTimeStamp(message?.createdAt), [message]);

  const isRead = useMemo(() => {
    // Unread mark === false if chat has no messages
    if (data?.messages?.items.length === 0) {
      return true;
    }

    // Unread mark === false if last chat message author !== current user
    if (data?.messages?.items[0]?.author?.id === userId) {
      return true;
    }

    return data?.messages?.items[0].isRead;
  }, [data?.messages?.items, userId]);

  return (
    <Container isSelected={data?.id === inboxId} button onClick={onClick}>
      <Avatar
        src={authorAvatarSource}
        srcSet={processFilestackUrlSrcSet(authorAvatarSource, {
          compress: true,
          resize: { width: AVATAR_SIZE, height: AVATAR_SIZE },
        })}
      />

      <Content>
        <Header>
          <HeaderName>
            <Name title={authorName}>{authorName}</Name>
            {!isRead && <Indicator />}
          </HeaderName>

          <TimeStamp>{timestamp}</TimeStamp>
        </Header>

        <Subject>{subject}</Subject>
        <Message>{message?.text}</Message>
      </Content>
    </Container>
  );
};
