import React from 'react';
import { css, Theme } from '@emotion/react';
import { Box, Divider, Typography } from '@material-ui/core';

import { Separator, UserAvatar, UserNameLink } from 'shared/components/symbols';
import { usePosts } from 'shared/features/posts';
import { CollectiveIntelligenceItemFragment } from 'shared/graphql';
import { getTextTimeAgo } from 'shared/utils/date';
import { MediaGallery } from 'shared/components/ui';
import { CenterLayout } from 'shared/components/layouts';
import { MentionsText } from 'shared/features/mentions';

const SEPARATOR_DIAMETER = '4px';

const authorActivityCss = (theme: Theme) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${theme.breakpoints.down('xs')} {
    margin: 0;
  }
`;

const titleCss = (theme: Theme) => css`
  color: ${theme.palette.primary.dark};
  line-height: 2rem;
  font-weight: 400;
  padding-right: 20px;
  word-break: break-word;
`;

const authorNameCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.dark};
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CollectiveIntelligencePost = ({
  id,
  post,
}: {
  id: string;
  post: CollectiveIntelligenceItemFragment | undefined;
}) => {
  const { activity, loading } = usePosts({ postId: id });

  const media = post?.images?.items ?? [];

  if (loading) {
    return <CenterLayout withBottomOffset>Loading...</CenterLayout>;
  }

  if (!activity) {
    return (
      <Box>
        <Typography>No activity was found for this thread</Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        display={{ xs: 'grid', lg: 'flex' }}
        justifyContent="space-between"
        alignItems="flex-start"
        gridGap={{ xs: 3, lg: 0 }}
      >
        <Typography css={titleCss} variant="subtitle2">
          {post?.title}
        </Typography>

        <Box display="flex" alignItems="center" overflow={{ xs: 'hidden', md: 'initial' }}>
          <Box display="flex" alignItems="center" gridGap="0.5rem">
            <UserAvatar user={post?.creator} />

            <UserNameLink css={authorNameCSS} user={post?.creator} />
          </Box>

          <Box mx={1}>
            <Separator diameter={SEPARATOR_DIAMETER} />
          </Box>

          <Typography css={authorActivityCss} color="textSecondary">
            {getTextTimeAgo(post?.createdAt)}
          </Typography>
        </Box>
      </Box>

      <Box my={4}>
        <Divider />
      </Box>

      <MentionsText text={post?.text ?? ''} />

      {media.length > 0 && <MediaGallery media={media} />}
    </React.Fragment>
  );
};
