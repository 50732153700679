import { useEffect, useMemo } from 'react';
import { uniqBy } from 'ramda';

import {
  CareersPageSearchQuery,
  MembersWithZipListQuery,
  useCareersPageSearchQuery,
  useMembersWithZipListQuery,
} from 'shared/graphql';
import { CAREERS_SORT_INFO } from 'features/career/constants';
import { PAGE_SIZE, USER_SORT_BY_NAME_INFO, USER_SORT_INFO } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import { isSortOptionMatchesQuery } from 'shared/features/search/utils';
import { getJobSeekerSearchFilter } from 'shared/utils/search';
import { useJobOfferExpirationDate, useResponsive } from 'shared/hooks';
import { getCareerSearchFilter } from 'shared/utils/search/getCareerSearchFilter';

export function useCareersSearch(localZipQuery: string, initialRadius: string) {
  const { searchQuery, sortOption, zipQuery, setSortOption } = useSearchContext();
  const { isMobile } = useResponsive();

  const nonExpiredJobsDate = useJobOfferExpirationDate();

  const createdAtJobFilter = useMemo(() => {
    // https://8base-dev.atlassian.net/browse/JEB-1303
    return { gte: nonExpiredJobsDate.toISO() };
  }, [nonExpiredJobsDate]);

  const radius = useMemo(() => {
    return initialRadius.split(' ')[0] ?? '';
  }, [initialRadius]);

  useEffect(() => {
    setSortOption(CAREERS_SORT_INFO.options[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userFilter = useMemo(() => getJobSeekerSearchFilter(searchQuery), [searchQuery]);

  const userZipFilter = useMemo(
    () =>
      isMobile
        ? {
            userPreferences: localZipQuery
              ? {
                  address: {
                    zip: { equals: localZipQuery },
                  },
                }
              : {},
          }
        : {
            userPreferences: zipQuery
              ? {
                  address: {
                    zip: { equals: zipQuery },
                  },
                }
              : {},
          },
    [isMobile, localZipQuery, zipQuery],
  );

  const careerFilter = useMemo(() => {
    return getCareerSearchFilter(searchQuery, false, createdAtJobFilter);
  }, [createdAtJobFilter, searchQuery]);

  const internshipFilter = useMemo(() => {
    return getCareerSearchFilter(searchQuery, true, createdAtJobFilter);
  }, [createdAtJobFilter, searchQuery]);

  const {
    data: responseOffers,
    loading: loadingOffers,
    fetchMore: fetchMoreOffers,
  } = useCareersPageSearchQuery({
    variables: {
      careersFirst: PAGE_SIZE,
      startPointZip: zipQuery || localZipQuery,
      radius,
      careerFilter,
      careersSort: isSortOptionMatchesQuery(sortOption, CAREERS_SORT_INFO) ? sortOption : undefined,
    },
  });

  const {
    data: responseInternships,
    loading: loadingInternships,
    fetchMore: fetchMoreInternships,
  } = useCareersPageSearchQuery({
    variables: {
      careersFirst: PAGE_SIZE,
      startPointZip: zipQuery || localZipQuery,
      radius,
      careerFilter: internshipFilter,
      careersSort: isSortOptionMatchesQuery(sortOption, CAREERS_SORT_INFO) ? sortOption : undefined,
    },
  });

  const {
    data: responseUsers,
    loading: loadingUsers,
    fetchMore: fetchMoreSeekers,
  } = useMembersWithZipListQuery({
    variables: {
      radius,
      startPointZip: zipQuery || localZipQuery,
      first: PAGE_SIZE,

      filter: {
        ...userFilter,
        ...userZipFilter,
      },

      sort:
        isSortOptionMatchesQuery(sortOption, USER_SORT_INFO) ||
        isSortOptionMatchesQuery(sortOption, USER_SORT_BY_NAME_INFO)
          ? sortOption
          : undefined,
    },
  });

  const offers = responseOffers?.page?.items ?? [];
  const offersCount = responseOffers?.page?.count ?? 0;
  const hasMoreOffers = offers.length < offersCount;

  const internships = responseInternships?.page?.items ?? [];
  const internshipsCount = responseInternships?.page?.count ?? 0;
  const hasMoreInternships = internships.length < internshipsCount;

  const seekers = responseUsers?.members?.items ?? [];
  const seekersCount = responseUsers?.members?.count ?? 0;
  const hasMoreSeekers = seekers.length < seekersCount;

  const loading = loadingOffers || loadingInternships || loadingUsers;

  const nextOffers = async () => {
    await fetchMoreOffers({
      variables: {
        careersFirst: PAGE_SIZE,
        careersSkip: offers.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.page.items ?? [];
        const newest = curr?.page?.items ?? [];

        const count = curr?.page.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(user => user.id, [...oldest, ...newest]);

        const data: CareersPageSearchQuery = {
          ...curr,

          page: {
            ...prev.page,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  const nextInternships = async () => {
    await fetchMoreInternships({
      variables: {
        careersFirst: PAGE_SIZE,
        careersSkip: internships.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.page.items ?? [];
        const newest = curr?.page?.items ?? [];

        const count = curr?.page.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(user => user.id, [...oldest, ...newest]);

        const data: CareersPageSearchQuery = {
          ...curr,

          page: {
            ...prev.page,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  const nextSeekers = async () => {
    await fetchMoreSeekers({
      variables: {
        first: PAGE_SIZE,
        skip: seekers.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.members.items ?? [];
        const newest = curr?.members?.items ?? [];

        const count = curr?.members.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(user => user.id, [...oldest, ...newest]);

        const data: MembersWithZipListQuery = {
          ...curr,

          members: {
            ...prev.members,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  return {
    jobOfferItems: offers,
    jobOfferCount: offersCount,
    jobSeekersItems: seekers,
    jobSeekersCount: seekersCount,
    internshipsItems: internships,
    internshipsCount,

    loading,

    fetchMoreOffers: nextOffers,
    fetchMoreInternships: nextInternships,
    fetchMoreSeekers: nextSeekers,

    hasMoreOffers,
    hasMoreInternships,
    hasMoreSeekers,
  };
}
