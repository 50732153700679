/* eslint-disable no-continue */
import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { ParseResult, parse as parseCSV } from 'papaparse';
import { Formik } from 'formik';

import { Button, Icon, Typography } from 'shared/components/ui';
import { UserRequestAccessInput } from 'shared/graphql';
import { FormSelectUser } from 'shared/components/form';
import { useToast, useUserInvitation } from 'shared/hooks';
import { recordError, recordMessage } from 'shared/utils/record';

const DROP_ZONE_HEIGHT = 150;
const DROP_ZONE_ICON_SIZE = 50;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const FILE_TYPE = '.csv';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(1)}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
`;

const dropZoneContainer = theme => css`
  background-color: ${theme.palette.background.light};
  border: 1px dashed #bbbbbb;
  height: ${DROP_ZONE_HEIGHT}px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
`;

const dropZoneIconCss = theme => css`
  font-size: 14px;
  fill: ${theme.palette.primary.light};
  width: ${DROP_ZONE_ICON_SIZE}px;
  height: ${DROP_ZONE_ICON_SIZE}px;
`;

const dropZoneTextCss = theme => css`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

interface Values {
  managers: string[];
}

interface Props {
  onModalClose: () => void;
}

const INITIAL_VALUES: Values = {
  managers: [],
};

export function BusinessInviteModal(props: Props) {
  const [emails, setEmails] = useState<string[]>([]);
  const [fileInput, setFileInput] = useState<string | null>(null);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { showError, showSuccess, showWarning } = useToast();
  const { inviteBusinessAdmins } = useUserInvitation();

  const setEmailsMerging = (curr: string[]) => {
    setEmails(prev => {
      const unique = new Set([...prev, ...curr]);
      const emails = Array.from(unique);

      return emails;
    });
  };

  const onSubmit = async () => {
    if (emails.length === 0) {
      showWarning(`You should specify at least one email to be invited.`);
      return;
    }

    try {
      const invitated = emails.map<UserRequestAccessInput>(email => {
        return {
          email,
          firstName: 'Business',
          lastName: 'User',
          affiliation: 'other',
          affiliationDescription: 'Business User',
        };
      });

      await inviteBusinessAdmins(invitated);

      recordMessage(`${invitated.length} user(s) invited as manager`);
      showSuccess(`Success! Your invitations have been sent. Thank you.`);

      props.onModalClose();
    } catch (err) {
      showError(`Error! Your invitations haven't been sent`);
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onUploadFileChange = (event: any) => {
    const uploadedFile: File = event?.target?.files[0];

    if (uploadedFile?.name?.endsWith(FILE_TYPE)) {
      parseCSV(uploadedFile, {
        complete: (parsedCsv: ParseResult<string[]>) => {
          const emails = parsedCsv?.data?.flat()?.filter(e => e.match(EMAIL_REGEX)) || [];

          setEmailsMerging(emails);
          setFileInput(null);
        },
      });
    }
  };

  const onUploadFile = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChangeEmails = (emails: string[]) => {
    setEmailsMerging(emails);
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
      {form => (
        <Box display="grid" gridGap={20}>
          <Typography variant="body1" color="primary">
            Invite businesses to the site using their email address
          </Typography>

          <FormSelectUser
            multiple
            allowUnknown="only"
            name="managers"
            variant="outlined"
            label="Invite Managers"
            placeholder="example@domain.com"
            onChange={handleChangeEmails}
            helperText={
              <span>
                Either hit <em>&quot;enter&quot;</em> or use the <em>&quot;add ...&quot;</em> option
                to save the desire email.
              </span>
            }
          />

          <Typography variant="body1" color="primary">
            Invite businesses by uploading a list
          </Typography>

          <Box css={dropZoneContainer} onClick={onUploadFile}>
            <Icon css={dropZoneIconCss} name="CloudUpload" variant="filled" />

            <Typography css={dropZoneTextCss} variant="inherit">
              Import a list (CSV file)
            </Typography>

            <input
              type="file"
              accept={FILE_TYPE}
              ref={hiddenFileInput}
              value={fileInput ?? ''}
              style={{ display: 'none' }}
              onChange={onUploadFileChange}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              css={cancelButtonCSS}
              onClick={props.onModalClose}
              disabled={form.isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>

            <Button
              css={submitButtonCSS}
              variant="contained"
              color="primary"
              loading={form.isSubmitting}
              disabled={form.isSubmitting}
              onClick={form.submitForm}
            >
              Send invites
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
}
