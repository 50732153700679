import logoSource from 'shared/assets/8base-logo.svg';

import { Container, Logo, Text } from './PoweredBy.styles';

export function PoweredBy() {
  return (
    <Container>
      <Text>Built on</Text>
      <Logo src={logoSource} />
    </Container>
  );
}
