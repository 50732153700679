import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  WordsBlacklistsListQuery,
  WordsBlacklistsListQueryVariables,
  SchoolConfigurationWordsBlacklistUpdateMutation,
  SchoolConfigurationWordsBlacklistUpdateMutationVariables,
} from 'shared/graphql/__generated__';

import {
  SCHOOL_CONFIGURATION_WORDS_BLACKLIST_UPDATE_MUTATION,
  WORDS_BLACKLISTS_LIST_QUERY,
} from '../queries';

export const useWordsBlacklistsListQuery = () => {
  const { loading, data, ...rest } = useQuery<
    WordsBlacklistsListQuery,
    WordsBlacklistsListQueryVariables
  >(WORDS_BLACKLISTS_LIST_QUERY);

  return {
    wordsBlacklists: data?.schoolConfigurationWordsBlacklistsList?.items,
    wordsBlacklistsLoading: loading && !data,
    ...rest,
  };
};

export const useSchoolConfigurationWordsBlacklistUpdate = () => {
  const [schoolConfigurationUpdate, { loading, data, ...rest }] = useMutation<
    SchoolConfigurationWordsBlacklistUpdateMutation,
    SchoolConfigurationWordsBlacklistUpdateMutationVariables
  >(SCHOOL_CONFIGURATION_WORDS_BLACKLIST_UPDATE_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Actual Words Blacklist has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Actual Words Blacklist hasn't been updated. Try again later.`,
    },
  });

  const onSchoolConfigurationUpdate = React.useCallback(
    (data: SchoolConfigurationWordsBlacklistUpdateMutationVariables['data']) => {
      return schoolConfigurationUpdate({
        variables: {
          data,
        },
      });
    },
    [schoolConfigurationUpdate],
  );

  return { onSchoolConfigurationUpdate, schoolConfigurationUpdateLoading: loading, ...rest };
};
