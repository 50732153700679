/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
import AdapterLuxon from '@date-io/luxon';
import { DateTime } from 'luxon';

const DAYS_ON_WEEK = 7;

/**
 * Luxon adapter for the `LocalizationProvider` component to set the sunday as first day of the week.
 * https://8base-dev.atlassian.net/browse/JEB-1383
 */
export class CustomLuxonAdapter extends AdapterLuxon {
  /** Set the order of the labels in a week. */
  getWeekdays = () => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  };

  /** Generate the dates per month. */
  getWeekArray = (initial: DateTime) => {
    const monthStart = initial.startOf('month').startOf('week');
    const monthEnd = initial.endOf('month').endOf('week');
    const range = monthStart.diff(monthEnd);

    const weeks = Math.round(Math.abs(range.as('weeks'))) + 1;
    const days = weeks * DAYS_ON_WEEK;

    const month: DateTime[][] = [];

    for (let index = 0; index < days; index++) {
      const week = month.length - 1;

      const date = initial
        .startOf('month')
        .startOf('week')
        .plus({ day: index - 1 });

      if (index === 0 || index % 7 === 0) {
        month.push([date]);
        continue;
      }

      month[week] = [...month[week], date];
    }

    return month;
  };
}
