import { useCallback, useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Box, MenuItem, Typography, Avatar } from '@material-ui/core';
import { pick } from 'ramda';
import { DateTime } from 'luxon';
import { Business } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { formatISO8601Date, formatSQLDate, isObj, isStr } from '@jebel/utils';

import {
  JobEmployeeCreateInput,
  JobEmployeeUpdateInput,
  Maybe,
  OrganizationItemFragment,
  SmartAddress,
} from 'shared/graphql';
import {
  useProfessionalProfileUpdate,
  useProfessionalProfileCreate,
} from 'features/settings/hooks';
import {
  Form,
  FormTextField,
  ImageInput,
  Button,
  FormSelect,
  FormCheckbox,
  ExperienceCardData,
  DatePickerField,
  AutocompleteField,
} from 'shared/components/ui';
import { FIELDS_CONFIG, US_STATES_OPTIONS } from 'shared/constants';
import { useCurrentUser, useResponsive, useToast } from 'shared/hooks';
import { maxTextSymbols, required } from 'shared/utils/form';
import { ResultFile } from 'shared/types/files';
import { FormSelectOrganization } from 'shared/components/form';

const modalContainerCss = (theme: Theme) => css`
  padding: ${theme.spacing(1)}px 0px;
`;

const formCss = (theme: Theme) => css`
  display: grid;
  gap: ${theme.spacing(2)}px;
`;

const formRowCss = (theme: Theme) =>
  css`
    display: grid;
    grid-template-columns: auto min-content;
    gap: 1rem;

    ${theme.breakpoints.down('sm')} {
      grid-template-rows: auto min-content;
    }
  `;

const formRowInnerCss = (theme: Theme) =>
  css`
    display: grid;
    gap: 1rem;
  `;

const addressRowCss = (theme: Theme) =>
  css`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;

    ${theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr;
    }
  `;

const footerButtonsCss = (theme: Theme) => css`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const uploadImageCSS = (theme: Theme) => css`
  --image-height: 8rem;

  height: var(--image-height);

  ${theme.breakpoints.up('sm')} {
    width: var(--image-height);
  }

  ${theme.breakpoints.down('sm')} {
    --image-height: 8rem;

    width: 100%;
  }
`;

const uploadContentCSS = (theme: Theme) => css`
  ${uploadImageCSS(theme)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.background.paper};
  border: 1px dashed ${theme.palette.divider};
  border-radius: 0.25rem;

  &:hover {
    background-color: ${theme.palette.background.light};
  }
`;

const uploadedLogoCSS = (theme: Theme) => css`
  ${uploadImageCSS(theme)};

  border-radius: 6px;
  border: 1px solid ${theme.palette.divider};

  img {
    object-fit: contain;
  }
`;

const cancelButtonCss = (theme: Theme) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
`;

const FIELD_COMPANY_IMAGE = 'companyImage';

type Values = {
  employmentType?: Maybe<string>;
  positionTitle?: Maybe<string>;
  positionDescription?: Maybe<string>;
  companyName?: Maybe<string | OrganizationItemFragment>;
  companyImage?: Maybe<ResultFile>;
  companyLocation?: Maybe<SmartAddress>;
  isCurrentlyWorking?: boolean;
  startDate?: Maybe<string | DateTime>;
  endDate?: Maybe<string | DateTime>;
};

interface Props {
  isOpen: boolean;

  mode: 'create' | 'edit';
  data?: ExperienceCardData;

  onClose(): void;
}

export function ExperienceModal(props: Props) {
  const { createExperience } = useProfessionalProfileCreate();
  const { updateExperience } = useProfessionalProfileUpdate();
  const { isMobile } = useResponsive();
  const { user } = useCurrentUser();
  const { showError } = useToast();

  const label = useMemo(() => {
    return props.mode === 'create' ? 'Create Experience' : 'Edit Experience';
  }, [props.mode]);

  const initials = useMemo<Values>(() => {
    if (props.mode === 'create' || !props.data) {
      return {
        isCurrentlyWorking: false,
      };
    }

    const payload: Values = {
      positionTitle: props.data.positionTitle,
      positionDescription: props.data.positionDescription,
      companyName: props.data.companyName,
      companyImage: props.data.companyImage,
      companyLocation: cleanCompanyLocation(props.data.companyLocation),
      employmentType: props.data.employmentType,
      startDate: formatISO8601Date(props.data.startDate),
      endDate: formatISO8601Date(props.data.endDate),
      isCurrentlyWorking: Boolean(props.data.isCurrentlyWorking),
    };

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, props.data]);

  const handleEdit = useCallback(
    async (values: Values) => {
      if (props.mode === 'create' || !props.data) {
        return;
      }

      try {
        const data: JobEmployeeUpdateInput = {
          id: props.data.id,
          positionTitle: values.positionTitle as string,
          positionDescription: values.positionDescription as string,
          employmentType: values.employmentType as string,
          startDate: formatSQLDate(values.startDate),
          endDate: formatSQLDate(values.endDate),
          isCurrentlyWorking: Boolean(values.isCurrentlyWorking),
          companyLocation: cleanCompanyLocation(props.data.companyLocation),
        };

        if (isStr(values.companyName)) {
          // Use the text from the created organization as name.
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43562
          data.companyName = values.companyName;
        }

        if (isObj(values.companyName)) {
          // Use the name of the selected organization.
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43562
          data.companyName = values.companyName?.name;
        }

        if (
          values.companyImage &&
          values.companyImage?.fileId !== props.data.companyImage?.fileId
        ) {
          // Create the given image and detach the previous one.
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43763
          data.companyImage = {
            create: {
              fileId: values.companyImage.fileId,
              filename: values.companyImage.filename,
              public: true,
            },
          };
        }

        if (!values.companyImage && props.data.companyImage?.fileId) {
          // Once the image has been detach it will also removed
          data.companyImage = {
            disconnect: { fileId: props.data.companyImage.fileId },
          };
        }

        await updateExperience(data);
        props.onClose();
      } catch (err) {
        showError('Something went wrong creating the experience, please try again.');

        if (err instanceof Error) {
          showError(err.message);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data, props.mode, updateExperience],
  );

  const handleCreate = useCallback(
    async (values: Values) => {
      if (!user) {
        return;
      }

      try {
        const data: JobEmployeeCreateInput = {
          positionTitle: values.positionTitle as string,
          positionDescription: values.positionDescription as string,
          employmentType: values.employmentType as string,
          startDate: formatSQLDate(values.startDate),
          endDate: formatSQLDate(values.endDate),
          isCurrentlyWorking: Boolean(values.isCurrentlyWorking),
          user: { connect: { id: user.id } },
          companyLocation: cleanCompanyLocation(values.companyLocation),
        };

        if (isStr(values.companyName)) {
          // Use the text from the created organization as name.
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43562
          data.companyName = values.companyName;
        }

        if (isObj(values.companyName)) {
          // Use the name of the selected organization.
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43562
          data.companyName = values.companyName?.name;
        }

        if (values.companyImage) {
          // Creates a new image relationship for the new profile
          // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43562
          data.companyImage = {
            create: {
              fileId: values.companyImage.fileId,
              filename: values.companyImage.filename,
              public: true,
            },
          };
        }

        await createExperience(data);
        props.onClose();
      } catch (err) {
        showError('Something went wrong creating the experience, please try again.');

        if (err instanceof Error) {
          showError(err.message);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.mode, props.data, user, createExperience],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      if (props.mode === 'edit') {
        return handleEdit(values);
      }

      return handleCreate(values);
    },
    [handleCreate, handleEdit, props.mode],
  );

  return (
    <Dialog open={props.isOpen} fullWidth maxWidth="sm" onClose={props.onClose}>
      <DialogTitle css={{ textTransform: 'capitalize' }}>{label}</DialogTitle>

      <DialogContent dividers>
        <Box css={modalContainerCss}>
          <Form<Values> initialValues={initials} onSubmit={handleSubmit}>
            {form => (
              <Box css={formCss}>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Title',
                    variant: 'outlined',
                  }}
                  fieldProps={{
                    name: 'positionTitle',
                    validate: value => maxTextSymbols(value, 30) ?? required(value),
                  }}
                />

                <FormSelect
                  selectProps={{
                    children: FIELDS_CONFIG.employmentType?.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    label: 'Employment Type',
                  }}
                  fieldProps={{ name: 'employmentType', validate: required }}
                />

                {isMobile ? (
                  <Box css={formRowInnerCss}>
                    <FormSelectOrganization
                      name="companyName"
                      label="Company Name"
                      variant="outlined"
                      validate={value => maxTextSymbols(value, 30) ?? required(value)}
                      onlyActive={false}
                      onlyManaged={false}
                    />

                    <FormTextField
                      inputProps={{
                        color: 'primary',
                        label: 'City',
                        variant: 'outlined',
                      }}
                      fieldProps={{
                        name: 'companyLocation.city',
                        validate: value => maxTextSymbols(value, 30) ?? required(value),
                      }}
                    />
                  </Box>
                ) : (
                  <Box css={formRowCss}>
                    <Box css={formRowInnerCss}>
                      <FormSelectOrganization
                        name="companyName"
                        label="Company Name"
                        variant="outlined"
                        allowUnknown="allow"
                        validate={value => maxTextSymbols(value, 30) ?? required(value)}
                        onlyActive={false}
                        onlyManaged={false}
                      />

                      <FormTextField
                        inputProps={{
                          color: 'primary',
                          label: 'City',
                          variant: 'outlined',
                        }}
                        fieldProps={{ name: 'companyLocation.city', validate: required }}
                      />
                    </Box>

                    <ImageInput
                      name="companyImage"
                      maxFiles={1}
                      initialValue={props.mode === 'edit' ? props.data?.companyImage : undefined}
                      onChange={file => form.setFieldValue(FIELD_COMPANY_IMAGE, file)}
                      onDelete={() => form.setFieldValue(FIELD_COMPANY_IMAGE, undefined)}
                      customPicker={
                        <Box css={uploadContentCSS}>
                          <Business />

                          <Typography align="center" variant="body2">
                            Add Image
                          </Typography>
                        </Box>
                      }
                      customPreview={
                        <Avatar
                          css={uploadedLogoCSS}
                          src={form.values.companyImage?.downloadUrl ?? undefined}
                          variant="square"
                        />
                      }
                    />
                  </Box>
                )}

                <Box css={addressRowCss}>
                  <AutocompleteField
                    label="State"
                    name="companyLocation.state"
                    options={US_STATES_OPTIONS}
                    required
                  />

                  <FormTextField
                    inputProps={{
                      color: 'primary',
                      label: 'ZIP Code',
                      variant: 'outlined',
                      required: true,
                    }}
                    fieldProps={{
                      name: `companyLocation.zip`,
                      validate: value => maxTextSymbols(value, 6),
                    }}
                  />
                </Box>

                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Description',
                    variant: 'outlined',
                    multiline: true,
                    rows: '5',
                    rowsMax: '5',
                    required: true,
                  }}
                  fieldProps={{
                    name: `positionDescription`,
                    validate: value => maxTextSymbols(value, 3000),
                  }}
                />

                <Box display="flex">
                  <FormCheckbox
                    fieldProps={{ name: 'isCurrentlyWorking' }}
                    checkboxProps={{
                      label: 'I’m currently working in this role',
                      indeterminate: false,
                      labelPlacement: 'end',
                    }}
                  />
                </Box>

                <Box css={addressRowCss}>
                  <DatePickerField
                    name="startDate"
                    label="Start Date"
                    inputVariant="outlined"
                    disableFuture
                    validate={required}
                  />

                  {!form.values.isCurrentlyWorking && (
                    <DatePickerField
                      name="endDate"
                      label="End Date"
                      inputVariant="outlined"
                      minDate={form.values.startDate}
                    />
                  )}
                </Box>

                {isMobile && (
                  <ImageInput
                    name="companyImage"
                    maxFiles={1}
                    initialValue={props.mode === 'edit' ? props.data?.companyImage : undefined}
                    onChange={file => form.setFieldValue(FIELD_COMPANY_IMAGE, file)}
                    onDelete={() => form.setFieldValue(FIELD_COMPANY_IMAGE, undefined)}
                    customPicker={
                      <Box css={uploadContentCSS}>
                        <Business />

                        <Typography align="center" variant="body2">
                          Add Image
                        </Typography>
                      </Box>
                    }
                    customPreview={
                      <Avatar
                        css={uploadedLogoCSS}
                        src={form.values.companyImage?.downloadUrl ?? undefined}
                        variant="square"
                      />
                    }
                  />
                )}

                <Box css={footerButtonsCss}>
                  <Button variant="text" css={cancelButtonCss} onClick={props.onClose}>
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={form.isSubmitting}
                    loading={form.isSubmitting}
                  >
                    {label}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function cleanCompanyLocation(values: Maybe<SmartAddress> | undefined) {
  if (!values) {
    return undefined;
  }

  return pick(['city', 'country', 'state', 'street1', 'street2', 'zip'], values);
}
