import { useCallback } from 'react';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { OrganizationUpdateInput, useOrganizationUpdateMutation } from 'shared/graphql';

type Options = Parameters<typeof useOrganizationUpdateMutation>[0];

export function useUpdateOrganization(options?: Options) {
  const [organizationUpdateMutation] = useOrganizationUpdateMutation({
    ...options,

    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'The organization has been updated',
      [SNACKBAR_ERROR_MESSAGE]: 'Something went wrong updating the organization. Please try again',
    },
  });

  const organizationUpdate = useCallback(
    async (data: OrganizationUpdateInput, options?: Options) => {
      await organizationUpdateMutation({ ...options, variables: { data } });
    },
    [organizationUpdateMutation],
  );

  return {
    organizationUpdate,
  };
}
