import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { SORT } from '@jebel/constants';

import { Typography, Button, Modal } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { useCrudPermissions, useModalState } from 'shared/hooks';

import { BusinessesList } from './BusinessesList';
import { BusinessInviteModal } from './BusinessInviteModal';

const postButtonCss = theme => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(4)}px;
  background-color: ${theme.palette.primary.light};
`;

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export const BusinessContent = () => {
  const { businessPermissions } = useCrudPermissions();
  const { show: isModalOpen, open: onModalOpen, close: onModalClose } = useModalState();

  return (
    <React.Fragment>
      <Modal
        dialogProps={{ open: isModalOpen, onClose: onModalClose }}
        titleProps={{ title: 'Invite Business' }}
      >
        <BusinessInviteModal onModalClose={onModalClose} />
      </Modal>
      <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
        <SearchProvider>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            css={css`
              min-width: 0;
            `}
          >
            <Box display="flex" justifyContent="space-between" alignItems="start" ml={3} my={2}>
              <Typography variant="subtitle2" color="primary">
                Business Registry
              </Typography>
              {businessPermissions.add && (
                <Button
                  css={postButtonCss}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={onModalOpen}
                >
                  INVITE BUSINESS
                </Button>
              )}
            </Box>
            <BusinessesList />
          </Box>
        </SearchProvider>
      </SpreadsheetProvider>
    </React.Fragment>
  );
};
