import { MemberInfoFragment } from 'shared/graphql';
import { ExplorerUserCard } from 'features/explorer/components';

interface Props {
  data: MemberInfoFragment;
}

export function JobSeekersListItem({ data: item }: Props) {
  return <ExplorerUserCard data={item} withMessage withViewProfile />;
}
