import { QueryHookOptions } from '@apollo/client';

import {
  GroupDetailsQuery,
  GroupDetailsQueryVariables,
  useGroupDetailsQuery,
} from 'shared/graphql';

interface UseGroupDetailsOptions
  extends QueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables> {
  /** @deprecated Use `variables.id` instead. */
  id?: string | undefined | null;
}

export function useGroupDetails(options: UseGroupDetailsOptions) {
  const {
    data: response,
    loading,
    refetch,
  } = useGroupDetailsQuery({
    ...options,

    variables: {
      ...options?.variables,
      id: options?.variables?.id ?? options?.id,
    },
  });

  const data = response?.group || {};

  return {
    data,
    loading,
    refetch,
  };
}
