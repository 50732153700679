import React from 'react';
import { Avatar, Box, Divider, Skeleton, css } from '@mui/material';

import { useCommentAuthorQuery } from 'shared/graphql';
import { OnCommentRemove, OnLikeAdd, OnReactionRemove } from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { getUserFullName } from 'shared/utils/user';
import { CommentButton } from 'shared/features/posts';
import { MediaGallery, Typography } from 'shared/components/ui';
import { getTextTimeAgo } from 'shared/utils/date';
import { MentionsText } from 'shared/features/mentions';

import { LikeButton } from './LikeButton';

type EventCommentContentProps = {
  comment: CommentEnrichedReaction;
  onCommentAdd: (authorName?: string | undefined) => void;
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  onCommentRemove: OnCommentRemove;
};

const nameCss = theme => css`
  font-size: ${theme.typography.fontSize}px;
  color: ${theme.palette.primary.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const postedAtCss = theme => css`
  color: ${theme.palette.text.secondary};
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
`;

const AVATAR_SIZE = 32;

const avatarCss = theme => css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  margin-right: ${theme.spacing(1)}px;
`;

const DIVIDER_CONTAINER_SIZE = 24;

const dividerContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${DIVIDER_CONTAINER_SIZE}px;
`;

const DIVIDER_SIZE = 4;

const dividerCss = theme => css`
  height: ${DIVIDER_SIZE}px;
  width: ${DIVIDER_SIZE}px;
  border-radius: ${DIVIDER_SIZE / 2}px;
  background-color: ${theme.palette.text.secondary};
`;

const commentContentBoxCss = css`
  background-color: #f6f8fa;
  border-radius: 6px;
`;

export const EventCommentContent: React.FC<EventCommentContentProps> = ({
  comment,
  onCommentAdd,
  onLikeAdd,
  onReactionRemove,
  onCommentRemove,
}) => {
  const { data: commentAuthorData, loading: isCommentAuthorLoading } = useCommentAuthorQuery({
    variables: {
      id: comment.user_id,
    },
    skip: !comment,
    fetchPolicy: 'cache-and-network',
  });

  const commentAuthor = React.useMemo(() => {
    return commentAuthorData?.user || undefined;
  }, [commentAuthorData]);

  const commentAuthorFullName = React.useMemo(() => {
    return getUserFullName(commentAuthor, 'Deleted User');
  }, [commentAuthor]);

  if (isCommentAuthorLoading) {
    return (
      <Box display="flex" flexDirection="row" flex={1} gap={1}>
        <Skeleton css={avatarCss} variant="circular" width={40} height={40} />

        <Box flex={1}>
          <Skeleton variant="rectangular" height={50} />

          <Box display="flex" gap={1} mt={1}>
            <Skeleton variant="rectangular" width={65} height={40} />
            <Skeleton variant="rectangular" width={65} height={40} />
            <Skeleton variant="rectangular" width={65} height={40} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" flex={1} gap={1}>
      <Avatar css={avatarCss} src={commentAuthorData?.user?.avatar?.downloadUrl || ''} />

      <Box flex={1} sx={{ overflow: 'hidden' }}>
        <Box css={commentContentBoxCss} flex={1} p={2} pt={0.5} pb={0.5}>
          <Box display="flex" flex={1}>
            <Box display="flex" sx={{ overflow: 'hidden' }}>
              <Typography css={nameCss}>{commentAuthorFullName}</Typography>
            </Box>

            <Box css={dividerContainerCss}>
              <Divider css={dividerCss} />
            </Box>

            <Typography css={postedAtCss}>{getTextTimeAgo(comment.created_at)}</Typography>
          </Box>

          <MentionsText text={comment.data?.text ?? ''} />

          <MediaGallery media={comment.data?.media ?? [{ downloadUrl: comment.data?.imageUrl }]} />
        </Box>

        <Box display="flex" gap={1} mt={1}>
          <LikeButton
            onLikeAdd={onLikeAdd}
            commentId={comment.id}
            onReactionRemove={onReactionRemove}
            likeInfo={comment.latest_children?.like || []}
            parentEntityAuthorId={comment.user_id}
          />
          <CommentButton
            onComment={() => onCommentAdd(commentAuthorFullName)}
            onCommentRemove={() => onCommentRemove(comment.id)}
            commentId={comment.id}
          />
        </Box>
      </Box>
    </Box>
  );
};
