import React from 'react';
import { css } from '@emotion/react';
import { Typography, Box, Button } from '@material-ui/core';

import { Icon } from 'shared/components/ui';
import { CommentEnrichedReaction } from 'shared/types';

import { transformDeletedCommentsState } from '../../utils';
import { useResponsive } from '../../../../hooks';

const commentContainerCss = theme => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.palette.primary.light};
`;

const COMMENT_ICON_SIZE = 20;

const commentIconCss = (isMobile: boolean) => css`
  height: ${COMMENT_ICON_SIZE - (isMobile ? 2 : 0)}px;
  width: ${COMMENT_ICON_SIZE - (isMobile ? 2 : 0)}px;
`;

const commentCountCss = theme => css`
  font-size: 14px;
  font-weight: 500;
  ${theme.breakpoints.down('xs')} {
    font-size: 0.85rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 0.95rem;
  }
`;

const commentButtonCss = (centerContent: boolean) => theme =>
  css`
    justify-content: ${centerContent ? 'space-between' : 'center'};
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  `;

const deleteButtonCss = (theme: any) => css`
  text-transform: none;
`;

type CommentButtonProps = {
  onComment: () => any;
  onCommentRemove?: (commentId: string) => void;
  commentsAmount?: number;
  isIcon?: boolean;
  commentId?: string;
  setComments?: React.Dispatch<React.SetStateAction<CommentEnrichedReaction[]>>;
  withDelete?: boolean;
  withProfilePostSight?: boolean | null | undefined;
  disableComments?: boolean;
};

export const CommentButton = ({
  onComment,
  onCommentRemove,
  commentsAmount,
  isIcon = false,
  commentId,
  setComments = () => {},
  withProfilePostSight = false,
  withDelete = false,
  disableComments,
}: CommentButtonProps) => {
  const displayAmount = typeof commentsAmount === 'number';
  const [loading, setLoading] = React.useState(false);
  const { isMobile } = useResponsive();

  const onDeleteComment = async () => {
    setLoading(true);
    if (commentId) {
      await onCommentRemove?.(commentId);
      setComments?.(prevComments => prevComments.map(transformDeletedCommentsState(commentId)));
    }
    setLoading(false);
  };

  return (
    <Box css={commentContainerCss}>
      {!disableComments && (
        <Button color="primary" css={commentButtonCss(displayAmount)} onClick={onComment}>
          {isIcon ? (
            <Icon
              css={commentIconCss(isMobile)}
              name="ModeComment"
              variant="filled"
              htmlColor="#295f7b"
            />
          ) : (
            'Reply'
          )}

          {displayAmount && <Typography css={commentCountCss}>{commentsAmount}</Typography>}
        </Button>
      )}

      {withDelete && (
        <Button
          color="secondary"
          disabled={loading}
          css={deleteButtonCss}
          onClick={onDeleteComment}
        >
          Delete
        </Button>
      )}
    </Box>
  );
};
