import { useContext } from 'react';

import { HomeFeedCardContext } from './HomeFeedCardProvider';

/**
 * Give access to the current `HomeFeedCardContext`.
 * @throws When outside of `HomeFeedCardProvider`.
 */
export function useHomeFeedCardProvider() {
  const context = useContext(HomeFeedCardContext);

  if (!context) {
    throw new Error(`Must be inside of "HomeFeedCardProvider"`);
  }

  return context;
}

/**
 * Give access to the current `isHaveBeenShown`.
 * @throws When outside of `HomeFeedCardProvider`.
 */
export function useHomeFeedCardHaveBeenShown() {
  const context = useHomeFeedCardProvider();
  return context.isHaveBeenShown;
}

/**
 * Give access to the current `isHaveBeenRender`.
 * @throws When outside of `HomeFeedCardProvider`.
 */
export function useHomeFeedCardHaveBeenRender() {
  const context = useHomeFeedCardProvider();
  return context.isHaveBeenRender;
}

/**
 * Give access to the current `HomeFeedItem`.
 * @throws When outside of `HomeFeedCardProvider`.
 */
export function useHomeFeedCardData() {
  const context = useHomeFeedCardProvider();
  return context.data;
}
