import React from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  DashboardsAdvertisementsListQuery,
  DashboardsAdvertisementsListQueryVariables,
  DashboardsMembersListQuery,
  DashboardsMembersListQueryVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { DASHBOARDS_MEMBERS_LIST_QUERY, DASHBOARDS_ADVERTISEMENTS_LIST_QUERY } from '../queries';

export const useDashboardStats = () => {
  const [dashboardMembersSearch, { loading, data }] = useLazyQuery<
    DashboardsMembersListQuery,
    DashboardsMembersListQueryVariables
  >(DASHBOARDS_MEMBERS_LIST_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const onDashboardMembersSearch = React.useCallback(
    (startDate: string, endDate: string) => {
      try {
        dashboardMembersSearch({
          variables: {
            filter: {
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [dashboardMembersSearch],
  );

  const [
    dashboardAdvertisementsSearch,
    { loading: dashboardAdvertisementsLoading, data: dashboardAdvertisementsData },
  ] = useLazyQuery<DashboardsAdvertisementsListQuery, DashboardsAdvertisementsListQueryVariables>(
    DASHBOARDS_ADVERTISEMENTS_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const onDashboardAdvertisemenetsSearch = React.useCallback(
    (startDate: string, endDate: string) => {
      try {
        dashboardAdvertisementsSearch({
          variables: {
            filter: {
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [dashboardAdvertisementsSearch],
  );

  return {
    onDashboardMembersSearch,
    onDashboardAdvertisementsSearch: onDashboardAdvertisemenetsSearch,
    dashboardAdvertisementsData: dashboardAdvertisementsData?.dashboardsList?.items,
    dashboardsMembersList: data?.dashboardsList?.items,
    membersLoading: loading && !data,
    advertisingLoading: dashboardAdvertisementsLoading && !dashboardAdvertisementsData,
  };
};
