import { useCallback, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import {
  Box,
  Chip,
  MenuItem,
  Tab,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Field } from 'formik';
import { Avatar } from '@mui/material';
import { People } from '@material-ui/icons';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { DataBaseMultiSelectField } from 'shared/components/form/DateBaseMultiselectField';
import { useDataBaseSelectField } from 'shared/components/form/hooks/useDataBaseSelectField';
import {
  Button,
  Form,
  FormSelect,
  FormTextField,
  ImageInput,
  Typography,
} from 'shared/components/ui';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import {
  USERS_LIST_QUERY,
  AdminGroupDetailsQuery,
  AdminGroupDetailsQueryVariables,
  GroupUpdateMutation,
  GroupUpdateMutationVariables,
  User,
  UsersListQuery,
  AdminGroupDeleteMutation,
  AdminGroupDeleteMutationVariables,
  UserInviteMembersToGroupMutation,
  UserInviteMembersToGroupMutationVariables,
  GroupUpdateInput,
} from 'shared/graphql';
import { required } from 'shared/utils/form';
import { SearchProvider } from 'shared/features/search';
import { useCrudPermissions, useCurrentUser } from 'shared/hooks';
import { GroupMembersList } from 'features/groups/components';

import { GROUP_STATUS_OPTIONS } from '../constants';
import {
  ADMIN_GROUP_DETAILS_QUERY,
  GROUP_DELETE_MUTATION,
  GROUP_UPDATE_MUTATION,
} from '../queries';

const MEMBERS_TAB = 'members';
const INFORMATION_TAB = 'information';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(1)}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
`;

const IMAGE_INPUT_BOX_SIZE = 95;

const uploadFileBoxCSS = css`
  :hover {
    background-color: #abbeff;
  }
  width: ${IMAGE_INPUT_BOX_SIZE}px;
  height: ${IMAGE_INPUT_BOX_SIZE}px;
  cursor: pointer;
  background-color: #f6f8fa;
  border: 1px dashed #bbbbbb;
`;

const chipCss = (theme: any) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const deleteButtonCss = (theme: any) => css`
  color: ${theme.palette.secondary.light};
`;

const panelCss = () => css`
  padding: 0;
`;

const formBoxCss = theme => css`
  padding: ${theme.spacing(3)}px;
`;

type FormData = {
  title?: string | undefined | null;
  description?: string | undefined | null;
  logo?: {
    fileId?: string | null | undefined;
    donwloadUrl?: string | null | undefined;
  };
  status?: string | null | undefined;
  members?: Array<User> | null | undefined;
};

interface Props {
  id: string;

  isOpen: boolean;

  onClose(): void;
}

const INVITE_MEMBERS_MUTATION = gql`
  mutation UserInviteMembersToGroup($data: UserInviteMembersToGroupInput!) {
    userInviteMembersToGroup(data: $data) {
      success
    }
  }
`;

export function GroupDetailsModal(props: Props) {
  const [activeTab, setActiveTab] = useState<string>(INFORMATION_TAB);

  const { isAdmin } = useCurrentUser();

  const {
    groupsPermissions: { edit: isEditAccessed, delete: isDeleteAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const handleChangeTab = (_, activeTab: string) => {
    setActiveTab(activeTab);
  };

  const { data: dataDetails } = useQuery<AdminGroupDetailsQuery, AdminGroupDetailsQueryVariables>(
    ADMIN_GROUP_DETAILS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: props.id },
    },
  );

  const [deleteGroup] = useMutation<AdminGroupDeleteMutation, AdminGroupDeleteMutationVariables>(
    GROUP_DELETE_MUTATION,
    {
      refetchQueries: ['GroupsList'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been deleted',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
      },
    },
  );

  const [updateGroup] = useMutation<GroupUpdateMutation, GroupUpdateMutationVariables>(
    GROUP_UPDATE_MUTATION,
    {
      refetchQueries: ['GroupsList'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been updated.',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
      },
    },
  );

  const [inviteMembersToGroup] = useMutation<
    UserInviteMembersToGroupMutation,
    UserInviteMembersToGroupMutationVariables
  >(INVITE_MEMBERS_MUTATION, {
    refetchQueries: ['GroupsList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const initialValues = useMemo(
    () => ({
      description: dataDetails?.group?.description,
      title: dataDetails?.group?.title,
      status: dataDetails?.group?.status,
      logo: {
        fileId: dataDetails?.group?.logo?.fileId,
        downloadUrl: dataDetails?.group?.logo?.downloadUrl,
      },
      members: dataDetails?.group?.members?.items,
    }),
    [dataDetails],
  );

  const handleSubmit = async (formData: FormData) => {
    const rawMembers = dataDetails?.group?.members?.items || [];
    const connectMembers =
      formData?.members
        ?.map(member => ({ id: member.id }))
        .filter(member => !rawMembers.some(rawMember => rawMember.id === member.id)) || [];

    const initialMediaId = initialValues?.logo.fileId;
    const newMediaId = formData?.logo?.fileId;
    const newMedia = newMediaId !== initialMediaId ? formData?.logo?.fileId : undefined;
    if (!dataDetails?.group?.id) {
      console.error('No group id');

      return;
    }
    const data: GroupUpdateInput = {
      id: dataDetails.group.id,
      ...(newMedia
        ? {
            logo: {
              create: { fileId: newMedia },
            },
          }
        : {}),
      title: formData.title ?? '',
      description: formData.description ?? '',
      status: formData.status,
      members: { connect: connectMembers },
    };

    await updateGroup({ variables: { data } });
    if (connectMembers?.length)
      await inviteMembersToGroup({
        variables: {
          data: {
            members: { connect: connectMembers },
            id: data.id as string,
          },
        },
      });

    props.onClose();
  };

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: USERS_LIST_QUERY,
      getQueryItems: 'usersList.items',
    },
    {
      getOptionLabel: (user: User) => `${user.firstName} ${user.lastName}`,
      renderTag: (user: User) => {
        const isExistMember = dataDetails?.group?.members?.items.some(
          member => member.id === user.id,
        );
        if (isExistMember) {
          return null;
        }

        return <Chip css={chipCss} label={`${user?.firstName} ${user?.lastName}`} />;
      },
    },
  );

  const onDelete = useCallback(async () => {
    await deleteGroup({ variables: { data: { id: props.id } } });
    props.onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.onClose]);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Group Details</DialogTitle>

      <Divider />

      <DialogContent>
        <TabContext value={activeTab}>
          <TabList onChange={handleChangeTab}>
            <Tab label="Information" value={INFORMATION_TAB} />
            <Tab label="Members" value={MEMBERS_TAB} />
          </TabList>

          <TabPanel css={panelCss} value={INFORMATION_TAB}>
            <Form onSubmit={handleSubmit} initialValues={initialValues}>
              {({ isSubmitting, setFieldValue, values }) => (
                <Box display="grid" gridTemplateRows="auto" gridGap={10} css={formBoxCss}>
                  <Box display="flex" gridGap={10}>
                    <Box
                      display="grid"
                      gridTemplateRows="auto auto"
                      alignItems="center"
                      gridGap={10}
                      flex={1}
                    >
                      <FormTextField
                        disabled={!isEditAccessed}
                        inputProps={{
                          label: 'GROUP TITLE',
                          variant: 'outlined',
                          disabled: !isEditAccessed,
                        }}
                        fieldProps={{ name: 'title', validate: required }}
                      />
                      <Field name="members">
                        {({ field, form, meta }) => (
                          <DataBaseMultiSelectField
                            disabled={!isEditAccessed}
                            form={form}
                            input={field}
                            meta={meta}
                            placeholder="Invite members"
                            {...multiSelectFieldProps}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start">
                      <ImageInput
                        name="logo"
                        maxFiles={1}
                        initialValue={values.logo}
                        onChange={file => setFieldValue('logo', file)}
                        onDelete={() => setFieldValue('logo', undefined)}
                        customPicker={
                          <Box>
                            <Box
                              css={uploadFileBoxCSS}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              alignContent="center"
                            >
                              <People />
                            </Box>

                            <Typography color="highlight" variant="subtitle5" align="center">
                              Add Image
                            </Typography>
                          </Box>
                        }
                        customPreview={
                          <Box display="flex" flexDirection="column" justifyContent="center">
                            <Avatar
                              css={uploadFileBoxCSS}
                              src={values?.logo?.downloadUrl}
                              srcSet={processFilestackUrlSrcSet(values?.logo?.downloadUrl || '', {
                                resize: {
                                  width: 95 * 2,
                                  height: 95 * 2,
                                },
                              })}
                            />
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                  <FormSelect
                    selectProps={{
                      children: GROUP_STATUS_OPTIONS.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )),
                      variant: 'outlined',
                      label: 'Status',
                      disabled: !isEditAccessed,
                    }}
                    fieldProps={{ name: 'status', validate: required }}
                  />
                  <FormTextField
                    disabled={!isEditAccessed}
                    inputProps={{
                      color: 'primary',
                      label: 'DESCRIPTION',
                      multiline: true,
                      rows: '7',
                      rowsMax: '7',
                      variant: 'outlined',
                      disabled: !isEditAccessed,
                    }}
                    fieldProps={{ name: 'description' }}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      css={cancelButtonCSS}
                      onClick={props.onClose}
                      disabled={isSubmitting}
                      size="medium"
                    >
                      CANCEL
                    </Button>
                    {isDeleteAccessed && !loadingAddons && (
                      <Button
                        css={deleteButtonCss}
                        onClick={onDelete}
                        disabled={isSubmitting}
                        size="medium"
                      >
                        Delete group
                      </Button>
                    )}
                    <Button
                      loading={isSubmitting}
                      css={submitButtonCSS}
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={!isEditAccessed || loadingAddons}
                    >
                      Save changes
                    </Button>
                  </Box>
                </Box>
              )}
            </Form>
          </TabPanel>

          <TabPanel value={MEMBERS_TAB} css={panelCss}>
            <SpreadsheetProvider>
              <SearchProvider>
                <GroupMembersList group={{ id: props.id }} withActions={isAdmin} />
              </SearchProvider>
            </SpreadsheetProvider>
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}
