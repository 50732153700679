import React, { Fragment, useCallback } from 'react';
import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Icon, RouterLink, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { GroupsListItemFragment, OrganizationItemFragment } from 'shared/graphql';
import { buildUrl } from 'shared/routes';

import { OrganizationCardItem } from './OrganizationCardItem';

const AUTHOR_AVATAR_SIZE = 40;
const ITEMS_COUNT = 3;

const avatar = (theme: Theme) => css`
  margin-right: ${theme.spacing(3)}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.divider};
`;

const groupAvatarCss = (theme: Theme) => css`
  width: ${AUTHOR_AVATAR_SIZE}px;
  height: ${AUTHOR_AVATAR_SIZE}px;
  ${avatar(theme)};
`;

const titleCss = (theme: Theme) => css`
  line-height: initial;
  margin-left: ${theme.spacing(1)}px;
`;

const contentBoxCss = (theme: Theme) => css`
  background: #fff;
  border-radius: ${theme.spacing(1)}px;
`;

const groupItemCss = (theme: Theme) => css`
  cursor: pointer;
`;

const groupNameCss = (theme: Theme) => css`
  color: ${theme.palette.primary.light};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExplorerNationProfileSidebar = ({
  groups,
  organizations,
}: {
  groups: GroupsListItemFragment[] | undefined;
  organizations: OrganizationItemFragment[] | undefined;
}) => {
  const history = useHistory();

  const onGroupOpen = useCallback(
    group => () => {
      if (!group?.id) {
        return;
      }

      const url = buildUrl(APP_URL.user.groups.details, {
        pathParams: { id: group?.id },
      });

      history.push(url);
    },
    [history],
  );

  return (
    <Fragment>
      <Box css={contentBoxCss} mb={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <Icon name="Groups" />
            <Typography css={titleCss} variant="subtitle5">
              My Groups
            </Typography>
          </Box>
          {groups && groups.length > 3 && (
            <RouterLink color="secondary" absolute={false} to={APP_URL.user.groups.index}>
              View All
            </RouterLink>
          )}
        </Box>
        {groups?.slice(0, ITEMS_COUNT).map(group => {
          return (
            <Box
              key={group.id}
              onClick={onGroupOpen(group)}
              display="flex"
              alignItems="center"
              mb={1}
              css={groupItemCss}
            >
              <Avatar
                css={groupAvatarCss}
                src={group?.logo?.downloadUrl || ''}
                srcSet={processFilestackUrlSrcSet(group?.logo?.downloadUrl || '', {
                  compress: true,
                  resize: {
                    width: AUTHOR_AVATAR_SIZE,
                    height: AUTHOR_AVATAR_SIZE,
                    fit: 'crop',
                  },
                })}
                variant="square"
              />
              <Typography variant="subtitle5" css={groupNameCss}>
                {group?.title}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box css={contentBoxCss}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <Icon name="Work" />
            <Typography css={titleCss} variant="subtitle5">
              Related Organizations
            </Typography>
          </Box>
          {organizations && organizations.length > 3 && (
            <RouterLink color="secondary" absolute={false} to={APP_URL.user.organizations.index}>
              View All
            </RouterLink>
          )}
        </Box>

        {organizations?.slice(0, ITEMS_COUNT).map(organization => {
          return <OrganizationCardItem key={organization.id} organization={organization} />;
        })}
      </Box>
    </Fragment>
  );
};
