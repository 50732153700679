import { PropsWithChildren } from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { useResponsive } from 'shared/hooks';
import { SearchProvider } from 'shared/features/search';
import { LEGACY_POSTS_SORT_INFO_DEFAULT_VALUE } from 'features/legacy/constants/sort';

import { LegacyDetailsSidebar } from '../LegacyDetailsSidebar';

export function LegacyLayout(props: PropsWithChildren<unknown>) {
  const { isMobile } = useResponsive();

  return (
    <SearchProvider
      defaultFilterOption={{}}
      defaultSortOption={LEGACY_POSTS_SORT_INFO_DEFAULT_VALUE}
    >
      <RouteLayout withMaxWidth content={props.children} sidebar={<LegacyDetailsSidebar />} />
    </SearchProvider>
  );
}
