import React from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import type {
  DiscountListItemFragment,
  HomeFeedDiscountsListQuery,
  HomeFeedDiscountsListQueryVariables,
} from 'shared/graphql';

import { HOME_FEED_DISCOUNTS_LIST_QUERY } from '../queries';

export const useHomeFeedDiscountsList = (isMobile?: boolean) => {
  const dateNow = React.useMemo(() => {
    return DateTime.now();
  }, []);

  const { data, loading } = useQuery<
    HomeFeedDiscountsListQuery,
    HomeFeedDiscountsListQueryVariables
  >(HOME_FEED_DISCOUNTS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
    variables: {
      dateNow,
      first: isMobile ? 1 : undefined,
    },
  });

  return {
    items: (data?.discountsList?.items || []) as DiscountListItemFragment[],
    loading,
  };
};
