import * as R from 'ramda';

export const capitalize = R.replace(/^./, R.toUpper);

export const glueStrings = (fill: string, ...values: Array<string | null | undefined>) => {
  return values.filter(Boolean).join(fill);
};

export const stringEqualsIgnoreCase = (left = '', right = ''): boolean => {
  return left.trim().toLowerCase() === right.trim().toLowerCase();
};
