import styled from '@emotion/styled';

import {
  Container,
  Button,
} from 'shared/components/navigation/BottomNavigation/BottomNavigation.styles';

// PreviewsContainer

export const PreviewsContainer = styled(Container)``;

// PreviewsItem

export const PreviewsItem = styled(Button)``;
