import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

import { Icon } from 'shared/components/ui';

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const StyledHelperText = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
`;

interface ModalHintProps {
  title?: string;
  maxWidth: number;
  maxHeight: number;
  hintImage: string;
}

export const ModalHint: React.FC<ModalHintProps> = props => {
  const { title, maxHeight, maxWidth, hintImage } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton color="inherit" size="small" onClick={() => setIsOpen(true)}>
        <StyledIcon name="Help" />
      </IconButton>
      <Dialog maxWidth="md" fullWidth open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <Box p={4}>
          <StyledHelperText color={theme => theme.palette.text.disabled}>
            Max width: {maxWidth}px
          </StyledHelperText>
          <StyledHelperText color={theme => theme.palette.text.disabled}>
            Ideal size: {maxWidth}x{maxHeight}px
          </StyledHelperText>
          <img style={{ width: '100%' }} src={hintImage} alt="Example" />
          <Box display="flex" flexDirection="row-reverse">
            <Button variant="contained" onClick={() => setIsOpen(false)}>
              Done
            </Button>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
