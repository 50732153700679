import React from 'react';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';

import {
  User,
  useGraduatingYearsListQuery,
  UsersWithClassesQuery,
  UsersWithClassesQueryVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

const USERS_WITH_CLASSES_QUERY = gql`
  query UsersWithClasses {
    usersList(filter: { graduatingYear: { is_empty: false } }) {
      count
      items {
        id
        graduatingYear
      }
    }
  }
`;

const getUserGraduationYear = (user: User) => {
  return new Date(user.graduatingYear).getFullYear();
};

export const useScorecardStats = () => {
  const {
    data: classesData,
    loading: isClassesDataLoading,
    refetch: refetchClassesData,
  } = useGraduatingYearsListQuery();

  const {
    data: usersData,
    loading: isUsersDataLoading,
    refetch: refetchUsersData,
  } = useQuery<UsersWithClassesQuery, UsersWithClassesQueryVariables>(USERS_WITH_CLASSES_QUERY);

  const [isRefetch, setIsRefetch] = React.useState(false);

  const isScorecardStatsLoading = React.useMemo(() => {
    return isClassesDataLoading || isUsersDataLoading || isRefetch;
  }, [isClassesDataLoading, isUsersDataLoading, isRefetch]);

  const { classesCount, graduatedUsersCount } = React.useMemo(
    () => ({
      classesCount: classesData?.graduatingYearsList.count || 0,
      graduatedUsersCount: _.sumBy(
        classesData?.graduatingYearsList.items,
        graduatingYear => graduatingYear.graduateCount || 0,
      ),
    }),
    [classesData],
  );

  const graduatingYears = React.useMemo(() => {
    return _.uniq(
      classesData?.graduatingYearsList.items.map(graduatingYear => Number(graduatingYear.year)),
    );
  }, [classesData?.graduatingYearsList.items]);

  const usersWithClassesCount = React.useMemo(() => {
    return (
      usersData?.usersList.items.filter(user =>
        graduatingYears.includes(getUserGraduationYear(user)),
      ).length || 0
    );
  }, [graduatingYears, usersData?.usersList.items]);

  const usersWithoutClassesCount = React.useMemo(() => {
    return (
      usersData?.usersList.items.filter(
        user => !graduatingYears.includes(getUserGraduationYear(user)),
      ).length || 0
    );
  }, [usersData?.usersList.items, graduatingYears]);

  const averageCompletion = React.useMemo(() => {
    const averageCompletionCount =
      graduatedUsersCount && usersWithClassesCount
        ? ((usersWithClassesCount / graduatedUsersCount) * 100).toFixed(1)
        : 0;

    return `${averageCompletionCount}%`;
  }, [graduatedUsersCount, usersWithClassesCount]);

  const refetchScorecardStats = React.useCallback(async () => {
    setIsRefetch(true);
    try {
      await refetchClassesData();
      await refetchUsersData();
    } catch (e: any) {
      sendToSentry(e);
    }
    setIsRefetch(false);
  }, [refetchClassesData, refetchUsersData]);

  return {
    isScorecardStatsLoading,
    graduatingYears,
    usersWithClassesCount,
    usersWithoutClassesCount,
    classesCount,
    averageCompletion,
    refetchScorecardStats,
  };
};
