import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { DISCOUNT_STATUSES } from '@jebel/constants';

import { SpreadsheetStatsProps } from 'shared/components/ui/SpreadsheetStats';
import { DiscountsStatsQuery, DiscountsStatsQueryVariables } from 'shared/graphql/__generated__';

import { DISCOUNT_ACTIVATION_DAYS_COUNT } from '../constants';

const DISCOUNT_STATS_QUERY = gql`
  query DiscountsStats($totalDiscountsDaysCount: Int!) {
    pendingDiscounts: discountsList(filter: { status: { equals: "${DISCOUNT_STATUSES.pending}" } }) {
      count
    }
    activeDiscounts: discountsList(filter: { status: { equals: "${DISCOUNT_STATUSES.active}" } }) {
      count
    }
    totalDiscounts: discountActivationPerDatesList(last: $totalDiscountsDaysCount) {
      items {
        id
        count
        date
      }
    }
  }
`;
export const useDiscountsStats = () => {
  const { data, refetch } = useQuery<DiscountsStatsQuery, DiscountsStatsQueryVariables>(
    DISCOUNT_STATS_QUERY,
    { variables: { totalDiscountsDaysCount: DISCOUNT_ACTIVATION_DAYS_COUNT } },
  );

  const { totalReedemed, activeDiscounts, pendingDiscounts } = React.useMemo(
    () => ({
      totalReedemed:
        data?.totalDiscounts.items.reduce((sum, discount) => sum + (discount?.count || 0), 0) || 0,
      activeDiscounts: data?.activeDiscounts.count || 0,
      pendingDiscounts: data?.pendingDiscounts.count || 0,
    }),
    [data],
  );

  const discountStats: SpreadsheetStatsProps['data'] = [
    { label: 'Total Active Discounts', value: activeDiscounts },
    { label: 'Total Discounts Redeemed (last 30 days)', value: totalReedemed },
    { label: 'Pending Approval', value: pendingDiscounts, color: 'secondary' },
  ];

  return { discountStats, refetchDiscountsStats: refetch };
};
