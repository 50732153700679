import { ChangeEvent, Dispatch, Fragment, SetStateAction } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { MyLocation } from '@material-ui/icons';
import { FilterAlt } from '@mui/icons-material';

import { DISTANCE_FILTER_IN_MILES } from '@jebel/constants';

import { Select, Typography, Button } from 'shared/components/ui';
import { FIELDS_CONFIG } from 'shared/constants';
import { SearchControl, useSearchContext } from 'shared/features/search';
import { useCurrentLocationZip, useResponsive, useToast } from 'shared/hooks';
import { SearchCareerFilter } from 'features/career/types';

type ExplorerFilterProps = {
  selectedFilter: SearchCareerFilter;
  setFilters: Dispatch<SetStateAction<SearchCareerFilter>>;
};

export const CareerFilter = ({ selectedFilter, setFilters }: ExplorerFilterProps) => {
  const { isMobile } = useResponsive();
  const { setZipQuery } = useSearchContext();
  const { loading: searchingZip, onSearchZip } = useCurrentLocationZip();
  const { showError } = useToast();

  const switchIndustryOption = (event: any) => {
    setFilters(prev => ({ ...prev, type: event.target.value }));
  };

  const onDistanceChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
  ) => {
    setFilters(prev => ({ ...prev, radius: event.target.value }));
  };

  const handleUsePosition = async () => {
    navigator.geolocation.getCurrentPosition(async position => {
      try {
        const lng = position?.coords.longitude;
        const lat = position?.coords.latitude;

        const response = await onSearchZip(lng.toString(), lat.toString());
        const zip = response.data?.currentLocationZipRequest.zip;

        if (zip) {
          setZipQuery(zip);
        }
      } catch (err) {
        showError('Something went wrong getting your zip-code, please try again.');

        if (err instanceof Error) {
          showError(err.message);
        }
      }
    });
  };

  return (
    <Box display="grid" gridGap="1rem">
      {!isMobile && (
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          <FilterAlt />
          <Typography variant="subtitle5">Filter by</Typography>
        </Box>
      )}

      <Select
        variant="outlined"
        label="TYPE"
        onChange={switchIndustryOption}
        value={selectedFilter?.type}
      >
        {Object.values(FIELDS_CONFIG.type).map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>

      <SearchControl
        withTextField={false}
        withZipFilterField
        zipFilterFieldProps={{ placeholder: 'ZIP Code' }}
      />

      <Select
        variant="outlined"
        renderValue={value => <Fragment>{value}</Fragment>}
        label="Distance"
        onChange={onDistanceChange}
        value={selectedFilter?.radius}
      >
        {Object.values(DISTANCE_FILTER_IN_MILES).map(distance => (
          <MenuItem key={distance.toString()} value={distance.toString()}>
            {distance.toString()}
          </MenuItem>
        ))}
      </Select>

      <Button
        type="button"
        color="secondary"
        onClick={handleUsePosition}
        startIcon={<MyLocation color="inherit" />}
        loading={searchingZip}
      >
        Use current location
      </Button>
    </Box>
  );
};
