import React from 'react';
import { Divider } from '@mui/material';
import { DateTime } from 'luxon';

import { formatISO8601Date } from '@jebel/utils';

import { DashboardMembersCharts } from '../DashboardMembersCharts';
import { DashboardAdvertisingCharts } from '../DashboardAdvertisingCharts';
import { useDashboardStats, useMembersCountByAffiliation } from '../../hooks';
import { useDateFilterState } from '../../hooks/useDateFilterState';
import { DateFilterType } from '../../types';

const dateFilterWeek: DateFilterType = {
  startDate: DateTime.now().startOf('week').setLocale('en-US'),
  endDate: DateTime.now().endOf('day').setLocale('en-US'),
};

export function DashboardCharts() {
  const [adDateFilter, setPartialAdDateFilter] = useDateFilterState(dateFilterWeek);
  const [membersDateFilter, setPartialMembersDateFilter] = useDateFilterState(dateFilterWeek);
  const {
    dashboardsMembersList,
    dashboardAdvertisementsData,
    onDashboardAdvertisementsSearch,
    onDashboardMembersSearch,
    advertisingLoading,
    membersLoading,
  } = useDashboardStats();

  const {
    totalMembersByAffiliationCount,
    loading: memberAffiliationLoading,
    onMembersCountByAffiliationSearch,
  } = useMembersCountByAffiliation();

  React.useEffect(() => {
    const startDateMembers = formatISO8601Date(membersDateFilter.startDate);
    const endDateMembers = formatISO8601Date(membersDateFilter.endDate);
    onDashboardMembersSearch(startDateMembers, endDateMembers);
    onMembersCountByAffiliationSearch(startDateMembers, endDateMembers);
  }, [membersDateFilter, onDashboardMembersSearch, onMembersCountByAffiliationSearch]);

  React.useEffect(() => {
    const startDateAdvertising = formatISO8601Date(adDateFilter.startDate);
    const endDateAdvertising = formatISO8601Date(adDateFilter.endDate);
    onDashboardAdvertisementsSearch(startDateAdvertising, endDateAdvertising);
  }, [adDateFilter, onDashboardAdvertisementsSearch]);

  return (
    <React.Fragment>
      <DashboardMembersCharts
        dateFilter={membersDateFilter}
        data={dashboardsMembersList}
        totalMembersByAffiliationCount={totalMembersByAffiliationCount}
        loading={memberAffiliationLoading || membersLoading}
        setDateFilter={setPartialMembersDateFilter}
      />
      <Divider variant="fullWidth" flexItem sx={{ mt: 4, mb: 2 }} />
      <DashboardAdvertisingCharts
        dateFilter={adDateFilter}
        data={dashboardAdvertisementsData}
        loading={advertisingLoading}
        setDateFilter={setPartialAdDateFilter}
      />
    </React.Fragment>
  );
}
