import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { EventUpdateInput, useEventUpdateMutation } from 'shared/graphql';

export function useEventEdit() {
  const [eventEdit, { loading, error }] = useEventUpdateMutation({
    refetchQueries: ['EventSearch', 'EventsCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The event has been updated',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The event hasn't been updated. Please review carefully and try again.`,
    },
  });

  const onEventEdit = (data: EventUpdateInput, id: string) => {
    return eventEdit({
      variables: {
        data: { ...data, id },
      },
    });
  };

  return {
    error,
    loading,
    onEventEdit,
  };
}
