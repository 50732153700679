import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem;
`;

export const Header = styled.div`
  ${contentCSS};

  border-bottom: 1px solid ${p => p.theme.palette.divider};
`;

export const Content = styled.div`
  ${contentCSS};
`;

export const PresetOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
