import React from 'react';
import { css } from '@emotion/react';
import { Divider } from '@material-ui/core';
import { Box, CircularProgress, Typography } from '@mui/material';

import { DISCOUNT_CATEGORIES } from 'shared/constants/discounts';
import { useSearchContext } from 'shared/features/search';
import { DiscountListItemFragment } from 'shared/graphql';
import { useGlobalState } from 'providers/GlobalStateProvider';
import { PaperLayout } from 'shared/components/layouts';
import { Modal } from 'shared/components/ui';
import { useCurrentLocationZip, useModalState, useToast } from 'shared/hooks';

import { useDiscountsList } from '../hooks/useDiscountsList';
import { DiscountItem } from './DiscountItem';
import { DiscountsHeader } from './DiscountsHeader';
import { ReedemDiscountModal } from './ReedemDiscountModal';
import { DiscountsFiltersModal } from './DiscountsFiltersModal';
import { useDiscountActivity } from '../hooks/useDiscountActivity';

export function DiscountsContent() {
  const { currentDiscount } = useGlobalState();
  const { addView, addClick } = useDiscountActivity();
  const { showError } = useToast();

  const hasCurrentSelectedDiscount = Boolean(currentDiscount.currentRedeemedDiscount);

  const {
    show: isRedeemOpenModal,
    params: selectedDiscount,
    open: openRedeemModal,
    close: closeRedeemModal,
  } = useModalState<DiscountListItemFragment>({
    show: hasCurrentSelectedDiscount,
    params: currentDiscount.currentRedeemedDiscount,
  });

  const {
    items: discountsList,
    count: discountsCount,
    loading,
    setLocalZipQuery,
  } = useDiscountsList();

  const { filter, isFilterModalOpen, setIsFilterModalOpen, setZipQuery } = useSearchContext();
  const { loading: currentLocationLoading, onSearchZip } = useCurrentLocationZip();

  const onPositionGet = React.useCallback(
    async () =>
      navigator.geolocation.getCurrentPosition(async position => {
        try {
          const lng = position?.coords.longitude;
          const lat = position?.coords.latitude;

          const response = await onSearchZip(lng.toString(), lat.toString());
          const zip = response.data?.currentLocationZipRequest.zip;

          if (zip) {
            setZipQuery(zip);
          }
        } catch (err) {
          showError('Something went wrong getting your zip-code, please try again.');

          if (err instanceof Error) {
            showError(err.message);
          }
        }
      }),
    [onSearchZip, setZipQuery, showError],
  );

  const onClose = () => {
    currentDiscount.clearCurrentRedeemedDiscount();
    closeRedeemModal();
  };

  const onOpen = (discount: DiscountListItemFragment) => () => {
    openRedeemModal(discount);

    if (discount.id) {
      addView(currentDiscount?.currentRedeemedDiscount?.id);

      addClick(discount.id);
    }
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const renderDiscountList = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          sx={{ width: '100%', height: '10rem' }}
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (discountsList?.length === 0) {
      return (
        <Box
          sx={{ width: '100%', height: '10rem' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="GrayText">No discounts found</Typography>
        </Box>
      );
    }

    return (
      <div css={discountsContainerCSS}>
        {discountsList?.map(discount => (
          <DiscountItem discount={discount} key={discount.id} onReedem={onOpen(discount)} />
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      {isRedeemOpenModal && (
        <ReedemDiscountModal
          isOpen={isRedeemOpenModal}
          discount={selectedDiscount}
          onClose={onClose}
        />
      )}

      <Modal
        dialogProps={{
          open: isFilterModalOpen,
          onClose: onFilterModalClose,
          fullWidth: true,
          maxWidth: 'xs',
        }}
        titleProps={{ title: 'Filter by' }}
        isVisibleDivider
        dialogContentWidth={280}
      >
        <DiscountsFiltersModal
          onModalClose={onFilterModalClose}
          currentLocationLoading={currentLocationLoading}
          onPositionGet={onPositionGet}
          setLocalZipQuery={setLocalZipQuery}
        />
      </Modal>

      <PaperLayout>
        <Box css={container225CSS}>
          <DiscountsHeader
            currentLocationLoading={currentLocationLoading}
            onPositionGet={onPositionGet}
          />

          <Box marginLeft={3}>
            <Typography css={itemsCountTextCss}>{`${discountsCount} items found`}</Typography>
          </Box>

          <Divider
            absolute={false}
            flexItem={false}
            light={false}
            orientation="horizontal"
            variant="fullWidth"
          />

          <Box marginLeft={3}>
            <Typography css={categoryTextCss}>
              {DISCOUNT_CATEGORIES.find(item => item.key === filter)?.value || ''}
            </Typography>
          </Box>

          {renderDiscountList()}
        </Box>
      </PaperLayout>
    </React.Fragment>
  );
}

const discountsContainerCSS = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 1rem;
  padding: 2rem;
`;

const container225CSS = css`
  display: grid;
  grid-gap: 25px;
`;

const itemsCountTextCss = css`
  color: #0089ff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const categoryTextCss = css`
  color: #162d46;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
`;
