import { useQuery } from '@apollo/client';

import { LIST_PINNED_POSTS } from '../../../../features/home/queries';

export const useGetPinnedPosts = () => {
  const {
    data: pinnedPostData,
    loading: pinnedPostLoading,
    fetchMore: pinnedPostFetchMore,
    refetch: pinnedPostRefetch,
  } = useQuery(LIST_PINNED_POSTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  return { pinnedPostData, pinnedPostLoading, pinnedPostFetchMore, pinnedPostRefetch };
};
