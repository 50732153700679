import { useMemo } from 'react';
import { Skeleton } from '@mui/material';

import { useFilesListQuery } from 'shared/graphql';
import { MediaGallery } from 'shared/components/ui';
import { useHomeFeedCardHaveBeenShown } from 'shared/features/posts/providers';

import { useHomeFeedMedia } from '../../useHomeFeedMedia';
import { SkeletonContainer } from './HomeFeedCardGallery.styles';

export function HomeFeedCardGallery() {
  const media = useHomeFeedMedia();
  const haveBeenShown = useHomeFeedCardHaveBeenShown();

  const identifiers = useMemo(() => {
    const files = media?.items ?? [];
    return files.map(file => String(file.fileId));
  }, [media]);

  const { data: response, loading } = useFilesListQuery({
    skip: identifiers.length === 0 || !haveBeenShown,
    variables: { ids: identifiers },
  });

  const files = response?.filesList.items ?? [];

  if (!media || identifiers.length === 0) {
    return null;
  }

  if (loading) {
    return (
      <SkeletonContainer>
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </SkeletonContainer>
    );
  }

  return <MediaGallery media={files} />;
}
