import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  --logo-height: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  pointer-events: none;
  user-select: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
`;

// Text

export const Text = styled.span``;

// Logo

export const Logo = styled.img`
  height: var(--logo-height);
`;
