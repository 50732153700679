import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import type {
  FileInputProps,
  FileInputValue,
  OriginalFileInputValue,
} from '@8base-react/file-input';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PickerOptions } from 'filestack-js';
import pluralize from 'pluralize';
import { Close } from '@material-ui/icons';
import { css } from '@emotion/react';

import { fileSizeToHuman, processFilestackUrlSrcSet } from '@jebel/utils';

import { ONE_MEGABYTE } from 'shared/constants/files';
import { ResultFile, ResultFileValue } from 'shared/types/files';
import { isEmptyObject } from 'shared/utils/object';

import { FileInputWrap as FileInput } from '../FileInputWrap';

const MAX_FILE_SIZE = ONE_MEGABYTE * 10;

const previewNameCSS = css`
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const renderImage = (
  preview: ResultFile,
  onDelete: (fileId: string) => void,
  showDescription: boolean,
  showFileSize = false,
) => {
  const filename = preview.filename ?? '(File)';

  return (
    <ListItem key={preview.fileId}>
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          src={preview.downloadUrl || ''}
          alt={preview.filename || ''}
          srcSet={processFilestackUrlSrcSet(preview.downloadUrl || '', {
            resize: {
              width: 65,
            },
          })}
        />
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box display="flex" alignItems="center" gridGap="0.5rem">
            {showDescription && (
              <Typography variant="body2" title={filename} css={previewNameCSS}>
                {preview.filename}
              </Typography>
            )}

            <IconButton
              size="small"
              aria-label="Close"
              aria-roledescription="Button"
              onClick={() => onDelete(preview.fileId ?? '')}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
        }
        secondary={
          showFileSize && (
            <Typography variant="body2">
              {fileSizeToHuman(preview.size, 'Unknown file size')}
            </Typography>
          )
        }
      />
    </ListItem>
  );
};

export interface ImageInputProps {
  label?: string;
  name?: string;
  initialValue?: ResultFileValue | null;
  value?: ResultFileValue;
  /**
   * @default true
   */
  showPreviewImage?: boolean;
  pickerOptions?: PickerOptions;
  inputProps?: FileInputProps;
  /** show size and name of the file */
  showDescription?: boolean;
  disabled?: boolean;
  maxFiles?: number;

  customPreview?: ReactNode | ((value: ResultFileValue) => ReactNode);
  customPicker?: ReactNode;

  /** @deprecated Use either {@linkcode customPreview} or {@linkcode customPicker}. */
  children?: ReactNode;

  /** @deprecated */
  height?: number;
  /** @deprecated */
  width?: number | string;

  onDelete?: () => void;
  onChange?(file: ResultFileValue | null): void;
}

export function ImageInput({
  label,
  children,
  maxFiles = 6,
  initialValue,
  value,
  showPreviewImage = true,
  showDescription = true,
  pickerOptions,
  inputProps,
  disabled = false,
  customPicker,
  customPreview,
  onChange,
  onDelete,
}: ImageInputProps) {
  const [selected, setFile] = useState<ResultFileValue | null>(() => initialValue ?? null);

  useEffect(() => {
    setFile(prev => value ?? prev);
  }, [value, setFile]);

  const pickerContent = useMemo(() => {
    if (customPicker) {
      return customPicker;
    }

    return (
      <Fragment>
        {children}
        {label && (
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
        )}
      </Fragment>
    );
  }, [children, customPicker, label]);

  const handleChange = useCallback(
    (value: typeof selected) => {
      if (value === null) {
        setFile(null);
        onChange?.(null);

        return;
      }

      let files: ResultFileValue = Array.isArray(value) ? value : [value];

      if (maxFiles > 1) {
        files = files.slice(0, maxFiles);
      }

      if (maxFiles === 1) {
        files = files[0];
      }

      setFile(files);
      onChange?.(files);
    },

    [maxFiles, setFile, onChange],
  );

  const onImageDelete = useCallback(
    (fileId: string) => {
      if (Array.isArray(selected) && selected.length >= 1) {
        const files: ResultFileValue = selected.filter(value => value.fileId !== fileId);

        handleChange(files);
        return;
      }

      handleChange(null);
    },
    [selected, handleChange],
  );

  const addImages = useCallback(
    (result: ResultFileValue) => {
      const files: ResultFile[] = [];

      if (Array.isArray(selected)) {
        files.push(...selected);
      }

      if (selected && !Array.isArray(selected)) {
        files.push(selected);
      }

      if (Array.isArray(result)) {
        files.push(...result);
      }

      if (result && !Array.isArray(result)) {
        files.push(result);
      }

      if (maxFiles === 1 && files.length === 2) {
        const [, last] = files;
        // Select the last element to replace the selected one
        handleChange(last);
        return;
      }

      handleChange(files);
    },
    [handleChange, maxFiles, selected],
  );

  const previews = useMemo(() => {
    if (!showPreviewImage || !selected) {
      return null;
    }

    if (typeof customPreview === 'function') {
      return customPreview(selected);
    }

    if (customPreview) {
      return customPreview;
    }

    if (Array.isArray(selected)) {
      return (
        <Fragment>
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              Uploaded {selected.length} {pluralize('file', selected.length)} out of {maxFiles}
            </Typography>
          </Box>

          <List>
            {selected.map(file =>
              renderImage(file, onImageDelete, showDescription, Boolean(file?.size)),
            )}
          </List>
        </Fragment>
      );
    }

    return renderImage(selected, onImageDelete, showDescription, Boolean(selected?.size));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected,
    children,
    maxFiles,
    customPreview,
    onImageDelete,
    showDescription,
    showPreviewImage,
  ]);

  const onPickImage = useCallback(
    (pick: (options: PickerOptions) => void) => {
      // picker spamming his script into DOM on every pick
      document.getElementById('fs-loader-picker')?.remove();
      if (!disabled) {
        pick({
          accept: 'image/*',
          fromSources: ['local_file_system'],
          maxSize: MAX_FILE_SIZE,
          ...pickerOptions,
        });
      }
    },
    [disabled, pickerOptions],
  );

  const onUploadDone = useCallback(
    async (value: FileInputValue, original: OriginalFileInputValue | undefined) => {
      if (Array.isArray(value) && Array.isArray(original)) {
        const images = value.map((image, index) => {
          return { ...image, size: original[index]?.size };
        });

        addImages(images);
        return value;
      }

      if (!Array.isArray(value) && !Array.isArray(original)) {
        addImages({ ...value, size: original?.size });
      }

      return value;
    },
    [addImages],
  );

  const filesLeft = useMemo(() => {
    let count = 0;

    if (Array.isArray(selected)) {
      count = selected.length;
    } else if (selected) {
      count = 1;
    }

    return maxFiles - count;
  }, [maxFiles, selected]);

  const handleDelete = () => {
    setFile(null);
    if (typeof onDelete === 'function') onDelete();
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="0.5rem">
      <Box>
        {filesLeft > 0 && (
          <FileInput maxFiles={filesLeft} onUploadDone={onUploadDone} {...inputProps}>
            {({ pick }) => {
              return (
                <Box
                  onClick={() => {
                    onPickImage(pick);
                  }}
                >
                  {pickerContent}
                </Box>
              );
            }}
          </FileInput>
        )}

        {previews}
      </Box>

      {typeof onDelete === 'function' && showPreviewImage && !isEmptyObject(selected) && (
        <Box display="flex" justifyContent="center">
          <IconButton size="small" onClick={handleDelete}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export type { ResultFile };
