import { DateTime } from 'luxon';

import {
  LegacyFeedPostsPostsMonthsRelationInput,
  useLegacyFeedPostCreateMutation,
  LegacyFeedPostCreateInput,
  InputMaybe,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

import { useLegacyTimeStamps } from './useLegacyTimeStamps';

export function useLegacyFeedPostCreate() {
  const { userId, isAdmin } = useCurrentUser();
  const { data: createdYears } = useLegacyTimeStamps();

  const [mutate, { loading }] = useLegacyFeedPostCreateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'HomeFeedItems',
      'LegacyTimeStamps',
      'LegacyTimelineYears',
      'LegacyTimelineMonths',
      'LegacyTimelinePosts',
    ],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: `Your post will be moderated, we'll let you know when it's available on the feed.`,
      [SNACKBAR_ERROR_MESSAGE]: 'Something went wrong creating your post, please try again.',
    },
  });

  const relateLegacyMonth = (
    legacyDate: string,
  ): InputMaybe<LegacyFeedPostsPostsMonthsRelationInput> => {
    const { month, year } = DateTime.fromISO(legacyDate);

    const createdYear = createdYears.find(curr => curr.year === year);

    if (!createdYear) {
      return {
        create: {
          month,
          legacyYears: {
            create: {
              legacyYear: year,
            },
          },
        },
      };
    }

    const createdMonths = createdYear.months?.items ?? [];
    const createdMonth = createdMonths.find(curr => curr.month === month);

    if (!createdMonth) {
      return {
        create: {
          month,
          legacyYears: {
            connect: { id: createdYear.id },
          },
        },
      };
    }

    return {
      connect: { id: createdMonth.id },
    };
  };

  const create = async (data: LegacyFeedPostCreateInput, isScheduled = false) => {
    if (!userId || !isAdmin) {
      return;
    }

    const monthRelation = relateLegacyMonth(data.legacyDate);

    const payload: LegacyFeedPostCreateInput = {
      author: { connect: { id: userId } },
      postsMonths: monthRelation,
      ...data,
    };

    const response = await mutate({ variables: { data: payload } });
    // eslint-disable-next-line consistent-return
    return response;
  };

  return {
    create,
    loading,
  };
}
