import { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';

import { LegacyHeader } from '../LegacyHeader';

export function ContentLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Box flexGrow={1} minWidth={0} display="flex" flexDirection="column" gridGap={2.75}>
      <LegacyHeader />
      {children}
    </Box>
  );
}
