import React from 'react';
import { Paper, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { formatDate } from 'shared/utils/date';

export function DashboardHeader({ name }: { name: string }) {
  const today = formatDate(new Date(), DateTime.DATE_HUGE);
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  return (
    <Paper
      sx={{
        p: isSmUp ? 4 : 2,
        display: 'flex',
        justifyContent: isSmUp ? 'space-between' : 'center',
        flexFlow: 'row wrap',
      }}
    >
      <Typography variant="h5" fontWeight="medium">
        Welcome back, {name}
      </Typography>
      <Typography variant="body1" textTransform="capitalize" color="textSecondary">
        {today}
      </Typography>
    </Paper>
  );
}
