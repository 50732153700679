import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { SxProp } from 'shared/types/styles';

import { AsyncContent } from '../ui';

const layoutStyles: SxProp = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  height: '100%',
};

const titleBoxStyles: SxProp = {
  py: 3.125,
  px: 3.75,
};

const titleStyles: SxProp = theme => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  fontSize: theme.typography.fontSize + 7,
});

const MAX_CONTENT_SIZE = 600;

const contentLayoutStyles: SxProp = dividerColor => ({
  borderTop: `1px solid ${dividerColor}`,
});

const contentStyles: SxProp = {
  py: 3.125,
  px: 3.75,
  maxWidth: MAX_CONTENT_SIZE,
};

interface LayoutProps {
  isLoading?: boolean;
  title: string;
  content?: React.ReactNode;
}

export const SettingsContentLayout: React.FC<LayoutProps> = ({
  isLoading,
  children,
  title,
  content,
}) => {
  const theme: any = useTheme();

  return (
    <Box sx={layoutStyles}>
      <Box sx={titleBoxStyles}>
        <Typography sx={titleStyles}>{title}</Typography>
      </Box>
      {content || (
        <Box sx={() => contentLayoutStyles(theme.palette.border)}>
          <AsyncContent center loading={!!isLoading}>
            <Box sx={contentStyles}>{children}</Box>
          </AsyncContent>
        </Box>
      )}
    </Box>
  );
};
