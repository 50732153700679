import { object, string, array } from 'yup';
const SCHEMA = object({
    items: array(object({
        mentionId: string().nullable().optional(),
        mentionText: string().nullable().optional(),
        position: string().nullable().optional(),
        type: string().nullable().optional(),
    }))
        .nullable()
        .optional(),
})
    .nullable()
    .optional();
/**
 * Extract the mentions from the stringified JSON in the `HomeFeedItem`.
 * @throws When the string is not a valid JSON.
 * @throws When the content of the JSON is not valid.
 * @deprecated Use the new Mentions API instead. See https://8base-dev.atlassian.net/browse/JEB-1458.
 */
export function extractHomeFeedItemMentions(mentions) {
    if (typeof mentions === 'string') {
        const content = JSON.parse(mentions);
        return SCHEMA.cast(content);
    }
    return SCHEMA.cast(mentions);
}
