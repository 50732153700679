import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { HomePage } from './pages/HomePage';

export const getHomeRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.home.index,
    exact: true,
    component: HomePage,
  },
];
