import { Box } from '@material-ui/core';
import React from 'react';

import { APP_URL } from 'shared/constants';
import { useUserById } from 'shared/hooks/useUserById';
import { buildUrl } from 'shared/routes/routerUtils';

import { SettingsMobileNavbarList } from './SettingsMobileNavbarList';
import { SettingsMobileNavbarUserProfile } from './SettingsMobileNavbarUserProfile';

const MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: APP_URL.user.settings.information,
  },
  {
    title: 'Sign-in & Security',
    icon: 'Lock',
    route: APP_URL.user.settings.security,
  },
];

const ADDITIONAL_SIDEBAR_LIST_DATA = [
  { title: 'Groups', icon: 'People', route: APP_URL.user.settings.groups },
  {
    title: 'Edit Public Profile',
    icon: 'Assignment',
    route: APP_URL.user.settings.profile,
  },
];

export const SettingsMobileNavbar = ({
  isAdminRoute = false,
  userId = undefined,
}: {
  isAdminRoute: boolean;
  userId?: string | null | undefined;
}) => {
  const { user, loading: loadingUser } = useUserById({ id: userId });

  const ADMIN_MAIN_SIDEBAR_LIST_DATA = React.useMemo(() => {
    return [
      {
        title: 'Account Information',
        icon: 'AccountCircle',
        route: userId
          ? buildUrl(APP_URL.admin.settings.information, { pathParams: { id: userId } })
          : '',
      },
    ];
  }, [userId]);

  return (
    <Box>
      <SettingsMobileNavbarUserProfile user={user} loading={loadingUser} />

      <SettingsMobileNavbarList
        listData={
          isAdminRoute
            ? ADMIN_MAIN_SIDEBAR_LIST_DATA
            : [...MAIN_SIDEBAR_LIST_DATA, ...ADDITIONAL_SIDEBAR_LIST_DATA]
        }
      />
    </Box>
  );
};
