import styled from '@emotion/styled';
import { Breakpoint } from '@mui/material';

const MOBILE_BREAKPOINT: Breakpoint = 'sm';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${p => p.theme.breakpoints.up(MOBILE_BREAKPOINT)} {
    max-height: 80vh;
    overflow-y: hidden;
  }
`;

// Content

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${p => p.theme.breakpoints.up(MOBILE_BREAKPOINT)} {
    max-height: 100%;
    grid-template-columns: 35fr 65fr;
  }
`;

// ChatsContainer

export const ChatsContainer = styled.div`
  min-width: 0;

  ${p => p.theme.breakpoints.up(MOBILE_BREAKPOINT)} {
    overflow-y: auto;
    border-right: solid 1px;
    border-right-color: ${p => p.theme.palette.divider};
  }
`;

// MessagesContainer

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${p => p.theme.breakpoints.up(MOBILE_BREAKPOINT)} {
    overflow-y: auto;
  }
`;
