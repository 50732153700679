import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { FormSelectOrganization } from 'shared/components/form';
import { OrganizationItemFragment } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

import { Values } from './EventsCreateModal';

const FIELD_NAME = 'otherSponsors';

export function FormOtherSponsors() {
  const { values, setFieldValue } = useFormikContext<Values>();
  const { isAdmin } = useCurrentUser();

  useEffect(() => {
    const otherSponsors = values.otherSponsors ?? [];
    const hasTitleSponsor = otherSponsors.some(option => values.titleSponsor?.id === option.id);

    if (hasTitleSponsor) {
      // This prevents to have one title sponsor in the both fields.
      // By removing the title sponsor from the other sponsors field.

      const withoutTitleSponsor = filterTitleSponsor(otherSponsors);
      setFieldValue(FIELD_NAME, withoutTitleSponsor);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.titleSponsor, values.otherSponsors]);

  const filterTitleSponsor = (options: OrganizationItemFragment[]) => {
    return options.filter(option => !isEqual(values.titleSponsor?.id, option.id));
  };

  return (
    <FormSelectOrganization
      name={FIELD_NAME}
      label="Other Sponsors"
      variant="outlined"
      onlyManaged={!isAdmin}
      filterOptions={filterTitleSponsor}
      onlyActive
      multiple
    />
  );
}
