import { useMemo } from 'react';
import { EChartsOption as ChartOptions } from 'echarts';
import Chart from 'echarts-for-react';
import { useTheme } from '@emotion/react';

import { LinearChartProps } from './LinearChart.types';
import { Container } from './LinearChart.styles';
import { createLinearChartOptions } from './createLinearChartOptions';

export function LinearChart(props: LinearChartProps) {
  const theme = useTheme();

  const options = useMemo<ChartOptions>(() => {
    return createLinearChartOptions(props, theme);
  }, [props, theme]);

  return (
    <Container>
      <Chart option={options} notMerge lazyUpdate />
    </Container>
  );
}
