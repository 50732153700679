import React from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { useField } from 'formik';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Icon, ResultFile } from 'shared/components/ui';

interface ImageViewerProps {
  name: string;
}

const StyledImage = styled('img')`
  width: 64px;
  height: 40px;
  object-fit: cover;
`;

export const ImageViewer: React.FC<ImageViewerProps> = props => {
  const [{ value: file }, _, { setValue }] = useField<ResultFile | undefined | null>(props);

  const handleClearImage = () => {
    setValue(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontSize={14} height="auto" color={theme => theme.palette.text.disabled}>
        {file ? '1 file uploaded' : 'No files has been uploaded'}
      </Typography>
      {file && (
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <StyledImage
            srcSet={processFilestackUrlSrcSet(file?.downloadUrl ?? '', {
              resize: {
                width: 200,
              },
            })}
            src={file?.downloadUrl || ''}
          />
          <Typography
            maxWidth={200}
            fontSize="14px"
            fontWeight={600}
            textOverflow="ellipsis"
            overflow="hidden"
            noWrap
            height="auto"
          >
            {file?.filename}
          </Typography>
          <IconButton color="error" onClick={handleClearImage}>
            <Icon name="Clear" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
