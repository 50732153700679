import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ExplorerSupportersList } from 'features/explorer/components/Explorer';
import { ROUTES } from 'shared/constants';
import { useExplorerOrganizations } from 'features/explorer/hooks';
import { useQueryParams } from 'shared/hooks';

import { SearchSection } from '../SearchSection';

interface Props {
  canFetchMore?: boolean;
}

const PREVIEW_COUNT = 3;

export function SearchPageOrganizations(props: Props) {
  const [params] = useQueryParams();

  const { push: navigate } = useHistory();

  const { data, count, loading, refreshing, hasMore, fetchMore } = useExplorerOrganizations({
    first: props.canFetchMore ? undefined : PREVIEW_COUNT,
  });

  const title = useMemo(() => `Organizations (${count})`, [count]);

  const showMore = () => {
    const search = new URLSearchParams(params as Record<string, string>);
    const route = `${ROUTES.user.search.organizations}?${search}`;

    navigate(route);
  };

  return (
    <SearchSection
      title={title}
      loading={loading}
      fetching={refreshing}
      hasMore={hasMore}
      showMoreLabel="Show all organizations"
      canShowMore={props.canFetchMore}
      onShowMore={showMore}
      onFetchMore={fetchMore}
    >
      <ExplorerSupportersList supportersList={data} viewAll />
    </SearchSection>
  );
}
