import { gql } from '@apollo/client';

export const DISCOUNT_PRESENTATION_FRAGMENT = gql`
  fragment DiscountPresentation on Discount {
    id
    title
    terms
    amountPercent
    type
    startDate
    expirationDate
    category

    organization {
      id
      name

      logo {
        downloadUrl
      }
    }

    images {
      items {
        downloadUrl
      }
    }
  }
`;
