import { INBOX_SUBJECT_FROM_SCHOOL_SCHEMA, INBOX_SUBJECT_FROM_SYSTEM_SCHEMA, INBOX_SUBJECT_FROM_USER_SCHEMA, INBOX_SUBJECT_SCHEMA, } from '@jebel/constants';
import { isValidJSON } from './types';
/** Read the `subject` and check wether it can be a valid JSON. */
function isSerialized(subject) {
    return typeof subject === 'string' && isValidJSON(subject);
}
/** Check the given `subject` as instance of `InboxSubjectSchool`. */
export function isInboxSubjectFromSchool(subject) {
    const commit = INBOX_SUBJECT_FROM_SCHOOL_SCHEMA.safeParse(subject);
    return commit.success;
}
/** Check the given `subject` as instance of `InboxSubjectSystem`. */
export function isInboxSubjectFromSystem(subject) {
    const commit = INBOX_SUBJECT_FROM_SYSTEM_SCHEMA.safeParse(subject);
    return commit.success;
}
/** Check the given `subject` as instance of `InboxSubjectUser`. */
export function isInboxSubjectFromUser(subject) {
    const commit = INBOX_SUBJECT_FROM_USER_SCHEMA.safeParse(subject);
    return commit.success;
}
/** Check the given `subject` as instance of `InboxSubject`. */
export function isInboxSubject(subject) {
    const commit = INBOX_SUBJECT_SCHEMA.safeParse(subject);
    return commit.success;
}
/**
 * Parse the given `subject` as instance of `InboxSubjectSchool`.
 * @param subject Given inbox subject serialized either in JSON or object.
 * @throws When the given `subject` does not satisfies the schema.
 */
export function parseInboxSubjectFromSchool(subject) {
    return INBOX_SUBJECT_FROM_SCHOOL_SCHEMA.parse(subject);
}
/**
 * Parse the given `subject` as instance of `InboxSubjectSystem`.
 * @param subject Given inbox subject serialized either in JSON or object.
 * @throws When the given `subject` does not satisfies the schema.
 */
export function parseInboxSubjectFromSystem(subject) {
    return INBOX_SUBJECT_FROM_SYSTEM_SCHEMA.parse(subject);
}
/**
 * Parse the given `subject` as instance of `InboxSubjectUser`.
 * @throws When the given `subject` does not satisfies the schema.
 */
export function parseInboxSubjectFromUser(subject) {
    return INBOX_SUBJECT_FROM_USER_SCHEMA.parse(subject);
}
/**
 * Parse the given `subject` as instance of `InboxSubject`.
 * @throws When the given `subject` does not satisfies the schema.
 */
export function parseInboxSubject(subject) {
    return INBOX_SUBJECT_SCHEMA.parse(subject);
}
/**
 * Extracts the subject content from `subject` in case to be an instance of `InboxSubject`.
 * @throws When the given `subject` does not satisfies the schema.
 */
export function extractInboxSubjectContent(subject) {
    const parsed = parseInboxSubject(subject);
    if (typeof parsed === 'string') {
        return parsed;
    }
    return parsed.content;
}
/** Transform the given `subject` to be saved on the database. */
export function createInboxSubject(subject) {
    return subject;
}
