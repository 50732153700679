export const ROUTES = {
  splashPage: '/',
  public: {
    auth: '/auth',
    login: '/auth/login',
    linkedin: '/linkedin/',
    changePassword: '/auth/change-password/:resetPasswordCode',
    recovery: '/auth/recovery',
    recoverySent: '/auth/recovery-sent',
    request: '/auth/request',
    authConfirmation: '/auth/confirmation',
    authCallback: '/auth/callback',
    register: '/register',
  },
  user: {
    home: {
      index: '/home',
      /** @deprecated */
      searchIndex: '/search',
      /** @deprecated */
      search: '/search/:filter',
    },
    search: {
      index: '/search/',
      all: '/search/all',
      organizations: '/search/organizations',
      people: '/search/people',
      posts: '/search/posts',
    },
    mail: {
      index: '/mail',
      inbox: '/mail/inbox',
      sent: '/mail/sent',
      trash: '/mail/trash',
    },
    settings: {
      index: '/settings',
      information: '/settings/information',
      security: '/settings/security',
      notifications: '/settings/notifications',
      groups: '/settings/groups',
      profile: '/settings/profile',
    },
    organizations: {
      index: '/organizations',
      organization: '/organizations/:id',
    },
    explorer: {
      index: '/explorer',
      year: '/explorer/:year',
      profileIndex: '/explorer/profile',
      profile: '/explorer/profile/:id',
      profilePosts: '/explorer/profile/:id/posts',
    },
    news: {
      index: '/news',
    },
    collective: {
      index: '/collective',
      detailsIndex: '/collective/details',
      detail: '/collective/details/:id',
    },
    discounts: {
      index: '/discounts',
    },
    services: {
      index: '/services',
    },
    events: {
      index: '/events',
    },
    groups: {
      index: '/groups',
      details: '/groups/:id',
    },
    legacy: {
      index: '/legacy',
      all: '/legacy/all',
      year: '/legacy/:year',
      month: '/legacy/:year/:month',
    },
    scorecard: {
      index: '/scorecard',
    },
    store: {
      index: '/store',
    },
  },
  admin: {
    root: {
      index: '/admin',
    },
    home: {
      index: '/admin/dashboard',
    },
    dashboard: {
      index: '/admin/dashboard',
    },
    posts: {
      index: '/admin/posts',
    },
    settings: {
      index: '/admin/settings',
      details: '/admin/settings/:id/details',
      information: '/admin/settings/:id/information',
      siteSettings: '/admin/settings/siteSettings',
      navigation: '/admin/settings/navigation',
      news: '/admin/settings/news',
      siteAdmins: '/admin/settings/siteAdmins',
      customization: '/admin/settings/customization',
      roles: '/admin/settings/roles',
      contentModeration: '/admin/settings/content-moderation',
    },
    members: {
      index: '/admin/members',
      memberRoot: '/admin/members/:id',
      information: '/admin/members/:id/information',
    },
    contentCuration: {
      index: '/admin/contentCuration',
      flaggedPosts: '/admin/contentCuration/flaggedPosts',
      blacklist: '/admin/contentCuration/blacklist',
    },
    business: {
      index: '/admin/business',
      detailsRoot: '/admin/business/details',
      details: '/admin/business/details/:id',
      information: '/admin/business/details/:id/information',
      managers: '/admin/business/details/:id/managers',
      organizationDetails: '/organizations/:id',
    },
    events: {
      index: '/admin/events',
    },
    scorecard: {
      index: '/admin/scorecard',
    },
    groups: {
      index: '/admin/groups',
    },
    discounts: {
      index: '/admin/discounts',
    },
    legacy: {
      index: '/admin/legacy',
    },
    reports: {
      index: '/admin/reports',
    },
    mail: {
      index: '/admin/mail',
      inbox: '/admin/mail/inbox',
      sent: '/admin/mail/sent',
      trash: '/admin/mail/trash',
    },
  },
} as const;

export const APP_URL: Record<string, any> = ROUTES;

export const USER_BREADCRUMBS = [
  {
    title: '',
    path: APP_URL.user.home.index,
  },
  {
    title: 'Search',
    path: APP_URL.user.home.search,
  },
  {
    title: 'Inbox',
    path: APP_URL.user.mail.inbox,
  },
  {
    title: 'Sent',
    path: APP_URL.user.mail.sent,
  },
  {
    title: 'Trash',
    path: APP_URL.user.mail.trash,
  },
  {
    title: 'Settings',
    path: APP_URL.user.settings.index,
  },
  {
    title: 'Information',
    path: APP_URL.user.settings.information,
  },
  {
    title: 'Security',
    path: APP_URL.user.settings.security,
  },
  {
    title: 'Notifications',
    path: APP_URL.user.settings.notifications,
  },
  {
    title: 'Groups',
    path: APP_URL.user.settings.groups,
  },
  {
    title: 'Profile',
    path: APP_URL.user.settings.profile,
  },
  {
    title: 'Organizations',
    path: APP_URL.user.organizations.index,
  },
  {
    title: 'Details',
    path: APP_URL.user.organizations.organization,
  },
  {
    title: 'Explorer Nation',
    path: APP_URL.user.explorer.index,
  },
  {
    title: 'Year',
    path: APP_URL.user.explorer.year,
  },
  {
    title: 'Profile',
    path: APP_URL.user.explorer.profile,
  },
  {
    title: 'Posts',
    path: APP_URL.user.explorer.profilePosts,
  },
  {
    title: 'School News',
    path: APP_URL.user.news.index,
  },
  {
    title: 'Collective Intelligence',
    path: APP_URL.user.collective.index,
  },
  {
    title: 'Details',
    path: APP_URL.user.collective.detail,
  },
  {
    title: 'Discounts',
    path: APP_URL.user.discounts.index,
  },
  {
    title: 'Services',
    path: APP_URL.user.services.index,
  },
  {
    title: 'Events',
    path: APP_URL.user.events.index,
  },
  {
    title: 'Groups',
    path: APP_URL.user.groups.index,
  },
  {
    title: 'Details',
    path: APP_URL.user.groups.details,
  },
  {
    title: 'Legacy',
    path: APP_URL.user.legacy.index,
  },
  {
    title: 'Year',
    path: APP_URL.user.legacy.year,
  },
  {
    title: 'Month',
    path: APP_URL.user.legacy.month,
  },
  {
    title: 'Class Scorecard',
    path: APP_URL.user.scorecard.index,
  },
  {
    title: 'Store',
    path: APP_URL.user.store.index,
  },
];

export const ADMIN_BREADCRUMBS = [
  {
    title: 'Dashboard',
    path: APP_URL.admin.home.index,
  },
  {
    title: 'Dashboard',
    path: APP_URL.admin.dashboard.index,
  },
  {
    title: 'Posts',
    path: APP_URL.admin.posts.index,
  },
  {
    title: 'Settings',
    path: APP_URL.admin.settings.index,
  },
  {
    title: 'Details',
    path: APP_URL.admin.settings.details,
  },
  {
    title: 'Information',
    path: APP_URL.admin.settings.information,
  },
  {
    title: 'Site Settings',
    path: APP_URL.admin.settings.siteSettings,
  },
  {
    title: 'Navigation',
    path: APP_URL.admin.settings.navigation,
  },
  {
    title: 'News',
    path: APP_URL.admin.settings.news,
  },
  {
    title: 'Site Admins',
    path: APP_URL.admin.settings.siteAdmins,
  },
  {
    title: 'Customization',
    path: APP_URL.admin.settings.customization,
  },
  {
    title: 'Roles And Permissions',
    path: APP_URL.admin.settings.roles,
  },
  {
    title: 'Members',
    path: APP_URL.admin.members.index,
  },
  {
    title: 'Information',
    path: APP_URL.admin.members.information,
  },
  {
    title: 'Content Curation',
    path: APP_URL.admin.contentCuration.index,
  },
  {
    title: 'Flagged Posts',
    path: APP_URL.admin.contentCuration.flaggedPosts,
  },
  {
    title: 'Blacklist',
    path: APP_URL.admin.contentCuration.blacklist,
  },
  {
    title: 'Business',
    path: APP_URL.admin.business.index,
  },
  {
    title: 'Details',
    path: APP_URL.admin.business.details,
  },
  {
    title: 'Information',
    path: APP_URL.admin.business.information,
  },
  {
    title: 'Managers',
    path: APP_URL.admin.business.managers,
  },
  {
    title: 'Events',
    path: APP_URL.admin.events.index,
  },
  {
    title: 'Scorecard',
    path: APP_URL.admin.scorecard.index,
  },
  {
    title: 'Groups',
    path: APP_URL.admin.groups.index,
  },
  {
    title: 'Discounts',
    path: APP_URL.admin.discounts.index,
  },
  {
    title: 'Legacy',
    path: APP_URL.admin.legacy.index,
  },
  {
    title: 'Reports',
    path: APP_URL.admin.reports.index,
  },
  {
    title: 'Inbox',
    path: APP_URL.admin.mail.inbox,
  },
  {
    title: 'Sent',
    path: APP_URL.admin.mail.sent,
  },
  {
    title: 'Trash',
    path: APP_URL.admin.mail.trash,
  },
];
