import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { generatePath, useHistory } from 'react-router-dom';

import { useLegacyTimelineYears } from 'features/legacy/hooks';
import { Maybe } from 'shared/graphql';
import { ROUTES } from 'shared/constants';

import { LegacyTimeline } from '../LegacyTimeline';
import { LegacyTimelineSection } from '../LegacyTimelineSection';
import { LegacyTimelinePosts } from '../LegacyTimelinePosts';

export function LegacyTimelineYears() {
  const { data, loading, refreshing, hasMore, fetchMore } = useLegacyTimelineYears();
  const { push: navigate } = useHistory();

  const epoachs = useMemo(() => {
    return data.map(epoach => {
      const title = DateTime.now()
        .set({ year: Number(epoach.year) })
        .toFormat('yyyy');

      return { ...epoach, title };
    });
  }, [data]);

  const handleSeeMore = (year: Maybe<number> | undefined) => () => {
    const url = generatePath(ROUTES.user.legacy.year, { year: String(year) });

    navigate(url);
  };

  return (
    <LegacyTimeline
      data={epoachs}
      loading={loading}
      refreshing={refreshing}
      hasMore={hasMore}
      onFetchMore={fetchMore}
    >
      {epoach => (
        <LegacyTimelineSection
          title={epoach.title}
          key={epoach.year}
          onSeeMore={handleSeeMore(epoach.year)}
        >
          <LegacyTimelinePosts year={epoach.year} perPage={1} />
        </LegacyTimelineSection>
      )}
    </LegacyTimeline>
  );
}
