import {
  INDUSTRIES,
  JOB_EMPLOYMENT_TYPE,
  ORGANIZATION_STATUS,
  USER_AFFILIATIONS,
  GRADUATING_YEAR_START_YEAR as GRADUATING_YEAR_START,
} from '@jebel/constants';
import { createRange, generateGraduatingYearsOptions } from '@jebel/utils';

export const SELECT_OPTION_ALL = 'All';

/** @deprecated Use from `@jebel/constants` instead. */
export const GRADUATING_YEAR_START_YEAR = GRADUATING_YEAR_START;
const GRADUATING_YEAR_END_YEAR = 2099;

const YEARS = createRange(GRADUATING_YEAR_START_YEAR, GRADUATING_YEAR_END_YEAR).sort(
  (yearA, yearB) => yearB - yearA,
);

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/** @deprecated Use `generateGraduatingYearsOptions` from `@jebel/constants` instead. */
export const generateGraduatingYearFormConfig = generateGraduatingYearsOptions;
/** @deprecated Use `generateGraduatingYearsOptions` from `@jebel/constants` instead. */
export const generateGraduatingYears = generateGraduatingYearsOptions;

export const generateLegacyYearArray = (): { year: string }[] => {
  return [{ year: SELECT_OPTION_ALL }, ...YEARS.map(year => ({ year: String(year) }))];
};

export const generateLegacyMonthArray = (): { month: string }[] => {
  return [{ month: SELECT_OPTION_ALL }, ...MONTHS.map(month => ({ month }))];
};

export const FIELDS_CONFIG = {
  industry: [
    { value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL },
    { value: INDUSTRIES.accounting, label: 'Accounting' },
    { value: INDUSTRIES.alternativeDisputeResolution, label: 'Airlines/Aviation' },
    { value: INDUSTRIES.automotive, label: 'Alternative Dispute Resolution' },
    { value: INDUSTRIES.alternativeMedicine, label: 'Alternative Medicine' },
    { value: INDUSTRIES.animation, label: 'Animation' },
    { value: INDUSTRIES.apparelFashion, label: 'Apparel & Fashion' },
    { value: INDUSTRIES.architecturePlanning, label: 'Architecture & Planning' },
    { value: INDUSTRIES.artsCrafts, label: 'Arts and Crafts' },
    { value: INDUSTRIES.automotive, label: 'Automotive' },
    { value: INDUSTRIES.aviationAerospace, label: 'Aviation & Aerospace' },
    { value: INDUSTRIES.banking, label: 'Banking' },
    { value: INDUSTRIES.biotechnology, label: 'Biotechnology' },
    { value: INDUSTRIES.broadcastMedia, label: 'Broadcast Media' },
    { value: INDUSTRIES.buildingMaterials, label: 'Building Materials' },
    { value: INDUSTRIES.businessSuppliesEquipment, label: 'Business Supplies and Equipment' },
    { value: INDUSTRIES.capitalMarkets, label: 'Capital Markets' },
    { value: INDUSTRIES.chemicals, label: 'Chemicals' },
    { value: INDUSTRIES.civicSocialOrganization, label: 'Civic & Social Organization' },
    { value: INDUSTRIES.civilEngineering, label: 'Civil Engineering' },
    { value: INDUSTRIES.commercialRealEstate, label: 'Commercial Real Estate' },
    { value: INDUSTRIES.computerNetworkSecurity, label: 'Computer & Network Security' },
    { value: INDUSTRIES.computerGames, label: 'Computer Games' },
    { value: INDUSTRIES.computerHardware, label: 'Computer Hardware' },
    { value: INDUSTRIES.computerNetworking, label: 'Computer Networking' },
    { value: INDUSTRIES.computerSoftware, label: 'Computer Software' },
    { value: INDUSTRIES.construction, label: 'Construction' },
    { value: INDUSTRIES.consumerElectronics, label: 'Consumer Electronics' },
    { value: INDUSTRIES.consumerGoods, label: 'Consumer Goods' },
    { value: INDUSTRIES.consumerServices, label: 'Consumer Services' },
    { value: INDUSTRIES.cosmetics, label: 'Cosmetics' },
    { value: INDUSTRIES.dairy, label: 'Dairy' },
    { value: INDUSTRIES.defenseSpace, label: 'Defense & Space' },
    { value: INDUSTRIES.design, label: 'Design' },
    { value: INDUSTRIES.educationManagement, label: 'Education Management' },
    { value: INDUSTRIES.eLearning, label: 'E-Learning' },
    {
      value: INDUSTRIES.electricalElectronicManufacturing,
      label: 'Electrical/Electronic Manufacturing',
    },
    { value: INDUSTRIES.entertainment, label: 'Entertainment' },
    { value: INDUSTRIES.environmentalServices, label: 'Environmental Services' },
    { value: INDUSTRIES.eventsServices, label: 'Events Services' },
    { value: INDUSTRIES.executiveOffice, label: 'Executive Office' },
    { value: INDUSTRIES.facilitiesServices, label: 'Facilities Services' },
    { value: INDUSTRIES.farming, label: 'Farming' },
    { value: INDUSTRIES.financialServices, label: 'Financial Services' },
    { value: INDUSTRIES.fineArt, label: 'Fine Art' },
    { value: INDUSTRIES.fishery, label: 'Fishery' },
    { value: INDUSTRIES.foodBeverages, label: 'Food & Beverages' },
    { value: INDUSTRIES.foodProduction, label: 'Food Production' },
    { value: INDUSTRIES.fundRaising, label: 'Fund-Raising' },
    { value: INDUSTRIES.furniture, label: 'Furniture' },
    { value: INDUSTRIES.gamblingCasinos, label: 'Gambling & Casinos' },
    { value: INDUSTRIES.glassCeramicsConcrete, label: 'Glass, Ceramics & Concrete' },
    { value: INDUSTRIES.governmentAdministration, label: 'Government Administration' },
    { value: INDUSTRIES.governmentRelations, label: 'Government Relations' },
    { value: INDUSTRIES.graphicDesign, label: 'Graphic Design' },
    { value: INDUSTRIES.healthWellnessFitness, label: 'Health, Wellness and Fitness' },
    { value: INDUSTRIES.higherEducation, label: 'Higher Education' },
    { value: INDUSTRIES.hospitalHealthCare, label: 'Hospital & Health Care' },
    { value: INDUSTRIES.hospitality, label: 'Hospitality' },
    { value: INDUSTRIES.humanResources, label: 'Human Resources' },
    { value: INDUSTRIES.importExport, label: 'Import and Export' },
    { value: INDUSTRIES.individualFamilyServices, label: 'Individual & Family Services' },
    { value: INDUSTRIES.industrialAutomation, label: 'Industrial Automation' },
    { value: INDUSTRIES.informationServices, label: 'Information Services' },
    {
      value: INDUSTRIES.informationTechnologyServices,
      label: 'Information Technology and Services',
    },
    { value: INDUSTRIES.insurance, label: 'Insurance' },
    { value: INDUSTRIES.internationalAffairs, label: 'International Affairs' },
    {
      value: INDUSTRIES.internationalTradeDevelopment,
      label: 'International Trade and Development',
    },
    { value: INDUSTRIES.internet, label: 'Internet' },
    { value: INDUSTRIES.investmentBanking, label: 'Investment Banking' },
    { value: INDUSTRIES.investmentManagement, label: 'Investment Management' },
    { value: INDUSTRIES.judiciary, label: 'Judiciary' },
    { value: INDUSTRIES.lawEnforcement, label: 'Law Enforcement' },
    { value: INDUSTRIES.lawPractice, label: 'Law Practice' },
    { value: INDUSTRIES.legalServices, label: 'Legal Services' },
    { value: INDUSTRIES.legislativeOffice, label: 'Legislative Office' },
    { value: INDUSTRIES.leisureTravelTourism, label: 'Leisure, Travel & Tourism' },
    { value: INDUSTRIES.libraries, label: 'Libraries' },
    { value: INDUSTRIES.logisticsSupplyChain, label: 'Logistics and Supply Chain' },
    { value: INDUSTRIES.luxuryGoodsJewelry, label: 'Luxury Goods & Jewelry' },
    { value: INDUSTRIES.machinery, label: 'Machinery' },
    { value: INDUSTRIES.managementConsulting, label: 'Management Consulting' },
    { value: INDUSTRIES.maritime, label: 'Maritime' },
    { value: INDUSTRIES.marketResearch, label: 'Market Research' },
    { value: INDUSTRIES.marketingAdvertising, label: 'Marketing and Advertising' },
    {
      value: INDUSTRIES.mechanicalIndustrialEngineering,
      label: 'Mechanical or Industrial Engineering',
    },
    { value: INDUSTRIES.mediaProduction, label: 'Media Production' },
    { value: INDUSTRIES.medicalDevices, label: 'Medical Devices' },
    { value: INDUSTRIES.medicalPractice, label: 'Medical Practice' },
    { value: INDUSTRIES.mentalHealthCare, label: 'Mental Health Care' },
    { value: INDUSTRIES.military, label: 'Military' },
    { value: INDUSTRIES.miningMetals, label: 'Mining & Metals' },
    { value: INDUSTRIES.motionPicturesFilm, label: 'Motion Pictures and Film' },
    { value: INDUSTRIES.museumsInstitutions, label: 'Museums and Institutions' },
    { value: INDUSTRIES.music, label: 'Music' },
    { value: INDUSTRIES.nanotechnology, label: 'Nanotechnology' },
    { value: INDUSTRIES.newspapers, label: 'Newspapers' },
    {
      value: INDUSTRIES.nonProfitOrganizationManagement,
      label: 'Non-Profit Organization Management',
    },
    { value: INDUSTRIES.oilEnergy, label: 'Oil & Energy' },
    { value: INDUSTRIES.onlineMedia, label: 'Online Media' },
    { value: INDUSTRIES.outsourcingOffshoring, label: 'Outsourcing/Offshoring' },
    { value: INDUSTRIES.packageFreightDelivery, label: 'Package/Freight Delivery' },
    { value: INDUSTRIES.packagingContainers, label: 'Packaging and Containers' },
    { value: INDUSTRIES.paperForestProducts, label: 'Paper & Forest Products' },
    { value: INDUSTRIES.performingArts, label: 'Performing Arts' },
    { value: INDUSTRIES.pharmaceuticals, label: 'Pharmaceuticals' },
    { value: INDUSTRIES.philanthropy, label: 'Philanthropy' },
    { value: INDUSTRIES.photography, label: 'Photography' },
    { value: INDUSTRIES.plastics, label: 'Plastics' },
    { value: INDUSTRIES.politicalOrganization, label: 'Political Organization' },
    { value: INDUSTRIES.primarySecondaryEducation, label: 'Primary/Secondary Education' },
    { value: INDUSTRIES.printing, label: 'Printing' },
    { value: INDUSTRIES.professionalTrainingCoaching, label: 'Professional Training & Coaching' },
    { value: INDUSTRIES.programDevelopment, label: 'Program Development' },
    { value: INDUSTRIES.publicPolicy, label: 'Public Policy' },
    {
      value: INDUSTRIES.publicRelationsCommunications,
      label: 'Public Relations and Communications',
    },
    { value: INDUSTRIES.publicSafety, label: 'Public Safety' },
    { value: INDUSTRIES.publishing, label: 'Publishing' },
    { value: INDUSTRIES.railroadManufacture, label: 'Railroad Manufacture' },
    { value: INDUSTRIES.ranching, label: 'Ranching' },
    { value: INDUSTRIES.realEstate, label: 'Real Estate' },
    {
      value: INDUSTRIES.recreationalFacilitiesServices,
      label: 'Recreational Facilities and Services',
    },
    { value: INDUSTRIES.religiousInstitutions, label: 'Religious Institutions' },
    { value: INDUSTRIES.renewablesEnvironment, label: 'Renewables & Environment' },
    { value: INDUSTRIES.research, label: 'Research' },
    { value: INDUSTRIES.restaurants, label: 'Restaurants' },
    { value: INDUSTRIES.retail, label: 'Retail' },
    { value: INDUSTRIES.securityInvestigations, label: 'Security and Investigations' },
    { value: INDUSTRIES.semiconductors, label: 'Semiconductors' },
    { value: INDUSTRIES.shipbuilding, label: 'Shipbuilding' },
    { value: INDUSTRIES.sportingGoods, label: 'Sporting Goods' },
    { value: INDUSTRIES.sports, label: 'Sports' },
    { value: INDUSTRIES.staffingRecruiting, label: 'Staffing and Recruiting' },
    { value: INDUSTRIES.supermarkets, label: 'Supermarkets' },
    { value: INDUSTRIES.telecommunications, label: 'Telecommunications' },
    { value: INDUSTRIES.textiles, label: 'Textiles' },
    { value: INDUSTRIES.thinkTanks, label: 'Think Tanks' },
    { value: INDUSTRIES.tobacco, label: 'Tobacco' },
    { value: INDUSTRIES.translationLocalization, label: 'Translation and Localization' },
    { value: INDUSTRIES.transportationTruckingRailroad, label: 'Transportation/Trucking/Railroad' },
    { value: INDUSTRIES.utilities, label: 'Utilities' },
    { value: INDUSTRIES.ventureCapitalPrivateEquity, label: 'Venture Capital & Private Equity' },
    { value: INDUSTRIES.veterinary, label: 'Veterinary' },
    { value: INDUSTRIES.warehousing, label: 'Warehousing' },
    { value: INDUSTRIES.wholesale, label: 'Wholesale' },
    { value: INDUSTRIES.wineSpirits, label: 'Wine and Spirits' },
    { value: INDUSTRIES.wireless, label: 'Wireless' },
    { value: INDUSTRIES.writingEditing, label: 'Writing and Editing' },
  ],
  classYear: [
    { value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL },
    ...generateGraduatingYearsOptions(),
  ],
  affiliation: [
    { value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL },
    { label: 'Alumni', value: USER_AFFILIATIONS.alumni },
    { label: 'Parent', value: USER_AFFILIATIONS.parent },
    { label: 'Student', value: USER_AFFILIATIONS.student },
    { label: 'Staff', value: USER_AFFILIATIONS.staff },
    { label: 'Other', value: USER_AFFILIATIONS.other },
  ],
  employmentType: [
    { label: 'Apprentice', value: JOB_EMPLOYMENT_TYPE.apprentice },
    { label: 'Contract', value: JOB_EMPLOYMENT_TYPE.contract },
    { label: 'Freelance', value: JOB_EMPLOYMENT_TYPE.freelance },
    { label: 'Full Time', value: JOB_EMPLOYMENT_TYPE.fullTime },
    { label: 'Intern', value: JOB_EMPLOYMENT_TYPE.intern },
    { label: 'Part Time', value: JOB_EMPLOYMENT_TYPE.partTime },
    { label: 'Seasonal', value: JOB_EMPLOYMENT_TYPE.seasonal },
    { label: 'Self Employment', value: JOB_EMPLOYMENT_TYPE.selfEmployed },
  ],
  type: {
    all: 'All',
    jobs: 'Jobs',
    internships: 'Internships',
    jobSeekers: 'Job Seekers',
  },
  organizationStatus: [
    { value: ORGANIZATION_STATUS.active, label: 'Active' },
    { value: ORGANIZATION_STATUS.invitationSent, label: 'Invitation Sent' },
    { value: ORGANIZATION_STATUS.inactive, label: 'Inactive' },
  ],
};
