import React from 'react';
import { Box } from '@mui/material';

import { Icon, Typography } from 'shared/components/ui';
import { MaterialIconNames } from 'shared/components/ui/Icon/Icon';
import { useResponsive } from 'shared/hooks';

import {
  titleIconCss,
  headerIconCss,
  blockTitleCss,
  ViewAllLink,
  BlockDivider,
} from '../CommunitySidebar';

interface DiscountsContainerProps {
  withDivider?: boolean;
  title?: string;
  toViewAll?: string;
  icon?: MaterialIconNames;
  leftBox?: React.ReactNode;
}

export const ModuleContainer: React.FC<DiscountsContainerProps> = ({
  children,
  withDivider = true,
  title,
  toViewAll,
  icon,
  leftBox,
}) => {
  const { isMobile } = useResponsive();
  return (
    <React.Fragment>
      <Box p={isMobile ? 2 : 3.125} pt={0}>
        <Box py={2.75} display="flex" justifyContent="space-between" alignItems="baseline">
          <Box display="flex" alignItems="center">
            {leftBox || (
              <Box css={titleIconCss} mr={1} p={0.5}>
                <Icon css={headerIconCss} name={icon || 'Dvr'} htmlColor="#FFF" />
              </Box>
            )}
            <Typography css={blockTitleCss} variant="subtitle5">
              {title}
            </Typography>
          </Box>

          {toViewAll && <ViewAllLink to={toViewAll} />}
        </Box>
        {children}
      </Box>
      {withDivider && <BlockDivider />}
    </React.Fragment>
  );
};
