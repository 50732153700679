import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { DiscountsPage } from './pages/DiscountsPage';

export const getAdminDiscountsRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.discounts.index,
    exact: true,
    component: DiscountsPage,
  },
];
