import { ROUTES } from 'shared/constants';
import { InboxMessageFilter } from 'shared/graphql';

export const SENT_ROUTE = 'Sent';
export const INBOX_ROUTE = 'Inbox';
export const TRASH_ROUTE = 'Trash';
export const INDEX_ROUTE = 'Index';
/** @deprecated Use the school name from `useSchoolConfiguration`. */
export const COLUMBUS_CONNECT = 'Columbus Connects';

export const HEADER_HEIGHT = 65;

export const AVATAR_SIZE = 28;

export function createInboxMessageFilterOnLocation(route: string): InboxMessageFilter {
  if (route === ROUTES.user.mail.sent || route === ROUTES.admin.mail.sent) {
    return {
      deletedBy: { none: { is_self: true } },
      author: { is_self: true },
    };
  }

  if (route === ROUTES.user.mail.trash || route === ROUTES.admin.mail.trash) {
    return {
      deletedBy: { some: { is_self: true } },
    };
  }

  return {
    deletedBy: { none: { is_self: true } },
  };
}

export const INBOX_TYPES = {
  personal: 'personal',
  group: 'group',
  multiPersonal: 'multiPersonal',
};

export const MESSAGE_TYPE_OPTIONS = [
  {
    value: INBOX_TYPES.personal,
    label: 'Individual message',
  },
  {
    value: INBOX_TYPES.group,
    label: 'Group message',
  },
];

export interface InboxParams {
  inboxId?: string;
}
