import { useField, useFormikContext } from 'formik';

import { getIsInvalid } from 'shared/components/ui';
import { MentionPosition, MentionsInput } from 'shared/features/mentions';

import { MentionsFieldProps } from './MentionsField.types';

export function MentionsField(props: MentionsFieldProps) {
  const [field, meta, helpers] = useField<string>({
    name: props.name,
    validate: props.validate,
  });

  const form = useFormikContext();

  const hasError = getIsInvalid({ meta, form });
  const message = meta.error;
  const value = field.value ?? '';

  const onChange = (value: string, mentions: MentionPosition[]) => {
    helpers.setValue(value);
    props.onChange?.(value);
    props.onChangeMentions?.(mentions);
  };

  return (
    <MentionsInput
      {...props}
      value={value}
      error={hasError}
      helperText={message}
      onChange={onChange}
    />
  );
}
