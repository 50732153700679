import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAppAuth } from 'providers/useAppAuth';
import { APP_URL } from 'shared/constants';

import { renderContent } from './routerUtils';

export type GuestRouteProps = RouteProps;

export const GuestRoute = ({ component, render, children, ...rest }: GuestRouteProps) => {
  const { isAuthenticated } = useAppAuth();

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          renderContent({ component, render, children, props })
        ) : (
          <Redirect
            to={{
              pathname: APP_URL.user.home.index,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
