import React from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  JobOfferCreateMutation,
  JobOfferCreateMutationVariables,
  OrganizationSearchQuery,
  OrganizationSearchQueryVariables,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { createSnackMessage, SNACK_TYPES } from 'shared/components/ui';
import { sendToSentry } from 'shared/utils/sentry';

import { JOB_OFFER_CREATE_MUTATION, ORGANIZATION_SEARCH_QUERY } from '../queries';
import { getOrganizationIdSearchFilter } from '../utils';
import { CreateJobPostForm } from '../components';

export const useOrganizationSearch = () => {
  const [organizationSearch, { data }] = useLazyQuery<
    OrganizationSearchQuery,
    OrganizationSearchQueryVariables
  >(ORGANIZATION_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const onOrganizationSearch = React.useCallback(
    (organizationName: string | undefined | null) => {
      try {
        organizationSearch({
          variables: { filter: getOrganizationIdSearchFilter(organizationName) },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [organizationSearch],
  );

  return {
    onOrganizationSearch,
    organizationId: data?.organizationsList?.items[0]?.id,
  };
};

export const useJobPostCreate = () => {
  const { user, userId, isAdmin, isBusinessAdminOrManager } = useCurrentUser();
  const [jobOfferCreate, { loading }] = useMutation<
    JobOfferCreateMutation,
    JobOfferCreateMutationVariables
  >(JOB_OFFER_CREATE_MUTATION, {
    refetchQueries: [
      'CareerSearch',
      'CareersListCount',
      'CareersFilterByZipRequest',
      'CareersPageSearch',
    ],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your job post has been published!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Your job post hasn't been published.`,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const onJobOfferCreate = async (data: CreateJobPostForm, organizationId?: string | null) => {
    if (!user) {
      return;
    }

    if (!(isAdmin || isBusinessAdminOrManager)) {
      enqueueSnackbar(
        'Error. Sorry, but you dont have Administrative Preferences. Check it out with Admin.',
        {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.error),
        },
      );
      return;
    }
    try {
      await jobOfferCreate({
        variables: {
          data: {
            position: data?.position || '',
            description: data?.description || '',
            employmentType: data?.internship
              ? JOB_EMPLOYMENT_TYPE.intern
              : JOB_EMPLOYMENT_TYPE.fullTime,
            organization: {
              connect: {
                id: organizationId,
              },
            },
            creator: {
              connect: {
                id: userId,
              },
            },
          },
        },
      });
    } catch (e: any) {
      sendToSentry(e);
    }
  };

  return { onJobOfferCreate, loading };
};
