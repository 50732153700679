import { gql } from '@apollo/client';

export const INBOX_FRAGMENT = gql`
  fragment Inbox on Inbox {
    id
    subject
    type
    destinationGroups
    users(first: $firstUsers) {
      items {
        ...InboxUser
      }
    }
    messages(first: $firstMessages, sort: $sortMessages, filter: $filterMessages) {
      items {
        ...InboxMessage
      }
    }
  }

  fragment InboxUser on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
  }

  fragment InboxMessage on InboxMessage {
    id
    createdAt
    text
    reply
    isRead
    isDeleted
    media {
      items {
        id
        meta
        downloadUrl
      }
    }
    author {
      id
    }
    inbox {
      id
      type
      destinationGroups
    }
    messageMedia {
      id
      media {
        items {
          id
          meta
          downloadUrl
          filename
        }
      }
    }
  }
`;

export const INBOX_CHATS_LIST_QUERY = gql`
  query InboxChatsList(
    $filter: InboxFilter
    $sort: [InboxSort!]!
    $first: Int!
    $firstUsers: Int = 10
    $firstMessages: Int = 5
    $sortMessages: [InboxMessageSort!]
    $filterMessages: InboxMessageFilter
  ) {
    inboxes: inboxesList(first: $first, filter: $filter, sort: $sort) {
      count
      items {
        ...Inbox
      }
    }
  }

  ${INBOX_FRAGMENT}
`;

export const INBOX_MESSAGES_LIST_QUERY = gql`
  query InboxMessagesList(
    $inboxId: ID!
    $firstUsers: Int = 10
    $firstMessages: Int!
    $sortMessages: [InboxMessageSort!]
    $filterMessages: InboxMessageFilter!
  ) {
    inbox(id: $inboxId) {
      ...Inbox
    }
  }

  ${INBOX_FRAGMENT}
`;

export const INBOX_MESSAGES_COUNT_QUERY = gql`
  query InboxMessagesCount(
    $inboxId: ID!
    $sort: [InboxMessageSort!]
    $filter: InboxMessageFilter!
  ) {
    inbox(id: $inboxId) {
      id
      messages(sort: $sort, filter: $filter) {
        count
      }
    }
  }
`;

export const INBOX_MESSAGE_CREATE_MUTATION = gql`
  mutation InboxMessageCreate($data: InboxMessageCreateInput!) {
    inboxMessageCreate(data: $data) {
      id
      createdAt
      text
      isRead
      isDeleted
      media {
        items {
          id
          downloadUrl
        }
      }
      author {
        id
      }
      inbox {
        id
        type
        destinationGroups
        users {
          items {
            id
          }
        }
      }
    }
  }
`;

export const INBOX_CREATE_MUTATION = gql`
  mutation InboxCreate($data: InboxCreateInput!) {
    inboxCreate(data: $data) {
      id
    }
  }
`;

export const INBOX_DELETE_MUTATION = gql`
  mutation InboxDelete($data: InboxDeleteInput!, $force: Boolean) {
    inboxDelete(data: $data, force: $force) {
      success
    }
  }
`;

export const INBOX_UPDATE_MESSAGE = gql`
  mutation InboxMessageUpdate($data: InboxMessageUpdateInput!) {
    inboxMessageUpdate(data: $data) {
      id
      isDeleted
      isRead
    }
  }
`;

export const INBOX_DELETE_MESSAGE = gql`
  mutation InboxMessageDelete($data: InboxMessageDeleteInput!) {
    inboxMessageDelete(data: $data) {
      success
    }
  }
`;

export const INBOX_MESSAGES_CLEAR_TRASH = gql`
  mutation InboxMessageClearTrash {
    inboxMessageDeleteByFilter(filter: { isDeleted: { equals: true } }, force: true) {
      success
    }
  }
`;

export const INBOX_MESSAGE_MEDIA_CREATE = gql`
  mutation InboxMessageMediaCreate($data: InboxMessageMediaCreateInput!) {
    inboxMessageMediaCreate(data: $data) {
      id
    }
  }
`;

export const USER_TRASH_MESSAGES_QUERY = gql`
  query UserTrashMessages {
    inboxMessagesList(
      filter: { deletedBy: { some: { is_self: true } }, isHide: { every: { is_self: false } } }
    ) {
      items {
        id
      }
    }
  }
`;

export const INBOX_UPDATE_MUTATION = gql`
  mutation InboxUpdate($data: InboxUpdateInput!) {
    inboxUpdate(data: $data, force: true) {
      id
    }
  }
`;

export const READ_ALL_INBOX_MESSAGES = gql`
  mutation ReadAllInboxMessages($inboxId: ID!) {
    inboxMessageUpdateByFilter(
      data: { isRead: { set: true } }
      filter: {
        inbox: { id: { equals: $inboxId } }
        author: { is_self: false }
        isRead: { equals: false }
      }
    ) {
      count
    }
  }
`;

export const INBOX_UNREAD_MESSAGES = gql`
  query InboxUnreadMessages($id: ID!) {
    inboxMessagesList(
      filter: {
        author: { is_self: false }
        isRead: { equals: false }
        inbox: { users: { some: { id: { equals: $id } } } }
      }
      first: 100
    ) {
      count
    }
  }
`;
