import { gql } from '@apollo/client';

export const JOB_OFFER_PRESENTATION_FRAGMENT = gql`
  fragment JobOfferPresentation on JobOffer {
    id
    position
    description
    employmentType
    postDate

    createdBy {
      id
    }

    organization {
      id
      name

      logo {
        downloadUrl
      }
    }
  }
`;
