import { css, Theme } from '@emotion/react';
import { Typography, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FileInputValue } from '@8base-react/file-input';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { OrganizationUpdateInput } from 'shared/graphql';
import { AvatarImageInput, Icon } from 'shared/components/ui';
import { FileInputWrap } from 'shared/components/ui/FileInputWrap';

import { useBusinessEdit, useBusinessSearch } from '../../hooks';

const USER_AVATAR_SIZE = 90;

const avatarCss = css`
  width: ${USER_AVATAR_SIZE}px;
  height: ${USER_AVATAR_SIZE}px;
`;

const userNameCss = css`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const containerCss = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.palette.divider};
`;

const userNameContainerCss = css`
  display: grid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 20px;
`;

const avatarIconsContainerCss = (theme: Theme) => css`
  margin: ${theme.spacing(0.75)}px ${theme.spacing(2.5)}px;
`;

const avatarIconsCss = (theme: Theme) => css`
  color: ${theme.palette.secondary.light};
`;

export function BusinessDetailsSidebarOrganizationProfile() {
  const { id } = useParams<{ id: string }>();
  const { business } = useBusinessSearch(id);
  const { onBusinessEdit, loading } = useBusinessEdit();

  const handleUpdateUserAvatar = async (images: FileInputValue | null) => {
    const payload: OrganizationUpdateInput = {
      id,
    };

    const image = Array.isArray(images) ? images[0] : images;

    if (!image && business?.logo) {
      payload.logo = {
        disconnect: {
          fileId: business.logo.fileId,
        },
      };
    }

    if (image) {
      payload.logo = {
        create: {
          fileId: image.fileId,
          filename: image.filename,
          public: true,
        },
      };
    }

    await onBusinessEdit(payload);
  };

  const onPickImage = (pick: any) => {
    pick({ fromSources: ['local_file_system'], accept: 'image/*' });
  };

  return (
    <Box
      css={containerCss}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={2.5}
      pb={1}
    >
      <AvatarImageInput
        srcSet={processFilestackUrlSrcSet(business?.logo?.downloadUrl || '', {
          compress: true,
          resize: {
            width: USER_AVATAR_SIZE,
            height: USER_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        src={business?.logo?.downloadUrl || ''}
        css={avatarCss}
        loading={loading}
        onChange={handleUpdateUserAvatar}
      />

      <Box css={avatarIconsContainerCss}>
        {/* TODO: replace with the image editor */}
        <FileInputWrap onChange={handleUpdateUserAvatar}>
          {({ pick }) => (
            <IconButton size="small" onClick={() => onPickImage(pick)}>
              <Icon name="FileUpload" css={avatarIconsCss} variant="filled" fontSize="small" />
            </IconButton>
          )}
        </FileInputWrap>

        <IconButton size="small" onClick={() => handleUpdateUserAvatar(null)}>
          <Icon name="Clear" css={avatarIconsCss} variant="filled" fontSize="small" />
        </IconButton>
      </Box>

      <Box css={userNameContainerCss}>
        <Typography css={userNameCss}>{business?.name}</Typography>
      </Box>
    </Box>
  );
}
