import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { Fragment, useState } from 'react';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { AvatarImageInput, Box, Icon, ImageInput } from 'shared/components/ui';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { useSchoolConfigurationUpdate } from 'shared/hooks/useSchoolConfigurationUpdate';

import { EDIT_SETTINGS_TYPE, EditSettingsModal } from './EditSettingsModal';

const AVATAR_SIZE = 90;

const containerCss = (theme: any) => css`
  display: grid;
  place-content: center;
  place-items: center;
  padding: 1rem 2.5rem;
  border-bottom: 1px solid ${theme.palette.border};
`;

const avatarCss = theme => css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const avatarIconsContainerCss = theme => css`
  display: flex;
  margin: ${theme.spacing(0.75)}px ${theme.spacing(2.5)}px;
`;

const avatarIconsCss = (theme: any) => css`
  color: ${theme.palette.secondary.light};
`;

const siteNameContainerCss = theme => css`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: ${theme.spacing(0.75)}px ${theme.spacing(2.5)}px;
`;

const siteNameCss = theme => css`
  font-weight: ${theme.typography.subtitle5.fontWeight};
  font-size: ${theme.typography.subtitle5.fontSize};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const titleContainerCss = (theme: any) => css`
  color: ${theme.palette.text.light};
  text-align: center;
`;

const editIconCss = (theme: any) => css`
  font-size: ${theme.typography.fontSize + 2}px;
  margin-left: ${theme.spacing(0.75)}px;
  color: ${theme.palette.text.light};
`;

export const SettingsSidebarProfile = () => {
  const [editNameModal, setEditNameModal] = useState(false);
  const [editFullNameModal, setEditFullNameModal] = useState(false);
  const { configuration, loading } = useSchoolConfiguration();
  const { showSuccess } = useToast();

  const { schoolConfigurationUpdate, loading: updateLoading } = useSchoolConfigurationUpdate();

  const handleChangeLogo = async (logo: any) => {
    await schoolConfigurationUpdate({
      images: {
        logo,
      } as any,
    });
  };

  const handleChangeName = async (name: string) => {
    setEditNameModal(false);
    await schoolConfigurationUpdate({ name });

    showSuccess('School name updated successfully.');
  };

  const handleChangeFullName = async (fullName: string) => {
    setEditFullNameModal(false);

    await schoolConfigurationUpdate({ fullName });
    showSuccess('School name updated successfully.');
  };

  return (
    <Fragment>
      <Box css={containerCss}>
        <AvatarImageInput
          loading={loading || updateLoading}
          src={configuration?.images?.logo?.downloadUrl || ''}
          srcSet={processFilestackUrlSrcSet(configuration?.images?.logo?.downloadUrl || '', {
            compress: true,
            resize: {
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
            },
          })}
          css={avatarCss}
          onChange={handleChangeLogo}
        />
        <Box css={avatarIconsContainerCss}>
          <ImageInput maxFiles={1} height={80} width={80} onChange={handleChangeLogo}>
            <IconButton size="small">
              <Icon name="FileUpload" css={avatarIconsCss} variant="filled" fontSize="small" />
            </IconButton>
          </ImageInput>

          <IconButton size="small" onClick={() => handleChangeLogo(null)}>
            <Icon name="Clear" css={avatarIconsCss} variant="filled" fontSize="small" />
          </IconButton>
        </Box>
        <Box css={siteNameContainerCss}>
          <Typography
            css={siteNameCss}
            variant="inherit"
            align="inherit"
            color="initial"
            paragraph={false}
          >
            {configuration?.name}
          </Typography>
          <IconButton size="small" onClick={() => setEditNameModal(true)}>
            <Icon name="Edit" css={editIconCss} variant="filled" color="inherit" fontSize="small" />
          </IconButton>
        </Box>

        {configuration?.fullName && (
          <Box css={titleContainerCss}>
            <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
              {configuration?.fullName}
            </Typography>

            <IconButton onClick={() => setEditFullNameModal(true)}>
              <Icon
                name="Edit"
                css={editIconCss}
                variant="filled"
                color="inherit"
                fontSize="small"
              />
            </IconButton>
          </Box>
        )}
      </Box>

      <EditSettingsModal
        isOpen={editNameModal}
        type={EDIT_SETTINGS_TYPE.NAME}
        value={configuration?.name || ''}
        onClose={() => setEditNameModal(false)}
        onSave={handleChangeName}
      />
      <EditSettingsModal
        isOpen={editFullNameModal}
        type={EDIT_SETTINGS_TYPE.FULL_NAME}
        value={configuration?.fullName || ''}
        onClose={() => setEditFullNameModal(false)}
        onSave={handleChangeFullName}
      />
    </Fragment>
  );
};
