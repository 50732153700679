import { useCallback } from 'react';
import { List, RootRef } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SchoolConfigurationExternalLink } from 'shared/graphql';

import { ExternalLink } from './ExternalLink';

type ExternalLinksListProps = {
  items: SchoolConfigurationExternalLink[];
  onDragEnd: (result: any) => void;
  onItemUpdate: (item: SchoolConfigurationExternalLink) => void;
  onModalOpen: (item: SchoolConfigurationExternalLink) => void;
};

export const ExternalLinksList = ({
  items,
  onDragEnd,
  onItemUpdate,
  onModalOpen,
}: ExternalLinksListProps) => {
  const handleOnModalOpen = useCallback(
    (item: SchoolConfigurationExternalLink) => () => onModalOpen(item),
    [onModalOpen],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <RootRef rootRef={provided.innerRef}>
            <List>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id as string} index={index}>
                  {(provided, snapshot) => (
                    <ExternalLink
                      item={item}
                      snapshot={snapshot}
                      provided={provided}
                      onItemUpdate={onItemUpdate}
                      onModalOpen={handleOnModalOpen(item)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};
