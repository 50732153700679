import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { LineChart, BarChart, PieChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import type { EChartsOption } from 'echarts';
import _ from 'lodash';

import type { SxProp } from 'shared/types/styles';
import { Preloader } from 'shared/components/ui';

import type { LineChartData, BarChartData, PieChartData, ChartDateRange } from './Chart.types';

echarts.use([SVGRenderer, LineChart, BarChart, PieChart, GridComponent]);

const boxStyles: SxProp = {
  width: '100%',
  overflow: 'hidden',
  '& > *': {
    width: '100%',
    height: '100% !important',
    '& > *': {
      width: '100% !important',
      height: '100% !important',
    },
  },
};

type ChartPropsVariants =
  | { type: 'line'; chartData?: LineChartData }
  | { type: 'bar'; chartData?: BarChartData }
  | { type: 'pie'; chartData?: PieChartData };

type ChartProps = {
  xAxisType?: 'value' | 'time';
  xAxisShowLabel?: boolean;
  color?: string;
  height?: number;
  overrideOptions?: (defaultOptions: EChartsOption) => EChartsOption;
  dateRange?: ChartDateRange;
  loading?: boolean;
};

// const defaultFormatter: LabelFormatterCallback = ({ value }) => value[1];

/** @deprecated Use `LinearChart` instead. */
export const Chart: React.FC<ChartProps & ChartPropsVariants> = ({
  type,
  xAxisType = 'time',
  xAxisShowLabel = true,
  chartData,
  color = '#0089ff',
  height = 250,
  overrideOptions,
  dateRange,
  loading = false,
}) => {
  const chartOptions = React.useMemo((): EChartsOption | undefined => {
    if (_.isNil(chartData)) {
      return undefined;
    }

    let dataOptions: EChartsOption = {};

    if (type === 'line') {
      dataOptions = {
        xAxis: {
          type: xAxisType,
          splitLine: {
            show: true,
          },
          axisLabel: {
            show: xAxisShowLabel,
          },
          ...dateRange,
        },
        series: {
          type,
          lineStyle: {
            color,
          },
          symbol: 'none',
          endLabel: {
            show: true,
            color,
          },
          animation: false,
          data: chartData.map(point => ({
            name: point.date.toISOString(),
            value: [point.date, point.value as number],
          })),
        },
      };
    }

    const isBarChart = type === 'bar';

    if (type === 'bar') {
      dataOptions = {
        xAxis: {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: '#000',
            padding: 5,
          },
        },
        series: {
          type,
          color,
          showBackground: false,
          label: {
            show: true,
            color,
            position: 'top',
          },
          animation: false,
          data: chartData.map(point => ({
            name: 'category',
            value: [_.capitalize(point.category), point.value as number],
          })),
        },
      };
    }

    if (type === 'pie') {
      dataOptions = {
        xAxis: {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: '#000',
            padding: 5,
          },
        },
        series: {
          type,
          radius: ['40%', '85%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          animation: false,
          color: chartData.map(point => point.color).filter(Boolean) as string[],
          data: chartData.map(point => ({
            name: point.name,
            value: point.value as number,
          })),
        },
      };
    }

    const defaultOptions: EChartsOption = {
      grid: {
        top: isBarChart ? 30 : 10,
        bottom: xAxisShowLabel ? 30 : 15,
        left: 40,
        right: 45,
        containLabel: true,
      },
      animation: false,
      yAxis: {
        type: 'value',
        splitLine: {
          show: isBarChart,
        },
      },
      ...dataOptions,
      ...overrideOptions,
    };
    return overrideOptions?.(defaultOptions) ?? defaultOptions;
  }, [chartData, color, dateRange, overrideOptions, type, xAxisShowLabel, xAxisType]);

  if (loading) {
    return (
      <Box display="flex" height={height} alignItems="center">
        <Preloader />
      </Box>
    );
  }

  if (_.isNil(chartOptions)) {
    return (
      <Box display="flex" height={height} alignItems="center">
        <Typography>Wrong chart data!</Typography>
      </Box>
    );
  }

  return (
    <Box height={height} sx={boxStyles}>
      <ReactEChartsCore
        echarts={echarts}
        option={chartOptions}
        notMerge={true}
        lazyUpdate={true}
        className="test"
      />
    </Box>
  );
};
