import React from 'react';
import { TableCell } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import { SpreadsheetCellType } from 'shared/features/spreadsheet';

type SpreadsheetCellProps = {
  value: SpreadsheetCellType;
  isNumeric: boolean;
};

export const SpreadsheetCellRaw = ({ value, isNumeric }: SpreadsheetCellProps) => {
  if (typeof value === 'string') {
    return (
      <TableCell align="left">
        <Typography variant="body2">{value}</Typography>
      </TableCell>
    );
  }

  return <TableCell>{value}</TableCell>;
};

export const SpreadsheetCell = React.memo(SpreadsheetCellRaw);
