import React from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  GroupCreateRequestMutation,
  GroupCreateRequestMutationVariables,
  UsersIdsSearchQuery,
  UsersIdsSearchQueryVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { GROUP_CREATE_REQUEST_MUTATION, USERS_IDS_SEARCH_QUERY } from '../queries';

export const useUsersIdsSearch = () => {
  const [usersIdsSearch, { data }] = useLazyQuery<
    UsersIdsSearchQuery,
    UsersIdsSearchQueryVariables
  >(USERS_IDS_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const onUsersIdsSearch = React.useCallback(
    (usersNames: string[]) => {
      try {
        usersIdsSearch({
          variables: {
            filter: {
              OR: [
                ...usersNames?.map(name => ({
                  firstName: { equals: name },
                })),
              ],
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [usersIdsSearch],
  );

  return {
    onUsersIdsSearch,
    usersIds: data?.usersList?.items,
  };
};

export const useGroupCreateRequest = () => {
  const [groupCreateRequest, { loading, data, ...rest }] = useMutation<
    GroupCreateRequestMutation,
    GroupCreateRequestMutationVariables
  >(GROUP_CREATE_REQUEST_MUTATION, {
    refetchQueries: ['GroupsList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]:
        'Success: Your New Group Request has been submitted and is currently under review. You will be notified once the review is completed.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: There are one or more errors in your New Group Request. Please review carefully and try again.`,
    },
  });

  const onGroupCreateRequest = React.useCallback(
    (data: GroupCreateRequestMutationVariables['data']) => {
      return groupCreateRequest({
        variables: {
          data,
        },
      });
    },
    [groupCreateRequest],
  );

  return { onGroupCreateRequest, loading, data, ...rest };
};
