import { DateTime } from 'luxon';

import { DatePicker } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';

export type DateFilterFieldProps = {
  label?: string;
};

export function DateFilterField(props: DateFilterFieldProps) {
  const { dateFilter: value, setDateFilter: setValue } = useSearchContext();

  const onChange = (given: unknown) => {
    if (given instanceof DateTime && given.isValid) {
      setValue(given);
      return;
    }

    setValue(null);
  };

  return (
    <DatePicker
      value={value}
      onChange={onChange}
      label={props.label}
      disableFuture
      InputProps={{
        style: { maxHeight: '56px' },
      }}
      inputVariant="outlined"
      variant="inline"
      autoOk
    />
  );
}
