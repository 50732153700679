import { useAppAuth } from 'providers';
import {
  UserNotificationReadableFragment,
  useCurrentUserNotificationCreatedSubscription,
} from 'shared/graphql';

export function useCurrentUserNotificationsSubscription(
  onReceive: (response: UserNotificationReadableFragment) => void,
) {
  const { hasSession } = useAppAuth();

  const { data: response, loading } = useCurrentUserNotificationCreatedSubscription({
    skip: !hasSession,
    shouldResubscribe: true,

    onSubscriptionData(options) {
      const response = options.subscriptionData.data?.UserNotificationsByUser?.node;

      if (response) {
        onReceive(response);
      }
    },
  });

  const data = response?.UserNotificationsByUser?.node;

  return {
    data,
    loading,
  };
}
