import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Name = styled.span``;
