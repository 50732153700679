import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { GroupsPage } from './pages/GroupsPage';

export const getAdminGroupsRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.groups.index,
    exact: true,
    component: GroupsPage,
  },
];
