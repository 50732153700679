import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { DateTime } from 'luxon';

import { useLegacyTimelineMonths } from 'features/legacy/hooks';
import { Maybe } from 'shared/graphql';
import { ROUTES } from 'shared/constants';

import { LegacyTimeline } from '../LegacyTimeline';
import { LegacyTimelineSection } from '../LegacyTimelineSection';
import { LegacyTimelinePosts } from '../LegacyTimelinePosts';

interface Params {
  year: string;
}

export function LegacyTimelineMonths() {
  const params = useParams<Params>();
  const year = Number(params.year);

  const { data, loading, refreshing, hasMore, fetchMore } = useLegacyTimelineMonths({ year });

  const { push: navigate } = useHistory();

  const epoachs = useMemo(() => {
    return data.map(epoach => {
      const title = DateTime.now()
        .set({ year, month: Number(epoach.month) })
        .toFormat('MMM yyyy');

      return { ...epoach, title };
    });
  }, [data, year]);

  const handleSeeMore = (month: Maybe<number> | undefined) => () => {
    const url = generatePath(ROUTES.user.legacy.month, {
      year: params.year,
      month: String(month),
    });

    navigate(url);
  };

  return (
    <LegacyTimeline
      data={epoachs}
      loading={loading}
      refreshing={refreshing}
      hasMore={hasMore}
      onFetchMore={fetchMore}
    >
      {epoach => (
        <LegacyTimelineSection
          title={epoach.title}
          key={`${epoach.month}/${params.year}`}
          onSeeMore={handleSeeMore(epoach.month)}
        >
          <LegacyTimelinePosts year={year} month={epoach.month} perPage={1} />
        </LegacyTimelineSection>
      )}
    </LegacyTimeline>
  );
}
