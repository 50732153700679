import { Maybe, useJobOfferUpdateMutation } from 'shared/graphql/__generated__';
import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

import { useUpdateIncrementJobOffer } from '../../home/hooks/useIncrementJobPost';

export function useJobOfferSave() {
  const { user } = useCurrentUser();

  const preferences = user?.userPreferences;

  const incrementJobEngagement = useUpdateIncrementJobOffer();

  const [updateJob] = useJobOfferUpdateMutation({
    refetchQueries: ['CurrentUser', 'CurrentUserSavedJobOffers'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Saved jobs information was successfully updated',
      [SNACKBAR_ERROR_MESSAGE]: 'Saved jobs information was not updated',
    },
  });

  const onSaveJob = async (id: Maybe<string> | undefined) => {
    if (!preferences?.id || !id) {
      return;
    }

    try {
      const response = await updateJob({
        variables: {
          data: {
            id,
            interestedUsers: {
              connect: [{ id: preferences.id }],
            },
          },
        },
      });

      const offer = response.data?.jobOfferUpdate;

      if (!offer?.id) {
        throw new Error(`Response must have "jobOfferUpdate". Got ${typeof offer?.id}`);
      }

      await incrementJobEngagement(offer.id);
    } catch (err: any) {
      sendToSentry(err);
    }
  };

  const onUnsaveJob = async (id: Maybe<string> | undefined) => {
    if (!preferences?.id || !id) {
      return;
    }

    try {
      const response = await updateJob({
        variables: {
          data: {
            id,
            interestedUsers: {
              disconnect: [{ id: preferences.id }],
            },
          },
        },
      });

      const offer = response.data?.jobOfferUpdate;

      if (!offer?.id) {
        throw new Error(`Response must have "jobOfferUpdate". Got ${typeof offer?.id}`);
      }
    } catch (err: any) {
      sendToSentry(err);
    }
  };

  return {
    onSaveJob,
    onUnsaveJob,
  };
}
