import { useCurrentUserNotificationsCountQuery } from 'shared/graphql';

type Options = Parameters<typeof useCurrentUserNotificationsCountQuery>[0];

export function useCurrentUserNotificationsCount(options?: Options) {
  const { data: response, loading, refetch } = useCurrentUserNotificationsCountQuery(options);

  const unread = response?.unread.count ?? 0;
  const read = response?.read.count ?? 0;

  return {
    unread,
    read,
    loading,
    refetch,
  };
}
