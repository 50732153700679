import { useMemo } from 'react';

import { createFilterBuilder } from '@jebel/utils';

import {
  useOrganizations,
  useOrganizationsFilter,
  useRandomOrganizations,
} from 'features/organizations/hooks';
import { OrganizationFilter } from 'shared/graphql';
import { useSearchContext } from 'shared/features/search';

export function useExplorerSupporters() {
  const { searchQuery, sortOption } = useSearchContext();
  const { filter: currentFilter } = useOrganizationsFilter();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<OrganizationFilter>(currentFilter);

    filter.and({ schoolSupporter: { some: { id: { is_not_empty: true } } } });

    return filter.build();
  }, [currentFilter]);

  const {
    data: organizations,
    count: organizationsCount,
    loading: organizationsLoading,
  } = useOrganizations({
    skip: searchQuery.length === 0,
    variables: { filter },
  });

  const {
    data: randomOrganizations,
    count: randomOrganizationsCount,
    loading: randomOrganizationsLoading,
  } = useRandomOrganizations({
    skip: searchQuery.length > 0,
    variables: { filter, sort: sortOption },
  });

  const data = searchQuery ? organizations : randomOrganizations;
  const count = searchQuery ? organizationsCount : randomOrganizationsCount;
  const loading = searchQuery ? organizationsLoading : randomOrganizationsLoading;

  return {
    data,
    count,
    loading,
  };
}
