import React from 'react';
import { css } from '@emotion/react';
import { Box, Chip } from '@material-ui/core';
import { Avatar } from '@mui/material';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { UserListItemFragment } from 'shared/graphql/__generated__';
import { buildUrl } from 'shared/routes';
import { getPassedTime } from 'shared/utils/date';
import { useResponsive } from 'shared/hooks';
import { glueStrings } from 'shared/utils/strings';

const AVATAR_SIZE = 40;
const AVATAR_MOBILE_SIZE = 35;

const cardIconCss = theme => css`
  width: ${theme.breakpoints.down('sm') ? AVATAR_MOBILE_SIZE : AVATAR_SIZE}px;
  height: ${theme.breakpoints.down('sm') ? AVATAR_MOBILE_SIZE : AVATAR_SIZE}px;
  margin-right: ${theme.spacing(1)}px;
`;

const chipCss = theme => css`
  background-color: ${theme.palette.primary.icon};
  color: #fff;
  border-radius: ${theme.spacing(0.5)}px;
  padding: 0;
  height: 16px;
  margin-left: ${theme.spacing(1)}px;
`;

const fullNameCss = theme => css`
  color: ${theme.palette.primary.dark};

  ${theme.breakpoints.down('sm') &&
  `
    font-size: 0.83rem;
    font-weight: ${theme.typography.fontWeightMedium}
   `};
`;

const birthDateCss = theme => css`
  ${theme.breakpoints.down('sm') && `color: ${theme.palette.text.secondary}; font-size: 0.825rem`};
`;

const cardCss = theme => css`
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

export const UserCard = ({ user }: { user: UserListItemFragment }) => {
  const history = useHistory();
  const { isMobile } = useResponsive();

  const avatarSize = isMobile ? AVATAR_MOBILE_SIZE : AVATAR_SIZE;

  const isBirthdayToday = React.useMemo(
    () =>
      DateTime.now().day === DateTime.fromISO(user?.birthDate).day &&
      DateTime.now().month === DateTime.fromISO(user?.birthDate).month,
    [user?.birthDate],
  );

  const onOpenProfile = React.useCallback(() => {
    if (!user?.id) {
      return;
    }

    const url = buildUrl(APP_URL.user.explorer.profile, {
      pathParams: { id: user?.id },
    });

    history.push(url);
  }, [history, user?.id]);

  return (
    <Box
      onClick={onOpenProfile}
      css={cardCss}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex">
        <Box display="flex" mr={2}>
          <Avatar
            css={cardIconCss}
            srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
              compress: true,
              resize: {
                width: avatarSize,
                height: avatarSize,
                fit: 'crop',
              },
            })}
            src={user?.avatar?.downloadUrl || ''}
            variant="circular"
          />
          <Box>
            <Box display="flex" alignItems="center">
              <Typography css={fullNameCss} variant="body2" color="inherit">
                {glueStrings(' ', user.firstName, user.lastName)}
              </Typography>
              {isBirthdayToday && (
                <Chip css={chipCss} disabled={false} label="Today" size="medium" />
              )}
            </Box>
            {user?.birthDate && (
              <Typography css={birthDateCss} variant="body2">
                {DateTime.fromISO(user?.birthDate).toFormat('MMM d yyyy')}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {user?.birthDate && (
        <Typography variant="body2" color="textSecondary">
          {getPassedTime(user?.birthDate, { units: ['years'] })[0]}{' '}
          {getPassedTime(user?.birthDate, { units: ['years'] })[1]}
          {' old'}
        </Typography>
      )}
    </Box>
  );
};
