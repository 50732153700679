import React from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { Icon, Typography } from 'shared/components/ui';

const CONTAINER_MAX_HEIGHT = 165;

type DescriptionProps = {
  description: string | null | undefined;
};

const Container = styled.div<{ isShowMoreButton: boolean }>`
  max-height: ${props => (props.isShowMoreButton ? `${CONTAINER_MAX_HEIGHT}px` : '100%')};
  overflow: ${props => (props.isShowMoreButton ? `hidden` : 'inherit')};
`;

const moreButtonContainerCss = theme => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const moreTextCss = theme => css`
  color: ${theme.palette.secondary.light};
`;

export const Description = ({ description }: DescriptionProps) => {
  const theme: any = useTheme();
  const [height, setHeight] = React.useState<number>(0);
  const [isOpenDescription, setIsOpenDesription] = React.useState<boolean>(false);

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const isShowMoreButton = React.useMemo(
    () => height > CONTAINER_MAX_HEIGHT && !isOpenDescription,
    [height, isOpenDescription],
  );

  const onOpenDescription = React.useCallback(() => {
    setIsOpenDesription(true);
  }, []);

  return (
    <React.Fragment>
      <Container isShowMoreButton={isShowMoreButton} ref={measuredRef}>
        <Typography
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          `}
        >
          {description}
        </Typography>
      </Container>
      {isShowMoreButton && (
        <Box onClick={onOpenDescription} css={moreButtonContainerCss}>
          <Typography variant="subtitle5" css={moreTextCss}>
            Show more
          </Typography>
          <Icon
            name="KeyboardArrowDown"
            variant="filled"
            style={{ fill: theme.palette.secondary.light }}
          />
        </Box>
      )}
    </React.Fragment>
  );
};
