import React from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { Box } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { buildUrl } from 'shared/routes';

import { BusinessDetailsSidebarList } from './BusinessDetailsSidebarList';
import { BusinessDetailsSidebarOrganizationProfile } from './BusinessDetailsSidebarOrganizationProfile';

const containerCss = (theme: any) => css`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${theme.palette.border};
`;

export const BusinessDetailsSidebar = () => {
  const { id } = useParams<{ id: string }>();

  const SIDEBAR_LIST_DATA = [
    {
      title: 'Business Information',
      icon: 'Info',
      route: buildUrl(APP_URL.admin.business.information, {
        pathParams: { id },
      }),
    },
    {
      title: 'Managers',
      icon: 'People',
      route: buildUrl(APP_URL.admin.business.managers, {
        pathParams: { id },
      }),
    },
  ];

  return (
    <Box css={containerCss}>
      <BusinessDetailsSidebarOrganizationProfile />
      <BusinessDetailsSidebarList listData={SIDEBAR_LIST_DATA} />
    </Box>
  );
};
