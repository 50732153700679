import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  USER_UPDATE_MUTATION,
  UserUpdateInput,
  UserUpdateMutation,
  UserUpdateMutationVariables,
} from 'shared/graphql';

import { useCurrentUser } from '.';

export const useUserUpdate = ({ id }: { id?: string | null | undefined }) => {
  const { userId: currentUserId } = useCurrentUser();

  const [userUpdate, { loading }] = useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
    USER_UPDATE_MUTATION,
    {
      refetchQueries: ['CurrentUser', 'UserPublicProfile'],
      awaitRefetchQueries: true,
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Account information was successfully updated',
        [SNACKBAR_ERROR_MESSAGE]: 'Account information was not updated',
      },
    },
  );

  const onUserUpdate = (data: UserUpdateInput) => {
    if (!id && !currentUserId) {
      return;
    }

    return userUpdate({ variables: { data: { ...data, id: id || currentUserId } } });
  };

  return { onUserUpdate, loading };
};
