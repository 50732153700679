import { Box } from '@material-ui/core';

import { Button } from 'shared/components/ui';
import { GroupKeyFilter } from 'shared/graphql';

import { GroupMembersList } from 'features/groups/components';
import { useCurrentUser } from 'shared/hooks';

export const GroupDetailsMembers = ({
  group,
  onModalClose,
  onDeleteGroupClick,
}: {
  group: GroupKeyFilter | undefined | null;
  onModalClose: () => void;
  onDeleteGroupClick: () => void;
}) => {
  const { isAdmin } = useCurrentUser();

  return (
    <Box display="flex" flexDirection="column" justifyContent="start">
      <GroupMembersList group={group} withActions={isAdmin} />

      <Box display="flex" justifyContent="flex-end" my={1}>
        <Button onClick={onModalClose} size="medium">
          Close
        </Button>

        <Button onClick={onDeleteGroupClick} disabled={!isAdmin} size="medium">
          Delete Group
        </Button>
      </Box>
    </Box>
  );
};
