import { useQuery } from '@apollo/client';

import { PAGE_SIZE } from 'shared/constants';
import {
  GraduatedUsersListPerYearQuery,
  GraduatedUsersListPerYearQueryVariables,
} from 'shared/graphql';

import { GRADUATED_USERS_LIST_PER_YEAR_QUERY } from '../queries';

export const useGraduatedUsersListPerYear = (startYear: number) => {
  const { data, loading, ...rest } = useQuery<
    GraduatedUsersListPerYearQuery,
    GraduatedUsersListPerYearQueryVariables
  >(GRADUATED_USERS_LIST_PER_YEAR_QUERY, {
    variables: {
      first: PAGE_SIZE,
      filter: {
        AND: [
          { graduatingYear: { gte: `${startYear}-01-01` } },
          { graduatingYear: { lt: `${startYear + 1}-01-01` } },
        ],
      },
    },
  });

  return {
    correspondingUsersList: data?.usersList?.items ?? [],
    correspondingUsersLoading: loading,
    ...rest,
  };
};
