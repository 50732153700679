import { useMemo } from 'react';

import { formatSQLDate, parseDate } from '@jebel/utils';

import { LegacyFilterType } from 'features/legacy/types';
import { useSearchContext } from 'shared/features/search';
import { HomeFeedItemFilter } from 'shared/graphql';
import { UsePostsFeedOptions, usePostsFeed } from 'shared/hooks';
import { createFilterBuilder } from 'shared/queries/filterBuilder';
import { LEGACY_POST_TYPE } from 'shared/features/posts/constants';
import { PAGE_SIZE } from 'shared/constants';

/**
 * List all the home feed posts but limitated to **Legacy Posts** and filtered using the `SearchContext`.
 * @throws When outside of `SearchProvider`.
 */
export function useLegacyFeed(options?: UsePostsFeedOptions) {
  const {
    searchQuery: search,
    filter: selectedFilter,
    dateFilter: selectedFilterDate,
    sortOption,
  } = useSearchContext<LegacyFilterType>();

  const filter = useMemo<HomeFeedItemFilter>(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>({
      postType: { equals: LEGACY_POST_TYPE },
    });

    if (options?.variables?.filter) {
      filter.and(options.variables.filter);
    }

    if (search) {
      const searchFilter = createFilterBuilder<HomeFeedItemFilter>();
      const words = search.split(/\s+/i);

      searchFilter.or({ text: { contains: search } });
      searchFilter.or({ authorEmail: { contains: search } });
      searchFilter.or({ authorFirstName: { contains: search } });
      searchFilter.or({ authorLastName: { contains: search } });
      searchFilter.or({ authorFullName: { starts_with: search } });

      for (const word of words) {
        searchFilter.or({ text: { contains: word } });
      }

      filter.and(searchFilter);
    }

    if (selectedFilter?.authorId) {
      filter.and({
        authorId: { equals: selectedFilter.authorId },
      });
    }

    const parsedFilterDate = parseDate(selectedFilterDate);

    if (parsedFilterDate.isValid) {
      filter.and({
        // Filter by the exact date as selected on the filter.
        legacyDate: { equals: formatSQLDate(parsedFilterDate) },
      });
    }

    const parsedFilterStartDate = parseDate(selectedFilter?.legacyStartDate);

    if (parsedFilterStartDate.isValid) {
      filter.and({
        // Filter by a range dates with "startDate" selected on the filter.
        legacyDate: { gte: formatSQLDate(parsedFilterStartDate) },
      });
    }

    const parsedFilterEndDate = parseDate(selectedFilter?.legacyEndDate);

    if (parsedFilterEndDate.isValid) {
      filter.and({
        // Filter by a range dates with "endDate" selected on the filter.
        legacyDate: { lte: formatSQLDate(parsedFilterEndDate) },
      });
    }

    return filter.build();
  }, [options, search, selectedFilter, selectedFilterDate]);

  const { data, count, loading, refreshing, refetch, fetchMore } = usePostsFeed({
    ...options,

    variables: {
      filter,
      first: PAGE_SIZE,
      sort: sortOption,
    },
  });

  return {
    data,
    count,
    hasMore: data.length < count,
    loading,
    refreshing,

    refetch,
    fetchMore,
  };
}
