import React from 'react';

import { LegacyPostsContent } from '../components/LegacyPosts';
import { LegacyLayout } from '../components/layouts/LegacyLayout';

export const LegacyPostsPage = () => {
  return (
    <LegacyLayout>
      <LegacyPostsContent />
    </LegacyLayout>
  );
};
