/* eslint-disable guard-for-in */
import { DateTime, DurationUnit } from 'luxon';
import pluralize from 'pluralize';
import { last } from 'lodash';

import {
  parseDate,
  formatDate as formatDateLuxon,
  extractYear,
  formatUSADate,
  formatTableDate,
  formatShortDate,
  formatMilliseconds,
  formatSQLDate,
  currentDateTime,
} from '@jebel/utils';

import { roundNumber } from '../math';

export {
  /** @deprecated Import from `@jebel/utils` instead. */
  parseDate,
  /** @deprecated Import from `@jebel/utils` instead. */
  extractYear,
  /** @deprecated Import from `@jebel/utils` instead. */
  formatUSADate,
  /** @deprecated Import from `@jebel/utils` instead. */
  formatTableDate,
  /** @deprecated Import from `@jebel/utils` instead. */
  formatShortDate,
  /** @deprecated Import from `@jebel/utils` instead. */
  formatMilliseconds,
  /** @deprecated Import from `@jebel/utils` instead. */
  formatSQLDate,
};

type GetTimeDiffOptions = { units?: DurationUnit[] };

/**
 * Returns time difference between `fromDate` and `toDate` for highest unit
 * @param fromDate - ISO date
 * @param toDate - ISO date
 * @example getTimeDiff('2020-04-08', '2020-04-09', { units: ['days'] }) // -> [-1, 'day']
 */
export const getTimeDiff = (
  fromDate: string,
  toDate: string,
  { units = ['weeks', 'days', 'hours', 'minutes', 'years', 'months'] }: GetTimeDiffOptions = {},
) => {
  const from = DateTime.fromISO(fromDate);
  const to = DateTime.fromISO(toDate);
  const diffObject = from.diff(to, units).toObject();
  const diffObjectEntries: Array<[string, number]> = Object.entries(diffObject);

  const [timeUnit, timeValue] = diffObjectEntries.find(
    ([key, value]) => !!((units as string[]).includes(key) && value !== 0),
  ) ||
    last(diffObjectEntries) || ['seconds', 0];

  return [timeValue, pluralize(timeUnit, timeValue)] as const;
};

/**
 * Returns passed time from `startDate` to now
 * @param startDate - ISO date to be compared with now
 * @example getPassedTime('2020-04-08') // -> [2, 'years']
 */
export const getPassedTime = (startDate: string, options?: GetTimeDiffOptions) => {
  const now = DateTime.utc().toISO();
  const [timeDiff, timeUnit] = getTimeDiff(now, startDate, options);
  const timeValue = Math.max(roundNumber(timeDiff), 0);

  return [timeValue, pluralize(timeUnit, timeValue)] as const;
};

/**
 * Returns passed time as a text, e.g. '8 hours ago'
 * @param startDate - ISO date to be compared with now
 * @deprecated Use `formatTimeAgo` instead.
 */
export const getTextTimeAgo = (startDate: string, options?: GetTimeDiffOptions) => {
  const [timeAgo, timeAgoUnit] = getPassedTime(startDate, options);

  return `${timeAgo} ${timeAgoUnit} ago`;
};

type GetRemainingTimeOptions = { startDate?: string; unit?: DurationUnit; shouldFloor?: boolean };

/**
 * Returns remaining time for some unit
 * @param expireDate - ISO date
 */
export const getRemainingTime = (
  expireDate: string,
  {
    startDate = DateTime.utc().toISO(),
    unit = 'millisecond',
    shouldFloor = false,
  }: GetRemainingTimeOptions = {},
) => {
  let [timeDiff] = getTimeDiff(expireDate, startDate, { units: [unit] });

  timeDiff = shouldFloor ? Math.floor(timeDiff) : roundNumber(timeDiff);

  return Math.max(timeDiff, 0);
};

/**
 * Returns remaining time as a text, e.g. '1 hour remaining'
 */
export const getRemainingTimeText = (
  remainingTime: number,
  unit: DurationUnit,
  { remainingWord = 'remaining' } = {},
) => {
  return `${remainingTime} ${pluralize(unit, remainingTime)} ${remainingWord}`;
};

export const dateToMonthAndYearSpan = (date1: string, date2: string): string => {
  return `${DateTime.fromISO(date1).toFormat('LLL y')} - ${DateTime.fromISO(date2).toFormat(
    'LLL y',
  )}`;
};

export const getDateDifferenceInMonthsAndYears = (date1: string, date2: string): string => {
  const differenceObject = DateTime.fromISO(date2)
    .diff(DateTime.fromISO(date1), ['years', 'months'])
    .toObject();
  return `${differenceObject?.years?.toFixed(0)} ${pluralize(
    'year',
    differenceObject?.years,
  )} ${differenceObject?.months?.toFixed(0)} ${pluralize('month', differenceObject?.months)}`;
};

export const getFullMonthAndWeekdayAndTime = (date: Date, timezone: string): string | undefined => {
  const formatDate = DateTime.fromJSDate(new Date(date)).setLocale('en-US');
  return `${formatDate.toFormat('cccc, DD - t')} ${timezone}`;
};

export const getDateDifferenceInDays = (date1: string, date2: string): string => {
  const differenceObject = DateTime.fromISO(date1)
    .diff(DateTime.fromISO(date2), ['days'])
    .toObject();
  return `${roundNumber(Number(differenceObject?.days))} ${pluralize(
    'day',
    roundNumber(Number(differenceObject?.days)),
  )} ago`;
};

export const getFullDateInMonthDayYear = (date: string) =>
  DateTime.fromISO(date).setLocale('en-US').toFormat('DDD');

/** @deprecated Use `formatDate` from `@jebel/utils` instead. */
export const formatDate = (date: string | Date, format: Intl.DateTimeFormatOptions) => {
  return parseDate(date).toLocaleString(format);
};

/** Convert any birht-date into age on years from now. */
export function formatAgeYears(birthdate: unknown) {
  const years = parseDate(birthdate).diffNow('years').as('years');
  return Math.floor(Math.abs(years));
}

/**
 * Extract the `month` of a given `value` formatted as any supported date format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `month` from `value`.
 */
export function extractMonth(value: unknown) {
  const date = parseDate(value);

  if (date.isValid) {
    return date.month;
  }

  return null;
}

const RELATIVE_TIME_FORMAT_UNITS: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export function formatTimeAgo(
  value: unknown,
  relative: unknown = currentDateTime(),
  options?: { units?: typeof RELATIVE_TIME_FORMAT_UNITS },
): string {
  const units = options?.units ?? RELATIVE_TIME_FORMAT_UNITS;

  const selectedDate = parseDate(value);
  const relativeDate = parseDate(relative);

  const diff = selectedDate.diff(relativeDate).shiftTo(...units);
  const unit = units.find(unit => Math.abs(diff.get(unit)) > 0) ?? 'second';

  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const amount = Math.round(diff.as(unit));

  return formatter.format(amount, unit);
}

export function formatPostDate(value: unknown): string {
  const date = parseDate(value);
  const now = currentDateTime();

  const diff = date.diff(now);
  const hours = Math.abs(diff.as('hours'));

  if (hours < 1) {
    return formatTimeAgo(date);
  }

  if (date.year === now.year) {
    return formatDateLuxon(date, `MMMM dd 'at' hh:mm a`);
  }

  return formatDateLuxon(date, `MMMM dd, yyyy 'at' hh:mm a`);
}

export function formatLegacyDate(value: unknown): string {
  return formatDateLuxon(value, `MMMM dd, yyyy`);
}

interface FormatRelativeDatesOptions {
  units?: Intl.RelativeTimeFormatUnit[];
}

export function formatRelativeDates(
  start: unknown,
  end: unknown,
  options?: FormatRelativeDatesOptions,
): string {
  const units = options?.units ?? RELATIVE_TIME_FORMAT_UNITS;

  const startDate = parseDate(start);
  const endDate = parseDate(end);

  const diff = startDate.diff(endDate, units).toObject();
  const sections: string[] = [];

  for (const unit in diff) {
    const value = Math.abs(Math.round(diff[unit]));

    if (value > 0) {
      const formattedUnit = pluralize(unit, value);
      sections.push(`${value} ${formattedUnit}`);
    }
  }

  return sections.join(' ');
}

export function formatRelativeDate(value: unknown, options?: FormatRelativeDatesOptions): string {
  return formatRelativeDates(value, currentDateTime(), options);
}

export function formatExperienceRangeDates(
  start: unknown,
  end: unknown,
  context: {
    isCurrent?: boolean;
  },
) {
  if (context.isCurrent) {
    return 'Current';
  }

  const startDate = parseDate(start);
  const endDate = parseDate(end);

  const diff = formatRelativeDates(startDate, endDate, { units: ['years', 'months'] });

  const formattedStart = formatDateLuxon(startDate, 'MMM yyyy');
  const formattedEnd = formatDateLuxon(endDate, 'MMM yyyy');

  let payload = `${formattedStart} - ${formattedEnd}`;

  if (diff) {
    payload += `, ${diff}`;
  }

  return payload;
}

export function areYearsEqual(left: unknown, right: unknown) {
  const first = extractYear(left);
  const second = extractYear(right);

  return first === second;
}
