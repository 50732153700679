import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Container

export const Container = styled.div(p => {
  const color = p.theme.palette.primary.main;
  const backgroundColor = p.theme.palette.background.paper;

  return css`
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
    color: ${color};
    background-color: ${backgroundColor};
    cursor: pointer;
  `;
});

// Label

export const Label = styled.div`
  font-size: 1rem;
  color: ${p => p.theme.palette.grey[700]};
`;
