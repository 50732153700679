import { useQuery } from '@apollo/client';

import { GET_USER_JOINED_GROUPS } from '../graphql';

export const useGetUserGroups = (userId: string) => {
  const { data: groupData, loading: groupLoading } = useQuery<
    {
      user: { joinedGroups: { __typename: string; items: { __typename: string; id: string }[] } };
    },
    { ID: string }
  >(GET_USER_JOINED_GROUPS, {
    variables: { ID: userId },
  });

  return {
    groupData,
    groupLoading,
  };
};
