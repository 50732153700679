import { ReactNode } from 'react';
import { OnChangeHandlerFunc as MentionWrapperChangeHandler } from 'react-mentions';
import { FormControl, FormHelperText, MenuList, Paper } from '@material-ui/core';
import { useTheme } from '@emotion/react';

import { HASHTAG_TRIGGER, MENTION_TRIGGER } from '@jebel/constants';

import { MentionDataSource, MentionList, MentionPaper, Wrapper } from './MentionsInput.styles';
import { MentionsInputProps } from './MentionsInput.types';
import {
  useMentionsGroupDataSource,
  useMentionsHashTagDataSource,
  useMentionsOrganizationDataSource,
  useMentionsUserDataSource,
} from './datasources';
import { MentionPosition } from '../../types';

function renderSuggestionContainer(children: ReactNode) {
  return (
    <MentionPaper>
      <MentionList>{children}</MentionList>
    </MentionPaper>
  );
}

export function MentionsInput(props: MentionsInputProps) {
  const UsersDataSource = useMentionsUserDataSource();
  const OrganzationsDataSource = useMentionsOrganizationDataSource();
  const GroupsDataSource = useMentionsGroupDataSource();
  const HashTagDataSource = useMentionsHashTagDataSource();

  const { palette } = useTheme();

  const onChange: MentionWrapperChangeHandler = (_event, value, _rawValue, mentions) => {
    props.onChange(value, mentions as MentionPosition[]);
  };

  return (
    <FormControl fullWidth={props.fullWidth}>
      <Wrapper
        value={props.value}
        spellCheck={props.spellCheck}
        onChange={onChange}
        multiline={props.multiline}
        fullWidth={props.fullWidth}
        allowSpaceInQuery={props.allowSearchSpace}
        placeholder={props.placeholder}
        customSuggestionsContainer={renderSuggestionContainer}
      >
        <MentionDataSource trigger={MENTION_TRIGGER} {...UsersDataSource} />
        <MentionDataSource trigger={MENTION_TRIGGER} {...OrganzationsDataSource} />
        <MentionDataSource trigger={MENTION_TRIGGER} {...GroupsDataSource} />

        <MentionDataSource
          trigger={HASHTAG_TRIGGER}
          color={palette.info.main}
          {...HashTagDataSource}
        />
      </Wrapper>

      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
