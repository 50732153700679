import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

import { SORT } from '@jebel/constants';

import { Typography } from 'shared/components/ui';
import { HeadlinesType, HeadlineType, useSpreadsheetContext } from 'shared/features/spreadsheet';

const labelTypographyCss = css`
  font-weight: 500;
  white-space: nowrap;
`;

const headerTitlesCss = (theme: any) => css`
  background-color: ${theme.palette.grey['100']};
`;

const checkCellCss = () => css`
  width: 4%;
`;

export const actionCellCss = css`
  width: 6.17%;
  border-left: 1px solid #e0e0e0;
  padding: 8px 16px;
  border-bottom-color: rgba(224, 224, 224, 1);
`;

type SpreadsheetHeaderProps = {
  headlines: HeadlinesType;
  withActions?: boolean;
  withCheckbox?: boolean;
  withIndex?: boolean;
};

export const SpreadsheetHeader = ({
  headlines,
  withActions,
  withCheckbox = true,
  withIndex = true,
}: SpreadsheetHeaderProps) => {
  const theme = useTheme();

  const { setSortOption, sortOption } = useSpreadsheetContext();

  const createSortHandler = React.useCallback(
    ({ id, sortPath: differentPath }: HeadlineType) =>
      () => {
        const isSameHeadcell = sortOption?.headcellId === id;

        const newSortOrder =
          isSameHeadcell && sortOption?.order === SORT.asc ? SORT.desc : SORT.asc;

        const newSortOption = {
          order: newSortOrder,
          headcellId: id,
          sort: differentPath?.(newSortOrder) || { [id]: newSortOrder },
        };

        setSortOption(newSortOption);
      },
    [setSortOption, sortOption?.headcellId, sortOption?.order],
  );

  return (
    <TableHead>
      <TableRow>
        {withCheckbox && <TableCell css={checkCellCss} />}
        {withIndex && <TableCell css={[checkCellCss, headerTitlesCss(theme)]} />}
        {headlines.map(headCell => {
          const isActiveSort = headCell.id === sortOption?.headcellId;
          const direction = isActiveSort && sortOption?.order === SORT.desc ? 'desc' : 'asc';
          const withoutSort = typeof headCell.sortPath !== 'function' || headCell.withoutSort;

          return (
            <TableCell
              key={headCell.name}
              align="left"
              css={[headCell.css, withIndex && headerTitlesCss(theme)]}
            >
              {withoutSort ? (
                <Typography variant="body2" css={labelTypographyCss}>
                  {headCell.label}
                </Typography>
              ) : (
                <TableSortLabel
                  active={isActiveSort}
                  direction={direction}
                  onClick={createSortHandler(headCell)}
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  <Typography variant="body2" css={labelTypographyCss}>
                    {headCell.label}
                  </Typography>
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
        {withActions && (
          <TableCell align="left" css={actionCellCss}>
            <Typography variant="body2" css={labelTypographyCss}>
              ACTIONS
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
