import React from 'react';
import { Divider, Typography, Box, styled, Button } from '@mui/material';

import { AsyncContent, RouteLayout } from 'shared/components/ui';

import { MainNavigationSidebar } from '../MainNavigationSidebar/MainNavigationSidebar';
import { MainNavigationMobileNav } from '../MainNavigationMobileNav/MainNavigationMobileNav';
import { MainNavigationExternalLinks } from '../MainNavigationExternalLinks/MainNavigationExternalLinks';
import { useSchoolConfigurationLabels } from '../hooks/useSchoolConfigurationLabels';

const LayoutContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(min(350px, 100%), 1fr))',
  gridTemplateRows: 'auto 1fr',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  alignItems: 'start',
  [theme.breakpoints.between('md', 'xl')]: {
    '& > :first-of-type': {
      gridRow: 'span 2',
    },
  },
}));
const BottomActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));
const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: '500',
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export function MainNavigationWrapper() {
  const {
    mobileLabels,
    mobileLabelOptions,
    setMobileLabels,
    sidebarLabels,
    setSidebarLabels,
    loading,
    externalLinks,
    setExternalLinks,
    resetState,
    applyChanges,
  } = useSchoolConfigurationLabels();

  return (
    <RouteLayout>
      <Title variant="h4">Main Navigation</Title>

      <Divider />

      <AsyncContent loading={loading} center>
        <LayoutContent>
          <MainNavigationSidebar items={sidebarLabels} onListUpdate={setSidebarLabels} />

          <MainNavigationExternalLinks items={externalLinks} onListUpdate={setExternalLinks} />

          <MainNavigationMobileNav
            items={mobileLabels}
            availableOptions={mobileLabelOptions}
            onListUpdate={setMobileLabels}
          />
        </LayoutContent>
      </AsyncContent>

      <BottomActions>
        <Button onClick={resetState} variant="outlined">
          Cancel
        </Button>

        <Button onClick={applyChanges} variant="contained">
          Save Changes
        </Button>
      </BottomActions>
    </RouteLayout>
  );
}
