import { createUserFilter } from 'features/search/utils';

export function getJobSeekerSearchFilter(searchQuery: string) {
  const filter = createUserFilter({
    search: searchQuery,
    initial: {
      isSeekingEmployment: { equals: true },
    },
  });

  return filter;
}
