import { useLocation } from 'react-router-dom';

import { InboxSubject } from '@jebel/constants';
import { createInboxSubject } from '@jebel/utils';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { InboxMessageCreateInput, useInboxMessageCreateMutation } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { useNewInboxMessageNotification } from './useNewInboxMessageNotification';

export function useInboxMessagesPost() {
  const { sendInboxMessageNotification } = useNewInboxMessageNotification();
  const { userId } = useCurrentUser();
  const { pathname } = useLocation();
  const { configuration: school } = useSchoolConfiguration();
  const { showError } = useToast();

  const [createMessageMutation, mutationData] = useInboxMessageCreateMutation({
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been sent!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been sent. Try again.`,
    },
  });

  const createMessage = async (data: InboxMessageCreateInput, users?: string[]) => {
    try {
      await createMessageMutation({
        variables: {
          data: {
            ...data,
            author: { connect: { id: userId } },
          },
        },
      });

      let subject: InboxSubject = data.text;

      const isAdminRoute = pathname.startsWith('/admin');

      if (isAdminRoute) {
        subject = createInboxSubject({
          content: data.text,
          isFromSchool: true,
          school: {
            id: school.id,
            name: school.name,
          },
        });
      }

      sendInboxMessageNotification({
        userIds: users?.filter(id => id !== userId) as string[],
        inboxId: data?.inbox?.connect?.id as string,
        authorId: userId as string,
        subject,
      });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return {
    createMessage,
    mutationData,
  };
}
