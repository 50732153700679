import { useCallback, useMemo } from 'react';

import { Maybe } from 'shared/graphql';
import { useRemoteVariable } from 'shared/hooks';
import { parseDate } from 'shared/utils/date';

interface Input {
  postDate?: Maybe<string>;
  createdAt?: Maybe<string>;
}

/** Check the `offer` as expired or not. */
export function useHasJobOfferExpired(offer: Input) {
  const isExpired = useJobOfferExpiredFilter();

  return useMemo(() => {
    return isExpired(offer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.createdAt]);
}

/** Creates a function that checks if a job offer is expired. */
export function useJobOfferExpiredFilter() {
  const expirationDays = useRemoteVariable('jobOfferExpirationDays');

  return useCallback(
    (offer: Input) => {
      return isJobOfferExpired(offer.postDate ?? offer.createdAt, expirationDays);
    },
    [expirationDays],
  );
}

/** @returns A `boolean` whether the `creation` date is expired based on the `expirationDays`. */
export function isJobOfferExpired(creation: unknown, expirationDays = 40) {
  const date = parseDate(creation);
  const days = Math.abs(date.diffNow().as('days'));

  return days > expirationDays;
}
