import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { UsersFilter } from 'features/explorer/types';
import { SELECT_OPTION_ALL } from 'shared/constants';
import { UserFilter } from 'shared/graphql';
import { createFilterBuilder } from 'shared/queries/filterBuilder';
import { createUserFilter } from 'features/search/utils';

interface Context {
  searchQuery?: string;
  currentFilter: UsersFilter;
  /** @deprecated Use `zipCode` inside `currentFilter` instead. */
  zipCode?: string;
}

export function getUserSearchFilter({
  currentFilter,
  searchQuery,
  ...context
}: Context): UserFilter {
  const zip = currentFilter?.zipCode ?? context.zipCode;

  const base = createUserFilter({ search: searchQuery, zip });
  const filter = createFilterBuilder<UserFilter>(base);

  if (
    currentFilter?.currentIndustry &&
    isEqual(currentFilter.currentIndustry, SELECT_OPTION_ALL) === false
  ) {
    filter.and({ currentIndustry: { equals: currentFilter.currentIndustry } });
  }

  if (currentFilter?.graduatingYear && !isEqual(currentFilter.graduatingYear, SELECT_OPTION_ALL)) {
    const date = DateTime.now().set({ year: Number(currentFilter.graduatingYear) });

    const startYear = date.startOf('year');
    const endYear = date.endOf('year');

    filter
      .and({ graduatingYear: { gte: startYear.toSQLDate() } })
      .and({ graduatingYear: { lte: endYear.toSQLDate() } });
  }

  if (currentFilter?.affiliation && !isEqual(currentFilter.affiliation, SELECT_OPTION_ALL)) {
    filter.and({ affiliation: { equals: currentFilter.affiliation } });
  }

  if (currentFilter?.jobSeekersOnly && !isEqual(currentFilter.jobSeekersOnly, SELECT_OPTION_ALL)) {
    const isSeekingEmployment = Boolean(currentFilter.jobSeekersOnly);
    filter.and({ isSeekingEmployment: { equals: isSeekingEmployment } });
  }

  return filter.build();
}

export const getFilterUserByName = (searchQuery: string) => [
  {
    createdBy: { firstName: { contains: searchQuery } },
  },
  { createdBy: { lastName: { contains: searchQuery } } },
  {
    AND: [
      { createdBy: { firstName: { contains: searchQuery?.split(' ')[0] } } },
      { createdBy: { lastName: { contains: searchQuery?.split(' ')[1] } } },
    ],
  },
];
