import { useCallback } from 'react';

import { SCHOOL_POST_TYPE } from '@jebel/constants';

import { SchoolNewsPostUpdateInput, useUpdatePostMutation } from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

export const useSchoolNewsPostUpdate = () => {
  const [postUpdate, mutationData] = useUpdatePostMutation({
    refetchQueries: ['SchoolNewsPostsList', 'HomeFeedItems'],
    awaitRefetchQueries: true,
  });

  const onPostUpdate = useCallback(
    async (data: SchoolNewsPostUpdateInput, postLastSchedule?: string | null) => {
      if (!data.id) {
        throw new Error('Should sent a ID post to update');
      }

      const response = await postUpdate({
        variables: { postData: data, postId: data.id, postType: SCHOOL_POST_TYPE },
        context: !postLastSchedule
          ? {
              [SNACKBAR_SUCCESS_MESSAGE]: 'Success: You have scheduled the post.',
              [SNACKBAR_ERROR_MESSAGE]: `Error: You haven't scheduled the post. Try again later. `,
            }
          : {
              [SNACKBAR_SUCCESS_MESSAGE]: 'Success: You have updated the post.',
              [SNACKBAR_ERROR_MESSAGE]: `Error: You haven't updated the post. Try again later. `,
            },
      });

      return response;
    },
    [postUpdate],
  );

  return {
    onPostUpdate,
    ...mutationData,
  };
};
