import { descend } from 'ramda';

import { User } from 'shared/graphql';

import { formatMilliseconds, parseDate } from '../date';

export const getUsersByBirthday = <T extends { birthDate?: User['birthDate'] | null }>(
  users: T[],
  count = 3,
): T[] => {
  if (users.length === 0) {
    return [];
  }

  const year = new Date().getFullYear();

  const people = [...users];

  const sorted = people
    .filter(user => {
      const birthdate = parseDate(user.birthDate);
      const diff = birthdate.set({ year }).diffNow('days');

      /**
       * Next birthdates between today and seven days in the future.
       * https://8base-dev.atlassian.net/browse/JEB-1258
       */
      return diff.days > 0 && diff.days < 7;
    })
    .sort(descend(user => formatMilliseconds(user.birthDate)));

  return sorted.slice(0, count);
};
