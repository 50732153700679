import React from 'react';
import { css } from '@emotion/react';

const divCSS = css`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type RootProps = {
  children?: React.ReactNode;
  dialogs?: React.ReactNode;
};

export const Root: React.FC<RootProps> = symbolProps => {
  return (
    <div css={divCSS}>
      {symbolProps.children}
      {symbolProps.dialogs}
    </div>
  );
};
