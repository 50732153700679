import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Theme, css, useTheme } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { extractInboxSubjectContent } from '@jebel/utils';

import { AsyncContent, Typography, Icon } from 'shared/components/ui';
import { useCurrentUser, useQueryParams, useResponsive } from 'shared/hooks';
import { INBOX_ROUTE, INBOX_TYPES, SENT_ROUTE } from 'features/inbox/constants';
import { READ_ALL_INBOX_MESSAGES } from 'features/inbox/queries';
import {
  InboxMessageFragment,
  ReadAllInboxMessagesMutation,
  ReadAllInboxMessagesMutationVariables,
} from 'shared/graphql';
import { APP_URL } from 'shared/constants';
import { useNotifications } from 'shared/features/notifications';
import { recordError } from 'shared/utils/record';

import { useInboxMessagesList } from '../../hooks/useInboxMessagesList';
import { InboxMessagesButtonForm } from './InboxMessagesButtonForm';
import { InboxMessagesItem } from './InboxMessagesItem';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
`;

const headerCSS = (theme: Theme) => css`
  padding: 1rem 1.5rem;
  border-bottom: solid 1px ${theme.palette.divider};
`;

const messagesCSS = (theme: Theme) => css`
  ${theme.breakpoints.up('sm')} {
    flex: auto;
    overflow-y: auto;
  }
`;

interface Props {
  currentRoute: string;
}

export const InboxMessages = ({ currentRoute }: Props) => {
  const { markNotificationsAsRead } = useNotifications();
  const { isMobile } = useResponsive();
  const history = useHistory();
  const [{ inboxId }] = useQueryParams<{ inboxId: string | null | undefined }>();
  const { items, loading, users, subject, inbox, refetch, attachOptimisticMessage } =
    useInboxMessagesList({ inboxId });

  const subjectContent = useMemo(() => {
    try {
      return extractInboxSubjectContent(subject);
    } catch (err) {
      recordError(err);
    }
  }, [subject]);

  const [readAllMessages] = useMutation<
    ReadAllInboxMessagesMutation,
    ReadAllInboxMessagesMutationVariables
  >(READ_ALL_INBOX_MESSAGES, {
    variables: { inboxId: inboxId as string },
    refetchQueries: ['InboxUnreadMessages'],
  });

  const { userId: currentUserId } = useCurrentUser();
  const theme: any = useTheme();

  const renderItem = useCallback(
    (data: InboxMessageFragment, index: number, array: Array<unknown>) => {
      const key = `${index}-${data.id}`;
      return (
        <Fragment key={key}>
          <InboxMessagesItem
            {...data}
            users={users}
            subject={subject}
            refetch={refetch}
            inboxId={inboxId}
            currentRoute={currentRoute}
          />
        </Fragment>
      );
    },
    [currentRoute, inboxId, refetch, subject, users],
  );

  useEffect(() => {
    // if current route isn't inbox, we can't read all messages
    if (currentRoute !== INBOX_ROUTE) return;

    // if all messages are read, we can't read all messages
    const allMessageAreRead = items.every(message => message.isRead);
    if (allMessageAreRead || currentRoute !== INBOX_ROUTE) return;
    readAllMessages({ variables: { inboxId: inboxId as string } }).catch(console.error);
    markNotificationsAsRead(item => {
      if (!item.link) return false;
      const urlParams = new URL(item.link, window.location.origin).searchParams;
      return urlParams.get('inboxId') === inboxId;
    });
  }, [currentRoute, items, inboxId, readAllMessages, markNotificationsAsRead]);

  const onBackToChats = useCallback(() => {
    if (currentRoute === INBOX_ROUTE) {
      return history.push(APP_URL.user.mail.inbox);
    }

    if (currentRoute === SENT_ROUTE) {
      return history.push(APP_URL.user.mail.sent);
    }

    return history.push(APP_URL.user.mail.trash);
  }, [currentRoute, history]);

  const formatDestination = useCallback(() => {
    if (inbox?.type === INBOX_TYPES.multiPersonal || inbox?.type === INBOX_TYPES.personal) {
      return users
        .filter(user => user?.id !== currentUserId)
        .map(user => `${user?.firstName} ${user?.lastName}`)
        .join(', ');
    }

    return inbox?.destinationGroups.map(group => group.name).join(', ');
  }, [currentUserId, inbox?.destinationGroups, inbox?.type, users]);

  return (
    <div css={containerCSS}>
      {isMobile && (
        <Button
          variant="text"
          startIcon={<Icon name="KeyboardBackspace" variant="filled" />}
          onClick={onBackToChats}
        >
          Go back
        </Button>
      )}

      <AsyncContent loading={loading}>
        {!inboxId || items.length === 0 ? (
          <Box p={3}>
            <Typography variant="subtitle5" align="center">
              You don&apos;t have {currentRoute.toLowerCase()} messages
            </Typography>
          </Box>
        ) : (
          <div css={headerCSS}>
            <Typography variant="subtitle4" withEllipsis>
              {subjectContent}
            </Typography>

            {currentRoute === SENT_ROUTE && (
              <Typography variant="subtitle4" style={{ color: theme.palette.secondary.dark }}>
                to: {formatDestination()}
              </Typography>
            )}
          </div>
        )}

        <div css={messagesCSS}>{items.map(renderItem)}</div>
      </AsyncContent>

      <InboxMessagesButtonForm
        currentRoute={currentRoute}
        users={users.map(user => user.id) as any}
        inbox={inbox}
        author={items[0]?.author}
        onMessageCreate={attachOptimisticMessage}
      />
    </div>
  );
};
