import React from 'react';
import { ApolloError, ApolloCache, useApolloClient } from '@apollo/client';
import * as R from 'ramda';

import { useAppAuth } from 'providers/useAppAuth';
import { USER_BY_ID_QUERY } from 'shared/graphql';
import {
  useUserByIdQuery,
  UserByIdQuery,
  UserByIdQueryVariables,
  CurrentUserFragment,
} from 'shared/graphql/__generated__';

type UserByIdParameters = Parameters<typeof useUserByIdQuery>[0];

const readCurrentUserCache = (
  cache: ApolloCache<unknown>,
  variables: UserByIdQueryVariables | undefined,
) => {
  return cache.readQuery<UserByIdQuery, UserByIdQueryVariables>({
    query: USER_BY_ID_QUERY,
    variables,
  });
};

const updateCurrentUserCache = (
  cache: ApolloCache<unknown>,
  variables: UserByIdQueryVariables | undefined,
  newUserData: Partial<CurrentUserFragment>,
) => {
  const existingData = readCurrentUserCache(cache, variables);

  if (R.isNil(existingData)) {
    return undefined;
  }

  const newData: UserByIdQuery = {
    ...existingData,
    user: {
      ...existingData.user,
      ...newUserData,
    },
  };

  cache.writeQuery<UserByIdQuery, UserByIdQueryVariables>({
    query: USER_BY_ID_QUERY,
    data: newData,
    variables,
  });
};

type CurrentUserResponse = {
  user: CurrentUserFragment | undefined;
  userId: string | null | undefined;
  updateUserCache: (newData: Partial<CurrentUserFragment>, memberId?: string) => void;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useUserById = ({ id }: { id?: string | undefined | null }): CurrentUserResponse => {
  const { cache } = useApolloClient();
  const { isAuthenticated } = useAppAuth();

  const STATIC_VARIABLES: UserByIdParameters = React.useMemo(
    () => ({
      variables: { id },
      skip: !isAuthenticated,
    }),
    [id, isAuthenticated],
  );

  const queryOptions: UserByIdParameters = React.useMemo(() => {
    return id ? STATIC_VARIABLES : R.omit(['variables'], STATIC_VARIABLES);
  }, [STATIC_VARIABLES, id]);

  const { data, loading, error } = useUserByIdQuery(queryOptions);

  const updateUserCache = React.useCallback(
    (newData: Partial<CurrentUserFragment>, memberId?: string) => {
      updateCurrentUserCache(cache, queryOptions.variables || { id: memberId }, newData);
    },
    [cache, queryOptions.variables],
  );

  return {
    user: data?.user || undefined,
    userId: data?.user?.id,
    updateUserCache,
    loading: loading && !data,
    error,
  };
};
