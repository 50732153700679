export const HOME_POST_TYPE = 'HomePost';
export const SCHOOL_POST_TYPE = 'SchoolPost';
export const LEGACY_POST_TYPE = 'LegacyPost';
export const EVENT_POST_TYPE = 'EventPost';
export const DISCOUNT_POST_TYPE = 'DiscountPost';
export const JOB_OFFER_POST_TYPE = 'JobOfferPost';
export const GROUP_POST_TYPE = 'GroupPost';
/** Group the types from `HomeFeedItemType`. */
export const HOME_FEED_ITEM_TYPES = {
    HOME: HOME_POST_TYPE,
    SCHOOL: SCHOOL_POST_TYPE,
    LEGACY: LEGACY_POST_TYPE,
    EVENT: EVENT_POST_TYPE,
    DISCOUNT: DISCOUNT_POST_TYPE,
    JOB_OFFER: JOB_OFFER_POST_TYPE,
    GROUP: GROUP_POST_TYPE,
};
