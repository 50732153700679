import { Business } from '@material-ui/icons';

import { ProfessionalProfileFragment } from 'shared/graphql';

import {
  Aside,
  CompanyLogo,
  CompanyName,
  Container,
  Content,
  Description,
  ExperienceContent,
  TimelineContainer,
  TimelineDot,
} from './ExperienceCard.styles';
import { ExperienceCardContent } from './ExperienceCardContent';
import { ExperienceCardActions } from './types';

interface Props extends ExperienceCardActions {
  data: ProfessionalProfileFragment;
  timeline?: ProfessionalProfileFragment[];
}

export function ExperienceCard(props: Props) {
  const timeline = props.timeline ?? [];
  const description = props.data.positionDescription;

  const companyName = props.data.companyName ?? '(Unknown)';
  const companyAvatarSource = props.data.companyImage?.downloadUrl ?? undefined;

  return (
    <Container>
      <Aside>
        <CompanyLogo variant="square" src={companyAvatarSource}>
          <Business />
        </CompanyLogo>

        <TimelineContainer>
          {timeline.map(data => (
            <TimelineDot key={data.id} />
          ))}
        </TimelineContainer>
      </Aside>

      <Content>
        <CompanyName>{companyName}</CompanyName>

        <ExperienceContent>
          <ExperienceCardContent
            data={props.data}
            allowEdit={props.allowEdit}
            allowRemove={props.allowRemove}
            onEdit={props.onEdit}
            onRemove={props.onRemove}
          />

          {timeline.map(data => (
            <ExperienceCardContent
              key={data.id}
              data={data}
              allowEdit={props.allowEdit}
              allowRemove={props.allowRemove}
              onEdit={props.onEdit}
              onRemove={props.onRemove}
            />
          ))}
        </ExperienceContent>

        <Description>{description}</Description>
      </Content>
    </Container>
  );
}
