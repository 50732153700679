import styled from '@emotion/styled';
import { Dialog as DialogBase, IconButton, PaperProps } from '@mui/material';
import { ComponentType } from 'react';

// Container

const Container = styled.div<PaperProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 80vh;
`;

// Dialog

export const Dialog = styled(DialogBase)``;

Dialog.defaultProps = {
  PaperComponent: Container as ComponentType<PaperProps>,
};

// Header

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
`;

// CarouselContainer

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
`;

// CarouselContent

export const CarouselContent = styled.div`
  min-height: 80vh;
  background-color: white;
  align-self: stretch;
  flex: 1;
`;

// CarouselButtons

export const CarouselButtons = styled.div``;

// CarouselButton

export const CarouselButton = styled(IconButton)`
  color: white;
`;
