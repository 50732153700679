import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  UserUpdatePasswordMutation,
  UserUpdatePasswordMutationVariables,
} from 'shared/graphql/__generated__';

import { USER_UPDATE_PASSWORD_MUTATION } from '../queries';

export const useUserPasswordUpdate = () => {
  const [userPasswordUpdate, { loading, data, ...rest }] = useMutation<
    UserUpdatePasswordMutation,
    UserUpdatePasswordMutationVariables
  >(USER_UPDATE_PASSWORD_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your password has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: There was an error updating your password, please try again.`,
    },
  });

  const onUserPasswordUpdate = React.useCallback(
    (
      email: string,
      previousPassword: string,
      proposedPassword: string,
      confirmProposedPassword: string,
    ) => {
      return userPasswordUpdate({
        variables: {
          data: { email, previousPassword, proposedPassword, confirmProposedPassword },
        },
      });
    },
    [userPasswordUpdate],
  );

  return { onUserPasswordUpdate, loading, data, ...rest };
};
