import { gql } from '@apollo/client';

export const DISCOUNT_LIST_ITEM_FRAGMENT = gql`
  fragment DiscountListItem on Discount {
    id
    title
    amountPercent
    startDate
    expirationDate
    timezone
    category
    status
    type
    createdAt
    terms
    usersRedeemedCount

    organization {
      id
      name
      logo {
        downloadUrl
      }
    }

    images {
      items {
        id
        downloadUrl
      }
    }
  }
`;

export const DISCOUNTS_LIST_QUERY = gql`
  query DiscountsList($first: Int, $skip: Int, $filter: DiscountFilter, $sort: [DiscountSort!]) {
    discounts: discountsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...DiscountListItem
      }
    }
  }

  ${DISCOUNT_LIST_ITEM_FRAGMENT}
`;

export const DISCOUNTS_COUNT_QUERY = gql`
  query DiscountsCount($filter: DiscountFilter, $skip: Int, $first: Int, $sort: [DiscountSort!]) {
    discounts: discountsList(filter: $filter, skip: $skip, first: $first, sort: $sort) {
      count
    }
  }
`;

export const INCREASE_DISCOUNT_VIEWS_COUNT = gql`
  mutation IncreaseDiscountViewsRequest($id: ID!) {
    discountUpdateByFilter(data: { totalViews: { add: 1 } }, filter: { id: { equals: $id } }) {
      count
    }
  }
`;

export const INCREASE_DISCOUNT_CLICKS_COUNT = gql`
  mutation IncreaseDiscountClicksRequest($id: ID!) {
    discountUpdateByFilter(data: { totalClicks: { add: 1 } }, filter: { id: { equals: $id } }) {
      count
    }
  }
`;

export const REDEEM_DISCOUNT_MUTATION = gql`
  mutation RedeemDiscount($id: ID!) {
    redeemDiscountRequest(id: $id) {
      success
    }
  }
`;
