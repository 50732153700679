import { Dispatch, SetStateAction } from 'react';

import { SelectMenu } from 'shared/components/ui';

import { ADVERTISING_TITLES } from '../consts';

type KeyValue = {
  label: string;
  value: string;
};

const mapObjectToOptions = <T extends Record<string, string>>(obj: T): KeyValue[] => {
  return Object.entries(obj).map(([value, label]) => ({
    value,
    label,
  }));
};

interface DashboardAdvertisingSelectProps {
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
}

export function DashboardAdvertisingSelect({
  selectedOption,
  setSelectedOption,
}: DashboardAdvertisingSelectProps) {
  const options = mapObjectToOptions(ADVERTISING_TITLES);

  return (
    <SelectMenu
      label={ADVERTISING_TITLES[selectedOption]}
      value={selectedOption}
      onChange={setSelectedOption}
      height="20px"
      options={options}
    />
  );
}
