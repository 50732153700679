import { PropsWithChildren } from 'react';

import { Container, Content, Header, SeeMoreButton, Title } from './LegacyTimelineSection.styles';

interface Props {
  title: string;

  onSeeMore(): void;
}

export function LegacyTimelineSection(props: PropsWithChildren<Props>) {
  return (
    <Container>
      <Header>
        <Title>{props.title}</Title>

        <SeeMoreButton onClick={props.onSeeMore}>View all posts</SeeMoreButton>
      </Header>

      <Content>{props.children}</Content>
    </Container>
  );
}
