import { gql } from '@apollo/client';

const CONTENT_REPORTS_LIST_ITEM_FRAGMENT = gql`
  fragment ContentReportsListItem on ContentReport {
    id
    authorId
    authorName
    text
    createdAt
    category
    isSupporter
  }
`;

/** @deprecated Use `CONTENT_ENGAGEMENT_REPORT_LIST_QUERY` instead. */
export const CONTENT_REPORTS_LIST_QUERY = gql`
  query ContentReportsList(
    $first: Int
    $skip: Int
    $sort: [ContentReportSort!]
    $filter: ContentReportFilter
  ) {
    contentReportsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...ContentReportsListItem
      }
    }
  }
  ${CONTENT_REPORTS_LIST_ITEM_FRAGMENT}
`;

export const CONTENT_ENGAGEMENT_REPORT_FRAGMENT = gql`
  fragment ContentEngagementReport on ContentEngagementReport {
    id
    text
    category
    legacyDate
    authorId
    authorName
    groupId
    likesCount
    commentsCount
    reactionsCount
    reportStatus
    flagReason
    lastFlaggedByName
    isFlagged
    isAutoReported
    isSupporter
    createdAt
    updatedAt
    flaggedAt
  }
`;

export const CONTENT_ENGAGEMENT_REPORT_QUERY = gql`
  query ContentEngagementReport(
    $filter: ContentReportFilter
    $orderBy: [ContentReportOrderBy]
    $sort: [ContentReportSort!]
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $withDeleted: Boolean
  ) {
    report: contentEngagementReportsList(
      filter: $filter
      orderBy: $orderBy
      sort: $sort
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      withDeleted: $withDeleted
    ) {
      count
      items {
        ...ContentEngagementReport
      }
    }
  }

  ${CONTENT_ENGAGEMENT_REPORT_FRAGMENT}
`;

export const REVENUE_REPORT_QUERY = gql`
  query RevenueReport(
    $filter: OrganizationFilter
    $debtFilter: DebtOperationHistoryFilter
    $skip: Int
    $first: Int
  ) {
    organizations: organizationsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        name

        sponsorRequests {
          count
        }

        debt {
          balance: debtOperationHistories(
            filter: $debtFilter
            groupBy: { query: { amount: { as: "revenue", fn: { aggregate: SUM } } } }
          ) {
            metrics: groups {
              revenue: Float
            }
          }
        }
      }
    }
  }
`;
