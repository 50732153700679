import { Button } from '@material-ui/core';
import { ModeComment } from '@material-ui/icons';

import { CommentEnrichedReaction } from 'shared/types';

interface Props {
  comments: CommentEnrichedReaction[];

  onClick(): void;
}

export function HomeFeedCardCommentButton(props: Props) {
  return (
    <Button
      color="primary"
      startIcon={<ModeComment />}
      size="small"
      variant="text"
      onClick={props.onClick}
    >
      {props.comments.length}
    </Button>
  );
}
