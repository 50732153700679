import React from 'react';
import { Box } from '@mui/material';

import { RouteLayout } from 'shared/components/layouts';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';

import { GroupDetailsContent } from '../components/GroupDetails';
import { GroupsPostsFilter } from '../components/GroupDetails/Posts/GroupsPostsFilter';
import { JoinedGroupList } from '../components/GroupDetails/JoinedGroupList';

export const GroupDetailsPage: React.FC = () => {
  return (
    <SearchProvider>
      <RouteLayout
        withMaxWidth
        content={<GroupDetailsContent />}
        sidebar={
          <Box display="flex" flexDirection="column" rowGap={3.125}>
            <GroupsPostsFilter />
            <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />
            <JoinedGroupList />
          </Box>
        }
      />
    </SearchProvider>
  );
};
