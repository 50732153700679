import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Typography } from '@material-ui/core';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
  overflow-x: hidden;
  user-select: none;
`;

// Divided

export const Divided = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// Logo

export const Logo = styled(Avatar)`
  --size: 4rem;

  width: var(--size);
  height: var(--size);
`;

Logo.defaultProps = {
  variant: 'rounded',
};

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 0.25rem;
  flex: 1;
`;

// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

// HeaderDescriptor

export const HeaderDescriptor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
`;

// HeaderOptions

export const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// Title

export const Title = styled(Typography)`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
  line-height: normal;
`;

// Count

export const Count = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;

// Joined

export const Joined = styled.span(p => {
  const background = p.theme.palette.primary.main;
  const color = p.theme.palette.getContrastText(background);

  return css`
    display: flex;
    align-items: center;
    background-color: ${background};
    border-radius: 0.25rem;
    padding: 0.3rem 0.6rem;
    color: ${color};
    line-height: 1;

    &::before {
      content: 'Joined';
      font-size: 0.7rem;
    }
  `;
});

// Description

export const Description = styled.span`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Buttons

export const Buttons = styled.div``;

// Group List Container

export const GroupListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
