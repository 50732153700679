import React from 'react';
import { Paper, styled } from '@mui/material';

import { Typography } from '../Typography';

const STAT_HEIGHT = 110;
const STAT_WIDTH = 180;

const StatContainer = styled('div')`
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StatElement = styled(Paper)`
  height: ${STAT_HEIGHT}px;
  min-width: ${STAT_WIDTH}px;
  flex: 1;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  :last-child {
    margin-right: 0px;
  }
`;

const StatNum = styled(Typography)<{ color?: 'primary' | 'secondary' | undefined }>`
  color: ${p => (p.color === 'secondary' ? '#EF5C5C' : '#295f7b')};
  font-size: 63px;
  letter-spacing: -0.53px;
  line-height: 76px;
  text-align: center;
`;

const StatLabel = styled(Typography)`
  text-align: center;
`;

export type SpreadsheetStatsProps = {
  data: Array<{
    value: string | number;
    label: string;
    color?: 'primary' | 'secondary';
    customTransform?: (value: string | number) => string;
  }>;
  transform?: (value: string | number) => string;
};

export const SpreadsheetStats: React.FC<SpreadsheetStatsProps> = ({ data, transform }) => {
  return (
    <StatContainer>
      {data.map(({ label, value, color, customTransform }) => {
        const transformedValue = customTransform?.(value) || transform?.(value) || value;
        return (
          <StatElement>
            <StatNum color={color}>{transformedValue}</StatNum>
            <StatLabel>{label}</StatLabel>
          </StatElement>
        );
      })}
    </StatContainer>
  );
};
