import React from 'react';
import { debounce } from 'lodash';

import { TextField, TextFieldProps } from 'shared/components/ui';

import { useSearchContext } from '../../providers';

export type ZipFilterFieldProps = Omit<TextFieldProps, 'value' | 'onChange'>;

export const ZipFilterField: React.FC<ZipFilterFieldProps> = props => {
  const { zipQuery, setZipQuery, setIsUserTyping } = useSearchContext();
  const [localSearchQuery, setLocalSearchQuery] = React.useState(zipQuery);

  const fetchSearchResults = React.useCallback(
    (searchText: string) => {
      setIsUserTyping(false);

      setZipQuery(searchText);
    },
    [setIsUserTyping, setZipQuery],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSearchResults = React.useCallback(
    debounce(
      (searchText: string) => {
        fetchSearchResults(searchText);
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  );

  const onSearchQueryChange = React.useCallback(
    (newSearchQuery, disableRequestDebounce = false) => {
      setIsUserTyping(!!newSearchQuery);
      setLocalSearchQuery(newSearchQuery);

      const skipDebounce = disableRequestDebounce || !newSearchQuery.trim();

      if (skipDebounce) {
        debouncedFetchSearchResults.cancel();
        fetchSearchResults(newSearchQuery);
        return;
      }

      debouncedFetchSearchResults(newSearchQuery);
    },
    [debouncedFetchSearchResults, fetchSearchResults, setIsUserTyping],
  );

  const onChange = React.useCallback(
    event => {
      const newSearchQuery = event.target.value;
      onSearchQueryChange(newSearchQuery);
    },
    [onSearchQueryChange],
  );

  React.useEffect(() => {
    setLocalSearchQuery(zipQuery);
  }, [zipQuery]);

  return (
    <TextField
      type="search"
      variant="outlined"
      {...props}
      value={localSearchQuery}
      onChange={onChange}
    />
  );
};
