import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { SettingsSidebarList } from './SettingsSidebarList';
import { SettingsSidebarProfile } from './SettingsSidebarProfile';

const containerCss = (theme: any) => css`
  border-right: 1px solid ${theme.palette.border};
`;

export const SettingsSidebar = () => {
  return (
    <Box css={containerCss}>
      <SettingsSidebarProfile />
      <SettingsSidebarList />
    </Box>
  );
};
