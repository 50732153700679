import { useCallback, useMemo, useState } from 'react';
import { uniqBy } from 'ramda';

import { EVENT_STATUSES, SORT } from '@jebel/constants';

import {
  EventSearchQuery,
  EventSearchQueryVariables,
  EventSort,
  useEventSearchQuery,
} from 'shared/graphql';
import { PAGE_SIZE } from 'shared/constants';
import { isValidZipCode } from 'shared/utils/form';
import { createEventFilter } from 'features/search/utils';
import { recordError } from 'shared/utils/record';

interface Options {
  searchQuery: string | undefined;
  sortInfo: EventSort & { proximity?: typeof SORT['desc'] | undefined | null };
  zipFilter?: { zip?: string; distance?: string };
  status?: keyof typeof EVENT_STATUSES;
}

export function useEventSearch(options: Options) {
  const [refreshing, setRefreshing] = useState(false);

  const startPointZip = options.zipFilter?.zip ?? '';
  const radius = options.zipFilter?.distance?.split(' ')[0] ?? '';

  const variables: EventSearchQueryVariables = useMemo(() => {
    let proximitySort: typeof SORT['desc'] | undefined | null = null;
    const startZip = isZipCorrect(startPointZip);

    if (startZip && options.sortInfo.proximity) {
      proximitySort = options.sortInfo.proximity;
    } else {
      proximitySort = null;
    }

    const filter = createEventFilter({
      search: options.searchQuery,
      status: options.status,
    });

    return {
      first: PAGE_SIZE,
      sort: options.sortInfo.proximity ? { date: SORT.desc } : options.sortInfo,
      startPointZip: startZip,
      radius,
      proximitySort,
      filter,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const { data: response, loading, fetchMore: next } = useEventSearchQuery({ variables });

  const data = response?.events.items ?? [];
  const count = response?.events.count ?? 0;

  const fetchMore = useCallback(async () => {
    setRefreshing(true);

    try {
      await next({
        variables: {
          skip: data.length,
        },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const oldest = prev?.events.items ?? [];
          const newest = curr?.events?.items ?? [];

          const count = curr?.events.count ?? 0;
          /** Merge between `oldest` items and `newest` reduced by ID. */
          const items = uniqBy(user => user.id, [...oldest, ...newest]);

          const data: EventSearchQuery = {
            ...curr,

            events: {
              ...prev.events,

              count,
              items,
            },
          };

          return data;
        },
      });
    } catch (err) {
      recordError(err);
    }

    setRefreshing(false);
  }, [data.length, next]);

  return {
    data,
    count,
    loading,
    refreshing,
    hasMore: count > data.length,

    fetchMore,
  };
}

const isZipCorrect = (zip: number | string) => {
  const isCorrectZip = !isValidZipCode(zip);
  return isCorrectZip ? zip.toString() : '';
};
