import { css, Theme } from '@emotion/react';
import { List, ListItemText } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { ListItemIconSymbol, NavListItemSymbol } from 'shared/components/symbols';
import { ROUTES } from 'shared/constants';

const LIST = [
  {
    title: 'Site Settings',
    icon: 'Settings',
    route: ROUTES.admin.settings.siteSettings,
  },
  {
    title: 'Main Navigation',
    icon: 'Dehaze',
    route: ROUTES.admin.settings.navigation,
  },
  {
    title: 'Top Bar News Ticker',
    icon: 'Feed',
    route: ROUTES.admin.settings.news,
  },
  {
    title: 'Site Admins',
    icon: 'AccountCircle',
    route: ROUTES.admin.settings.siteAdmins,
  },
  {
    title: 'Customization',
    icon: 'Palette',
    route: ROUTES.admin.settings.customization,
  },
  {
    title: 'Roles and Permissions',
    icon: 'SupervisorAccount',
    route: ROUTES.admin.settings.roles,
  },
  {
    title: 'Content Auto-Moderation',
    icon: 'Flag',
    route: ROUTES.admin.settings.contentModeration,
  },
];

const listItemContainerCss = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const listItemIconCss = (theme: Theme) => css`
  margin-right: ${theme.spacing(1.25)}px;
  fill: ${theme.palette.text.secondary};
`;

const containerCss = (theme: Theme) => css`
  border-bottom: solid 1px;
  border-bottom-color: ${theme.palette.divider};
`;

export function SettingsSidebarList() {
  const location = useLocation();

  return (
    <List css={containerCss}>
      {LIST.map(item => (
        <NavListItemSymbol
          css={listItemContainerCss}
          key={item.route}
          path={item.route}
          selected={location.pathname === item.route}
          button
          disableGutters
          startIcon={
            <ListItemIconSymbol
              css={listItemIconCss}
              name={item.icon}
              variant="filled"
              color="inherit"
              fontSize="default"
            />
          }
          listItemText={<ListItemText disableTypography={false}>{item.title}</ListItemText>}
        />
      ))}
    </List>
  );
}
