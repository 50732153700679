import React, { ChangeEvent } from 'react';
import * as R from 'ramda';

import { usePostsList } from 'shared/features/posts/hooks';
import { useSearchContext, SearchContextValue } from 'shared/features/search';
import {
  CollectiveIntelligenceThreadsListQuery,
  CollectiveIntelligenceThreadsListQueryVariables,
  CollectiveIntelligenceThreadsCountQuery,
  CollectiveIntelligenceThreadFilter,
} from 'shared/graphql/__generated__';
import { SELECT_OPTION_ALL } from 'shared/constants';

import {
  COLLECTIVE_INTELLIGENCE_THREADS_LIST_QUERY,
  COLLECTIVE_INTELLIGENCE_THREADS_COUNT_QUERY,
} from '../queries';
import { createFilterBuilder } from '@jebel/utils';

const PER_PAGE_SIZE = 5;

const getCreatorFilter = (searchText: string) => {
  return searchText.split(' ').reduce<{ OR: Array<any> }>(
    (previousValue, currentValue) => {
      previousValue.OR.push(
        {
          firstName: {
            contains: currentValue,
          },
        },
        {
          lastName: {
            contains: currentValue,
          },
        },
      );
      return previousValue;
    },
    { OR: [] },
  );
};

const transformQueryData = (
  listsData: CollectiveIntelligenceThreadsListQuery | undefined,
  countData: CollectiveIntelligenceThreadsCountQuery | undefined,
) => {
  const posts = listsData?.collectiveIntelligenceThreadsList.items || [];
  const count = countData?.collectiveIntelligenceThreadsList.count || 0;
  const hasMore = posts.length < count;

  return { items: R.uniqBy(item => item.id, posts), count, hasMore };
};

type ThreadVariables = Pick<SearchContextValue, 'searchQuery' | 'sortOption'>;

export const getThreadVariables = (
  { searchQuery, sortOption }: ThreadVariables,
  page = 1,
  filterParam: CollectiveIntelligenceThreadFilter | undefined = undefined,
): CollectiveIntelligenceThreadsListQueryVariables => {
  const filter = createFilterBuilder<CollectiveIntelligenceThreadFilter>();

  if (typeof filterParam === 'object') {
    filter.and(filterParam);
  }

  if (searchQuery) {
    const searchFilter = createFilterBuilder<CollectiveIntelligenceThreadFilter>();

    searchFilter.or({ title: { contains: searchQuery } });
    searchFilter.or({ text: { contains: searchQuery } });
    searchFilter.or({ creator: getCreatorFilter(searchQuery) });

    filter.and(searchFilter);
  }

  return {
    first: PER_PAGE_SIZE,
    skip: page && (page - 1) * PER_PAGE_SIZE,
    sort: sortOption,
    filter: filter.build(),
  };
};

const FIELD_NAME = 'collectiveIntelligenceThreadsList';

export const useThreadsQuery = () => {
  const [page, setPage] = React.useState(1); // first page by default
  const { searchQuery, sortOption, filter } = useSearchContext();

  const { listsData, countData, loading, ...rest } = usePostsList<
    CollectiveIntelligenceThreadsListQuery,
    CollectiveIntelligenceThreadsCountQuery
  >({
    itemsFieldName: FIELD_NAME,
    listQuery: COLLECTIVE_INTELLIGENCE_THREADS_LIST_QUERY,
    countQuery: COLLECTIVE_INTELLIGENCE_THREADS_COUNT_QUERY,
    variables: getThreadVariables({ searchQuery, sortOption }, page, filter),
  });

  const { items, hasMore, count } = React.useMemo(
    () => transformQueryData(listsData, countData),
    [countData, listsData],
  );

  const { pageCount } = React.useMemo(
    () => ({ pageCount: Math.ceil(count / PER_PAGE_SIZE) }),
    [count],
  );

  const onChangePage = React.useCallback((event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  }, []);

  return {
    items,
    hasMore,
    loading,
    onChangePage,
    pageCount,
    page,
    ...rest,
  };
};
