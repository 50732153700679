import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  UserUpdateEmailMutation,
  UserUpdateEmailMutationVariables,
} from 'shared/graphql/__generated__';

import { USER_UPDATE_EMAIL_MUTATION } from '../queries';

export const useUserEmailUpdate = () => {
  const [userEmailUpdate, { loading, data, ...rest }] = useMutation<
    UserUpdateEmailMutation,
    UserUpdateEmailMutationVariables
  >(USER_UPDATE_EMAIL_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your email has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: There was an error updating your email, please try again.`,
    },
  });

  const onUserEmailUpdate = React.useCallback(
    (email: string, proposedEmail: string, password: string) => {
      return userEmailUpdate({
        variables: {
          data: {
            password,
            email,
            proposedEmail,
          },
        },
      });
    },
    [userEmailUpdate],
  );

  return { onUserEmailUpdate, loading, data, ...rest };
};
