import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from './file';
import { MINIMAL_USER_FRAGMENT } from './users';

export const POST_CHECK_QUERY = gql`
  query PostCheck(
    $text: String
    $media: [FileKeyFilter!]
    $blacklistId: String!
    $postId: String
    $postType: String
  ) {
    postModerateRequest(
      text: $text
      media: $media
      blacklistId: $blacklistId
      postId: $postId
      postType: $postType
    ) {
      verified
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($postId: ID!, $postData: JSON!, $postType: String!) {
    updatePost(postData: $postData, postId: $postId, postType: $postType) {
      successful
      data
    }
  }
`;

export const MINIMAL_LEGACY_FEED_POST_FRAGMENT = gql`
  fragment MinimalLegacyFeedPost on LegacyFeedPost {
    id
    text
    createdAt
    legacyDate
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MINIMAL_USER_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export const MINIMAL_GROUP_POST_FRAGMENT = gql`
  fragment MinimalGroupPost on GroupPost {
    id
    text
    createdAt
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MINIMAL_USER_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export const MINIMAL_HOME_FEED_POST_FRAGMENT = gql`
  fragment MinimalHomeFeedPost on HomeFeedPost {
    id
    text
    createdAt
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MINIMAL_USER_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export const MINIMAL_SCHOOL_POST_FRAGMENT = gql`
  fragment MinimalSchoolNewsPost on SchoolNewsPost {
    id
    text
    createdAt
    postDate
    author {
      ...MinimalUser
    }
    createdBy {
      ...MinimalUser
    }
    media {
      ...Media
    }
  }
  ${MINIMAL_USER_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export const FIND_POST_QUERY = gql`
  query FindPost($id: ID!) {
    homeFeedPost(id: $id) {
      ...MinimalHomeFeedPost
    }
    schoolNewsPost(id: $id) {
      ...MinimalSchoolNewsPost
    }
    legacyFeedPost(id: $id) {
      ...MinimalLegacyFeedPost
    }
    groupPost(id: $id) {
      ...MinimalGroupPost
    }
  }
  ${MINIMAL_HOME_FEED_POST_FRAGMENT}
  ${MINIMAL_GROUP_POST_FRAGMENT}
  ${MINIMAL_LEGACY_FEED_POST_FRAGMENT}
  ${MINIMAL_SCHOOL_POST_FRAGMENT}
`;
