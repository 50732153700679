import { USER_AFFILIATIONS, USER_CLUBS, USER_GENDERS, USER_HOBBIES } from '@jebel/constants';
import { formatSQLDate } from '@jebel/utils';

const GRADUATING_YEAR_START_YEAR = 1959;
const GRADUATING_YEAR_END_YEAR = 2099;

const generateGraduatingYearFormConfig = () => {
  const config: { label: string; value: string }[] = [];

  for (let year = GRADUATING_YEAR_START_YEAR; year <= GRADUATING_YEAR_END_YEAR; year++) {
    config.push({ label: `Class of ${year}`, value: formatSQLDate(new Date(year, 0, 1)) });
  }

  return config;
};

export const FIELDS_CONFIG_USER = {
  gender: [
    { value: USER_GENDERS.male, label: 'Male' },
    { value: USER_GENDERS.female, label: 'Female' },
  ],
  affiliation: [
    { value: USER_AFFILIATIONS.alumni, label: 'Alumni' },
    { value: USER_AFFILIATIONS.parent, label: 'Parent' },
    { value: USER_AFFILIATIONS.student, label: 'Student' },
    { value: USER_AFFILIATIONS.staff, label: 'Staff' },
    { value: USER_AFFILIATIONS.other, label: 'Other' },
  ],
  graduatingYear: generateGraduatingYearFormConfig(),
};

export const LAST_ACTIVE_DATE_FIELD_LOCAL_STORAGE = 'LAST_ACTIVE_DATE';

export const USER_ACTIVITIES_OPTIONS = {
  hobbies: [
    { value: USER_HOBBIES.beach, label: 'Beach' },
    { value: USER_HOBBIES.bicycling, label: 'Byciling' },
    { value: USER_HOBBIES.billiards, label: 'Billiards' },
    { value: USER_HOBBIES.boating, label: 'Boating' },
    { value: USER_HOBBIES.bowling, label: 'Bowling' },
    { value: USER_HOBBIES.camping, label: 'Camping' },
    { value: USER_HOBBIES.churchActivities, label: 'Church Activities' },
    { value: USER_HOBBIES.computer, label: 'Computer' },
    { value: USER_HOBBIES.cooking, label: 'Cooking' },
    { value: USER_HOBBIES.crafts, label: 'Crafts' },
    { value: USER_HOBBIES.dancing, label: 'Dancing' },
    { value: USER_HOBBIES.dating, label: 'Dating' },
    { value: USER_HOBBIES.eatingOut, label: 'Eating Out' },
    { value: USER_HOBBIES.entertaining, label: 'Entertaining' },
    { value: USER_HOBBIES.exercise, label: 'Exercise' },
    { value: USER_HOBBIES.familyTime, label: 'Family Time' },
    { value: USER_HOBBIES.fishing, label: 'Fishing' },
    { value: USER_HOBBIES.gardening, label: 'Gardening' },
    { value: USER_HOBBIES.goingToMovies, label: 'Going to Movies' },
    { value: USER_HOBBIES.golf, label: 'Golf' },
    { value: USER_HOBBIES.hiking, label: 'Hiking' },
    { value: USER_HOBBIES.horsebackRiding, label: 'Horseback Riding' },
    { value: USER_HOBBIES.housework, label: 'Housework' },
    { value: USER_HOBBIES.hunting, label: 'Hunting' },
    { value: USER_HOBBIES.listeningToMusic, label: 'Listening to Music' },
    { value: USER_HOBBIES.motorcycling, label: 'Motorcycling' },
    { value: USER_HOBBIES.painting, label: 'Painting' },
    { value: USER_HOBBIES.pets, label: 'Pets' },
    { value: USER_HOBBIES.playingCards, label: 'Playing Cards' },
    { value: USER_HOBBIES.playingMusic, label: 'Playing Music' },
    { value: USER_HOBBIES.reading, label: 'Reading' },
    { value: USER_HOBBIES.relaxing, label: 'Relaxing' },
    { value: USER_HOBBIES.rentingMovies, label: 'Renting Movies' },
    { value: USER_HOBBIES.running, label: 'Running' },
    { value: USER_HOBBIES.sewing, label: 'Sewing' },
    { value: USER_HOBBIES.skiing, label: 'Skiing' },
    { value: USER_HOBBIES.sleeping, label: 'Sleeping' },
    { value: USER_HOBBIES.socializing, label: 'Socializing' },
    { value: USER_HOBBIES.swimming, label: 'Swimming' },
    { value: USER_HOBBIES.teamSports, label: 'Team Sports' },
    { value: USER_HOBBIES.tennis, label: 'Tennis' },
    { value: USER_HOBBIES.theater, label: 'Theater' },
    { value: USER_HOBBIES.traveling, label: 'Traveling' },
    { value: USER_HOBBIES.volunteerWork, label: 'Volunteer Work' },
    { value: USER_HOBBIES.walking, label: 'Walking' },
    { value: USER_HOBBIES.watchingSports, label: 'Watching Sports' },
    { value: USER_HOBBIES.watchingTv, label: 'Watching TV' },
    { value: USER_HOBBIES.workingOnCars, label: 'Working on Cars' },
    { value: USER_HOBBIES.writing, label: 'Writing' },
  ],
  clubs: [
    { value: USER_CLUBS.printingClub, label: '3D Printing Club' },
    { value: USER_CLUBS.airsoftClub, label: 'Airsoft Club' },
    { value: USER_CLUBS.anglersClub, label: 'Anglers Club' },
    { value: USER_CLUBS.artHistory, label: 'Art History' },
    { value: USER_CLUBS.artPhotographyClub, label: 'Art/Photography Club' },
    { value: USER_CLUBS.beTheLight, label: 'Be the Light' },
    { value: USER_CLUBS.bestBuddies, label: 'Best Buddies' },
    { value: USER_CLUBS.blackStudentOrganization, label: 'Black Student Organization' },
    { value: USER_CLUBS.businessClub, label: 'Business Club' },
    { value: USER_CLUBS.cangrejos, label: 'Cangrejos' },
    { value: USER_CLUBS.carClub, label: 'Car Club' },
    { value: USER_CLUBS.cCNNLive, label: 'CCNN Live' },
    { value: USER_CLUBS.chess, label: 'Chess' },
    { value: USER_CLUBS.clubPurpleEpilepsyAwareness, label: 'Club Purple - Epilepsy Awareness' },
    { value: USER_CLUBS.codingClub, label: 'Coding Club' },
    { value: USER_CLUBS.columbusFilms, label: 'Columbus Films' },
    { value: USER_CLUBS.comicBookClub, label: 'Comic Book Club' },
    { value: USER_CLUBS.cookingClub, label: 'Cooking Club' },
    { value: USER_CLUBS.cybersecurity, label: 'Cybersecurity' },
    { value: USER_CLUBS.debate, label: 'Debate' },
    { value: USER_CLUBS.dJsforACause, label: 'DJs for a Cause' },
    { value: USER_CLUBS.dramaClub, label: 'Drama Club' },
    { value: USER_CLUBS.environmentalClub, label: 'Environmental Club' },
    { value: USER_CLUBS.fellowshipOfChristianAthletes, label: 'Fellowship of Christian Athletes' },
    { value: USER_CLUBS.frenchClub, label: 'French Club' },
    {
      value: USER_CLUBS.futureBusinessLeadersOfAmerica,
      label: 'Future Business Leaders of America',
    },
    { value: USER_CLUBS.geographyClub, label: 'Geography Club' },
    { value: USER_CLUBS.gymRatClub, label: 'Gyb Rat Club' },
    { value: USER_CLUBS.hideAndSeekClub, label: 'Hide and Seek Club' },
    { value: USER_CLUBS.historyClub, label: 'History Club' },
    {
      value: USER_CLUBS.internationalThespiansHonorSociety,
      label: 'International Thespians Honor Society',
    },
    { value: USER_CLUBS.intramurals, label: 'Intramurals' },
    { value: USER_CLUBS.investmentClub, label: 'Investment Club' },
    { value: USER_CLUBS.keyClub, label: 'Key Club' },
    { value: USER_CLUBS.masScholar, label: 'Mas Scholar' },
    { value: USER_CLUBS.mathHonorSociety, label: 'Math Honor Society (Mu Alpha Theta)' },
    { value: USER_CLUBS.mindfulnessClub, label: 'Mindfulness Club' },
    { value: USER_CLUBS.mockTrial, label: 'Mock Trial' },
    { value: USER_CLUBS.modelUN, label: 'Model UN' },
    { value: USER_CLUBS.origamiEngineeringClub, label: 'Origami Engineering Club' },
    { value: USER_CLUBS.paintballClub, label: 'Paintball Club' },
    { value: USER_CLUBS.patriotClub, label: 'Patriot Club' },
    { value: USER_CLUBS.preLawClub, label: 'Pre-law Club' },
    { value: USER_CLUBS.preMedClub, label: 'PreMed Club' },
    { value: USER_CLUBS.projectLastDrop, label: 'Project Last Drop (affilated with JDRF)' },
    { value: USER_CLUBS.respectLifeClub, label: 'Respect Life Club' },
    { value: USER_CLUBS.rhoKappaNationalHonorSociety, label: 'Rho Kappa National Honor Society' },
    { value: USER_CLUBS.roboticsClub, label: 'Robotics Club' },
    { value: USER_CLUBS.rollD20, label: 'Roll D20 (Dungeons & Dragons)' },
    { value: USER_CLUBS.sadd, label: 'SADD (Students Agains Destructive Decisions)' },
    { value: USER_CLUBS.scubaDivingClub, label: 'Scuba Diving Club' },
    { value: USER_CLUBS.spanishClub, label: 'Spanish Club' },
    { value: USER_CLUBS.spearfishingClub, label: 'Spearfishing Club' },
    { value: USER_CLUBS.squiresClub, label: 'Squires Club' },
    { value: USER_CLUBS.standForTheSilent, label: 'Stand for the Silent' },
    { value: USER_CLUBS.starWarsClub, label: 'Star Wars Club' },
    { value: USER_CLUBS.sTEMClub, label: 'STEM Club' },
    { value: USER_CLUBS.studentActivitiesCommittee, label: 'Student Activities Committee (SAC)' },
    { value: USER_CLUBS.theFlyingClub, label: 'The Flying Club' },
    { value: USER_CLUBS.theLog, label: 'The Log' },
    { value: USER_CLUBS.theMindfulnessClub, label: 'The Mindfulness Club' },
    { value: USER_CLUBS.archery, label: 'Archery' },
    { value: USER_CLUBS.badminton, label: 'Badminton' },
    { value: USER_CLUBS.baseball, label: 'Baseball' },
    { value: USER_CLUBS.basketball, label: 'Basketball' },
    { value: USER_CLUBS.bowling, label: 'Bowling' },
    { value: USER_CLUBS.competativeSpirit, label: 'Competative Spirit' },
    { value: USER_CLUBS.crossCountry, label: 'Cross Country' },
    { value: USER_CLUBS.dance, label: 'Dance/Drill' },
    { value: USER_CLUBS.fieldHockey, label: 'Field Hockey' },
    { value: USER_CLUBS.flagFootball, label: 'Flag Football' },
    { value: USER_CLUBS.football, label: 'Football' },
    { value: USER_CLUBS.golf, label: 'Golf' },
    { value: USER_CLUBS.gymnastics, label: 'Gymnastics' },
    { value: USER_CLUBS.iceHockey, label: 'Ice Hockey' },
    { value: USER_CLUBS.lacrosse, label: 'Lacrosse' },
    { value: USER_CLUBS.soccer, label: 'Soccer' },
    { value: USER_CLUBS.softball, label: 'Softball (Fast Pitch)' },
    { value: USER_CLUBS.swimmingNDiving, label: 'Swimming & Diving' },
    { value: USER_CLUBS.tennis, label: 'Tennis' },
    { value: USER_CLUBS.trackNField, label: 'Track & Field' },
    { value: USER_CLUBS.volleyball, label: 'Volleyball' },
    { value: USER_CLUBS.waterPolo, label: 'Water Polo' },
    { value: USER_CLUBS.weightlifting, label: 'Weightlifting' },
    { value: USER_CLUBS.wrestling, label: 'Wrestling' },
  ],
};
