import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Typography, Box } from '@mui/material';
import { Button } from '@material-ui/core';

import { US_TIMEZONES } from '@jebel/constants';

import { getFullMonthAndWeekdayAndTime } from 'shared/utils/date';
import { glueStrings } from 'shared/utils/strings';
import { EventInfoFragment } from 'shared/graphql';

const DEFAULT_TIMEZONE = 'CST';

const eventDateLocationBoxCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;

const dateTimeCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${theme.palette.primary.dark};
`;

const eventDateTime = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  font-size: ${theme.typography.fontSize - 2}px;
  font-weight: 500;
`;

const locationCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${theme.palette.primary.dark};
`;

const eventLocation = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
`;

export const EventInfoModalDateLocation = ({ event }: { event: EventInfoFragment }) => {
  const fullLocation = useMemo(() => {
    return glueStrings(
      ', ',
      event?.location?.street1,
      event?.location?.city,
      event?.location?.state,
      event?.location?.zip,
    );
  }, [event?.location]);

  const openLink = () => {
    if (!event?.link) {
      return;
    }

    window.open(event.link, '_blank');
  };

  return (
    <Box css={eventDateLocationBoxCSS}>
      <Box display="grid">
        <Typography
          css={dateTimeCss}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          Date/time
        </Typography>
        <Typography
          css={eventDateTime}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {getFullMonthAndWeekdayAndTime(
            event?.date,
            US_TIMEZONES.find(item => item.abbreviation === event?.timezone)?.abbreviation ||
              DEFAULT_TIMEZONE,
          )}
        </Typography>
      </Box>

      <Box display="grid">
        <Typography
          css={locationCSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          Location
        </Typography>

        <Typography
          css={eventLocation}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {fullLocation}
        </Typography>
      </Box>

      {event?.link && (
        <Button color="primary" variant="contained" fullWidth disableElevation onClick={openLink}>
          Event Details
        </Button>
      )}
    </Box>
  );
};
