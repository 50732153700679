import { Radio } from '@material-ui/core';

import {
  Container,
  Content,
  Description,
  Title,
} from './SettingsSchoolContentModerationThresholdPreset.styles';

interface Props {
  title: string;
  description: string;

  checked: boolean;

  onCheck(): void;
}

export function SettingsSchoolContentModerationThresholdPreset(props: Props) {
  return (
    <Container onClick={props.onCheck}>
      <Radio checked={props.checked} />

      <Content>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </Content>
    </Container>
  );
}
