import { gql } from '@apollo/client';

export const GET_USER_JOINED_GROUPS = gql`
  query GET_USER_JOINED_GROUPS($ID: ID!) {
    user(id: $ID) {
      joinedGroups {
        items {
          id
        }
      }
    }
  }
`;

export const GROUP_MEMBER_REQUEST_UPSERT_MUTATION = gql`
  mutation GroupMemberRequestUpsert($groupId: ID!, $userId: ID!, $status: String!) {
    transaction: groupMembersRequestUpsert(groupId: $groupId, userId: $userId, status: $status) {
      successful
      message
    }
  }
`;

export const GROUP_MEMBER_REQUESTS_QUERY = gql`
  query GroupMemberRequests($userId: ID!, $groupId: ID!) {
    requests: groupsMembersRequestsList(
      filter: { user: { id: { equals: $userId } }, group: { id: { equals: $groupId } } }
    ) {
      items {
        id
      }
      count
    }
  }
`;
