import React from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

interface DashboardChartTemplateTopProps {
  title: string;
  date: DateTime;
}

export const DashboardChartTemplateTop: React.FC<DashboardChartTemplateTopProps> = ({
  title,
  date,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h6">{title}</Typography>

      <Typography variant="body1" color="textSecondary">
        Date as of {date.setLocale('en-US').toFormat('DDD')}
      </Typography>
    </Box>
  );
};
