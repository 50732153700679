import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';

import { Icon } from 'shared/components/ui';

const paperCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AVATAR_SIZE = 250;

const iconCss = css`
  fill: #bdbdbd;
  width: ${AVATAR_SIZE - 50}px;
  height: ${AVATAR_SIZE - 50}px;
`;

const avatarCss = css`
  background-color: #f0f0f0;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const textCss = css`
  font-weight: 500;
`;

export const ReportsPlug = () => {
  return (
    <Paper css={paperCss}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Avatar css={avatarCss} variant="circular">
          <Icon name="Assessment" css={iconCss} size="inherit" />
        </Avatar>
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" css={textCss}>
            Run a report using the options on the left &nbsp;
          </Typography>
          <Typography variant="body1" css={textCss}>
            to see the results here.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
