import { Avatar, Box, Button, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import { useState } from 'react';
import { Theme, css } from '@emotion/react';
import { School } from '@material-ui/icons';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { useExplorerSupporters } from 'features/explorer/hooks';
import { useSchoolConfiguration } from 'shared/hooks';
import { AsyncContent } from 'shared/components/ui';

import { ExplorerSupportersList } from './ExplorerSupportersList';

const SUPPORTERS_RESULT_IMAGE_SIZE = 100;

export function ExplorerSupporters() {
  const [viewAll, setViewAll] = useState(false);

  const { configuration: school } = useSchoolConfiguration();
  const { data: supporters, count: supportersCount, loading } = useExplorerSupporters();

  const schoolAvatarSource = school?.images?.logo?.downloadUrl ?? undefined;

  return (
    <Box css={topSupportersResultBoxCSS}>
      <Box css={supportersResultBoxCSS}>
        <Box css={supporterResultBoxCSS}>
          <Avatar
            css={supportersResultImageCSS}
            src={schoolAvatarSource}
            srcSet={processFilestackUrlSrcSet(schoolAvatarSource, {
              compress: true,
              resize: {
                width: SUPPORTERS_RESULT_IMAGE_SIZE,
                height: SUPPORTERS_RESULT_IMAGE_SIZE,
                fit: 'crop',
              },
            })}
          >
            <School fontSize="inherit" />
          </Avatar>

          <Typography color="primary">
            {pluralize('Supporter', supportersCount)} ({supportersCount})
          </Typography>
        </Box>

        <Box css={viewAllBoxCSS}>
          {!viewAll && (
            <Button size="small" color="secondary" onClick={() => setViewAll(true)}>
              View all
            </Button>
          )}
        </Box>
      </Box>

      <AsyncContent loading={loading}>
        <Box display="flex" flexDirection="column" gridGap="1rem" padding="1rem">
          {supporters.length ? (
            <ExplorerSupportersList supportersList={supporters} viewAll={viewAll} />
          ) : (
            <Typography css={noSupportersFoundCSS}>No supporter organizations found</Typography>
          )}
        </Box>
      </AsyncContent>
    </Box>
  );
}

const topSupportersResultBoxCSS = css`
  width: 100%;
`;

const supportersResultBoxCSS = css`
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding: 15px;
`;

const supporterResultBoxCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
`;

const SUPPORTER_IMAGE_SIZE = 25;

const supportersResultImageCSS = css`
  width: ${SUPPORTER_IMAGE_SIZE}px;
  height: ${SUPPORTER_IMAGE_SIZE}px;
  font-size: ${SUPPORTER_IMAGE_SIZE * 0.6}px;
`;

const viewAllBoxCSS = css`
  display: flex;
  align-items: center;
`;

const noSupportersFoundCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 400;
  color: ${theme.palette.primary.dark};
  text-align: center;
  margin-bottom: 15px;
`;
