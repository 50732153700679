import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Typography, IconButton, Box, Link } from '@material-ui/core';
import { descend, groupBy, pipe, sortWith } from 'ramda';
import { AddCircle } from '@material-ui/icons';

import { formatMilliseconds } from '@jebel/utils';

import { ExperienceCard } from 'shared/components/ui';
import { Maybe, ProfessionalProfileFragment } from 'shared/graphql';
import { useModalState } from 'shared/hooks';

import { ExperienceModal } from './ExperienceModal';
import { ExperienceDeleteModal } from './ExperienceDeleteModal';

const topLayoutCSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;

const referenceIconsBoxCSS = (theme: any) => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
`;

interface Props {
  data?: Maybe<ProfessionalProfileFragment[]>;
  /** @default false */
  withAdd?: boolean;
  /** @default false */
  withEdit?: boolean;
  /** @default false */
  withRemove?: boolean;
}

const groupByCompanyName = groupBy<ProfessionalProfileFragment>(
  experience => experience.companyName as string,
);

const sortByStartDate = sortWith<ProfessionalProfileFragment>([
  // https://github.com/8base-services/jebel/issues/1425
  descend<ProfessionalProfileFragment>(experience => formatMilliseconds(experience.startDate)),
]);

const transformProfessionalProfile = pipe(sortByStartDate, groupByCompanyName);

export function SettingsPublicProfileSectionsExperience(props: Props) {
  const withAdd = props.withAdd ?? false;
  const withEdit = props.withEdit ?? false;
  const withRemove = props.withRemove ?? false;

  const { show: showCreateModal, open: openCreateModal, close: closeCreateModal } = useModalState();

  const {
    show: showEditModal,
    params: selectedEdit,
    open: openEditModal,
    close: closeEditModal,
  } = useModalState<ProfessionalProfileFragment>();

  const {
    show: showRemoveModal,
    params: selectedRemove,
    open: openRemoveModal,
    close: closeRemoveModal,
  } = useModalState<ProfessionalProfileFragment>();

  const profile = useMemo(() => {
    const profile = props.data ?? [];
    const groups = transformProfessionalProfile(profile);

    return Object.values(groups);
  }, [props.data]);

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <ExperienceModal isOpen={showCreateModal} mode="create" onClose={closeCreateModal} />

      <ExperienceModal
        mode="edit"
        data={selectedEdit}
        isOpen={showEditModal}
        onClose={closeEditModal}
      />

      {typeof selectedRemove?.id === 'string' && (
        <ExperienceDeleteModal
          id={selectedRemove.id}
          isOpen={showRemoveModal}
          onClose={closeRemoveModal}
        />
      )}

      <Box css={topLayoutCSS}>
        <Typography variant="h6">Experience</Typography>

        {withAdd && (
          <Box css={referenceIconsBoxCSS}>
            <IconButton color="primary" onClick={openCreateModal}>
              <AddCircle />
            </IconButton>
          </Box>
        )}
      </Box>

      {profile.length === 0 && (
        <Typography color="textSecondary">
          <span>No experience has been added yet.&nbsp;</span>

          {withAdd && (
            <Link color="secondary" onClick={openCreateModal}>
              Create a new one.
            </Link>
          )}
        </Typography>
      )}

      {profile.map(resume => {
        const [first, ...timeline] = resume;

        return (
          <ExperienceCard
            key={first.id}
            allowEdit={withEdit}
            allowRemove={withRemove}
            data={first}
            timeline={timeline}
            onEdit={openEditModal}
            onRemove={openRemoveModal}
          />
        );
      })}
    </Box>
  );
}
