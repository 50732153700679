import { ListItemText, MenuItem } from '@material-ui/core';

import { HASHTAG_MARKUP } from '@jebel/constants';

import {
  MentionDataSource,
  MentionFetcher,
  MentionSuggestionRenderer,
} from 'shared/features/mentions/types';

const renderSuggestion: MentionSuggestionRenderer = (
  suggestion,
  _search,
  _highlighted,
  index,
  focused,
) => {
  return (
    <MenuItem key={index} selected={focused}>
      <ListItemText primary={suggestion.display} />
    </MenuItem>
  );
};

/**
 * Create a `MentionDataSource` with the hash-tags data and configurations needed for `MentionsInput`.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
 * @returns Instance of `MentionDataSource`.
 */
export function useMentionsHashTagDataSource(): MentionDataSource {
  const fetchMentions: MentionFetcher = (search, setSuggestions) => {
    if (!search) {
      return;
    }

    setSuggestions([{ id: search, display: `#${search}` }]);
  };

  return {
    data: fetchMentions,
    markup: HASHTAG_MARKUP,
    renderSuggestion,
  };
}
