import { useEffect, useState } from 'react';
import { Box, Button, Chip, Paper, Typography } from '@material-ui/core';
import { Theme, css, useTheme } from '@emotion/react';

import { URL_REGEX } from '@jebel/utils';

import { RichText, MediaGallery } from 'shared/components/ui';
import {
  authorNameCss,
  contentContainerCss,
  dateChipCss,
  descriptionContainerCSS,
  descriptionTextCSS,
  postHeaderAuthorCss,
  postHeaderCss,
  PostProps,
  AdLikeButton,
} from 'shared/features/posts';
import { useIntersectionWatcher, useResponsive } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const containerCss = (theme: Theme) => css`
  padding: ${theme.spacing(4)}px;
  box-shadow: 0 7px 21px 0 rgba(171, 171, 171, 0.17);
  border-radius: ${theme.spacing(1)}px;
`;

const adActionsCss = theme => css`
  margin-top: ${theme.spacing(2)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const adAuthorCss = isMobile => (theme: Theme) =>
  css`
    ${authorNameCss(isMobile)(theme)};
    :hover {
      cursor: pointer;
    }
  `;

const sponsoredBadgeCss = theme => css`
  ${dateChipCss(theme)};
  background-color: ${theme.palette.info.light};
`;

type AdditionalProps = {
  organizationName: string;
  onLikeAdd: () => Promise<void>;
  onReactionRemove: () => Promise<void>;
  onClickAction: () => void;
  onClickName: () => void;
  actionText: string;
  actionLink: string;
  onShowedUp: () => void;
  image?:
    | {
        __typename?: 'File';
        id?: string | null;
        downloadUrl?: string | null;
      }
    | null
    | undefined;
  loading?: boolean;
};

export type AdProps = Pick<PostProps, 'createdAt' | 'postDate' | 'text' | 'id'> & AdditionalProps;

export const Ad = ({
  id,
  organizationName,
  createdAt,
  postDate,
  text,
  actionText = 'Learn More',
  actionLink,
  image,
  onClickAction,
  onClickName,
  onLikeAdd,
  onReactionRemove,
  onShowedUp,
  loading = false,
}: AdProps) => {
  const [reported, setReported] = useState(false);

  const theme = useTheme();

  const { ref, haveBeenShown: isHaveBeenDisplayed } = useIntersectionWatcher<HTMLDivElement>();
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isHaveBeenDisplayed && reported === false) {
      setReported(true);
      onShowedUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHaveBeenDisplayed]);

  const onClick = () => {
    onClickAction();

    if (!actionLink || !URL_REGEX.test(actionLink)) {
      return;
    }

    try {
      const destionation = new URL(actionLink);

      if (destionation.origin === window.location.origin) {
        // Open in the same tab instead of open a new window.
        window.location.assign(destionation);
        return;
      }
    } catch (err) {
      recordError(err);
    }

    window.open(actionLink);
  };

  return (
    <Paper ref={ref} css={containerCss} variant="elevation">
      <Box css={contentContainerCss}>
        <Box css={postHeaderCss}>
          <Box css={postHeaderAuthorCss}>
            <Typography css={adAuthorCss(isMobile)} paragraph={false} onClick={onClickName}>
              {organizationName}
            </Typography>
          </Box>
          <Chip css={sponsoredBadgeCss} label="Sponsored" />
        </Box>
      </Box>

      <Box css={descriptionContainerCSS(isMobile)}>
        <RichText css={descriptionTextCSS(isMobile)(theme)}>
          <p>{text}</p>
        </RichText>
      </Box>

      {image && <MediaGallery media={[image]} />}

      <Box css={adActionsCss}>
        <AdLikeButton onLikeAdd={onLikeAdd} onReactionRemove={onReactionRemove} adId={id} />

        <Button variant="contained" color="secondary" onClick={onClick} disableElevation>
          {actionText}
        </Button>
      </Box>
    </Paper>
  );
};
