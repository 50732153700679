import { parseHomeFeedItemText } from './parseHomeFeedItemText';
/** Clean the mentions from `HomeFeedItem` content leaving human-readable text. */
export function sanitizeHomeFeedItemText(text) {
    const tokens = parseHomeFeedItemText(text);
    let sanitized = '';
    for (const token of tokens) {
        if (token.token === 'text') {
            sanitized += token.content;
        }
        if (token.token === 'hashtag') {
            sanitized += token.content;
        }
        if (token.token === 'mention') {
            sanitized += token.content;
        }
        if (token.token === 'url') {
            sanitized += token.url;
        }
    }
    return sanitized;
}
