import React from 'react';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Box, Typography } from 'shared/components/ui';
import { CareerInfoFragment } from 'shared/graphql';
import { getOrganizationAddress } from 'shared/utils/address';

const addressCSS = (theme: any) => css`
  min-height: auto;
  display: block;
  font-weight: 500;
  color: ${theme.palette.text.secondary};
`;

const companyTitleCSS = (theme: any) => css`
  font-weight: 500;
  color: ${theme.palette.secondary.light};
  min-height: auto;
  font-size: ${theme.typography.fontSize}px;
  line-height: 17px;
`;

const jobTitleCSS = (theme: any) => css`
  font-weight: 500;
  font-size: ${theme.typography.fontSize}px;
  line-height: 24px;
  color: ${theme.palette.primary.light};
`;

const savedJobBoxCSS = css`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 2px;
`;

const savedJobContainerCSS = css`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 15px;
  align-content: center;
  align-items: center;
`;

const AUTHOR_AVATAR_SIZE = 80;

const careerImageCSS = (theme: any) => css`
  width: ${AUTHOR_AVATAR_SIZE}px;
  height: ${AUTHOR_AVATAR_SIZE}px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.borderlight};
`;

type SavedJobsItemProps = {
  savedJob: CareerInfoFragment | undefined;
};

export const SavedJobsItem = ({ savedJob }: SavedJobsItemProps) => {
  return (
    <Box css={savedJobContainerCSS}>
      <Avatar
        css={careerImageCSS}
        src={savedJob?.organization?.logo?.downloadUrl || ''}
        srcSet={processFilestackUrlSrcSet(savedJob?.organization?.logo?.downloadUrl || '', {
          compress: true,
          resize: {
            width: AUTHOR_AVATAR_SIZE,
            height: AUTHOR_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        variant="square"
      />
      <Box css={savedJobBoxCSS}>
        <Typography css={companyTitleCSS}>{savedJob?.organization?.name}</Typography>
        <Typography css={jobTitleCSS}>{savedJob?.position}</Typography>
        <Typography css={addressCSS} color="primary">
          {getOrganizationAddress(savedJob?.organization)}
        </Typography>
      </Box>
    </Box>
  );
};
