import { useState } from 'react';
import { DateTime } from 'luxon';

import { DateFilterType } from '../types';

const defaultDateFilterState: DateFilterType = {
  startDate: DateTime.now().startOf('week').setLocale('en-US'),
  endDate: DateTime.now().endOf('day').setLocale('en-US'),
};

export function useDateFilterState(defaultState: DateFilterType = defaultDateFilterState) {
  const [dateFilter, setDateFilter] = useState(defaultState);

  const setPartialDateFilter = (field: keyof DateFilterType) => {
    return (value: DateTime) => {
      setDateFilter(prevDateFilter => ({
        ...prevDateFilter,
        [field]: value,
      }));
    };
  };

  return [dateFilter, setPartialDateFilter] as const;
}
