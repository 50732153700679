import styled from '@emotion/styled';
import { Avatar, Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Header = styled.div`
  --header-logo-size: 10rem;
  --header-space: 2rem;

  display: grid;
  align-items: start;
  grid-template-columns: var(--header-logo-size) 1fr max-content;
  gap: var(--header-space);

  ${p => p.theme.breakpoints.down('md')} {
    --header-logo-size: 8rem;
    --header-space: 1rem;

    grid-template-columns: 1fr;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  gap: 0.5rem;
`;

export const HeaderAvatar = styled(Avatar)`
  width: var(--header-logo-size);
  height: var(--header-logo-size);
  border: solid 1px;
  border-color: ${p => p.theme.palette.divider};
`;

HeaderAvatar.defaultProps = {
  variant: 'rounded',
};

export const HeaderTitle = styled(Typography)``;

HeaderTitle.defaultProps = {
  variant: 'subtitle1',
};

export const HeaderMembers = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

HeaderMembers.defaultProps = {
  color: 'secondary',
};

export const HeaderDescription = styled(Typography)``;

export const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
