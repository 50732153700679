import React from 'react';

export const useInitialPreloaderHide = () => {
  React.useEffect(() => {
    const htmlNode = document.querySelector('html');

    if (htmlNode && htmlNode.dataset.preloader === '') {
      delete htmlNode.dataset.preloader;
    }
  }, []);
};
