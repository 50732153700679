import { useEffect, useState } from 'react';

interface Payload<T> {
  data: T;
  readonly timestamp: number;
}

interface Options<T> {
  initial?: T;
  /** Set the primary key to store the value on persistence. Otherwise will be a basic state. */
  key?: string;
}

export function usePersistentState<T>(options: Options<T>) {
  const [current, setCurrent] = useState<T | undefined>(options.initial);
  const [timestamp, setTimeStamp] = useState(0);

  useEffect(() => {
    const saved = get();

    set(saved?.data ?? options.initial);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get = () => {
    if (!options.key) {
      const timestamp = Date.now();

      return {
        data: options?.initial,
        timestamp,
      };
    }

    const saved = localStorage.getItem(options.key);

    if (typeof saved === 'string') {
      const payload: Payload<T> | null = JSON.parse(saved);

      if (payload) {
        return payload;
      }
    }

    return undefined;
  };

  const set = (value: T | undefined) => {
    if (value === undefined || value === null) {
      clean();
      return;
    }

    const timestamp = Date.now();

    if (options.key) {
      const payload: Payload<T> = {
        data: value,
        timestamp,
      };

      const json = JSON.stringify(payload);

      localStorage.setItem(options.key, json);
    }

    setTimeStamp(timestamp);
    setCurrent(value);
  };

  const clean = () => {
    setCurrent(undefined);

    if (options.key) {
      localStorage.removeItem(options.key);
    }
  };

  return {
    get value() {
      return current;
    },

    get timestamp() {
      return timestamp;
    },

    set,
    clean,
    setValue: set,
  };
}
