import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { DiscountsPage } from './pages/DiscountsPage';

export const getDiscountsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.discounts.index,
    exact: true,
    component: DiscountsPage,
  },
];
