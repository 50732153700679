import { gql } from '@apollo/client';

import {
  CURRENT_USER_FRAGMENT,
  UserPublicProfileFragment,
  useUserPublicProfileQuery,
} from 'shared/graphql';

import { EDUCATION_PROFILE_FRAGMENT, PROFESSIONAL_PROFILE_FRAGMENT } from '../queries';

const USER_PUBLIC_PROFILE_FRAGMENT = gql`
  fragment UserPublicProfile on User {
    ...CurrentUser

    facebookLink
    instagramLink
    linkedInLink
    twitterLink
  }

  ${CURRENT_USER_FRAGMENT}
`;

const USER_PUBLIC_PROFILE_QUERY = gql`
  query UserPublicProfile($id: ID!) {
    user(id: $id) {
      ...UserPublicProfile
    }

    professionalExperience: jobEmployeesList(filter: { user: { id: { equals: $id } } }) {
      count
      items {
        ...ProfessionalProfile
      }
    }

    professionalEducation: educationalInstitutionsList(filter: { user: { id: { equals: $id } } }) {
      count
      items {
        ...EducationProfile
      }
    }
  }

  ${USER_PUBLIC_PROFILE_FRAGMENT}
  ${PROFESSIONAL_PROFILE_FRAGMENT}
  ${EDUCATION_PROFILE_FRAGMENT}
`;

interface Options {
  id: string | undefined | null;
}

/**
 * Custom hook to fetch the public profile information of a user.
 * @param id - The ID of the user.
 * @returns An object containing the public profile information, loading state, and error state.
 */
export function useUserPublicProfile({ id }: Options) {
  const {
    data: response,
    loading,
    error,
  } = useUserPublicProfileQuery({
    skip: !id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id! },
  });

  const user = response?.user as UserPublicProfileFragment | undefined;
  const professionalExperience = response?.professionalExperience?.items ?? [];
  const professionalEducation = response?.professionalEducation?.items ?? [];

  return {
    user,
    professionalExperience,
    professionalEducation,
    loading,
    /** @deprecated Use `user`, `professionalExperience` or `professionalEducation` instead. */
    publicProfileInfo: response,
    /** @deprecated Use `loading` instead. */
    publicInfoLoading: loading,
    error,
  };
}
