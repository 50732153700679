import { useCurrentUser } from 'shared/hooks';
import { SchoolSupporterKeyFilter, useSchoolSupporterClickCreateMutation } from 'shared/graphql';
import { recordMessage } from 'shared/utils/record';

export function useCreateSchoolClickMutation() {
  const { user } = useCurrentUser();

  const [createClick] = useSchoolSupporterClickCreateMutation();

  return async (supporter: SchoolSupporterKeyFilter) => {
    if (!user?.id || !supporter?.id) {
      recordMessage(`No either "user" or "supporter" ID were found to create a business click.`);
      return;
    }

    await createClick({
      variables: {
        userId: user.id,
        supporterId: supporter.id,
      },
    });
  };
}
