import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { OrganizationDetailsPage } from './pages/OrganizationDetailsPage';
import { OrganizationsPage } from './pages/OrganizationsPage';

export const getOrganizationsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.organizations.index,
    exact: true,
    component: OrganizationsPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.organizations.organization,
    exact: true,
    component: OrganizationDetailsPage,
  },
];
