export class FilterBuilder {
    constructor(initial) {
        this.initial = initial;
        this.unions = [];
        this.intersections = [];
    }
    and(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.and(rule.build());
                continue;
            }
            const keys = Object.keys(rule);
            if (keys.length === 0) {
                continue;
            }
            /** Validate if the new filter only have `AND` and can be concat. */
            if (keys.length === 1 && 'AND' in rule && Array.isArray(rule.AND)) {
                this.and(...rule.AND);
                continue;
            }
            this.unions.push(rule);
        }
        return this;
    }
    or(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.or(rule.build());
                continue;
            }
            const keys = Object.keys(rule);
            if (keys.length === 0) {
                continue;
            }
            /** Validate if the new filter only have `OR` and can be concat. */
            if (keys.length === 1 && 'OR' in rule && Array.isArray(rule.OR)) {
                this.or(...rule.OR);
                continue;
            }
            this.intersections.push(rule);
        }
        return this;
    }
    build() {
        let filter = { ...this.initial };
        if (Array.isArray(filter.AND)) {
            this.and(...filter.AND);
            delete filter.AND;
        }
        if (Array.isArray(filter.OR)) {
            this.or(...filter.OR);
            delete filter.OR;
        }
        if (this.unions.length > 0) {
            filter = { ...filter, AND: this.unions };
        }
        if (this.intersections.length > 0) {
            filter = { ...filter, OR: this.intersections };
        }
        return filter;
    }
}
export function createFilterBuilder(initial) {
    return new FilterBuilder({ ...initial });
}
