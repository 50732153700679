import { css } from '@emotion/react';
import styled from '@emotion/styled';

const titleCSS = css`
  font-weight: 500;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: inherit;
`;

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.palette.primary.sideFont};
  padding: 0 1rem;
  gap: 1rem;
`;

// Title

export const Title = styled.span`
  ${titleCSS};
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// ViewAll

export const ViewAll = styled.a`
  ${titleCSS};

  text-decoration: underline;
  cursor: pointer;
`;
