import React from 'react';
import { Divider, Paper, Box } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core';

import { useCurrentUser } from '../../../../shared/hooks';
import { DashboardHeader } from './DashboardHeader';
import { DashboardCountStats } from './DashboardCountStats';
import { DashboardCharts } from './DashboardCharts';

export function DashboardLayout() {
  const { user } = useCurrentUser();
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  return (
    <Box>
      <DashboardHeader name={user?.firstName ?? ''} />
      <Paper sx={{ mt: 2, p: isSmUp ? 4 : 2, gap: 2 }}>
        <DashboardCountStats />
        <Divider variant="fullWidth" flexItem sx={{ mt: 4, mb:2 }} />
        <DashboardCharts />
      </Paper>
    </Box>
  );
}
