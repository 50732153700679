import React from 'react';

import { usePostsListItem } from 'shared/features/posts';
import { EventsListQuery, EVENTS_LIST_QUERY } from 'shared/graphql';

const transformGroupPostsQueryData = (listsData: EventsListQuery | undefined) => {
  const posts = listsData?.eventsList.items || [];
  return { posts };
};

const EVENT_FIELD_NAME = 'eventsList';

export const useEventPostsQuery = (postId: string | null | undefined) => {
  const { listsData, loading, ...rest } = usePostsListItem<EventsListQuery>({
    itemsFieldName: EVENT_FIELD_NAME,
    listQuery: EVENTS_LIST_QUERY,
    variables: {
      filter: {
        id: { equals: postId },
      },
    },
  });

  const { posts } = React.useMemo(() => transformGroupPostsQueryData(listsData), [listsData]);

  return {
    posts,
    loading,
    ...rest,
  };
};
