import { gql } from '@apollo/client';

export const EVENT_INFO_FRAGMENT = gql`
  fragment EventInfo on Event {
    id
    title
    date
    link
    status
    description
    createdBy {
      id
      firstName
      lastName
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
    createdAt
    location {
      id
      city
      state
      zip
      street1
    }
    titleSponsor {
      id
      industry
      name
      logo {
        id
        downloadUrl
        fileId
        filename
      }
      media {
        items {
          id
          downloadUrl
        }
      }
    }
    images {
      items {
        id
        downloadUrl
        fileId
        filename
      }
    }
    otherSponsors {
      items {
        id
        logo {
          id
          downloadUrl
          fileId
          filename
        }
        name
      }
    }
    author {
      id
      firstName
      lastName
    }
    timezone
  }
`;

export const EVENTS_LIST_QUERY = gql`
  query EventsList($filter: EventFilter, $sort: [EventSort!], $first: Int, $skip: Int) {
    eventsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...EventInfo
      }
    }
  }
  ${EVENT_INFO_FRAGMENT}
`;

// NOTE: Use the same fragment either client-side and server-side to avoid apollo cache mismatches.
export const EVENT_SEARCH_FRAGMENT = gql`
  fragment EventSearch on Event {
    id
    title
    date
    link
    status
    description
    timezone
    createdAt

    createdBy {
      id
      firstName
      lastName
    }

    images {
      items {
        id
        downloadUrl
        fileId
      }
    }

    location {
      id
      city
      state
      zip
      street1
    }

    titleSponsor {
      id
      industry
      name
      logo {
        id
        fileId
        downloadUrl
      }
    }

    otherSponsors {
      items {
        id
        logo {
          id
          fileId
          downloadUrl
        }
      }
    }
  }
`;

export const EVENT_SEARCH_QUERY = gql`
  query EventSearch(
    $filter: EventFilter
    $sort: [EventSort!]
    $first: Int
    $skip: Int
    $startPointZip: String!
    $radius: String!
    $proximitySort: String
  ) {
    events: eventsFilterByZipRequest(
      startPointZip: $startPointZip
      radius: $radius
      filter: $filter
      sort: $sort
      first: $first
      skip: $skip
      proximitySort: $proximitySort
    ) {
      count
      items {
        ...EventSearch
      }
    }
  }
  ${EVENT_SEARCH_FRAGMENT}
`;

export const EVENT_CREATE_MUTATION = gql`
  mutation EventCreate($data: EventCreateInput!) {
    eventCreate(data: $data) {
      ...EventInfo
    }
  }
  ${EVENT_INFO_FRAGMENT}
`;

export const EVENT_UPDATE_MUTATION = gql`
  mutation EventUpdate($data: EventUpdateInput!) {
    eventUpdate(data: $data) {
      ...EventInfo
    }
  }
  ${EVENT_INFO_FRAGMENT}
`;
