import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { LegacyPage } from './pages/LegacyPage';

export const getAdminLegacyRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.legacy.index,
    exact: true,
    component: LegacyPage,
  },
];
