import { useTheme } from '@emotion/react';
import { Box } from '@material-ui/core';
import { ElementType, PropsWithChildren, useMemo } from 'react';

export interface StackProps {
  className?: string;

  /**
   * The component used for the root node.
   * @default "div"
   */
  component?: ElementType;

  /**
   * @default "column"
   */
  direction?: 'row' | 'column';

  /**
   * The space between the children.
   * @default 1
   */
  spacing?: number;
}

export function Stack(props: PropsWithChildren<StackProps>) {
  const theme = useTheme();

  const direction = useMemo(() => props.direction ?? 'column', [props.direction]);
  const spacing = useMemo(() => theme.spacing(props.spacing ?? 1), [props.spacing, theme]);

  return (
    <Box
      component={props.component}
      className={props.className}
      display="flex"
      flexDirection={direction}
      gridGap={spacing}
    >
      {props.children}
    </Box>
  );
}
