import React from 'react';
import { css } from '@emotion/react';
import { Paper, Box } from '@material-ui/core';
import { Redirect, useParams, Switch } from 'react-router-dom';

import { RouteLayout } from 'shared/components/layouts';
import { Button, Modal, Route, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { buildUrl } from 'shared/routes';
import { useCrudPermissions } from 'shared/hooks';

import { BusinessDetailsSidebar } from '../components/BusinessDetailsSidebar';
import { BusinessDetailsInformationPage } from './BusinessDetailsInformationPage';
import { BusinessManagers } from './BusinessManagers';
import { BusinessInviteModal } from '../components/BusinessContent/BusinessInviteModal';

const contentCss = css`
  display: grid;
  grid-template-columns: 1fr 4fr;
`;

const postButtonCss = theme => css`
  background-color: ${theme.palette.primary.light};
  height: 32px;
`;

export const BusinessDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { businessPermissions } = useCrudPermissions();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { onModalClose, onModalOpen } = React.useMemo(
    () => ({
      onModalClose: () => setIsModalOpen(false),
      onModalOpen: () => setIsModalOpen(true),
    }),
    [],
  );
  return (
    <RouteLayout
      content={
        <Box display="grid" gridTemplateRows="min-content auto">
          <Modal
            dialogProps={{ open: isModalOpen, onClose: onModalClose }}
            titleProps={{ title: 'Invite Business' }}
          >
            <BusinessInviteModal onModalClose={onModalClose} />
          </Modal>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="subtitle2" color="primary">
              Business Details
            </Typography>
            {businessPermissions.add && (
              <Button
                css={postButtonCss}
                color="primary"
                variant="contained"
                size="small"
                onClick={onModalOpen}
              >
                INVITE BUSINESS
              </Button>
            )}
          </Box>
          <Paper css={contentCss}>
            <BusinessDetailsSidebar />
            <Switch>
              <Route
                path={APP_URL.admin.business.information}
                title="Business Information"
                authAccess="any"
              >
                <BusinessDetailsInformationPage businessId={id} />
              </Route>
              <Route
                path={APP_URL.admin.business.managers}
                title="Business Managers"
                authAccess="any"
              >
                <BusinessManagers businessId={id} />
              </Route>
              <Redirect
                to={buildUrl(APP_URL.admin.business.information, {
                  pathParams: { id },
                })}
              />
            </Switch>
          </Paper>
        </Box>
      }
      withFullWidth
    />
  );
};
