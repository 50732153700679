import React from 'react';

import { AsyncContent } from 'shared/components/ui';
import { usePosts } from 'shared/features/posts';
import { useStreamChatReactions } from 'shared/hooks';

import { EventComments } from './EventComments';

interface EventCommentsListProps {
  eventId?: string | null;
}

export const EventCommentsList: React.FC<EventCommentsListProps> = ({ eventId }) => {
  const { activity, loading } = usePosts({ postId: eventId });
  const { onCommentAdd, onLikeAdd, onReactionRemove, onCommentRemove } = useStreamChatReactions();

  if (!activity) {
    return null;
  }

  return (
    <AsyncContent loading={loading}>
      <React.Fragment>
        <EventComments
          onCommentAdd={onCommentAdd}
          onLikeAdd={onLikeAdd}
          onReactionRemove={onReactionRemove}
          onCommentRemove={onCommentRemove}
          activity={activity}
        />
      </React.Fragment>
    </AsyncContent>
  );
};
