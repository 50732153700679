import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { EVENT_STATUSES } from '@jebel/constants';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { EVENT_CREATE_MUTATION } from 'shared/graphql';
import { EventCreateMutation, EventCreateMutationVariables } from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';

export const useEventCreate = () => {
  const { userId, isAdmin } = useCurrentUser();
  const [eventCreate, { loading, data, ...rest }] = useMutation<
    EventCreateMutation,
    EventCreateMutationVariables
  >(
    EVENT_CREATE_MUTATION,
    isAdmin
      ? {
          refetchQueries: ['EventSearch', 'EventsCount', 'EventsList', 'EventsListCount'],
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your Event has been created and published.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: There are one or more errors in your Event Request. Please review carefully and try again.`,
          },
        }
      : {
          refetchQueries: ['EventSearch', 'EventsCount', 'EventsList'],
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]:
              'Success: Your Event Request has been submitted and is currently under review. You will be notified once the review is completed.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: There are one or more errors in your Event Request. Please review carefully and try again.`,
          },
        },
  );

  const onEventCreate = useCallback(
    async (data: EventCreateMutationVariables['data']) => {
      const response = await eventCreate({
        variables: {
          data: {
            ...data,
            author: {
              connect: {
                id: userId,
              },
            },
            status: isAdmin ? EVENT_STATUSES.active : EVENT_STATUSES.pendingApproval,
            isApproved: isAdmin,
          },
        },
      });

      return response.data?.eventCreate;
    },
    [userId, eventCreate, isAdmin],
  );

  return { onEventCreate, loading, data, ...rest };
};
