/** Generate the relationships needed to create or update a list of files. */
export function fileManyRelations(newest, oldest) {
    const relation = {};
    const initial = oldest !== null && oldest !== void 0 ? oldest : [];
    const images = newest !== null && newest !== void 0 ? newest : [];
    const disconnect = initial
        .filter(image => {
        /** Verify in the seleceted array of images if the current stills selected. */
        const isSelected = images.some(selected => selected.fileId === (image === null || image === void 0 ? void 0 : image.fileId));
        return !isSelected;
    })
        .map(image => {
        return { fileId: image.fileId };
    });
    if (disconnect.length > 0) {
        // Disconnect only when are images
        relation.disconnect = disconnect;
    }
    const create = images
        .filter(image => !(image === null || image === void 0 ? void 0 : image.id) && (image === null || image === void 0 ? void 0 : image.fileId))
        .map(image => {
        return {
            fileId: image.fileId,
            filename: image.filename,
        };
    });
    if (create.length > 0) {
        // Create only when are images
        relation.create = create;
    }
    return relation;
}
