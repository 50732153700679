import { ROUTES } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { LegacyPage } from './pages/LegacyPage';
import { LegacyPostsPage } from './pages/LegacyPostsPage';
import { LegacyYearPage } from './pages/LegacyYearPage';

export const getLegacyRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.legacy.index,
    exact: true,
    component: LegacyPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.legacy.all,
    exact: true,
    component: LegacyPostsPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.legacy.year,
    exact: true,
    component: LegacyYearPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.legacy.month,
    exact: true,
    component: LegacyPostsPage,
  },
];
