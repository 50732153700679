import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes';

import { EventsPage } from './pages/EventsPage';

export const getAdminEventsRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.events.index,
    exact: true,
    component: EventsPage,
  },
];
