import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Reactions

export const Reactions = styled.div`
  min-height: 2rem;
  display: flex;
  gap: 0.5rem;
`;
