import {
  HighSchoolFragment,
  HighSchoolLocationCreateInput,
  HighSchoolLocationKeyFilter,
  Maybe,
} from 'shared/graphql';

import { UserManageEducationValues } from './UserManageEducationForm';

interface Relation {
  connect?: Maybe<HighSchoolLocationKeyFilter>;
  disconnect?: Maybe<HighSchoolLocationKeyFilter>;
  create?: Maybe<HighSchoolLocationCreateInput>;
}

/**
 * Use the `form` to either create, connect or disconnect the previous high school.
 * @url https://8base-dev.atlassian.net/browse/JEB-1487?focusedCommentId=42751
 */
export function createEducationHighSchoolRelation(
  form: UserManageEducationValues,
  oldest?: Maybe<HighSchoolFragment>,
): Relation {
  const relation: Relation = {};

  if (typeof form.highSchool === 'string') {
    // Create a new high school using the form information
    const payload: HighSchoolLocationCreateInput = {
      name: form.highSchool,
      state: form.address?.state,
      city: form.address?.city,
      zip: form.address?.zip,
    };

    relation.create = payload;
  }

  if (typeof form.highSchool === 'object' && form.highSchool.id) {
    // Connect the new high school selected from the form
    relation.connect = { id: form.highSchool.id };
  }

  return relation;
}
