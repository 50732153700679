import { useHistory } from 'react-router-dom';
import { ReactNode } from 'react';

import { MemberInfoFragment, UserInfoFragment } from 'shared/graphql';
import { useResponsive } from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';
import { UserAvatar } from 'shared/components/symbols';
import { generateUserProfileRoute } from 'shared/routes';

import { Container, Content, Description, Divided } from './ExplorerUserCard.styles';
import { ExplorerUserCardButtons } from './ExplorerUserCardButtons';
import { ExplorerUserCardHeader } from './ExplorerUserCardHeader';

interface Props {
  data: UserInfoFragment | MemberInfoFragment;

  /**
   * Whether to show a divider at the end of the card.
   * @default false
   */
  withDivider?: boolean;

  /** @default false */
  withMessage?: boolean;
  /** @default false */
  withViewProfile?: boolean;
  /** Allow to extend the current button options. */
  addons?: ReactNode;
}

export function ExplorerUserCard(props: Props) {
  const description = props.data?.professionalDesignations;

  const withMessage = props.withMessage ?? false;
  const withViewProfile = props.withViewProfile ?? false;

  const { push: navigate } = useHistory();
  const { isMobile } = useResponsive();
  const { onOpenInboxModal } = useInboxContext();

  const openProfile = () => {
    if (!props.data?.id) {
      return;
    }

    const url = generateUserProfileRoute(props.data.id);
    navigate(url);
  };

  const openMessage = () => {
    if (!onOpenInboxModal) {
      return;
    }

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: [props.data],
        messageType: 'personal',
      },
    });
  };

  if (isMobile) {
    return (
      <Container withDivider={props.withDivider}>
        <Divided>
          <UserAvatar user={props.data} />
          <ExplorerUserCardHeader user={props.data} />
        </Divided>

        <ExplorerUserCardButtons
          withMessage={withMessage}
          withViewProfile={withViewProfile}
          addons={props.addons}
          onOpenProfile={openProfile}
          onOpenMessage={openMessage}
        />

        {description && <Description>{description}</Description>}
      </Container>
    );
  }

  return (
    <Container withDivider={props.withDivider}>
      <Divided>
        <UserAvatar user={props.data} />

        <Content>
          <ExplorerUserCardHeader user={props.data} />

          {description && <Description>{description}</Description>}
        </Content>

        <ExplorerUserCardButtons
          withMessage={withMessage}
          withViewProfile={withViewProfile}
          addons={props.addons}
          onOpenProfile={openProfile}
          onOpenMessage={openMessage}
        />
      </Divided>
    </Container>
  );
}
