import React, { useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { UserKeyFilter, useAdLikesQuery } from 'shared/graphql';
import { recordError } from 'shared/utils/record';

type AdLikeButtonProps = {
  adId: string;
  onLikeAdd: () => Promise<void>;
  onReactionRemove: () => Promise<void>;
};

export const AdLikeButton: React.FC<AdLikeButtonProps> = ({
  adId,
  onLikeAdd,
  onReactionRemove: onLikeRemove,
}) => {
  const [reactions, setReactions] = useState<UserKeyFilter[]>([]);

  const { user } = useCurrentUser();

  useAdLikesQuery({
    skip: !adId,
    variables: { adId },
    notifyOnNetworkStatusChange: true,

    onCompleted(data) {
      const reactions = data?.advertisingCampaignAd?.userLikes?.items ?? [];
      setReactions(reactions);
    },
  });

  const currentUserReaction = useMemo(() => {
    return reactions.find(reaction => reaction.id === user?.id);
  }, [user, reactions]);

  const hasCurrentUserLike = Boolean(currentUserReaction);

  const removeReaction = async (selected: typeof reactions[number]) => {
    const prev = [...reactions];

    // Change the state optimistically before the response arrives.
    setReactions(reactions => {
      return reactions.filter(reaction => reaction.id !== selected.id);
    });

    try {
      await onLikeRemove();
    } catch (err) {
      setReactions(prev);
      recordError(err);
    }
  };

  const attachReaction = async () => {
    const prev = [...reactions];

    if (!user?.id) {
      return;
    }

    const params: UserKeyFilter = { id: user?.id };
    // Change the state optimistically before the response arrives.
    setReactions(prev => [...prev, params]);

    try {
      await onLikeAdd();
    } catch (err) {
      setReactions(prev);
      recordError(err);
    }
  };

  const onClick = async () => {
    if (currentUserReaction?.id) {
      await removeReaction(currentUserReaction);
      return;
    }

    await attachReaction();
  };

  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<ThumbUp />}
      color={hasCurrentUserLike ? 'secondary' : 'primary'}
    >
      {reactions.length}
    </Button>
  );
};
