import { useCallback } from 'react';
import { Theme, css } from '@emotion/react';
import { IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { DragHandle, Launch } from '@material-ui/icons';

import { Box, Icon } from 'shared/components/ui';
import { SchoolConfigurationExternalLink } from 'shared/graphql';
import { SwitchToggle } from 'shared/components/ui/SwitchToggle';

const containerCSS = (isDragging: boolean) => (theme: Theme) =>
  css`
    display: flex;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${theme.palette.background.paper};

    ${isDragging &&
    css`
      box-shadow: ${theme.shadows[2]};
    `}

    :not(:last-child) {
      border-bottom: 1px solid ${theme.palette.divider};
    }
  `;

const buttonsCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const titleCSS = css`
  ${buttonsCSS};

  white-space: nowrap;
`;

const editIconCSS = (theme: Theme) => css`
  color: ${theme.palette.text.light};
`;

interface Props {
  item: SchoolConfigurationExternalLink;
  provided: any;
  snapshot: any;
  onItemUpdate(item: SchoolConfigurationExternalLink): void;
  onModalOpen(): void;
}

export function ExternalLink({ item, provided, snapshot, onItemUpdate, onModalOpen }: Props) {
  const onSwitchChange = useCallback(
    () => onItemUpdate({ ...item, isActive: !item.isActive }),
    [item, onItemUpdate],
  );

  return (
    <ListItem
      css={containerCSS(snapshot.isDragging)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      <ListItemIcon>
        <DragHandle fontSize="small" css={editIconCSS} />
      </ListItemIcon>

      <ListItemIcon color="GrayText">
        <Launch fontSize="small" />
      </ListItemIcon>

      <ListItemText css={titleCSS} disableTypography primary={item.name} />

      <Box css={buttonsCSS}>
        <IconButton onClick={onModalOpen}>
          <Icon name="Edit" variant="filled" fontSize="small" css={editIconCSS} />
        </IconButton>

        <SwitchToggle checked={item?.isActive ?? false} onChange={onSwitchChange} />
      </Box>
    </ListItem>
  );
}
