import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';

import { Form, FormTextField, Button, Modal } from 'shared/components/ui';
import { CareerInfoFragment, Maybe } from 'shared/graphql';
import { useCurrentUser, useInboxMessageSend } from 'shared/hooks';
import { getOrganizationAddress } from 'shared/utils/address';
import { getDateDifferenceInDays } from 'shared/utils/date';
import { APP_URL } from 'shared/constants';
import { buildUrl } from 'shared/routes';
import { formatUserName } from 'shared/utils/user';

import {
  AuthorAvatar,
  AuthorCreation,
  AuthorLink,
  AuthorName,
  Buttons,
  Container,
  Description,
  Header,
  HeaderContent,
  HeaderSection,
  OrganizationAddress,
  OrganizationAvatar,
  OrganizationName,
  Position,
  Section,
  SectionTitle,
} from './JobDetailsModal.styles';

type FormValues = {
  text?: string;
};

export interface Props {
  open: boolean;

  authorId?: Maybe<string>;
  job?: CareerInfoFragment;

  onClose(): void;
}

export function JobDetailsModal(props: Props) {
  const { createMessage } = useInboxMessageSend({ withContext: true });
  const { userId } = useCurrentUser();
  const { push: navigate } = useHistory();

  const initial = useMemo<FormValues>(() => {
    const position = props.job?.position ?? '';
    const organizationName = props.job?.organization?.name ?? '';

    return {
      text: `Hello, I'm interested in the "${position}" position on "${organizationName}".`,
    };
  }, [props.job]);

  const organizationName = props.job?.organization?.name ?? 'N/A';
  const organizationImage = props.job?.organization?.logo?.downloadUrl ?? undefined;
  const organizationAddress = getOrganizationAddress(props.job?.organization);

  const authorName = formatUserName(props.job?.createdBy);
  const authorImage = props.job?.createdBy?.avatar?.downloadUrl ?? undefined;

  const creationAgo = useMemo(() => {
    const now = new Date();

    return getDateDifferenceInDays(now.toISOString(), props.job?.createdAt);
  }, [props.job]);

  const openAuthorProfile = () => {
    const url = buildUrl(APP_URL.user.explorer.profile, {
      pathParams: { id: props.job?.createdBy?.id ?? '' },
    });

    navigate(url);
  };

  const handleSubmit = async (data: FormValues) => {
    const text = data.text?.trim();

    if (!text) {
      return;
    }

    const userIds: string[] = [userId as string];

    if (typeof props.authorId === 'string' && props.authorId !== userId) {
      userIds.push(props.authorId);
    }

    await createMessage({
      text,
      userIds,
      subject: 'Candidate applied for a job',
      jobId: props.job?.id || undefined,
    });

    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  return (
    <Modal
      titleProps={{ title: 'Job Post Detail' }}
      dialogProps={{ open: props.open, fullWidth: true, maxWidth: 'sm', onClose: handleCancel }}
      isVisibleDivider
    >
      <Container>
        <Header>
          <HeaderSection>
            <OrganizationAvatar src={organizationImage} alt={organizationName} />

            <HeaderContent>
              <Position>{props.job?.position}</Position>
              <OrganizationName>{organizationName}</OrganizationName>
              <OrganizationAddress>{organizationAddress}</OrganizationAddress>
            </HeaderContent>
          </HeaderSection>

          <HeaderSection>
            <AuthorAvatar src={authorImage} alt={authorName} />

            <HeaderContent>
              <AuthorName>
                Posted by <AuthorLink onClick={openAuthorProfile}>{authorName}</AuthorLink>
              </AuthorName>

              <AuthorCreation>{creationAgo}</AuthorCreation>
            </HeaderContent>
          </HeaderSection>
        </Header>

        <Section>
          <SectionTitle>Job Description</SectionTitle>
          <Description>{props.job?.description}</Description>
        </Section>

        <Divider />

        <Form initialValues={initial} onSubmit={handleSubmit}>
          {form => (
            <Section>
              <SectionTitle>Message Employer</SectionTitle>

              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'MESSAGE',
                  multiline: true,
                  rows: '7',
                  rowsMax: '7',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'text' }}
              />

              <Buttons>
                <Button disabled={form.isSubmitting} onClick={handleCancel}>
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={form.isSubmitting}
                  loading={form.isSubmitting}
                  onClick={form.submitForm}
                >
                  Send
                </Button>
              </Buttons>
            </Section>
          )}
        </Form>
      </Container>
    </Modal>
  );
}
