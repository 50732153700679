/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  CheckCircle,
  DeleteForeverSharp,
  Edit,
  ForumTwoTone,
  Mail,
  PersonAdd,
  ArrowCircleUp,
} from '@mui/icons-material';

import { USER_STATUSES, UserStatuses, SORT } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { Icon, Modal, ChooseStatusChipOption } from 'shared/components/ui';
import {
  APP_URL,
  SNACKBAR_ERROR_MESSAGE,
  SNACKBAR_SUCCESS_MESSAGE,
  USER_ACTIVITIES_OPTIONS,
} from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, SpreadsheetCellActions } from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  REJECT_REQUEST_MUTATION,
  AcceptRequestMutation,
  AcceptRequestMutationVariables,
  RejectRequestMutation,
  RejectRequestMutationVariables,
  MembersWithZipListQuery,
  MemberInfoFragment,
  Maybe,
  UserKeyFilter,
  UserFilter,
  MembersWithZipListQueryVariables,
  useResetUserCredentialsMutation,
} from 'shared/graphql';
import { buildUrl } from 'shared/routes';
import {
  useAudienceGraduatingYearsOptions,
  useCrudPermissions,
  useCurrentUser,
  useDownloadLazyQueryCSV,
  useToast,
  useUserRoles,
} from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';
import { formatToPhone } from 'shared/utils/form';
import { formatUserName } from 'shared/utils/user';
import { formatTableDate } from 'shared/utils/date';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { useOrganizations } from 'features/organizations/hooks';
import { recordError } from 'shared/utils/record';

import {
  MembersReportHeaders,
  MembersSpreadsheetHeader,
  memberSpreadsheetFilters,
} from '../constants';
import {
  ACCEPT_REQUEST_MUTATION,
  MEMBERS_WITH_ZIP_LIST_QUERY,
  TOGGLE_ACTIVITY_STATUS_MUTATION,
} from '../queries';
import { MemberEditRolesModal } from './MemberEditRolesModal';
import { ChooseUserStatusChip } from './ChooseUserStatusChip';

interface Props {
  statsLoading: boolean;
  refetchStats: () => Promise<void>;
}

const ADMIN_ROLE_ADDON = 'Community Admin';
const FALLBACK_RADIUS = '-';
const FALLBACK_START_POINT_ZIP = '';
const FALLBACK_SORT = { createdAt: SORT.desc };

export function MembersSpreadsheet({ refetchStats, statsLoading }: Props) {
  const {
    membersPermissions: { add: isCreateAccessed, edit: isEditAccessed, delete: isDeleteAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const { push: navigate } = useHistory();
  const { onOpenInboxModal } = useInboxContext();
  const { queryParams, sortOption, currentRowId, selected, chipsArray } = useSpreadsheetContext();

  const [editRolesModalMemberId, setEditRolesModalMemberId] = useState<string>();
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEditRolesModalOpen = () => {
    setIsEditRolesModalOpen(true);
  };

  const onEditRolesModalClose = () => {
    setIsEditRolesModalOpen(false);
  };

  const withCustomActivityFilter = useMemo(
    () => chipsArray.hobbies.length || chipsArray.clubs.length,
    [chipsArray.clubs.length, chipsArray.hobbies.length],
  );

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<MembersWithZipListQuery>(
    {
      query: MEMBERS_WITH_ZIP_LIST_QUERY,
      searchingFields: ['fullName', 'firstName', 'lastName', 'email'],
      queryVariables: {
        startPointZip: FALLBACK_START_POINT_ZIP,
        radius: FALLBACK_RADIUS,
        sort: queryParams.sort ?? FALLBACK_SORT,
        ...queryParams,
      },
    },
  );

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      filter.and({ id: { in: selected } });
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [generateCSV] = useDownloadLazyQueryCSV<
    MembersWithZipListQuery,
    MembersWithZipListQueryVariables
  >(MEMBERS_WITH_ZIP_LIST_QUERY, {
    filename: getFileNameWithTimestamp('Members.csv'),

    variables: {
      startPointZip: queryVariables.startPointZip ?? FALLBACK_START_POINT_ZIP,
      radius: queryVariables.radius ?? FALLBACK_RADIUS,
      filter: downloadFilter,
    },

    transform(response) {
      const members = response?.members?.items ?? [];
      return transformExport(members);
    },
  });

  const [toggleActiveStatus] = useMutation(TOGGLE_ACTIVITY_STATUS_MUTATION, {
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: `Success! You've toggled user active status.`,
      [SNACKBAR_ERROR_MESSAGE]: `Error! Something went wrong... Try again later.`,
    },
  });

  const [acceptRequest] = useMutation<AcceptRequestMutation, AcceptRequestMutationVariables>(
    ACCEPT_REQUEST_MUTATION,
    {
      refetchQueries: ['MembersWithZipList', 'MemberStatus', 'MemberStats'],
      context: {
        [SNACKBAR_ERROR_MESSAGE]: `Something went wrong acepting the member, please try again`,
      },
      onCompleted: () => {
        refetchStats();
      },
    },
  );

  const [rejectUser] = useMutation<RejectRequestMutation, RejectRequestMutationVariables>(
    REJECT_REQUEST_MUTATION,
    {
      refetchQueries: ['MembersWithZipList', 'MemberStatus'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Member has been rejected',
        [SNACKBAR_ERROR_MESSAGE]: `Something went wrong rejecting the member, please try again`,
      },
      onCompleted: () => {
        refetchStats();
      },
    },
  );

  const [resetUserCredentials] = useResetUserCredentialsMutation();

  const { user } = useCurrentUser();
  const { updateUserRoles } = useUserRoles();
  const { showError, showSuccess, showMessage, dismiss } = useToast();
  const { data: graduatingYears } = useAudienceGraduatingYearsOptions();
  const { data: organizations } = useOrganizations({
    variables: {
      sort: { name: SORT.asc },
    },
  });

  const promoteUser = async (member: UserKeyFilter) => {
    const adminRoleAddon =
      user?.rolesAddons?.items?.find(({ name }) => name === ADMIN_ROLE_ADDON) ?? {};

    const selectedMemberRolesIds = selectedMemberRoleAddons.map(member => member.id ?? '');

    try {
      const newestRoles = [...selectedMemberRolesIds, adminRoleAddon.id];

      await updateUserRoles(member.id as string, newestRoles as string[]);

      showSuccess('Roles updates successfully');
    } catch (err) {
      recordError(err);
      showError('Error while promoting to Admin');
    }
  };

  const getAvailableActions = (status?: string) => {
    const withApproveAction = checkUserStatus(status ?? selectedMember?.status, [
      USER_STATUSES.pending,
      USER_STATUSES.rejected,
    ]);

    const withActivesActions = checkUserStatus(status ?? selectedMember?.status, [
      USER_STATUSES.active,
      USER_STATUSES.inactive,
    ]);

    const withRejectAction = checkUserStatus(status ?? selectedMember?.status, [
      USER_STATUSES.invitationSent,
      USER_STATUSES.pending,
    ]);

    const withResendInvitation = checkUserStatus(status ?? selectedMember?.status, [
      USER_STATUSES.invitationSent,
    ]);

    const withResetCredentials = checkUserStatus(status ?? selectedMember?.status, [
      USER_STATUSES.invitationSent,
      USER_STATUSES.active,
    ]);

    return {
      withRejectAction,
      withActivesActions,
      withApproveAction,
      withResendInvitation,
      withResetCredentials,
    };
  };

  const onApproveAction = async (id: string) => {
    const member = members.find(member => member.id === id);

    if (!member || !member.email) {
      return;
    }

    try {
      await acceptRequest({ variables: { id } });

      showSuccess(`Member "${member.email}" has been approved`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  const onSendInvitation = async (id: string) => {
    try {
      // Use the same code because `adminAcceptRequest` will only send the emails
      // Because the user is already setup in the database
      // https://8base-dev.atlassian.net/browse/JEB-1558
      await acceptRequest({ variables: { id } });

      showSuccess(`Your invitation has been re-sent.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  const onRejectAction = async (id: string) => {
    try {
      await rejectUser({ variables: { id } });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  const onResetCredentials = async (id: string) => {
    if (!selectedMember) {
      return;
    }

    const RESETTING_CREDENTIALS_MESSAGE_KEY = `RESETTING_CREDENTIALS_${id}`;

    showMessage(`Resetting the account credentials for "${selectedMember.email}".`, {
      id: RESETTING_CREDENTIALS_MESSAGE_KEY,
    });

    try {
      await resetUserCredentials({ variables: { user: { id } } });

      showSuccess(
        `The account credentials for "${selectedMember.email}" have been successfully reset. Instructions will be sent to the user via email.`,
      );
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(RESETTING_CREDENTIALS_MESSAGE_KEY);
    }
  };

  const onToggleActive = async (id: string, oldStatus: string, newStatus: string) => {
    try {
      await toggleActiveStatus({
        variables: { id, status: newStatus },
        context: {
          [SNACKBAR_SUCCESS_MESSAGE]: `Success! You've toggled user status from ${oldStatus} to ${newStatus}.`,
          [SNACKBAR_ERROR_MESSAGE]: `Error! Something went wrong... Try again later.`,
        },
      });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  const filterClubs = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: MemberInfoFragment,
  ) => {
    return (
      !chipsArray.clubs.length ||
      chipsArray?.clubs?.some(club =>
        member?.activityClubs?.includes(
          USER_ACTIVITIES_OPTIONS.clubs.find(({ label }) => label === club)?.value || '',
        ),
      )
    );
  };

  const filterHobbies = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: MemberInfoFragment,
  ) => {
    return (
      !chipsArray.hobbies.length ||
      chipsArray?.hobbies?.some(hobby =>
        member?.hobbies?.includes(
          USER_ACTIVITIES_OPTIONS.hobbies.find(({ label }) => label === hobby)?.value || '',
        ),
      )
    );
  };

  const getMemberStatusComponent = (member: NormalizedMember) => {
    const options: ChooseStatusChipOption[] = [];

    const { withApproveAction, withActivesActions, withRejectAction, withResendInvitation } =
      getAvailableActions(member.status);

    if (withResendInvitation) {
      options.push({
        label: 'Re-send Invitation',
        value: USER_STATUSES.invitationSent,
        onClick: () => onSendInvitation(member.id),
      });
    }

    if (withApproveAction && isEditAccessed) {
      options.push({
        label: 'Approve Request',
        value: USER_STATUSES.invitationSent,
        onClick: () => onApproveAction(member.id),
      });
    }

    if (withActivesActions) {
      const value =
        member.status === USER_STATUSES.active ? USER_STATUSES.inactive : USER_STATUSES.active;
      const label = member.status === USER_STATUSES.active ? 'Inactive' : 'Active';

      options.push({
        label,
        value,
        onClick: () => onToggleActive(member.id, member.status, value),
      });
    }

    if (withRejectAction) {
      options.push({
        label: 'Reject',
        value: USER_STATUSES.rejected,
        onClick: () => onRejectAction(member.id),
      });
    }

    return <ChooseUserStatusChip status={member.status} options={options} />;
  };

  const members = useMemo(() => {
    const response = tableData?.members.items ?? [];

    return response
      .filter(member => filterClubs(chipsArray, member))
      .filter(member => filterHobbies(chipsArray, member));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const normalizedMembers = useMemo(() => {
    return normalizeMembers(members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const data = useMemo(() => {
    return normalizedMembers.map(member => {
      const status = getMemberStatusComponent(member);
      return { ...member, status };
    });
  }, [normalizedMembers, sortOption, getMemberStatusComponent]);

  const selectedMember = useMemo(() => {
    return normalizedMembers.find(user => user.id === currentRowId);
  }, [currentRowId, normalizedMembers]);

  const selectedMemberRoleAddons = useMemo(() => {
    const selected = members.find(user => user.id === currentRowId);
    return selected?.rolesAddons?.items ?? [];
  }, [currentRowId, members]);

  const spreadsheetActions = useMemo((): SpreadsheetCellActions => {
    const isPromoteToAdminActionVisible = !selectedMemberRoleAddons.find(
      ({ name }) => name === ADMIN_ROLE_ADDON,
    );

    const { withApproveAction, withRejectAction, withResendInvitation, withResetCredentials } =
      getAvailableActions();

    const options: SpreadsheetCellActions = [];

    if (isPromoteToAdminActionVisible && isEditAccessed && !withRejectAction) {
      options.push({
        id: 'promote_to_admin',
        title: 'Promote to Admin',
        onClickAction: (id: string) => promoteUser({ id }),
        icon: <ArrowCircleUp />,
      });
    }

    if (withApproveAction && isEditAccessed) {
      options.push({
        id: 'approve_request',
        title: 'Approve Request',
        onClickAction: onApproveAction,
        icon: <CheckCircle />,
      });
    }

    if (withResetCredentials) {
      options.push({
        id: 'reset_credentials',
        title: 'Reset Account Credentials',
        icon: <Mail />,
        onClickAction: onResetCredentials,
      });
    }

    options.push({
      id: 'view_details',
      title: 'View Details',
      onClickAction: (id: string) => {
        navigate(buildUrl(APP_URL.admin.members.information, { pathParams: { id } }));
      },
      icon: <Edit />,
    });

    if (isEditAccessed) {
      options.push({
        id: 'edit_roles',
        title: 'Edit User Roles',
        onClickAction: (id: string) => {
          onEditRolesModalOpen();
          setEditRolesModalMemberId(id);
        },
        icon: <PersonAdd />,
      });
    }

    options.push({
      id: 'message',
      title: 'Message',
      onClickAction: (id: string) => {
        const selected = members.filter(user => user.id === id);

        if (onOpenInboxModal) {
          onOpenInboxModal({
            isOpen: true,
            options: {
              members: selected,
              messageType: 'personal',
            },
          });
        }
      },
      icon: <ForumTwoTone />,
    });

    if (withRejectAction && isDeleteAccessed) {
      options.push({
        id: 'reject',
        title: 'Reject',
        onClickAction: onRejectAction,
        icon: <DeleteForeverSharp />,
      });
    }

    if (withResendInvitation) {
      options.push({
        id: 'ResendInvitation',
        title: 'Re-send Invitation',
        onClickAction: onSendInvitation,
        icon: <Mail />,
      });
    }

    return options;
  }, [
    selectedMember,
    getAvailableActions,
    isEditAccessed,
    isCreateAccessed,
    onApproveAction,
    isDeleteAccessed,
    onRejectAction,
    promoteUser,
    data,
    onOpenInboxModal,
  ]);

  const mainToolbarAction = useMemo(
    () => ({
      icon: <Icon name="Forum" size={20} color="secondary" />,
      label: 'Message',
      onClick: (ids: string[]) => {
        const selected = members.filter(user => ids.includes(user.id as string));

        if (onOpenInboxModal) {
          onOpenInboxModal({
            isOpen: true,
            options: { members: selected, messageType: 'personal' },
          });
        }
      },
    }),
    [onOpenInboxModal, data],
  );

  return (
    <Fragment>
      <div css={containerCSS}>
        <Spreadsheet
          data={data}
          headlines={MembersSpreadsheetHeader}
          toolbarOptions={{
            filters: memberSpreadsheetFilters({ organizations, graduatingYears }),
            mainToolbarAction,
            withPerPage: !withCustomActivityFilter,
            withDownload: true,
            downloadHandler: generateCSV,
            rawData: tableData?.members?.items ?? [],
            withCsvUpload: true,
          }}
          cellActions={spreadsheetActions}
          itemsCount={tableData?.members?.count ?? 0}
          loading={tableLoading || statsLoading || loadingAddons}
        />
      </div>

      <Modal
        dialogProps={{ open: isEditRolesModalOpen, onClose: onEditRolesModalClose }}
        titleProps={{ title: 'Edit User Roles' }}
      >
        <MemberEditRolesModal
          memberId={editRolesModalMemberId}
          onModalClose={onEditRolesModalClose}
        />
      </Modal>
    </Fragment>
  );
}

const containerCSS = css`
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    min-width: 0;
  }
`;

export const getStatus = (status?: Maybe<string>) => {
  if (!status || status === USER_STATUSES.pending) {
    return 'pending';
  }

  if (status === USER_STATUSES.invitationSent) {
    return 'invited';
  }

  return status;
};

/** @deprecated Use `ChooseUserStatusChip` instead. */
export const getUserStatusComponent = (status: Maybe<string> | undefined) => {
  return <ChooseUserStatusChip status={getStatus(status)} />;
};

const checkUserStatus = (status: string | undefined, statusesToCheck: UserStatuses[]): boolean => {
  if (!status) {
    return false;
  }

  return statusesToCheck.includes(status as UserStatuses);
};

type NormalizedMember = ReturnType<typeof normalizeMembers>[number];

function normalizeMembers(members: MemberInfoFragment[]) {
  return members.map(member => {
    const supportiveOrganizationsCount = member.ownedOrganizations?.count ?? 0;
    const roles = member.rolesAddons?.items ?? [];
    const rolesNames = roles.map(role => role.name ?? 'UNKNOWN').join(', ');
    const postCount = (member.groupPosts?.count ?? 0) + (member.homeFeedPosts?.count ?? 0);

    return {
      id: member.id as string,
      name: formatUserName(member),
      email: member.email,
      type: member.affiliation,
      gender: member.gender,
      phone: formatToPhone(member.userPreferences?.phoneNumber),
      birthDate: member?.birthDate ? formatTableDate(member.birthDate) : '',
      isSupporter: supportiveOrganizationsCount > 0 ? 'Yes' : 'No',
      posts: postCount,
      roles: rolesNames,
      createdAt: member.createdAt ? formatTableDate(member.createdAt) : '',
      status: member.userStatus ?? USER_STATUSES.pending,
    };
  });
}

function transformExport(members: MemberInfoFragment[]) {
  return members.map(member => {
    const supportiveOrganizationsCount = member.ownedOrganizations?.count ?? 0;
    const roles = member.rolesAddons?.items ?? [];
    const rolesNames = roles.map(role => role.name ?? 'UNKNOWN').join(', ');
    const postCount = (member.groupPosts?.count ?? 0) + (member.homeFeedPosts?.count ?? 0);

    return {
      [MembersReportHeaders.name]: formatUserName(member),
      [MembersReportHeaders.email]: member.email,
      [MembersReportHeaders.type]: member.affiliation,
      [MembersReportHeaders.gender]: member.gender,
      [MembersReportHeaders.birthDate]: member?.birthDate ? formatTableDate(member?.birthDate) : '',
      [MembersReportHeaders.supporter]: supportiveOrganizationsCount > 0 ? 'Yes' : 'No',
      [MembersReportHeaders.posts]: postCount,
      [MembersReportHeaders.roles]: rolesNames,
      [MembersReportHeaders.createdOn]: member.createdAt ? formatTableDate(member.createdAt) : '',
      [MembersReportHeaders.status]: member.userStatus ?? USER_STATUSES.pending,
    };
  });
}
