import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Form, FormTextField, Button, FormCheckbox } from 'shared/components/ui';
import { required, maxTextSymbols } from 'shared/utils/form';
import { OrganizationItemFragment } from 'shared/graphql';
import { FormSelectOrganization } from 'shared/components/form';
import { useCurrentUser } from 'shared/hooks';

import { useJobPostCreate } from '../../hooks';

const formBlockCSS = (theme: any) => css`
  display: grid;
  grid-row-gap: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(1)}px;
`;

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

export type CreateJobPostForm = {
  organization: OrganizationItemFragment | null;
  position?: string | undefined | null;
  description?: string | undefined | null;
  internship?: boolean;
};

export type CreateJobPostModalProps = {
  onModalClose: () => void;
};

export function CreateJobPostModal(props: CreateJobPostModalProps) {
  const { isAdmin } = useCurrentUser();
  const { onJobOfferCreate } = useJobPostCreate();

  const onSubmit = async (formData: CreateJobPostForm) => {
    await onJobOfferCreate(formData, formData.organization?.id);
    props.onModalClose();
  };

  return (
    <Form oldCss={formBlockCSS} onSubmit={onSubmit as any} validateOnBlur={false}>
      {form => (
        <Fragment>
          <Box display="grid" gridTemplateRows="auto" gridGap={20} my={2}>
            <FormSelectOrganization
              name="organization"
              validate={required}
              label="ORGANIZATION"
              placeholder="Select one organization"
              variant="outlined"
              onlyManaged={!isAdmin}
              onlyActive
            />

            <FormTextField
              inputProps={{
                label: 'JOB POSITION',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'position', validate: required }}
            />

            <FormCheckbox
              fieldProps={{ name: 'internship' }}
              checkboxProps={{ label: 'Is Internship' }}
            />

            <FormTextField
              inputProps={{
                color: 'primary',
                label: 'JOB DESCRIPTION',
                multiline: true,
                rows: '7',
                rowsMax: '7',
                variant: 'outlined',
              }}
              fieldProps={{
                name: 'description',
                validate: value => maxTextSymbols(value, 1000),
              }}
            />
          </Box>

          <Box display="flex" justifyContent="end">
            <Button
              css={cancelButtonCSS}
              onClick={props.onModalClose}
              disabled={form.isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>

            <Button
              loading={form.isSubmitting}
              css={submitButtonCSS}
              disableElevation
              variant="contained"
              type="submit"
            >
              CREATE JOB POST
            </Button>
          </Box>
        </Fragment>
      )}
    </Form>
  );
}
