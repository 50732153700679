import { generatePath } from 'react-router-dom';

import { MentionType } from '@jebel/constants';

import { ROUTES } from 'shared/constants';

export function generateMentionRedirect(type: unknown, id: string) {
  if (type === MentionType.User) {
    return generatePath(ROUTES.user.explorer.profile, { id });
  }

  if (type === MentionType.Group) {
    return generatePath(ROUTES.user.groups.details, { id });
  }

  if (type === MentionType.Organization) {
    return generatePath(ROUTES.user.organizations.organization, { id });
  }

  return ROUTES.user.explorer.index;
}

export function generateHashTagRedirect(display: string) {
  const url = new URL(ROUTES.user.search.posts, window.location.origin);

  url.searchParams.set('q', display);

  return url.toString();
}
