import React from 'react';
import { Grid } from '@mui/material';

import { DashboardCountPostWrapper } from '../DashboardCountWrappers/DashboardCountPostWrapper';
import { DashboardCountBusinessWrapper } from '../DashboardCountWrappers/DashboardCountBusinessWrapper';
import { DashboardCountSpecialOffersWrapper } from '../DashboardCountWrappers/DashboardCountSpecialOffersWrapper';

export function DashboardCountStats() {
  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs lg={6} xl>
        <DashboardCountPostWrapper />
      </Grid>
      <Grid item xs lg={6} xl>
        <DashboardCountBusinessWrapper />
      </Grid>
      <Grid item xs>
        <DashboardCountSpecialOffersWrapper />
      </Grid>
    </Grid>
  );
}
