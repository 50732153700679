import { ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { ListItemText, Box, List } from '@mui/material';

import { useAppAuth } from 'providers/useAppAuth';
import { NavListItemSymbol, ListItemIconSymbol } from 'shared/components/symbols';
import { Popover } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { useCurrentUser, useCurrentUserOrganizations, useResponsive } from 'shared/hooks';

import {
  HeaderNavigationUserProfile,
  HeaderNavigationUserProfileProps,
} from './HeaderNavigationUserProfile';

export interface HeaderNavigationPopoverProps extends HeaderNavigationUserProfileProps {
  isAdminRoute?: boolean;
  target: ReactNode;
}

const ADVERTISING_DOMAIN = process.env.REACT_APP_ADVERTISING_DOMAIN;
const CONTENT_CONTAINER_SIZE = 250;

const userProfileContainerCss = (theme: any) => css`
  padding: 15px 25px;
  border-bottom: 1px solid ${theme.palette.border};
  overflow: hidden;
  width: 100%;
`;

const commonNavItemCss = css`
  font-size: 16px;
  line-height: 26px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const navItemIconCss = css`
  width: 20px;
  height: 20px;
  margin-right: 1rem;
`;

const accountNavItemCss = (theme: any) => css`
  ${commonNavItemCss};
  color: ${theme.palette.text.secondary};
`;

const signOutNavItemCss = (theme: any) => css`
  ${commonNavItemCss};
  color: ${theme.palette.secondary.light};
`;

export function HeaderNavigationPopover({
  isAdminRoute,
  userLoading,
  target,
}: HeaderNavigationPopoverProps) {
  const { user, isAdmin: hasAdminRole } = useCurrentUser();
  const { isMobile } = useResponsive();
  const { data: organizations } = useCurrentUserOrganizations();
  const { logout } = useAppAuth();

  const switchToAdvertising = useCallback(() => {
    window.location.assign(`${ADVERTISING_DOMAIN}/dashboard?fromCommunity`);
  }, []);

  const showSwitchAdvertising = useMemo(() => {
    return organizations.length > 0 && !isMobile;
  }, [organizations, isMobile]);

  const handleLogout = () => {
    logout();
  };

  return (
    <Popover
      target={target}
      content={({ onClose }) => (
        <Box css={{ width: `${CONTENT_CONTAINER_SIZE}px` }}>
          <Box css={userProfileContainerCss}>
            <HeaderNavigationUserProfile userLoading={userLoading} user={user} isPopover />
          </Box>

          <List disablePadding>
            <NavListItemSymbol
              onClosePopover={onClose}
              button
              listItemText={<ListItemText>My Account</ListItemText>}
              startIcon={
                <ListItemIconSymbol
                  name="AccountCircle"
                  variant="filled"
                  color="inherit"
                  fontSize="small"
                  viewBox="0 0 24 24"
                  css={navItemIconCss}
                />
              }
              dense
              disableGutters
              divider
              path={APP_URL.user.settings.information}
              disableRipple={false}
              css={accountNavItemCss}
            />

            {hasAdminRole && (
              <NavListItemSymbol
                onClosePopover={onClose}
                button
                listItemText={
                  <ListItemText>Switch to {isAdminRoute ? 'Member' : 'Admin'}</ListItemText>
                }
                startIcon={<ListItemIconSymbol name="Settings" css={navItemIconCss} />}
                dense
                disableGutters
                divider
                path={isAdminRoute ? APP_URL.user.home.index : APP_URL.admin.dashboard.index}
                disableRipple={false}
                css={accountNavItemCss}
              />
            )}

            {showSwitchAdvertising && (
              <NavListItemSymbol
                onClick={switchToAdvertising}
                button
                listItemText={<ListItemText>Switch to Advertising</ListItemText>}
                startIcon={<ListItemIconSymbol name="Campaign" css={navItemIconCss} />}
                dense
                disableGutters
                divider
                disableRipple={false}
                css={accountNavItemCss}
              />
            )}
            <NavListItemSymbol
              button
              listItemText={<ListItemText onClick={handleLogout}>Sign Out</ListItemText>}
              startIcon={
                <ListItemIconSymbol
                  name="PowerSettingsNew"
                  variant="filled"
                  color="inherit"
                  fontSize="small"
                  viewBox="0 0 24 24"
                  css={navItemIconCss}
                />
              }
              dense
              disableGutters
              divider={false}
              path={APP_URL.public.login}
              css={signOutNavItemCss}
            />
          </List>
        </Box>
      )}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      elevation={4}
    />
  );
}
