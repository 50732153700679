import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Typography } from '@material-ui/core';

// Container

interface ContainerProps {
  disableElevation?: boolean;
  withDivider?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  overflow-x: hidden;
  user-select: none;

  ${p =>
    !p.disableElevation &&
    css`
      border-radius: 0.5rem;
      box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
    `}

  ${p =>
    p.withDivider &&
    css`
      border-bottom: solid 1px ${p.theme.palette.divider};
    `}

  ${p => p.theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;

// BusinessAvatar

export const BusinessAvatar = styled(Avatar)`
  --size: 5rem;

  width: var(--size);
  height: var(--size);
  cursor: pointer;
`;

BusinessAvatar.defaultProps = {
  variant: 'rounded',
};

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  gap: 0.25rem;
  flex: 1 1 auto;

  ${p => p.theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

// Header

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  overflow-x: hidden;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// Title

export const Title = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
  overflow: hidden;
  font-weight: bold;
  color: ${p => p.theme.palette.primary.dark};
  line-height: normal;
`;

// Description

export const Description = styled.span`
  display: block;
  color: ${p => p.theme.palette.text.hint};
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${p => p.theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;
