import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  MutationSchoolConfigurationLayoutUpdateArgs,
  SchoolConfigurationLayoutUpdateInput,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

const SCHOOL_CONFIGURATION_LAYOUT_ITEM_FRAGMENT = gql`
  fragment SchoolConfigurationLayoutItem on SchoolConfigurationLayout {
    id
    name
    icon
    isActive
    navbarOrderNumber
    sidebarOrderNumber
  }
`;

export const SCHOOL_CONFIGURATION_LAYOUT_UPDATE_MUTATION = gql`
  mutation SchoolConfigurationLayoutUpdate($data: SchoolConfigurationLayoutUpdateInput!) {
    schoolConfigurationLayoutUpdate(data: $data) {
      ...SchoolConfigurationLayoutItem
    }
  }
  ${SCHOOL_CONFIGURATION_LAYOUT_ITEM_FRAGMENT}
`;

export const useSchoolConfigurationLayoutUpdate = () => {
  const [schoolConfigurationLayoutUpdateMutation, options] = useMutation<
    SchoolConfigurationLayoutUpdateInput,
    MutationSchoolConfigurationLayoutUpdateArgs
  >(SCHOOL_CONFIGURATION_LAYOUT_UPDATE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
  });

  const schoolConfigurationLayoutUpdate = React.useCallback(
    async (schoolConfigurationLayout: SchoolConfigurationLayoutUpdateInput) => {
      try {
        await schoolConfigurationLayoutUpdateMutation({
          variables: {
            data: schoolConfigurationLayout,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [schoolConfigurationLayoutUpdateMutation],
  );

  return [schoolConfigurationLayoutUpdate, options] as const;
};
