import { useCallback, useState } from 'react';

import { Discount } from 'shared/graphql/__generated__';

export const useCurrentDiscount = (defaultState: any) => {
  const [currentRedeemedDiscount, setCurrentRedeemedDiscount] = useState(defaultState);

  const clearCurrentRedeemedDiscount = useCallback(() => {
    setCurrentRedeemedDiscount(undefined);
  }, []);

  const redeem = useCallback((discount?: Discount) => {
    setCurrentRedeemedDiscount(discount);
  }, []);

  return {
    currentRedeemedDiscount,
    clearCurrentRedeemedDiscount,
    redeem,
  };
};
