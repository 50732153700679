import React from 'react';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';

import {
  LegacyFeedPostsListQuery,
  LegacyFeedPostsListCountQuery,
  LegacyFeedPostsListQueryVariables,
} from 'shared/graphql/__generated__';
import { PAGE_SIZE } from 'shared/constants';
import { usePostsList } from 'shared/features/posts/hooks';
import { useSearchContext } from 'shared/features/search';
import { getLegacyFeedPostsSearchFilter } from 'shared/utils/search';

import { LEGACY_FEED_POSTS_LIST_QUERY, LEGACY_FEED_POSTS_LIST_COUNT_QUERY } from '../queries';
import { LegacyFilterType } from '../types';
import { getPinnedPosts } from '../../../shared/features/posts';
import { useGetPinnedPosts } from '../../../shared/features/posts/hooks/useGetPinnedPosts';
import { LEGACY_POST_TYPE } from '../../../shared/features/posts/constants';

const transformQueryData = (
  listsData: LegacyFeedPostsListQuery | undefined,
  countData: LegacyFeedPostsListCountQuery | undefined,
  pinnedPostsList: { count: number; pinnedPostsList: { items: any[] | undefined } },
) => {
  const pinnedPostsCount = Number(pinnedPostsList?.count) || 0;
  const pinnedPostItems: any[] | undefined = pinnedPostsList?.pinnedPostsList?.items;
  let pinnedPosts: any[] = [];
  if (pinnedPostItems?.length)
    pinnedPosts = pinnedPostItems
      .map(item => {
        if (item?.legacyPost) {
          return { ...item?.legacyPost, postType: LEGACY_POST_TYPE };
        }

        return [];
      })
      .filter(Boolean);
  const posts = listsData?.legacyFeedPostsList.items || [];
  const count = (countData?.legacyFeedPostsList.count || 0) + pinnedPostsCount;
  const hasMore = posts.length < count;

  return { items: R.uniqBy(item => item.id, [...pinnedPosts, ...posts]), count, hasMore };
};

const FIELD_NAME = 'legacyFeedPostsList';

/** @deprecated Use `useLegacyFeed` instead. */
export const useLegacyFeedPostsQuery = (
  manualFilter: LegacyFilterType = {},
  customFilter?: { startDate: string; endDate: string },
) => {
  const { year, month } = useParams<{ year: string | undefined; month: string | undefined }>();
  const { searchQuery, sortOption, filter: rawSearchFilter } = useSearchContext();
  const searchFilter = typeof rawSearchFilter !== 'object' ? {} : rawSearchFilter;

  const {
    legacyEndDate,
    legacyStartDate,
    legacyDate,
    authorId,
    year: selectedYear,
    month: selectedMonth,
  } = {
    ...manualFilter,
    ...searchFilter,
  } as LegacyFilterType;

  const postsFilter: LegacyFeedPostsListQueryVariables['postsFilter'] =
    getLegacyFeedPostsSearchFilter(
      searchQuery,
      false,
      year,
      month,
      legacyStartDate,
      legacyEndDate,
      authorId,
    );

  const filter = React.useMemo(() => {
    if (customFilter) {
      return {
        postsFilter: {
          AND: [
            { createdAt: { gte: customFilter?.startDate } },
            { createdAt: { lte: customFilter?.endDate } },
          ],
        },
        pinnedPostsFilter: {},
      };
    }
    if (legacyDate) {
      return {
        postsFilter: {
          ...postsFilter,
          legacyDate: {
            equals: DateTime.fromISO(legacyDate).toFormat('yyyy-MM-dd'),
          },
        },
      };
    }

    return { postsFilter };
  }, [customFilter, legacyDate, postsFilter]);

  const { listsData, countData, pinnedPostData, ...rest } = usePostsList<
    LegacyFeedPostsListQuery,
    LegacyFeedPostsListCountQuery
  >({
    listQuery: LEGACY_FEED_POSTS_LIST_QUERY,
    countQuery: LEGACY_FEED_POSTS_LIST_COUNT_QUERY,
    variables: {
      ...filter,
      first: !customFilter ? PAGE_SIZE : PAGE_SIZE * 10,
      sort: sortOption,
      skip: 0,
    },
    itemsFieldName: FIELD_NAME,
  });
  const data = React.useMemo(
    () => transformQueryData(listsData, countData, pinnedPostData),
    [countData, listsData, pinnedPostData],
  );

  return {
    ...data,
    ...rest,
  };
};
