import { ApolloCache, DataProxy } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';

import { HomeFeedPostsCountQuery, HomeFeedPostsCountQueryVariables } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

import { CommentEnrichedReaction } from '../../../types';
import { HOME_POST_TYPE, LEGACY_POST_TYPE, SCHOOL_POST_TYPE } from '../constants';

export function updatePostsCountCache<TData = any>({
  cache,
  fieldName,
  options,
}: {
  cache: ApolloCache<TData>;
  fieldName: string;
  options: DataProxy.ReadQueryOptions<TData, any>;
}) {
  try {
    const currentPostsCountCache = cache.readQuery<
      HomeFeedPostsCountQuery,
      HomeFeedPostsCountQueryVariables
    >(options);

    const currentPostsCountPath = [fieldName, 'count'];
    const defaultItemsCount = 0;

    const currentPostsCount = R.pathOr(
      defaultItemsCount,
      currentPostsCountPath,
      currentPostsCountCache,
    );

    const newPostsItemsCount = currentPostsCount + 1;

    const newPostsCache = R.assocPath(
      currentPostsCountPath,
      newPostsItemsCount,
      currentPostsCountCache,
    );

    if (!R.isNil(newPostsCache)) {
      cache.writeQuery<HomeFeedPostsCountQuery, HomeFeedPostsCountQueryVariables>({
        ...options,
        data: newPostsCache,
      });
    }
  } catch (e: any) {
    sendToSentry(e);
  }
}

export function updatePostsListCache<TData = any>({
  cache,
  data,
  fieldName,
  mutationFieldName,
  options,
}: {
  cache: ApolloCache<TData>;
  data: TData | undefined | null;
  fieldName: string;
  mutationFieldName: string;
  options: DataProxy.ReadQueryOptions<TData, any>;
}) {
  try {
    if (!data?.[mutationFieldName]?.id) {
      return;
    }

    const currentPostsListCache = cache.readQuery<TData, any>(options);
    const currentPostsListPath = [fieldName, 'items'];
    const currentPostsList = R.pathOr([], currentPostsListPath, currentPostsListCache);
    const newPostsItemsList = [data[mutationFieldName], ...currentPostsList];

    const newPostsCache = R.assocPath(
      currentPostsListPath,
      newPostsItemsList,
      currentPostsListCache,
    );

    cache.writeQuery({
      ...options,
      data: newPostsCache,
    });
  } catch (e: any) {
    sendToSentry(e);
  }
}

export type UpdatePostsCacheProps<TData = any> = {
  cache: ApolloCache<TData>;
  data: TData | undefined | null;
  fieldName: string;
  listOptions: DataProxy.ReadQueryOptions<TData, any>;
  countOptions: DataProxy.ReadQueryOptions<TData, any>;
  mutationFieldName: string;
};

export function updatePostsCache<TData = any>({
  listOptions,
  countOptions,
  ...rest
}: UpdatePostsCacheProps<TData>) {
  updatePostsListCache<TData>({
    options: listOptions,
    ...rest,
  });
  updatePostsCountCache<TData>({
    options: countOptions,
    ...rest,
  });
}

export const combineDateAndTime = (
  date?: DateTime | null | undefined,
  time?: DateTime | null | undefined,
) => {
  if (!date || !time) return;

  const { hour, minute } = time.toObject();
  const dateWithTime = date.set({ hour, minute });

  return dateWithTime;
};

export const transformDeletedCommentsState =
  (commentId: string) => (reaction: CommentEnrichedReaction) => ({
    ...reaction,
    data: {
      ...reaction.data,
      deleted: reaction.id === commentId || !!reaction.data.deleted,
    },
  });

/** @deprecated */
export const getPinnedPosts = (pinnedPostItems: any[]) => {
  if (pinnedPostItems?.length) {
    return pinnedPostItems
      .map(item => {
        if (item?.homePost) {
          return { ...item?.homePost, postType: HOME_POST_TYPE };
        }
        if (item?.legacyPost) {
          return { ...item?.legacyPost, postType: LEGACY_POST_TYPE };
        }
        if (item?.schoolPost) {
          return { ...item?.schoolPost, postType: SCHOOL_POST_TYPE };
        }
        return null;
      })
      .filter(Boolean);
  }
  return [];
};
