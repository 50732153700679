import { useCallback, useMemo } from 'react';
import { Box, Chip, Tooltip, Typography } from '@material-ui/core';
import { ThumbDownOutlined, ThumbUpOutlined } from '@material-ui/icons';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';

import { GroupUserRequestStatus } from '@jebel/constants';

import { GroupKeyFilter, useMemberCommunitySidebarItemsLazyQuery } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { ExplorerUserCard } from 'features/explorer/components';
import {
  NormalizedGroupMember,
  useGroupMemberApprove,
  useGroupMemberDemote,
  useGroupMemberPromote,
  useGroupMemberReject,
  useGroupMembers,
} from 'features/groups/hooks';
import { AsyncContent, ChooseStatusChipOption } from 'shared/components/ui';

import { GroupMemberStatus } from './GroupMemberStatus';

interface Props {
  group: GroupKeyFilter | undefined | null;

  /**
   * Whether to show only active members.
   * @default false
   */
  onlyActive?: boolean;

  /**
   * Whether to show actions for the group members.
   * @default false
   */
  withActions?: boolean;
}

export function GroupMembersList(props: Props) {
  const {
    data: members,
    isLoading: isFetching,
    isCurrentUserAdmin,
  } = useGroupMembers(props.group?.id);

  const { user: currentUser } = useCurrentUser();
  const { action: rejectMember } = useGroupMemberReject({ group: props.group });
  const { action: approveMember } = useGroupMemberApprove({ group: props.group });
  const { action: promoteMember } = useGroupMemberPromote({ group: props.group });
  const { action: demoteMember } = useGroupMemberDemote({ group: props.group });

  const withActions = props.withActions ?? false;
  const showActions = withActions || isCurrentUserAdmin;

  const filteredMembers = useMemo(() => {
    if (props.onlyActive) {
      return members.filter(member => member.status === GroupUserRequestStatus.Approved);
    }

    return members;
  }, [members, props.onlyActive]);

  const curryStatusOptions = useCallback(
    (member: NormalizedGroupMember) => {
      const options: ChooseStatusChipOption[] = [];

      if (member.user.id === currentUser?.id) {
        // Do not show actions for the current user.
        return options;
      }

      if (
        member.status === GroupUserRequestStatus.Pending ||
        member.status === GroupUserRequestStatus.Rejected
      ) {
        options.push({
          label: 'Approve',
          value: 'approve',
          icon: <ThumbUpOutlined />,

          async onClick() {
            await approveMember(member);
          },
        });
      }

      if (
        member.status === GroupUserRequestStatus.Pending ||
        member.status === GroupUserRequestStatus.Approved
      ) {
        options.push({
          label: 'Reject',
          value: 'reject',
          icon: <ThumbDownOutlined />,

          async onClick() {
            await rejectMember(member);
          },
        });
      }

      if (member.status === GroupUserRequestStatus.Approved && !member.isAdmin) {
        options.push({
          label: 'Promote to Admin',
          value: 'promote',
          icon: <ArrowCircleUp />,

          async onClick() {
            await promoteMember(member);
          },
        });
      }

      if (member.status === GroupUserRequestStatus.Approved && member.isAdmin) {
        options.push({
          label: 'Demote from Admin',
          value: 'demote',
          icon: <ArrowCircleDown />,

          async onClick() {
            await demoteMember(member);
          },
        });
      }

      return options;
    },
    [approveMember, currentUser, demoteMember, promoteMember, rejectMember],
  );

  return (
    <AsyncContent loading={isFetching}>
      <Box display="flex" flexDirection="column">
        {filteredMembers.map(member => (
          <ExplorerUserCard
            key={member.id}
            data={member.user}
            withDivider
            addons={
              <Box display="flex" alignItems="center" gridGap="0.5rem">
                {member.user?.id === currentUser?.id && (
                  <Tooltip title="You can't perform actions on yourself">
                    <Chip label="You" color="primary" />
                  </Tooltip>
                )}

                {member.isAdmin && <Chip label="Admin" color="secondary" />}

                {showActions && (
                  <GroupMemberStatus status={member.status} options={curryStatusOptions(member)} />
                )}
              </Box>
            }
          />
        ))}

        {members.length === 0 && <Typography align="center">No members found</Typography>}
      </Box>
    </AsyncContent>
  );
}
