import { ROUTES } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { SettingsPage } from './pages/SettingsPage';

export const getSettingsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.settings.index,
    component: SettingsPage,
  },
];
