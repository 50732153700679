import { ApolloCache, DataProxy } from '@apollo/client';
import * as R from 'ramda';

import { DiscountStatuses, DISCOUNT_STATUSES } from '@jebel/constants';
import { currentDateTime, isBetween, isPastDate } from '@jebel/utils';

import { sendToSentry } from 'shared/utils/sentry';

export function updateDiscountsCache<TData = any>({
  cache,
  data,
  fieldName,
  mutationFieldName,
  options,
}: {
  cache: ApolloCache<TData>;
  data: TData | undefined | null;
  fieldName: string;
  mutationFieldName: string;
  options: DataProxy.ReadQueryOptions<TData, any>;
}) {
  try {
    if (!data?.[mutationFieldName]?.id) {
      return;
    }

    const currentDiscountsCache = cache.readQuery<TData, any>(options);
    const currentDiscountsPath = [fieldName, 'items'];
    const currentDiscounts = R.pathOr([], currentDiscountsPath, currentDiscountsCache);
    const newDiscounts = [data[mutationFieldName], ...currentDiscounts];

    const newDiscountsCache = R.assocPath(
      currentDiscountsPath,
      newDiscounts,
      currentDiscountsCache,
    );

    cache.writeQuery({
      ...options,
      data: newDiscountsCache,
    });
  } catch (e: any) {
    sendToSentry(e);
  }
}

export const getDiscountStatus = (startDate: string, expirationDate: string): DiscountStatuses => {
  const isExpired = isPastDate(expirationDate);
  const isActive = isBetween(currentDateTime(), { start: startDate, end: expirationDate });

  if (isActive) {
    return DISCOUNT_STATUSES.active;
  }

  if (isExpired) {
    return DISCOUNT_STATUSES.expired;
  }

  return DISCOUNT_STATUSES.pending;
};
