import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

import { useStreamActivityContext } from 'shared/features/posts';
import { CommentEnrichedReaction } from 'shared/types';

import { Container, Reactions } from './HomeFeedCardReactions.styles';
import { HomeFeedCardLikeButton } from './HomeFeedCardLikeButton';
import { HomeFeedCardCommentButton } from './HomeFeedCardCommentButton';
import { HomeFeedCardComments } from './HomeFeedCardComments';
import { CAN_ADD_HOME_POSTS, useCurrentUserPermissionSelector } from 'shared/hooks';

interface Props {
  /**
   * Shows the available comments for the post.
   * Can be overridden by [the user permissions](https://github.com/jebelapp/jebel/issues/1528#issuecomment-2329497938).
   */
  withComments: boolean;
  /**
   * Shows the available reactions for the post.
   * Can be overridden by [the user permissions](https://github.com/jebelapp/jebel/issues/1528#issuecomment-2329497938).
   */
  withLikes: boolean;
}

export function HomeFeedCardReactions(props: Props) {
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState<CommentEnrichedReaction[]>([]);

  const { data: activity, loading } = useStreamActivityContext();

  // https://github.com/jebelapp/jebel/issues/1528#issuecomment-2329497938
  const hasPermission = useCurrentUserPermissionSelector(CAN_ADD_HOME_POSTS);

  const showSectionComments = hasPermission && props.withComments && showComments;
  const showSectionLikes = hasPermission && props.withLikes;

  const showSections = showComments || showSectionLikes;

  useEffect(() => {
    const comments = activity?.latest_reactions?.comment ?? [];
    setComments(comments);
  }, [activity]);

  const toggleComments = () => {
    setShowComments(prev => !prev);
  };

  if (loading) {
    return (
      <Container>
        <Reactions>
          <Skeleton variant="rectangular" height="2rem" width="4rem" />
          <Skeleton variant="rectangular" height="2rem" width="4rem" />
        </Reactions>
      </Container>
    );
  }

  if (!showSections || !activity?.id) {
    return null;
  }

  return (
    <Container>
      <Reactions>
        {props.withLikes && <HomeFeedCardLikeButton />}

        {props.withComments && (
          <HomeFeedCardCommentButton comments={comments} onClick={toggleComments} />
        )}
      </Reactions>

      {showSectionComments && (
        <HomeFeedCardComments comments={comments} onCommentsChange={setComments} />
      )}
    </Container>
  );
}
