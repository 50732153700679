import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Divider } from '@mui/material';

import {
  OnCommentAdd,
  OnCommentRemove,
  OnLikeAdd,
  OnReactionRemove,
} from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { Icon, Typography } from 'shared/components/ui';
import { MentionPosition } from 'shared/features/mentions/types';

import { usePosts } from '../../providers';
import { Comment, CommentButton, CommentForm, CommentImage } from '../Comment';
import { LikeButton } from '../LikeButton';
import { ViewMoreButton } from '../ViewMoreButton';

const DIVIDER_CONTAINER_WIDTH = 24;

const dotSpaceCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${DIVIDER_CONTAINER_WIDTH}px;
`;

const DIVIDER_SIZE = 4;

const dividerCss = theme => css`
  height: ${DIVIDER_SIZE}px;
  width: ${DIVIDER_SIZE}px;
  border-radius: ${DIVIDER_SIZE / 2}px;
  background-color: ${theme.palette.primary.light};
`;

const likesAndCommentsCSS = css`
  display: flex;
  flex-direction: row;
`;

const SHARE_ICON_SIZE = 20;

const shareIconCss = css`
  height: ${SHARE_ICON_SIZE}px;
  width: ${SHARE_ICON_SIZE}px;
`;

const shareButtonCss = css`
  min-width: 0px;
`;

export type PostStreamInteractionsProps = {
  id: string;
  onCommentAdd?: OnCommentAdd;
  onCommentRemove?: OnCommentRemove;
  onLikeAdd?: OnLikeAdd;
  onReactionRemove?: OnReactionRemove;
  commentsAllowed: boolean;
  withProfilePostSight?: boolean | undefined | null;
  disableComments?: boolean;
};

export function PostStreamInteractions({
  id,
  onCommentAdd,
  onCommentRemove,
  onLikeAdd,
  onReactionRemove,
  commentsAllowed,
  withProfilePostSight = false,
  disableComments = false,
}: PostStreamInteractionsProps) {
  const [viewAllComments, setViewAllComments] = useState(false);
  const [displayTextField, setDisplayTextField] = useState(false);
  const [comments, setComments] = useState<CommentEnrichedReaction[]>([]);

  const { activity } = usePosts({ postId: id });

  const textFieldRef = useRef<HTMLInputElement>(null);
  const commentsAmount = comments.length - 1;

  const postAuthorId = useMemo(() => activity?.actor.toString().split(':')[1], [activity?.actor]);

  const onCommentPost = useCallback(
    async (text: string, media: CommentImage[], mentions: MentionPosition[]) => {
      if (!activity?.id) {
        return;
      }

      if (typeof onCommentAdd === 'function') {
        const comment = await onCommentAdd({
          activityId: activity?.id,
          foreignId: id,
          media,
          text,
          parentEntityAuthorId: postAuthorId,
          parentEntityType: 'post',
          mentions,
        });

        if (!comment) {
          return;
        }

        setComments(prevComments => [...prevComments, comment]);
        setViewAllComments(true);
      }
    },
    [activity?.id, id, onCommentAdd, postAuthorId],
  );

  const onCommentIconClick = useCallback(() => {
    setDisplayTextField(true);
    setViewAllComments(true);

    if (textFieldRef.current && 'scrollIntoView' in textFieldRef.current) {
      textFieldRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    if (textFieldRef.current?.focus) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const newComments = activity?.latest_reactions?.comment || [];

    if (newComments.length) {
      setDisplayTextField(true);
    }

    setComments(newComments);
  }, [activity?.latest_reactions?.comment]);

  if (!activity?.id) {
    return null;
  }

  if (!commentsAllowed && onLikeAdd && onReactionRemove) {
    return (
      <Box css={likesAndCommentsCSS}>
        <LikeButton
          onLikeAdd={onLikeAdd}
          activityId={activity?.id}
          onReactionRemove={onReactionRemove}
          likeInfo={activity?.latest_reactions?.like || []}
          parentEntityAuthorId={postAuthorId}
          foreignId={id}
        />
      </Box>
    );
  }

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box css={likesAndCommentsCSS}>
          {onLikeAdd && onReactionRemove && (
            <LikeButton
              onLikeAdd={onLikeAdd}
              activityId={activity?.id}
              onReactionRemove={onReactionRemove}
              likeInfo={activity?.latest_reactions?.like || []}
              withProfilePostSight={withProfilePostSight}
              parentEntityAuthorId={postAuthorId}
              foreignId={id}
            />
          )}

          <Box css={dotSpaceCSS}>
            <Divider css={dividerCss} />
          </Box>

          <CommentButton
            onComment={onCommentIconClick}
            commentsAmount={comments.length}
            isIcon
            setComments={setComments}
            disableComments={disableComments}
          />

          {withProfilePostSight && (
            <Fragment>
              <Box css={dotSpaceCSS}>
                <Divider css={dividerCss} />
              </Box>

              <Box display="flex" alignItems="center" justifyContent="center">
                <Button color="primary" css={shareButtonCss}>
                  <Icon css={shareIconCss} name="Share" variant="filled" htmlColor="#295f7b" />
                  <Typography css={{ textTransform: 'none' }} paragraph={false}>
                    Share
                  </Typography>
                </Button>
              </Box>
            </Fragment>
          )}
        </Box>

        {withProfilePostSight && (
          <Box display="flex" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Icon name="ThumbUp" variant="filled" htmlColor="#9B9B9B" />
              <Typography css={{ color: '#9B9B9B', marginLeft: '16px' }} paragraph={false}>
                {activity?.latest_reactions?.like?.length || 0}
              </Typography>
            </Box>
            <Box css={dotSpaceCSS}>
              <Divider css={dividerCss} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Icon name="ModeComment" variant="filled" htmlColor="#9B9B9B" />
              <Typography css={{ color: '#9B9B9B', marginLeft: '16px' }} paragraph={false}>
                {comments.length || 0}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {(!!comments.length || displayTextField) && (
        <Box my={2}>
          <Divider />
        </Box>
      )}

      {!!comments[0] && onCommentAdd && onCommentRemove && onReactionRemove && onLikeAdd && (
        <Box mb={2}>
          <Comment
            comment={comments[0]}
            onCommentAdd={onCommentAdd}
            onCommentRemove={onCommentRemove}
            onReactionRemove={onReactionRemove}
            onLikeAdd={onLikeAdd}
            commentId={comments[0]?.id ?? ''}
            setComments={setComments}
            postId={id ?? ''}
            disableComments={disableComments}
          />
        </Box>
      )}

      {viewAllComments &&
        onCommentAdd &&
        onCommentRemove &&
        onLikeAdd &&
        onReactionRemove &&
        comments.slice(1).map(comment => (
          <Box key={comment.id} mb={4}>
            <Comment
              comment={comment}
              onCommentAdd={onCommentAdd}
              onCommentRemove={onCommentRemove}
              onLikeAdd={onLikeAdd}
              onReactionRemove={onReactionRemove}
              commentId={comment.id}
              setComments={setComments}
              postId={id ?? ''}
              disableComments={disableComments}
            />
          </Box>
        ))}

      {!viewAllComments && comments.length > 1 && (
        <ViewMoreButton
          onViewMore={() => setViewAllComments(true)}
          type="comment"
          amount={commentsAmount}
        />
      )}

      {displayTextField && !disableComments ? (
        <CommentForm type="comment" fieldRef={textFieldRef} onCommentPost={onCommentPost} />
      ) : null}
    </Fragment>
  );
}
