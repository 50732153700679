import { useMemo } from 'react';

import { SelectOption } from '@jebel/constants';

import {
  AudienceGraduatingYearsOptionsQuery,
  useAudienceGraduatingYearsOptionsQuery,
} from 'shared/graphql';

export type AudienceGraduatingYearsOption =
  AudienceGraduatingYearsOptionsQuery['options']['groups'][number];

/**
 * List all the audience graduating years options available.
 * This hook filter the graduating years to have at least one user.
 */
export function useAudienceGraduatingYearsOptions() {
  const { data: response, loading } = useAudienceGraduatingYearsOptionsQuery({
    errorPolicy: 'ignore',
  });

  const data = useMemo(() => {
    const groups = response?.options.groups ?? [];

    return groups.map<SelectOption>(group => {
      return {
        id: String(group.year),
        label: `Class of ${group.year}`,
        value: String(group.year),
      };
    });
  }, [response]);

  return {
    data,
    loading,
  };
}
