import { Box, Tab, Tabs } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Icon } from 'shared/components/ui';

type SettingsMobileNavbarListItemProps = {
  title: string;
  icon: string;
  route: string;
};

interface Props {
  listData: SettingsMobileNavbarListItemProps[];
}

export function SettingsMobileNavbarList(props: Props) {
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const handleChangeRoute = (_event: unknown, route: string) => {
    navigate(route);
  };

  return (
    <Box display="flex">
      <Tabs value={pathname} variant="scrollable" scrollButtons="auto" onChange={handleChangeRoute}>
        {props.listData.map(item => (
          <Tab
            key={item.route}
            icon={<Icon name={item.icon} />}
            value={item.route}
            label={item.title}
            wrapped
          />
        ))}
      </Tabs>
    </Box>
  );
}
