import React from 'react';
import { css } from '@emotion/react';
import { Typography, Button } from '@material-ui/core';
import pluralize from 'pluralize';

const buttonCss = theme => css`
  height: 24px;
  color: ${theme.palette.secondary.light};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.09px;
  line-height: 24px;
  text-transform: none;
`;

const viewTextCss = theme => css`
  ${theme.breakpoints.down('sm')} {
    font-size: 0.95rem;
  }
  ${theme.breakpoints.down('xs')} {
    font-size: 0.85rem;
    font-weight: 500;
  }
`;

export type ViewMoreButtonProps = {
  onViewMore: () => void;
  type: 'comment' | 'reply';
  amount: number;
};

export const ViewMoreButton = ({ onViewMore, type, amount }: ViewMoreButtonProps) => {
  return (
    <Button css={buttonCss} onClick={onViewMore}>
      <Typography css={viewTextCss}>
        View {amount} more {pluralize(type, amount)}
      </Typography>
    </Button>
  );
};
