import { FieldValidator } from 'formik';

import { SORT } from '@jebel/constants';

import { MinimalUserFragment, UserSort, useMinimalUsersListLazyQuery } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { isValidEmail } from 'shared/utils/form';
import { createUserFilter } from 'features/search/utils';

import {
  FormSelectDataSource,
  FormSelectDataSourceOption,
  FormSelectDataSourceOptionText,
  FormSelectDataSourceDefinitionProps,
  FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT,
} from '../FormSelectDataSource';

type BasicProps = FormSelectDataSourceDefinitionProps<MinimalUserFragment>;

type Props = BasicProps & {
  /**
   * Filter the organizations by "status".
   * @default false
   */
  onlyActive?: boolean;
};

export function FormSelectUser(props: Props) {
  const [fetchUsers] = useMinimalUsersListLazyQuery();

  const fetchOptions = async (search: string) => {
    const isSuggestions = search.length === 0;

    const sort: UserSort[] = [{ firstName: SORT.asc }, { lastName: SORT.asc }, { email: SORT.asc }];

    const filter = createUserFilter({ search, onlyActive: props.onlyActive });
    const first = isSuggestions ? FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT : undefined;

    const response = await fetchUsers({ variables: { first, filter, sort } });

    return response.data?.users.items ?? [];
  };

  const extractIdentifier = (option: MinimalUserFragment) => {
    return option.id as string;
  };

  const extractLabel = (option: MinimalUserFragment) => {
    return formatUserName(option);
  };

  return (
    <FormSelectDataSource<MinimalUserFragment>
      {...props}
      validate={validateField(props.validate)}
      extractIdentifier={extractIdentifier}
      extractLabel={extractLabel}
      fetchOptions={fetchOptions}
    >
      {(params, option) => (
        <FormSelectDataSourceOption {...params} key={option?.id}>
          <FormSelectDataSourceOptionText>{formatUserName(option)}</FormSelectDataSourceOptionText>
          <FormSelectDataSourceOptionText isSmall>{option?.email}</FormSelectDataSourceOptionText>
        </FormSelectDataSourceOption>
      )}
    </FormSelectDataSource>
  );
}

function validateField(validate: FieldValidator | undefined) {
  type Value = string | MinimalUserFragment;

  return (value: Value | Value[]) => {
    const issues: string[] = [];

    if (typeof value === 'string' && !isValidEmail(value)) {
      return validate?.(value);
    }

    if (Array.isArray(value)) {
      for (const option of value) {
        const email = typeof option === 'string' ? option : (option.email as string);
        const output = isValidEmail(email);

        if (typeof output === 'string') {
          issues.push(`"${email}" is not a valid email format`);
        }
      }

      if (issues.length > 0) {
        return issues.join(', ');
      }
    }

    return validate?.(value);
  };
}
