import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  SendPostReactionNotificationMutation,
  SendPostReactionNotificationMutationVariables,
  SendPostReactionNotificationRequestInput,
} from 'shared/graphql/__generated__';

import { useCurrentUser } from '../../../hooks';

const SEND_POST_REACTION_NOTIFICATION_MUTATION = gql`
  mutation SendPostReactionNotification($data: SendPostReactionNotificationRequestInput!) {
    sendPostReactionNotificationRequest(data: $data) {
      success
    }
  }
`;

export const usePostReactionsNotifications = () => {
  const { userId } = useCurrentUser();

  const [sendNotification] = useMutation<
    SendPostReactionNotificationMutation,
    SendPostReactionNotificationMutationVariables
  >(SEND_POST_REACTION_NOTIFICATION_MUTATION);

  const sendPostReactionNotification = React.useCallback(
    async (data: SendPostReactionNotificationRequestInput & { postId?: string | undefined }) => {
      if (userId === data.authorId) return;

      if (!data.type || !data.postId) return;

      await sendNotification({ variables: { data } });
    },
    [sendNotification, userId],
  );

  return { sendPostReactionNotification };
};
