import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Container

export const Container = styled.div``;

// ListOptionContainer

export const ListOptionContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: ${p => p.theme.palette.background.paper};

  &:active,
  &:focus,
  &:hover {
    background-color: ${p => p.theme.palette.grey[100]};
  }
`;

// ListOptionText

interface ListOptionTextProps {
  isSmall?: boolean;
}

export const ListOptionText = styled.span<ListOptionTextProps>`
  line-height: initial;
  user-select: none;

  ${p =>
    p.isSmall &&
    css`
      font-size: ${p.theme.typography.body2.fontSize};
      color: ${p.theme.palette.grey[500]};
    `}
`;
