import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  OrganizationContactMutation,
  OrganizationContactMutationVariables,
} from 'shared/graphql/__generated__';

import { ORGANIZATION_CONTACT_MUTATION } from '../queries';
import { useUpdateIncrementJobOffer } from './useIncrementJobPost';

export const useContactOrganization = () => {
  const [contactOrganization, { loading }] = useMutation<
    OrganizationContactMutation,
    OrganizationContactMutationVariables
  >(ORGANIZATION_CONTACT_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success - Your contact information has been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been sent. Try again.`,
    },
  });
  const incrementJobOffer = useUpdateIncrementJobOffer();

  const onContactOrganization = React.useCallback(
    async (subject: string, text: string, userIds?: string | string[], jobId?: string) => {
      const contactRes = await contactOrganization({
        variables: {
          subject,
          text,
          userIds: userIds || [],
        },
      });

      if (jobId) {
        const response = await incrementJobOffer(jobId);
      }
      return contactRes;
    },

    [contactOrganization],
  );

  return { onContactOrganization, loading };
};
