import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';

import {
  OrganizationItemFragment,
  OrganizationLikeKeyFilter,
  useOrganizationCreateLikeMutation,
  useOrganizationDeleteLikeMutation,
} from 'shared/graphql';
import { useCurrentUser, useToast } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

interface Props {
  organization: OrganizationItemFragment;

  children(context: { count: number; hasUserLike: boolean; onToggle(): void }): ReactNode;
}

export function OrganizationLikeWrapper(props: Props) {
  const [hasUserLike, setHasUserLike] = useState(false);
  const [userLike, setUserLike] = useState<OrganizationLikeKeyFilter>();

  const { user } = useCurrentUser();
  const { showError, showWarning } = useToast();

  const organizationLikes = props.organization.likesCount ?? 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userLikes = props.organization.userLikes?.items ?? [];

  const [createLikeMutation] = useOrganizationCreateLikeMutation();
  const [removeLikeMutation] = useOrganizationDeleteLikeMutation();

  useEffect(() => {
    const userLike = userLikes.find(curr => curr.user?.id === user?.id);

    if (userLike) {
      setUserLike(userLike);
      setHasUserLike(true);
    }
  }, [userLikes, user?.id]);

  const likesCount = useMemo(() => {
    return hasUserLike ? organizationLikes + 1 : organizationLikes;
  }, [organizationLikes, hasUserLike]);

  /** Allow to remove the like when there is one selected. */
  const removeLike = async () => {
    if (!userLike?.id) {
      return;
    }

    await removeLikeMutation({ variables: { likeId: userLike.id } });

    setUserLike(undefined);
  };

  /** Allow to create the like when there is no one. */
  const createLike = async () => {
    if (!props.organization?.id || !user?.id) {
      return;
    }

    const response = await createLikeMutation({
      variables: {
        organizationId: props.organization.id,
        userId: user.id,
      },
    });

    const like = response.data?.organizationLikeCreate;

    if (like) {
      setUserLike(like);
    }
  };

  /** Create or remove the like in the database. */
  const syncronizeUserLike = async (hasUserLike: boolean) => {
    try {
      if (hasUserLike === false) {
        await removeLike();
        return;
      }

      await createLike();
    } catch (err) {
      showWarning(`Your action was not saved, please try again.`);
      sendToSentry(err);
      setHasUserLike(!hasUserLike);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const toggleLike = () => {
    setHasUserLike(prev => {
      const curr = !prev;

      syncronizeUserLike(curr);
      return curr;
    });
  };

  return (
    <Fragment>{props.children({ count: likesCount, hasUserLike, onToggle: toggleLike })}</Fragment>
  );
}
