import { POST_STATUSES } from '@jebel/constants';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { GroupPostCreateInput, useGroupPostCreateMutation } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

export function useGroupPostCreate(groupId: string) {
  const { userId } = useCurrentUser();

  const [mutate, { loading }] = useGroupPostCreateMutation({
    refetchQueries: ['GroupsList', 'HomeFeedItems'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: `Your post will be moderated, we'll let you know when it's available on the feed.`,
      [SNACKBAR_ERROR_MESSAGE]: 'Something went wrong creating your post, please try again.',
    },
  });

  const create = async (data: GroupPostCreateInput, isScheduled = false) => {
    if (!userId) {
      return;
    }

    const response = await mutate({
      variables: {
        data: {
          // https://8base-dev.atlassian.net/browse/JEB-1553
          status: POST_STATUSES.pending,
          group: {
            connect: {
              id: groupId,
            },
          },
          author: {
            connect: {
              id: userId,
            },
          },
          ...data,
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return response;
  };

  return {
    create,
    loading,
  };
}
