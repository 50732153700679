import { useMutation } from '@apollo/client';

import { useCurrentUser } from 'shared/hooks';
import {
  CollectiveIntelligenceThreadUpdateMutation,
  CollectiveIntelligenceThreadUpdateMutationVariables,
} from 'shared/graphql/';

import { COLLECTIVE_INTELLIGENCE_THREAD_UPDATE_MUTATION } from '../queries';

export const useThreadUpdateViews = () => {
  const { user } = useCurrentUser();

  const [updateThread, mutationData] = useMutation<
    CollectiveIntelligenceThreadUpdateMutation,
    CollectiveIntelligenceThreadUpdateMutationVariables
  >(COLLECTIVE_INTELLIGENCE_THREAD_UPDATE_MUTATION);

  const onThreadView = async (threadId: string) => {
    if (!user?.id || !threadId) {
      return;
    }

    const isAlreadyViewed = user.viewedThreads?.items.find(thread => thread?.id === threadId);
    if (isAlreadyViewed) {
      return;
    }

    await updateThread({
      variables: {
        data: {
          id: threadId,
          views: {
            connect: [
              {
                id: user?.id,
              },
            ],
          },
        },
      },
    });
  };

  return { onThreadView, ...mutationData };
};
