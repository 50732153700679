import React from 'react';
import * as R from 'ramda';
import { OperationVariables } from '@apollo/client';

import { POST_REPORT_STATUS, SORT } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { PAGE_SIZE } from 'shared/constants';
import { usePostsList } from 'shared/features/posts/hooks';
import { useSearchContext } from 'shared/features/search';
import { SchoolNewsPostsListQuery, SchoolNewsPostsCountQuery } from 'shared/graphql';

import { SCHOOL_NEWS_POSTS_LIST_QUERY, SCHOOL_NEWS_POSTS_COUNT_QUERY } from '../queries';
import { getPinnedPosts } from '../../../shared/features/posts';

const transformQueryData = (
  listsData: SchoolNewsPostsListQuery | undefined,
  countData: SchoolNewsPostsCountQuery | undefined,
  pinnedPostsList: { count: number; pinnedPostsList: { items: any[] | undefined } },
) => {
  const pinnedPostsCount = Number(pinnedPostsList?.count) || 0;
  const pinnedPostItems: any[] | undefined = pinnedPostsList?.pinnedPostsList?.items;
  let pinnedPosts: any[] = [];

  if (pinnedPostItems?.length) {
    pinnedPosts = getPinnedPosts(pinnedPostItems);
  }

  const posts = listsData?.schoolNewsPostsList.items || [];
  const count = countData?.schoolNewsPostsList.count || 0;
  const hasMore = posts.length < count;

  return { items: R.uniqBy(item => item.id, [...pinnedPosts, ...posts]), count, hasMore };
};

const FIELD_NAME = 'schoolNewsPostsList';

type UseSchoolNewsPostsQueryProps = {
  forAdmin?: boolean;
  withSearch?: boolean;
  customFilter?: {
    startDate: string;
    endDate: string;
  };
};

/** @deprecated Simple do not use this. */
export const SCHOOL_NEWS_POSTS_VARIABLES = (isCustom: boolean) => {
  return {
    first: !isCustom ? PAGE_SIZE : PAGE_SIZE * 10,
    sort: {
      postDate: SORT.desc,
    },
    pinnedFilter: {
      isPinned: {
        equals: true,
      },
    },
  };
};

const DEFAULT_OPTIONS = {
  withSearch: true,
  forAdmin: false,
};

/** @deprecated Use `useSchoolNewsFeed` instead. */
export const useSchoolNewsPostsQuery = (props?: UseSchoolNewsPostsQueryProps) => {
  const { searchQuery, sortOption } = useSearchContext();

  const { forAdmin, withSearch } = React.useMemo(() => {
    let options = DEFAULT_OPTIONS;
    if (props) {
      options = {
        ...DEFAULT_OPTIONS,
        ...props,
      };
    }

    return options;
  }, [props]);

  const startDate = props?.customFilter?.startDate;
  const endDate = props?.customFilter?.endDate;

  const variables: OperationVariables = React.useMemo(() => {
    const isSearch = Boolean(withSearch && searchQuery);
    const filter = !props?.customFilter
      ? {
          _fullText: isSearch ? searchQuery : undefined,
          reportStatus: { equals: POST_REPORT_STATUS.postAllowed },
          ...{
            postDate: { lte: formatISO8601Date() },
          },
        }
      : {
          AND: [{ postDate: { gte: startDate } }, { postDate: { lte: endDate } }],
        };

    const pinnedFilter = {
      ...SCHOOL_NEWS_POSTS_VARIABLES(!props?.customFilter).pinnedFilter,
      ...filter,
    };

    return {
      ...SCHOOL_NEWS_POSTS_VARIABLES(!!props?.customFilter),
      filter,
      pinnedFilter,
      sort: sortOption,
    };
  }, [withSearch, searchQuery, props?.customFilter, startDate, endDate, sortOption]);

  const { listsData, countData, loading, pinnedPostData, ...rest } = usePostsList<
    SchoolNewsPostsListQuery,
    SchoolNewsPostsCountQuery
  >({
    listQuery: SCHOOL_NEWS_POSTS_LIST_QUERY,
    countQuery: SCHOOL_NEWS_POSTS_COUNT_QUERY,
    itemsFieldName: FIELD_NAME,
    variables,
  });

  const { items, hasMore } = React.useMemo(
    () => transformQueryData(listsData, countData, pinnedPostData),
    [countData, listsData, pinnedPostData],
  );

  return {
    items,
    hasMore,
    loading,
    count: countData?.schoolNewsPostsList?.count ?? 0,
    ...rest,
  };
};
