import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { SchoolNewsPage } from './pages/SchoolNewsPage';

export const getNewsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.news.index,
    exact: true,
    component: SchoolNewsPage,
  },
];
