import React from 'react';
import { Card, CardHeader } from '@mui/material';

type Props = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
};
export function MainNavigationCard({ title, subtitle, children }: Props) {
  return (
    <Card elevation={3}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'body1',
          component: 'h6',
          fontWeight: 'medium',
          color: 'info.main',
        }}
        subheader={subtitle}
        subheaderTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
      />
      {children}
    </Card>
  );
}
