import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { BusinessDetailsPage } from './pages/BusinessDetailsPage';
import { BusinessPage } from './pages/BusinessPage';

export const getAdminBusinessRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.business.index,
    exact: true,
    component: BusinessPage,
  },
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.business.details,
    exact: false,
    component: BusinessDetailsPage,
  },
];
