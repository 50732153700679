import React from 'react';
import { Button } from '@material-ui/core';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { OnLikeAdd, OnReactionRemove, LikeAddProps } from 'shared/hooks/useStreamChatActivity';

import { useResponsive } from '../../../../hooks';
import { ThumbUp } from '@material-ui/icons';

export type PostLikeButtonProps = LikeAddProps & {
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  parentEntityAuthorId?: string;
  likeInfo: {
    id: string;
    // eslint-disable-next-line camelcase
    user_id: string;
  }[];
  withProfilePostSight?: boolean | null | undefined;
};

const TEMP_REACTION_ID = 'tempId';

export const LikeButton = ({
  onLikeAdd,
  onReactionRemove,
  likeInfo,
  withProfilePostSight = false,
  ...onLikeProps
}: PostLikeButtonProps) => {
  const [likes, setLikes] = React.useState(likeInfo);
  const { isMobile } = useResponsive();
  const { userId: currentUserId } = useCurrentUser();

  const hasCurrentUserLike = React.useMemo(
    () => likes.find(reaction => reaction.user_id === currentUserId),
    [currentUserId, likes],
  );

  const onLike = React.useCallback(async () => {
    if (hasCurrentUserLike) {
      onReactionRemove(hasCurrentUserLike.id);
      setLikes(likes.filter(reaction => reaction.id !== hasCurrentUserLike.id));

      return;
    }

    // in order to not wait for the response
    if (currentUserId) {
      setLikes([...likes, { user_id: currentUserId, id: TEMP_REACTION_ID }]);
    }

    const res = await onLikeAdd(onLikeProps);

    if (!res) {
      return;
    }

    setLikes(res);
  }, [hasCurrentUserLike, likes, currentUserId, onLikeAdd, onLikeProps, onReactionRemove]);

  return (
    <Button
      variant="text"
      size="small"
      onClick={onLike}
      startIcon={<ThumbUp />}
      color={hasCurrentUserLike ? 'secondary' : 'primary'}
    >
      {withProfilePostSight ? 'Like' : likes.length}
    </Button>
  );
};
