import { RouteLayout } from 'shared/components/layouts';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';

import { GroupsContent } from '../components/Groups';

export function GroupsPage() {
  return (
    <RouteLayout
      content={<GroupsContent />}
      sidebar={<CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />}
    />
  );
}
