import { gql } from '@apollo/client';
import { ascend } from 'ramda';

import {
  SchoolConfigurationExternalLink,
  SchoolConfigurationLayout,
  useSchoolConfigurationQuery,
} from 'shared/graphql';

export const SCHOOL_CONFIGURATION_QUERY = gql`
  query SchoolConfiguration {
    schoolConfigurationsList(first: 1) {
      items {
        id
        timezone
        fullName
        name
        email
        moderationThreshold

        images {
          id
          logo {
            id
            filename
            downloadUrl
            fileId
            public
          }
          splashImage {
            id
            filename
            downloadUrl
            fileId
            public
          }
          emailLogo {
            id
            filename
            downloadUrl
            fileId
            public
          }
          emailBanner {
            id
            filename
            downloadUrl
            fileId
            public
          }
        }
        colorSchema {
          id
          accent1
          accent2
          primary
          secondary
          sidebar
          sidebarFont
        }
        topBar {
          id
          title
          text
          startDate
          link
          label
          endDate
        }
        layout {
          items {
            id
            name
            icon
            route
            navbarOrderNumber
            sidebarOrderNumber
            isActive
            isAlwaysShown
          }
        }
        externalLinks {
          items {
            id
            name
            order
            link
            isActive
          }
        }
        wordsBlacklist {
          id
          blacklistId
          blacklistLabel
        }
      }
    }
  }
`;

export const useSchoolConfiguration = () => {
  const { data: response, loading, error } = useSchoolConfigurationQuery();

  /** @todo IMPROVE THIS ON THE MULTI-TENANT MODEL. */
  const [data] = response?.schoolConfigurationsList.items ?? [];

  return {
    data,
    /** @deprecated Use `data` instead. */
    configuration: data,
    loading,
    error,
  };
};

export const sortLabels = (items: SchoolConfigurationLayout[]): SchoolConfigurationLayout[] => {
  const copiedItems = [...items];
  copiedItems.sort((a, b) => (a.sidebarOrderNumber ?? 0) - (b.sidebarOrderNumber ?? 0));
  return copiedItems;
};

export const sortMobileLabels = (
  items: SchoolConfigurationLayout[],
): SchoolConfigurationLayout[] => {
  const pages = [...items];

  return pages
    .filter(page => typeof page?.navbarOrderNumber === 'number')
    .sort(ascend(page => page?.navbarOrderNumber as number));
};

export const sortExternalLinks = (
  items: SchoolConfigurationExternalLink[],
): SchoolConfigurationExternalLink[] => {
  const copiedItems = [...items];
  copiedItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  return copiedItems;
};
