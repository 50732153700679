import { css } from '@emotion/react';
import { Box, Button } from '@material-ui/core';
import React from 'react';
import { Avatar } from '@mui/material';

import { processFilestackUrl } from '@jebel/utils';

import { useSchoolConfiguration } from 'shared/hooks';

import { Modal, Typography } from '../ui';
import { MobileShortcutInstructionsModal } from './MobileShortcutInstructionsModal';

interface MobileShortcutModalType {
  onModalClose: () => void;
}

const avatarBoxCss = (theme: any) => css`
  background-color: ${theme.palette.primary.main};
  border-radius: 2px;
  border: 1px solid ${theme.palette.primary.main};
`;

const commonButtonsCSS = (theme: any) => css`
  width: 100%;
  padding: ${theme.spacing(1)}px ${theme.spacing(10)}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.primary};
  border: 1px solid ${theme.palette.text.primary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

const textCss = (theme: any) => css`
  color: ${theme.palette.primary.main};
  line-height: 18px;
`;

export const MobileShortcutModal: React.FC<MobileShortcutModalType> = ({
  onModalClose: onPreviewShortcutModalClose,
}) => {
  const { configuration } = useSchoolConfiguration();
  const [isMobileShortcutInstructionsModalOpen, setMobileShortcutInstructionsModalOpen] =
    React.useState(false);

  const { onInstructionsModalOpen, onInstructionsModalClose } = React.useMemo(
    () => ({
      onInstructionsModalOpen: () => setMobileShortcutInstructionsModalOpen(true),
      onInstructionsModalClose: () => {
        setMobileShortcutInstructionsModalOpen(false);
        onPreviewShortcutModalClose();
      },
    }),
    [onPreviewShortcutModalClose],
  );

  return (
    <React.Fragment>
      <Modal
        dialogProps={{
          open: isMobileShortcutInstructionsModalOpen,
          onClose: onInstructionsModalClose,
          fullWidth: true,
          maxWidth: 'xs',
        }}
        dialogContentWidth={280}
      >
        <MobileShortcutInstructionsModal onModalClose={onInstructionsModalClose} />
      </Modal>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap={25}
        my={2}
      >
        <Box css={avatarBoxCss}>
          <Avatar
            src={processFilestackUrl(configuration?.images?.logo?.downloadUrl || '')} // , {
            variant="square"
            css={avatarBoxCss}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography css={textCss} variant="body1">
            Create a Home Screen icon /
          </Typography>
          <Typography css={textCss} variant="body1">
            Shortcut for Columbus Connects
          </Typography>
          <Typography css={textCss} variant="body1">
            on your Smartphone
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={10}>
          <Button css={submitButtonCSS} onClick={onInstructionsModalOpen} variant="contained">
            LEARN HOW
          </Button>
          <Button
            css={cancelButtonCSS}
            onClick={onPreviewShortcutModalClose}
            size="medium"
            variant="outlined"
          >
            GOT IT
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};
