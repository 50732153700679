import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Layout

interface LayoutProps {
  sidebarWidth?: string | number;
}

const LayoutSharedCSS = css`
  overflow: hidden;
  flex: 1;
`;

export const Layout = styled.div<LayoutProps>(props => {
  if (props.sidebarWidth) {
    return css`
      display: grid;
      grid-template-columns: ${props.sidebarWidth} 1fr;

      ${LayoutSharedCSS}
    `;
  }

  return css`
    display: flex;
    flex-direction: column;

    ${LayoutSharedCSS}
  `;
});

// Header

export const HEADER_HEIGHT = '64px';

export const Header = styled.div`
  max-height: ${HEADER_HEIGHT};
  position: sticky;
  top: 0;
  z-index: 5;
  grid-template-areas: header;
`;

// Centered

export const Centered = styled.div`
  min-width: 0px;
  min-height: 90vh;
  overflow: auto;
`;

// Sidebar

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  overflow: hidden;
  top: 0;
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
