import React from 'react';
import { useQuery, DocumentNode, QueryFunctionOptions, OperationVariables } from '@apollo/client';
import { debounce, get, isFunction } from 'lodash';

import { useCurrentUser } from 'shared/hooks';

import type { RenderTag, RenderOption } from './interface';

type DataBaseSelectFieldResult<Option> = {
  options: Array<Option>;
  loading: boolean;
  onSearch: (searchText: string) => void;
  getOptionLabel?: (option: Option) => string;
  renderOption?: RenderOption<Option>;
  renderTag?: RenderTag<Option>;
  parse?: (values: Array<Option>, name: string) => Array<any>;
};

type GetQueryItemsFunc<Option = any> = (res: any) => Array<Option>;
type QueryItemsPath = string;

export type QueryParams<Option> = {
  query: DocumentNode;
  getQueryItems: GetQueryItemsFunc<Option> | QueryItemsPath;
  getCustomQueryVariables?: (searchText: string) => OperationVariables;
  queryOptions?: QueryFunctionOptions;
};

export type RenderParams<Option = any> = {
  getOptionLabel: (option: Option) => string;
  renderOption?: RenderOption<Option>;
  renderTag?: RenderTag<Option>;
  mapValues?: (values: Array<Option>, name: string) => Array<any>;
};

const DEFAULT_DEBOUNCE_DELAY = 700;

export const DEFAULT_NUMBER_OF_SEARCH_OPTIONS = 20;

function useDataBaseSelectField<Query = any, Option = any>(
  queryParams: QueryParams<Option>,
  renderParams: RenderParams<Option>,
  skipSelf?: boolean,
  shouldAcceptNonExistingValues?: boolean,
): DataBaseSelectFieldResult<Option> {
  const { userId } = useCurrentUser();
  const { queryOptions = {} } = queryParams;
  const [searchText, setSearchText] = React.useState<string>('');
  const hasSearchText = searchText.length !== 0;

  const getVariables = () => {
    if (isFunction(queryParams.getCustomQueryVariables)) {
      return queryParams.getCustomQueryVariables(searchText);
    }

    return {
      filter: {
        _fullText: searchText,
        id: { not_equals: skipSelf ? userId : '' },
      },
      first: DEFAULT_NUMBER_OF_SEARCH_OPTIONS,
    };
  };

  const { data, loading } = useQuery<Query>(queryParams.query, {
    variables: getVariables(),
    ...queryOptions,
    // skip: !hasSearchText,
    fetchPolicy: 'network-only',
  });

  const onSearch = (textToSearch: string) => {
    setSearchText(textToSearch);
  };

  const options = isFunction(queryParams.getQueryItems)
    ? queryParams.getQueryItems(data)
    : get(data, queryParams.getQueryItems, []);

  return {
    options: !shouldAcceptNonExistingValues
      ? options
      : [...options, { email: searchText, id: searchText }],
    getOptionLabel: renderParams.getOptionLabel,
    renderOption: renderParams.renderOption,
    renderTag: renderParams.renderTag,
    parse: renderParams.mapValues,
    onSearch: debounce(onSearch, DEFAULT_DEBOUNCE_DELAY),
    loading: loading && hasSearchText,
  };
}

export { useDataBaseSelectField };
