import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { MembersPage } from './pages/MembersPage';
import { MembersAccountInformationPage } from './pages/MembersAccountInformationPage';

export const getAdminMembersRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.members.index,
    exact: true,
    component: MembersPage,
  },
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.members.information,
    exact: true,
    component: MembersAccountInformationPage,
  },
];
