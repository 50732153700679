import { Forum, Business, ThumbUp } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { OrganizationItemFragment } from 'shared/graphql';

import { Buttons } from './ExplorerBusinessCard.styles';
import { OrganizationLikeWrapper } from '../../../organizations/components/OrganizationLikeWrapper';

export interface OrganizationListItemButtonsProps {
  data: OrganizationItemFragment;

  withLike: boolean;
  withMessage: boolean;
  withViewProfile: boolean;

  onOpenProfile?(): void;
  onOpenMessage?(): void;
}

export function OrganizationListItemButtons(props: OrganizationListItemButtonsProps) {
  return (
    <Buttons>
      {props.withLike && <LikeButton organization={props.data} />}
      {props.withMessage && <MessageButton onClick={props.onOpenMessage} />}
      {props.withViewProfile && <ViewProfileButton onClick={props.onOpenProfile} />}
    </Buttons>
  );
}

function LikeButton(props: { organization: OrganizationItemFragment }) {
  return (
    <OrganizationLikeWrapper organization={props.organization}>
      {context => (
        <Button
          size="small"
          startIcon={<ThumbUp fontSize="small" />}
          color={context.hasUserLike ? 'secondary' : 'primary'}
          onClick={context.onToggle}
        >
          {context.count}
        </Button>
      )}
    </OrganizationLikeWrapper>
  );
}

function MessageButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Forum fontSize="small" />}
      onClick={props.onClick}
    >
      Message
    </Button>
  );
}

function ViewProfileButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Business fontSize="small" />}
      onClick={props.onClick}
    >
      View Profile
    </Button>
  );
}
