import { gql } from '@apollo/client';

export const MESSAGE_SEND_REQUEST_MUTATION = gql`
  mutation MessageSendRequest(
    $subject: JSON!
    $text: String!
    $userIds: [String!]
    $media: String
    $type: String
    $destinationGroups: JSON
  ) {
    messageSendRequest(
      subject: $subject
      text: $text
      userIds: $userIds
      media: $media
      type: $type
      destinationGroups: $destinationGroups
    ) {
      id
      subject
      users {
        items {
          id
          firstName
          lastName
          avatar {
            id
            downloadUrl
          }
        }
      }
      messages {
        items {
          id
          createdAt
          text
          isRead
          isDeleted
          media {
            items {
              id
              downloadUrl
            }
          }
          author {
            id
          }
          inbox {
            id
          }
        }
      }
    }
  }
`;
