import { useMemo } from 'react';
import { uniqBy } from 'ramda';

import { GroupAdminStatus, GroupUserRequestStatus } from '@jebel/constants';

import { MinimalUserFragment, useGroupMembersQuery } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

export interface NormalizedGroupMember {
  id: string;
  status: GroupUserRequestStatus;
  user: MinimalUserFragment;
  isAdmin: boolean;
}

/**
 * List the group members for the given `id`.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1611
 */
export function useGroupMembers(id: string | undefined | null) {
  const { user } = useCurrentUser();

  const { data: response, loading } = useGroupMembersQuery({
    skip: !id,
    variables: { id: id! },
  });

  const invitedUsers = useMemo(() => {
    const data = response?.group?.invitedUsers?.items ?? [];

    return data
      .filter(member => Boolean(member?.user))
      .map<NormalizedGroupMember>(member => {
        return {
          id: String(member.id),
          status: member.status as GroupUserRequestStatus,
          user: member.user as MinimalUserFragment,
          isAdmin: false,
        };
      });
  }, [response]);

  const adminUsers = useMemo(() => {
    const normalized = response?.group?.adminUsers?.items ?? [];
    // Clone the data to avoid mutating the original data and thrown an read-only error.
    const data = structuredClone(normalized);

    const creator = response?.group?.creator;

    if (creator) {
      const alreadyAdmin = data.some(member => member.user?.id === creator.id);

      if (!alreadyAdmin) {
        data.push({ status: GroupAdminStatus.Active, user: creator });
      }
    }

    return data
      .filter(member => Boolean(member?.user))
      .map<NormalizedGroupMember>(member => {
        return {
          id: String(member.id),
          status: GroupUserRequestStatus.Approved,
          user: member.user as MinimalUserFragment,
          isAdmin: true,
        };
      });
  }, [response]);

  const data = useMemo(() => {
    const members = adminUsers.concat(invitedUsers);

    return uniqBy(member => member.user?.id, members);
  }, [invitedUsers, adminUsers]);

  const currentUserMembership = useMemo(() => {
    return data.find(member => member.user?.id === user?.id);
  }, [data, user]);

  /** Is the current user an active members of this group? */
  const isCurrentUserCreator = useMemo(() => {
    const creator = response?.group?.creator;
    return creator?.id === user?.id;
  }, [response, user]);

  /** Is the current user an active members of this group? */
  const isCurrentUserMember = useMemo(() => {
    const hasMembership = currentUserMembership?.status === GroupUserRequestStatus.Approved;

    return hasMembership || isCurrentUserCreator;
  }, [currentUserMembership, isCurrentUserCreator]);

  /** Has the current user requested membership of this group? */
  const isCurrentUserPending = useMemo(() => {
    return currentUserMembership?.status === GroupUserRequestStatus.Pending;
  }, [currentUserMembership]);

  /** Is the current user an active administrator of this group? */
  const isCurrentUserAdmin = useMemo(() => {
    const hasAdminMembership = adminUsers.some(member => member.user?.id === user?.id);

    return hasAdminMembership || isCurrentUserCreator;
  }, [adminUsers, isCurrentUserCreator, user?.id]);

  return {
    data,

    isLoading: loading,

    /** Is the current user an active members of this group? */
    isCurrentUserMember,
    /** Has the current user requested membership of this group? */
    isCurrentUserPending,
    /** Is the current user an active administrator of this group? */
    isCurrentUserAdmin,
  };
}
