import { Fragment, useCallback, useEffect, useState } from 'react';
import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Divider, Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { AsyncContent, Typography, Modal } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import { RouteLayout } from 'shared/components/layouts';
import { InfiniteScroll } from 'shared/features/infinite-scroll';
import { useExplorerSupporters } from 'features/explorer/hooks';
import { useSchoolConfiguration } from 'shared/hooks';
import { ExplorerBusinessCard } from 'features/explorer/components';
import { OrganizationItemFragment } from 'shared/graphql';
import { recordMessage } from 'shared/utils/record';

import {
  useOrganizationsFilter,
  useOrganizationsPaginated,
  useCreateSchoolSearchMutation,
  useCreateSchoolClickMutation,
} from '../../hooks';
import { OrganizationClaimModal } from '../OrganizationClaimModal';
import { IMAGE_WIDTH } from './styles';
import { OrganizationsFilter } from './OrganizationsFilter';
import { OrganizationsHeader } from './OrganizationsHeader';

const SUPPORTER_ICON_SIZE = 25;

const containerCSS = (theme: Theme) => css`
  display: grid;
  gap: 2rem;
`;

const organizationsCSS = css`
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
`;

const supportersTitleContainerCss = css`
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
`;

const supportersTitleRowCss = theme => css`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: ${theme.spacing(1)};
`;

const supportersIconCss = theme => css`
  width: ${SUPPORTER_ICON_SIZE}px;
  height: ${SUPPORTER_ICON_SIZE}px;
  margin-right: ${theme.spacing(1)}px;
`;

const rowTitleCss = theme => css`
  color: ${theme.palette.primary.icon};
`;

interface CardsContainerProps {
  displayList: boolean;
}

const CardsContainer = styled(Box)<CardsContainerProps>`
  display: grid;
  gap: 1rem;
  grid-template-columns: ${props =>
    props.displayList ? '1fr' : 'repeat(auto-fill, minmax(20rem, 1fr))'};
`;

const messageCSS = css`
  text-align: center;
  margin-bottom: 2rem;
`;

export function OrganizationsContent() {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { location } = useHistory();
  const { filter, sort } = useOrganizationsFilter();
  const { data: supporters, loading: supportersLoading } = useExplorerSupporters();
  const { configuration: school } = useSchoolConfiguration();
  const { displayList, isFilterModalOpen, setIsFilterModalOpen, setFilter } = useSearchContext();

  const supportersLogo = school?.images?.logo?.downloadUrl ?? undefined;

  const createSchoolSearchImpression = useCreateSchoolSearchMutation();
  const createSchoolSupporterClick = useCreateSchoolClickMutation();

  const handleBusinessSearch = useCallback(
    async (business: OrganizationItemFragment) => {
      const schoolSupporters = business.schoolSupporter?.items ?? [];

      const schoolSupporter = schoolSupporters.find(
        // Match the current school with some school supporter.
        // https://8base-dev.atlassian.net/browse/JEB-1583
        supporter => supporter.school?.id === school.id,
      );

      if (!schoolSupporter?.id) {
        recordMessage(`No supporter found for the current school and business: "${business?.id}"`);
        return;
      }

      await createSchoolSearchImpression(schoolSupporter);
    },
    [school, createSchoolSearchImpression],
  );

  const onBusinessSearch = useCallback(
    (business: OrganizationItemFragment) => () => handleBusinessSearch(business),
    [handleBusinessSearch],
  );

  const handleBusinessClick = useCallback(
    async (business: OrganizationItemFragment) => {
      const schoolSupporters = business.schoolSupporter?.items ?? [];

      const schoolSupporter = schoolSupporters.find(
        // Match the current school with some school supporter.
        // https://8base-dev.atlassian.net/browse/JEB-1583
        supporter => supporter.school?.id === school.id,
      );

      if (!schoolSupporter?.id) {
        recordMessage(`No supporter found for the current school and business: "${business?.id}"`);
        return;
      }

      await createSchoolSupporterClick(schoolSupporter);
    },
    [school, createSchoolSupporterClick],
  );

  const onBusinessClick = useCallback(
    (business: OrganizationItemFragment) => () => handleBusinessClick(business),
    [handleBusinessClick],
  );

  const {
    data: organizations,
    loading: organizationsLoading,
    hasMore: hasMoreOrganizations,
    next: fetchMoreOrganizations,
  } = useOrganizationsPaginated({ variables: { filter, sort } });

  const loading = supportersLoading || organizationsLoading;

  useEffect(() => {
    if ((location.state as Record<string, unknown>)?.isBusiness) {
      setIsOpenModal(true);
    }
  }, [location]);

  const onCloseClaimModal = () => {
    setIsOpenModal(false);
  };

  const onOpenClaimModal = () => {
    setIsOpenModal(true);
  };

  return (
    <RouteLayout
      withFullWidth
      content={
        <Fragment>
          <Modal
            dialogProps={{
              open: isFilterModalOpen,
              onClose: () => setIsFilterModalOpen(false),
              fullWidth: true,
            }}
            withPadding={false}
          >
            <OrganizationsFilter recentFilters={filter} onChangeOrganizationFilters={setFilter} />
          </Modal>

          <OrganizationClaimModal isOpen={isOpenModal} onClose={onCloseClaimModal} />

          <div css={containerCSS}>
            <OrganizationsHeader onOpenClaimModal={onOpenClaimModal} />

            <Divider />

            <AsyncContent loading={loading} stretch>
              <Box css={organizationsCSS}>
                <Box css={supportersTitleContainerCss}>
                  <Box css={supportersTitleRowCss}>
                    <Avatar
                      css={supportersIconCss}
                      src={supportersLogo}
                      srcSet={processFilestackUrlSrcSet(supportersLogo, {
                        compress: true,
                        resize: {
                          width: IMAGE_WIDTH,
                          height: IMAGE_WIDTH,
                          fit: 'crop',
                        },
                      })}
                    />

                    <Typography variant="subtitle5" css={rowTitleCss}>
                      Supporter Results ({supporters.length})
                    </Typography>
                  </Box>
                </Box>

                <CardsContainer displayList={displayList}>
                  {supporters?.map(business => (
                    <ExplorerBusinessCard
                      key={business.id}
                      variant={displayList ? 'list' : 'grid'}
                      data={business}
                      onShowedUp={onBusinessSearch(business)}
                      onClickAction={onBusinessClick(business)}
                    />
                  ))}
                </CardsContainer>
              </Box>

              <Divider />

              <InfiniteScroll
                hasMore={hasMoreOrganizations}
                fetchMore={fetchMoreOrganizations}
                fetchingState={
                  <Typography color="textSecondary" css={messageCSS}>
                    Loading...
                  </Typography>
                }
              >
                <Box css={organizationsCSS}>
                  <Box css={supportersTitleContainerCss}>
                    <Box css={supportersTitleRowCss}>
                      <Typography variant="subtitle5" css={rowTitleCss}>
                        {organizations.length} Result(s) Found
                      </Typography>
                    </Box>
                  </Box>

                  <CardsContainer displayList={displayList}>
                    {organizations?.map(item => (
                      <ExplorerBusinessCard
                        key={item.id}
                        variant={displayList ? 'list' : 'grid'}
                        data={item}
                      />
                    ))}
                  </CardsContainer>
                </Box>
              </InfiniteScroll>
            </AsyncContent>
          </div>
        </Fragment>
      }
      sidebar={
        <OrganizationsFilter recentFilters={filter} onChangeOrganizationFilters={setFilter} />
      }
    />
  );
}
