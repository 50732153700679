import React from 'react';

import { usePostsListItem } from 'shared/features/posts/hooks';
import {
  GroupPostsListItemQuery,
  HomeFeedPostsListItemQuery,
  LegacyFeedPostsListItemQuery,
  SchoolNewsPostsListItemQuery,
} from 'shared/graphql/__generated__';

import {
  GROUP_POSTS_LIST_ITEM_QUERY,
  HOME_FEED_POSTS_LIST_ITEM_QUERY,
  LEGACY_FEED_POSTS_LIST_ITEM_QUERY,
  SCHOOL_NEWS_POSTS_LIST_ITEM_QUERY,
} from '../queries';

const transformGroupPostsQueryData = (listsData: GroupPostsListItemQuery | undefined) => {
  const posts = listsData?.groupPostsList.items || [];
  return { posts };
};

const GROUP_FIELD_NAME = 'groupPostsList';

export const useGroupPostQuery = (postId: string | null | undefined) => {
  const { listsData, loading, ...rest } = usePostsListItem<GroupPostsListItemQuery>({
    itemsFieldName: GROUP_FIELD_NAME,
    listQuery: GROUP_POSTS_LIST_ITEM_QUERY,
    variables: {
      filter: {
        id: { equals: postId },
      },
    },
  });

  const { posts } = React.useMemo(() => transformGroupPostsQueryData(listsData), [listsData]);

  return {
    posts,
    loading,
    ...rest,
  };
};

const transformHomeFeedPostsQueryData = (listsData: HomeFeedPostsListItemQuery | undefined) => {
  const posts = listsData?.homeFeedPostsList?.items || [];
  return { posts };
};

const HOME_FEED_FIELD_NAME = 'homeFeedPostsList';

export const useHomeFeedPostQuery = (postId: string | null | undefined) => {
  const { listsData, loading, ...rest } = usePostsListItem<HomeFeedPostsListItemQuery>({
    listQuery: HOME_FEED_POSTS_LIST_ITEM_QUERY,
    variables: {
      filter: {
        id: { equals: postId },
      },
    },
    itemsFieldName: HOME_FEED_FIELD_NAME,
  });

  const { posts } = React.useMemo(() => transformHomeFeedPostsQueryData(listsData), [listsData]);

  return {
    posts,
    loading,
    ...rest,
  };
};

const transformLegacyFeedPostsQueryData = (listsData: LegacyFeedPostsListItemQuery | undefined) => {
  const posts = listsData?.legacyFeedPostsList?.items || [];
  return { posts };
};

const LEGACY_FEED_FIELD_NAME = 'legacyFeedPostsList';

export const useLegacyFeedPostQuery = (postId: string | null | undefined) => {
  const { listsData, loading, ...rest } = usePostsListItem<LegacyFeedPostsListItemQuery>({
    listQuery: LEGACY_FEED_POSTS_LIST_ITEM_QUERY,
    variables: {
      filter: {
        id: { equals: postId },
      },
    },
    itemsFieldName: LEGACY_FEED_FIELD_NAME,
  });

  const { posts } = React.useMemo(() => transformLegacyFeedPostsQueryData(listsData), [listsData]);

  return {
    posts,
    loading,
    ...rest,
  };
};

const transformSchoolNewsPostsQueryData = (listsData: SchoolNewsPostsListItemQuery | undefined) => {
  const posts = listsData?.schoolNewsPostsList?.items || [];
  return { posts };
};

const SCHOOL_NEWS_FIELD_NAME = 'schoolNewsPostsList';

export const useSchoolNewsPostQuery = (postId: string | null | undefined) => {
  const { listsData, loading, ...rest } = usePostsListItem<SchoolNewsPostsListItemQuery>({
    listQuery: SCHOOL_NEWS_POSTS_LIST_ITEM_QUERY,
    variables: {
      filter: {
        id: { equals: postId },
      },
    },
    itemsFieldName: SCHOOL_NEWS_FIELD_NAME,
  });

  const { posts } = React.useMemo(() => transformSchoolNewsPostsQueryData(listsData), [listsData]);

  return {
    posts,
    loading,
    ...rest,
  };
};
