import React from 'react';
import { css } from '@emotion/react';
import { Box, Chip } from '@material-ui/core';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from 'shared/components/ui';
import { CurrentUserQuery } from 'shared/graphql/__generated__';

const USER_AVATAR_SIZE = 40;

const cardAvatarCss = theme => css`
  margin-right: ${theme.spacing(1)}px;
`;

const cardCss = theme => css`
  cursor: pointer;
  padding: ${theme.spacing(3)}px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const nameTextCss = theme => css`
  line-height: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${theme.spacing(0.75)}px;
`;

const locationTextCss = theme => css`
  color: ${theme.palette.text.secondary};
  line-height: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const userInfoBoxCss = theme => css`
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const chipCss = theme => css`
  border-radius: ${theme.spacing(0.5)}px;
  font-size: 0.75rem;
  font-weight: 500;
  padding-bottom: ${theme.spacing(0.25)}px;
  background-color: ${theme.palette.background.lightBlue};
`;

type UserListItemProps = {
  user: CurrentUserQuery['user'];
};

export const UserListItem: React.FC<UserListItemProps> = ({ user }: UserListItemProps) => {
  return (
    <Box css={cardCss} display="flex">
      <Avatar
        css={cardAvatarCss}
        srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
          compress: true,
          resize: {
            width: USER_AVATAR_SIZE,
            height: USER_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        src={user?.avatar?.downloadUrl || ''}
      />
      <Box css={userInfoBoxCss}>
        <Box display="flex">
          <Typography css={nameTextCss} color="primary" variant="subtitle5">
            {user?.firstName || user?.email} {user?.lastName}
          </Typography>
          {!!user?.affiliation && (
            <Chip color="primary" label={user?.affiliation} size="small" css={chipCss} />
          )}
        </Box>
        <Typography css={locationTextCss} variant="subtitle5">
          {user?.description}
        </Typography>
      </Box>
    </Box>
  );
};
