import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { CollectiveIntelligenceDetailPage } from './pages/CollectiveIntelligenceDetailPage';
import { CollectiveIntelligencePage } from './pages/CollectiveIntelligencePage';

export const getCollectiveRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.collective.index,
    exact: true,
    component: CollectiveIntelligencePage,
  },
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.collective.detail,
    exact: true,
    component: CollectiveIntelligenceDetailPage,
  },
];
