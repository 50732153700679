import { US_STATES } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { DISCOUNTS_OPTIONS } from 'shared/constants';

type SearchInput = string | null | undefined;
type Filters = Record<string, { contains?: string; in?: string }>[];
type GeneratorResult = Record<'OR', Filters>;

function contains(searchable: string, search: string) {
  return searchable.toLowerCase().includes(search.toLowerCase());
}

/**
 * @param searchInput - search field input
 * @param fields - fields to search
 * @example searchFilterGenerator('2011', ['year'])
 * @returns ``{ "OR" : [{ "year" : { "contains" : "2011" } }] }``
 * @deprecated Use `createFilterBuilder` instead.
 */
const pushFilter = (fields: string[], searchQuery: string, index = 0) => {
  const field = fields[index];

  if (fields.length > 1 && index < fields.length - 1) {
    return {
      [field]: pushFilter(fields, searchQuery, index + 1),
    };
  }

  let value = searchQuery;

  if (field === 'state') {
    const state = US_STATES.find(state => contains(state.name, searchQuery));
    value = state?.name ?? value;
  }

  if (field === 'industry') {
    const option = DISCOUNTS_OPTIONS.find(option => contains(option.label, searchQuery));
    value = option?.value ?? value;
  }

  return {
    [field]: { contains: value },
  };
};

/** @deprecated Use `createFilterBuilder` instead. */
export const searchFilterGenerator = (
  searchInput: SearchInput,
  fields: string[],
): GeneratorResult | undefined => {
  const filter = createFilterBuilder();

  if (!searchInput) {
    return undefined;
  }

  const search = searchInput?.trim();

  for (const name of fields) {
    if (!name) {
      continue;
    }

    const paths = name.split('.');
    filter.or(pushFilter(paths, search));
  }

  return filter.build() as GeneratorResult;
};
