import { SystemRoleName } from '@jebel/constants';

import { RoleInfoFragment, useSystemRolesQuery, useUserUpdateRolesMutation } from 'shared/graphql';

export function useUserRoles() {
  const { data, loading: isLoading } = useSystemRolesQuery();

  const [userUpdateRolesMutation, { loading: isUpdating }] = useUserUpdateRolesMutation({
    refetchQueries: ['MembersWithZipList'],
  });

  const roles: RoleInfoFragment[] = data?.rolesList.items || [];

  const updateUserRoles = async (userId: string, rolesAddonsIds: string[]) => {
    await userUpdateRolesMutation({
      variables: {
        input: {
          id: userId,
          rolesAddonsIds,
        },
      },
    });
  };

  const getRoleByName = (name: string) => {
    // Allow to find the given role addon using `name` as filter.
    // https://8base-dev.atlassian.net/browse/JEB-1600
    return roles.find(role => role.name === name || role.name === SystemRoleName[name]);
  };

  return {
    roles,
    updateUserRoles,
    isLoading,
    isUpdating,
    getRoleByName,
  };
}
