import { useCallback, useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  capitalize,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import { SYSTEM_ROLE_NAMES, SystemRoleName } from '@jebel/constants';

import { Form, FormSelect, FormTextField, FormCheckbox, Button } from 'shared/components/ui';
import type {
  RoleInfoFragment,
  SchoolConfigurationLayout,
  RoleAddonInfoFragment,
} from 'shared/graphql';
import { required } from 'shared/utils/form';

import type { FormMode, RolesEditFormValues, RolesEditFormPermissionsValues } from '../Roles.types';
import { buildSectionsValue } from '../utils/utils';
import { RoleAddonPermission } from 'shared/types';

const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`;

const sectionListCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const sectionTitleCSS = css`
  font-weight: bold;
`;

const sectionAccessCSS = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  gap: 1rem;
`;

const sectionAccessGroupCSS = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const footerCSS = css`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

interface RoleAddonPermissionSection {
  id: keyof RolesEditFormPermissionsValues;
  title: string;
  description?: string;
}

const ROLE_ADDON_PERMISSION_SECTIONS: RoleAddonPermissionSection[] = [
  {
    id: 'homePostsPermissions',
    title: 'Home Posts',
    description:
      'Allow users with this role to add, edit, and delete posts on the home feed. This also includes the ability to comment and like.',
  },
  {
    id: 'schoolPostsPermissions',
    title: 'School Posts',
  },
  {
    id: 'membersPermissions',
    title: 'Members',
  },
  {
    id: 'businessPermissions',
    title: 'Business',
  },
  {
    id: 'forumPermissions',
    title: 'Forum',
  },
  {
    id: 'groupsPermissions',
    title: 'Groups',
  },
];

const additionalAccesses: Array<keyof RoleAddonPermission> = ['add', 'edit', 'delete'];

const DEFAULT_PERMISSION: RoleAddonPermission = {
  add: false,
  edit: false,
  delete: false,
};

const noLayoutChecked = values => Object.values(values.sections).every(item => !item);

interface RoleEditFormProps {
  formMode: FormMode;
  currentRoleInfo: RoleAddonInfoFragment | undefined;
  roles: RoleInfoFragment[];
  layouts: SchoolConfigurationLayout[];
  onSubmit: (formValues: RolesEditFormValues) => Promise<void>;
  isLoading: boolean;
}

export function RoleEditForm({
  formMode,
  currentRoleInfo,
  roles,
  layouts,
  onSubmit,
  isLoading,
}: RoleEditFormProps) {
  const initialValues = useMemo((): Partial<RolesEditFormValues> => {
    return {
      name: currentRoleInfo?.name || undefined,
      systemRoleId: currentRoleInfo?.role?.id || undefined,
      isDefault: Boolean(currentRoleInfo?.isDefault),
      sections: buildSectionsValue(layouts, currentRoleInfo?.accessibleLayouts),
      homePostsPermissions: currentRoleInfo?.homePostsPermissions || DEFAULT_PERMISSION,
      schoolPostsPermissions: currentRoleInfo?.schoolPostsPermissions || DEFAULT_PERMISSION,
      membersPermissions: currentRoleInfo?.membersPermissions || DEFAULT_PERMISSION,
      businessPermissions: currentRoleInfo?.businessPermissions || DEFAULT_PERMISSION,
      forumPermissions: currentRoleInfo?.forumPermissions || DEFAULT_PERMISSION,
      groupsPermissions: currentRoleInfo?.groupsPermissions || DEFAULT_PERMISSION,
    };
  }, [layouts, currentRoleInfo]);

  const innerSubmit = useCallback(
    async (values, { setFieldError }) => {
      if (noLayoutChecked(values)) {
        setFieldError('sectionError', 'At least one section should be checked');
        return;
      }
      await onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Form initialValues={initialValues} onSubmit={innerSubmit as any} enableReinitialize>
      {({ isSubmitting, isValid, isValidating, handleSubmit, handleReset, errors }) => {
        const isInputsDisabled = isLoading || isSubmitting;
        const isActionsDisabled = isInputsDisabled || !isValid || isValidating;

        return (
          <Paper css={containerCSS}>
            <FormTextField
              inputProps={{
                label: 'Role name',
                variant: 'outlined',
                style: { width: '100%' },
                disabled: isInputsDisabled,
              }}
              fieldProps={{ name: 'name', validate: required }}
            />

            <FormSelect
              selectProps={{
                label: 'System Role',
                variant: 'outlined',
                style: { width: '100%' },
                disabled: isInputsDisabled,
                children: roles
                  .filter(role => SYSTEM_ROLE_NAMES.includes(role.name as SystemRoleName))
                  .map(role => (
                    <MenuItem key={role.id} value={role.id || undefined}>
                      {role.name || 'Unknown name'}
                    </MenuItem>
                  )),
              }}
              fieldProps={{ name: 'systemRoleId', validate: required }}
            />

            <FormCheckbox
              fieldProps={{ name: `isDefault` }}
              checkboxProps={{
                color: 'primary',
                label: 'Set this role to new users',
                disabled: isInputsDisabled,
              }}
            />

            <Typography css={sectionTitleCSS}>Role has access to view these sections...</Typography>

            {errors.sectionError && <FormHelperText error>{errors.sectionError}</FormHelperText>}

            <Box css={sectionListCSS}>
              {layouts.map(layout => (
                <Box key={layout.id}>
                  <FormCheckbox
                    fieldProps={{ name: `sections[${layout.id}]` }}
                    checkboxProps={{
                      color: 'primary',
                      label: layout.name,
                      defaultChecked: true,
                      disabled: isInputsDisabled || layout.name === 'Home',
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Typography css={sectionTitleCSS}>Role has additional access to...</Typography>

            <Box css={sectionAccessCSS}>
              {ROLE_ADDON_PERMISSION_SECTIONS.map(section => (
                <Box key={section.id} css={sectionAccessGroupCSS}>
                  <Box display="flex" alignItems="center" gridGap={6}>
                    <Typography variant="body2">{section.title}</Typography>

                    {Boolean(section.description) && (
                      <Tooltip arrow title={section.description!}>
                        <HelpOutline fontSize="inherit" color="action" style={{ fontSize: 16 }} />
                      </Tooltip>
                    )}
                  </Box>

                  {additionalAccesses.map(accessName => (
                    <Box key={`${section.id}][${accessName}`}>
                      <FormCheckbox
                        fieldProps={{ name: `[${section.id}][${accessName}]` }}
                        checkboxProps={{
                          color: 'primary',
                          label: capitalize(accessName),
                          disabled: isInputsDisabled,
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Box css={footerCSS}>
              <Button
                disabled={isActionsDisabled}
                loading={isLoading}
                variant="outlined"
                color="primary"
                onClick={() => handleReset()}
              >
                Cancel
              </Button>
              <Button
                disabled={isActionsDisabled}
                loading={isLoading}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {formMode === 'create' ? 'Add Role' : 'Save changes'}
              </Button>
            </Box>
          </Paper>
        );
      }}
    </Form>
  );
}
