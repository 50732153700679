import { Error as IconError, Cancel as IconClose } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useEffect, useMemo } from 'react';

import { parseDate } from '@jebel/utils';

import { useSchoolConfiguration } from 'shared/hooks';
import { useGlobalState } from 'providers';

import { Buttons, Container, Header, Link, Text, Title } from './TopBar.styles';

export function TopBar() {
  const { configuration } = useSchoolConfiguration();
  const { topBar } = useGlobalState();

  const settings = configuration?.topBar;

  useEffect(() => {
    const startDate = parseDate(settings?.startDate);
    const starts = startDate.toMillis();

    if (topBar.value === false && topBar.timestamp < starts) {
      // Show when the new top-bar changes
      topBar.set(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const show = useMemo(() => {
    if (!settings?.startDate || !settings?.endDate) {
      return false;
    }

    const startDate = parseDate(settings?.startDate);
    const endDate = parseDate(settings?.endDate);

    const startDiff = startDate.diffNow().as('minutes');
    const endDiff = endDate.diffNow().as('minutes');

    const inRange = startDiff <= 0 && endDiff >= 0;

    return inRange && topBar.value;
  }, [topBar.value, settings]);

  const href = useMemo(() => {
    const link = configuration?.topBar?.link;

    if (typeof link !== 'string') {
      return undefined;
    }

    if (link.startsWith('https') || link.startsWith('https')) {
      return link;
    }

    return `https://${link}`;
  }, [configuration]);

  const handleClose = () => {
    topBar.set(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Container>
      <Header>
        <IconError fontSize="small" />
        <Title>{settings?.title}</Title>
      </Header>

      <Text>{settings?.text}</Text>

      {href && (
        <Link href={href} target="_blank">
          {settings?.label}
        </Link>
      )}

      <Buttons>
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <IconClose fontSize="small" />
        </IconButton>
      </Buttons>
    </Container>
  );
}
