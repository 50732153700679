import React from 'react';
import { css } from '@emotion/react';
import { Box, Paper, Typography } from '@mui/material';

import { useEventsCount } from '../hooks';

const searchContainerCss = theme => css`
  padding: ${theme.spacing(2)}px ${theme.spacing(3.5)}px;
  box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
`;

const FONT_SIZE = 60;

const totalEventsCss = (theme: any) => css`
  color: ${theme.palette.primary.light};
  font-weight: 500;
  font-size: ${FONT_SIZE}px;
  line-height: ${FONT_SIZE}px;
`;

const pendingEventsCss = (theme: any) => css`
  ${totalEventsCss(theme)};
  color: ${theme.palette.error.light};
`;

export const EventsStatistics: React.FC = () => {
  const { totalEventsCount, pendingEventsCount, upcomingEventsCount } = useEventsCount();

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={10} alignItems="start" my={2}>
      <Paper css={searchContainerCss} elevation={1} variant="elevation">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography css={totalEventsCss}>{totalEventsCount}</Typography>
          <Typography variant="body1">Total Events</Typography>
        </Box>
      </Paper>
      <Paper css={searchContainerCss} elevation={1} variant="elevation">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography css={totalEventsCss}>{upcomingEventsCount}</Typography>
          <Typography variant="body1">Upcoming Events</Typography>
        </Box>
      </Paper>
      <Paper css={searchContainerCss} elevation={1} variant="elevation">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography css={pendingEventsCss}>{pendingEventsCount}</Typography>
          <Typography variant="body1">Pending Approval</Typography>
        </Box>
      </Paper>
    </Box>
  );
};
