import { useMemo } from 'react';

import { useSpreadsheetSearch, useTrendingSearchesFilter } from 'shared/features/search';
import { TRENDING_SEARCHES_LIST_QUERY } from 'shared/features/search/queries';
import { Spreadsheet, useSpreadsheetContext } from 'shared/features/spreadsheet';
import { TrendingSearchesListQuery, TrendingSearchesListQueryVariables } from 'shared/graphql';
import { useDownloadLazyQueryCSV, useDownloadLazyQueryPDF } from 'shared/hooks';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import {
  TrendingSearchesReportHeaders,
  TrendingSearchesReportsSpreadsheetHeadlines,
} from '../constants';

interface TrendingSearchesReportsSpreadsheetProps {
  filterByCount: string;
  filterByWord: string;
  dateRange: { from: any; to: any };
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
}

export function TrendingSearchesReportsSpreadsheet({
  filterByCount,
  filterByWord,
  dateRange,
  toolbarHeader,
  textToolbarHeader,
}: TrendingSearchesReportsSpreadsheetProps) {
  const { queryParams } = useSpreadsheetContext();

  const filter = useTrendingSearchesFilter({
    currentFilter: queryParams.filter,
    filterByCount,
    filterByWord,
    dateRange,
  });

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<TrendingSearchesListQuery>({
      query: TRENDING_SEARCHES_LIST_QUERY,
      searchingFields: ['wordOrPhrase'],
      queryVariables: { ...queryParams, filter },
    });

  const count = tableData?.trendingSearchesList?.count ?? 0;

  const data = useMemo(() => {
    const trends = tableData?.trendingSearchesList?.items ?? [];

    return trends.map(trend => ({
      id: trend.id as string,
      word: trend.wordOrPhrase as string,
      count: Number(trend.searchesCount),
    }));
  }, [tableData]);

  const [downloadCSV] = useDownloadLazyQueryCSV<
    TrendingSearchesListQuery,
    TrendingSearchesListQueryVariables
  >(TRENDING_SEARCHES_LIST_QUERY, {
    filename: getFileNameWithTimestamp('TrendingSearches.csv'),
    transform: transformExport,

    variables: {
      filter: queryVariables.filter,
    },
  });

  const [downloadPDF] = useDownloadLazyQueryPDF<
    TrendingSearchesListQuery,
    TrendingSearchesListQueryVariables
  >(TRENDING_SEARCHES_LIST_QUERY, {
    filename: getFileNameWithTimestamp('TrendingSearches.pdf'),
    displayName: 'Trending Searches',
    transform: transformExport,

    variables: {
      filter: queryVariables.filter,
    },
  });

  return (
    <Spreadsheet
      loading={tableLoading}
      headlines={TrendingSearchesReportsSpreadsheetHeadlines}
      toolbarOptions={{
        withDownload: true,
        withPDFDownload: true,
        withPerPage: true,
        downloadHandler: downloadCSV,
        downloadPDFHandler: downloadPDF,
        rawData: tableData?.trendingSearchesList?.items ?? [],
      }}
      data={data}
      itemsCount={count}
      toolbarHeader={toolbarHeader}
      textToolbarHeader={textToolbarHeader}
    />
  );
}

function transformExport(response: TrendingSearchesListQuery) {
  const searches = response.trendingSearchesList.items ?? [];

  return searches.map(search => {
    return {
      [TrendingSearchesReportHeaders.wordPhrase]: String(search.wordOrPhrase),
      [TrendingSearchesReportHeaders.minimumSearchCount]: search.searchesCount ?? 0,
    };
  });
}
