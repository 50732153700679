import { gql } from '@apollo/client';

export const EVENTS_COUNT_QUERY = gql`
  query EventsCount(
    $upcomingEventsFilter: EventFilter
    $pendingEventsFilter: EventFilter
    $totalEventsFilter: EventFilter
  ) {
    totalEventsCount: eventsList(filter: $totalEventsFilter) {
      count
    }
    upcomingEventsCount: eventsList(filter: $upcomingEventsFilter) {
      count
    }
    pendingEventsCount: eventsList(filter: $pendingEventsFilter) {
      count
    }
  }
`;

export const EVENT_STATUS_UPDATE = gql`
  mutation EventStatusUpdate($data: EventStatusUpdateRequestInput!) {
    eventStatusUpdateRequest(data: $data) {
      success
    }
  }
`;

export const EVENT_DELETE_MUTATION = gql`
  mutation EventDelete($data: EventDeleteInput!) {
    eventDelete(data: $data) {
      success
    }
  }
`;
