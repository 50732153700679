/**
 * Rounds a number
 * @param n - number
 * @param nDecimalPlaces - number of decimal places to leave
 * @example
 * roundNumber(0.56) // -> 1
 * roundNumber(0.56, 1) // -> 0.6
 * roundNumber(-0.5) // -> -1
 */
export function roundNumber(n: number, nDecimalPlaces = 0) {
  return Number(n.toFixed(nDecimalPlaces));
}
