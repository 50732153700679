import { css } from '@mui/material';
import React from 'react';

import { useResponsive } from 'shared/hooks';

import { Typography } from './Typography';

const titleCss = theme => css`
  ${theme.breakpoints.down('sm')} {
    font-weight: 500;
  }
`;

export const Heading: React.FC = ({ children }) => {
  const { getSizes } = useResponsive();
  return (
    <Typography
      css={titleCss}
      variant={getSizes('subtitle2', { sm: 'subtitle3', xs: 'subtitle4' })}
      color="primary"
    >
      {children}
    </Typography>
  );
};
