import React from 'react';
import { ConfirmDialogProvider as MuiConfirmDialogProvider } from 'react-mui-confirm';

import type { SxProp } from 'shared/types/styles';

const confirmDialogActionsStyles: SxProp = {
  px: 3,
  pt: 2,
  pb: 3,
  gap: 1,
};

const confirmDialogContentStyles: SxProp = theme => ({
  pb: 0,
  '.MuiTypography-root': {
    color: theme.palette.text.primary,
  },
});

export const ConfirmDialogsProvider: React.FC = ({ children }) => {
  return (
    <MuiConfirmDialogProvider
      dialogContentProps={{ sx: confirmDialogContentStyles }}
      dialogActionsProps={{ sx: confirmDialogActionsStyles }}
    >
      {children}
    </MuiConfirmDialogProvider>
  );
};
