import { useMemo } from 'react';
import { DateTime } from 'luxon';

import { useRemoteVariable } from './useRemoteVariables';

/**
 * Uses the `jobOfferExpirationDays` remote variable to calculate the non-expired date.
 * https://8base-dev.atlassian.net/browse/JEB-1303
 */
export function useJobOfferExpirationDate() {
  const expirationDays = useRemoteVariable('jobOfferExpirationDays');

  return useMemo(() => {
    return DateTime.now().minus({ days: expirationDays }).startOf('hour');
  }, [expirationDays]);
}
