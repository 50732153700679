import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useLayoutEffect } from 'react';

import { SNACK_TYPES, createSnackMessage } from 'shared/components/ui';

const MESSAGE_DURATION = 3000;

const INITIATE_MESSAGE = `Please do not close this tab until the download process has been completed.`;
const ERROR_MESSAGE = `Seems there was an error while downloading the file. Please check your internet connection and try again.`;
const PREVENT_MESSAGE = `Please do not close this tab while the download is in progress.`;

export type UseDownloadLazyQueryDocument<D, V> = DocumentNode | TypedDocumentNode<D, V>;

interface Options<D, T> {
  filename?: string;
  displayName?: string;

  transform?(response: D): T[];
}

export type UseDownloadLazyQueryOptions<D, V extends OperationVariables, T> = LazyQueryHookOptions<
  D,
  V
> &
  Options<D, T>;

export function useDownloadLazyQuery<D = any, V extends OperationVariables = any, T = unknown>(
  query: UseDownloadLazyQueryDocument<D, V>,
  options?: UseDownloadLazyQueryOptions<D, V, T>,
) {
  const [execute, params] = useLazyQuery(query, options);

  const { enqueueSnackbar: createToast } = useSnackbar();

  useLayoutEffect(() => {
    // eslint-disable-next-line consistent-return
    function preventClose(event: BeforeUnloadEvent) {
      if (params.loading) {
        event.preventDefault();
        event.returnValue = PREVENT_MESSAGE;

        createToast(PREVENT_MESSAGE, {
          content: createSnackMessage(SNACK_TYPES.warning),
          autoHideDuration: MESSAGE_DURATION,
        });

        return PREVENT_MESSAGE;
      }
    }

    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.loading]);

  const generate = async () => {
    createToast(INITIATE_MESSAGE, {
      content: createSnackMessage(SNACK_TYPES.info),
      autoHideDuration: MESSAGE_DURATION,
    });

    try {
      const response = await execute(options);

      if (response.error) {
        throw response.error;
      }

      return options?.transform?.(response.data as D) ?? response.data ?? [];
    } catch (err) {
      createToast(ERROR_MESSAGE, {
        content: createSnackMessage(SNACK_TYPES.info),
        autoHideDuration: MESSAGE_DURATION,
      });

      throw err;
    }
  };

  return [generate, params] as const;
}
