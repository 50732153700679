import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  MutationSchoolConfigurationExternalLinkUpdateArgs,
  SchoolConfigurationExternalLinkUpdateInput,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

const SCHOOL_CONFIGURATION_EXTERNAL_LINK_ITEM_FRAGMENT = gql`
  fragment SchoolConfigurationExternalLinkItem on SchoolConfigurationExternalLink {
    id
    name
    link
    isActive
    order
  }
`;

export const SCHOOL_CONFIGURATION_EXTERNAL_LINK_UPDATE_MUTATION = gql`
  mutation SchoolConfigurationExternalLinkUpdate(
    $data: SchoolConfigurationExternalLinkUpdateInput!
  ) {
    schoolConfigurationExternalLinkUpdate(data: $data) {
      ...SchoolConfigurationExternalLinkItem
    }
  }
  ${SCHOOL_CONFIGURATION_EXTERNAL_LINK_ITEM_FRAGMENT}
`;

export const useSchoolConfigurationExternalLinkUpdate = () => {
  const [schoolConfigurationExternalLinkUpdateMutation, options] = useMutation<
    SchoolConfigurationExternalLinkUpdateInput,
    MutationSchoolConfigurationExternalLinkUpdateArgs
  >(SCHOOL_CONFIGURATION_EXTERNAL_LINK_UPDATE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
  });

  const schoolConfigurationExternalLinkUpdate = React.useCallback(
    async (schoolConfigurationExternalLink: SchoolConfigurationExternalLinkUpdateInput) => {
      try {
        await schoolConfigurationExternalLinkUpdateMutation({
          variables: {
            data: schoolConfigurationExternalLink,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [schoolConfigurationExternalLinkUpdateMutation],
  );

  return [ schoolConfigurationExternalLinkUpdate, options] as const;
};
