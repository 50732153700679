import { DateTime } from 'luxon';

import { Container, DatePicker } from './DashboardFilters.styles';

interface Props {
  dateFilter: { startDate: DateTime; endDate: DateTime };
  loading: boolean;

  setStartDateFilter: (date: any) => void;
  setEndDateFilter: (date: any) => void;
}

export function DashboardFilters(props: Props) {
  const DatePickerProps = {
    readOnly: true,
    disableUnderline: true,
    disabled: props.loading,
  };

  return (
    <Container>
      <DatePicker
        value={props.dateFilter.startDate.toISO()}
        format="MMM dd, yyyy"
        onChange={props.setStartDateFilter}
        maxDate={props.dateFilter.endDate ?? undefined}
        maxDateMessage="Start date cant be later than the end date."
        disableFuture
        InputProps={DatePickerProps}
      />

      <span> - </span>

      <DatePicker
        value={props.dateFilter.endDate.toISO()}
        format="MMM dd, yyyy"
        onChange={props.setEndDateFilter}
        minDate={props.dateFilter.startDate ?? undefined}
        minDateMessage="End date cant be earlier than the start date."
        InputProps={DatePickerProps}
      />
    </Container>
  );
}
