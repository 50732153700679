import styled from '@emotion/styled';

import { CONTENT_HEIGHT } from '../ExperienceCard.styles';

// Container

export const Container = styled.div`
  height: ${CONTENT_HEIGHT}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

// Title

export const Title = styled.span`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
`;

// DateRange

export const DateRange = styled.span`
  color: ${p => p.theme.palette.grey[600]};
`;
