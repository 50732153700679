import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { POST_REPORT_STATUS } from '@jebel/constants';

import { AsyncContent, Button } from 'shared/components/ui';
import { PostsProvider, Post } from 'shared/features/posts';
import type {
  HomeFeedPostsListItemFragment,
  FullContentReportsListQuery,
} from 'shared/graphql/__generated__';
import { useStreamChatReactions } from 'shared/hooks/useStreamChatActivity';
import { PaperLayout } from 'shared/components/layouts';

import { useHomeFeedPostQuery } from '../../hooks';
import { FlaggedPostDetailsHeader } from './FlaggedPostDetailsHeader';

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const allowButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.error.light};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

export const FlaggedHomeFeedPostDetailsModal = ({
  onModalClose,
  currentPostId,
  onAllowPostClick,
  postDeleteLoading,
  postStatusUpdateLoading,
  onConfirmDeleteModalOpen,
  homeFeedPostsData,
}: {
  onModalClose: () => void;
  currentPostId: string;
  onAllowPostClick: (id: string) => void;
  postDeleteLoading: boolean;
  postStatusUpdateLoading: boolean;
  onConfirmDeleteModalOpen: (id: string) => void;
  homeFeedPostsData: FullContentReportsListQuery | undefined;
}) => {
  const { posts, activities, activitiesLoading, loading } = useHomeFeedPostQuery(currentPostId);
  const { onCommentAdd, onLikeAdd, onReactionRemove, onCommentRemove } = useStreamChatReactions();

  const isPostAllowed =
    homeFeedPostsData?.contentReportsList?.items?.find(post => post?.id === currentPostId)
      ?.reportStatus === POST_REPORT_STATUS.postAllowed;

  const renderItem = React.useCallback(
    (data: HomeFeedPostsListItemFragment) => {
      const { author: postAuthor, id, ...itemProps } = data;

      if (!id) {
        return null;
      }

      const author = {
        avatar: {
          downloadUrl: postAuthor?.avatar?.downloadUrl || '',
        },
        name: `${postAuthor?.firstName || ''} ${postAuthor?.lastName || ''}`,
      };

      return (
        <Post
          {...itemProps}
          key={id}
          id={id}
          author={author}
          onPostDelete={() => {}}
          onPostEdit={() => {}}
          onPostFlag={() => {}}
          onCommentAdd={onCommentAdd}
          onLikeAdd={onLikeAdd}
          onReactionRemove={onReactionRemove}
          onCommentRemove={onCommentRemove}
          withInteractions={false}
          disableReactions
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onCommentRemove, onCommentAdd, onLikeAdd, onReactionRemove],
  );

  const { onDeletePost, onAllowPost } = React.useMemo(
    () => ({
      onDeletePost: () => onConfirmDeleteModalOpen(currentPostId),
      onAllowPost: () => onAllowPostClick(currentPostId),
    }),
    [currentPostId, onConfirmDeleteModalOpen, onAllowPostClick],
  );

  return (
    <AsyncContent loading={loading || (!!posts.length && !activities.length)}>
      <React.Fragment>
        <PaperLayout>
          <FlaggedPostDetailsHeader post={posts[0]} isAutoReported={posts[0]?.isAutoReported} />
        </PaperLayout>
        <PaperLayout>
          <PostsProvider activities={activities} loading={activitiesLoading}>
            {posts.map(renderItem)}
          </PostsProvider>
        </PaperLayout>
        <Box display="flex" justifyContent="end">
          <Button css={cancelButtonCSS} onClick={onModalClose} size="medium">
            CANCEL
          </Button>
          {!isPostAllowed && (
            <Button
              css={allowButtonCSS}
              disableElevation
              loading={postStatusUpdateLoading}
              onClick={onAllowPost}
            >
              ALLOW POST
            </Button>
          )}
          <Button
            css={submitButtonCSS}
            disableElevation
            variant="contained"
            loading={postDeleteLoading}
            onClick={onDeletePost}
          >
            DELETE POST
          </Button>
        </Box>
      </React.Fragment>
    </AsyncContent>
  );
};
