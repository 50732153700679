import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { PostsCountQuery, PostsCountQueryVariables } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

import { POSTS_COUNT_QUERY } from '../queries';
import { useStreamChatActivityQuery } from '../../../shared/hooks/useStreamChatActivity';

export const usePostsCount = () => {
  const [totalEventsEngagement, setTotalEventsEngagement] = React.useState(0);
  const [postsCountSearch, { loading, data, ...rest }] = useLazyQuery<
    PostsCountQuery,
    PostsCountQueryVariables
  >(POSTS_COUNT_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const onPostsCountSearch = React.useCallback(
    (startDate: string, endDate: string) => {
      try {
        postsCountSearch({
          variables: {
            jobOffersFilter: {
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            eventsFilter: {
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [postsCountSearch],
  );
  const { activities, onListQueryComplete: rawOnQueryCompleted } = useStreamChatActivityQuery();

  React.useEffect(() => {
    const events: any[] = data?.eventPostsCount?.items ?? [];
    rawOnQueryCompleted([...events]);
  }, [data?.eventPostsCount?.items, rawOnQueryCompleted]);

  const totalEventsPostsCount = data?.eventPostsCount?.count ?? 0;
  const totalJobsPostsCount = data?.jobPostsCount?.count ?? 0;
  const totalJobsEngagementCount =
    data?.jobPostsCount?.items.reduce((a, b) => {
      return a + (b?.engagementCount || 0);
    }, 0) || 0;

  React.useEffect(() => {
    if (activities?.length) {
      setTotalEventsEngagement(
        (activities?.reduce((a, b) => {
          const res = (a + (b.reaction_counts?.comment || 0)) as number;
          return res;
        }, 0) as any as number) || 0,
      );
    }
  }, [activities]);

  return {
    onPostsCountSearch,
    totalEventsPostsCount,
    totalJobsPostsCount,
    totalEventsEngagement,
    totalJobsEngagementCount,
    loading: loading && !data,
    ...rest,
  };
};
