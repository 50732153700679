/** Allow to convert readable numbers to [Stripe amount format](https://stripe.com/docs/api/charges/object#charge_object-amount). */
export const TO_STRIPE_CENTS = 100;
/** Allow to convert the [Stripe amount format](https://stripe.com/docs/api/charges/object#charge_object-amount) to readable numbers. */
export const FROM_STRIPE_CENTS = 0.01;
export const isInteger = (obj) => Number.isInteger(obj);
/**
 * Format an `amount` using `Intl.NumberFormat` API.
 */
export function formatMoney(amount, digits = 2) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
    });
    return formatter.format(amount);
}
export const formatMoneyFromCents = (value, minimumFractionDigits = 0) => {
    return formatMoney((value !== null && value !== void 0 ? value : 0) / 100, minimumFractionDigits);
};
