import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { ExplorerNationPage } from './pages/ExplorerNationPage';
import { ExplorerNationProfilePage } from './pages/ExplorerNationProfilePage';

export const getExplorerRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.explorer.index,
    exact: true,
    component: ExplorerNationPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.explorer.year,
    exact: true,
    component: ExplorerNationPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.explorer.profile,
    exact: false,
    component: ExplorerNationProfilePage,
  },
];
