import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  EducationalInstitutionCreateInput,
  useEducationProfileCreateMutation,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

export function useEducationProfileCreate() {
  const { userId } = useCurrentUser();

  const [mutate] = useEducationProfileCreateMutation({
    refetchQueries: ['UserPublicInfo'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your education profile has been updated.',
      [SNACKBAR_ERROR_MESSAGE]:
        '"Error: There are one or more errors in your education profile. Please review carefully and try again.',
    },
  });

  const onEducationCreate = async (data: EducationalInstitutionCreateInput) => {
    if (!userId) {
      return;
    }

    const response = await mutate({
      variables: {
        data: { ...data, user: { connect: { id: userId } } },
      },
    });

    return response;
  };

  return { onEducationCreate };
}
