import React from 'react';
import { Box, BoxProps } from '@mui/material';

export type CenterLayoutProps = BoxProps & {
  withBottomOffset?: boolean;
};

const CenterLayout: React.FC<CenterLayoutProps> = ({ children, withBottomOffset, ...rest }) => {
  return (
    <Box
      display="flex"
      height={withBottomOffset ? undefined : '100vh'}
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      {...rest}
    >
      {children}
    </Box>
  );
};

export { CenterLayout };
