import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography, Link as MaterialLink } from '@material-ui/core';

export const Container = styled.a`
  --link-preview-image-size: 8rem;

  display: flex;
  height: var(--link-preview-image-size);
  overflow: hidden;
  border: solid 1px;
  border-color: ${p => p.theme.palette.divider};
  border-radius: 0.25rem;
  text-decoration: none;
  color: ${p => p.theme.palette.text.primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  overflow: hidden;
`;

const textCSS = css`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled(Typography)`
  ${textCSS};

  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
`;

export const Description = styled(Typography)`
  ${textCSS};
`;

export const Link = styled(MaterialLink)`
  ${textCSS};

  color: ${p => p.theme.palette.secondary.main};
`;

export const Image = styled.img`
  width: var(--link-preview-image-size);
  height: var(--link-preview-image-size);
  object-position: center;
  object-fit: cover;
  background-color: ${p => p.theme.palette.background.paper};
`;
