import { CircularProgress } from '@material-ui/core';
import { lazy, Suspense } from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { PostFeedLoader } from 'shared/components/ui';

const CommunitySidebar = lazy(
  () => import('shared/features/community-sidebar/CommunitySidebarDefaultExport'),
);

const SchoolNewsContent = lazy(() => import('../components/SchoolNews/SchoolNewsContent'));

export function SchoolNewsPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <Suspense fallback={<PostFeedLoader />}>
          <SchoolNewsContent />
        </Suspense>
      }
      sidebar={
        <Suspense fallback={<CircularProgress />}>
          <CommunitySidebar />
        </Suspense>
      }
    />
  );
}
