import { gql } from '@apollo/client';

export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
    email
    affiliation
    graduatingYear
    professionalDesignations
    ownedOrganizations {
      items {
        id
        advertising {
          id
          supporter {
            id
            isSupporter
          }
        }
      }
    }
  }
`;

// NOTE: Use the same fragment either client-side and server-side to avoid apollo cache mismatches.
export const CAREER_INFO_FRAGMENT = gql`
  fragment CareerInfo on JobOffer {
    id
    description
    position
    postDate
    employmentType
    createdAt

    createdBy {
      id
      email
      fullName
      graduatingYear
      professionalDesignations
      currentIndustry
      isSeekingEmployment
      affiliation

      jobExperience(first: 1, orderBy: createdAt_DESC) {
        items {
          id
          companyName
          positionTitle
          isCurrentlyWorking

          companyImage {
            fileId
            downloadUrl
          }
        }
      }

      avatar {
        downloadUrl
      }
    }

    organization {
      id
      name

      owner {
        id
      }

      managers {
        items {
          id
          user {
            id
          }
        }
      }

      location {
        items {
          id
          address {
            items {
              id
              zip
              street1
              state
              city
            }
          }
        }
      }

      logo {
        fileId
        downloadUrl
      }
    }
  }
`;

export const CAREERS_PAGE_SEARCH = gql`
  query CareersPageSearch(
    $startPointZip: String!
    $radius: String!
    $careerFilter: JobOfferFilter
    $careersSort: [JobOfferSort!]
    $careersFirst: Int
    $careersSkip: Int
  ) {
    page: careersFilterByZipRequest(
      startPointZip: $startPointZip
      radius: $radius
      careerFilter: $careerFilter
      careersSort: $careersSort
      careersFirst: $careersFirst
      careersSkip: $careersSkip
    ) {
      count
      items {
        ...CareerInfo
      }
    }
  }

  ${CAREER_INFO_FRAGMENT}
  ${USER_INFO_FRAGMENT}
`;

export const CAREERS_LIST_COUNT_QUERY = gql`
  query CareersListCount($filter: JobOfferFilter) {
    jobOffersList(filter: $filter) {
      count
    }
  }
`;

export const PREVIEW_SEARCH_QUERY = gql`
  query PreviewSearch(
    $userFilter: UserFilter
    $internshipsFilter: JobOfferFilter
    $first: Int!
    $jobOfferFilter: JobOfferFilter
  ) {
    users: usersList(first: $first, filter: $userFilter) {
      items {
        ...UserInfo
      }
    }
    jobOffers: jobOffersList(first: $first, filter: $jobOfferFilter) {
      items {
        ...CareerInfo
      }
    }
    internships: jobOffersList(first: $first, filter: $internshipsFilter) {
      items {
        ...CareerInfo
      }
    }
  }
  ${CAREER_INFO_FRAGMENT}
  ${USER_INFO_FRAGMENT}
`;

export const USER_SEARCH_QUERY = gql`
  query UserSearch($filter: UserFilter, $sort: [UserSort!], $first: Int, $skip: Int) {
    users: usersList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...UserInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

const TRENDING_SEARCHES_LIST_ITEM_FRAGMENT = gql`
  fragment TrendingSearchesListItem on TrendingSearch {
    id
    wordOrPhrase
    searchesCount
  }
`;

export const TRENDING_SEARCHES_LIST_QUERY = gql`
  query TrendingSearchesList(
    $filter: TrendingSearchFilter
    $skip: Int
    $sort: [TrendingSearchSort!]
    $first: Int
  ) {
    trendingSearchesList(first: $first, sort: $sort, filter: $filter, skip: $skip) {
      count
      items {
        ...TrendingSearchesListItem
      }
    }
  }
  ${TRENDING_SEARCHES_LIST_ITEM_FRAGMENT}
`;

export const TRENDING_SEARCHES_LIST_COUNT_QUERY = gql`
  query TrendingSearchesListCount($filter: TrendingSearchFilter) {
    trendingSearchesList(filter: $filter) {
      count
    }
  }
`;

export const TRENDING_SEARCHES_ADD_WORD_MUTATION = gql`
  mutation TrendingSearchesAddWord($data: TrendingSearchCreateInput!) {
    trendingSearchCreate(data: $data) {
      ...TrendingSearchesListItem
    }
  }
  ${TRENDING_SEARCHES_LIST_ITEM_FRAGMENT}
`;

export const TRENDING_SEARCHES_UPDATE_WORD_COUNT_MUTATION = gql`
  mutation TrendingSearchesUpdateWordCount($data: TrendingSearchUpdateInput!) {
    trendingSearchUpdate(data: $data) {
      ...TrendingSearchesListItem
    }
  }
  ${TRENDING_SEARCHES_LIST_ITEM_FRAGMENT}
`;
