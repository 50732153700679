import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { USER_AFFILIATIONS } from '@jebel/constants';

import {
  MembersCountByAffiliationQuery,
  MembersCountByAffiliationQueryVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { MEMBERS_COUNT_BY_AFFILIATION_QUERY } from '../queries';

export const useMembersCountByAffiliation = () => {
  const [membersCountByAffiliationSearch, { loading, data, ...rest }] = useLazyQuery<
    MembersCountByAffiliationQuery,
    MembersCountByAffiliationQueryVariables
  >(MEMBERS_COUNT_BY_AFFILIATION_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const onMembersCountByAffiliationSearch = React.useCallback(
    (startDate: string, endDate: string) => {
      try {
        membersCountByAffiliationSearch({
          variables: {
            alumniFilter: {
              affiliation: {
                equals: USER_AFFILIATIONS.alumni,
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            studentFilter: {
              affiliation: {
                equals: USER_AFFILIATIONS.student,
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            staffFilter: {
              affiliation: {
                equals: USER_AFFILIATIONS.staff,
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            parentFilter: {
              affiliation: {
                equals: USER_AFFILIATIONS.parent,
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            otherFilter: {
              affiliation: {
                equals: USER_AFFILIATIONS.other,
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [membersCountByAffiliationSearch],
  );

  const totalMembersByAffiliationCount: { affiliation: string; count: number }[] = [
    { affiliation: USER_AFFILIATIONS.alumni, count: data?.alumniUsers?.count ?? 0 },
    { affiliation: USER_AFFILIATIONS.student, count: data?.studentUsers?.count ?? 0 },
    { affiliation: USER_AFFILIATIONS.staff, count: data?.staffUsers?.count ?? 0 },
    { affiliation: USER_AFFILIATIONS.parent, count: data?.parentUsers.count ?? 0 },
    { affiliation: USER_AFFILIATIONS.other, count: data?.otherUsers.count ?? 0 },
  ];

  return {
    onMembersCountByAffiliationSearch,
    totalMembersByAffiliationCount,
    loading: loading && !data,
    ...rest,
  };
};
