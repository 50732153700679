import React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Option = { value: string; label: string };
type OptionAlias = Option | string;

const mapAliasToOption = (option: OptionAlias): Option => {
  if (typeof option === 'string') {
    return { value: option, label: option };
  }
  return option;
};

type Props = {
  label: string;
  value: string;
  options: Array<OptionAlias>;
  onChange: (value: string) => void;
  disabled?: boolean;
  height?: string;
};
export function SelectMenu({ options, value, onChange, label, disabled, height }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuItemClick = (option: string) => () => {
    onChange(option);
    handleClose();
  };
  const mappedOptions = React.useMemo(() => options.map(mapAliasToOption), [options]);
  return (
    <Box>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleButtonClick}
        variant="text"
        size="small"
        disabled={disabled}
        sx={{
          height: `${height ?? 'auto'}`,
          textTransform: 'none',
          color: 'info.main',
          fontSize: 'fontSize',
          fontWeight: 'medium',
        }}
      >
        {label}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        id="lock-menu"
        aria-labelledby="basic-button"
        role="listbox"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {mappedOptions.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={handleMenuItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
