import { useMutation } from '@apollo/client';

import { useCurrentUser } from 'shared/hooks';

import {
  AdClickCreateMutation,
  AdClickCreateMutationVariables,
} from '../../../shared/graphql/__generated__';
import { AD_CLICK_CREATE_MUTATION } from '../queries';

export const useUpdateAdClicks = () => {
  const { user } = useCurrentUser();

  const [createAdClick] = useMutation<AdClickCreateMutation, AdClickCreateMutationVariables>(
    AD_CLICK_CREATE_MUTATION,
  );

  const onAdClick = async (adId: string) => {
    if (!user?.id) {
      return;
    }

    await createAdClick({
      variables: {
        userId: user.id,
        adId,
      },
    });
  };

  return onAdClick;
};
