import { GroupKeyFilter, useGroupMemberPromoteMutation } from 'shared/graphql';
import { NormalizedGroupMember } from './useGroupMembers';
import { useToast } from 'shared/hooks';
import { recordDebug, recordError } from 'shared/utils/record';

interface UseGroupMemberPromoteOptions {
  group: GroupKeyFilter | undefined | null;
}

export function useGroupMemberPromote(options: UseGroupMemberPromoteOptions) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();

  const [promoteMutation] = useGroupMemberPromoteMutation({
    refetchQueries: ['GroupMembers'],
    awaitRefetchQueries: true,
  });

  const mutate = async (member: NormalizedGroupMember) => {
    if (!options.group) {
      recordDebug(`Group is not defined. Cannot promote member.`);
      return;
    }

    const messagePromotingKey = `PROMOTE_GROUP_MEMBER_REQUEST_${member.id}`;

    try {
      showMessage(`Promoting member to admin of the group.`, { id: messagePromotingKey });

      await promoteMutation({
        variables: {
          user: { id: member.user.id },
          group: { id: options.group.id },
        },
      });

      showSuccess(`Member has been promoted.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    dismiss(messagePromotingKey);
  };

  return {
    action: mutate,
  };
}
