import { useMemo } from 'react';
import { People } from '@material-ui/icons';

import { GroupInfoFragment } from 'shared/graphql';
import { generateGroupRoute } from 'shared/routes';

import { AuthorAvatar } from './AuthorAvatar';

interface Props {
  group: GroupInfoFragment | undefined | null;

  className?: string;
}

export function GroupAvatar(props: Props) {
  const source = props.group?.logo?.downloadUrl ?? undefined;
  const name = props.group?.title ?? '(Group)';

  const route = useMemo(() => {
    return generateGroupRoute(props.group?.id);
  }, [props.group]);

  return (
    <AuthorAvatar className={props.className} source={source} name={name} route={route}>
      <People />
    </AuthorAvatar>
  );
}
