import styled from '@emotion/styled';

import { PaperLayout as PaperLayoutBase } from 'shared/components/layouts';

// Container

export const Container = styled(PaperLayoutBase)``;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;
