import { useMemo } from 'react';

import { GroupAdminStatus, GroupStatus } from '@jebel/constants';

import { UserFilter, useGroupsQuery, GroupFilter, GroupDetailsFragment } from 'shared/graphql';
import { createFilterBuilder } from 'shared/queries/filterBuilder';

import { useCurrentUser } from './useCurrentUser';

type Options = Parameters<typeof useGroupsQuery>[0];

/** List all the groups that belongs to the current user. */
export function useCurrentUserGroups(options?: Options) {
  const { user, loading: userLoading } = useCurrentUser();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<GroupFilter>(options?.variables?.filter);

    if (user) {
      const filterUser: UserFilter = { id: { equals: user?.id } };

      filter.and({
        OR: [
          { createdBy: filterUser },
          {
            groupToJoin: {
              // https://github.com/8base-services/jebel/issues/1435
              some: { status: { equals: GroupStatus.Active }, user: filterUser },
            },
          },
          {
            groupAdminPreferences: {
              // https://github.com/8base-services/jebel/issues/1435
              some: { status: { equals: GroupAdminStatus.Active }, user: filterUser },
            },
          },
        ],
      });
    }

    return filter.build();
  }, [user, options?.variables?.filter]);

  const {
    data: response,
    loading: organizationsLoading,
    refetch,
    fetchMore,
  } = useGroupsQuery({
    ...options,

    skip: userLoading,
    variables: { ...options?.variables, filter },
  });

  const data: GroupDetailsFragment[] = response?.groupsList?.items ?? [];
  const count = response?.groupsList?.count ?? 0;

  return {
    data,
    count,
    loading: userLoading || organizationsLoading,
    refetch,
    fetchMore,
  };
}
