import { HomeFeedItemFragment } from 'shared/graphql';
import { useIntersectionWatcher } from 'shared/hooks';

import { Container, Header } from './HomeFeedCard.styles';
import { HomeFeedCardIndicator } from './HomeFeedCardIndicator';
import { HomeFeedCardAuthor } from './HomeFeedCardAuthor';
import { HomeFeedCardContent } from './HomeFeedCardContent';
import { HomeFeedCardDiscount } from './HomeFeedCardDiscount';
import { HomeFeedCardReactions } from './HomeFeedCardReactions';
import { HomeFeedCardOptions } from './HomeFeedCardOptions';
import { HomeFeedCardProvider, StreamActivityProvider } from '../../providers';
import { useHomeFeedItemActivity } from '../../hooks';

interface Props {
  data: HomeFeedItemFragment;

  /**
   * Hide the available comments for the post.
   * @default false
   */
  hideComments?: boolean;

  /**
   * Hide the available reactions for the post.
   * @default false
   */
  hideLikes?: boolean;

  /**
   * Hide the available options for the post.
   * @default false
   */
  hideOptions?: boolean;

  onClick?(): void;

  /** Handles the `onEdit` event, should be defined to show the "edit" option. */
  onEdit?(): void;
  /** Handles the `onReport` event, should be defined to show the "mark as inappropriate" option. */
  onReport?(): void;
  /** Handles the `onRemove` event, should be defined to show the "remove" option. */
  onRemove?(): void;
}

/** Shows the `HomeFeedItemFragment` as a beautiful card. */
export function HomeFeedCard(props: Props) {
  const withComments = !props.hideComments;
  const withReactions = !props.hideLikes;
  const withOptions = !props.hideOptions;

  const {
    ref,
    visible: haveBeenRender,
    haveBeenShown,
  } = useIntersectionWatcher<HTMLDivElement>({ once: false });

  const { data: activity, loading: activityLoading } = useHomeFeedItemActivity({
    skip: !haveBeenShown,
    post: props.data,
  });

  return (
    <HomeFeedCardProvider
      data={props.data}
      isHaveBeenRender={haveBeenRender}
      isHaveBeenShown={haveBeenShown}
    >
      <StreamActivityProvider data={activity} loading={activityLoading}>
        <Container ref={ref} onClick={props.onClick}>
          <HomeFeedCardIndicator />

          <Header>
            <HomeFeedCardAuthor />

            {withOptions && (
              <HomeFeedCardOptions
                onEdit={props.onEdit}
                onRemove={props.onRemove}
                onReport={props.onReport}
              />
            )}
          </Header>

          <HomeFeedCardDiscount />
          <HomeFeedCardContent />

          <HomeFeedCardReactions withComments={withComments} withLikes={withReactions} />
        </Container>
      </StreamActivityProvider>
    </HomeFeedCardProvider>
  );
}
