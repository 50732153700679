import { uniqBy } from 'ramda';

import { PAGE_SIZE } from 'shared/constants';
import { GraduatingYearsMetricsQuery, useGraduatingYearsMetricsQuery } from 'shared/graphql';

type Options = Parameters<typeof useGraduatingYearsMetricsQuery>[0];

export function useGraduatingYearsList(options?: Options) {
  const {
    data: response,
    loading,
    refetch,
    fetchMore,
  } = useGraduatingYearsMetricsQuery({
    ...options,

    variables: {
      first: PAGE_SIZE,

      ...options?.variables,
    },
  });

  const data = response?.metrics?.items ?? [];
  const count = response?.metrics?.count ?? 0;

  return {
    data,
    count,
    loading,

    fetchMore,
    refetch,
  };
}

export function useGraduatingYearsListPaginated(options?: Options) {
  const { data, count, loading, fetchMore } = useGraduatingYearsList(options);

  const next = async () => {
    await fetchMore({
      variables: {
        first: PAGE_SIZE,
        skip: data.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.metrics.items ?? [];
        const newest = curr?.metrics?.items ?? [];

        const count = curr?.metrics.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(organization => organization.id, [...oldest, ...newest]);

        const data: GraduatingYearsMetricsQuery = {
          ...curr,

          metrics: {
            ...prev.metrics,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  return {
    data,
    count,

    loading: loading && data.length === 0,
    refreshing: loading && data.length > 0,
    hasMore: data.length < count,

    next,
    fetchMore: next,
  };
}
