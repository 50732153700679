import { JOB_EMPLOYMENT_TYPE } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { DateTimePredicate, InputMaybe, JobOfferFilter } from 'shared/graphql';

import { organizationFilters } from './util';

export const getCareerSearchFilter = (
  search = '',
  isInternship = false,
  createdAtJobFilter: InputMaybe<DateTimePredicate> | undefined = undefined,
): JobOfferFilter => {
  const filter = createFilterBuilder<JobOfferFilter>();

  filter.and({
    employmentType: isInternship
      ? { equals: JOB_EMPLOYMENT_TYPE.intern }
      : { equals: JOB_EMPLOYMENT_TYPE.fullTime },
  });

  if (search) {
    const searchFilter = createFilterBuilder<JobOfferFilter>();

    searchFilter.or({ position: { contains: search } });
    searchFilter.or({ description: { contains: search } });
    searchFilter.or({ organization: organizationFilters(search) });
    searchFilter.or({ createdBy: { firstName: { contains: search } } });
    searchFilter.or({ createdBy: { lastName: { contains: search } } });
    searchFilter.or({ createdBy: { fullName: { starts_with: search } } });

    filter.and(searchFilter);
  }

  if (createdAtJobFilter) {
    filter.and({ createdAt: createdAtJobFilter });
  }

  return filter.build();
};
