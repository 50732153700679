import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { debounce } from 'lodash';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import { SORT } from '@jebel/constants';

import { SortInfoOption } from 'shared/components/ui';
import { useTrendingSearchesCreate } from 'shared/features/search';

import { Icon } from '../Icon';
import { TextField } from '../TextField';

type SearchTextFieldProps = {
  placeholder?: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  onSearch?: (searchQuery: string, sortInfo: any) => void;
  onResultCount?: (searchQuery: string) => void;
  setIsUserTyping: React.Dispatch<React.SetStateAction<boolean>>;
  sortOption?: SortInfoOption;
};

export const SearchTextField = ({
  placeholder,
  searchQuery,
  setSearchQuery,
  onSearch,
  onResultCount = () => {},
  setIsUserTyping,
  sortOption = { date: SORT.desc },
}: SearchTextFieldProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation<any>();

  const { onAddSearchingWord } = useTrendingSearchesCreate();

  const fetchSearchResults = React.useCallback(
    (searchQuery: string) => {
      setIsUserTyping(false);

      onSearch?.(searchQuery, sortOption);
      onResultCount && onResultCount(searchQuery);

      onAddSearchingWord({ wordOrPhrase: searchQuery });
    },
    [onAddSearchingWord, onResultCount, onSearch, setIsUserTyping, sortOption],
  );

  const debouncedFetchSearchResults = React.useCallback(
    debounce(
      (searchQuery: string) => {
        fetchSearchResults(searchQuery);
      },
      1500,
      { leading: false, trailing: true },
    ),
    [fetchSearchResults],
  );

  const onSearchQueryChange = React.useCallback(
    (newSearchQuery, disableRequestDebounce = false) => {
      setIsUserTyping(true);
      setSearchQuery(newSearchQuery);

      disableRequestDebounce
        ? fetchSearchResults(newSearchQuery)
        : debouncedFetchSearchResults(newSearchQuery);
    },
    [debouncedFetchSearchResults, fetchSearchResults, setIsUserTyping, setSearchQuery],
  );

  const onChange = React.useCallback(
    event => {
      const newSearchQuery = event.target.value;
      onSearchQueryChange(newSearchQuery);
    },
    [onSearchQueryChange],
  );

  React.useEffect(() => {
    history.replace({ ...location, state: { ...location.state, search: searchQuery } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match.url, searchQuery]);

  React.useEffect(() => {
    onSearchQueryChange(location.state?.search, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchSearchResults(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption, onSearch]);

  return (
    <TextField
      type="search"
      variant="outlined"
      fullWidth
      value={searchQuery}
      onChange={onChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name="Search" variant="filled" />
          </InputAdornment>
        ),
      }}
    />
  );
};
