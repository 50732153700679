import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { IconButton, Box, List, ListItem } from '@material-ui/core';
import { Avatar } from '@mui/material';
import { Edit, PlaylistRemove, School } from '@mui/icons-material';
import { MoreVert } from '@material-ui/icons';

import { formatSQLDate } from '@jebel/utils';

import { Modal, Popover, Typography } from 'shared/components/ui';
import { EducationalInstitution, EducationalInstitutionUpdateInput } from 'shared/graphql';
import { useModalState, useResponsive, useToast } from 'shared/hooks';
import { extractYear } from 'shared/utils/date';
import { recordError } from 'shared/utils/record';

import {
  UserManageEducationForm,
  UserManageEducationValues,
  createEducationHighSchoolRelation,
} from './UserManageEducationForm';
import { useEducationProfileRemove, useEducationProfileUpdate } from '../../hooks';

const schoolNameCSS = (theme: Theme) => css`
  font-weight: 500;
  font-size: ${theme.typography.fontSize + 2}px;
  color: ${theme.palette.primary.dark};
  line-height: ${theme.spacing(2)}px;
`;

const degreeCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  line-height: ${theme.spacing(2.5)}px;
`;

const schoolAvatarCSS = (theme: Theme) =>
  css`
    --image-size: 5rem;

    width: var(--image-size);
    height: var(--image-size);
    border-radius: 0.25rem;
    border: solid 1px;
    border-color: ${theme.palette.divider};

    ${theme.breakpoints.down('md')} {
      --image-size: 3rem;
    }
  `;

const locationCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize + 2}px;
  color: ${theme.palette.text.secondary};
  line-height: ${theme.spacing(2)}px;
`;

const periodCSS = (theme: Theme) => css`
  font-weight: 500;
  color: ${theme.palette.text.secondary};
  line-height: ${theme.spacing(2.5)}px;
`;

type EducationCardProps = Pick<
  EducationalInstitution,
  | 'gpa'
  | 'address'
  | 'startDate'
  | 'endDate'
  | 'degree'
  | 'id'
  | 'fieldOfStudy'
  | 'activities'
  | 'description'
  | 'highSchool'
  | 'image'
> & {
  /** @default false */
  withEdit?: boolean;

  /** @default false */
  withRemove?: boolean;
};

export function EducationCard(props: EducationCardProps) {
  const { isMobile } = useResponsive();
  const { showError } = useToast();
  const { execute: editEducation } = useEducationProfileUpdate();
  const { execute: removeEducation, loading: removing } = useEducationProfileRemove();

  const {
    show: isEducationEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useModalState();

  const withEdit = props.withEdit ?? false;
  const withRemove = props.withRemove ?? false;

  const withOptions = withEdit || withRemove;

  const city = props?.address?.city;
  const state = props?.address?.state ?? 'All';
  const startYear = extractYear(props?.startDate);
  const endYear = extractYear(props?.endDate);

  const initials = useMemo<UserManageEducationValues>(() => {
    const payload: UserManageEducationValues = {
      degree: props.degree,
      fieldOfStudy: props.fieldOfStudy,
      gpa: String(props.gpa),
      activities: props.activities,
      description: props.description,
      startDate: formatSQLDate(props.startDate),
      endDate: formatSQLDate(props.endDate),
      image: props.image,
      address: props.address,
    };

    if (props.highSchool) {
      payload.highSchool = props.highSchool;
    }

    return payload;
  }, [props]);

  const handleEdit = async (form: UserManageEducationValues) => {
    const payload: EducationalInstitutionUpdateInput = {
      id: props.id,
      degree: form.degree,
      fieldOfStudy: form.fieldOfStudy,
      activities: form.activities,
      description: form.description,
      gpa: form.gpa ? Number(form.gpa) : null,

      address: {
        create: {
          city: form.address?.city,
          state: form.address?.state,
          zip: form.address?.zip,
        },
      },
    };

    if (form.startDate) {
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45814
      payload.startDate = formatSQLDate(form.startDate);
    }

    if (form.endDate) {
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45814
      payload.endDate = formatSQLDate(form.endDate);
    }

    if (form.image && form.image?.fileId !== props.image?.fileId) {
      // Create the given image and detach the previous one.
      // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43763
      payload.image = {
        create: {
          fileId: form.image.fileId,
          filename: form.image.filename,
          public: true,
        },
      };
    }

    if (!form.image && props.image?.fileId) {
      // Once the image has been detach it will also removed
      payload.image = {
        disconnect: { fileId: props.image.fileId },
      };
    }

    // https://8base-dev.atlassian.net/browse/JEB-1487?focusedCommentId=42751
    payload.highSchool = createEducationHighSchoolRelation(form, props.highSchool);

    try {
      await editEducation(payload);
      closeEditModal();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const handleRemove = async () => {
    await removeEducation(props);
  };

  if (removing) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography align="center">This education profile has been removed</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Modal
        dialogProps={{
          maxWidth: 'sm',
          open: isEducationEditModalOpen,
          onClose: closeEditModal,
          fullWidth: true,
        }}
        titleProps={{ title: 'Edit Education' }}
        isVisibleDivider
      >
        <UserManageEducationForm
          initialValues={initials}
          onSave={handleEdit}
          onCancel={closeEditModal}
          isEdit
        />
      </Modal>

      <Box
        display="grid"
        gridTemplateColumns="min-content auto"
        alignItems="center"
        gridColumnGap={12}
      >
        <Avatar
          css={schoolAvatarCSS}
          src={props?.image?.downloadUrl ?? undefined}
          variant="circular"
        >
          <School />
        </Avatar>

        {isMobile ? (
          <Box display="grid" gridTemplateRows="auto auto auto" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography css={schoolNameCSS}>{props.highSchool?.name}</Typography>

              {withOptions && (
                <Popover
                  target={
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  }
                >
                  <List>
                    {withEdit && (
                      <ListItem button onClick={openEditModal}>
                        Edit
                      </ListItem>
                    )}

                    {withRemove && (
                      <ListItem button onClick={handleRemove}>
                        Remove
                      </ListItem>
                    )}
                  </List>
                </Popover>
              )}
            </Box>

            <Typography css={locationCSS}>
              {city}, {state}
            </Typography>

            <Typography css={periodCSS}>
              {startYear} - {endYear}
            </Typography>
          </Box>
        ) : (
          <Box
            display="grid"
            gridTemplateRows="min-content min-content min-content"
            alignItems="start"
            mt={-1}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                display="inline-flex"
                justifyContent="start"
                gridTemplateColumns="auto auto"
                alignItems="center"
                gridGap={4}
                mt={1}
              >
                <Typography css={schoolNameCSS}>{props.highSchool?.name}</Typography>

                <Typography css={locationCSS}>
                  {city}, {state}
                </Typography>
              </Box>

              {withOptions && (
                <Box display="flex" gridGap="0.25rem">
                  {withEdit && (
                    <IconButton color="secondary" onClick={openEditModal}>
                      <Edit />
                    </IconButton>
                  )}

                  {withRemove && (
                    <IconButton color="secondary" onClick={handleRemove}>
                      <PlaylistRemove />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>

            <Typography css={periodCSS}>
              {extractYear(props.startDate)} - {extractYear(props.endDate)}
            </Typography>

            <Typography css={degreeCSS}>{props.degree}</Typography>

            {Boolean(props.gpa) && <Typography css={degreeCSS}>GPA {props.gpa}</Typography>}
          </Box>
        )}
      </Box>

      {isMobile && (
        <Box display="flex" flexDirection="column">
          <Typography css={degreeCSS}>{`${props.degree}`}</Typography>
          {Boolean(props.gpa) && <Typography css={degreeCSS}>GPA {props.gpa}</Typography>}
        </Box>
      )}
    </Box>
  );
}
