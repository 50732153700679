import { useMemo } from 'react';

import { PAGE_SIZE } from 'shared/constants';
import { GroupKeyFilter, HomeFeedItemFilter } from 'shared/graphql';
import { usePostsFeed } from 'shared/hooks';
import { createFilterBuilder } from 'shared/queries/filterBuilder';

interface Props {
  skip?: boolean;
  searchFirst?: number;
  searchQuery?: string;
  filter?: HomeFeedItemFilter;
  group: GroupKeyFilter;
}

export function useGroupFeed(props: Props) {
  const search = props?.searchQuery;
  const currentFilter = props?.filter;

  const filter = useMemo<HomeFeedItemFilter>(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>(currentFilter);

    if (props.group) {
      filter.and({ groupId: { equals: props.group.id } });
    }

    if (search) {
      const searchFilter = createFilterBuilder<HomeFeedItemFilter>();
      const words = search.split(/\s+/i);

      searchFilter.or({ text: { contains: search } });
      searchFilter.or({ authorEmail: { contains: search } });
      searchFilter.or({ authorFullName: { starts_with: search } });

      for (const word of words) {
        searchFilter.or({ text: { contains: word } });
      }

      filter.and(searchFilter);
    }

    return filter.build();
  }, [search, currentFilter, props.group]);

  const { data, count, loading, refreshing, refetch, fetchMore } = usePostsFeed({
    variables: {
      filter,
      first: props?.searchFirst ?? PAGE_SIZE,
    },
  });

  return {
    data,
    count,
    hasMore: data.length < count,
    loading,
    refreshing,

    refetch,
    fetchMore,
  };
}
