import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Box, Chip, css } from '@mui/material';

import { Icon, Typography } from 'shared/components/ui';
import { APP_URL, SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useSearchContext, useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, SpreadsheetCellActions } from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  GroupAdminPreferenceDeleteMutation,
  GroupUpdateMutation,
  GroupUpdateMutationVariables,
  GroupAdminPreferenceDeleteMutationVariables,
  GroupMembersListQuery,
  GroupMembersListCountQuery,
  GroupMembersListCountQueryVariables,
  User,
} from 'shared/graphql/__generated__';
import { buildUrl } from 'shared/routes';
import { useCrudPermissions, useCurrentUser } from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';

import { GroupMembersSpreadsheetHeader } from '../constants';
import {
  GROUP_UPDATE_MUTATION,
  DELETE_ADMIN_PREFERENCE_MUTATION,
  GROUP_MEMBERS_LIST_QUERY,
  GROUP_MEMBERS_LIST_COUNT_QUERY,
} from '../queries';

const chipCss = theme => css`
  border-radius: ${theme.spacing(0.5)}px;
  background: ${theme.palette.primary.icon};
  color: #fff;
  font-size: ${theme.typography.fontSize - 2}px;
  margin-left: ${theme.spacing(0.5)}px;
  height: 16px;

  span {
    padding: ${theme.spacing(0.5)}px;
  }
`;

export const GroupAdminList = ({ id }: { id: string }) => {
  const {
    groupsPermissions: { edit: isEditAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();
  const { onOpenInboxModal } = useInboxContext();
  const history = useHistory();
  const { userId } = useCurrentUser();
  const { queryParams, currentRowId } = useSpreadsheetContext();

  const { searchQuery } = useSearchContext();

  const { tableData, tableLoading } = useSpreadsheetSearch<GroupMembersListQuery>({
    query: GROUP_MEMBERS_LIST_QUERY,
    searchingFields: ['firstName', 'lastName', 'fullName'],
    queryVariables: {
      ...queryParams,
      id,
      filter: { groupAdminPreferences: { group: { some: { id: { equals: id } } } } },
    },
  });

  const { data: adminsCount, loading: countLoading } = useQuery<
    GroupMembersListCountQuery,
    GroupMembersListCountQueryVariables
  >(GROUP_MEMBERS_LIST_COUNT_QUERY, {
    variables: {
      filter: searchQuery
        ? {
            OR: [{ firstName: { contains: searchQuery } }, { lastName: { contains: searchQuery } }],
            groupAdminPreferences: { group: { some: { id: { equals: id } } } },
          }
        : { groupAdminPreferences: { group: { some: { id: { equals: id } } } } },
    },
  });

  const [deleteGroupAdminPreferences] = useMutation<
    GroupAdminPreferenceDeleteMutation,
    GroupAdminPreferenceDeleteMutationVariables
  >(DELETE_ADMIN_PREFERENCE_MUTATION);

  const [updateGroup] = useMutation<GroupUpdateMutation, GroupUpdateMutationVariables>(
    GROUP_UPDATE_MUTATION,
    {
      refetchQueries: ['GroupsList', 'GroupMembersList'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been updated.',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
      },
    },
  );

  const currentMember = React.useMemo(
    () => tableData?.group?.members?.items.find(member => member.id === currentRowId),
    [currentRowId, tableData?.group?.members?.items],
  );

  const spreadsheetActions = React.useMemo((): SpreadsheetCellActions => {
    const resultActions = [
      {
        id: 'View Profile',
        title: 'View Profile',
        onClickAction: (id: string) => {
          const url = buildUrl(APP_URL.user.explorer.profile, {
            pathParams: { id: currentMember?.id as string },
          });
          history.push(url);
        },

        icon: <Icon name="AccountCircle" size={20} />,
      },
    ];

    if (isEditAccessed) {
      resultActions.push({
        id: 'Remove',
        title: 'Remove',
        onClickAction: async (memberId: string) => {
          await updateGroup({
            variables: {
              data: {
                groupAdminPreferences: {
                  disconnect: [
                    {
                      id: currentMember?.groupAdminPreferences?.id,
                    },
                  ],
                },
                id,
              },
            },
          });

          if (currentMember?.groupAdminPreferences) {
            await deleteGroupAdminPreferences({
              variables: { data: { id: currentMember?.groupAdminPreferences?.id } },
            });
          }
        },
        icon: <Icon name="DeleteForever" size={20} />,
      });
    }

    if (currentMember?.groupAdminPreferences?.id !== userId) {
      resultActions.push({
        id: 'Send Message',
        title: 'Send Message',
        onClickAction: (id: string) => {
          if (onOpenInboxModal && currentMember) {
            onOpenInboxModal({
              isOpen: true,
              options: {
                members: [currentMember] as User[],
                messageType: 'personal',
              },
            });
          }
        },
        icon: <Icon name="Forum" size={20} />,
      });
    }

    return resultActions;
  }, [
    currentMember,
    deleteGroupAdminPreferences,
    history,
    id,
    isEditAccessed,
    onOpenInboxModal,
    updateGroup,
    userId,
  ]);

  const memberName = React.useCallback(
    admin => (
      <Box display="flex" alignItems="center">
        <Typography>
          {admin?.firstName || ''} {admin?.lastName || ''}
        </Typography>
        {admin?.id === userId && <Chip css={chipCss} label="You" />}
      </Box>
    ),
    [userId],
  );

  const newData = React.useMemo(() => {
    return (
      tableData?.group?.members?.items.map(member => ({
        id: member.id || '',
        name: memberName(member),
        type: member.affiliation || '',
        posts: tableData?.group?.posts?.count.toString() || '0',
      })) || []
    );
  }, [tableData?.group?.members?.items, tableData?.group?.posts?.count]);

  // TODO add action messaging
  const mainToolbarAction = React.useMemo(
    () => ({
      icon: <Icon name="Forum" size={20} color="secondary" />,
      label: 'Message',
      onClick: (ids: string[]) => {
        const choosedMembers = tableData?.group?.members?.items?.filter(member =>
          ids.includes(member?.id as string),
        );

        if (onOpenInboxModal && choosedMembers) {
          onOpenInboxModal({
            isOpen: true,
            options: {
              members: choosedMembers as User[],
              messageType: 'personal',
            },
          });
        }
      },
    }),
    [onOpenInboxModal, tableData?.group?.members?.items],
  );

  return (
    <Spreadsheet
      data={newData}
      headlines={GroupMembersSpreadsheetHeader}
      toolbarOptions={{
        mainToolbarAction,
        withPerPage: true,
      }}
      cellActions={spreadsheetActions}
      itemsCount={adminsCount?.group?.members?.count || 0}
      loading={tableLoading || countLoading}
    />
  );
};
