import React from 'react';
import { Box } from '@material-ui/core';
import { css } from '@emotion/react';

import { SearchCareerFilter } from 'features/career/types';
import { Button } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';

import { CareerFilter } from '../CareerDetails';

interface CareerServicesFilterModalProps {
  onModalClose: () => void;
  selectedFilters: SearchCareerFilter;
  setFilters: React.Dispatch<React.SetStateAction<SearchCareerFilter>>;
  loading: boolean;
  setLocalZipQuery: React.Dispatch<React.SetStateAction<string>>;
}

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

export const CareerServicesFilterModal: React.FC<CareerServicesFilterModalProps> = ({
  onModalClose,
  selectedFilters,
  setFilters,
  loading,
  setLocalZipQuery,
}) => {
  const { zipQuery } = useSearchContext();
  const [localFilter, setLocalFilter] = React.useState<SearchCareerFilter>(selectedFilters);

  const onApplyFilter = React.useCallback(() => {
    setFilters(localFilter);
    setLocalZipQuery(zipQuery);
    onModalClose();
  }, [localFilter, onModalClose, setFilters, setLocalZipQuery, zipQuery]);

  return (
    <React.Fragment>
      <CareerFilter selectedFilter={localFilter} setFilters={setLocalFilter} />
      <Box display="flex" justifyContent="end">
        <Button css={cancelButtonCSS} onClick={onModalClose}>
          CANCEL
        </Button>
        <Button
          loading={loading}
          onClick={onApplyFilter}
          css={submitButtonCSS}
          disableElevation
          variant="contained"
        >
          APPLY
        </Button>
      </Box>
    </React.Fragment>
  );
};
