import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, FormHelperText } from '@material-ui/core';

import { Button, Modal, TextField } from 'shared/components/ui';
import { SchoolConfigurationLayout } from 'shared/graphql/__generated__';
import { NAVIGATION_LABEL_LIST } from 'admin-features/settings/constants';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(2)}px;
`;

type LabelEditModalProps = {
  item: SchoolConfigurationLayout;
  isOpen: boolean;
  onClose: () => void;
  onSave: (item: SchoolConfigurationLayout) => void;
};

export const LabelEditModal = ({ item, isOpen, onClose, onSave }: LabelEditModalProps) => {
  const [newName, setNewName] = useState('');
  const [isError, setError] = useState(false);

  useEffect(() => {
    setNewName(item?.name || '');
    setError(false);
  }, [isOpen, item]);

  const onChange = e => {
    setNewName(e.target.value);
    setError(!e.target.value.trim());
  };

  const onSaveInner = () => {
    if (!isError) {
      onSave({ ...item, name: newName });
    }
  };

  const routeName = useMemo(() => {
    const key = Object.keys(NAVIGATION_LABEL_LIST).find(route => route === item.route?.slice(1));

    if (!key) {
      return '(Unknown Route)';
    }

    return NAVIGATION_LABEL_LIST[key];
  }, [item]);

  return (
    <Modal
      dialogProps={{
        open: isOpen,
        onClose,
        fullWidth: true,
      }}
      titleProps={{
        title: `Rename label: ${routeName}`,
      }}
      isVisibleDivider
    >
      <Box display="grid" gridTemplateRows="auto" gridGap={2} my={2}>
        <TextField
          label="Label"
          variant="outlined"
          value={newName}
          onChange={onChange}
          error={isError}
          helperText={isError ? 'Label could not be empty' : null}
        />

        <FormHelperText>
          Changes will be reflected for all users when you hit the &quot;Save Changes&quot; button
          at the end of the form.
        </FormHelperText>
      </Box>

      <Box display="flex" justifyContent="end">
        <Button css={commonButtonsCSS} onClick={onClose} size="medium">
          Cancel
        </Button>

        <Button
          css={commonButtonsCSS}
          disableElevation
          variant="contained"
          type="submit"
          onClick={onSaveInner}
          disabled={isError}
        >
          Rename
        </Button>
      </Box>
    </Modal>
  );
};
