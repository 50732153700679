import { gql } from '@apollo/client';

export const ADMIN_DISCOUNT_FRAGMENT = gql`
  fragment AdminDiscount on Discount {
    id
    createdAt
    title
    type
    amountPercent
    startDate
    expirationDate
    timezone
    category
    status
    images {
      items {
        id
        fileId
        filename
        public
        downloadUrl
      }
    }
    isSchoolDiscount
    organization {
      id
      name
    }
    usersRedeemedCount
    terms
  }
`;

export const ADMIN_DISCOUNTS_LIST_QUERY = gql`
  query AdminDiscountsSpreadsheetList(
    $first: Int
    $skip: Int
    $filter: DiscountFilter
    $sort: [DiscountSort!]
  ) {
    discounts: discountsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...AdminDiscount
      }
    }
  }
  ${ADMIN_DISCOUNT_FRAGMENT}
`;

export const ADMIN_DISCOUNTS_LIST_COUNT_QUERY = gql`
  query AdminDiscountsSpreadsheetListCount($filter: DiscountFilter) {
    discountsList(filter: $filter) {
      count
    }
  }
`;

export const DISCOUNT_CREATE_REQUEST_MUTATION = gql`
  mutation DiscountCreateRequest($data: DiscountCreateRequestInput!) {
    discountCreateRequest(data: $data) {
      id
    }
  }
`;

export const ADMIN_DISCOUNT_UPDATE_MUTATION = gql`
  mutation AdminUpdateDiscount($data: DiscountUpdateInput!) {
    discountUpdate(data: $data) {
      ...AdminDiscount
    }
  }

  ${ADMIN_DISCOUNT_FRAGMENT}
`;

export const DISCOUNT_UPDATE_STATUS_REQUEST_MUTATION = gql`
  mutation DiscountUpdateStatusRequest($data: DiscountUpdateStatusRequestInput!) {
    discountUpdateStatusRequest(data: $data) {
      success
    }
  }
`;
