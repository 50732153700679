import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { DISCOUNT_STATUSES } from '@jebel/constants';

import {
  SpecialOffersCountQuery,
  SpecialOffersCountQueryVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { SPECIAL_OFFERS_COUNT_QUERY } from '../queries';

export const useSpecialOffersCount = () => {
  const [specialOffersCountSearch, { loading, data, ...rest }] = useLazyQuery<
    SpecialOffersCountQuery,
    SpecialOffersCountQueryVariables
  >(SPECIAL_OFFERS_COUNT_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const onSpecialOffersCountSearch = React.useCallback(
    (startDate: string, endDate: string) => {
      try {
        specialOffersCountSearch({
          variables: {
            activeFilter: {
              status: {
                in: [DISCOUNT_STATUSES.active, DISCOUNT_STATUSES.expired],
              },
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
            redeemedFilter: {
              AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [specialOffersCountSearch],
  );

  const redeemedOffersCount =
    data?.redeemedSpecialOffersCount.items.reduce(
      (sum, discount) => sum + (discount?.usersRedeemed?.count || 0),
      0,
    ) || 0;

  return {
    onSpecialOffersCountSearch,
    activeOffersCount: data?.activeSpecialOffersCount?.count ?? 0,
    redeemedOffersCount,
    loading: loading && !data,
    ...rest,
  };
};
