import { Fragment, useMemo, useState } from 'react';
import { Theme, css } from '@emotion/react';
import { AddCircle } from '@material-ui/icons';
import { descend } from 'ramda';
import { Box, IconButton, Link } from '@material-ui/core';

import { formatSQLDate } from '@jebel/utils';

import { Typography, Modal } from 'shared/components/ui';
import { EducationalInstitutionCreateInput, Maybe, EducationProfileFragment } from 'shared/graphql';
import { formatMilliseconds } from 'shared/utils/date';
import { recordError } from 'shared/utils/record';
import { useToast } from 'shared/hooks';

import {
  UserManageEducationForm,
  UserManageEducationValues,
  createEducationHighSchoolRelation,
} from './UserManageEducationForm';
import { EducationCard } from './EducationCard';
import { useEducationProfileCreate } from '../../hooks';

const educationTopLayoutCSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;

const referenceIconsBoxCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
`;

type Props = {
  educationProfile?: Maybe<EducationProfileFragment[]>;
  /** @default false */
  withAdd?: Maybe<boolean>;
  /** @default false */
  withEdit?: Maybe<boolean>;
  /** @default false */
  withRemove?: Maybe<boolean>;
};

export function SettingsPublicProfileSectionsEducation(props: Props) {
  const [isEducationAddModalOpen, setIsEducationAddModalOpen] = useState<boolean>(false);

  const { showError } = useToast();
  const { onEducationCreate } = useEducationProfileCreate();

  const withAdd = props.withAdd ?? false;
  const withEdit = props.withEdit ?? false;
  const withRemove = props.withRemove ?? false;

  const history = useMemo(() => {
    const history = Array.from(props.educationProfile ?? []);

    return history.sort(
      // Sort by `endDate` descending
      descend(education => formatMilliseconds(education.endDate)),
    );
  }, [props.educationProfile]);

  const openCreateModal = () => {
    setIsEducationAddModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsEducationAddModalOpen(false);
  };

  const onCreate = async (form: UserManageEducationValues) => {
    const payload: EducationalInstitutionCreateInput = {
      degree: form.degree,
      fieldOfStudy: form.fieldOfStudy,
      activities: form.activities,
      description: form.description,
      startDate: formatSQLDate(),
      endDate: formatSQLDate(),
      gpa: form.gpa ? Number(form.gpa) : null,

      address: {
        create: {
          city: form.address?.city,
          state: form.address?.state,
          zip: form.address?.zip,
        },
      },
    };

    if (form.startDate) {
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45814
      payload.startDate = formatSQLDate(form.startDate);
    }

    if (form.endDate) {
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45814
      payload.endDate = formatSQLDate(form.endDate);
    }

    if (form.image) {
      // https://8base-dev.atlassian.net/browse/JEB-1554?focusedCommentId=43564
      payload.image = {
        create: {
          fileId: form.image.fileId,
          filename: form.image.filename,
          public: true,
        },
      };
    }

    if (form.highSchool) {
      // https://8base-dev.atlassian.net/browse/JEB-1487?focusedCommentId=42751
      payload.highSchool = createEducationHighSchoolRelation(form);
    }

    try {
      await onEducationCreate(payload);
      closeCreateModal();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: isEducationAddModalOpen,
          onClose: closeCreateModal,
          fullWidth: true,
        }}
        titleProps={{ title: 'Add Education' }}
        isVisibleDivider
        dialogContentWidth={280}
      >
        <UserManageEducationForm onSave={onCreate} onCancel={closeCreateModal} />
      </Modal>

      <Box css={educationTopLayoutCSS}>
        <Typography variant="h6">Education</Typography>

        <Box css={referenceIconsBoxCSS}>
          {withAdd && (
            <IconButton color="primary" onClick={openCreateModal}>
              <AddCircle />
            </IconButton>
          )}
        </Box>
      </Box>

      {history.length === 0 && (
        <Typography color="textSecondary">
          <span>No education has been added yet.&nbsp;</span>

          {withAdd && (
            <Link color="secondary" onClick={openCreateModal}>
              Create a new one.
            </Link>
          )}
        </Typography>
      )}

      {history.map(item => (
        <EducationCard
          key={item.id}
          id={item.id}
          address={item?.address}
          startDate={item?.startDate}
          endDate={item?.endDate}
          degree={item?.degree}
          gpa={item?.gpa}
          highSchool={item?.highSchool}
          fieldOfStudy={item?.fieldOfStudy}
          activities={item?.activities}
          description={item?.description}
          image={item?.image}
          withEdit={withEdit}
          withRemove={withRemove}
        />
      ))}
    </Fragment>
  );
}
