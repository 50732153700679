import { difference } from 'ramda';

import { fileManyRelations } from '@jebel/utils';

import {
  OrganizationItemFragment,
  OrganizationLocationCreateInput,
  OrganizationLocationKeyFilter,
  OrganizationLocationUpdateInput,
  OrganizationUpdateInput,
  Organization_OrganizationLocationUpdateInput,
} from 'shared/graphql';

import { BUSINESS_FORM_LOCATION_BLANK } from '../constants';
import { BusinessFormData, BusinessFormLocation } from '../types';

/**
 * Generate the initial values based on the `business`.
 * @returns An object that satisfies the `BusinessFormData`.
 */
export function generateBusinessFormInitials(
  business: OrganizationItemFragment | undefined,
): BusinessFormData {
  const data: BusinessFormData = {
    name: business?.name,
    description: business?.description,
    industry: business?.industry,
    status: business?.status,
    location: [],
  };

  const locations = business?.location?.items ?? [];

  if (locations.length > 0) {
    data.location = locations.map<BusinessFormLocation>(location => {
      const [address] = location.address?.items ?? [];

      return {
        id: location.id,
        locationName: location.locationName,
        operationalHours: location.operationalHours,
        contactName: location.contactName,
        email: location.email,
        link: location.link,
        phoneNumber: location.phoneNumber,
        address: {
          city: address.city,
          street1: address.street1,
          zip: address.zip,
          state: address.state,
        },
      };
    });
  }

  if (data?.location?.length === 0) {
    data.location = [{ ...BUSINESS_FORM_LOCATION_BLANK }];
  }

  return data;
}

/**
 * Generate the values needed for the update values based that satisfies `OrganizationUpdateInput`.
 * @throws When the `initial` value does not contains the needed information.
 * @returns An object that satisfies `OrganizationUpdateInput`.
 */
export function generateBusinessFormUpdate(
  form: BusinessFormData,
  initial: OrganizationItemFragment | undefined,
): OrganizationUpdateInput {
  if (!initial) {
    throw new Error(`No business data was provided`);
  }

  const payload: OrganizationUpdateInput = {
    id: initial.id,
    name: form.name as string,
    industry: form.industry as string,
    description: form.description as string,
  };

  if (Array.isArray(form.location)) {
    const create: OrganizationLocationCreateInput[] = [];
    const update: Organization_OrganizationLocationUpdateInput[] = [];
    const disconnect: OrganizationLocationKeyFilter[] = [];

    const locations = initial?.location?.items ?? [];

    for (const curr of locations) {
      const exists = form.location?.some(location => location.id === curr.id);

      if (exists) {
        continue;
      }

      disconnect.push({ id: curr.id });
    }

    for (const curr of form.location) {
      const prev = locations.find(location => location.id === curr.id);
      const diffs = difference([curr], [BUSINESS_FORM_LOCATION_BLANK]);

      if (diffs.length === 0) {
        continue;
      }

      const payload: OrganizationLocationCreateInput | OrganizationLocationUpdateInput = {
        locationName: curr.locationName as string,
        contactName: curr.contactName as string,
        email: curr.email as string,
        link: curr.link as string,
        phoneNumber: curr.phoneNumber,
        operationalHours: curr.operationalHours as string,
      };

      if (prev) {
        const [address] = prev.address?.items ?? [];

        if (address.id) {
          payload.address = {
            update: [{ data: curr.address, filter: { id: address.id } }],
          };
        }

        update.push({ data: payload, filter: { id: prev.id } });
        continue;
      }

      create.push({
        locationName: curr.locationName as string,
        contactName: curr.contactName as string,
        email: curr.email as string,
        link: curr.link as string,
        phoneNumber: curr.phoneNumber,
        operationalHours: curr.operationalHours as string,
        address: { create: [curr.address] },
      });
    }

    payload.location = { create, update, disconnect };
  }

  if (form.logo) {
    payload.logo = { create: { fileId: form.logo, public: true } };
  }

  if (form.media) {
    payload.media = fileManyRelations(form.media);
  }

  return payload;
}
