import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography, Divider, Skeleton } from '@mui/material';

import { CommentButton } from 'shared/features/posts/components';
import {
  OnCommentRemove,
  OnCustomReactionAdd,
  OnLikeAdd,
  OnReactionRemove,
} from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { getTextTimeAgo } from 'shared/utils/date';
import { useCommentAuthorQuery } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { MediaGallery } from 'shared/components/ui';
import { MentionsText } from 'shared/features/mentions';
import { UserAvatar, UserNameLink } from 'shared/components/symbols';

import { HelpfulButton } from './HelpfulButton';

const nameCSS = theme => css`
  color: ${theme.palette.primary.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const postedAtCss = theme => css`
  color: ${theme.palette.text.secondary};
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
`;

const AVATAR_SIZE = 32;

const avatarCss = theme => css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  margin-right: ${theme.spacing(1)}px;
`;

const DIVIDER_CONTAINER_SIZE = 24;

const dividerContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${DIVIDER_CONTAINER_SIZE}px;
`;

const DIVIDER_SIZE = 4;

const dividerCss = theme => css`
  height: ${DIVIDER_SIZE}px;
  width: ${DIVIDER_SIZE}px;
  border-radius: ${DIVIDER_SIZE / 2}px;
  background-color: ${theme.palette.text.secondary};
`;

const commentContentBoxCss = css`
  background-color: #f6f8fa;
  border-radius: 6px;
`;

const COMMENT_IMAGE_SIZE = 120;

export type CommentContentProps = {
  comment: CommentEnrichedReaction;
  postId: string;
  onCommentAdd: (authorName?: string | undefined) => void;
  onCommentRemove: OnCommentRemove;
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  onCustomReactionAdd: OnCustomReactionAdd;
};

export const CollectiveIntelligenceCommentContent: React.FC<CommentContentProps> = ({
  comment,
  postId,
  onCommentAdd,
  onLikeAdd,
  onCommentRemove,
  onReactionRemove,
  onCustomReactionAdd,
}) => {
  const { data: commentAuthorData, loading: isCommentAuthorLoading } = useCommentAuthorQuery({
    variables: {
      id: comment.user_id,
    },
    skip: !comment,
    fetchPolicy: 'cache-and-network',
  });

  const commentAuthor = React.useMemo(() => {
    return commentAuthorData?.user || undefined;
  }, [commentAuthorData]);

  const commentAuthorFullName = React.useMemo(() => {
    return formatUserName(commentAuthor, '(Deleted User)');
  }, [commentAuthor]);

  if (isCommentAuthorLoading) {
    return (
      <Box display="flex" flexDirection="row" flex={1} gap={1}>
        <Skeleton css={avatarCss} variant="circular" width={40} height={40} />

        <Box flex={1}>
          <Skeleton variant="rectangular" height={50} />

          <Box display="flex" gap={1} mt={1}>
            <Skeleton variant="rectangular" width={65} height={40} />
            <Skeleton variant="rectangular" width={65} height={40} />
            <Skeleton variant="rectangular" width={65} height={40} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" flex={1} gap={1}>
      <UserAvatar user={commentAuthorData?.user} />

      <Box flex={1} sx={{ overflow: 'hidden' }}>
        <Box css={commentContentBoxCss} flex={1} p={2} pt={0.5} pb={0.5}>
          <Box display="flex" flex={1}>
            <Box display="flex" sx={{ overflow: 'hidden' }}>
              <UserNameLink css={nameCSS} user={commentAuthorData?.user} />
            </Box>

            <Box css={dividerContainerCss}>
              <Divider css={dividerCss} />
            </Box>

            <Typography css={postedAtCss}>{getTextTimeAgo(comment.created_at)}</Typography>
          </Box>

          <MentionsText text={comment.data?.text ?? ''} />

          {(comment.data.media || comment.data.imageUrl) && (
            <Box sx={{ width: COMMENT_IMAGE_SIZE }}>
              <MediaGallery
                height={COMMENT_IMAGE_SIZE}
                media={comment.data.media ?? [{ downloadUrl: comment.data.imageUrl }]}
              />
            </Box>
          )}
        </Box>

        <Box display="flex" gap={1} mt={1}>
          <HelpfulButton
            onCustomReactionAdd={onCustomReactionAdd}
            commentId={comment.id}
            onReactionRemove={onReactionRemove}
            likeInfo={comment.latest_children?.helpful || []}
            parentEntityAuthorId={comment.user_id}
            type="helpful"
            foreignId={postId}
          />

          <HelpfulButton
            onCustomReactionAdd={onCustomReactionAdd}
            commentId={comment.id}
            onReactionRemove={onReactionRemove}
            likeInfo={comment.latest_children?.nothelpful || []}
            type="nothelpful"
            parentEntityAuthorId={comment.user_id}
            foreignId={postId}
          />
          <CommentButton
            onComment={() => onCommentAdd(commentAuthorFullName)}
            commentId={comment.id || ''}
            onCommentRemove={onCommentRemove}
          />
        </Box>
      </Box>
    </Box>
  );
};
