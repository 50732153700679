import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Checkbox, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import Papa, { ParseResult } from 'papaparse';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { Print, CloudDownload, CloudUpload, Search } from '@material-ui/icons';

import { USER_AFFILIATIONS } from '@jebel/constants';

import { Select, Button, Popover } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import {
  MainToolbarActionType,
  SpreadsheetFilterPopover,
  SpreadsheetFiltersType,
  TABLE_PAGE_SIZE_VARIANTS,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useResponsive, useToast, useUserInvitation } from 'shared/hooks';
import { useMembersStats } from 'admin-features/members/hooks';

interface Props {
  filters?: SpreadsheetFiltersType;
  onSelectAll: () => void;
  isRowsSelected: boolean;
  toolbarOptions?: {
    mainToolbarAction?: MainToolbarActionType;
    filters?: SpreadsheetFiltersType;
    withDownload?: boolean;
    withSearch?: boolean;
    withPerPage?: boolean;
    downloadHandler?: (rawData: AnalyserNode) => void;
    rawData?: any;
    withCsvUpload?: boolean;
    withPDFDownload?: boolean;
    downloadPDFHandler?: (rawData?: any, text?: string) => void;
  };
  onClickMainAction: () => void;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const FILE_TYPE = '.csv';

const CSV_TEMPLATE_HEADERS = {
  affiliation: 'Affiliation',
  description: 'Affiliation Description',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  graduationYear: 'Graduation Year',
};

type InviteModalFormData = Record<
  any,
  {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    affiliation: string;
    graduatingYearIso?: string;
    affiliationDescription?: string;
  }
>;

export function SpreadsheetToolbar({
  onSelectAll,
  isRowsSelected,
  onClickMainAction,
  toolbarOptions,
  toolbarHeader,
  textToolbarHeader,
}: Props) {
  const [fileInput, setFileInput] = useState<string>();
  const [alertText, setAlertText] = useState<string>();
  const [loadingCsv, setLoadingCsv] = useState(false);

  const { setPageSize, pageSize } = useSpreadsheetContext();
  const { isDesktop } = useResponsive();

  const onChangePageSize = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize],
  );

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { inviteUsers, isInviting } = useUserInvitation({
    refetchQueries: ['MembersWithZipList', 'Members', 'MemberStats', 'MembersList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]:
        alertText || 'Success! Your invitations have been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: "Error! Your invitations haven't been sent",
    },
  });

  const onUploadFile = () => {
    hiddenFileInput?.current?.click();
  };

  const { showError } = useToast();
  const { refetchStats } = useMembersStats();

  const onSendInvites = useCallback(
    async (formData: InviteModalFormData) => {
      const userData = Object.values(formData).filter(item => !Array.isArray(item));

      if (!userData.length) {
        showError('You haven`t provided needed info to invite person. Review it carefully.');
        return;
      }

      try {
        await inviteUsers(userData);
        refetchStats?.();
      } catch (error: any) {
        showError(error?.message ?? `Error. Probably you're trying to invite existing user(s)`);
      } finally {
        setLoadingCsv(false);
        setAlertText(undefined);
      }
    },
    [inviteUsers, refetchStats, showError],
  );

  const onUploadFileChange = (event: any) => {
    const uploadedFile: File = event?.target?.files[0];

    if (uploadedFile?.name?.endsWith(FILE_TYPE)) {
      setLoadingCsv(true);
      [',', ';'].map(delimiter =>
        Papa.parse(uploadedFile, {
          delimiter,
          skipEmptyLines: true,
          header: true,
          complete: async (parsedCsv: ParseResult<any>) => {
            const formData: any = parsedCsv.data
              .map(row => {
                if (
                  row[CSV_TEMPLATE_HEADERS.affiliation] &&
                  row[CSV_TEMPLATE_HEADERS.firstName] &&
                  row[CSV_TEMPLATE_HEADERS.lastName] &&
                  row[CSV_TEMPLATE_HEADERS.email] &&
                  row[CSV_TEMPLATE_HEADERS.email].match(EMAIL_REGEX)
                ) {
                  if (
                    (row[CSV_TEMPLATE_HEADERS.affiliation]?.toLowerCase() ===
                    USER_AFFILIATIONS.alumni
                      ? !DateTime.fromFormat(row[CSV_TEMPLATE_HEADERS.graduationYear], 'M/d/yyyy')
                          .year
                      : false) ||
                    (row[CSV_TEMPLATE_HEADERS.affiliation]?.toLowerCase() ===
                    USER_AFFILIATIONS.other
                      ? !row[CSV_TEMPLATE_HEADERS.description].length
                      : false) ||
                    !Object.keys(USER_AFFILIATIONS).some(
                      affiliation =>
                        affiliation === row[CSV_TEMPLATE_HEADERS.affiliation]?.toLowerCase(),
                    )
                  ) {
                    return null;
                  }

                  const resultData: any = {
                    firstName: row[CSV_TEMPLATE_HEADERS.firstName],
                    lastName: row[CSV_TEMPLATE_HEADERS.lastName],
                    email: row[CSV_TEMPLATE_HEADERS.email],
                    phoneNumber: row[CSV_TEMPLATE_HEADERS.phoneNumber] || '',
                    affiliation: row[CSV_TEMPLATE_HEADERS.affiliation]?.toLowerCase(),
                    affiliationDescription: row[CSV_TEMPLATE_HEADERS.description] || '',
                  };

                  if (
                    row[CSV_TEMPLATE_HEADERS.graduationYear] &&
                    DateTime.fromFormat(row[CSV_TEMPLATE_HEADERS.graduationYear], 'M/d/yyyy')
                  ) {
                    resultData.graduatingYearIso = `${
                      DateTime.fromFormat(row[CSV_TEMPLATE_HEADERS.graduationYear], 'M/d/yyyy').year
                    }-01-01`;
                  }

                  return resultData;
                }

                return null;
              })
              .filter(Boolean);
            if (formData.length !== 0) {
              setAlertText(
                `Success! Your ${formData.length}/${parsedCsv.data.length} ${pluralize(
                  'Invitation',
                  formData.length,
                )} to ${formData.length} ${pluralize(
                  'email',
                  formData.length,
                )} has been sent. Thank you.`,
              );
            }

            formData.length && (await onSendInvites(formData));

            setFileInput(undefined);
          },
        }),
      );
    }
  };

  const isMainAction = !!toolbarOptions?.mainToolbarAction;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
      gridGap="1rem"
    >
      <Box display="flex" gridGap="1rem" flex={1}>
        {toolbarHeader && <Box flex={1}>{toolbarHeader}</Box>}

        {isMainAction && (
          <Box css={tableCellCheckboxCss}>
            <Checkbox color="secondary" checked={isRowsSelected} onChange={onSelectAll} />
          </Box>
        )}

        {isMainAction && (
          <Box display="flex" alignItems="center">
            {toolbarOptions?.mainToolbarAction?.icon && (
              <Button
                variant="text"
                startIcon={toolbarOptions?.mainToolbarAction?.icon}
                disabled={!!toolbarOptions.mainToolbarAction.disabled}
                onClick={onClickMainAction}
              >
                {toolbarOptions?.mainToolbarAction?.label}
              </Button>
            )}
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent={isMainAction ? 'space-between' : 'flex-end'}>
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          {toolbarOptions?.withPerPage && (
            <Box display="flex" alignItems="center">
              {isDesktop && (
                <Typography
                  style={{
                    marginRight: 10,
                    fontFamily: 'Barlow',
                    letterSpacing: '0.7px',
                    color: '#9A9A9A',
                    fontSize: '11px',
                  }}
                >
                  RESULTS PER PAGE
                </Typography>
              )}

              <Select
                size="small"
                variant="outlined"
                value={pageSize}
                onChange={onChangePageSize}
                style={{ minWidth: 'auto' }}
              >
                {TABLE_PAGE_SIZE_VARIANTS.map(el => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          <Box display="flex" gridGap="0.5rem">
            {toolbarOptions?.withSearch !== false && (
              <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                target={
                  <Tooltip title="Search">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </Tooltip>
                }
              >
                <SearchControl textFieldProps={{ trackSearches: false }} withTextField />
              </Popover>
            )}

            {toolbarOptions?.withPDFDownload && (
              <Tooltip title="Print as PDF">
                <IconButton
                  onClick={() =>
                    toolbarOptions?.downloadPDFHandler?.(toolbarOptions?.rawData, textToolbarHeader)
                  }
                >
                  <Print />
                </IconButton>
              </Tooltip>
            )}

            {!!toolbarOptions?.filters && (
              <SpreadsheetFilterPopover filters={toolbarOptions.filters} />
            )}

            {toolbarOptions?.withCsvUpload && (
              <Tooltip title="Upload from CSV">
                <IconButton disabled={isInviting || loadingCsv} onClick={onUploadFile}>
                  <CloudUpload />

                  <input
                    type="file"
                    accept={FILE_TYPE}
                    ref={hiddenFileInput}
                    value={fileInput ?? ''}
                    style={{ display: 'none' }}
                    onChange={onUploadFileChange}
                  />
                </IconButton>
              </Tooltip>
            )}

            {toolbarOptions?.withDownload && (
              <Tooltip title="Select data to download only specific records">
                <IconButton
                  onClick={() => toolbarOptions?.downloadHandler?.(toolbarOptions?.rawData)}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const tableCellCheckboxCss = css`
  border: none;
`;
