import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

import { PaperLayout } from 'shared/components/layouts';
import { MentionsText } from 'shared/features/mentions';

// Container

export const Container = styled(PaperLayout)`
  display: flex;
  flex-direction: row;
  min-height: 8rem;
  padding: 1rem;
  gap: 1rem;
`;

// Aside

export const Aside = styled.div``;

// Image

export const Image = styled(Avatar)`
  width: 12rem;
  min-height: 8rem;
  border-radius: 0.5rem;
`;

Image.defaultProps = {
  variant: 'square',
};

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

// Text

export const Text = styled(MentionsText)``;

// LegacyDate

export const LegacyDate = styled.div(props => {
  const backgroundColor = props.theme.palette.secondary.light;
  // eslint-disable-next-line prefer-destructuring
  const fontSize = props.theme.typography.body2.fontSize;
  const color = props.theme.palette.getContrastText(backgroundColor);

  return css`
    background-color: ${backgroundColor};
    font-size: ${fontSize};
    color: ${color};
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
  `;
});
