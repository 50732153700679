import React from 'react';

import { useCurrentUser } from 'shared/hooks';
import { CollectiveIntelligenceItemFragment } from 'shared/graphql';

export const useThreadWasViewed = (collectiveThread?: CollectiveIntelligenceItemFragment) => {
  const { user } = useCurrentUser();

  const hasViewedByCurrentUser = React.useMemo(() => {
    const viewedByUser = collectiveThread?.views?.items.find(
      viewedUser => viewedUser?.id === user?.id,
    );

    return Boolean(viewedByUser);
  }, [collectiveThread?.views?.items, user?.id]);

  return { isViewed: hasViewedByCurrentUser };
};
