import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { EducationalInstitutionKeyFilter, useEducationProfileRemoveMutation } from 'shared/graphql';

export function useEducationProfileRemove() {
  const [mutate, { loading, error }] = useEducationProfileRemoveMutation({
    refetchQueries: ['UserPublicInfo'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your education profile has been removed.',
      [SNACKBAR_ERROR_MESSAGE]:
        'Error: There are one or more errors in your education profile. Please review carefully and try again.',
    },
  });

  const execute = (data: EducationalInstitutionKeyFilter) => {
    return mutate({ variables: { filter: { id: data.id } } });
  };

  return {
    error,
    loading,
    execute,
  };
}
