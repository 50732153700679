import { useCallback, useMemo } from 'react';

import { createFilterBuilder, formatTableDate } from '@jebel/utils';

import {
  Spreadsheet,
  SpreadsheetCellActions,
  SpreadsheetFiltersType,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import { useDownloadLazyQueryCSV, useGraduatingYearsSpreadsheetInfo } from 'shared/hooks';
import { Icon } from 'shared/components/ui';
import {
  GraduatingYearsMetricFilter,
  GraduatingYearsMetricsQuery,
  GraduatingYearsMetricsQueryVariables,
} from 'shared/graphql';
import { getFilterUserByName } from 'shared/utils/search';
import { sendToSentry } from 'shared/utils/sentry';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { GRADUATING_YEARS_METRICS_QUERY } from 'features/scorecard/queries';
import { formatUserName } from 'shared/utils/user';

import { ClassScoreCardReportHeaders, ClassScorecardSpreadsheetHeader } from '../constants';

export type ClassScorecardSpreadsheetProps = {
  refetchStats: () => Promise<void>;
  loading: boolean;
  onViewClassModalOpen: (classId: string) => void;
  onEditClassModalOpen: (classId: string) => void;
};

export function ClassScorecardSpreadsheet({
  refetchStats,
  loading,
  onViewClassModalOpen,
  onEditClassModalOpen,
}: ClassScorecardSpreadsheetProps) {
  const { selected } = useSpreadsheetContext();
  const { tableData, tableLoading, deleteGraduatingYear, deleting, queryVariables } =
    useGraduatingYearsSpreadsheetInfo();

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<GraduatingYearsMetricFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      filter.and({ id: { in: selected } });
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadCSV] = useDownloadLazyQueryCSV<
    GraduatingYearsMetricsQuery,
    GraduatingYearsMetricsQueryVariables
  >(GRADUATING_YEARS_METRICS_QUERY, {
    filename: getFileNameWithTimestamp('ClassScore.csv'),
    transform: transformExport,

    variables: {
      filter: downloadFilter,
    },
  });

  const onDelete = useCallback(
    async (id: string) => {
      await deleteGraduatingYear({ variables: { data: { id } } });
    },
    [deleteGraduatingYear],
  );

  const onBulkDelete = useCallback(async () => {
    if (!selected.length) {
      return;
    }

    const yearsDeletePromise = selected.map(id => onDelete(id));

    try {
      await Promise.all(yearsDeletePromise);
      await refetchStats();
    } catch (e: any) {
      sendToSentry(`Error: Operation failed. ${e}`);
    }
  }, [refetchStats, onDelete, selected]);

  const onClickDelete = useCallback(
    async (id: string) => {
      await onDelete(id);
      await refetchStats();
    },
    [refetchStats, onDelete],
  );

  const spreadsheetActions = useMemo(
    (): SpreadsheetCellActions => [
      {
        id: 'View Details',
        title: 'View Details',
        onClickAction: onViewClassModalOpen,
        icon: <Icon name="People" size={20} />,
      },
      {
        id: 'Edit Class',
        title: 'Edit Class',
        onClickAction: onEditClassModalOpen,
        icon: <Icon name="Edit" size={20} />,
      },
      {
        id: 'Delete',
        title: 'Delete',
        onClickAction: onClickDelete,
        confirm: {
          text: 'Are you sure you want to delete the class?',
          confirmText: 'Delete',
          await: true,
        },
        icon: <Icon name="DeleteForeverSharp" color="gray" size={20} />,
      },
    ],
    [onClickDelete, onEditClassModalOpen, onViewClassModalOpen],
  );

  const spreadsheetFilters: SpreadsheetFiltersType = [
    {
      type: 'input',
      filterPath: (value: string) => (value ? { graduateCount: { equals: Number(value) } } : {}),
      name: 'alumni',
      label: 'Alumni count',
      size: 'full',
    },
    {
      type: 'input',
      filterPath: (value: string) =>
        value
          ? {
              OR: [...getFilterUserByName(value)],
            }
          : {},
      name: 'name',
      label: 'Created by',
      size: 'full',
    },
    {
      type: 'range',
      label: 'Years range',
      name: 'years',
      min: 1959,
      max: 2099,
      startLabel: '',
      endLabel: '',
      step: 1,
      size: 'full',
      filterPath: (value: string) =>
        value
          ? {
              AND: [
                { year: { gte: Number(value.split(',')[0]) } },
                { year: { lte: Number(value.split(',')[1]) } },
              ],
            }
          : {},
    },
  ];

  const spreadsheetData = useMemo(() => {
    const items = tableData?.metrics.items ?? [];

    return items.map(year => {
      const registeredUsersCount = year.registeredUsersCount ?? 0;
      const registeredUsersPercentage = year.registeredUsersPercentage ?? 0;

      return {
        id: year.id as string,
        year: year.year,
        alumni: year.expectedUsersCount,
        registeredUsers: `${registeredUsersCount} (${registeredUsersPercentage}%)`,
        createdBy: formatUserName(year.createdBy),
        createdAt: formatTableDate(year.createdAt),
      };
    });
  }, [tableData]);

  return (
    <Spreadsheet
      data={spreadsheetData}
      headlines={ClassScorecardSpreadsheetHeader}
      toolbarOptions={{
        filters: spreadsheetFilters,
        withPerPage: true,
        withDownload: true,
        withSearch: true,
        downloadHandler: downloadCSV,
        mainToolbarAction: {
          onClick: onBulkDelete,
          icon: (
            <Icon
              style={{
                fill: !selected.length ? '#00000059' : deleting ? '#00000042' : '#ff0000',
              }}
              name="DeleteForever"
            />
          ),
          label: 'Bulk Delete',
          disabled: deleting || !selected.length,
        },
        rawData: tableData?.metrics.items ?? [],
      }}
      cellActions={spreadsheetActions}
      itemsCount={tableData?.metrics?.count ?? 0}
      loading={tableLoading || loading}
    />
  );
}

function transformExport(response: GraduatingYearsMetricsQuery) {
  const classes = response.metrics.items ?? [];

  return classes.map(classMember => {
    return {
      [ClassScoreCardReportHeaders.classYear]: classMember.year,
      [ClassScoreCardReportHeaders.alumni]: classMember.expectedUsersCount,
      [ClassScoreCardReportHeaders.registeredUsers]: classMember.registeredUsersCount,
      [ClassScoreCardReportHeaders.createdBy]: formatUserName(classMember.createdBy),
      [ClassScoreCardReportHeaders.createdOn]: classMember.createdAt,
    };
  });
}
