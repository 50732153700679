import React from 'react';
import { Theme, css } from '@emotion/react';
import { Field } from 'formik';
import { Box, MenuItem, IconButton, Avatar, Chip } from '@material-ui/core';

import { GROUP_STATUSES } from '@jebel/constants';
import { processFilestackUrlSrcSet } from '@jebel/utils';

import { DataBaseMultiSelectField } from 'shared/components/form/DateBaseMultiselectField';
import { useDataBaseSelectField } from 'shared/components/form/hooks';
import {
  FormTextField,
  Form,
  Button,
  FormSelect,
  ImageInput,
  Icon,
  ResultFile,
  Typography,
} from 'shared/components/ui';
import { GroupInfoFragment, User, UsersListQuery } from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';
import { required } from 'shared/utils/form';
import { USERS_LIST_QUERY } from 'shared/graphql';

import { useGroupDetailsUpdate } from '../../hooks';
import { People } from '@material-ui/icons';

const commonButtonsCSS = (theme: Theme) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(1)}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const deleteButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.error.light};
`;

const submitButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

const fileUploadIconCSS = (theme: Theme) => css`
  fill: ${theme.palette.primary.light};
`;

const IMAGE_INPUT_BOX_SIZE = 90;

const logoCSS = () => css`
  width: ${IMAGE_INPUT_BOX_SIZE}px;
  height: ${IMAGE_INPUT_BOX_SIZE}px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  grid-template-rows: min-content min-content;
`;

const chipCss = (theme: Theme) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type FormData = {
  title?: string | undefined | null;
  description?: string | undefined | null;
  logo?: {
    fileId?: string | null | undefined;
  };
  members?: User[] | null | undefined;
};

const getLogo = (original, newLogo) => {
  if (original?.fileId === newLogo?.fileId) {
    return {};
  }
  if (!newLogo) {
    return {
      disconnect: { fileId: original?.fileId },
    };
  }

  return {
    create: { fileId: newLogo?.fileId },
  };
};

export const GroupDetailsInformation = ({
  group,
  onModalClose,
  onDeleteGroupClick,
}: {
  group: GroupInfoFragment | undefined;
  onModalClose: () => void;
  onDeleteGroupClick: () => void;
}) => {
  const { onGroupDetailsUpdate } = useGroupDetailsUpdate();
  const { userId } = useCurrentUser();
  const isAdmin: boolean =
    group?.groupAdminPreferences?.items.some(({ user: admin }) => admin?.id === userId) || false;

  const onChangeLogo = React.useCallback(
    (setFieldValue: any) => (logo: ResultFile | null) => {
      if (!logo) {
        return;
      }

      setFieldValue('logo', logo);
    },
    [],
  );
  const onSubmit = React.useCallback(
    async (formData: FormData) => {
      const connectMembers = formData?.members?.map(member => ({ id: member.id })) || [];
      const logo = getLogo(group?.logo, formData?.logo);

      const data = {
        id: group?.id ?? '',
        logo,
        members: {
          connect: [...connectMembers, { id: userId }],
        },
        title: formData.title ?? '',
        description: formData.description ?? '',
      };

      await onGroupDetailsUpdate(data);
      onModalClose();
    },
    [group?.id, onGroupDetailsUpdate, onModalClose, userId],
  );

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: USERS_LIST_QUERY,
      getQueryItems: 'usersList.items',
    },
    {
      getOptionLabel: (user: User) => `${user.firstName} ${user.lastName}`,
      renderTag: (user: User) => {
        return <Chip css={chipCss} label={`${user?.firstName} ${user?.lastName}`} />;
      },
    },
  );

  return (
    <Form
      initialValues={{
        title: group?.title,
        status: group?.status,
        description: group?.description,
        logo: group?.logo,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Box display="grid" gridTemplateRows="auto" gridGap={10}>
          <FormTextField
            inputProps={{
              label: 'GROUP TITLE',
              variant: 'outlined',
              disabled: !isAdmin,
            }}
            fieldProps={{ name: 'title', validate: required }}
          />
          <Box display="grid" gridTemplateColumns="80% auto" gridGap={10}>
            <Box display="grid" gridTemplateRows="min-content min-content" gridGap={10}>
              <Field name="members">
                {({ field, form, meta }) => (
                  <DataBaseMultiSelectField
                    form={form}
                    input={field}
                    meta={meta}
                    placeholder="INVITE MEMBERS"
                    {...multiSelectFieldProps}
                  />
                )}
              </Field>
              <FormSelect
                selectProps={{
                  // TODO: group can be active/inactive (JEB-429)
                  children: Object.keys(GROUP_STATUSES).map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )),
                  disabled: !isAdmin,
                  variant: 'outlined',
                  label: 'STATUS',
                }}
                fieldProps={{ name: 'status', validate: required }}
              />
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="start">
              <ImageInput
                name="logo"
                maxFiles={1}
                initialValue={values.logo}
                onChange={file => setFieldValue('logo', file)}
                onDelete={() => setFieldValue('logo', undefined)}
                customPicker={
                  <Box>
                    <Box
                      css={logoCSS}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <People css={fileUploadIconCSS} />
                    </Box>
                    <Typography color="highlight" variant="subtitle5" align="center">
                      Add Image
                    </Typography>
                  </Box>
                }
                customPreview={
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Avatar
                      css={css`
                        width: ${IMAGE_INPUT_BOX_SIZE}px;
                        height: ${IMAGE_INPUT_BOX_SIZE}px;
                        cursor: pointer;
                      `}
                      variant="square"
                      srcSet={processFilestackUrlSrcSet(values?.logo?.downloadUrl || '', {
                        resize: {
                          width: IMAGE_INPUT_BOX_SIZE,
                          height: IMAGE_INPUT_BOX_SIZE,
                        },
                      })}
                      src={values?.logo?.downloadUrl || ''}
                    />
                  </Box>
                }
              />
            </Box>
          </Box>
          <FormTextField
            inputProps={{
              disabled: !isAdmin,
              color: 'primary',
              label: 'DESCRIPTION',
              multiline: true,
              rows: '7',
              rowsMax: '7',
              variant: 'outlined',
            }}
            fieldProps={{ name: 'description' }}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button
              css={cancelButtonCSS}
              onClick={onModalClose}
              disabled={isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>
            <Button
              css={deleteButtonCSS}
              onClick={onDeleteGroupClick}
              disabled={isSubmitting || !isAdmin}
              size="medium"
            >
              DELETE GROUP
            </Button>
            <Button
              loading={isSubmitting}
              css={submitButtonCSS}
              disabled={!isAdmin}
              disableElevation
              variant="contained"
              type="submit"
            >
              SAVE CHANGES
            </Button>
          </Box>
        </Box>
      )}
    </Form>
  );
};
