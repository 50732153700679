import { PropsWithChildren } from 'react';
import { Skeleton } from '@mui/material';
import { Button } from '@material-ui/core';

import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { Container, Content, Title } from './SearchSection.styles';

interface Props {
  title: string;
  loading?: boolean;

  fetching: boolean;
  canShowMore?: boolean;
  hasMore: boolean;

  showMoreLabel?: string;

  onFetchMore(): Promise<void>;
  onShowMore(): void;
}

export function SearchSection(props: PropsWithChildren<Props>) {
  const showMoreLabel = props.showMoreLabel ?? 'Show All';

  const fetchMore = async () => {
    if (!props.canShowMore) {
      return;
    }

    await props.onFetchMore();
  };

  if (props.loading) {
    return (
      <Container>
        <Skeleton variant="rectangular" width="100%" height="10rem" />
        <Skeleton variant="rectangular" width="100%" height="10rem" />
        <Skeleton variant="rectangular" width="100%" height="10rem" />
        <Skeleton variant="rectangular" width="100%" height="10rem" />
      </Container>
    );
  }

  if (props.canShowMore) {
    return (
      <Container>
        <Title>{props.title}</Title>

        <InfiniteScroll fetching={props.fetching} hasMore={props.hasMore} fetchMore={fetchMore}>
          <Content>{props.children}</Content>
        </InfiniteScroll>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{props.title}</Title>
      <Content>{props.children}</Content>

      <Button onClick={props.onShowMore}>{showMoreLabel}</Button>
    </Container>
  );
}
