import { useMemo } from 'react';

import { useContentCurationCountQuery } from 'shared/graphql';

export function useContentCurationCount() {
  const { data: response, loading } = useContentCurationCountQuery();

  const data = useMemo(() => {
    const pending = response?.pending.count ?? 0;

    return {
      pending,
    };
  }, [response]);

  return {
    data,
    loading,
  };
}
