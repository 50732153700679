import { Fragment } from 'react';
import { Theme, css } from '@emotion/react';
import { Box, MenuItem } from '@material-ui/core';
import { FormikValues } from 'formik';

import {
  Button,
  Form,
  FormSelect,
  FormTextField,
  RouteLayout,
  Typography,
} from 'shared/components/ui';
import { US_TIMEZONES_OPTIONS } from 'shared/constants';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { useSchoolConfigurationUpdate } from 'shared/hooks/useSchoolConfigurationUpdate';
import { isValidEmail, required } from 'shared/utils/form';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const contentCSS = css`
  ${containerCSS};

  max-width: 40rem;
  padding: 2rem;
  gap: 1rem;
`;

const formFieldCss = (theme: Theme) => css`
  margin-bottom: ${theme.spacing(2)}px;
  width: 100%;
`;

const titleCss = (theme: Theme) => css`
  padding-left: ${theme.spacing(6)}px;
  padding-top: ${theme.spacing(2)}px;
  padding-bottom: ${theme.spacing(2)}px;
  border-bottom: 1px solid ${theme.palette.divider};
`;

type FormData =
  | FormikValues
  | {
      name: string;
      fullName: string;
      email: string;
      timezone: string;
    };

export function SiteSettings() {
  const { configuration } = useSchoolConfiguration();
  const { showSuccess } = useToast();
  const { schoolConfigurationUpdate, loading } = useSchoolConfigurationUpdate();

  const onSave = async (data: FormData) => {
    await schoolConfigurationUpdate({
      email: data.email,
      fullName: data.fullName,
      name: data.name,
      timezone: data.timezone,
    });

    showSuccess('Settings updated successfully');
  };

  return (
    <RouteLayout css={containerCSS}>
      <Typography css={titleCss} variant="subtitle4" align="inherit" paragraph={false}>
        Site Settings
      </Typography>

      <Form
        oldCss={contentCSS}
        onSubmit={onSave}
        initialValues={{
          name: configuration?.name,
          fullName: configuration?.fullName,
          email: configuration?.email,
          timezone: configuration?.timezone,
        }}
      >
        {formProps => (
          <Fragment>
            <Box display="grid" gridTemplateRows="auto" gridGap={2} my={2}>
              <FormTextField
                inputProps={{
                  label: 'APP/SITE NAME',
                  variant: 'outlined',
                  css: formFieldCss,
                }}
                fieldProps={{
                  name: 'name',
                  validate: required,
                }}
              />
              <FormTextField
                inputProps={{
                  label: 'SCHOOL FULL NAME',
                  variant: 'outlined',
                  css: formFieldCss,
                }}
                fieldProps={{
                  name: 'fullName',
                  validate: required,
                }}
              />
              <FormTextField
                inputProps={{
                  label: 'SCHOOL EMAIL ADDRESS',
                  variant: 'outlined',
                  css: formFieldCss,
                }}
                fieldProps={{
                  name: 'email',
                  validate: isValidEmail,
                }}
              />
              <FormSelect
                selectProps={{
                  variant: 'outlined',
                  label: 'Timezone',
                  children: US_TIMEZONES_OPTIONS.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                }}
                fieldProps={{
                  name: 'timezone',
                  validate: required,
                }}
              />
            </Box>

            <Box display="flex" justifyContent="end" gridGap="0.5rem">
              <Button variant="outlined" onClick={formProps.handleReset} size="medium" type="reset">
                CANCEL
              </Button>

              <Button
                loading={loading}
                disabled={loading}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
              >
                SAVE CHANGES
              </Button>
            </Box>
          </Fragment>
        )}
      </Form>
    </RouteLayout>
  );
}
