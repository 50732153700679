/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Typography, IconButton } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import { Avatar } from '@mui/material';

import { Box, AsyncContent } from 'shared/components/ui';
import { useCurrentUserSavedOffers } from 'shared/hooks';
import { PaperLayout } from 'shared/components/layouts';

import { SavedJobsItem } from './SavedJobsItem';

const topContainerCSS = (theme: any) => css`
  display: grid;
  padding: ${theme.spacing(3.5)}px;
  grid-gap: 25px;
`;

const topBarBoxCSS = (theme: any) => css`
  padding: ${theme.spacing(1.5)}px ${theme.spacing(3.5)}px 0 ${theme.spacing(3.5)}px;
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: auto auto;
  min-height: auto;
  justify-content: space-between;
`;

const viewAllCSS = (theme: any) => css`
  font-weight: 500;
  color: ${theme.palette.secondary.light};
  min-height: auto;
  font-size: ${theme.typography.fontSize}px;
  line-height: 17px;
`;

const savedJobsCSS = (theme: any) => css`
  font-weight: 600;
  color: ${theme.palette.info.main};
  min-height: auto;
  font-size: ${theme.typography.fontSize}px;
  line-height: 17px;
`;

const savedJobBoxCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WORK_AVATAR_SIZE = 20;

const workAvatarCSS = (theme: any) => css`
  color: white;
  background-color: ${theme.palette.info.main};
  width: ${WORK_AVATAR_SIZE}px;
  height: ${WORK_AVATAR_SIZE}px;
  border: 2px solid ${theme.palette.info.main};
  margin-right: 5px;
`;

const WORK_ICON_SIZE = 15;

const workIconCSS = css`
  width: ${WORK_ICON_SIZE}px;
  height: ${WORK_ICON_SIZE}px;
`;

const PREVIEW_SAVED_JOBS_COUNT = 4;

export const SavedJobs = () => {
  const [showAll, setShowAll] = useState(false);

  const { data: available, loading } = useCurrentUserSavedOffers();

  /** List only the jobs based on the restriction. */
  const restricted = useMemo(() => {
    if (showAll) {
      return available;
    }

    return available.slice(0, PREVIEW_SAVED_JOBS_COUNT);
  }, [showAll, available]);

  /** Can we show more jobs? */
  const canShowMore = available.length > restricted.length;

  const showMore = () => {
    setShowAll(true);
  };

  if (loading || available.length === 0) {
    return null;
  }

  return (
    <AsyncContent loading={loading} stretch>
      <PaperLayout>
        <Box css={topBarBoxCSS}>
          <Box css={savedJobBoxCSS}>
            <Avatar css={workAvatarCSS}>
              <WorkIcon css={workIconCSS} />
            </Avatar>

            <Typography css={savedJobsCSS}>Saved jobs</Typography>
          </Box>

          {canShowMore && (
            <IconButton color="inherit" size="small" onClick={showMore}>
              <Typography css={viewAllCSS}>View all</Typography>
            </IconButton>
          )}
        </Box>

        <Box css={topContainerCSS}>
          {restricted.map(item => (
            <SavedJobsItem key={item?.id} savedJob={item} />
          ))}
        </Box>
      </PaperLayout>
    </AsyncContent>
  );
};
