import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { CareerServicesPages } from './pages/CareerServicesPages';

export const getCareerRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.services.index,
    exact: true,
    component: CareerServicesPages,
  },
];
