import React from 'react';
import { css } from '@emotion/react';
import { Box, Chip, Dialog, DialogContent, DialogTitle, Divider, Avatar } from '@material-ui/core';
import { Field, useField } from 'formik';
import { omit } from 'ramda';
import { useMutation, gql } from '@apollo/client';

import { POST_STATUSES } from '@jebel/constants';
import { processFilestackUrlSrcSet } from '@jebel/utils';

import { DataBaseMultiSelectField } from 'shared/components/form/DateBaseMultiselectField';
import { useDataBaseSelectField } from 'shared/components/form/hooks';
import { FormTextField, Form, Button, Icon, Typography, ImageInput } from 'shared/components/ui';
import { USERS_LIST_QUERY } from 'shared/graphql';
import {
  File,
  GroupCreateInput,
  User,
  UserInviteMembersToGroupMutation,
  UserInviteMembersToGroupMutationVariables,
  UsersListQuery,
} from 'shared/graphql/__generated__';
import { required, maxLength } from 'shared/utils/form';
import { useCrudPermissions, useCurrentUser, useResponsive } from 'shared/hooks';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { ResultFile } from 'shared/types/files';

import { useGroupCreateRequest } from '../../hooks';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(1)}px;

  ${theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
`;

const IMAGE_INPUT_BOX_SIZE = 95;

const chipCss = (theme: any) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const logoIconCss = (theme: any) => css`
  fill: ${theme.palette.primary.light};
`;

const logoBoxCss = (theme: any) => css`
  width: ${IMAGE_INPUT_BOX_SIZE}px;
  height: ${IMAGE_INPUT_BOX_SIZE}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.muted};
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  grid-template-rows: min-content min-content;
`;

type FormData = {
  title?: string | undefined | null;
  description?: string | undefined | null;
  logo?: {
    fileId?: string | null | undefined;
  };
  members?: User[] | null | undefined;
};

const INVITE_MEMBERS_MUTATION = gql`
  mutation UserInviteMembersToGroup($data: UserInviteMembersToGroupInput!) {
    userInviteMembersToGroup(data: $data) {
      success
    }
  }
`;

interface Props {
  isOpen: boolean;

  /** @default "New Group" */
  title?: string;

  onClose(): void;
}

export function GroupCreateModal(props: Props) {
  const {
    groupsPermissions: { add: ifCreateAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const { isMobile } = useResponsive();
  const { userId } = useCurrentUser();
  const { onGroupCreateRequest } = useGroupCreateRequest();

  const [inviteMembersToGroup] = useMutation<
    UserInviteMembersToGroupMutation,
    UserInviteMembersToGroupMutationVariables
  >(INVITE_MEMBERS_MUTATION, {
    refetchQueries: ['GroupsList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const handleSubmit = async (formData: FormData) => {
    const fileId = formData.logo?.fileId;
    const connectMembers = formData?.members?.map(member => ({ id: member.id })) || [];
    // const userGroupPreferencesId = user?.groupAdminPreferences?.id;

    const data: GroupCreateInput = {
      logo: { create: { fileId } },
      members: {
        connect: [...connectMembers, { id: userId }],
      },
      membersCount: connectMembers?.length ? connectMembers?.length + 1 : 0,
      // NO LONGER IN USED.
      // https://github.com/8base-services/jebel/issues/1435#issuecomment-2195609254
      // groupAdminPreferences: {
      //   [userGroupPreferencesId ? `connect` : `create`]: userGroupPreferencesId
      //     ? { id: userGroupPreferencesId }
      //     : [{ user: { connect: { id: userId } } }],
      // },
      status: POST_STATUSES.pending,
      title: formData.title ?? '',
      description: formData.description ?? '',
    };

    try {
      const newGroup = await onGroupCreateRequest(fileId ? data : omit(['logo'], data));

      if (connectMembers.length > 0) {
        await inviteMembersToGroup({
          variables: {
            data: {
              members: { connect: connectMembers },
              id: newGroup.data?.groupCreateRequest?.groupId as string,
            },
          },
        });
      }

      props.onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: USERS_LIST_QUERY,
      getQueryItems: 'usersList.items',
    },
    {
      getOptionLabel: (user: User) => `${user.firstName} ${user.lastName}`,
      renderTag: (user: User) => {
        return <Chip css={chipCss} label={`${user?.firstName} ${user?.lastName}`} />;
      },
    },
    true,
  );

  return (
    <Dialog maxWidth="sm" fullWidth open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>{props.title ?? 'New Group'}</DialogTitle>

      <Divider />

      <DialogContent>
        <Form onSubmit={handleSubmit}>
          {form => {
            return (
              <Box display="grid" gridTemplateRows="auto" gridGap={10}>
                <Box display="flex" gridGap={10}>
                  <Box
                    display="grid"
                    gridTemplateRows="auto auto"
                    alignItems="center"
                    gridGap={10}
                    flex={1}
                  >
                    <FormTextField
                      inputProps={{
                        label: 'GROUP TITLE',
                        variant: 'outlined',
                      }}
                      fieldProps={{ name: 'title', validate: required }}
                    />

                    {isMobile && <GroupImageField />}

                    <Field name="members">
                      {({ field, form, meta }) => (
                        <DataBaseMultiSelectField
                          form={form}
                          input={field}
                          meta={meta}
                          placeholder="INVITE MEMBERS"
                          {...multiSelectFieldProps}
                        />
                      )}
                    </Field>
                  </Box>

                  {!isMobile && <GroupImageField />}
                </Box>

                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'DESCRIPTION',
                    multiline: true,
                    rows: '7',
                    rowsMax: '7',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'description', validate: maxLength }}
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    css={cancelButtonCSS}
                    onClick={props.onClose}
                    disabled={form.isSubmitting}
                    size="medium"
                  >
                    CANCEL
                  </Button>

                  {ifCreateAccessed && !loadingAddons && (
                    <Button
                      loading={form.isSubmitting}
                      disabled={form.isSubmitting}
                      css={submitButtonCSS}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      REQUEST NEW GROUP
                    </Button>
                  )}
                </Box>
              </Box>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
}

const GROUP_IMAGE_FIELD_SIZE = 95;

/** @internal */
function GroupImageField() {
  const [field, , helpers] = useField<File | null>('logo');

  const handleChange = (value: ResultFile) => {
    helpers.setValue(value);
  };

  return (
    <Box width={IMAGE_INPUT_BOX_SIZE}>
      <ImageInput
        onChange={handleChange}
        name="logo"
        maxFiles={1}
        value={field.value ?? undefined}
        onDelete={() => helpers.setValue(null)}
        customPicker={
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box
              css={logoBoxCss}
              display="flex"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Icon css={logoIconCss} name="Groups" variant="filled" />
            </Box>

            <Typography align="center" color="highlight" variant="subtitle5">
              Add Image
            </Typography>
          </Box>
        }
        customPreview={
          <Avatar
            variant="square"
            style={{
              width: GROUP_IMAGE_FIELD_SIZE,
              height: GROUP_IMAGE_FIELD_SIZE,
            }}
            src={field.value?.downloadUrl ?? undefined}
            srcSet={processFilestackUrlSrcSet(field.value?.downloadUrl ?? undefined, {
              resize: {
                width: GROUP_IMAGE_FIELD_SIZE * 2,
                height: GROUP_IMAGE_FIELD_SIZE * 2,
              },
            })}
          />
        }
      />
    </Box>
  );
}
