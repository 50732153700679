import React from 'react';
import { Box, MenuItem, css } from '@mui/material';

import { generateGraduatingYearFormConfig } from 'shared/constants/selectOptions';
import { CreateGraduatingYearFormFields } from 'shared/types/forms';
import {
  createGraduatingYearFormSchema,
  CreateGraduatingYearFormValues,
} from 'shared/validationSchemas';
import { Form, Button, FormTextField, FormSelect } from 'shared/components/ui';
import { FormError } from 'shared/components/form';

const formBlockCSS = (theme: any) => css`
  display: grid;
  margin-bottom: ${theme.spacing(1)}px;
`;

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 5}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.primary.dark};
  color: #fff;
`;

const rawYears = generateGraduatingYearFormConfig();
const renderYears = (existingGraduatingYears: number[]) => {
  return rawYears
    .filter(yearInfo => {
      return !existingGraduatingYears.includes(Number(yearInfo.value));
    })
    .map(item => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
};

const alumniCountInputProps = {
  step: 1,
  min: 1,
} as any;

const getAlumniCountFormError = <
  T extends { [CreateGraduatingYearFormFields.alumniCount]?: string },
>(
  errors: T,
) => {
  return errors[CreateGraduatingYearFormFields.alumniCount];
};

type CreateGraduatingYearModalProps = {
  initialValues?: Partial<CreateGraduatingYearFormValues>;
  onSubmit: (formValues: CreateGraduatingYearFormValues) => Promise<void>;
  onClose: () => void;
  existingGraduatingYears?: number[];
  isEditMode?: boolean;
  isLoading?: boolean;
};

export const CreateOrEditGraduatingYearForm: React.FC<CreateGraduatingYearModalProps> = ({
  initialValues,
  onSubmit,
  onClose,
  existingGraduatingYears = [],
  isEditMode = false,
  isLoading = false,
}) => {
  return (
    <Form
      oldCss={formBlockCSS}
      initialValues={initialValues}
      onSubmit={onSubmit as any}
      validationSchema={createGraduatingYearFormSchema}
    >
      {({ isSubmitting, isValid, dirty, errors }) => {
        const alumniCountFormError = getAlumniCountFormError(errors);
        return (
          <React.Fragment>
            <Box display="grid" gap={2.5}>
              <FormSelect
                selectProps={{
                  children: renderYears(existingGraduatingYears),
                  variant: 'outlined',
                  label: 'Class Year',
                  required: true,
                  disabled: isEditMode || isLoading,
                }}
                fieldProps={{ name: CreateGraduatingYearFormFields.classYear }}
              />

              <Box display="grid" gap={0.5}>
                <FormTextField
                  inputProps={{
                    label: 'Total Alumni count',
                    variant: 'outlined',
                    type: 'number',
                    inputProps: alumniCountInputProps,
                    required: true,
                    disabled: isLoading,
                  }}
                  fieldProps={{ name: CreateGraduatingYearFormFields.alumniCount }}
                />
                {alumniCountFormError && <FormError text={alumniCountFormError} />}
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" mt={3.5}>
              <Button css={cancelButtonCSS} onClick={onClose} disabled={isSubmitting} size="medium">
                CANCEL
              </Button>

              <Button
                disabled={isSubmitting || isLoading || !isValid || !dirty}
                loading={isSubmitting || isLoading}
                css={submitButtonCSS}
                disableElevation
                variant="contained"
                type="submit"
              >
                {isEditMode ? 'EDIT' : 'ADD'} CLASS
              </Button>
            </Box>
          </React.Fragment>
        );
      }}
    </Form>
  );
};
