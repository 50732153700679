import { boolean, object, string, union } from 'zod';
const INBOX_SUBJECT_SCHOOL_SCHEMA = object({
    id: string().optional().nullable(),
    name: string().optional().nullable(),
});
const INBOX_SUBJECT_BASE_SCHEMA = object({
    content: string(),
});
export const INBOX_SUBJECT_FROM_SCHOOL_SCHEMA = INBOX_SUBJECT_BASE_SCHEMA.extend({
    isFromSchool: boolean(),
    school: INBOX_SUBJECT_SCHOOL_SCHEMA,
});
export const INBOX_SUBJECT_FROM_SYSTEM_SCHEMA = INBOX_SUBJECT_BASE_SCHEMA.extend({
    isFromSystem: boolean(),
});
export const INBOX_SUBJECT_FROM_USER_SCHEMA = string();
export const INBOX_SUBJECT_SCHEMA = union([
    INBOX_SUBJECT_FROM_SCHOOL_SCHEMA,
    INBOX_SUBJECT_FROM_SYSTEM_SCHEMA,
    INBOX_SUBJECT_FROM_USER_SCHEMA,
]);
