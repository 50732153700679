import { Fragment } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useGlobalState } from 'providers';
import { css } from '@emotion/react';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useCurrentUser, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';
import { useNeedHelpMutation } from 'shared/graphql';

import { FormTextField } from '../ui';

const contentCSS = css`
  display: grid;
  grid-gap: 1rem;
`;

export interface HelpFormValues {
  subject?: string;
  message?: string;
  email?: string;
}

const HELP_FORM_SCHEMA = object({
  subject: string().required('Must be defined'),
  message: string().required('Must be defined'),
});

export function HelpModal() {
  const { helpModal: modal } = useGlobalState();
  const { user, loading } = useCurrentUser();
  const { showError } = useToast();

  const [sendNeedHelp] = useNeedHelpMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your message was successfully sent!',
      [SNACKBAR_ERROR_MESSAGE]: 'Error: Message was not sent',
    },
  });

  const showEmailField = loading ? !modal.params?.email : !user?.email && !modal.params?.email;

  const initials: HelpFormValues = {
    subject: modal.params?.subject ?? '',
    message: modal.params?.message ?? '',
    email: modal.params?.email ?? '',
  };

  const onSubmit = async (form: HelpFormValues) => {
    try {
      await sendNeedHelp({
        variables: {
          email: form.email ?? user?.email ?? '',
          firstName: user?.firstName ?? 'Unknown',
          lastName: user?.lastName ?? 'User',
          subject: form.subject ?? '',
          message: form.message ?? '',
        },
      });

      modal.close();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <Dialog open={modal.show} fullWidth maxWidth="sm">
      <DialogTitle>Need help?</DialogTitle>

      <Formik
        enableReinitialize
        validationSchema={HELP_FORM_SCHEMA}
        initialValues={initials}
        onSubmit={onSubmit}
      >
        {form => (
          <Fragment>
            <DialogContent css={contentCSS} dividers>
              <Typography>
                <span>Your message will be sent to </span>

                <Link color="secondary" href="mailto:support@jebelapp.com">
                  support@jebelapp.com
                </Link>

                <span>.</span>
              </Typography>

              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Subject',
                  variant: 'outlined',
                }}
                fieldProps={{
                  name: 'subject',
                  required: true,
                }}
              />

              {showEmailField && (
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Email',
                    variant: 'outlined',
                  }}
                  fieldProps={{
                    name: 'email',
                    required: true,
                  }}
                />
              )}

              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Message',
                  multiline: true,
                  rows: '8',
                  variant: 'outlined',
                }}
                fieldProps={{
                  name: 'message',
                  required: true,
                }}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={modal.close} disabled={form.isSubmitting} variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="contained"
                disabled={form.isSubmitting}
                onClick={form.submitForm}
                disableElevation
              >
                Send message
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </Dialog>
  );
}
