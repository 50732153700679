import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { useMemo } from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { useResponsive } from 'shared/hooks';
import { ROUTES } from 'shared/constants';

import { InboxContent, MobileInboxContent } from '../components/Inbox';

interface LocationState {
  shouldRedirect?: boolean;
}

export function InboxPage() {
  const { isMobile } = useResponsive();
  const { pathname, state } = useLocation<LocationState>();
  const { isExact: isMatchExact } = useRouteMatch();

  const isAdmin = useMemo(() => {
    return pathname.startsWith('/admin');
  }, [pathname]);

  const shouldRedirect = isMatchExact && state?.shouldRedirect !== false;

  if (shouldRedirect) {
    return <Redirect to={isAdmin ? ROUTES.admin.mail.inbox : ROUTES.user.mail.inbox} />;
  }

  return <RouteLayout content={isMobile ? <MobileInboxContent /> : <InboxContent />} />;
}
