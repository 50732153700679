import { Fragment, useState } from 'react';
import { Search } from '@material-ui/icons';

import { SearchProvider } from 'shared/features/search';
import { HomeSearchModal } from 'shared/components/dialogs';

import { Container, Label } from './HomeSearchButton.styles';

interface Props {
  className?: string;

  /** @default "Search..." */
  label?: string;
}

export function HomeSearchButton(props: Props) {
  const [showModal, setShowModal] = useState(false);

  const label = props.label ?? 'Search...';

  const onClose = () => {
    setShowModal(false);
  };

  const onOpen = () => {
    setShowModal(true);
  };

  return (
    <Fragment>
      <SearchProvider>
        {showModal && <HomeSearchModal isModalOpen={showModal} onClose={onClose} />}
      </SearchProvider>

      <Container className={props.className} onClick={onOpen}>
        <Search color="inherit" fontSize="default" />
        <Label>{label}</Label>
      </Container>
    </Fragment>
  );
}
