import React from 'react';
import { useMutation, ApolloCache } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { SORT } from '@jebel/constants';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE, PAGE_SIZE } from 'shared/constants';
import { sendToSentry } from 'shared/utils/sentry';
import {
  InboxMessagesListQuery,
  InboxMessagesListQueryVariables,
  InboxMessageUpdateMutation,
  InboxMessageUpdateMutationVariables,
  InboxMessageDeleteMutation,
  InboxMessageDeleteMutationVariables,
  InboxMessagesCountQuery,
  InboxMessagesCountQueryVariables,
} from 'shared/graphql';

import {
  INBOX_MESSAGES_COUNT_QUERY,
  INBOX_MESSAGES_LIST_QUERY,
  INBOX_UPDATE_MESSAGE,
  INBOX_DELETE_MESSAGE,
} from '../queries';
import { createInboxMessageFilterOnLocation } from '../constants';

const readInboxMessagesCacheQuery = (cache: ApolloCache<unknown>, variables: any) => {
  const currentListCache = cache.readQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>(
    {
      query: INBOX_MESSAGES_LIST_QUERY,
      variables,
    },
  );

  const currentCountCache = cache.readQuery<
    InboxMessagesCountQuery,
    InboxMessagesCountQueryVariables
  >({
    query: INBOX_MESSAGES_COUNT_QUERY,
    variables,
  });

  return {
    listCache: currentListCache as InboxMessagesListQuery,
    countCache: currentCountCache,
  };
};

const updateChatMessagesCache = async (
  cache: ApolloCache<unknown>,
  data?: InboxMessageUpdateMutation['inboxMessageUpdate'],
  variables?: any,
) => {
  try {
    const { listCache } = readInboxMessagesCacheQuery(cache, variables);
    if (_.isNil(listCache)) return;

    const cacheMessageItems = listCache?.inbox?.messages?.items;
    const newMessages = cacheMessageItems?.map(message => {
      if (message.id === data?.id) {
        return {
          ...message,
          isDeleted: data?.isDeleted,
          isRead: data?.isRead,
        };
      }
      return message;
    });

    const newData = {
      inbox: {
        ...listCache?.inbox,
        messages: {
          items: newMessages,
        },
      },
    };

    await cache.writeQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>({
      query: INBOX_MESSAGES_LIST_QUERY,
      data: newData as InboxMessagesListQuery,
      variables,
    });
  } catch (error: any) {
    sendToSentry(error);
  }
};

export const useInboxMessageUpdate = (withAlert?: boolean, inboxId?: string | null | undefined) => {
  const location = useLocation();

  const filterMessages = React.useMemo(() => {
    return createInboxMessageFilterOnLocation(location.pathname);
  }, [location.pathname]);

  const VARIABLES = React.useMemo(
    () => ({
      inboxId: inboxId as string,
      firstMessages: PAGE_SIZE,
      sortMessages: {
        createdAt: SORT.asc,
      },
      filterMessages,
    }),
    [filterMessages, inboxId],
  );

  const [deleteMessageMutation] = useMutation<
    InboxMessageDeleteMutation,
    InboxMessageDeleteMutationVariables
  >(INBOX_DELETE_MESSAGE, {
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been deleted!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been deleted. Try again.`,
    },
  });

  const deleteMessage = React.useCallback(
    async (formData: InboxMessageUpdateMutationVariables['data']) => {
      await deleteMessageMutation({
        variables: {
          data: formData,
        },
      });
    },
    [deleteMessageMutation],
  );

  const [updateMessageMutation, mutationData] = useMutation<
    InboxMessageUpdateMutation,
    InboxMessageUpdateMutationVariables
  >(INBOX_UPDATE_MESSAGE, {
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    awaitRefetchQueries: true,
    context: withAlert
      ? {
          [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been deleted!',
          [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been deleted. Try again.`,
        }
      : undefined,
  });

  const updateMessage = React.useCallback(
    async (data: InboxMessageUpdateMutationVariables['data']) => {
      await updateMessageMutation({
        variables: {
          data,
        },
        update: (cache, { data }) => {
          if (!data) {
            return;
          }
          updateChatMessagesCache(cache, data?.inboxMessageUpdate, VARIABLES);
        },
      });
    },
    [VARIABLES, updateMessageMutation],
  );

  return { updateMessage, deleteMessage, mutationData };
};
