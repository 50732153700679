import Formatter from 'jspdf';
import attachTable from 'jspdf-autotable';
import { isObject } from 'lodash';
import { OperationVariables } from '@apollo/client';

import { sendToSentry } from 'shared/utils/sentry';

import {
  UseDownloadLazyQueryDocument,
  UseDownloadLazyQueryOptions,
  useDownloadLazyQuery,
} from './useDownloadLazyQuery';

interface Options {
  titles?: string[];
}

export type UseDownloadLazyQueryPDFOptions<D, V extends OperationVariables, T> = Options &
  UseDownloadLazyQueryOptions<D, V, T>;

const FALLBACK_FILENAME = 'jebel.csv';

/** Allow to download a `LazyQuery` to PDF format. */
export function useDownloadLazyQueryPDF<D = any, V extends OperationVariables = any, T = unknown>(
  query: UseDownloadLazyQueryDocument<D, V>,
  options: UseDownloadLazyQueryPDFOptions<D, V, T>,
) {
  const [generate, params] = useDownloadLazyQuery(query, options);

  const execute = async () => {
    try {
      const response = await generate();
      const formatter = new Formatter();

      const filename = options.filename ?? FALLBACK_FILENAME;
      const titles = extractTitles(response);

      const table = Array.from(response as unknown[]).map(row => {
        if (Array.isArray(row)) {
          return row;
        }

        if (isObject(row)) {
          return Object.values(row);
        }

        return [row];
      });

      formatter.text(options.displayName ?? filename, 5, 10);
      attachTable(formatter, { head: [titles], body: table });

      formatter.save(filename);
    } catch (err) {
      sendToSentry(err);
    }
  };

  const extractTitles = (response: T | D | T[]) => {
    if (Array.isArray(options.titles)) {
      return options.titles;
    }

    if (Array.isArray(response)) {
      return extractTitles(response[0]);
    }

    if (isObject(response)) {
      return Object.keys(response);
    }

    return [];
  };

  return [execute, params] as const;
}
