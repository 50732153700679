import { useMemo } from 'react';
import { EChartsOption as ChartOptions } from 'echarts';
import Chart from 'echarts-for-react';
import { useTheme } from '@emotion/react';

import { DountChartProps } from './DountChart.types';
import { createDountChartOptions } from './createDountChartOptions';

export function DountChart(props: DountChartProps) {
  const theme = useTheme();

  const options = useMemo<ChartOptions>(() => {
    return createDountChartOptions(props, theme);
  }, [props, theme]);

  return (
    <Chart
      option={options}
      notMerge
      lazyUpdate
      style={{ width: props.size ?? '100%', height: props.size ?? '100%' }}
    />
  );
}
