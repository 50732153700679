import { DeleteForever, Edit, Flag, MoreVert } from '@material-ui/icons';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import { useMemo, useRef, useState } from 'react';

import { HOME_FEED_ITEM_TYPES } from '@jebel/constants';

import { useCurrentUser, useCurrentUserPermissions } from 'shared/hooks';
import { useHomeFeedCardData } from 'shared/features/posts';

import { Container } from './HomeFeedCardOptions.styles';
import { useHomeFeedAuthor } from '../useHomeFeedAuthor';

interface Props {
  onEdit?(): void;
  onRemove?(): void;
  onReport?(): void;
}

export function HomeFeedCardOptions(props: Props) {
  const [isOpen, setOpen] = useState(false);

  const { user, isAdmin } = useCurrentUser();
  const { permissions } = useCurrentUserPermissions();
  const { data: author, loading } = useHomeFeedAuthor();

  const post = useHomeFeedCardData();
  const anchor = useRef<HTMLButtonElement | null>(null);

  const isHomePost = post.postType === HOME_FEED_ITEM_TYPES.HOME;
  const isGroupPost = post.postType === HOME_FEED_ITEM_TYPES.GROUP;
  const isSchoolPost = post.postType === HOME_FEED_ITEM_TYPES.SCHOOL;
  const isLegacyPost = post.postType === HOME_FEED_ITEM_TYPES.LEGACY;

  /** Checks whether can manage this because is the owner. */
  const isCurrentUserAuthor = author?.id === user?.id;

  const canReport = (isHomePost || isGroupPost) && !isCurrentUserAuthor;

  const canEdit = useMemo(() => {
    if (isSchoolPost || isLegacyPost) {
      // Checks the permissions of the role addon.
      return permissions.schoolPostsPermissions.edit;
    }

    if (isCurrentUserAuthor) {
      // Is the author and can edit the post.
      return permissions.homePostsPermissions.edit;
    }

    // Is admin and can edit home posts.
    return isAdmin && permissions.homePostsPermissions.edit;
  }, [isAdmin, isSchoolPost, isLegacyPost, isCurrentUserAuthor, permissions]);

  const canRemove = useMemo(() => {
    if (isSchoolPost || isLegacyPost) {
      // Checks the permissions of the role addon.
      return permissions.schoolPostsPermissions.delete;
    }

    if (isCurrentUserAuthor) {
      // Is the author and can remove the post.
      return permissions.homePostsPermissions.delete;
    }

    // Is admin and can remove home posts.
    return isAdmin && permissions.homePostsPermissions.delete;
  }, [isAdmin, isSchoolPost, isLegacyPost, isCurrentUserAuthor, permissions]);

  const showOptions = canEdit || canRemove || canReport;

  const openOptions = () => {
    setOpen(true);
  };

  const closeOptions = () => {
    setOpen(false);
  };

  const onEdit = () => {
    props.onEdit?.();
    closeOptions();
  };

  const onRemove = () => {
    props.onRemove?.();
    closeOptions();
  };

  if (!showOptions) {
    return null;
  }

  return (
    <Container>
      <IconButton ref={anchor} disabled={loading} onClick={openOptions}>
        <MoreVert />
      </IconButton>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={closeOptions}
        anchorEl={anchor.current}
      >
        <List>
          {canEdit && (
            <ListItem button onClick={onEdit}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>

              <ListItemText primary="Edit Post" />
            </ListItem>
          )}

          {canRemove && (
            <ListItem button onClick={onRemove}>
              <ListItemIcon>
                <DeleteForever />
              </ListItemIcon>

              <ListItemText primary="Delete Post" />
            </ListItem>
          )}

          {canReport && (
            <ListItem button onClick={props.onReport}>
              <ListItemIcon>
                <Flag />
              </ListItemIcon>

              <ListItemText primary="Flag as Inappropriate" />
            </ListItem>
          )}
        </List>
      </Popover>
    </Container>
  );
}
