import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useCurrentUser } from 'shared/hooks';
import { createSnackMessage, SNACK_TYPES } from 'shared/components/ui';
import { sendToSentry } from 'shared/utils/sentry';

import {
  InboxMessageUpdateMutation,
  InboxMessageUpdateMutationVariables,
} from '../../../shared/graphql/__generated__';
import { INBOX_UPDATE_MESSAGE, USER_TRASH_MESSAGES_QUERY } from '../queries';

export const useClearTrash = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();
  const { userId } = useCurrentUser();
  const [updateMessageMutation] = useMutation<
    InboxMessageUpdateMutation,
    InboxMessageUpdateMutationVariables
  >(INBOX_UPDATE_MESSAGE, {
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    awaitRefetchQueries: true,
  });

  const clearTrash = React.useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: {
          inboxMessagesList: { items: MessageIDs },
        },
      } = await client.query({
        query: USER_TRASH_MESSAGES_QUERY,
        fetchPolicy: 'network-only',
      });

      if (!MessageIDs) {
        return;
      }

      await Promise.all(
        MessageIDs.map(inbox => {
          return updateMessageMutation({
            variables: {
              data: {
                id: inbox.id,
                isHide: { connect: [{ id: userId as string }] },
              },
            },
          });
        }),
      );

      enqueueSnackbar('Success: Trash has been deleted!', {
        autoHideDuration: 5000,
        content: createSnackMessage(SNACK_TYPES.success),
      });
      setLoading(false);
    } catch (e: any) {
      sendToSentry(e);
      setLoading(false);
    }
  }, [client, enqueueSnackbar, userId, updateMessageMutation]);

  return {
    clearTrash,
    loading,
  };
};
