import { useMemo } from 'react';

import { createFilterBuilder } from '@jebel/utils';

import { useSearchContext } from 'shared/features/search';
import { GroupFilter } from 'shared/graphql';
import { useCurrentUserGroups } from 'shared/hooks';
import { createUserFilter } from 'features/search/utils';

/** Searching groups based on a search query provided. */
export function useGroupSearch() {
  const { searchQuery } = useSearchContext();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<GroupFilter>();

    if (searchQuery) {
      const filterCreator = createUserFilter({ search: searchQuery });

      filter.or({ title: { contains: searchQuery } });
      filter.or({ createdBy: filterCreator });
      filter.or({ creator: filterCreator });
    }

    return filter.build();
  }, [searchQuery]);

  const { data, loading } = useCurrentUserGroups({ variables: { filter } });

  return {
    data,
    loading,
  };
}
