import { useMutation } from '@apollo/client';

import { INCREASE_DISCOUNT_CLICKS_COUNT, INCREASE_DISCOUNT_VIEWS_COUNT } from '../query';

export const useDiscountActivity = () => {
  const [viewAdd, { loading: addingView }] = useMutation(INCREASE_DISCOUNT_VIEWS_COUNT);
  const [clickAdd, { loading: addingClick }] = useMutation(INCREASE_DISCOUNT_CLICKS_COUNT);

  const addClick = async (postId: string) => {
    if (!postId) {
      return;
    }

    try {
      await clickAdd({
        variables: {
          id: postId,
        },
      });
    } catch (e) {
      console.log('Couldnt Add Like');
    }
  };

  const addView = async (postId: string) => {
    if (!postId) {
      return;
    }

    try {
      await viewAdd({
        variables: {
          id: postId,
        },
      });
    } catch (e) {
      console.log('Couldnt Add View');
    }
  };

  return { addView, addClick, loading: addingView || addingClick };
};
