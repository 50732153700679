import { descend } from 'ramda';
import { GRADUATING_YEAR_START_YEAR } from '@jebel/constants';
import { createRange } from './ranges';
/**
 * Generate graduating years from 1959 up to the next 2 years from the current year.
 * @returns List of `SelectOption`
 */
export const generateGraduatingYearsOptions = () => {
    const now = new Date();
    const year = now.getFullYear();
    /** https://8base-dev.atlassian.net/browse/JEB-1259  */
    const range = createRange(GRADUATING_YEAR_START_YEAR, year + 2);
    const years = [...range];
    return years.sort(descend(year => year)).map(year => {
        const label = `Class of ${year}`;
        const value = `${year}`;
        return { label, value };
    });
};
