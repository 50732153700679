import { forwardRef, useCallback } from 'react';
import { SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import { Alert, Color } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { Flag, Launch } from '@material-ui/icons';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';

export enum SNACK_TYPES {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

const alertContainerCSS = css`
  max-width: 30rem;
  /** Extracted from https://mui.com/material-ui/customization/z-index */
  z-index: 1400;
`;

/** @deprecated Use `useToast` instead. */
export const createSnackMessage = (variant: SNACK_TYPES, onClick?: () => void) => {
  return (key: string, message: string) => (
    <SnackMessage id={key} variant={variant} message={message} onClick={onClick} />
  );
};

export interface SnackMessageProps {
  id?: SnackbarKey;
  message: string | React.ReactNode;
  variant: SNACK_TYPES | Color;

  /**
   * The label for the action button.
   * @default 'Open'
   */
  label?: string;

  /**
   * Mark this error message as reportable.
   * @default false
   */
  reportable?: boolean;

  onClick?: () => void;
  onReport?(): void;
}

export const SnackMessage = forwardRef<HTMLDivElement, SnackMessageProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const label = props.label ?? 'Open';
  const reportable = props.reportable ?? false;

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const showReportAction = Boolean(props.onReport) && reportable;
  const showAction = Boolean(props.onClick) || showReportAction;

  return (
    <SnackbarContent ref={ref}>
      <Alert
        css={alertContainerCSS}
        severity={props.variant}
        onClose={handleDismiss}
        action={
          showAction ? (
            <Stack direction="row" spacing={1}>
              {props.onClick && (
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  endIcon={<Launch color="inherit" />}
                  onClick={props.onClick}
                >
                  {label}
                </Button>
              )}

              {showReportAction && (
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  endIcon={<Flag color="inherit" />}
                  onClick={props.onReport}
                >
                  Report
                </Button>
              )}
            </Stack>
          ) : undefined
        }
      >
        {props.message}
      </Alert>
    </SnackbarContent>
  );
});
