import React from 'react';

import { StreamChatActivity } from 'shared/hooks/useStreamChatActivity';

/** @deprecated No longer needed, use `HomeFeedCard` instead. */
export type PostsContextValue = {
  activities: StreamChatActivity[];
  loading: boolean;
};

/** @deprecated No longer needed, use `HomeFeedCard` instead. */
export const PostsContext = React.createContext<PostsContextValue>({
  activities: [],
  loading: false,
});
