import { useOrganizationsFilter, useOrganizationsPaginated } from 'features/organizations/hooks';

interface Options {
  first?: number;
}

/** Explore all the organizations using the same API as **Organizations** page. */
export function useExplorerOrganizations(options?: Options) {
  const { filter } = useOrganizationsFilter();

  return useOrganizationsPaginated({
    variables: { filter, first: options?.first },
  });
}
