import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar as AvatarBase, ListItem } from '@material-ui/core';

import { AVATAR_SIZE } from 'features/inbox/constants';

const textEllipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

// Container

interface ContainerProps {
  isSelected?: boolean;
}

export const Container = styled(ListItem)<ContainerProps>`
  min-width: 0;
  display: flex;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  overflow-x: hidden;
  gap: 0.75rem;

  ${p =>
    p.isSelected &&
    css`
      background-color: ${p.theme.palette.grey[200]};
    `}
`;

// Avatar

export const Avatar = styled(AvatarBase)`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

// Content

export const Content = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

// HeaderName

export const HeaderName = styled.div`
  display: flex;
  align-items: center;
  max-width: 45%;
  gap: 0.5rem;
`;

// Name

export const Name = styled.span`
  ${textEllipsis};

  font-weight: bold;
`;

// Indicator

export const Indicator = styled.span`
  --size: 0.35rem;

  display: block;
  min-width: var(--size);
  min-height: var(--size);
  background-color: ${p => p.theme.palette.primary.main};
  border-radius: var(--size);
`;

// TimeStamp

export const TimeStamp = styled.span`
  color: ${p => p.theme.palette.grey[600]};
  text-align: right;
  font-size: 0.7rem;
`;

// Subject

export const Subject = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;

// Message

export const Message = styled.span`
  ${textEllipsis};
`;
