export var GroupStatus;
(function (GroupStatus) {
    GroupStatus["Pending"] = "pending";
    GroupStatus["Inactive"] = "inactive";
    GroupStatus["Active"] = "active";
    GroupStatus["Rejected"] = "rejected";
})(GroupStatus || (GroupStatus = {}));
export var GroupUserRequestStatus;
(function (GroupUserRequestStatus) {
    GroupUserRequestStatus["Approved"] = "Approved";
    GroupUserRequestStatus["Pending"] = "Pending";
    GroupUserRequestStatus["Rejected"] = "Rejected";
})(GroupUserRequestStatus || (GroupUserRequestStatus = {}));
export var GroupAdminStatus;
(function (GroupAdminStatus) {
    GroupAdminStatus["Active"] = "active";
    GroupAdminStatus["Pending"] = "pending";
    GroupAdminStatus["Rejected"] = "rejected";
})(GroupAdminStatus || (GroupAdminStatus = {}));
/** @deprecated Use {@linkcode GroupStatus} instead. */
export const GROUP_STATUSES = {
    pending: 'pending',
    inactive: 'inactive',
    active: 'active',
    rejected: 'rejected',
};
/** @deprecated Use {@linkcode GroupAdminStatus} instead. */
export const GROUP_ADMIN_STATUSES = {
    active: 'active',
    pending: 'pending',
    rejected: 'rejected',
};
