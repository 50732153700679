import { css } from '@emotion/react';
import { Box, Divider } from '@mui/material';

import { SORT } from '@jebel/constants';

import { Button, Typography } from 'shared/components/ui';
import { PaperLayout } from 'shared/components/layouts';
import { SearchProvider, SearchControl, SortFieldProps } from 'shared/features/search';
import { useResponsive } from 'shared/hooks/useResponsive';
import { useCrudPermissions, useModalState, usePageTitle } from 'shared/hooks';

import { GroupCreateModal } from './GroupCreateModal';
import { GroupsList } from './GroupsList';

const postButtonCSS = theme => css`
  background-color: ${theme.palette.primary.light};
  height: 40px;
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 17px;

  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.fontSize - 2}px;
  }
`;

const titleCss = theme => css`
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.fontSize + 8}px;
    margin-right: ${theme.spacing(0.5)}px;
  }
`;

const SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Most Popular',
        value: {
          membersCount: SORT.desc,
        },
      },
      {
        label: 'Most Recent',
        value: {
          createdAt: SORT.desc,
        },
      },
    ],
  },
};

export function GroupsContent() {
  const title = usePageTitle({ fallback: 'Groups' });

  const {
    groupsPermissions: { add: allowRequestGroup },
    loading: loadingAddons,
  } = useCrudPermissions();

  const { isMobile } = useResponsive();
  const { show: showCreateModal, open: openCreateModal, close: closeCreateModal } = useModalState();

  return (
    <SearchProvider>
      <GroupCreateModal
        title="Request New Group"
        isOpen={showCreateModal}
        onClose={closeCreateModal}
      />

      <PaperLayout>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={{ xs: 2.5, md: 4 }}
        >
          <Typography css={titleCss} variant="subtitle2" color="primary">
            {title}
          </Typography>

          {allowRequestGroup && !loadingAddons && (
            <Button
              color="primary"
              variant="contained"
              css={postButtonCSS}
              onClick={openCreateModal}
            >
              Request new group
            </Button>
          )}
        </Box>

        <Box py={{ sm: 1, md: 1 }} px={{ xs: 2.5, md: 4 }}>
          <SearchControl
            withSortField
            withLayoutControl={!isMobile}
            textFieldProps={{ style: { flexShrink: 1 }, placeholder: 'Search...' }}
            sortFieldProps={SORT_INFO}
            elementsWidth={!isMobile ? ['2fr', '1fr', '0fr'] : []}
          />
        </Box>

        <Box my={2}>
          <Divider />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          maxWidth="100%"
          padding={{ xs: 2.5, md: 4 }}
          minWidth={0}
        >
          <GroupsList />
        </Box>
      </PaperLayout>
    </SearchProvider>
  );
}
