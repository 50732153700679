import React from 'react';
import { Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Divider } from '@material-ui/core';

import { SearchProvider } from 'shared/features/search';
import { SxProp } from 'shared/types/styles';
import { useGlobalState } from 'providers';

import { ContentCurationFlaggedPostsList } from './ContentCurationFlaggedPostsList';

const titleStyles: SxProp = theme => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  fontSize: theme.typography.fontSize + 7,
});

export const ContentCurationFlaggedPosts: React.FC = () => {
  const { contentCurationBubble } = useGlobalState();

  React.useEffect(() => contentCurationBubble.close(), [contentCurationBubble]);

  return (
    <SearchProvider>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        css={css`
          min-width: 0;
        `}
      >
        <Box mx={2} my={2} display="flex">
          <Typography sx={titleStyles}>Flagged Posts</Typography>
        </Box>
        <Divider />
        <ContentCurationFlaggedPostsList />
      </Box>
    </SearchProvider>
  );
};
