import { gql } from '@apollo/client';

export const HOBBIES_UPDATE_MUTATION = gql`
  mutation HobbiesUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      hobbies
    }
  }
`;

export const PROFESSIONAL_PROFILE_FRAGMENT = gql`
  fragment ProfessionalProfile on JobEmployee {
    id
    employmentType
    positionDescription
    positionTitle
    isCurrentlyWorking
    startDate
    endDate
    companyName

    companyLocation {
      state
      city
      street1
      zip
    }

    companyImage {
      fileId
      downloadUrl
    }
  }
`;

export const EDUCATION_PROFILE_FRAGMENT = gql`
  fragment EducationProfile on EducationalInstitution {
    id
    degree
    fieldOfStudy
    gpa
    activities
    description
    startDate
    endDate

    address {
      id
      street1
      zip
      city
      state
    }

    image {
      fileId
      downloadUrl
    }

    highSchool {
      id
      name
      logo {
        downloadUrl
      }
    }
  }
`;

export const EDUCATION_PROFILE_CREATE_MUTATION = gql`
  mutation EducationProfileCreate($data: EducationalInstitutionCreateInput!) {
    educationProfile: educationalInstitutionCreate(data: $data) {
      ...EducationProfile
    }
  }

  ${EDUCATION_PROFILE_FRAGMENT}
`;

export const EDUCATION_PROFILE_UPDATE_MUTATION = gql`
  mutation EducationProfileUpdate($data: EducationalInstitutionUpdateInput!) {
    educationProfile: educationalInstitutionUpdate(data: $data, destroyDetached: true) {
      ...EducationProfile
    }
  }

  ${EDUCATION_PROFILE_FRAGMENT}
`;

export const EDUCATION_PROFILE_REMOVE_MUTATION = gql`
  mutation EducationProfileRemove($filter: EducationalInstitutionKeyFilter!) {
    commit: educationalInstitutionDelete(filter: $filter) {
      success
    }
  }
`;

export const ACTIVITY_CLUBS_UPDATE_MUTATION = gql`
  mutation ActivityClubsUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      activityClubs
    }
  }
`;

const GROUP_INFO_FRAGMENT = gql`
  fragment GroupInfo on Group {
    id
    creator {
      id
    }
    title
    membersCount
    description
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    status
    members {
      items {
        id
        email
        firstName
        lastName
      }
    }
    groupAdminPreferences {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;

export const GROUP_SEARCH_QUERY = gql`
  query GroupSearch($filter: GroupFilter, $first: Int!, $skip: Int) {
    groupsList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...GroupInfo
      }
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;

export const GROUP_LEAVE_MUTATION = gql`
  mutation GroupLeave($data: GroupUpdateInput!) {
    groupUpdate(data: $data) {
      id
      members {
        items {
          id
          email
        }
      }
    }
  }
`;

export const GROUP_DELETE_MUTATION = gql`
  mutation GroupDelete($filter: GroupKeyFilter!) {
    groupDelete(filter: $filter) {
      success
    }
  }
`;

export const USER_UPDATE_PASSWORD_MUTATION = gql`
  mutation UserUpdatePassword($data: UserPasswordChangeInput!) {
    userPasswordChangeRequest(data: $data) {
      success
    }
  }
`;

export const GROUP_DETAILS_UPDATE_MUTATION = gql`
  mutation GroupDetailsUpdate($data: GroupUpdateInput!) {
    groupDetailsUpdateRequest(data: $data) {
      success
    }
  }
`;

export const USER_UPDATE_EMAIL_MUTATION = gql`
  mutation UserUpdateEmail($data: UserEmailChangeInput!) {
    userEmailChangeRequest(data: $data) {
      success
    }
  }
`;

export const GROUP_USERS_LIST_QUERY = gql`
  query GroupUsersList($first: Int, $skip: Int, $filter: UserFilter, $sort: [UserSort!]) {
    usersList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      items {
        id
        firstName
        lastName
        affiliation
        groupAdminPreferences {
          id
          group {
            items {
              id
            }
          }
        }
        groupPosts {
          count
        }
      }
    }
  }
`;

export const GROUP_MEMBER_REMOVE_MUTATION = gql`
  mutation GroupMemberRemove($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberRemove(group: $group, user: $user) {
      success
    }
  }
`;

export const GROUP_MEMBER_PROMOTE_MUTATION = gql`
  mutation GroupMemberPromote($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberPromote(group: $group, user: $user) {
      success
    }
  }
`;

export const GROUP_MEMBER_DEMOTE_MUTATION = gql`
  mutation GroupMemberDemote($group: GroupKeyFilter!, $user: UserKeyFilter!) {
    groupMemberDemote(group: $group, user: $user) {
      success
    }
  }
`;

export const JOB_EMPLOYEE_CREATE_MUTATION = gql`
  mutation JobEmployeeCreate($data: JobEmployeeCreateInput!) {
    jobEmployeeCreate(data: $data) {
      ...ProfessionalProfile
    }
  }

  ${PROFESSIONAL_PROFILE_FRAGMENT}
`;

export const JOB_EMPLOYEE_DELETE_MUTATION = gql`
  mutation JobEmployeeDelete($id: ID!) {
    jobEmployeeDelete(data: { id: $id }) {
      success
    }
  }
`;

export const JOB_EMPLOYEE_UPDATE_MUTATION = gql`
  mutation JobEmployeeUpdate($data: JobEmployeeUpdateInput!) {
    jobEmployeeUpdate(data: $data) {
      ...ProfessionalProfile
    }
  }

  ${PROFESSIONAL_PROFILE_FRAGMENT}
`;
