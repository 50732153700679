import React from 'react';
import { css } from '@emotion/react';
import { Box, Paper } from '@material-ui/core';

import { Button, AsyncContent, Typography } from 'shared/components/ui';
import { PostsProvider, Post } from 'shared/features/posts';
import { GroupPostsListItemFragment } from 'shared/graphql';
import { useStreamChatReactions } from 'shared/hooks/useStreamChatActivity';

import { useGroupPostQuery } from '../../hooks';

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.error.light};
  color: #ffffff;
`;

const commonPaperCss = (theme: any) => css`
  box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(2)}px ${theme.spacing(2)}px;
`;

const deleteCss = (theme: any) => css`
  color: ${theme.palette.error.light};
  font-weight: 600;
`;

export const ConfirmDeleteGroupPostModal = ({
  onModalClose,
  onDeletePost,
  loading,
  id,
}: {
  onModalClose: () => void;
  onDeletePost: (id: string) => void;
  loading: boolean;
  id: string | undefined;
}) => {
  const { posts, activities, activitiesLoading, loading: postsLoading } = useGroupPostQuery(id);
  const { onCommentAdd, onLikeAdd, onReactionRemove, onCommentRemove } = useStreamChatReactions();
  const renderItem = React.useCallback(
    (data: GroupPostsListItemFragment) => {
      const { author: postAuthor, id, ...itemProps } = data;

      if (!id) {
        return null;
      }

      const author = {
        avatar: {
          downloadUrl: postAuthor?.avatar?.downloadUrl || '',
        },
        name: `${postAuthor?.firstName || ''} ${postAuthor?.lastName || ''}`,
      };

      return (
        <Post
          {...itemProps}
          id={id}
          author={author}
          onPostDelete={() => {}}
          onPostEdit={() => {}}
          onPostFlag={() => {}}
          onCommentAdd={onCommentAdd}
          onLikeAdd={onLikeAdd}
          onCommentRemove={onCommentRemove}
          onReactionRemove={onReactionRemove}
          withInteractions={false}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onCommentAdd, onCommentRemove, onLikeAdd, onReactionRemove],
  );

  const onDeletePostClick = React.useCallback(() => {
    onDeletePost(id ?? '');
    onModalClose();
  }, [id, onDeletePost, onModalClose]);
  return (
    <AsyncContent loading={loading || (!!posts.length && !activities.length) || postsLoading}>
      <Box display="flex" flexDirection="column" justifyContent="start">
        <Paper css={commonPaperCss}>
          <PostsProvider activities={activities} loading={activitiesLoading}>
            <React.Fragment>{posts.map(renderItem)}</React.Fragment>
          </PostsProvider>
        </Paper>
        <Box my={1} mx={2}>
          <Typography variant="subtitle5" css={deleteCss}>
            Are you sure you want to delete this post?
          </Typography>
          <Typography variant="body1" color="textSecondary">
            This post will be removed immediately from the platform. You can undo this action from
            the Content Curation menu.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button css={cancelButtonCSS} disabled={loading} onClick={onModalClose} size="medium">
            GO BACK
          </Button>
          <Button
            css={submitButtonCSS}
            loading={loading}
            disableElevation
            variant="contained"
            onClick={onDeletePostClick}
          >
            YES, DELETE POST
          </Button>
        </Box>
      </Box>
    </AsyncContent>
  );
};
