import { useMemo } from 'react';

import { useSchoolConfiguration } from './useSchoolConfiguration';

interface OptionsBase {
  /**
   * Allow to specify the pathname used in the validation.
   * @default window.location.pathname
   */
  pathname?: string;
}

interface OptionsWithFallback extends OptionsBase {
  fallback: string;
}

interface OptionsWithoutFallback extends OptionsBase {
  fallback?: undefined;
}

type Options = OptionsWithFallback | OptionsWithoutFallback;

export function usePageTitle(options: OptionsWithFallback): string;

export function usePageTitle(options?: Options): string | undefined;

/** Get the page title configurated using the {@link useSchoolConfiguration} hook. */
export function usePageTitle(options?: Options) {
  const { configuration } = useSchoolConfiguration();

  return useMemo(() => {
    const route = options?.pathname ?? window.location.pathname;

    const layout = configuration?.layout?.items ?? [];
    const section = layout.find(section => section.route === route);

    if (!section?.name && options && 'fallback' in options) {
      return options.fallback;
    }

    return section?.name;
  }, [configuration, options]);
}
