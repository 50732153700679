import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

interface StyledAvatarProps {
  disabled?: boolean;
}

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
`;
