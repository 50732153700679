import { OneSignalContext } from 'providers';
import { useContext } from 'react';
import { PushNotificationProvider } from 'shared/types';

/**
 * Custom hook for accessing push notification context.
 * @returns The push notification context.
 * @throws {Error} If used outside of a push notification provider.
 */
export function usePushNotifications(): PushNotificationProvider {
  const context = useContext(OneSignalContext);

  if (!context) {
    throw new Error('"usePushNotifications" must be used within a "OneSignalProvider"');
  }

  return context;
}
