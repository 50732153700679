import { gql } from '@apollo/client';

import { EVENT_INFO_FRAGMENT } from 'shared/graphql';

export const USER_FRAGMENT = gql`
  fragment UserListItem on User {
    id
    firstName
    lastName
    birthDate
    avatar {
      id
      downloadUrl
    }
  }
`;

export const MEMBER_DISCOUNT_FRAGMENT = gql`
  fragment MemberDiscountItem on Discount {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    title
    amountPercent
    type
    startDate
    expirationDate
    timezone
    category
    status
    organization {
      id
      name
      logo {
        downloadUrl
      }
    }
    images {
      items {
        id
        downloadUrl
      }
    }
    usersRedeemedCount
    terms
  }
`;

export const MEMBER_COMMUNITY_SIDEBAR_QUERY = gql`
  query MemberCommunitySidebarItems($dateNow: DateTime!, $birthdayFilter: UserFilter!) {
    eventsList(
      sort: { date: ASC }
      first: 3
      filter: {
        status: { equals: "active" }
        date: { gte: $dateNow }
        isApproved: { equals: true }
      }
    ) {
      items {
        ...EventInfo
      }
    }

    usersList(sort: { birthDate: ASC }, filter: $birthdayFilter) {
      items {
        ...UserListItem
      }
    }
  }

  ${EVENT_INFO_FRAGMENT}
  ${USER_FRAGMENT}
`;
