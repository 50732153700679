import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { JobOfferDeleteMutation, JobOfferDeleteMutationVariables } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

import { JOB_OFFER_DELETE_MUTATION } from '../queries';

export const useJobPostDelete = () => {
  const [jobPostDelete] = useMutation<JobOfferDeleteMutation, JobOfferDeleteMutationVariables>(
    JOB_OFFER_DELETE_MUTATION,
    {
      refetchQueries: ['CareerSearch', 'CareersPageSearch'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Your Job Post has been deleted.',
        [SNACKBAR_ERROR_MESSAGE]: `Your Job Post hasn't been deleted`,
      },
    },
  );

  const onJobPostDelete = React.useCallback(
    async (jobOfferId?: string | null) => {
      try {
        jobPostDelete({
          variables: {
            filter: {
              id: jobOfferId,
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [jobPostDelete],
  );
  return { onJobPostDelete };
};
