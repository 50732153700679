import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { SORT } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { PAGE_SIZE, SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import {
  InboxChatsListQuery,
  MessageSendRequestMutation,
  MessageSendRequestMutationVariables,
  MESSAGE_SEND_REQUEST_MUTATION,
  InboxFilter,
  useInboxChatsListQuery,
} from 'shared/graphql';
import { useQueryParams } from 'shared/hooks';

import { InboxParams, createInboxMessageFilterOnLocation } from '../constants';

const transformQueryData = (listData: InboxChatsListQuery | undefined) => {
  const items = listData?.inboxes?.items ?? [];
  const count = listData?.inboxes?.count ?? 0;
  const hasMore = items.length < count;

  return { items, count, hasMore };
};

const SORT_OPTION = {
  updatedAt: SORT.desc,
};

export type InboxCreateFormData = {
  subject: unknown;
  text: string;
  userIds?: string[];
  media?: string;
  type?: string;
  destinationGroups?: unknown;
};

export const useInboxChatsList = () => {
  const [, { setParam }] = useQueryParams<InboxParams>();

  const { searchQuery } = useSearchContext();
  const { pathname } = useLocation();

  const setInboxMessages = (inboxId: string) => {
    setParam('inboxId', inboxId);
  };

  const [messageSendMutation] = useMutation<
    MessageSendRequestMutation,
    MessageSendRequestMutationVariables
  >(MESSAGE_SEND_REQUEST_MUTATION, {
    refetchQueries: ['InboxChatsList', 'InboxMessagesList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been sent!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been sent. Try again.`,
    },
  });

  const filter = useMemo(() => {
    const filter = createFilterBuilder<InboxFilter>();

    const messagesFilter = createInboxMessageFilterOnLocation(pathname);

    filter.and({ users: { some: { is_self: true } } });
    filter.and({ messages: { some: messagesFilter } });

    if (searchQuery) {
      const searchFilter = createFilterBuilder<InboxFilter>();

      searchFilter.or({ messages: { some: { text: { contains: searchQuery } } } });
      searchFilter.or({ users: { some: { fullName: { contains: searchQuery } } } });

      filter.and(searchFilter);
    }

    return filter.build();
  }, [pathname, searchQuery]);

  const onCreateInbox = useCallback(
    async (formData: InboxCreateFormData) => {
      await messageSendMutation({ variables: formData });
    },
    [messageSendMutation],
  );

  const {
    data: listData,
    loading: listLoading,
    refetch,
    fetchMore,
  } = useInboxChatsListQuery({
    variables: {
      first: PAGE_SIZE,
      filter,
      sort: SORT_OPTION,
      sortMessages: SORT_OPTION,
    },
  });

  const returnData = useMemo(() => transformQueryData(listData), [listData]);

  return {
    ...returnData,
    loading: listLoading && !listData,
    fetchMore,
    refetch,
    setInboxMessages,
    onCreateInbox,
  };
};
