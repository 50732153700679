import { Fragment, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, MenuItem } from '@mui/material';
import { ArrayHelpers, FieldArray } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  Form,
  FormTextField,
  FormSelect,
  Button,
  Typography,
  FormPhoneFieldV2 as FormPhoneField,
  AsyncContent,
  Icon,
  AutocompleteField,
} from 'shared/components/ui';
import { DISCOUNTS_OPTIONS, US_STATES_OPTIONS, FIELDS_CONFIG, APP_URL } from 'shared/constants';
import { required } from 'shared/utils/form';
import { useCrudPermissions, useToast } from 'shared/hooks';
import {
  BUSINESS_FORM_LOCATION_ADDITIONAL_FIELDS,
  BUSINESS_FORM_LOCATION_BLANK,
  BusinessFormData,
  generateBusinessFormInitials,
  generateBusinessFormUpdate,
} from 'shared/features/organizations';

import { useBusinessEdit, useBusinessSearch } from '../hooks';

const MAX_FORM_WIDTH = 720;

const LOCATION_FIELDS = [
  { name: 'Location Name', field: 'locationName' },
  { name: 'Address Line', field: 'address.street1' },
  { name: 'City', field: 'address.city' },
];

const formCss = theme => css`
  padding: ${theme.spacing(4)}px;
  max-width: ${MAX_FORM_WIDTH}px;
`;

const addLocationButtonCss = (theme: any) => css`
  color: ${theme.palette.primary.icon};
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
`;

const addLocation = (push: ArrayHelpers['push']) => () => {
  push(BUSINESS_FORM_LOCATION_BLANK);
};

export const BusinessDetailsInformationPage = ({ businessId }: { businessId: string }) => {
  const {
    businessPermissions: { edit: ifEditAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();
  const history = useHistory();

  const { business, loading } = useBusinessSearch(businessId ?? '');
  const { showError } = useToast();
  const { onBusinessEdit } = useBusinessEdit();

  const initial = useMemo(() => generateBusinessFormInitials(business), [business]);

  const onSubmit = async (form: BusinessFormData) => {
    try {
      const payload = generateBusinessFormUpdate(form, business);
      await onBusinessEdit(payload);
    } catch (err) {
      showError('Something went wrong trying to update the location, please try again');

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const deleteLocation = useCallback(
    (remove: ArrayHelpers['remove'], index: number, id?: string | undefined) => () => {
      remove(index);
    },
    [],
  );

  return (
    <AsyncContent loading={loading}>
      <Form
        oldCss={formCss}
        onSubmit={onSubmit}
        initialValues={initial}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Box display="grid" gridTemplateRows="auto" gap={2}>
            <Box display="grid" gridTemplateColumns="auto auto" alignItems="center" gap={2}>
              <FormTextField
                disabled={!ifEditAccessed}
                inputProps={{
                  label: 'BUSINESS NAME *',
                  variant: 'outlined',
                  disabled: !ifEditAccessed,
                  required: true,
                }}
                fieldProps={{ name: 'name', required: true }}
              />
              <FormSelect
                selectProps={{
                  children: DISCOUNTS_OPTIONS?.slice(1).map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'INDUSTRY *',
                  disabled: !ifEditAccessed,
                  required: true,
                }}
                fieldProps={{ name: 'industry', required: true }}
              />
            </Box>
            <FormTextField
              disabled={!ifEditAccessed}
              inputProps={{
                color: 'primary',
                label: 'DESCRIPTION',
                multiline: true,
                rows: '7',
                rowsMax: '7',
                variant: 'outlined',
                disabled: !ifEditAccessed,
                required: true,
              }}
              fieldProps={{ name: 'description', required: true }}
            />
            <FormSelect
              selectProps={{
                children: FIELDS_CONFIG.organizationStatus.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                )),
                variant: 'outlined',
                label: 'STATUS',
                disabled: !ifEditAccessed,
              }}
              fieldProps={{ name: 'status', required: true }}
            />
            <FieldArray name="location" validateOnChange={false}>
              {({ push, remove }) => {
                const locations = values?.location || [];

                return (
                  <Fragment>
                    {locations.map((field, index) => {
                      const isRequiredLocation = index === 0;

                      return (
                        <Fragment key={`location.${index}`}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle5">Location #{index + 1}</Typography>
                            {!isRequiredLocation && (
                              <Button onClick={deleteLocation(remove, index, field.id)}>
                                <Icon name="DeleteForever" />
                              </Button>
                            )}
                          </Box>

                          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                            {LOCATION_FIELDS.map(item => (
                              <Fragment key={item.name}>
                                <FormTextField
                                  inputProps={{
                                    color: 'primary',
                                    label: `${item.name} *`,
                                    variant: 'outlined',
                                    disabled: !ifEditAccessed,
                                  }}
                                  fieldProps={{
                                    name: `location.${index}.${item.field}`,
                                    validate: required,
                                  }}
                                />
                              </Fragment>
                            ))}

                            <AutocompleteField
                              label="State"
                              name={`location.${index}.address.state`}
                              options={US_STATES_OPTIONS}
                              disabled={!ifEditAccessed}
                              required
                            />

                            <FormTextField
                              inputProps={{
                                color: 'primary',
                                label: `ZIP Code`,
                                variant: 'outlined',
                                disabled: !ifEditAccessed,
                                required: true,
                              }}
                              fieldProps={{
                                name: `location.${index}.address.zip`,
                                required: true,
                              }}
                            />

                            {BUSINESS_FORM_LOCATION_ADDITIONAL_FIELDS.map(item => {
                              if (item.type === 'phone') {
                                return (
                                  <FormPhoneField
                                    key={item.name}
                                    inputProps={{
                                      label: 'Phone Number',
                                      variant: 'outlined',
                                      required: item.required,
                                    }}
                                    fieldProps={{
                                      name: `location.${index}.${item.name}`,
                                      validate: item.validate,
                                      required: item.required,
                                    }}
                                  />
                                );
                              }

                              if (item.type === 'description') {
                                return (
                                  <FormTextField
                                    key={item.name}
                                    inputProps={{
                                      label: item.label,
                                      multiline: true,
                                      rows: '3',
                                      rowsMax: '3',
                                      variant: 'outlined',
                                      required: item.required,
                                    }}
                                    fieldProps={{
                                      name: `location.${index}.${item.name}`,
                                      validate: item.validate,
                                      required: item.required,
                                    }}
                                  />
                                );
                              }

                              return (
                                <FormTextField
                                  key={item.name}
                                  inputProps={{
                                    label: item.label,
                                    variant: 'outlined',
                                    required: item.required,
                                  }}
                                  fieldProps={{
                                    name: `location.${index}.${item.name}`,
                                    validate: item.validate,
                                    required: item.required,
                                  }}
                                />
                              );
                            })}
                          </Box>
                        </Fragment>
                      );
                    })}
                    <Box>
                      <Button
                        disabled={!ifEditAccessed}
                        onClick={addLocation(push)}
                        css={addLocationButtonCss}
                      >
                        Add another location
                      </Button>
                    </Box>
                  </Fragment>
                );
              }}
            </FieldArray>
            <Box display="flex" justifyContent="end" mb={3}>
              <Box mr={1}>
                <Button
                  disabled={isSubmitting || !ifEditAccessed || loadingAddons}
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    history.push(APP_URL.admin.business.index);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Button
                color="primary"
                disableElevation
                disabled={!ifEditAccessed || loadingAddons}
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        )}
      </Form>
    </AsyncContent>
  );
};
