import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { CollectiveIntelligenceItemFragment } from 'shared/graphql/__generated__';

import { CollectiveIntelligenceThread } from './CollectiveIntelligenceThread';
import { AddAdsToContent } from '../../../advertising/utils/addAdsToContent';

const threadsBoxCss = theme => css`
  margin: ${theme.spacing(3)}px ${theme.spacing(4)}px;

  ${theme.breakpoints.down('sm')} {
    margin: ${theme.spacing(2)}px ${theme.spacing(2.5)}px;
  }
`;

type ThreadProps = CollectiveIntelligenceItemFragment[];

export const CollectiveIntelligenceThreadsList = ({ data }: { data: ThreadProps }) => {
  const renderItem = item => <CollectiveIntelligenceThread key={item.id} item={item} />;
  return (
    <Box css={threadsBoxCss}>
      <AddAdsToContent items={data} renderItem={renderItem} />
    </Box>
  );
};
