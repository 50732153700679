import { useMemo } from 'react';

import {
  Maybe,
  OrganizationFilter,
  UserFilter,
  UserKeyFilter,
  useOrganizationsListLazyQuery,
  useOrganizationsListQuery,
} from 'shared/graphql';
import { createFilterBuilder } from 'shared/queries/filterBuilder';
import { useOrganizations } from 'features/organizations/hooks';

import { useCurrentUser } from './useCurrentUser';

type Options = Parameters<typeof useOrganizationsListQuery>[0];

/** List all the organizations that belongs to the current user. */
export function useCurrentUserOrganizations(options?: Options) {
  const { user, loading: userLoading } = useCurrentUser();

  const filter = useMemo(() => {
    const filter = createCurrentUserOrganizationsFilter({
      initial: options?.variables?.filter,
      user,
    });

    return filter.build();
  }, [user, options?.variables?.filter]);

  const {
    data,
    count,
    loading: organizationsLoading,
    refetch,
    fetchMore,
  } = useOrganizations({
    ...options,

    skip: userLoading,
    variables: { ...options?.variables, filter },
  });

  return {
    data,
    count,
    loading: userLoading || organizationsLoading,
    refetch,
    fetchMore,
  };
}

/** List all the organizations that belongs to the current user. */
export function useCurrentUserOrganizationsLazy(options?: Options) {
  const { user } = useCurrentUser();

  const [executeQuery, params] = useOrganizationsListLazyQuery(options);

  const execute = async (params?: Parameters<typeof executeQuery>[0]) => {
    const filter = createCurrentUserOrganizationsFilter({
      initial: params?.variables?.filter,
      user,
    });

    if (params?.variables?.filter) {
      filter.and(params.variables.filter);
    }

    const response = await executeQuery({
      ...params,

      variables: { ...params?.variables, filter: filter.build() },
    });

    return response;
  };

  return [execute, params] as const;
}

function createCurrentUserOrganizationsFilter(context: {
  user?: Maybe<UserKeyFilter>;
  initial?: Maybe<OrganizationFilter>;
}) {
  const filter = createFilterBuilder<OrganizationFilter>(context.initial);

  if (context.user) {
    const filterUser: UserFilter = { id: { equals: context.user?.id } };

    filter.and({
      OR: [
        { owner: filterUser },
        { managers: { some: { user: filterUser, status: { equals: 'active' } } } },
      ],
    });
  }

  return filter;
}
