import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';

import { CustomThemeProvider } from 'providers';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <SnackbarProvider maxSnack={5}>
        <App />
      </SnackbarProvider>
    </CustomThemeProvider>
  </React.StrictMode>,
  document.getElementById('app-root'),
);
