import { useHistory } from 'react-router-dom';

import { ROUTES } from '../constants';
import { UserRequestAccessInput, useUserRequestAccessMutation } from '../graphql';

interface Options {
  /**
   * @deprecated Do it yourself, don't be lazy.
   * @default false
   */
  shouldRedirect?: boolean;
}

/** Send the request to be part of the platform without the approval. */
export function useUserRequestAccess(options?: Options) {
  const { push: navigate } = useHistory();

  const shouldRedirect = options?.shouldRedirect ?? false;

  const [mutate] = useUserRequestAccessMutation({
    onCompleted() {
      if (shouldRedirect) {
        navigate(ROUTES.public.login);
      }
    },
  });

  /** @deprecated Use `sendUserRequest` instead. */
  const onUserRequestAccess = (data: UserRequestAccessInput, _byMember = false) => {
    return sendUserRequest(data);
  };

  const sendUserRequest = async (data: UserRequestAccessInput) => {
    const response = await mutate({
      variables: { data },
    });

    return response;
  };

  return {
    /** @deprecated Use `sendUserRequest` instead. */
    onUserRequestAccess,
    sendUserRequest,
  };
}
