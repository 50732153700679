import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from 'shared/components/ui';

const MAIN_IMAGE_HEIGHT = 350;
const SMALL_IMAGE_HEIGHT = 220;

const mainPicCss = css`
  width: 100%;
  height: ${MAIN_IMAGE_HEIGHT}px;
  margin-bottom: 5px;
`;

const rowPicsCss = css`
  display: flex;
  align-items: center;
`;

const imageCss = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const smallPictureBoxCss = theme => css`
  position: relative;
  width: 33.3333%;
  height: ${SMALL_IMAGE_HEIGHT}px;
  margin-right: ${theme.spacing(0.5)}px;

  &:nth-last-child(1) {
    margin-right: 0px;
  }
`;

const overlayCss = theme => css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const morePicturesCount = theme => css`
  color: #fff;
  font-weight: 600;
`;

type OrganizationPicturesProps = {
  pictures:
    | {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null | undefined;
          downloadUrl?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export const OrganizationPictures = ({ pictures }: OrganizationPicturesProps) => {
  if (!!pictures && pictures.items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Box css={mainPicCss}>
        <Avatar
          css={imageCss}
          key={pictures?.items[0]?.id}
          srcSet={processFilestackUrlSrcSet(pictures?.items[0]?.downloadUrl || '', {
            compress: true,
            resize: {
              height: SMALL_IMAGE_HEIGHT,
              fit: 'crop',
            },
          })}
          src={pictures?.items[0]?.downloadUrl || ''}
          variant="square"
        />
      </Box>
      <Box css={rowPicsCss}>
        {pictures?.items?.slice(1, 4).map((pic, index, array) => {
          return (
            <Box key={pic.id} css={smallPictureBoxCss}>
              {pictures?.items?.length > 5 && index === array.length - 1 && (
                <Box css={overlayCss}>
                  <Typography css={morePicturesCount} variant="h2">
                    +{pictures?.items?.length - array.length - 1}
                  </Typography>
                </Box>
              )}
              <Avatar
                css={imageCss}
                src={pic.downloadUrl || ''}
                srcSet={processFilestackUrlSrcSet(pic.downloadUrl || '', {
                  compress: true,
                  resize: {
                    height: SMALL_IMAGE_HEIGHT,
                    fit: 'crop',
                  },
                })}
                variant="square"
              />
            </Box>
          );
        })}
      </Box>
    </React.Fragment>
  );
};
