import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { Divider } from '@material-ui/core';

import { Form, FormTextField, Button } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';
import { useSchoolConfiguration } from 'shared/hooks';
import { SxProp } from 'shared/types/styles';

import {
  useWordsBlacklistsListQuery,
  useSchoolConfigurationWordsBlacklistUpdate,
} from '../../hooks';

const inputFormCSS = (theme: any) => css`
  width: 100%;
  align-items: center;
  padding: ${theme.spacing(1.25)}px;
`;

const saveFormButton = (theme: any) => css`
  text-align: right;
  margin-top: ${theme.spacing(1.25)}px;
`;

const titleStyles: SxProp = theme => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  fontSize: theme.typography.fontSize + 7,
});

export const ContentCurationBlacklist: React.FC = () => {
  const { wordsBlacklists } = useWordsBlacklistsListQuery();
  const { onSchoolConfigurationUpdate } = useSchoolConfigurationWordsBlacklistUpdate();
  const { configuration } = useSchoolConfiguration();

  const currentBlacklist = wordsBlacklists?.find(({ blacklistId }) => blacklistId);

  const switchIndustryOption = React.useCallback(
    async (formData: Record<string, unknown>) => {
      const blacklist = wordsBlacklists?.find(
        ({ blacklistId }) => blacklistId === formData.blacklist,
      );
      if (blacklist) {
        await onSchoolConfigurationUpdate({
          id: blacklist?.id ?? '',
          schoolConfiguration: { connect: { id: configuration?.id ?? '' } },
        });
      }
    },
    [configuration?.id, onSchoolConfigurationUpdate, wordsBlacklists],
  );
  return (
    <SearchProvider>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        css={css`
          min-width: 0;
        `}
      >
        <Box mx={2} my={2} display="flex" justifyContent="start">
          <Typography sx={titleStyles}>Word and phrases blacklist</Typography>
        </Box>
        <Divider />
        <Box display="flex">
          <Form
            oldCss={inputFormCSS}
            onSubmit={switchIndustryOption}
            initialValues={{ blacklist: currentBlacklist?.blacklistId ?? '' }}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <FormTextField
                  inputProps={{
                    autoComplete: 'off',
                    variant: 'outlined',
                    label: 'BLACKLIST ID',
                    fullWidth: true,
                    inputProps: {
                      defaultValue: currentBlacklist?.blacklistId ?? '',
                    },
                  }}
                  fieldProps={{
                    name: 'blacklist',
                    validate: value =>
                      !wordsBlacklists?.find(({ blacklistId }) => blacklistId === value)
                        ? 'Incorrect blacklist id'
                        : undefined,
                  }}
                />
                <Box css={saveFormButton}>
                  <Button loading={isSubmitting} disableElevation type="submit">
                    SAVE
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Form>
        </Box>
      </Box>
    </SearchProvider>
  );
};
