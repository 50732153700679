import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { EventsPage } from './pages/EventsPage';

export const getEventsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.events.index,
    exact: true,
    component: EventsPage,
  },
];
