import { Fragment, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';

import { Heading } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import { useCurrentUser, useModalState, usePageTitle, useResponsive } from 'shared/hooks';
import { PaperLayout } from 'shared/components/layouts';

import { LEGACY_POSTS_SORT_INFO } from '../constants/sort';
import { LegacyFeedPostModalCreate } from './LegacyFeedPostModal';
import { glueStrings } from '../../../shared/utils/strings';
import { LegacyFilterFields } from './LegacyDetailsSidebar/LegacyFilterFields';
import { APP_URL, SELECT_OPTION_ALL } from '../../../shared/constants';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { css, useTheme } from '@emotion/react';

const postButtonCss = theme => css`
  background-color: ${theme.palette.primary.light};
  font-size: ${theme.typography.fontSize}px;
  font-weight: ${theme.typography.fontWeightMedium};
  letter-spacing: 1px;
  line-height: 17px;
  padding: ${theme.spacing(1.5)}px ${theme.spacing(2.5)}px;

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(1)}px ${theme.spacing(2.5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(0.75)}px ${theme.spacing(2)}px;
  }
`;

const containerCss = {
  backgroundColor: '#fff',
  px: { md: 3.75, xs: 2.5 },
  py: { md: 3.125, xs: 2.5 },
  display: 'flex',
  rowGap: 3.125,
  flexDirection: 'column',
};

export function LegacyHeader() {
  const { isAdmin } = useCurrentUser();
  const { isMobile } = useResponsive();
  const { month: rawMonth, year } = useParams<{ month?: string; year?: string }>();
  const { show: isFilterOpened, open: openFilterModal, close: closeFilterModal } = useModalState();

  const {
    show: isPostModalOpened,
    open: openCreateModal,
    close: closeCreateModal,
  } = useModalState();

  const theme = useTheme();

  const pageTitle = usePageTitle({
    pathname: APP_URL.user.legacy.index,
    fallback: 'Legacy',
  });

  const title = useMemo(() => {
    if (!year || year === SELECT_OPTION_ALL) {
      return pageTitle;
    }

    const month = rawMonth
      ? DateTime.local(Number(year), Number(rawMonth)).setLocale('en-US').monthLong
      : null;

    return glueStrings(' ', pageTitle, '-', month, year);
  }, [rawMonth, year, pageTitle]);

  return (
    <Fragment>
      <Dialog maxWidth="sm" fullWidth open={isFilterOpened} onClose={closeFilterModal}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="1rem"
          pr={3}
        >
          <DialogTitle>Filters</DialogTitle>

          <IconButton size="small" onClick={closeFilterModal}>
            <Close />
          </IconButton>
        </Box>

        <DialogContent dividers>
          <LegacyFilterFields />
        </DialogContent>
      </Dialog>

      <LegacyFeedPostModalCreate isOpen={isPostModalOpened} onClose={closeCreateModal} />

      <PaperLayout sx={containerCss}>
        <Box display="flex" alignItems="center" justifyContent="space-between" maxHeight={40}>
          <Heading>{title}</Heading>

          {isAdmin && (
            <Button
              disableElevation
              color="primary"
              variant="contained"
              css={postButtonCss}
              onClick={openCreateModal}
            >
              Post
            </Button>
          )}
        </Box>

        <SearchControl
          withTextField
          withSortField
          sortFieldProps={LEGACY_POSTS_SORT_INFO}
          textFieldProps={{ placeholder: 'Search Posts' }}
          elementsWidth={isMobile ? ['1fr'] : ['1fr', '0.452fr']}
          elementsGap={theme.spacing(2.5)}
          withFilterModal={isMobile}
          filterModalProps={{ onClick: openFilterModal }}
        />
      </PaperLayout>
    </Fragment>
  );
}
