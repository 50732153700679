import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, Divider } from '@material-ui/core';

import { GroupAdminStatus } from '@jebel/constants';

import { Typography, AsyncContent } from 'shared/components/ui';
import { GroupsListItemFragment } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { useGroupSearch } from 'features/settings/hooks';

import { GroupsList } from './GroupsList';

const groupCountCSS = (theme: any) => css`
  font-weight: 500;
  color: ${theme.palette.info.main};
`;

interface Props {
  onShowDetails?(group: GroupsListItemFragment): void;
}

export function GroupsContent(props: Props) {
  const { userId } = useCurrentUser();
  const { data: groups, loading: groupsLoading } = useGroupSearch();

  const managedGroups = useMemo(() => {
    return groups.filter(group => {
      const admins = group?.groupAdminPreferences?.items ?? [];

      const isOwner = group?.createdBy?.id === userId;

      const isAdmin = admins.some(
        admin => admin.user?.id === userId && admin.status === GroupAdminStatus.Active,
      );

      return isAdmin || isOwner;
    });
  }, [groups, userId]);

  return (
    <AsyncContent loading={groupsLoading} stretch>
      <Box px={3.75} py={3.125}>
        <Box display="grid" minHeight={230} gridTemplateRows="min-content auto">
          <Box display="grid" gridTemplateRows="min-content" pb={1} px={0.5}>
            <Typography css={groupCountCSS} color="primary" variant="body1">
              Groups you manage ({managedGroups?.length})
            </Typography>
          </Box>
          <GroupsList onShowDetails={props.onShowDetails} groups={managedGroups} isCreator />
        </Box>
      </Box>

      <Divider />

      <Box px={3.75} py={3.125}>
        <Box display="grid" gridTemplateRows="min-content auto">
          <Box display="grid" gridTemplateRows="min-content" pb={1} px={0.5}>
            <Typography css={groupCountCSS} color="primary" variant="body1">
              Groups you belong to ({groups.length})
            </Typography>
          </Box>

          <GroupsList onShowDetails={props.onShowDetails} groups={groups} isCreator={false} />
        </Box>
      </Box>
    </AsyncContent>
  );
}
