import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Theme, css } from '@emotion/react';

import { humanizeText } from '@jebel/utils';

import type { DashboardsMembersListQuery } from 'shared/graphql';
import { AsyncContent } from 'shared/components/ui';
import { BarChart, BarChartData, LinearChart, LinearTimeChartData } from 'shared/components/charts';
import { formatSQLDate } from 'shared/utils/date';

import { DashboardChartTemplateTop } from './DashboardChartTemplateTop';
import { DateFilterType } from '../types';
import { DashboardFilters } from './DashboardFilters';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const sectionContainerCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const paperContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  flex: 1;
`;

interface DashboardMembersChartsProps {
  dateFilter: DateFilterType;
  data?: DashboardsMembersListQuery['dashboardsList']['items'];
  totalMembersByAffiliationCount: { affiliation: string; count: number }[];
  loading: boolean;
  setDateFilter: (key: keyof DateFilterType) => (value: DateTime) => void;
}

export const DashboardMembersCharts: React.FC<DashboardMembersChartsProps> = ({
  dateFilter,
  data = [],
  totalMembersByAffiliationCount,
  loading,
  setDateFilter,
}) => {
  const membersChartData = React.useMemo(() => {
    return data.map<LinearTimeChartData>(item => {
      const count = item.totalMembers ?? 0;
      const timestamp = formatSQLDate(item.createdAt);

      return {
        timestamp,
        value: count,
        legend: `Members at ${timestamp}: <b>${count}</b>`,
      };
    });
  }, [data]);

  const totalMembersByAffiliationChartData = React.useMemo(() => {
    return totalMembersByAffiliationCount.map<BarChartData>(item => {
      return {
        category: humanizeText(item.affiliation),
        value: item.count,
      };
    });
  }, [totalMembersByAffiliationCount]);

  return (
    <Box css={containerCSS}>
      <Box display="flex" justifyContent="start" gridGap="1rem" alignItems="baseline">
        <Typography variant="h5">Members</Typography>

        <Box display="flex" gridGap="1rem">
          <DashboardFilters
            dateFilter={dateFilter}
            setStartDateFilter={setDateFilter('startDate')}
            setEndDateFilter={setDateFilter('endDate')}
            loading={loading}
          />
        </Box>
      </Box>

      <Box css={sectionContainerCSS}>
        <Paper css={paperContentCSS}>
          <AsyncContent loading={loading} height={300}>
            <DashboardChartTemplateTop title="Cumulative Members" date={dateFilter.endDate} />
            <LinearChart type="time" data={membersChartData} />
          </AsyncContent>
        </Paper>

        <Paper css={paperContentCSS}>
          <AsyncContent loading={loading} height={300}>
            <DashboardChartTemplateTop title="Members by Type" date={dateFilter.endDate} />
            <BarChart data={totalMembersByAffiliationChartData} />
          </AsyncContent>
        </Paper>
      </Box>
    </Box>
  );
};
