import React from 'react';
import { Box } from '@mui/material';

export const YoutubePreview = ({ embedId }) => (
  <Box>
    <iframe
      width="100%"
      height="300"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Box>
);
