import { ReactNode } from 'react';

import { useResponsive } from 'shared/hooks';

import {
  ContainerDesktop,
  ContainerMobile,
  Content,
  ContentProps,
  Sidebar,
  Wrapper,
} from './RouteLayout.styles';

interface Props extends ContentProps {
  content: ReactNode;
  sidebar?: ReactNode;
}

export function RouteLayout({ content, sidebar, ...contentProps }: Props) {
  const { isMobile } = useResponsive();

  const showSidebar = Boolean(sidebar) && !isMobile;

  if (isMobile) {
    return (
      <ContainerMobile>
        <Content>{content}</Content>
      </ContainerMobile>
    );
  }

  return (
    <ContainerDesktop hasSidebar={Boolean(sidebar)}>
      <Wrapper>
        <Content {...contentProps}>{content}</Content>
      </Wrapper>

      {showSidebar && <Sidebar>{sidebar}</Sidebar>}
    </ContainerDesktop>
  );
}
