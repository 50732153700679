import styled from '@emotion/styled';

export const HomeFeedCardLayout = styled.div`
  --home-feed-card-padding: 1.5rem;
  --home-feed-card-gap: 1rem;

  display: flex;
  flex-direction: column;
  padding: var(--home-feed-card-padding);
  gap: var(--home-feed-card-gap);
  background-color: ${p => p.theme.palette.background.paper};
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
`;
