import React from 'react';
import { Box, MenuItem, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  Preloader,
  Form,
  FormTextField,
  FormSelect,
  Button,
  createSnackMessage,
  SNACK_TYPES,
} from 'shared/components/ui';
import { useUserById, useUserRolesAddons, useUserRoles } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

const selectBoxCss: SxProps = {
  '& > *': {
    width: '100%',
  },
};

interface MemberEditRolesFormValues {
  userName: string;
  email: string;
  rolesAddonsIds: string[];
}

interface MemberEditRolesModalProps {
  memberId: string | undefined;
  onModalClose: () => void;
}

export const MemberEditRolesModal: React.FC<MemberEditRolesModalProps> = ({
  memberId,
  onModalClose,
}) => {
  const { user, updateUserCache, loading: isUserLoading } = useUserById({ id: memberId });
  const { rolesAddons, isLoading: isRolesAddonsLoading } = useUserRolesAddons();
  const { updateUserRoles, isUpdating: isRolesUpdating } = useUserRoles();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: MemberEditRolesFormValues = {
    userName: `${user?.firstName || ''} ${user?.lastName || ''}`,
    email: user?.email || 'Unknown',
    rolesAddonsIds: (user?.rolesAddons?.items || []).map(roleAddon => roleAddon.id || ''),
  };

  const onSubmit = React.useCallback(
    async (formValues: MemberEditRolesFormValues) => {
      try {
        await updateUserRoles(memberId || '', formValues.rolesAddonsIds);

        const newRolesAddons = rolesAddons.filter(roleAddon =>
          formValues.rolesAddonsIds.includes(roleAddon.id || ''),
        );

        updateUserCache({
          rolesAddons: {
            items: newRolesAddons,
          },
        });
        enqueueSnackbar('Roles updates successfully', {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.success),
        });
        onModalClose();
      } catch (e: any) {
        sendToSentry(`Error while updating roles! ${e}`);
        enqueueSnackbar('Error while updating roles!', {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.error),
        });
      }
    },
    [enqueueSnackbar, memberId, onModalClose, rolesAddons, updateUserCache, updateUserRoles],
  );

  const isLoading = isUserLoading || isRolesAddonsLoading;

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Box pb={1}>
      <Form key={user?.id} initialValues={initialValues as any} onSubmit={onSubmit as any}>
        {({ dirty, isSubmitting, isValid, isValidating, handleSubmit, handleReset }) => {
          const isInputsDisabled = isLoading || isRolesUpdating || isSubmitting;
          const isActionsDisabled = isInputsDisabled || !isValid || isValidating || !dirty;

          return (
            <React.Fragment>
              <Box>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Name',
                    variant: 'outlined',
                    style: { width: '100%' },
                    disabled: true,
                  }}
                  fieldProps={{ name: 'userName' }}
                />
              </Box>

              <Box mt={2}>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Email address',
                    variant: 'outlined',
                    style: { width: '100%' },
                    disabled: true,
                  }}
                  fieldProps={{ name: 'email' }}
                />
              </Box>

              <Box mt={2} sx={selectBoxCss}>
                <FormSelect
                  selectProps={{
                    label: 'Roles',
                    variant: 'outlined',
                    disabled: isInputsDisabled,
                    multiple: true,
                    children: rolesAddons.map(role => (
                      <MenuItem key={role.id} value={role.id || undefined}>
                        {role.name || role.role?.name || 'Unknown role'}
                      </MenuItem>
                    )),
                  }}
                  fieldProps={{ name: 'rolesAddonsIds' }}
                />
              </Box>

              <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  loading={isRolesUpdating}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleReset();
                    onModalClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isActionsDisabled}
                  loading={isRolesUpdating}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Save changes
                </Button>
              </Box>
            </React.Fragment>
          );
        }}
      </Form>
    </Box>
  );
};
