import { Theme } from '@emotion/react';
import {
  EChartsOption as ChartOptions,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import { alpha } from '@mui/material';

import { formatTableDate } from 'shared/utils/date';

import { LinearChartProps, LinearTimeChartProps, LinearValueChartProps } from './LinearChart.types';

/** Percetage where to start the zoom. */
const DATA_ZOOM_START = 30;
/** Percetage where to end the zoom. */
const DATA_ZOOM_END = 70;

/**
 * Create the options based on the given `props` and `theme` for a linear chart.
 * @param props Properties given to the component.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Instance of `ChartOptions` useful with `echarts`.
 */
export function createLinearChartOptions(props: LinearChartProps, theme: Theme): ChartOptions {
  if (props.type === 'time') {
    return createLinearTimeChartOptions(props, theme);
  }

  return createLinearValueChartOptions(props, theme);
}

/**
 * Create some variables to customize the chart.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Useful variables to customize the chart.
 */
function createChartTheme(theme: Theme) {
  const color = theme.palette.secondary.main;

  const borderColor = alpha(theme.palette.secondary.main, 0.3);
  const areaColor = alpha(theme.palette.secondary.main, 0.2);

  const fontFamily = theme.typography.fontFamily;
  const fontSize = theme.typography.fontSize;

  const textStyle: ChartOptions['textStyle'] = {
    fontFamily: theme.typography.fontFamily,
    fontSize,
  };

  const lineStyle: LineSeriesOption['lineStyle'] = {
    color,
  };

  const areaStyle: LineSeriesOption['areaStyle'] = {
    color: areaColor,
  };

  const itemStyle: LineSeriesOption['itemStyle'] = {
    color,
  };

  return {
    color,
    borderColor,
    areaColor,
    fontSize,
    fontFamily,
    textStyle,
    lineStyle,
    areaStyle,
    itemStyle,
  };
}

/**
 * Create the options based on the given `props` and `theme` for a time-based linear chart.
 * @param props Properties given to the component.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Instance of `ChartOptions` useful with `echarts`.
 */
function createLinearTimeChartOptions(props: LinearTimeChartProps, theme: Theme): ChartOptions {
  const { color, borderColor, areaColor, fontSize, textStyle, lineStyle, areaStyle, itemStyle } =
    createChartTheme(theme);

  const options: ChartOptions = {
    textStyle,

    tooltip: {
      trigger: 'axis',

      formatter(params: TooltipComponentFormatterCallbackParams) {
        const options = Array.isArray(params) ? params[0] : params;
        const data = props.data[options.dataIndex];

        if (!data) {
          return '(None)';
        }

        const timestamp = formatTableDate(data.timestamp);
        const value = data.legend ?? props.formatValue?.(data.value) ?? String(data.value);

        return data.legend ?? `<b>${timestamp}</b><br/>${value}`;
      },
    },

    dataset: {
      dimensions: ['timestamp', 'value'],
      source: props.data,
    },

    xAxis: {
      type: 'time',
      axisLabel: {
        fontSize,
        formatter: props.formatEpoach,
      },
    },

    yAxis: {
      axisLabel: {
        fontSize,
        formatter: props.formatValue,
      },
    },

    series: {
      type: 'line',
      dimensions: ['timestamp', 'value'],
      areaStyle,
      lineStyle,
      itemStyle,
    },

    dataZoom: {
      type: 'slider',
      start: DATA_ZOOM_START,
      end: DATA_ZOOM_END,
      borderColor,
      fillerColor: areaColor,

      labelFormatter(value) {
        if (!value) {
          return '(None)';
        }

        return formatTableDate(value);
      },

      dataBackground: {
        lineStyle,
        areaStyle,
      },

      selectedDataBackground: {
        lineStyle,
        areaStyle,
      },

      brushStyle: {
        borderColor,
        color: areaColor,
      },

      handleStyle: {
        borderColor,
        color,
      },

      moveHandleStyle: {
        color,
      },

      emphasis: {
        moveHandleStyle: {
          color,
        },
      },
    },
  };

  return options;
}

/**
 * Create the options based on the given `props` and `theme` for a value-based linear chart.
 * @param props Properties given to the component.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Instance of `ChartOptions` useful with `echarts`.
 */
function createLinearValueChartOptions(props: LinearValueChartProps, theme: Theme): ChartOptions {
  const { fontSize, textStyle, lineStyle, areaStyle, itemStyle } = createChartTheme(theme);

  const options: ChartOptions = {
    textStyle,

    tooltip: {
      trigger: 'axis',

      formatter(params: TooltipComponentFormatterCallbackParams) {
        const options = Array.isArray(params) ? params[0] : params;
        const data = props.data[options.dataIndex];

        if (!data) {
          return '(None)';
        }

        const label = props.formatLabel?.(data.label) ?? data.label;
        const value = data.legend ?? props.formatValue?.(data.value) ?? String(data.value);

        return data.legend ?? `<b>${label}</b><br/>${value}`;
      },
    },

    dataset: {
      dimensions: ['label', 'value'],
      source: props.data,
    },

    xAxis: {
      type: 'category',
      axisLabel: {
        fontSize,
        formatter: props.formatLabel,
      },
    },

    yAxis: {
      axisLabel: {
        fontSize,
        formatter: props.formatValue,
      },
    },

    series: {
      type: 'line',
      dimensions: ['label', 'value'],
      areaStyle,
      lineStyle,
      itemStyle,
    },
  };

  return options;
}
