import { Fragment, useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { DateTime } from 'luxon';
import { Box, Tooltip } from '@material-ui/core';

import { formatISO8601Date } from '@jebel/utils';

import {
  Button,
  DateTimePickerField,
  Form,
  FormTextField,
  RouteLayout,
  Typography,
} from 'shared/components/ui';
import { useSchoolConfiguration } from 'shared/hooks';
import {
  useSchoolConfigurationTopBarCreate,
  useSchoolConfigurationTopBarDelete,
  useSchoolConfigurationTopBarUpdate,
} from 'shared/hooks/useSchoolConfigurationTopBar';
import { composeValidators, isValidLink, maxTextSymbols, required } from 'shared/utils/form';
import { Formik } from 'formik';

const containerCss = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: column;
  height: 100%;
`;

const formCss = css`
  width: 100%;
`;

const formFieldCss = theme => css`
  margin-bottom: ${theme.spacing(2)}px;
  width: 100%;
  margin-right: ${theme.spacing(2)}px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const titleCss = (theme: any) => css`
  padding-left: ${theme.spacing(6)}px;
  padding-top: ${theme.spacing(2)}px;
  padding-bottom: ${theme.spacing(2)}px;
  border-bottom: 1px solid ${theme.palette.border};
`;

const contentCss = theme => css`
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: ${theme.spacing(4)}px ${theme.spacing(6)}px;
`;

type FormValues = {
  title: string;
  text: string;
  label: string;
  link: string;
  startDate: DateTime | string | undefined;
  endDate: DateTime | string | undefined;
};

const MAX_TITLE_LENGTH = 100;

const minDate = formatISO8601Date();

export const TopBarSettings = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { configuration } = useSchoolConfiguration();
  const { schoolConfigurationTopBarCreate } = useSchoolConfigurationTopBarCreate();
  const { schoolConfigurationTopBarUpdate } = useSchoolConfigurationTopBarUpdate();
  const { schoolConfigurationTopBarDelete } = useSchoolConfigurationTopBarDelete();

  const initials = useMemo<FormValues>(() => {
    return {
      title: configuration?.topBar?.title ?? '',
      text: configuration?.topBar?.text ?? '',
      label: configuration?.topBar?.label ?? '',
      link: configuration?.topBar?.link ?? '',
      startDate: formatISO8601Date(configuration?.topBar?.startDate) ?? minDate,
      endDate: formatISO8601Date(configuration?.topBar?.endDate) ?? minDate,
    };
  }, [configuration?.topBar]);

  const onSave = async (data: FormValues) => {
    setIsSaving(true);

    const { title, text, label, link, startDate, endDate } = data;

    const topBar = {
      title,
      text,
      label,
      link,
      startDate: formatISO8601Date(startDate) ?? minDate,
      endDate: formatISO8601Date(endDate) ?? minDate,
    };

    if (configuration?.topBar?.id) {
      await schoolConfigurationTopBarUpdate({
        id: configuration.topBar.id,
        ...topBar,
      });
    } else if (configuration?.id) {
      await schoolConfigurationTopBarCreate({
        configuration: { connect: { id: configuration.id } },
        ...topBar,
      });
    }

    setIsSaving(false);
  };

  const onDelete = useCallback(async () => {
    setIsDeleting(true);

    if (configuration?.topBar?.id) {
      await schoolConfigurationTopBarDelete({
        id: configuration.topBar.id,
      });
    }

    setIsDeleting(false);
  }, [configuration?.topBar, schoolConfigurationTopBarDelete]);

  return (
    <RouteLayout css={containerCss}>
      <Typography css={titleCss} variant="subtitle4" align="inherit" paragraph={false}>
        Top Bar News Ticker
      </Typography>

      <Box css={contentCss}>
        <Formik onSubmit={onSave} initialValues={initials} enableReinitialize>
          {form => (
            <Fragment>
              <Box display="grid" gridTemplateRows="auto" gridGap={2} my={2}>
                <FormTextField
                  inputProps={{
                    label: 'NEWS TITLE',
                    variant: 'outlined',
                    css: formFieldCss,
                    required: true,
                  }}
                  fieldProps={{
                    name: 'title',
                    validate: composeValidators(required, value =>
                      maxTextSymbols(value, MAX_TITLE_LENGTH),
                    ),
                  }}
                />
                <FormTextField
                  inputProps={{
                    label: 'NEWS TEXT',
                    variant: 'outlined',
                    css: formFieldCss,
                    required: true,
                  }}
                  fieldProps={{
                    name: 'text',
                    validate: composeValidators(required, value =>
                      maxTextSymbols(value, MAX_TITLE_LENGTH * 2.5),
                    ),
                  }}
                />
                <FormTextField
                  inputProps={{
                    label: 'CTA LABEL',
                    variant: 'outlined',
                    css: formFieldCss,
                  }}
                  fieldProps={{
                    name: 'label',
                    validate: value => maxTextSymbols(value, MAX_TITLE_LENGTH),
                  }}
                />
                <FormTextField
                  inputProps={{
                    label: 'CTA URL',
                    variant: 'outlined',
                    css: formFieldCss,
                  }}
                  fieldProps={{
                    name: 'link',
                    validate: composeValidators(Boolean(form?.values?.link) && isValidLink, value =>
                      maxTextSymbols(value, MAX_TITLE_LENGTH * 2.5),
                    ),
                  }}
                />

                <Box>Duration</Box>

                <Box mt={2} display="grid">
                  <DateTimePickerField
                    name="startDate"
                    allowKeyboardControl
                    label="Start Date"
                    inputVariant="outlined"
                    disablePast
                    minDate={minDate}
                  />
                </Box>

                <Box mt={2} display="grid">
                  <DateTimePickerField
                    name="endDate"
                    allowKeyboardControl
                    label="End Date"
                    inputVariant="outlined"
                    disablePast
                    minDate={form.values.startDate}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="end" gridGap="1rem">
                <Button
                  color="primary"
                  variant="contained"
                  loading={isSaving}
                  disabled={isSaving || isDeleting || !form.dirty}
                  onClick={form.submitForm}
                  disableElevation
                >
                  SAVE CHANGES
                </Button>

                {configuration?.topBar?.id && (
                  <Tooltip title="Remove the current top bar news ticker and clean the settings.">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={onDelete}
                      loading={isDeleting}
                      disabled={isSaving || isDeleting}
                      disableElevation
                    >
                      DELETE
                    </Button>
                  </Tooltip>
                )}

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={form.handleReset}
                  disabled={isSaving || isDeleting || !form.dirty}
                  type="reset"
                >
                  CANCEL
                </Button>
              </Box>
            </Fragment>
          )}
        </Formik>
      </Box>
    </RouteLayout>
  );
};
