import { Theme, css } from '@emotion/react';
import { Box, Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';

import { HomeFeedItemType } from '@jebel/constants';

import { FormTextField, Form, Button, Typography } from 'shared/components/ui';
import { usePostFlag } from 'shared/features/posts/hooks/usePostFlag';
import { useResponsive } from 'shared/hooks';

type HomeFeedPostModalFlagProps = {
  isOpen: boolean;
  onModalClose: () => void;
  postId: string;
  postType: string;
};

export function HomeFeedPostModalFlag({
  isOpen,
  onModalClose,
  postId,
  postType,
}: HomeFeedPostModalFlagProps) {
  const { onPostFlag } = usePostFlag({ postType: postType as HomeFeedItemType });
  const { isMobile } = useResponsive();

  const onSubmit = async ({ flagReason }: { flagReason?: string | undefined | null }) => {
    await onPostFlag({ postId, flagReason });
    onModalClose();
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose} fullWidth={true} maxWidth={isMobile ? 'xs' : 'sm'}>
      <DialogTitle
        style={{
          fontSize: '18px',
          fontWeight: 500,
          letterSpacing: '0.06px',
          lineHeight: '25px',
        }}
      >
        Flag Post as Inappropriate
      </DialogTitle>

      <Divider orientation="horizontal" variant="fullWidth" />

      <DialogContent>
        <Form onSubmit={onSubmit} validateOnBlur={false}>
          {({ isSubmitting }) => (
            <Box display="flex" flexDirection="column" my={3}>
              <Box display="grid" gridTemplateColumns="auto">
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'COMMENTS',
                    multiline: true,
                    rows: '7',
                    rowsMax: '7',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'flagReason' }}
                />
              </Box>

              <Box display="flex" my={2}>
                <Typography css={warningTextCSS} variant="body2">
                  By flagging this post, it will be temporarily removed from the platform and
                  reviewed by an Administrator.
                </Typography>
              </Box>

              <Box display="flex" justifyContent="flex-end" gridGap="0.5rem">
                <Button
                  onClick={onModalClose}
                  color="primary"
                  disabled={isSubmitting}
                  loading={false}
                  size="medium"
                >
                  Cancel
                </Button>

                <Button
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  disableElevation
                  variant="contained"
                  type="submit"
                >
                  Flag post
                </Button>
              </Box>
            </Box>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
}

const warningTextCSS = (theme: Theme) =>
  css`
    color: ${theme.palette.error.main};
  `;
