import { MouseEvent, SyntheticEvent, useMemo } from 'react';
import pluralize from 'pluralize';
import { generatePath, useHistory } from 'react-router-dom';

import { GroupUserRequestStatus } from '@jebel/constants';
import { Button, Icon } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import {
  GroupsListItemFragment,
  Maybe,
  useGroupMemberRequestUpsertMutation,
  useGroupMemberRequestsQuery,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { ROUTES, SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

import {
  Buttons,
  Container,
  Content,
  Count,
  Description,
  Divided,
  GroupListItemContainer,
  Header,
  HeaderDescriptor,
  HeaderOptions,
  Joined,
  Logo,
  Title,
} from './GroupListItem.styles';
import { GroupListItemOptions } from '../GroupListItemOptions';

interface Props {
  data?: Maybe<GroupsListItemFragment>;

  /** @default true */
  showJoined?: boolean;
  /** @default false */
  showSettings?: boolean;
  /** @default false */
  isCreator?: boolean;

  onShowDetails?(): void;
}

export function GroupListItem(props: Props) {
  const { userId } = useCurrentUser();
  const { displayList } = useSearchContext();
  const { push: navigate } = useHistory();

  const { data: groupRequests } = useGroupMemberRequestsQuery({
    variables: {
      userId: userId as string,
      groupId: props.data?.id as string,
    },
  });

  const [updateGroup] = useGroupMemberRequestUpsertMutation({
    refetchQueries: ['GroupsList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! You successfully requested to join the group.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request was not sent.`,
    },
  });

  const members = useMemo(() => props.data?.members?.items ?? [], [props.data]);

  const membersCount = useMemo(() => {
    const count = members.length;
    const word = pluralize('member', count);

    return `${count} ${word}`;
  }, [members]);

  const title = props.data?.title;
  const description = props.data?.description;
  const logoUrl = props.data?.logo?.downloadUrl ?? undefined;

  const isRequestPending = Boolean(groupRequests?.requests?.count);

  const isMember = useMemo(() => {
    const hasMembership = members.some(member => member?.id === userId);
    return hasMembership;
  }, [members, userId]);

  const showJoinButton = !isMember && !isRequestPending;
  const showJoined = isMember && (props.showJoined ?? true);
  const showSettings = props.showSettings ?? false;
  const isManagedByAdmin = props.isCreator ?? false;

  const handleJoin = async (event: SyntheticEvent) => {
    // Prevent to propagate the click and be redirected to the group feed
    // https://8base-dev.atlassian.net/browse/JEB-1497
    event.stopPropagation();

    await updateGroup({
      variables: {
        userId: userId as string,
        groupId: props.data?.id as string,
        status: GroupUserRequestStatus.Pending,
      },
    });
  };

  const openFeed = () => {
    if (!props.data?.id) {
      return;
    }

    const url = generatePath(ROUTES.user.groups.details, { id: props.data.id });
    navigate(url);
  };

  const handleClick = (event: MouseEvent) => {
    openFeed();
  };

  const handleOpenDetails = () => {
    props.onShowDetails?.();
  };

  if (!displayList) {
    return (
      <Container onClick={handleClick}>
        <Divided>
          <Logo src={logoUrl} />

          <Content>
            <Title>{title}</Title>

            <HeaderDescriptor>
              <Count>{membersCount}</Count>

              {showJoined && <Joined />}

              {showSettings && (
                <GroupListItemOptions
                  onViewDetails={handleOpenDetails}
                  groupId={props.data?.id ?? ''}
                  isManagedByAdmin={isManagedByAdmin}
                />
              )}
            </HeaderDescriptor>
          </Content>
        </Divided>

        <Description>{description}</Description>
      </Container>
    );
  }

  return (
    <Container onClick={handleClick}>
      <Divided>
        <Logo src={logoUrl} />

        <Content>
          <Header>
            <HeaderDescriptor>
              <HeaderOptions>
                <Title>{title}</Title>
                <Count>{membersCount}</Count>

                {showJoined && <Joined />}
              </HeaderOptions>

              {showSettings && (
                <GroupListItemContainer>
                  <GroupListItemOptions
                    onViewDetails={handleOpenDetails}
                    groupId={props.data?.id ?? ''}
                    isManagedByAdmin={isManagedByAdmin}
                  />
                </GroupListItemContainer>
              )}
            </HeaderDescriptor>

            <Buttons>
              {showJoinButton && (
                <Button onClick={handleJoin} startIcon={<Icon name="GroupAdd" />}>
                  Join
                </Button>
              )}
            </Buttons>
          </Header>

          <Description>{description}</Description>
        </Content>
      </Divided>
    </Container>
  );
}
