import { Fragment, useMemo } from 'react';
import { css, Theme } from '@emotion/react';
import { MenuItem, Box, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { FormSelect, FormTextField, Button, Form, FormSwitch } from 'shared/components/ui';
import { APP_URL, FIELDS_CONFIG } from 'shared/constants';
import { useCurrentUser, useResponsive, useUserUpdate } from 'shared/hooks';
import { buildUrl } from 'shared/routes';
import { isValidLink, maxTextSymbols, required } from 'shared/utils/form';
import { SettingsContentLayout } from 'shared/components/layouts';
import { Maybe } from 'shared/graphql';

import {
  SettingsPublicProfileSectionsExperience,
  SettingsPublicProfileSectionsEducation,
  SettingsPublicProfileSectionsInterests,
} from '../components/SettingsPublicProfileSections';
import { useUserPublicProfile } from '../hooks';

const formCSS = (theme: Theme) => css`
  display: grid;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`;

const topFormLayoutCSS = (theme: Theme) => css`
  display: grid;
  grid-gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }

  ${theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const viewPublicProfileContainerCSS = (theme: Theme) => css`
  display: flex;

  ${theme.breakpoints.up('sm')} {
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    gap: 1rem;
    margin-top: 0.5rem;
  }
`;

const buttonsContainerCSS = (theme: Theme) => css`
  display: flex;
  gap: 0.5rem;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

interface Values {
  currentIndustry?: Maybe<string>;
  professionalDesignations?: Maybe<string>;
  description?: Maybe<string>;
  isSeekingEmployment?: Maybe<boolean>;
  facebookLink?: Maybe<string>;
  instagramLink?: Maybe<string>;
  linkedInLink?: Maybe<string>;
  twitterLink?: Maybe<string>;
}

export function PublicProfileSettingPage() {
  const { push: navigate } = useHistory();
  const { userId: id, loading } = useCurrentUser();
  const { onUserUpdate } = useUserUpdate({ id });

  const {
    user: profile,
    professionalEducation,
    professionalExperience,
    publicInfoLoading,
  } = useUserPublicProfile({ id });

  const onSubmit = async (values: Values) => {
    await onUserUpdate({
      currentIndustry: values.currentIndustry,
      professionalDesignations: values.professionalDesignations,
      description: values.description,
      isSeekingEmployment: Boolean(values.isSeekingEmployment),
      facebookLink: values.facebookLink,
      instagramLink: values.instagramLink,
      linkedInLink: values.linkedInLink,
      twitterLink: values.twitterLink,
    });
  };

  const initialValues = useMemo<Values>(() => {
    return {
      currentIndustry: profile?.currentIndustry,
      professionalDesignations: profile?.professionalDesignations,
      description: profile?.description,
      isSeekingEmployment: Boolean(profile?.isSeekingEmployment),
      facebookLink: profile?.facebookLink,
      instagramLink: profile?.instagramLink,
      linkedInLink: profile?.linkedInLink,
      twitterLink: profile?.twitterLink,
    };
  }, [profile]);

  return (
    <SettingsContentLayout title="Public Profile" isLoading={loading || publicInfoLoading}>
      <Form oldCss={formCSS} initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
        {form => (
          <Fragment>
            <Box css={topFormLayoutCSS}>
              <FormSelect
                selectProps={{
                  children: FIELDS_CONFIG.industry.slice(1).map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'Current Industry',
                }}
                fieldProps={{ name: 'currentIndustry', validate: required }}
              />

              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Professional Designations',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'professionalDesignations' }}
              />
            </Box>

            <Box display="grid" gridTemplateColumns="auto">
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'About Me',
                  multiline: true,
                  rows: '7',
                  rowsMax: '7',
                  variant: 'outlined',
                }}
                fieldProps={{
                  name: 'description',
                  validate: value => maxTextSymbols(value, 3000),
                }}
              />
            </Box>

            <Box css={topFormLayoutCSS}>
              <FormTextField
                inputProps={{
                  label: 'Facebook',
                  variant: 'outlined',
                  placeholder: 'https://www.facebook.com/username',
                }}
                fieldProps={{ name: 'facebookLink', validate: isValidLink }}
              />

              <FormTextField
                inputProps={{
                  label: 'Instagram',
                  variant: 'outlined',
                  placeholder: 'https://www.instagram.com/username',
                }}
                fieldProps={{ name: 'instagramLink', validate: isValidLink }}
              />

              <FormTextField
                inputProps={{
                  label: 'LinkedIn',
                  variant: 'outlined',
                  placeholder: 'https://www.linkedin.com/in/username',
                }}
                fieldProps={{ name: 'linkedInLink', validate: isValidLink }}
              />

              <FormTextField
                inputProps={{
                  label: 'X (formerly Twitter)',
                  variant: 'outlined',
                  placeholder: 'https://www.x.com/username',
                }}
                fieldProps={{ name: 'twitterLink', validate: isValidLink }}
              />
            </Box>

            <Box>
              <FormSwitch
                fieldProps={{
                  name: 'isSeekingEmployment',
                }}
                switchProps={{
                  name: 'isSeekingEmployment',
                  disableRipple: false,
                  color: 'primary',
                  size: 'small',
                  label: 'Seeking employment',
                }}
              />
            </Box>

            <Box css={viewPublicProfileContainerCSS}>
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => {
                  navigate(
                    buildUrl(APP_URL.user.explorer.profile, {
                      pathParams: { id: id as string },
                    }),
                  );
                }}
              >
                View Public Profile
              </Button>

              <Box css={buttonsContainerCSS}>
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={form.isSubmitting || !form.dirty}
                  disableElevation
                  onClick={() => form.resetForm()}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  loading={form.isSubmitting}
                  onClick={form.submitForm}
                  disabled={form.isSubmitting || !form.dirty}
                  disableElevation
                >
                  Save changes
                </Button>
              </Box>
            </Box>

            <Divider />

            <SettingsPublicProfileSectionsExperience
              data={professionalExperience}
              withEdit
              withRemove
              withAdd
            />

            <SettingsPublicProfileSectionsEducation
              educationProfile={professionalEducation}
              withEdit
              withRemove
              withAdd
            />

            <SettingsPublicProfileSectionsInterests />
          </Fragment>
        )}
      </Form>
    </SettingsContentLayout>
  );
}
