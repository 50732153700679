import { useUserByIdQuery } from 'shared/graphql';

import { useHomeFeedCardData, useHomeFeedCardHaveBeenShown } from '../../providers';

/** Extract the author from the stringified JSON. */
export function useHomeFeedAuthor() {
  const post = useHomeFeedCardData();
  const haveBeenShown = useHomeFeedCardHaveBeenShown();

  const { data: response, loading } = useUserByIdQuery({
    skip: !post.authorId || !haveBeenShown,
    fetchPolicy: 'cache-first',

    variables: {
      id: post.authorId as string,
    },
  });

  const hasAuthor = Boolean(post.authorId);

  return {
    data: response?.user,
    has: hasAuthor,
    loading: loading || !haveBeenShown,
  };
}
