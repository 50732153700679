import { AppProvider } from '@8base-react/app-provider';
import { Auth, AUTH_STRATEGIES, ISubscribableAuthClient } from '@8base/auth';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { ApolloError } from '@apollo/client';

import {
  AuthProvider,
  ConfirmDialogsProvider,
  CustomDateTimeProvider,
  GlobalStateProvider,
  LoggerProvider,
  OneSignalProvider,
  ReactQueryProvider,
  StreamChatProvider,
  useAppAuth,
} from 'providers';
import { RootContent } from 'RootContent';
import { SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { HelpModal } from 'shared/components/dialogs';
import { InboxProvider } from 'providers/InboxProvider/InboxProvider';
import { GRAPHQL_ISSUE_CODE, GraphQLIssue } from 'shared/graphql/errors';
import { useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { NotificationsProvider } from './shared/features/notifications';

const { REACT_APP_ENDPOINT = '' } = process.env;

export default function App() {
  const { showSuccess } = useToast();
  const { logout, login, checkSession } = useAppAuth();

  const authClient = Auth.createClient(
    {
      strategy: AUTH_STRATEGIES.WEB_OAUTH,
      subscribable: true,
    },
    {
      authorize: async (data: { email: string; password: string }) => {
        await login(data);
      },
      logout,
    },
  );

  const onRequestSuccess = ({ operation }: any) => {
    const context = operation.getContext();
    const message = context?.[SNACKBAR_SUCCESS_MESSAGE];

    if (message) {
      showSuccess(message);
    }
  };

  const onRequestError = (err: Record<string, unknown>) => {
    recordError(err);

    if (err instanceof ApolloError) {
      const issues = err.graphQLErrors ?? [];

      const isNotAuthenticated = issues.some((issue: GraphQLIssue) => {
        return (
          issue.code === GRAPHQL_ISSUE_CODE.TokenExpiredErrorCode ||
          issue.code === GRAPHQL_ISSUE_CODE.UserNotFoundErrorCode ||
          issue.code === GRAPHQL_ISSUE_CODE.InvalidTokenErrorCode
        );
      });

      if (isNotAuthenticated) {
        checkSession();
      }
    }
  };

  return (
    <AppProvider
      authClient={authClient as ISubscribableAuthClient}
      onRequestSuccess={onRequestSuccess}
      onRequestError={onRequestError}
      uri={REACT_APP_ENDPOINT}
      withSubscriptions
    >
      <LoggerProvider>
        <OneSignalProvider>
          <BrowserRouter>
            <AuthProvider>
              <ConfirmDialogsProvider>
                <CustomDateTimeProvider>
                  <CssBaseline />

                  <InboxProvider>
                    <GlobalStateProvider>
                      <HelpModal />

                      <NotificationsProvider>
                        <StreamChatProvider>
                          <ReactQueryProvider>
                            <RootContent />
                          </ReactQueryProvider>
                        </StreamChatProvider>
                      </NotificationsProvider>
                    </GlobalStateProvider>
                  </InboxProvider>
                </CustomDateTimeProvider>
              </ConfirmDialogsProvider>
            </AuthProvider>
          </BrowserRouter>
        </OneSignalProvider>
      </LoggerProvider>
    </AppProvider>
  );
}
