import React from 'react';
import { Box, styled, Avatar } from '@mui/material';
import { Theme, css } from '@emotion/react';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from '../../../../shared/components/ui';
import { Group } from '../../../../shared/graphql';

const GroupItem = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  marginBottom: `${theme.spacing(2)}px`,
}));

const AVATAR_ICON_WIDTH = 40;
const AVATAR_ICON_HEIGHT = 40;

const groupItemLogoCss = (theme: Theme) => css`
  width: ${AVATAR_ICON_WIDTH}px;
  height: ${AVATAR_ICON_HEIGHT}px;
  margin-right: ${theme.spacing(2)}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.divider};
`;

const groupItemTextCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
  user-select: none;
`;

interface JoinedGroupListItemProps {
  logo: Group['logo'];
  title: Group['title'];
  onClick: () => void;
}

export const JoinedGroupListItem: React.FC<JoinedGroupListItemProps> = ({
  logo,
  title,
  onClick,
}) => {
  return (
    <GroupItem onClick={onClick}>
      <Avatar
        css={groupItemLogoCss}
        src={logo?.downloadUrl || ''}
        srcSet={processFilestackUrlSrcSet(logo?.downloadUrl || '', {
          compress: true,
          resize: {
            width: AVATAR_ICON_WIDTH,
            height: AVATAR_ICON_HEIGHT,
            fit: 'crop',
          },
        })}
        variant="square"
      />
      <Typography css={groupItemTextCss}>{title}</Typography>
    </GroupItem>
  );
};
