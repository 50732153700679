import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

// MessageContainer

export const MessageContainer = styled.div`
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.palette.grey[500]};
  gap: 0.6rem;
`;

// Message

export const Message = styled(Typography)``;

Message.defaultProps = {
  variant: 'body1',
};
