import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import React from 'react';

import { EVENT_STATUSES } from '@jebel/constants';

import { EVENTS_LIST_QUERY, EventsListQuery, EventsListQueryVariables } from 'shared/graphql/';

export const useEvents = () => {
  const dateNow = React.useMemo(() => {
    return DateTime.now();
  }, []);
  const { data, loading, error } = useQuery<EventsListQuery, EventsListQueryVariables>(
    EVENTS_LIST_QUERY,
    {
      variables: {
        filter: {
          status: { equals: EVENT_STATUSES.active },
          date: { gte: dateNow },
        },
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    items: data?.eventsList.items || [],
    loading,
    error,
  };
};
