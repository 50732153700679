import { forwardRef } from 'react';
import styled from '@emotion/styled';
import {
  FormControlLabel,
  Checkbox,
  FormControlLabelProps,
  CheckboxProps,
  FormHelperText,
} from '@material-ui/core';
import { Field, FieldProps, FieldValidator } from 'formik';

import { getIsInvalid } from '../common';
import { ComponentDataProps } from '../../types';

export type FormCheckboxProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  checkboxProps: Omit<FormControlLabelProps, 'control'> & CheckboxProps;
  onChangeBox?: (value: boolean) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
`;

export const FormCheckbox = forwardRef<HTMLDivElement, FormCheckboxProps>(
  (
    {
      checkboxProps,
      fieldProps,
      onChangeBox,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const { disabled, label, labelPlacement = 'end', ...otherCheckboxProps } = checkboxProps ?? {};

    return (
      <Field name={fieldProps.name} validate={fieldProps.validate}>
        {({ field, meta, form }: FieldProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid && meta.error;

          return (
            <div
              ref={ref}
              data-test={dataTest}
              data-node-id={dataNodeID}
              data-node-render-path={dataRenderPath}
            >
              <StyledFormControlLabel
                disabled={disabled}
                label={label}
                labelPlacement={labelPlacement}
                name={fieldProps.name}
                control={
                  <Checkbox
                    {...otherCheckboxProps}
                    checked={field.value}
                    onChange={e => {
                      field.onChange(e);
                      onChangeBox && onChangeBox(e.target.checked);
                    }}
                  />
                }
              />
              {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
            </div>
          );
        }}
      </Field>
    );
  },
);
