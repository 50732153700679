export const JOB_EMPLOYMENT_TYPE = {
    fullTime: 'fullTime',
    partTime: 'partTime',
    selfEmployed: 'selfEmployed',
    freelance: 'freelance',
    contract: 'contract',
    intern: 'intern',
    apprentice: 'apprentice',
    seasonal: 'seasonal',
};
