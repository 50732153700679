import { SidebarNavigationItemList, AdminSidebarNavigationItemsList } from 'shared/types';

import { APP_URL } from '.';

export const USER_SIDEBAR_NAVIGATION_ITEMS: SidebarNavigationItemList = [
  {
    name: 'Home',
    route: APP_URL.user.home.index,
    icon: 'Home',
  },
  {
    name: 'Inbox',
    route: APP_URL.user.mail.index,
    icon: 'MoveToInbox',
  },
  {
    name: 'School News',
    route: APP_URL.user.news.index,
    icon: 'Description',
  },
  {
    name: 'Explorer Nation',
    route: APP_URL.user.explorer.index,
    icon: 'PeopleAlt',
  },
  {
    name: 'Organizations',
    route: APP_URL.user.organizations.index,
    icon: 'Apartment',
  },
  {
    name: 'Collective Intelligence',
    route: APP_URL.user.collective.index,
    icon: 'QuestionAnswer',
  },
  {
    name: 'Columbus Discounts',
    route: APP_URL.user.discounts.index,
    icon: 'Redeem',
  },
  {
    name: 'Career Services',
    route: APP_URL.user.services.index,
    icon: 'Work',
  },
  { name: 'Events', route: APP_URL.user.events.index, icon: 'EventNote' },
  { name: 'Groups', route: APP_URL.user.groups.index, icon: 'Group' },
  { name: 'Legacy', route: APP_URL.user.legacy.index, icon: 'Public' },
  {
    name: 'Class Scorecard',
    route: APP_URL.user.scorecard.index,
    icon: 'Equalizer',
  },
  { name: 'School Store', route: APP_URL.user.store.index, icon: 'Launch' },
];

export const ADMIN_SIDEBAR_NAVIGATION_ITEMS: AdminSidebarNavigationItemsList = [
  {
    name: 'Dashboard',
    route: APP_URL.admin.dashboard.index,
    icon: 'Dashboard',
  },
  {
    name: 'School Posts',
    route: APP_URL.admin.posts.index,
    icon: 'Description',
  },
  {
    name: 'Inbox',
    route: APP_URL.admin.mail.index,
    icon: 'MoveToInbox',
  },
  {
    name: 'Content Curation',
    route: APP_URL.admin.contentCuration.index,
    icon: 'Flag',
    showUnreadElements: true,
  },
  {
    name: 'Events',
    route: APP_URL.admin.events.index,
    icon: 'EventNote',
  },
  {
    name: 'Legacy',
    route: APP_URL.admin.legacy.index,
    icon: 'Public',
  },
  {
    name: 'Members',
    route: APP_URL.admin.members.index,
    icon: 'People',
  },
  {
    name: 'Business Registry',
    route: APP_URL.admin.business.index,
    icon: 'Work',
  },
  {
    name: 'Groups',
    route: APP_URL.admin.groups.index,
    icon: 'Groups',
  },
  {
    name: 'Discounts',
    route: APP_URL.admin.discounts.index,
    icon: 'Redeem',
  },
  { name: 'Class Scorecard', route: APP_URL.admin.scorecard.index, icon: 'Equalizer' },
  { name: 'Reports', route: APP_URL.admin.reports.index, icon: 'AutoGraph' },
  {
    name: 'Settings',
    route: APP_URL.admin.settings.index,
    icon: 'Settings',
  },
];
