import { css } from '@emotion/react';

import { HeadlinesType } from 'shared/features/spreadsheet';
import { SortOrder } from 'shared/graphql/__generated__';

export enum ClassScoreCardReportHeaders {
  classYear = 'Class Year',
  alumni = 'Alumni',
  registeredUsers = 'Registered Users',
  createdBy = 'Created By',
  createdOn = 'Created On',
}

export const ClassScorecardSpreadsheetHeader: HeadlinesType = [
  {
    name: 'year',
    label: 'CLASS YEAR',
    id: 'year',
    sortPath: (order: SortOrder) => ({ year: order }),
    css: () =>
      css`
        width: 18.15%;
      `,
  },
  {
    name: 'alumni',
    label: 'ALUMNI',
    id: 'alumni',
    sortPath: (order: SortOrder) => ({ graduateCount: order }),
    css: () =>
      css`
        width: 10.45%;
      `,
  },
  {
    name: 'registeredUsers',
    label: 'REGISTERED USERS',
    id: 'registeredUsers',
    sortPath: (order: SortOrder) => ({ registeredUsers: order }),
    css: () =>
      css`
        width: 19.55%;
      `,
  },
  {
    name: 'createdBy',
    label: 'CREATED BY',
    id: 'createdBy',
    sortPath: (order: SortOrder) => ({ createdBy: { firstName: order } }),
    css: () =>
      css`
        width: 26.43%;
      `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => ({ createdAt: order }),
    css: () =>
      css`
        width: 15.42%;
      `,
  },
] as const;
