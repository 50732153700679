import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { useCallback } from 'react';
import { Business } from '@material-ui/icons';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { buildUrl } from 'shared/routes';
import { OrganizationItemFragment } from 'shared/graphql';

const ORGANIZATION_AVATAR_SIZE = 80;

const groupItemCss = theme => css`
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

const organizationavatarCss = theme => css`
  width: ${ORGANIZATION_AVATAR_SIZE}px;
  height: ${ORGANIZATION_AVATAR_SIZE}px;
  margin-right: ${theme.spacing(2)}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.border};
`;

const organizationDescriptionCss = theme => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: ${theme.typography.fontSize + 2}px;
  letter-spacing: 0.08px;
  line-height: 26px;
`;

const organizationNameCss = theme => css`
  color: ${theme.palette.error.light};
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 18px;
`;

interface Props {
  organization: OrganizationItemFragment;
}

export function OrganizationCardItem({ organization }: Props) {
  const history = useHistory();

  const sourceAvatar = organization?.logo?.downloadUrl ?? undefined;

  const onOrganizationOpen = useCallback(
    organization => () => {
      if (!organization?.id) {
        return;
      }

      const url = buildUrl(APP_URL.user.organizations.organization, {
        pathParams: { id: organization?.id },
      });

      history.push(url);
    },
    [history],
  );

  return (
    <Box
      key={organization.id}
      onClick={onOrganizationOpen(organization)}
      display="flex"
      alignItems="center"
      css={groupItemCss}
    >
      <Avatar
        css={organizationavatarCss}
        src={sourceAvatar}
        srcSet={processFilestackUrlSrcSet(sourceAvatar, {
          compress: true,
          resize: {
            width: ORGANIZATION_AVATAR_SIZE,
            height: ORGANIZATION_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        variant="square"
      >
        <Business />
      </Avatar>

      <Box display="flex" flexDirection="column">
        <Typography css={organizationNameCss} variant="subtitle5">
          {organization?.name}
        </Typography>

        <Typography css={organizationDescriptionCss} variant="body2" color="primary">
          {organization?.description}
        </Typography>
      </Box>
    </Box>
  );
}
