import { composeValidators, isValidEmail, isValidLink, required } from 'shared/utils/form';

import { BusinessFormLocation, BusinessFormLocationField } from '../types';

export const BUSINESS_FORM_LOCATION_BLANK: BusinessFormLocation = {
  locationName: '',
  operationalHours: '',
  contactName: '',
  email: '',
  link: '',
  phoneNumber: '',
  address: {
    city: '',
    street1: '',
    zip: '',
    state: '',
  },
};

export const BUSINESS_FORM_LOCATION_FIELDS = [
  { name: 'Location Name', field: 'locationName' },
  { name: 'Address Line', field: 'address.street1' },
  { name: 'City', field: 'address.city' },
];

export const BUSINESS_FORM_LOCATION_ADDITIONAL_FIELDS: BusinessFormLocationField[] = [
  {
    label: 'Hours of operation',
    name: 'operationalHours',
    type: 'description',
    required: true,
  },
  {
    label: 'Contact Name',
    name: 'contactName',
    type: 'text',
    required: true,
  },
  {
    label: 'Email Address',
    name: 'email',
    type: 'text',
    validate: isValidEmail,
    required: true,
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    type: 'phone',
  },
  {
    label: 'URL',
    name: 'link',
    type: 'text',
    validate: isValidLink,
    required: true,
  },
];
