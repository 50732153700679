import { PropsWithChildren } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { CustomLuxonAdapter } from './CustomLuxonAdapter';

export function CustomDateTimeProvider(props: PropsWithChildren<unknown>) {
  return (
    <MuiPickersUtilsProvider utils={CustomLuxonAdapter}>{props.children}</MuiPickersUtilsProvider>
  );
}
