import { ROUTES } from 'shared/constants';
import { GetRoutesFn } from 'shared/routes';

import { AuthPage } from './pages/AuthPage';

const getAuthRoutes: GetRoutesFn = () => [
  {
    path: ROUTES.public.auth,
    exact: false,
    component: AuthPage,
  },
];

export default getAuthRoutes;
