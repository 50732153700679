import { ContentLayout } from '../layouts/ContentLayout';
import { LegacyPostsList } from './LegacyPostsList';

export function LegacyPostsContent() {
  return (
    <ContentLayout>
      <LegacyPostsList />
    </ContentLayout>
  );
}
