import { useCallback, useEffect } from 'react';
import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';
import pluralize from 'pluralize';

import { AsyncContent, Typography } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import { GroupsListItemFragment } from 'shared/graphql/__generated__';
import { useResponsive } from 'shared/hooks';
import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { useGroupsList } from '../../hooks';
import { GroupListItem } from '../GroupListItem';

const resultsAmountCss = theme => css`
  color: ${theme.palette.primary.icon};
  margin-bottom: ${theme.spacing(3)}px;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: ${theme.spacing(1)}px;
  }
`;

const gridLayoutCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;

  ${theme.breakpoints.up('xl')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const listLayoutCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;

export function GroupsList() {
  const { isMobile } = useResponsive();
  const { displayList, setDisplayList } = useSearchContext();
  const { data: groups, count, loading, refreshing, hasMore, fetchMore } = useGroupsList();

  const renderGroupItem = useCallback(
    (
      item: (GroupsListItemFragment & { belongs?: boolean; isAdmin?: boolean }) | undefined | null,
    ) => {
      if (!item) {
        return null;
      }

      return <GroupListItem key={item?.id} data={item} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isMobile) {
      setDisplayList(false);
    }
  }, [isMobile, setDisplayList]);

  return (
    <AsyncContent loading={loading}>
      <Box display="flex" flexDirection="column">
        <Typography css={resultsAmountCss} variant="subtitle5">
          {count} {pluralize('Result', count)} Found
        </Typography>

        <InfiniteScroll hasMore={hasMore} fetching={refreshing} fetchMore={fetchMore}>
          <Box css={displayList ? listLayoutCSS : gridLayoutCSS}>{groups.map(renderGroupItem)}</Box>
        </InfiniteScroll>
      </Box>
    </AsyncContent>
  );
}
