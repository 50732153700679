import styled from '@emotion/styled';
import { Chip, Typography } from '@material-ui/core';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// Content

export const Content = styled.div`
  display: block;
`;

// LegacyDate

export const LegacyDate = styled(Chip)`
  margin-right: 0.5rem;
`;

LegacyDate.defaultProps = {
  size: 'small',
  color: 'secondary',
};
