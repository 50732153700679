import fileSize from 'file-size';
import isNil from 'lodash/isNil';
import { lookup, mimes } from 'mrmime';
mimes.jif = 'image/jpeg';
mimes.jfi = 'image/jpeg';
mimes.jpe = 'image/jpeg';
mimes.jfif = 'image/jpeg';
export const fileSizeToHuman = (size, valueIfNil = '-') => {
    if (isNil(size)) {
        return valueIfNil;
    }
    return fileSize(size).human('si');
};
/** Get the `mime` type based on a file name or url. */
export function checkMime(pathname) {
    var _a;
    return (_a = lookup(pathname)) !== null && _a !== void 0 ? _a : undefined;
}
/** Check if the file is an image using their name or remote location. */
export function isImageFile(pathname) {
    const mime = checkMime(pathname);
    const isImage = mime === null || mime === void 0 ? void 0 : mime.startsWith('image');
    return Boolean(isImage);
}
/** Check if the file is a video using their name or remote location. */
export function isVideoFile(pathname) {
    const mime = checkMime(pathname);
    const isVideo = mime === null || mime === void 0 ? void 0 : mime.startsWith('video');
    return Boolean(isVideo);
}
