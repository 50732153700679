import { Box, css } from '@mui/material';
import { isEmpty, isNumber } from 'lodash';
import { Theme } from '@emotion/react';

import { useResponsive } from 'shared/hooks';

import { DateFilterField, DateFilterFieldProps } from '../DateFilterField';
import { FilterField, FilterFieldProps } from '../FilterField';
import { LayoutControl } from '../LayoutControl';
import { SearchTextField, SearchTextFieldProps } from '../SearchTextField';
import { SortField, SortFieldProps } from '../SortField';
import { ZipFilterField, ZipFilterFieldProps } from '../ZipFilterField';
import { MobileFilter, MobileFilterProps } from '../MobileFilter';

export type SearchControlSortProps =
  | {
      withSortField: true;
      sortFieldProps: SortFieldProps;
    }
  | {
      withSortField?: false;
      sortFieldProps?: SortFieldProps;
    }
  | {
      withSortField: boolean;
      sortFieldProps: SortFieldProps;
    };

export type SearchControlFilterProps =
  | {
      withFilterField: true;
      filterFieldProps: FilterFieldProps;
    }
  | {
      withFilterField?: false;
      filterFieldProps?: FilterFieldProps;
    };

export type SearchControlDateFilterProps =
  | {
      withDateFilterField: true;
      dateFilterFieldProps: DateFilterFieldProps;
    }
  | {
      withDateFilterField?: false;
      dateFilterFieldProps?: DateFilterFieldProps;
    };

export type SearchControlZipFilterProps =
  | {
      withZipFilterField: true;
      zipFilterFieldProps: ZipFilterFieldProps;
    }
  | {
      withZipFilterField?: false;
      zipFilterFieldProps?: ZipFilterFieldProps;
    };

const containerCss = (
  itemsCount: number,
  elementsWidth: string[],
  elementsGap: string | number,
) => css`
  display: grid;
  grid-template-columns: ${isEmpty(elementsWidth)
    ? `repeat(${itemsCount}, 1fr)`
    : `${elementsWidth.join(' ')}`};
  gap: ${isNumber(elementsGap) ? `${elementsGap}px` : elementsGap};
`;

export const sortBoxCSS = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${theme.breakpoints.down('md')} {
    display: grid;
    grid-template-columns: 6fr max-content;
  }
`;

export type SearchControlProps = {
  withFilterModal?: boolean;
  filterModalProps?: MobileFilterProps;
  withTextField?: boolean;
  textFieldProps?: SearchTextFieldProps;
  withLayoutControl?: boolean;
  elementsWidth?: string[];
  elementsGap?: number | string;
} & SearchControlSortProps &
  SearchControlFilterProps &
  SearchControlDateFilterProps &
  SearchControlZipFilterProps;

export const SearchControl: React.FC<SearchControlProps> = ({
  withTextField = true,
  textFieldProps,
  withFilterField = false,
  filterFieldProps,
  withDateFilterField = false,
  dateFilterFieldProps,
  withSortField = false,
  sortFieldProps,
  withLayoutControl = false,
  zipFilterFieldProps,
  withZipFilterField = false,
  elementsWidth = [],
  elementsGap = 10,
  withFilterModal = false,
  filterModalProps,
}) => {
  const { isMobile } = useResponsive();

  const itemsCount = isMobile
    ? 0
    : [
        withTextField,
        withFilterField,
        withDateFilterField,
        withZipFilterField,
        withSortField,
        withLayoutControl,
      ].filter(Boolean).length;

  return (
    <Box css={containerCss(itemsCount, elementsWidth, elementsGap)}>
      {withTextField && <SearchTextField {...textFieldProps} />}
      {withFilterField && filterFieldProps?.renderOptions && <FilterField {...filterFieldProps} />}
      {withDateFilterField && <DateFilterField {...dateFilterFieldProps} />}
      {withZipFilterField && <ZipFilterField {...zipFilterFieldProps} />}

      <Box css={sortBoxCSS}>
        {withSortField && sortFieldProps?.sortInfo && <SortField {...sortFieldProps} />}
        {withFilterModal && <MobileFilter {...filterModalProps} />}
      </Box>

      {withLayoutControl && <LayoutControl />}
    </Box>
  );
};
