import { useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import { InfiniteScroll } from 'shared/features/infinite-scroll';
import { useNotifications } from 'shared/features/notifications';

import { NotificationItem } from './NotificationItem';
import { NormalizedNotification } from '../utils';

const CONTENT_CONTAINER_WIDTH = 330;
const CONTENT_CONTAINER_MAX_HEIGHT = 300;

export type NotificationsPopoverContentProps = {
  isOpen: boolean;
  handlePopoverClose: () => void;
};

export function NotificationsPopoverContent(props: NotificationsPopoverContentProps) {
  const {
    notifications,
    unreadCount,
    markAllNotificationsAsRead,
    navigateToNotification,
    fetching,
    hasMore,
    fetchMoreNotifications,
  } = useNotifications();

  const openNotification = (notification: NormalizedNotification) => {
    if (!notification.redirectUrl) {
      return;
    }

    navigateToNotification(notification);
  };

  useLayoutEffect(() => {
    if (unreadCount > 0) {
      markAllNotificationsAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <Box css={popoverContentCss}>
      <Box css={headerCss}>
        <Typography css={titleCss} variant="h4">
          Notifications
        </Typography>

        {unreadCount > 0 && (
          <Typography css={newNotificationsCss} variant="h4">
            {unreadCount} new notifications
          </Typography>
        )}
      </Box>

      <div css={notificationsListCss}>
        <InfiniteScroll hasMore={hasMore} fetching={fetching} fetchMore={fetchMoreNotifications}>
          {notifications.map(notification => (
            <NotificationItem
              notification={notification}
              key={notification.id}
              onClick={() => {
                openNotification(notification);
                props.handlePopoverClose();
              }}
            />
          ))}
        </InfiniteScroll>

        {notifications.length === 0 && (
          <Typography css={emptyNotificationsListCSS}>You don&#39;t have notifications</Typography>
        )}
      </div>
    </Box>
  );
}

const emptyNotificationsListCSS = css`
  text-align: center;
  margin: 10px auto;
`;

const popoverContentCss = css`
  width: ${CONTENT_CONTAINER_WIDTH}px;
  position: relative;
  overflow: hidden;
`;

const notificationsListCss = css`
  width: ${CONTENT_CONTAINER_WIDTH}px;
  max-height: ${CONTENT_CONTAINER_MAX_HEIGHT}px;
  overflow-y: scroll;
`;

const headerCss = theme => css`
  background-color: ${theme.palette.primary.dark};
  width: 100%;
  padding: 15px;
`;

const titleCss = css`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const newNotificationsCss = css`
  color: #ffffff;
  font-size: 12px;
`;
