import { useMemo } from 'react';
import { Person } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';

import { MinimalUserFragment } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { generateUserProfileRoute } from 'shared/routes';

import { AuthorAvatar } from './AuthorAvatar';

interface Props {
  user: MinimalUserFragment | undefined | null;
  iconSize?: SvgIconProps['fontSize'];

  disabled?: boolean;

  className?: string;
}

export function UserAvatar(props: Props) {
  const source = props.user?.avatar?.downloadUrl ?? undefined;
  const name = formatUserName(props.user);

  const route = useMemo(() => {
    return generateUserProfileRoute(props.user?.id);
  }, [props.user]);

  return (
    <AuthorAvatar
      className={props.className}
      source={source}
      name={name}
      route={route}
      disabled={!props.user || props.disabled}
    >
      <Person fontSize={props.iconSize} />
    </AuthorAvatar>
  );
}
