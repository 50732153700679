import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { nanoid } from 'nanoid';

import { FormSelect, FormTextField, FormTextFieldProps } from 'shared/components/ui';

import { FormSelectProps } from '../../../ui/FormSelect/FormSelect';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '1.125rem',
}));

export const CustomButton = styled(Button)({
  textTransform: 'none',
});

export const DialogFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '0.5rem',
  flexFlow: 'row nowrap',
});

type CustomTextFieldProps = FormTextFieldProps['inputProps'] & {
  name: string;
};
export const CustomTextField: React.FC<CustomTextFieldProps> = ({ name, ...inputProps }) => (
  <FormTextField
    inputProps={{ color: 'primary', variant: 'outlined', ...inputProps }}
    fieldProps={{ name }}
  />
);

type CustomSelectProps = FormSelectProps['selectProps'] & {
  name: string;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
};
export const CustomSelectField: React.FC<CustomSelectProps> = ({ name, onChange, ...props }) => (
  <FormSelect selectProps={{ variant: 'outlined', ...props }} fieldProps={{ name, onChange }} />
);

type CustomSelectItemProps = Partial<React.InputHTMLAttributes<HTMLInputElement>> & {
  children: React.ReactNode;
};
export const FileInputButton = ({ children, ...inputProps }: CustomSelectItemProps) => {
  const id = React.useMemo(() => `file_input_${nanoid(3)}`, []);
  return (
    <Button component="label" htmlFor={id} disabled={inputProps?.disabled} size="medium">
      {children}
      <input type="file" hidden id={id} {...inputProps} />
    </Button>
  );
};
