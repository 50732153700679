import { useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import { useTheme } from '@material-ui/core';

import { DISTANCE_FILTER_IN_MILES } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { SelectMenu } from '../../../../../shared/components/ui';
import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';
import { DashboardFilters } from '../../DashboardFilters';
import { useDateFilterState } from '../../../hooks/useDateFilterState';
import { useBusinessesCount } from '../../../hooks';
import { formatDecimalNumbers } from '../../../../../shared/utils/numbers/formatDecimalNumbers';
import { Container, ZipCode } from './DashboardCountBusinessWrapper.styles';

type ZipFilterStateType = {
  zip: string;
  distance: string;
};

export function DashboardCountBusinessWrapper() {
  const theme = useTheme();
  const [zipFilter, setZipFilter] = useState<ZipFilterStateType>({
    zip: '',
    distance: '-',
  });
  const [dateFilter, setPartialDateFilter] = useDateFilterState();

  const {
    businessesCount,
    loading: businessLoading,
    supportersCount,
  } = useBusinessesCount({
    zipFilter,
    dateFilter: {
      startDateIso: formatISO8601Date(dateFilter.startDate),
      endDateIso: formatISO8601Date(dateFilter.endDate),
    },
  });

  return (
    <DashboardCountStatsItem
      title="Business"
      icon={<BusinessIcon />}
      color={theme.palette.primary.light}
      counters={[
        {
          label: 'Registered business',
          count: formatDecimalNumbers(businessesCount),
        },
        {
          label: 'Active supporters',
          count: formatDecimalNumbers(supportersCount),
        },
      ]}
    >
      <Container>
        <DashboardFilters
          dateFilter={dateFilter}
          setStartDateFilter={setPartialDateFilter('startDate')}
          setEndDateFilter={setPartialDateFilter('endDate')}
          loading={businessLoading}
        />

        <ZipCode
          value={zipFilter.zip}
          onChange={e => setZipFilter({ ...zipFilter, zip: e.target.value })}
        />

        <SelectMenu
          label={zipFilter.distance}
          value={zipFilter.distance}
          onChange={distance => setZipFilter(prevState => ({ ...prevState, distance }))}
          options={Object.values(DISTANCE_FILTER_IN_MILES)}
          disabled={!zipFilter.zip}
        />
      </Container>
    </DashboardCountStatsItem>
  );
}
