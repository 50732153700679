import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { ClassScorecardPage } from './pages/ClassScorecardPage';

export const getScorecardRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.scorecard.index,
    exact: true,
    component: ClassScorecardPage,
  },
];
