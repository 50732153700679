import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';
import styled from '@emotion/styled';

import { Icon, Typography } from 'shared/components/ui';
import { useIntersectionWatcher, useRelativeDate } from 'shared/hooks';

import { NormalizedNotification } from '../utils';

const NOTIFICATION_ICON_SIZE = 36;

interface Props {
  notification: NormalizedNotification;
  onClick?: () => void;
}

export function NotificationItem({ notification, onClick }: Props) {
  const { ref, visible: haveBeenShown } = useIntersectionWatcher<HTMLDivElement>();

  const haveImage = Boolean(notification?.imageUrl);
  const haveLink = Boolean(notification?.redirectUrl);
  const haveBeenRead = Boolean(notification?.haveBeenRead);

  const formattedDate = useRelativeDate(notification.createdAt, { refresh: haveBeenShown });

  return (
    <Container ref={ref} haveLink={haveLink} haveBeenRead={haveBeenRead} onClick={onClick}>
      <Box css={iconContainerCss}>
        {haveImage ? (
          <Avatar src={notification?.imageUrl} alt={notification.title} />
        ) : (
          <Icon name="Info" variant="filled" css={iconCss} />
        )}
      </Box>

      <Box css={notificationTextCss}>
        <Typography css={notificationTitleCss}>{notification?.title}</Typography>
        <Typography css={notificationDateCss}>{formattedDate}</Typography>
      </Box>

      {haveLink && (
        <Box>
          <Icon name="KeyboardArrowRight" />
        </Box>
      )}
    </Container>
  );
}

const notificationTextCss = css`
  flex-grow: 1;
`;

const notificationDateCss = css`
  font-size: 12px;
  line-height: 19px;
`;

const notificationTitleCss = css`
  font-size: 14px;
  line-height: 20px;
`;

interface ContainerProps {
  haveLink: boolean;
  haveBeenRead: boolean;
}

const Container = styled.div<ContainerProps>(p => {
  const background = p.theme.palette.grey[100];
  const backgroundRead = p.theme.palette.background.paper;

  return css`
    width: 100%;
    padding: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: ${p.haveLink ? 'pointer' : 'default'};
    background-color: ${p.haveBeenRead ? backgroundRead : background};
  `;
});

const iconCss = theme => css`
  width: 100%;
  height: 100%;
  color: ${theme.palette.text.secondary};
`;

const iconContainerCss = css`
  width: ${NOTIFICATION_ICON_SIZE}px;
  height: ${NOTIFICATION_ICON_SIZE}px;
  padding: 6px;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
`;
