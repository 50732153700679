import React from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { SUPPORTERS } from 'shared/constants';

import { DiscountsContent } from '../components/DiscountsContent';

export const DiscountsPage = () => {
  return (
    <SearchProvider>
      <RouteLayout
        withFullWidth
        content={<DiscountsContent />}
        sidebar={<CommunitySidebar items={[SUPPORTERS]} />}
      />
    </SearchProvider>
  );
};
