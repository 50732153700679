import React from 'react';
import { css, useTheme } from '@emotion/react';
import { Box, Typography, Button, Pagination } from '@mui/material';

import { SORT } from '@jebel/constants';

import { useThreadsQuery } from 'features/collective/hooks/useThreadsQuery';
import { AsyncContent } from 'shared/components/ui';
import { PostsProvider } from 'shared/features/posts';
import { SearchControl, SortFieldProps } from 'shared/features/search';
import { PaperLayout } from 'shared/components/layouts';
import { useCrudPermissions, usePageTitle, useResponsive } from 'shared/hooks';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';

import { CollectiveIntelligenceModal } from '../CollectiveIntelligenceModal';
import { CollectiveIntelligenceListHeader } from './CollectiveIntelligenceListHeader';
import { CollectiveIntelligenceThreadsList } from './CollectiveIntelligenceThreadsList';

const contentContainerCss = {
  flex: 1,
  minWidth: 0,
  py: { xs: 1.75, md: 4 },
};

const requestThreadButtonCss = theme => css`
  background-color: ${theme.palette.primary.light};
  height: 40px;
  font-size: ${theme.typography.fontSize};
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 17px;
  white-space: nowrap;
`;

const titleCss = theme => css`
  ${theme.breakpoints.down('xs')} {
    font-size: ${theme.typography.fontSize + 8}px;
    font-weight: 400;
  }
`;

// TODO: FIX FILTER BY ACTIVITY
const SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    options: [
      {
        label: 'Most Recent First',
        value: {
          createdAt: SORT.desc,
        },
      },
      {
        label: 'Topic',
        value: {
          title: SORT.asc,
        },
      },
      {
        label: 'Author',
        value: {
          creator: { firstName: SORT.asc },
        },
      },
    ],
  },
};

export const CollectiveIntellegenceContent = () => {
  const { isMobile } = useResponsive();
  const title = usePageTitle({ fallback: 'Collective Intelligence' });
  const theme: any = useTheme();
  const {
    forumPermissions: { add: isCreateAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();
  const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

  const { items, activities, activitiesLoading, loading, onChangePage, pageCount, page } =
    useThreadsQuery();

  const { onModalOpen, onModalClose } = React.useMemo(
    () => ({
      onModalOpen: () => setIsModalOpened(true),
      onModalClose: () => setIsModalOpened(false),
    }),
    [],
  );

  return (
    <React.Fragment>
      <CollectiveIntelligenceModal onClose={onModalClose} isOpen={isModalOpened} />
      <Box display="grid" gap={3}>
        <PaperLayout sx={contentContainerCss}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={{ xs: 1, md: 3 }}
            mx={{ xs: 2.5, md: 4 }}
          >
            <Typography css={titleCss} variant="subtitle2" color="primary" lineHeight="initial">
              {title}
            </Typography>

            {isCreateAccessed && !loadingAddons && (
              <Button
                onClick={onModalOpen}
                color="primary"
                variant="contained"
                css={requestThreadButtonCss}
              >
                {isMobile ? 'New' : 'New topic'}
              </Button>
            )}
          </Box>
          <Box my={{ xs: 1, md: 3 }} mx={{ xs: 2.5, md: 4 }}>
            <SearchControl
              elementsGap={theme.spacing(2)}
              withSortField
              textFieldProps={{ style: { flexShrink: 1 }, placeholder: 'Search' }}
              sortFieldProps={SORT_INFO}
            />
          </Box>
          <PostsProvider activities={activities} loading={activitiesLoading}>
            <Box
              flexDirection="column"
              flexGrow={1}
              minWidth={0}
              display="flex"
              justifyContent="center"
            >
              <CollectiveIntelligenceListHeader loading={loading || activitiesLoading} />
              <AsyncContent loading={loading || activitiesLoading}>
                {items.length === 0 ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography my={5} variant="body2">
                      NO DATA
                    </Typography>
                  </Box>
                ) : (
                  <React.Fragment>
                    <CollectiveIntelligenceThreadsList data={items} />
                    <Box mx="auto">
                      <Pagination
                        page={page}
                        onChange={onChangePage}
                        count={pageCount}
                        color="primary"
                      />
                    </Box>
                  </React.Fragment>
                )}
              </AsyncContent>
            </Box>
          </PostsProvider>
        </PaperLayout>
        {isMobile && <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />}
      </Box>
    </React.Fragment>
  );
};
