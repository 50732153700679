import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { ascend } from 'ramda';

import { CommentEnrichedReaction } from 'shared/types';
import { useStreamChatReactions } from 'shared/hooks';
import { useHomeFeedCardData, useStreamActivityContext } from 'shared/features/posts/providers';
import { formatMilliseconds } from 'shared/utils/date';
import { MentionPosition } from 'shared/features/mentions/types';

import { Comment, CommentForm, CommentImage } from '../../../Comment';
import { useHomeFeedAuthor } from '../../useHomeFeedAuthor';
import { Container, Content } from './HomeFeedCardComments.styles';

interface Props {
  comments: CommentEnrichedReaction[];

  onCommentsChange?(comments: CommentEnrichedReaction[]): void;
}

export function HomeFeedCardComments(props: Props) {
  const [comments, setComments] = useState<CommentEnrichedReaction[]>(props.comments);
  const [showComments, setShowComments] = useState(false);

  const {
    onCommentAdd: attachComment,
    onCommentRemove: removeComment,
    onLikeAdd: attachLike,
    onReactionRemove: removeLike,
  } = useStreamChatReactions();

  const { data: activity } = useStreamActivityContext();
  const { data: author } = useHomeFeedAuthor();

  const post = useHomeFeedCardData();

  const canShowMoreComments = comments.length > 1;
  const textField = useRef<HTMLInputElement>(null);

  const commentsSorted = useMemo(() => {
    const commentsMixed = [...comments];
    return commentsMixed.sort(ascend(comment => formatMilliseconds(comment.created_at)));
  }, [comments]);

  const latestComment = useMemo(() => {
    return commentsSorted[commentsSorted.length - 1];
  }, [commentsSorted]);

  const otherComments = useMemo(() => {
    return commentsSorted.slice(0, commentsSorted.length - 1);
  }, [commentsSorted]);

  const onCommentPost = async (
    text: string,
    media: CommentImage[],
    mentions: MentionPosition[],
  ) => {
    if (!activity?.id) {
      return;
    }

    const comment = await attachComment({
      activityId: activity.id,
      foreignId: post.id ?? undefined,
      parentEntityAuthorId: author?.id ?? undefined,
      parentEntityType: 'post',
      media,
      text,
      mentions,
    });

    if (!comment) {
      return;
    }

    setComments(prev => [...prev, comment]);
    setShowComments(true);
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      props.onCommentsChange?.(comments);
    }

    return () => {
      mount = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const showMoreComments = () => {
    setShowComments(true);
  };

  return (
    <Container>
      {showComments && (
        <Content>
          {otherComments.map(comment => (
            <Comment
              key={comment.id}
              setComments={setComments}
              postId={post.id as string}
              comment={comment}
              onLikeAdd={attachLike}
              onReactionRemove={removeLike}
              onCommentAdd={attachComment}
              onCommentRemove={removeComment}
            />
          ))}
        </Content>
      )}

      {Boolean(latestComment) && (
        <Comment
          setComments={setComments}
          postId={post.id as string}
          comment={latestComment}
          onLikeAdd={attachLike}
          onReactionRemove={removeLike}
          onCommentAdd={attachComment}
          onCommentRemove={removeComment}
        />
      )}

      {canShowMoreComments && !showComments && (
        <Button variant="text" onClick={showMoreComments}>
          View all comments
        </Button>
      )}

      <CommentForm type="comment" fieldRef={textField} onCommentPost={onCommentPost} />
    </Container>
  );
}
