import { validateUrl } from './validateUrl';

export function addHttpsPrefix(url: string) {
  const result = validateUrl(url);

  if (!result) {
    throw new Error(`"${url}" is not a URL`);
  }

  const hasPrefix = new RegExp('^(https?:\\/\\/)', 'i');

  if (hasPrefix.test(url)) {
    return url;
  }

  const newUrl = `https://${url}`;
  return newUrl;
}
