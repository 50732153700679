import React from 'react';
import styled from '@emotion/styled';
import { Paper, CircularProgress, Box } from '@mui/material';

import type { MembersStats as MembersStatsType } from '@jebel/constants';

import { Typography } from 'shared/components/ui';

const STAT_HEIGHT = 110;

const formatNumber = (num?: number | null | undefined) => {
  if (!num) return 0;

  const thousands = num / 1000;
  if (thousands > 3) {
    return `${thousands.toFixed(1)}K`;
  }

  if (thousands > 1) {
    return `${Math.trunc(thousands)},${num % 1000}`;
  }

  return num;
};

export type MembersStatsProps = {
  membersStats: MembersStatsType;
  loading: boolean;
};

export const MembersStats: React.FC<MembersStatsProps> = ({ membersStats, loading }) => {
  const membersStatsCards = React.useMemo(() => {
    return [
      {
        label: 'Total Members',
        count: membersStats.totalCount,
      },
      {
        label: 'Students',
        count: membersStats.students,
      },
      {
        label: 'Parents',
        count: membersStats.parents,
      },
      {
        label: 'Alumni',
        count: membersStats.alumni,
      },
      {
        label: 'School Staff',
        count: membersStats.schoolStaff,
      },
      {
        label: 'Pending Approval',
        count: membersStats.pendingApproval,
        isPending: membersStats.pendingApproval > 0,
      },
    ];
  }, [membersStats]);

  if (loading) {
    return (
      <Box height={`${STAT_HEIGHT}px`} mb="15px" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StatContainer>
      {membersStatsCards.map(({ label, count, isPending }) => (
        <StatElement key={label}>
          <StatNum isPending={isPending}>{formatNumber(count)}</StatNum>
          <StatLabel>{label}</StatLabel>
        </StatElement>
      ))}
    </StatContainer>
  );
};

const StatContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StatElement = styled(Paper)`
  height: ${STAT_HEIGHT}px;
  flex: 1;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  :last-child {
    margin-right: 0;
  }
`;

interface StatNumProps {
  isPending?: boolean;
}
const StatNum = styled(Typography, {
  shouldForwardProp: (prop: keyof StatNumProps) => prop !== 'isPending',
})<StatNumProps>`
  color: ${p => (p.isPending ? '#EF5C5C' : '#295f7b')};
  font-size: 63px;
  letter-spacing: -0.53px;
  line-height: 76px;
  text-align: center;
`;

const StatLabel = styled(Typography)`
  text-align: center;
`;
