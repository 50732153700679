import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip, IconButton } from '@material-ui/core';

// Container

export const Container = styled.div`
  display: flex;
  gap: 0.35rem;
  align-items: center;
`;

// Content

interface ContentProps {
  background: string | undefined;
}

export const Content = styled(Chip)<ContentProps>(props => {
  const backgroundColor = props.background ?? props.theme.palette.divider;
  const color = !props.background ? 'black' : 'white';

  return css`
    background-color: ${backgroundColor};
    color: ${color};
  `;
});

// Action

export const Action = styled(IconButton)`
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;

  &[disabled],
  &:disabled {
    cursor: default;
  }
`;
