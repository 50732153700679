import { useMemo } from 'react';

import { createFilterBuilder } from '@jebel/utils';
import {
  GROUP_POST_TYPE,
  HOME_POST_TYPE,
  LEGACY_POST_TYPE,
  SCHOOL_POST_TYPE,
} from '@jebel/constants';

import { useHomeFeed } from 'features/home/hooks';
import { useSearchContext } from 'shared/features/search';
import { HomeFeedItemFilter } from 'shared/graphql';

interface Options {
  first?: number;
  filter?: HomeFeedItemFilter;
}

/** Explore all the feed using the same API as **Home** page. */
export function useExplorerFeed(options?: Options) {
  const { searchQuery } = useSearchContext();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>(options?.filter).and({
      postType: { in: [HOME_POST_TYPE, SCHOOL_POST_TYPE, LEGACY_POST_TYPE, GROUP_POST_TYPE] },
    });

    return filter.build();
  }, [options?.filter]);

  return useHomeFeed({
    searchFirst: options?.first,
    searchQuery,
    filter,
  });
}
