import { uniqBy } from 'ramda';

import { Group, Maybe, User, UserKeyFilter } from 'shared/graphql';

type UserInput = Pick<User, 'id' | '__typename'>;

type GroupInput = Pick<Group, 'id' | '__typename'> & {
  // title?: string;
  members?: Maybe<{ items?: Maybe<UserKeyFilter[]> }>;
};

export function normalizeMembers(selected: Array<UserInput | GroupInput> = []): UserInput[] {
  const users: UserKeyFilter[] = selected.flatMap(element => {
    // eslint-disable-next-line no-underscore-dangle
    if (element.__typename === 'Group') {
      const members = element.members?.items ?? [];
      return normalizeMembers(members);
    }

    return { id: element.id };
  });

  return uniqBy(user => user.id, users);
}
