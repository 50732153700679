import { NotificationOptionalInfo as Params } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { Maybe, UserNotificationReadableFragment } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';
import { formatUserName } from 'shared/utils/user';

export interface NormalizedNotification {
  id: string;
  title: string;
  haveBeenRead: boolean;
  /** URL of the image. */
  imageUrl?: string;
  /** URL of the given URL to redirect the user. */
  redirectUrl?: string;
  /** Creation date on time-stamp format. */
  createdAt: string;

  /** @deprecated Use `redirectUrl` instead. */
  link?: string;
  /** @deprecated Use `redirectUrl` instead. */
  image?: string;

  onClick?(): void;
}

export function normalizeNotification(
  readable: Maybe<UserNotificationReadableFragment> | undefined,
): NormalizedNotification {
  const template = readable?.notification?.template;
  const sender = readable?.notification?.sender;

  const username = formatUserName(sender);
  const params = extractParams(readable?.notification?.infoJSON);

  const title = normalizeTitle(template?.title ?? '', { username, ...params });
  const imageSource = readable?.notification?.image?.downloadUrl ?? undefined;

  const payload: NormalizedNotification = {
    id: String(readable?.id),
    title,
    image: imageSource,
    imageUrl: imageSource,
    haveBeenRead: Boolean(readable?.isRead),
    createdAt: formatISO8601Date(readable?.createdAt),
  };

  if (params.link) {
    const link = normalizeLink(params.link, params.site);

    payload.redirectUrl = link;
    payload.link = link;

    payload.onClick = () => {
      window.location.assign(link);
    };
  }

  return payload;
}

function normalizeTitle(template: string, params: Params): string {
  return template.replace(/\[(\w+)]/g, (match, key) => params[key] as string);
}

const COMMUNITY_DOMAIN = process.env.REACT_APP_COMMUNITY_DOMAIN;
const ADVERTISING_DOMAIN = process.env.REACT_APP_ADVERTISING_DOMAIN;
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

function normalizeLink(link: string, site?: Params['site']): string {
  const base = site === 'advertising' ? ADVERTISING_DOMAIN : COMMUNITY_DOMAIN;
  const url = new URL(link, IS_DEVELOPMENT ? window.location.origin : base);
  // Parse the given URL into readable text.
  return `${url}`;
}

function extractParams(metadata: unknown): Params {
  try {
    if (typeof metadata === 'string') {
      return JSON.parse(metadata);
    }

    if (typeof metadata === 'object' && Array.isArray(metadata)) {
      return metadata as Params;
    }
  } catch (err) {
    sendToSentry(err);
  }

  return {};
}
