import { Fragment, useCallback } from 'react';
import { Theme, css } from '@emotion/react';
import { Box, TextField } from '@material-ui/core';

import { Form, FormTextField, Button, FormCheckbox } from 'shared/components/ui';
import { CareerInfoFragment, JobOfferUpdateInput } from 'shared/graphql';
import { required } from 'shared/utils/form';

import { useJobPostEdit, useJobPostDelete, useJobOfferRenew } from '../../hooks';

const formBlockCSS = (theme: any) => css`
  display: grid;
  grid-row-gap: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(1)}px;
`;

const buttonsCSS = (theme: Theme) => css`
  display: flex;
  gap: 0.5rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
  }
`;

type FormData = {
  position?: string | undefined | null;
  description?: string | undefined | null;
  internship?: boolean | undefined;
};

type EditPostModalProps = {
  onModalClose: () => void;
  jobOfferInfo: CareerInfoFragment | undefined;
  callbackRefetch?: any;
};

export const EditPostModal = ({
  onModalClose,
  jobOfferInfo,
  callbackRefetch,
}: EditPostModalProps) => {
  const { onJobPostEdit } = useJobPostEdit();
  const { onJobPostDelete } = useJobPostDelete();
  const { renewJobOffer } = useJobOfferRenew();

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const data: JobOfferUpdateInput = {
        description: formData.description,
        position: formData.position,
        employmentType: 'fullTime',
      };

      if (formData.internship) {
        data.employmentType = 'intern';
      }

      await onJobPostEdit(data, jobOfferInfo?.id);
      onModalClose();
    },
    [onJobPostEdit, jobOfferInfo?.id, onModalClose],
  );

  const onRenewClick = useCallback(async () => {
    await renewJobOffer(jobOfferInfo?.id || '');

    if (callbackRefetch) {
      await callbackRefetch();
    }
    onModalClose();
  }, [renewJobOffer, jobOfferInfo?.id, callbackRefetch, onModalClose]);

  const onDeleteClick = useCallback(async () => {
    await onJobPostDelete(jobOfferInfo?.id);

    if (callbackRefetch) {
      await callbackRefetch();
    }
    onModalClose();
  }, [onJobPostDelete, jobOfferInfo?.id, callbackRefetch, onModalClose]);

  return (
    <Form
      oldCss={formBlockCSS}
      onSubmit={onSubmit}
      validateOnBlur={false}
      initialValues={{
        position: jobOfferInfo?.position,
        description: jobOfferInfo?.description,
        internship: jobOfferInfo?.employmentType === 'intern',
      }}
    >
      {({ isSubmitting }) => (
        <Fragment>
          <Box display="grid" gridTemplateRows="auto" gridGap={20} my={2}>
            <TextField
              label="ORGANIZATION"
              variant="outlined"
              value={jobOfferInfo?.organization?.name}
              disabled
            />

            <FormTextField
              inputProps={{
                label: 'JOB POSITION',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'position', validate: required }}
            />

            <FormCheckbox
              fieldProps={{ name: 'internship' }}
              checkboxProps={{ label: 'Is Internship' }}
            />

            <FormTextField
              inputProps={{
                color: 'primary',
                label: 'JOB DESCRIPTION',
                multiline: true,
                rows: '7',
                rowsMax: '7',
                variant: 'outlined',
              }}
              fieldProps={{ name: 'description' }}
            />
          </Box>

          <Box css={buttonsCSS}>
            <Button disabled={isSubmitting} onClick={onModalClose}>
              Cancel
            </Button>

            <Button variant="outlined" disabled={isSubmitting} onClick={onDeleteClick}>
              Delete
            </Button>

            <Button variant="outlined" disabled={isSubmitting} onClick={onRenewClick}>
              Renew
            </Button>

            <Button
              variant="contained"
              color="primary"
              loading={isSubmitting}
              disableElevation
              type="submit"
            >
              Save Changes
            </Button>
          </Box>
        </Fragment>
      )}
    </Form>
  );
};
