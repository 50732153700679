import { ReactNode } from 'react';

import { useResponsive } from 'shared/hooks/useResponsive';
import { TopBar } from 'admin-features/settings/components/TopBar/TopBar';

import { Centered, Container, Content, Header, Layout, Sidebar } from './PageLayout.styles';
import { PoweredBy } from './PoweredBy';

export interface PageLayoutProps {
  content?: ReactNode;
  sidebar?: ReactNode;
  header?: ReactNode;
  bottomMenu?: ReactNode;
  drawerOpen?: boolean;
  drawerOpenWidth?: string | number;
  drawerClosedWidth?: string | number;
  /** @deprecated */
  drawerAnchor?: any;
  /** @deprecated */
  drawerVariant?: any;
  /** @deprecated */
  drawerOnClose?: (event: any) => void;
}

export function PageLayout({
  content,
  header,
  sidebar,
  bottomMenu,
  drawerOpen,
  drawerOnClose,
  drawerAnchor,
  drawerVariant,
  drawerOpenWidth,
  drawerClosedWidth,
}: PageLayoutProps) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Container>
        <TopBar />

        <Layout>
          <Header>{header}</Header>
          <Content>{content}</Content>

          {sidebar}
        </Layout>

        {bottomMenu}
      </Container>
    );
  }

  return (
    <Container>
      <TopBar />

      <Layout sidebarWidth={drawerOpen ? drawerOpenWidth : drawerClosedWidth}>
        <Sidebar>{sidebar}</Sidebar>

        <Centered>
          <Header>{header}</Header>
          <Content>{content}</Content>
        </Centered>
      </Layout>

      <PoweredBy />
    </Container>
  );
}
