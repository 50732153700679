import { SORT } from '@jebel/constants';

import { SortFieldProps } from 'shared/features/search';

export const LEGACY_POSTS_SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Legacy Date: Newest First',
        value: {
          legacyDate: SORT.desc,
        },
      },
      {
        label: 'Legacy Date: Oldest First',
        value: {
          legacyDate: SORT.asc,
        },
      },
      {
        label: 'Post Date: Newest First',
        value: {
          createdAt: SORT.desc,
        },
      },
      {
        label: 'Post Date: Oldest First',
        value: {
          createdAt: SORT.asc,
        },
      },
    ],
  },
};

export const LEGACY_POSTS_SORT_INFO_DEFAULT_VALUE =
  LEGACY_POSTS_SORT_INFO.sortInfo?.options[0].value;
