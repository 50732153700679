import React from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import { EVENT_STATUSES } from '@jebel/constants';

import { EventsCountQuery, EventsCountQueryVariables } from 'shared/graphql/__generated__';

import { EVENTS_COUNT_QUERY } from '../queries';

export const useEventsCount = () => {
  const currentDate = React.useMemo(() => DateTime.now(), []);
  const { loading, data, ...rest } = useQuery<EventsCountQuery, EventsCountQueryVariables>(
    EVENTS_COUNT_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        upcomingEventsFilter: {
          date: {
            gte: currentDate,
          },
          status: { equals: EVENT_STATUSES.active },
          isApproved: { equals: true },
        },
        pendingEventsFilter: {
          status: { equals: EVENT_STATUSES.pendingApproval },
        },
      },
    },
  );

  return {
    totalEventsCount: data?.totalEventsCount?.count,
    pendingEventsCount: data?.pendingEventsCount?.count,
    upcomingEventsCount: data?.upcomingEventsCount?.count,
    loading: loading && !data,
    ...rest,
  };
};
