import React, { useState } from 'react';
import styled from '@emotion/styled';

import { SORT } from '@jebel/constants';

import { GroupCreateModal } from 'features/groups/components/Groups/GroupCreateModal';
import { Button, Typography, Modal } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { useCrudPermissions, useModalState } from 'shared/hooks';

import { GroupDetailsModal } from './GroupDetailsModal';
import { GroupsSpreadSheet } from './GroupsSpreadSheet';
import { GroupAdminList } from './GroupAdminList';

const MembersContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 0;
`;

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export function GroupsContent() {
  const [currentDetailsId, setCurrentDetailsId] = useState('');
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isAdminsModalOpen, setIsAdminsModalOpen] = useState(false);

  const { show: showCreateModal, open: openCreateModal, close: closeCreateModal } = useModalState();

  const {
    groupsPermissions: { add: ifCreateAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const onAdminsModalOpen = (id: string) => {
    setIsAdminsModalOpen(true);
    setCurrentDetailsId(id);
  };

  const onAdminsModalClose = () => {
    setIsAdminsModalOpen(false);
  };

  const onDetailsModalOpen = (id: string) => {
    setIsDetailsModalOpen(true);
    setCurrentDetailsId(id);
  };
  const onDetailsModalClose = () => {
    setIsDetailsModalOpen(false);
  };

  return (
    <React.Fragment>
      <GroupCreateModal isOpen={showCreateModal} onClose={closeCreateModal} />

      <Modal
        dialogProps={{
          open: isAdminsModalOpen,
          onClose: onAdminsModalClose,
        }}
        titleProps={{ title: 'Group Admins' }}
        withPadding={false}
      >
        <SearchProvider>
          <SpreadsheetProvider>
            <GroupAdminList id={currentDetailsId} />
          </SpreadsheetProvider>
        </SearchProvider>
      </Modal>

      <GroupDetailsModal
        isOpen={isDetailsModalOpen}
        id={currentDetailsId}
        onClose={onDetailsModalClose}
      />

      <MembersContentLayout>
        <Header>
          <Typography variant="subtitle2" color="primary">
            Groups
          </Typography>
          {ifCreateAccessed && !loadingAddons && (
            <Button size="medium" variant="contained" color="primary" onClick={openCreateModal}>
              NEW GROUP
            </Button>
          )}
        </Header>

        <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
          <SearchProvider>
            <GroupsSpreadSheet
              onDetailsModalOpen={onDetailsModalOpen}
              onAdminsModalOpen={onAdminsModalOpen}
            />
          </SearchProvider>
        </SpreadsheetProvider>
      </MembersContentLayout>
    </React.Fragment>
  );
}
