import React from 'react';

import { useBusinessCountQuery } from 'shared/graphql/__generated__';

type ZipFilter = {
  zip?: string;
  distance?: string;
};

type DateFilter = {
  startDateIso: string;
  endDateIso: string;
};

type BusinessesCountHookProps = {
  zipFilter?: ZipFilter;
  dateFilter?: DateFilter;
};

export const useBusinessesCount = ({ zipFilter, dateFilter }: BusinessesCountHookProps) => {
  const startPointZip = zipFilter?.zip ?? '';
  const radius = zipFilter?.distance?.split(' ')[0] ?? '';

  const { loading, data, ...rest } = useBusinessCountQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filter: {
        AND: [
          { createdAt: { gte: dateFilter?.startDateIso } },
          { createdAt: { lte: dateFilter?.endDateIso } },
        ],
      },
      supporterFilter: {
        status: { equals: 'active' },

        schoolSupporter: {
          some: {
            status: { equals: 'active' },
          },
        },

        AND: [
          { createdAt: { gte: dateFilter?.startDateIso } },
          { createdAt: { lte: dateFilter?.endDateIso } },
        ],
      },
      radius,
      startPointZip,
    },
  });

  const businessesCount = React.useMemo(() => {
    return data?.businesses?.count ?? 0;
  }, [data?.businesses?.count]);

  const supportersCount = React.useMemo(() => {
    return data?.supporters?.count ?? 0;
  }, [data?.supporters?.count]);

  return {
    businessesCount,
    supportersCount,
    loading: loading && !data,
    ...rest,
  };
};
