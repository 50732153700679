import { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { SORT } from '@jebel/constants';

import { Button, Modal, Typography } from 'shared/components/ui';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { useCrudPermissions } from 'shared/hooks';
import { InviteMemberModal } from 'shared/components/dialogs/InviteModal/components/InviteMemberModal';

import { useMembersStats } from '../hooks';
import { MembersSpreadsheet } from './MembersSpreadsheet';
import { MembersStats } from './MembersStats';
import { SearchProvider } from '../../../shared/features/search';

const INVITE_BTN_HEIGHT = 36;

const inviteBtnCss = (theme: any) => css`
  height: ${INVITE_BTN_HEIGHT}px;
  background-color: ${theme.palette.primary.light};
  color: white;
`;

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export function MembersContent() {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { membersPermissions } = useCrudPermissions();
  const { membersStats, loading, refetchStats } = useMembersStats();

  const onModalClose = () => {
    setIsInviteModalOpen(false);
  };

  const onModalOpen = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <Fragment>
      <Modal
        dialogProps={{ open: isInviteModalOpen, onClose: onModalClose }}
        titleProps={{ title: 'Invite Members' }}
      >
        <InviteMemberModal
          onClose={onModalClose}
          onSuccessfulSubmit={refetchStats}
          isAdminInviting
        />
      </Modal>

      <Content>
        <Header>
          <Typography variant="subtitle2" color="primary">
            Members
          </Typography>

          {membersPermissions.add && (
            <Button css={inviteBtnCss} size="medium" variant="contained" onClick={onModalOpen}>
              Invite
            </Button>
          )}
        </Header>

        <MembersStats membersStats={membersStats} loading={loading} />

        <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
          <SearchProvider>
            <MembersSpreadsheet refetchStats={refetchStats} statsLoading={loading} />
          </SearchProvider>
        </SpreadsheetProvider>
      </Content>
    </Fragment>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 0;
  align-items: center;
`;
