import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  --home-feed-card-padding: 1.5rem;
  --home-feed-card-gap: 1rem;

  display: flex;
  // Preserve the aspect ratio of the card on grid layouts.
  // https://github.com/8base-services/jebel/issues/1433
  min-width: 0;
  flex-direction: column;
  padding: var(--home-feed-card-padding);
  gap: var(--home-feed-card-gap);
  background-color: ${p => p.theme.palette.background.paper};
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
`;

// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
