import { useMemo } from 'react';

import { extractHomeFeedItemMedia } from '@jebel/utils';

import { useHomeFeedCardData } from '../../providers';

/** Extract the media from the stringified JSON. */
export function useHomeFeedMedia() {
  const post = useHomeFeedCardData();

  return useMemo(() => extractHomeFeedItemMedia(post.media), [post.media]);
}
