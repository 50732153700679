import { SORT } from '@jebel/constants';

import type { SortFieldProps } from 'shared/features/search';

export const SCORECARD_SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Highest Ranking',
        value: { position: SORT.asc },
      },
      {
        label: 'Class Year Ascending',
        value: { year: SORT.asc },
      },
      {
        label: 'Class Year Descending',
        value: { year: SORT.desc },
      },
    ],
  },
};

export const SCORECARD_SORT_INFO_DEFAULT_OPTION = SCORECARD_SORT_INFO.sortInfo?.options[0].value;
