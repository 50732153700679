import { gql } from '@apollo/client';

export const LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT = gql`
  fragment LegacyFeedPostsListItem on LegacyFeedPost {
    id
    createdAt
    updatedAt
    text
    isPinned
    commentsAllowed
    postDate
    legacyDate
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }

    media {
      items {
        id
        meta
        downloadUrl
        fileId
        filename
      }
    }
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
  }
`;

const LEGACY_FEED_YEARS_INFO_FRAGMENT = gql`
  fragment LegacyFeedMonthsInfo on LegacyFeedMonth {
    id
    month
    legacyYears {
      id
      legacyYear
    }
  }
  fragment LegacyFeedYearsInfo on LegacyFeedYear {
    id
    legacyYear
    legacyMonths {
      items {
        id
        month
      }
    }
  }
`;

/** @deprecated */
export const LEGACY_FEED_YEARS_LIST_QUERY = gql`
  query LegacyFeedYearsList(
    $first: Int
    $skip: Int
    $filter: LegacyFeedPostFilter!
    $sort: [LegacyFeedPostSort!]
    $yearsSort: [LegacyFeedYearSort!]
    $monthsSort: [LegacyFeedMonthSort!]
  ) {
    legacyFeedYearsList(sort: $yearsSort) {
      items {
        id
        legacyYear
        legacyMonths(sort: $monthsSort) {
          items {
            id
            month
            posts(first: $first, filter: $filter, sort: $sort, skip: $skip) {
              items {
                ...LegacyFeedPostsListItem
              }
            }
          }
        }
      }
    }
  }
  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_YEARS_QUERY = gql`
  query LegacyTimelineYears(
    $filter: LegacyFeedYearFilter
    $first: Int
    $skip: Int
    $sort: [LegacyFeedYearSort!]
  ) {
    years: legacyFeedYearsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        year: legacyYear
      }
    }
  }
`;

export const LEGACY_MONTHS_QUERY = gql`
  query LegacyTimelineMonths(
    $filter: LegacyFeedMonthFilter
    $first: Int
    $skip: Int
    $sort: [LegacyFeedMonthSort!]
  ) {
    months: legacyFeedMonthsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        month
      }
    }
  }
`;

export const LEGACY_TIMELINE_POST_FRAGMENT = gql`
  fragment LegacyTimelinePost on LegacyFeedPost {
    id
    text
    legacyDate
    postDate: createdAt

    media {
      items {
        downloadUrl
      }
    }
  }
`;

export const LEGACY_FEED_POSTS_QUERY = gql`
  query LegacyTimelinePosts(
    $filter: LegacyFeedPostFilter!
    $first: Int
    $skip: Int
    $sort: [LegacyFeedPostSort!]
  ) {
    feed: legacyFeedPostsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...LegacyTimelinePost
      }
    }
  }

  ${LEGACY_TIMELINE_POST_FRAGMENT}
`;

export const LEGACY_FEED_POST_CREATE_MUTATION = gql`
  mutation LegacyFeedPostCreate($data: LegacyFeedPostCreateInput!) {
    legacyFeedPostCreate(data: $data) {
      ...LegacyFeedPostsListItem
    }
  }

  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_TIMESTAMPS_QUERY = gql`
  query LegacyTimeStamps {
    years: legacyFeedYearsList {
      items {
        id
        year: legacyYear

        months: legacyMonths {
          items {
            id
            month
          }
        }
      }
    }
  }

  ${LEGACY_FEED_YEARS_INFO_FRAGMENT}
`;

export const LEGACY_FEED_MONTHS_LIST_QUERY = gql`
  query LegacyFeedMonthsList(
    $first: Int!
    $skip: Int
    $yearFilter: LegacyFeedYearFilter!
    $postFilter: LegacyFeedPostFilter!
    $sort: [LegacyFeedPostSort!]
    $monthsSort: [LegacyFeedMonthSort!]
  ) {
    legacyFeedYearsList(filter: $yearFilter) {
      items {
        id
        legacyYear
        legacyMonths(sort: $monthsSort) {
          items {
            id
            month
            posts(first: $first, filter: $postFilter, sort: $sort, skip: $skip) {
              items {
                ...LegacyFeedPostsListItem
              }
            }
          }
        }
      }
    }
  }
  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_FEED_POST_UPDATE_MUTATION = gql`
  mutation LegacyFeedPostUpdate($data: LegacyFeedPostUpdateInput!, $id: ID!) {
    legacyFeedPostUpdate(data: $data, filter: { id: $id }) {
      ...LegacyFeedPostsListItem
    }
  }
  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_FEED_POSTS_LIST_QUERY = gql`
  query LegacyFeedPostsList(
    $first: Int!
    $skip: Int
    $sort: [LegacyFeedPostSort!]
    $postsFilter: LegacyFeedPostFilter!
  ) {
    legacyFeedPostsList(first: $first, skip: $skip, sort: $sort, filter: $postsFilter) {
      items {
        ...LegacyFeedPostsListItem
      }
    }
  }
  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_FEED_POSTS_LIST_COUNT_QUERY = gql`
  query LegacyFeedPostsListCount($postsFilter: LegacyFeedPostFilter!) {
    legacyFeedPostsList(filter: $postsFilter) {
      count
    }
  }
`;
