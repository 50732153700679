import { captureException, addBreadcrumb, setUser } from '@sentry/browser';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const IS_VERBOSE = process.env.REACT_APP_VERBOSITY === 'VERBOSE';

/** Set the current user in session. */
export function recordUser(email: string) {
  setUser({ email });
}

/** Reset the current user in session. */
export function stopRecordUser() {
  setUser(null);
}

/** Sent additional information to follow an issue. */
export function recordDebug(message: string, data?: Record<string, unknown>) {
  if (IS_VERBOSE) {
    // eslint-disable-next-line no-console
    console.debug(message);
  }

  if (IS_PRODUCTION) {
    addBreadcrumb({
      type: 'debug',
      category: 'console',
      level: 'debug',
      message,
      data,
    });
  }
}

/** Record the error as message. */
export function recordMessage(message: string) {
  if (IS_VERBOSE) {
    // eslint-disable-next-line no-console
    console.info(message);
  }

  if (IS_PRODUCTION) {
    addBreadcrumb({
      type: 'debug',
      category: 'console',
      level: 'info',
      message,
    });
  }
}

/** Record the error as breadcrumb. */
export function recordError(err: unknown) {
  if (IS_VERBOSE) {
    console.error(err);
  }

  if (!IS_PRODUCTION) {
    return;
  }

  let message: string | undefined;

  if (err instanceof Error) {
    message = err.message;
  }

  addBreadcrumb({
    type: 'debug',
    category: 'console',
    level: 'error',
    message,
  });
}

/** Send the error to the logger and return an ID of the transaction. */
export function recordException(err?: unknown) {
  if (IS_PRODUCTION) {
    return captureException(err);
  }

  return crypto.randomUUID();
}
