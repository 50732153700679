import { useMemo } from 'react';
import { useCurrentUser } from './useCurrentUser';
import { RoleAddonInfoFragment } from 'shared/graphql';
import { RoleAddonPermission, RoleAddonPermissions } from 'shared/types';

const DEFAULT_PERMISSION: RoleAddonPermission = {
  add: false,
  edit: false,
  delete: false,
};

const DEFAULT_PERMISSIONS: RoleAddonPermissions = {
  homePostsPermissions: DEFAULT_PERMISSION,
  schoolPostsPermissions: DEFAULT_PERMISSION,
  membersPermissions: DEFAULT_PERMISSION,
  businessPermissions: DEFAULT_PERMISSION,
  forumPermissions: DEFAULT_PERMISSION,
  groupsPermissions: DEFAULT_PERMISSION,
};

function calculatePermissions(roles: RoleAddonInfoFragment[]): RoleAddonPermissions {
  const permissions: RoleAddonPermissions = { ...DEFAULT_PERMISSIONS };

  for (const role of roles) {
    const keys = Object.keys(DEFAULT_PERMISSIONS);

    for (const key of keys) {
      const newest = role[key];
      const curr = permissions[key];

      permissions[key] = {
        add: newest?.add || curr.add,
        edit: newest?.edit || curr.edit,
        delete: newest?.delete || curr.delete,
      };
    }
  }

  return permissions;
}

/**
 * Custom hook that retrieves the current user's permissions.
 * @returns An object containing the current user's permissions.
 */
export function useCurrentUserPermissions() {
  const { user, loading } = useCurrentUser();

  const permissions: RoleAddonPermissions = useMemo(() => {
    const roles = user?.rolesAddons?.items ?? [];
    return calculatePermissions(roles);
  }, [user?.rolesAddons?.items]);

  return {
    /**
     * Current user permissions normalized.
     */
    permissions,

    loading,
  };
}

/**
 * A function that takes the current user's permissions and returns a boolean value indicating whether the permission is selected or not.
 */
export type PermissionSelector = (permissions: RoleAddonPermissions) => boolean;

/**
 * Determines if the current user has permission to add home posts.
 * @returns `true` if the user has permission to add home posts, `false` otherwise.
 */
export const CAN_ADD_HOME_POSTS: PermissionSelector = p => p.homePostsPermissions.add;

/**
 * Determines if the current user has permission to edit home posts.
 * @returns `true` if the user has permission to edit home posts, `false` otherwise.
 */
export const CAN_EDIT_HOME_POSTS: PermissionSelector = p => p.homePostsPermissions.edit;

/**
 * Determines if the current user has permission to delete home posts.
 * @returns `true` if the user has permission to delete home posts, `false` otherwise.
 */
export const CAN_DELETE_HOME_POSTS: PermissionSelector = p => p.homePostsPermissions.delete;

/**
 * A custom hook that selects a specific permission from the current user's permissions.
 * @param selector - A function that takes the current user's permissions and returns a boolean value indicating whether the permission is selected or not.
 * @returns A memoized boolean value indicating whether the selected permission is granted or not.
 */
export function useCurrentUserPermissionSelector(selector: PermissionSelector): boolean {
  const { permissions } = useCurrentUserPermissions();

  return useMemo(() => selector(permissions), [selector]);
}
