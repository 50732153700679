/**
 * Transform the given `text` from `camelCase`, `snake_case` and `PascalCase` to readable text.
 * @param text Given text on `camelCase`, `snake_case` or `PascalCase` format.
 * @returns Readable text transforming the given `text`.
 */
export function humanizeText(text) {
    return text
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/^(\w+)([A-Z])/g, '$1 $2')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]|\s\w/g, selected => selected.toUpperCase());
}
/**
 * Hidden overflow `text` based on the given `size`.
 * @param text Given `text` overflowed.
 * @returns Hidden `text` when overflowed.
 * @example "Hello wor..."
 */
export function overlapText(text, options) {
    var _a, _b;
    const separator = (_a = options === null || options === void 0 ? void 0 : options.separator) !== null && _a !== void 0 ? _a : '...';
    const size = (_b = options === null || options === void 0 ? void 0 : options.size) !== null && _b !== void 0 ? _b : 20;
    if (text.length < size) {
        return text;
    }
    return text.slice(0, size) + separator;
}
