import { ChangeEvent, useCallback, useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { debounce } from 'lodash';
import { Close } from '@material-ui/icons';

import { TextField, Icon, TextFieldProps } from 'shared/components/ui';
import { useSearchContext, useTrendingSearchesCreate } from 'shared/features/search';

export type SearchTextFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  /**
   * Allow to manage the trending search report strategy.
   * By default will always send any search to the server.
   * @default true
   */
  trackSearches?: boolean;
};

const DEBOUNCE_TIME = 500;

export function SearchTextField({ trackSearches = true, ...props }: SearchTextFieldProps) {
  const { searchQuery: search, setSearchQuery, setIsUserTyping } = useSearchContext();
  const { onAddSearchingWord } = useTrendingSearchesCreate();

  const [value, setValue] = useState(() => search);

  const fetchSearchResults = async (text: string) => {
    setSearchQuery(text);
    setIsUserTyping(false);

    if (trackSearches && text) {
      await onAddSearchingWord({ wordOrPhrase: text });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSearchDebounced = useCallback(
    debounce(fetchSearchResults, DEBOUNCE_TIME, { leading: false, trailing: true }),
    [],
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const given = event.target.value ?? '';

    setValue(given);

    if (!given) {
      fetchSearchDebounced.cancel();
    }

    setIsUserTyping(given.length > 0);
    fetchSearchDebounced(given);
  };

  const cleanSearch = () => {
    fetchSearchDebounced.cancel();

    setValue('');
    setSearchQuery('');
    setIsUserTyping(false);
  };

  return (
    <TextField
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              name="Search"
              variant="filled"
              color="inherit"
              fontSize="default"
              viewBox="0 0 24 24"
            />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton disabled={!value} size="small" onClick={cleanSearch}>
            <Close fontSize="small" />
          </IconButton>
        ),
        ...props.InputProps,
      }}
      {...props}
      disabled={false}
      value={value}
      onChange={onChange}
    />
  );
}
