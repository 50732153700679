import React from 'react';
import { css } from '@emotion/react';
import { FormControl, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import _ from 'lodash';

import { getIsInvalid, Typography } from 'shared/components/ui';

import type { RenderTag, DataBaseMultiSelectFieldProps } from './interface';

// When you will use initialValues add initialValuesEqual={() => true} property to Form component
// <Form initialValue={initialValues} initialValuesEqual={() => true} />

const inputCss = isError => theme =>
  css`
    & fieldset {
      border: 1px solid ${isError ? '#f44336' : 'rgba(0, 0, 0, 0.23)'};
    }

    & input {
      color: ${isError ? '#f44336' : 'rgb(33, 33, 33)'};

      ::placeholder {
        opacity: ${isError ? '1' : 'rgb(33, 33, 33)'};
      }
    }
    border-radius: ${theme.spacing(0.5)};
  `;

/** @deprecated Use an instance of `FormSelectDataSource` (like `FormSelectUser`, `FormSelectOrganization`, ...) instead. */
const DataBaseMultiSelectField: React.FC<DataBaseMultiSelectFieldProps> = ({
  form,
  input,
  meta,
  label,
  options = [],
  onSearch,
  renderTag,
  renderOption,
  placeholder = 'Type to search',
  fullWidth = false,
  disabled = false,
  required = false,
  loading = false,
  shouldAcceptNonExistingValues = false,
  ...multiSelectProps
}) => {
  const { name, value: fieldValue, onBlur, onChange: onChangeField } = input;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(() => fieldValue || []);
  const [inputValue, setInputValue] = React.useState<string>('');

  const isInvalid = getIsInvalid({ meta, form });
  const errorText = isInvalid && meta.error;

  const onChangeValue = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
      setValue(newValue);
      form.setFieldValue(name, newValue);
    },
    [form, name],
  );

  React.useEffect(() => {
    if (fieldValue) {
      setValue(fieldValue);
    }
  }, [fieldValue]);

  const optionsToRender = React.useMemo(() => {
    if (!options) return [];

    const valueIds = value.map((item: any) => {
      return item?.id;
    });
    return !shouldAcceptNonExistingValues
      ? options.filter((option: any) => !valueIds.includes(option.id))
      : options;
  }, [options, shouldAcceptNonExistingValues, value]);

  const renderTags = (options: Array<any>, getTagProps: AutocompleteRenderGetTagProps) => {
    if (!_.isFunction(renderTag)) {
      return undefined;
    }

    const renderTagFn = renderTag as RenderTag;
    return options.map((option: any, index: number) => {
      const { key, ...tagProps } = getTagProps({ index });
      const tagComponent = renderTagFn(option, index) as any;
      if (_.isNil(tagComponent)) {
        return null;
      }

      const tagComponentProps = tagComponent?.props;

      return React.cloneElement(tagComponent, {
        ...tagProps,
        ...tagComponentProps,
      });
    });
  };

  const onInputChange = (event: any, inputValue: string) => {
    setInputValue(inputValue);
    onSearch(inputValue);
  };

  return (
    <FormControl variant="outlined" error={errorText} fullWidth={fullWidth} required={required}>
      <Autocomplete
        multiple
        options={optionsToRender}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onBlur={onBlur}
        value={value}
        onChange={onChangeValue}
        inputValue={inputValue}
        onInputChange={onInputChange}
        renderTags={renderTags}
        disabled={disabled}
        renderInput={params => (
          <TextField
            css={inputCss(errorText)}
            name={name}
            {...params}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...multiSelectProps}
      />
      {errorText && (
        <Typography
          style={{ margin: '0 14px', fontSize: '0.75rem' }}
          variant="body2"
          color="highlight"
        >
          {errorText}
        </Typography>
      )}
    </FormControl>
  );
};

export { DataBaseMultiSelectField };
