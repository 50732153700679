import { useSchoolConfiguration } from 'shared/hooks';

import { AuthorNameLink } from './AuthorNameLink';

interface Props {
  className?: string;
}

export function SchoolNameLink(props: Props) {
  const { configuration: school } = useSchoolConfiguration();

  const name = school?.name;

  return <AuthorNameLink className={props.className} name={name} />;
}
