import { Theme } from '@emotion/react';
import {
  EChartsOption as ChartOptions,
  PieSeriesOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import { darken } from '@material-ui/core';

import { DountChartProps } from './DountChart.types';

/**
 * Create the options based on the given `props` and `theme` for a donut chart.
 * @param props Properties given to the component.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Instance of `ChartOptions` useful with `echarts`.
 */
export function createDountChartOptions(props: DountChartProps, theme: Theme): ChartOptions {
  const { textStyle } = createChartTheme(theme);

  const data = props.data.map((series, index) => {
    const { itemStyle } = createSeriesTheme(props, index, theme);

    return {
      value: series.value,
      name: series.label,
      itemStyle,
    };
  });

  const options: ChartOptions = {
    textStyle,

    tooltip: {
      trigger: 'item',
      show: props.showTooltip ?? false,

      formatter(params: TooltipComponentFormatterCallbackParams) {
        const options = Array.isArray(params) ? params[0] : params;
        const data = props.data[options.dataIndex];

        if (!data) {
          return '(None)';
        }

        const label = props.formatLabel?.(data.label) ?? data.label;
        const value = data.legend ?? props.formatValue?.(data.value) ?? String(data.value);

        return data.legend ?? `<b>${label}</b><br/>${value}`;
      },
    },

    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        data,

        label: {
          show: false,
        },

        labelLine: {
          show: true,
        },
      },
    ],
  };

  return options;
}

/**
 * Create some variables to customize the chart.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Useful variables to customize the chart.
 */
function createChartTheme(theme: Theme) {
  const color = theme.palette.secondary.main;

  const fontFamily = theme.typography.fontFamily;
  const fontSize = theme.typography.fontSize;

  const textStyle: ChartOptions['textStyle'] = {
    fontFamily: theme.typography.fontFamily,
    fontSize,
  };

  return {
    color,
    fontSize,
    fontFamily,
    textStyle,
  };
}

/**
 * Create some variables to customize the series styles.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Useful variables to customize the series.
 */
function createSeriesTheme(props: DountChartProps, index: number, theme: Theme) {
  const series = props.data[index];

  const palette = darken(theme.palette.secondary.main, index / props.data.length);
  const color = series?.color ?? palette;

  const itemStyle: PieSeriesOption['itemStyle'] = {
    color,
  };

  return {
    color,
    itemStyle,
  };
}
