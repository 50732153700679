import { CircularProgress } from '@material-ui/core';
import { ReactNode } from 'react';

import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { Container, Content, Message, MessageContainer } from './LegacyTimeline.styles';

type TimelineGenerator<T> = (data: T, index: number) => ReactNode;

interface Props<T> {
  data: T[];

  loading: boolean;
  refreshing: boolean;
  hasMore: boolean;

  children: TimelineGenerator<T>;

  onFetchMore(): void;
}

export function LegacyTimeline<T>(props: Props<T>) {
  if (props.loading) {
    return (
      <MessageContainer>
        <CircularProgress size={14} color="inherit" />
        <Message>Loading timeline...</Message>
      </MessageContainer>
    );
  }

  if (props.data.length === 0) {
    return (
      <MessageContainer>
        <Message>No posts found</Message>
      </MessageContainer>
    );
  }

  return (
    <Container>
      <InfiniteScroll
        fetching={props.refreshing}
        hasMore={props.hasMore}
        fetchMore={props.onFetchMore}
      >
        <Content>{props.data.map(props.children)}</Content>
      </InfiniteScroll>
    </Container>
  );
}
