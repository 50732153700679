import { useMemo } from 'react';

import { DISCOUNT_STATUSES } from '@jebel/constants';

import { ChooseStatusChip, ChooseStatusChipOption } from 'shared/components/ui';

interface Props {
  status: string;
  options?: ChooseStatusChipOption[];
}

const DISCOUNT_STATUS_COLORS = {
  [DISCOUNT_STATUSES.active]: '#01B9A1',
  [DISCOUNT_STATUSES.pending]: '#EF5C5C',
  [DISCOUNT_STATUSES.inactive]: '#616DEE',
  [DISCOUNT_STATUSES.rejected]: '#162D46',
  [DISCOUNT_STATUSES.expired]: '#FF782A',
};

const DISCOUNT_STATUS_LABEL = {
  [DISCOUNT_STATUSES.active]: 'Active',
  [DISCOUNT_STATUSES.pending]: 'Pending Approval',
  [DISCOUNT_STATUSES.inactive]: 'Inactive',
  [DISCOUNT_STATUSES.rejected]: 'Rejected',
  [DISCOUNT_STATUSES.expired]: 'Expired',
};

export function ChooseDiscountStatusChip(props: Props) {
  const color = useMemo(() => {
    return DISCOUNT_STATUS_COLORS[props.status];
  }, [props.status]);

  const label = useMemo(() => {
    return DISCOUNT_STATUS_LABEL[props.status];
  }, [props.status]);

  return <ChooseStatusChip label={label} color={color} options={props.options} />;
}
