import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Mention, MentionsInput } from 'react-mentions';
import { MenuList, Paper } from '@material-ui/core';

interface WrapperProps {
  multiline?: boolean;
  fullWidth?: boolean;
}

export const Wrapper = styled(MentionsInput)<WrapperProps>(props => {
  const height = props.multiline ? '12rem' : '3.5rem';

  return css`
    z-index: 3;

    &__control {
      min-height: ${height};
      font-size: 1rem;

      ${props.fullWidth &&
      css`
        width: 100%;
        flex: 1;
      `}
    }

    &__input,
    &__highlighter {
      height: 100%;
      padding: 0.8rem;
      line-height: 1.8;
      word-break: break-word;
    }

    &__input {
      border: solid 1px;
      border-color: ${props.theme.palette.divider};
      border-radius: 0.25rem;
      outline: none;

      &:hover {
        border-color: ${props.theme.palette.action.active};
      }

      &:foucs {
        border-color: ${props.theme.palette.action.focus};
      }
    }

    &__suggestions {
      width: max-content;
      max-height: 20rem;
      background-color: ${props.theme.palette.background.paper};
      z-index: 3;
    }
  `;
});

export interface MentionDataSourceProps {
  color?: string;
}

export const MentionDataSource = styled(Mention)<MentionDataSourceProps>(props => {
  const textColor = props.color ?? props.theme.palette.secondary.main;
  const backgroundColor = props.theme.palette.background.default;
  const textShadowSize = '1px';

  return css`
    position: relative;
    color: ${textColor};
    z-index: 1;
    text-shadow: ${textShadowSize} ${textShadowSize} ${textShadowSize} ${backgroundColor},
      ${textShadowSize} -${textShadowSize} ${textShadowSize} ${backgroundColor},
      -${textShadowSize} ${textShadowSize} ${textShadowSize} ${backgroundColor},
      -${textShadowSize} -${textShadowSize} ${textShadowSize} ${backgroundColor};
  `;
});

export const MentionList = styled(MenuList)`
  max-width: 70vw;
  max-height: 20rem;
  overflow: auto;
`;

export const MentionPaper = styled(Paper)``;
