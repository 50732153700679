import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Modal, Typography } from 'shared/components/ui';
import { EventInfoFragment } from 'shared/graphql/__generated__';
import { getFullMonthAndWeekdayAndTime } from 'shared/utils/date';
import { EventInfoModal } from 'features/events/components/Events/EventInfoModal';

const AVATAR_ICON_SIZE = 80;
const DEFAULT_TIMEZONE = 'CST';

const cardIconCss = theme => css`
  width: ${AVATAR_ICON_SIZE}px;
  height: ${AVATAR_ICON_SIZE}px;
  margin-right: ${theme.spacing(3)}px;
  border-radius: ${theme.spacing(0.5)}px;
  border: 1px solid ${theme.palette.border};
`;

const cardTitleBoxCss = theme => css`
  display: flex;
  flex-direction: column;
`;

const cardTitleCss = theme => css`
  color: ${theme.palette.primary.dark};
  font-weight: ${theme.typography.fontWeightRegular};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const cardBodyCss = theme => css`
  font-weight: 500;
`;

const cardCss = theme => css`
  cursor: pointer;
  user-select: none;

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2)}px;
  }
`;

export const EventCard = ({ event }: { event: EventInfoFragment }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onClose = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onEventOpen = React.useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return (
    <React.Fragment>
      <Modal
        dialogProps={{
          open: isModalOpen,
          onClose,
          fullWidth: true,
        }}
        titleProps={{ title: event?.title ?? 'Title is not found' }}
        isVisibleDivider={false}
      >
        <EventInfoModal eventId={event?.id} onClose={onClose} />
      </Modal>
      <Box display="flex" css={cardCss} onClick={onEventOpen}>
        <Avatar
          css={cardIconCss}
          srcSet={processFilestackUrlSrcSet(event?.images?.items[0]?.downloadUrl || '', {
            compress: true,
            resize: {
              width: AVATAR_ICON_SIZE,
              height: AVATAR_ICON_SIZE,
              fit: 'crop',
            },
          })}
          src={event?.images?.items[0]?.downloadUrl || ''}
          variant="square"
        />
        <Box css={cardTitleBoxCss}>
          <Typography variant="subtitle5" css={cardTitleCss}>
            {event?.title}
          </Typography>
          <Typography color="textSecondary" variant="body2" css={cardBodyCss}>
            Date/Time:{' '}
            {getFullMonthAndWeekdayAndTime(event?.date, event?.timezone || DEFAULT_TIMEZONE)}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
