import React from 'react';
import { css } from '@emotion/react';
import { Typography, Divider, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { Box, Button, Modal } from 'shared/components/ui';
import { aboutJebelLink } from 'shared/constants';

const topContainerCSS = theme => css`
  display: grid;
  grid-gap: 20px;
  padding-top: ${theme.spacing(0.825)}px;
  padding-bottom: ${theme.spacing(0.825)};

  ${theme.breakpoints.down('sm')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const topBoxCSS = theme => css`
  display: grid;
  grid-gap: 40px;

  ${theme.breakpoints.down('sm')} {
    grid-gap: 20px;
  }
  @media (max-width: 330px) {
    grid-gap: 10px;
  }
`;

const logoImageCSS = theme => css`
  height: 59px;
  width: 240px;

  ${theme.breakpoints.down('sm')} {
    width: 146px;
    height: 36px;
  }
  @media (max-width: 330px) {
    width: 120px;
    height: 30px;
  }
`;

const bodyBoxCSS = css`
  display: grid;
  grid-gap: 10px;
`;

const descriptionCSS = css`
  letter-spacing: 0.07px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
`;

const buttonBoxCSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 20px;
`;

const mainAppointmentCSS = (theme: any) => css`
  letter-spacing: -0.1px;
  color: ${theme.palette.primary.dark};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const baseButtonCss = theme => css`
  letter-spacing: 1px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 500;

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(0.75)}px ${theme.spacing(1.125)}px;
  }
`;

const learnMoreButtonCSS = theme => css`
  ${baseButtonCss(theme)};
`;

const cancelButtonCSS = (theme: any) => css`
  ${baseButtonCss(theme)};
  color: ${theme.palette.text.secondary};
`;

export const AboutModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const onCancel = () => {
    onClose();
  };

  const openJebelInfo = () => {
    window.open(aboutJebelLink);
    onClose();
  };

  return (
    <Modal
      dialogProps={{ maxWidth: 'xs', onClose, fullWidth: true, open: isOpen }}
      isVisibleDivider
      dialogContentWidth={280}
      titleProps={{ title: 'About' }}
    >
      <Box css={topContainerCSS}>
        <Box css={topBoxCSS}>
          <img
            css={logoImageCSS}
            src="https://cdn.filestackcontent.com/security=policy:eyJjYWxsIjpbInJlYWQiLCJjb252ZXJ0Il0sImV4cGlyeSI6MjUyNDYwODAwMCwiaGFuZGxlIjoiWkhETzR0cmdSdGVXd3VKT2VIcDUifQ==,signature:3f97082f76578cbce635030a4198f2fd5e4f78d663286ff95dace68439f77ecc/ZHDO4trgRteWwuJOeHp5"
          />
          <Box css={bodyBoxCSS}>
            <Typography
              css={mainAppointmentCSS}
              variant="inherit"
              align="inherit"
              color="initial"
              paragraph={false}
            >
              Connecting Schools, Alumni, and their Communities
            </Typography>
            <Typography
              css={descriptionCSS}
              variant="inherit"
              align="inherit"
              color="initial"
              paragraph={false}
            >
              Jebel is a private, individually-branded, micro-social digital platform built by
              Christopher Columbus High School Alumni to connect local schools with their
              communities. We are focused on transforming your existing communications efforts into
              a more powerful social engagement model which also leverages the power of a national,
              multi-school network to financially benefit all participants.
            </Typography>
          </Box>
        </Box>
        <Box css={buttonBoxCSS}>
          <Button
            css={cancelButtonCSS}
            onClick={onCancel}
            color="primary"
            disabled={false}
            loading={false}
            size="medium"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            css={learnMoreButtonCSS}
            onClick={openJebelInfo}
            color="primary"
            disabled={false}
            loading={false}
            size="medium"
            variant="contained"
          >
            Learn more
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
