import { GROUP_STATUSES, SORT } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import {
  GroupFilter,
  GroupsListItemFragment,
  UserFilter,
  useGroupsListLazyQuery,
} from 'shared/graphql';

import {
  FormSelectDataSource,
  FormSelectDataSourceOption,
  FormSelectDataSourceOptionText,
  FormSelectDataSourceDefinitionProps,
  FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT,
} from '../FormSelectDataSource';
import { useCurrentUser } from 'shared/hooks';

type BasicProps = FormSelectDataSourceDefinitionProps<GroupsListItemFragment>;

type Props = BasicProps & {
  /**
   * Filter the groups by "status".
   * @default false
   */
  onlyActive?: boolean;

  /**
   * Filter the groups where you belongs.
   * @default false
   */
  onlyMember?: boolean;
};

export function FormSelectGroup(props: Props) {
  const [fetchGroups] = useGroupsListLazyQuery();

  const { user } = useCurrentUser();

  const fetchOptions = async (search: string) => {
    const isSuggestions = search.length === 0;

    const filter = createFilterBuilder<GroupFilter>({
      OR: [{ title: { contains: search } }, { description: { contains: search } }],
    });

    if (props.onlyActive) {
      filter.and({ status: { equals: 'active' } });
    }

    if (props.onlyMember) {
      const filterMember = createFilterBuilder<GroupFilter>();

      const filterUser: UserFilter = { id: { equals: user?.id } };

      filterMember.or({ members: { some: filterUser } });

      filterMember.or({
        groupToJoin: {
          some: {
            status: { equals: GROUP_STATUSES.active },
            user: filterUser,
          },
        },
      });

      filter.and(filterMember);
    }

    const response = await fetchGroups({
      variables: {
        first: isSuggestions ? FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT : undefined,
        sort: [{ title: SORT.asc }],
        filter: filter.build(),
      },
    });

    return response.data?.groupsList.items ?? [];
  };

  const extractIdentifier = (option: GroupsListItemFragment) => {
    return option.id as string;
  };

  const extractLabel = (option: GroupsListItemFragment) => {
    return option?.title ?? '(Group)';
  };

  return (
    <FormSelectDataSource<GroupsListItemFragment>
      {...props}
      extractIdentifier={extractIdentifier}
      extractLabel={extractLabel}
      fetchOptions={fetchOptions}
    >
      {(params, option) => (
        <FormSelectDataSourceOption {...params} key={option?.id}>
          <FormSelectDataSourceOptionText>{option?.title}</FormSelectDataSourceOptionText>

          {option?.description && (
            <FormSelectDataSourceOptionText isSmall>
              {option?.description}
            </FormSelectDataSourceOptionText>
          )}
        </FormSelectDataSourceOption>
      )}
    </FormSelectDataSource>
  );
}
