import { TextColor, MuiTheme, BackgroundColor } from './types';

export const getTextColor = (color: TextColor, theme: MuiTheme) => {
  const colorPaletteMap = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    highlight: theme.palette.secondary.light,
    textPrimary: theme.palette.text.primary,
    textSecondary: theme.palette.text.secondary,
  };

  return color === 'inherit' ? color : colorPaletteMap[color];
};

export const getBackgroundColor = (color: BackgroundColor, theme: MuiTheme) => {
  const colorPaletteMap = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
  };

  return color === 'inherit' || color === 'transparent' ? color : colorPaletteMap[color];
};
