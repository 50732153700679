import React from 'react';

import { SORT } from '@jebel/constants';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';

import { ReportsContent } from '../components';
import { SORT_TYPES } from '../constants';

const FALLBACK_SORT = {
  order: SORT.desc,
  headcellId: SORT_TYPES.postDate,
  sort: {
    createdAt: SORT.desc,
  },
};

export const ReportsPage: React.FC = () => {
  return (
    <SearchProvider>
      <RouteLayout
        content={
          <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
            <ReportsContent />
          </SpreadsheetProvider>
        }
      />
    </SearchProvider>
  );
};
