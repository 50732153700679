import { forwardRef, useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Box } from '@material-ui/core';
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
  KeyboardTimePicker as MuiKeyboardTimePicker,
  KeyboardTimePickerProps as MuiKeyboardTimePickerProps,
} from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { useResponsive } from 'shared/hooks';

import { ComponentDataProps } from '../../types';

export type KeyboardDateTimePickerProps = ComponentDataProps &
  Omit<MuiKeyboardDatePickerProps, 'value' | 'initialFocusedDateTime'> &
  Omit<MuiKeyboardTimePickerProps, 'value' | 'initialFocusedDateTime'> & {
    initialFocusedDateTime?: string;
    value: DateTime | null;
    css?: (theme: any) => SerializedStyles | string;
    timeLabel?: string;
    fieldName?: string;
  };

const dateTimeBoxCss = (isMobile: boolean) => css`
  display: grid;
  ${!isMobile
    ? `grid-template-columns: 1fr 1fr; grid-column-gap: 10px;`
    : `grid-template-rows: 1fr 1fr; grid-row-gap: 15px;`}
`;

export const KeyboardDateTimePicker = forwardRef<HTMLDivElement, KeyboardDateTimePickerProps>(
  (
    {
      onChange,
      value,
      initialFocusedDate,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      fieldName,
      ...props
    },
    ref,
  ) => {
    const DatePickerProps = useMemo(
      () => ({ ref, style: props.style, css: props.css, ...props.InputProps }),
      [props.InputProps, props.style, props.css, ref],
    );

    const TimePickerProps = useMemo(
      () => ({ ref, style: props.style, css: props.css, ...props.InputProps }),
      [props.InputProps, props.style, props.css, ref],
    );

    const onChangeDate = (data: MaterialUiPickersDate) => {
      const datetime = value?.isValid ? value : DateTime.now();

      if (datetime instanceof DateTime && data?.isValid) {
        onChange(
          datetime.set({
            day: data?.day,
            month: data?.month,
            year: data?.year,
          }),
        );
      }
    };

    const onChangeTime = (data: MaterialUiPickersDate) => {
      const datetime = value?.isValid ? value : DateTime.now();

      if (datetime instanceof DateTime && data?.isValid) {
        onChange(
          datetime.set({
            hour: data?.hour,
            minute: data?.minute,
          }),
        );
      }
    };

    const { isMobile } = useResponsive();

    return (
      <Box css={dateTimeBoxCss(isMobile)}>
        <MuiKeyboardDatePicker
          {...props}
          InputProps={DatePickerProps}
          onChange={onChangeDate}
          value={value}
          format="MM/dd/yyyy"
          label={props.label}
          data-test={dataTest}
          data-node-id={dataNodeID}
          data-node-render-path={dataRenderPath}
          autoOk
        />

        <MuiKeyboardTimePicker
          {...props}
          InputProps={TimePickerProps}
          onChange={onChangeTime}
          value={value}
          format="HH:mm"
          label={props.timeLabel || props.label}
          data-test={dataTest}
          data-node-id={dataNodeID}
          data-node-render-path={dataRenderPath}
        />
      </Box>
    );
  },
);
