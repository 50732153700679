import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { GroupDeleteMutation, GroupDeleteMutationVariables } from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { GROUP_DELETE_MUTATION } from '../queries';

export const useGroupDelete = () => {
  const [groupDelete] = useMutation<GroupDeleteMutation, GroupDeleteMutationVariables>(
    GROUP_DELETE_MUTATION,
    {
      refetchQueries: ['GroupSearch'],
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your Group has been Deleted',
        [SNACKBAR_ERROR_MESSAGE]: `Error: Your group hasn't been deleted. Try again.`,
      },
    },
  );

  const onGroupDelete = React.useCallback(
    (id: string) => {
      try {
        groupDelete({
          variables: {
            filter: {
              id,
            },
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [groupDelete],
  );

  return {
    onGroupDelete,
  };
};
