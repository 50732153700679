import { useMemo } from 'react';
import { MenuItem, useTheme } from '@mui/material';

import { generateGraduatingYearsOptions } from '@jebel/utils';

import { PaperLayout } from 'shared/components/layouts';
import { SearchControl } from 'shared/features/search';
import { Heading } from 'shared/components/ui';
import { usePageTitle, useResponsive } from 'shared/hooks';
import { SELECT_OPTION_ALL } from 'shared/constants';

import { SCORECARD_SORT_INFO } from '../../constants';

export function ClassScorecardHeader() {
  const theme = useTheme();
  const title = usePageTitle({ fallback: 'Class Scorecard' });
  const { isMobile } = useResponsive();

  const renderYears = useMemo(() => {
    const options = generateGraduatingYearsOptions();

    options.unshift({ value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL });

    return options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }, []);

  return (
    <PaperLayout
      sx={{
        px: { md: 3.75, xs: 2.5 },
        py: { md: 3.125, xs: 2.5 },
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3.125,
      }}
    >
      <Heading>{title}</Heading>

      <SearchControl
        withTextField={false}
        withSortField
        withFilterField
        sortFieldProps={SCORECARD_SORT_INFO}
        filterFieldProps={{
          label: 'Class Year',
          renderOptions: renderYears,
        }}
        elementsWidth={isMobile ? ['1fr'] : ['3fr', '2fr']}
        elementsGap={theme.spacing(2.5)}
      />
    </PaperLayout>
  );
}
