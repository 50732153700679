import { ROUTES } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { SearchPage } from './SearchPage';

export const getSearchRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: ROUTES.user.search.index,
    component: SearchPage,
  },
];
