import { css, Box } from '@mui/material';
import { DateTime } from 'luxon';

import { Icon, Typography, DatePicker } from 'shared/components/ui';
import { useSearchDateFilterContext } from 'shared/features/search';
import { PaperLayout } from 'shared/components/layouts';

const titleCss = theme => css`
  margin-left: ${theme.spacing(0.75)}px;
  font-size: ${theme.typography.fontSize + 4}px;
  font-weight: ${theme.typography.fontWeightMedium};
  line-height: 25px;
`;

export interface GroupsPostsDateFilterValues {
  startDate?: DateTime | null;
  endDate?: DateTime | null;
}

export function GroupsPostsFilter() {
  const { filter, setFilter } = useSearchDateFilterContext<GroupsPostsDateFilterValues>();

  const onChangeStart = (date: DateTime | null) => {
    setFilter(prev => ({ ...prev, startDate: date }));
  };

  const onChangeEnd = (date: DateTime | null) => {
    setFilter(prev => ({ ...prev, endDate: date }));
  };

  return (
    <PaperLayout sx={{ px: 3.75, py: 3.125 }}>
      <Box display="flex" alignItems="center">
        <Icon name="FilterAlt" />

        <Typography css={titleCss} variant="subtitle5">
          Filter by
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={2.5} pt={3.125}>
        <DatePicker
          value={filter?.startDate || null}
          onChange={onChangeStart}
          label="Date: Start"
          inputVariant="outlined"
          disableFuture
        />

        <DatePicker
          value={filter?.endDate || null}
          onChange={onChangeEnd}
          label="Date: End"
          inputVariant="outlined"
        />
      </Box>
    </PaperLayout>
  );
}
