import { US_STATES } from '@jebel/constants';

export const US_STATES_OPTIONS = [
  { label: 'All', value: 'All' },
  ...US_STATES.map(({ abbreviation }) => ({
    label: abbreviation,
    value: abbreviation,
  })),
];

export const US_PHONE_CODE = ``;
