import { DEFAULT_USER_NAME } from '@jebel/constants';

import { Maybe, MinimalUserFragment } from 'shared/graphql';

/** @deprecated Use {@link formatUserName} instead */
export const getUserFullName = (
  user: MinimalUserFragment | null | undefined,
  valueIfNil = DEFAULT_USER_NAME,
): string => {
  return formatUserName({ firstName: user?.firstName, lastName: user?.lastName }, valueIfNil);
};

type FormatUserNameInput = {
  fullName?: Maybe<string>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
};

/** Format the `firstName` and `lastName` of a user in a single string. */
export function formatUserName(user?: Maybe<FormatUserNameInput>, fallback?: string): string {
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';

  if (user?.fullName) {
    return user.fullName;
  }

  if (!firstName || !lastName) {
    return fallback ?? DEFAULT_USER_NAME;
  }

  return `${firstName} ${lastName}`;
}
