import { extractMentionIdentifier, extractMentionType } from '@jebel/utils';

import { PostMentionCreateInput } from 'shared/graphql';

import { MentionPosition } from '../types';

/** Transform the given `MentionPosition` into `PostMentionCreateInput`. */
export function prepareMentionsCreateInput(mention: MentionPosition): PostMentionCreateInput {
  const identifier = extractMentionIdentifier(mention.id);
  const type = extractMentionType(mention.id);

  return {
    mentionId: identifier,
    mentionText: mention.display,
    position: mention.index,
    type,
  };
}
