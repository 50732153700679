import { ReactNode, useMemo } from 'react';
import { Typography } from '@material-ui/core';

import {
  HomeFeedItemHashTagToken,
  HomeFeedItemMentionToken,
  HomeFeedItemTextToken,
  HomeFeedItemURLToken,
  parseHomeFeedItemText,
} from '@jebel/utils';

import { Maybe } from 'shared/graphql';

import { generateHashTagRedirect, generateMentionRedirect } from './generateRedirect';
import { MentionsTextLink } from './MentionsText.styles';

export interface MentionsTextProps {
  text: Maybe<string> | undefined;
  /** @default true */
  paragraph?: boolean;
}

/**
 * Use the Mentions API to transform the given `text` into HTML safely and with the needed styles.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
 */
export function MentionsText(props: MentionsTextProps) {
  const text = props.text ?? '';
  const paragraph = props.paragraph ?? true;

  const tokens = useMemo(() => parseHomeFeedItemText(text), [text]);

  const renderMention = (section: HomeFeedItemMentionToken): ReactNode => {
    const redirect = generateMentionRedirect(section.type, section.identifier);

    return (
      <MentionsTextLink key={section.startPosition} color="secondary" href={redirect}>
        {section.content}
      </MentionsTextLink>
    );
  };

  const renderHashTag = (section: HomeFeedItemHashTagToken): ReactNode => {
    const redirect = generateHashTagRedirect(section.content);

    return (
      <MentionsTextLink key={section.startPosition} color="secondary" href={redirect}>
        {section.content}
      </MentionsTextLink>
    );
  };

  const renderURL = (section: HomeFeedItemURLToken): ReactNode => {
    return (
      <MentionsTextLink
        key={section.startPosition}
        color="secondary"
        href={section.url}
        target="_blank"
      >
        {section.url}
      </MentionsTextLink>
    );
  };

  const renderText = (section: HomeFeedItemTextToken): ReactNode => {
    return (
      <Typography key={section.startPosition} component="span">
        {section.content}
      </Typography>
    );
  };

  return (
    <Typography paragraph={paragraph}>
      {tokens.map(section => {
        if (section.token === 'mention') {
          return renderMention(section);
        }

        if (section.token === 'hashtag') {
          return renderHashTag(section);
        }

        if (section.token === 'url') {
          return renderURL(section);
        }

        return renderText(section);
      })}
    </Typography>
  );
}
