import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { lazy, Suspense } from 'react';

import { useGroupMembers } from 'features/groups/hooks';
import { PostFeedLoader } from 'shared/components/ui';
import { PaperLayout } from 'shared/components/layouts';

import { GroupDetailsHeader } from './GroupDetailsHeader';

const GroupDetailsPostsList = lazy(() => import('./Posts/GroupDetailsPostsList'));

export function GroupDetailsContent() {
  const { id } = useParams<{ id: string }>();
  const { isCurrentUserMember, isLoading } = useGroupMembers(id);

  return (
    <PaperLayout>
      <Box display="grid" gridTemplateColumns="1fr" padding="2rem" gridGap="2rem">
        <GroupDetailsHeader />

        {isLoading ? (
          <PostFeedLoader />
        ) : (
          isCurrentUserMember && (
            <Suspense fallback={<PostFeedLoader />}>
              <GroupDetailsPostsList />
            </Suspense>
          )
        )}
      </Box>
    </PaperLayout>
  );
}
