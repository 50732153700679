import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { RouteLayout, Box, Form, FormTextField, Button, RouterLink } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { isValidEmail, required } from 'shared/utils/form';

import { FormError } from '../form';

const layoutContainerCSS = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  height: 100%;
`;

const topBoxCSS = css`
  display: grid;
  grid-row-gap: 20px;
`;

const titleCSS = (theme: any) => css`
  line-height: 26px;
  font-size: ${theme.typography.fontSize + 6}px;
  font-weight: 600;
  width: 100%;
  padding: ${theme.spacing(1.25)}px;
  text-align: center;
`;

const formBoxCSS = css`
  display: grid;
  grid-gap: 20px;
`;

const buttonsBoxCSS = css`
  display: grid;
  grid-gap: 30px;
`;

const resetButtonCSS = (theme: any) => css`
  letter-spacing: 1px;
  background-color: ${theme.palette.secondary.light};
  color: #ffffff;
  font-size: ${theme.typography.fontSize}px;
  line-height: 17px;
  padding: ${theme.spacing(2.25)}px;
`;

const returnBoxCSS = css`
  display: grid;
  grid-template-columns: auto auto;
  place-content: center;
  place-items: center;
`;

const loginLinkCSS = (theme: any) => css`
  white-space: nowrap;
  color: ${theme.palette.primary.icon};
`;

export type FormDataRecovery = {
  email?: string;
};

export type FormDataReset = {
  password?: string;
  confirmPassword?: string;
};

interface ResetPageProps {
  onSubmit: <T extends FormDataRecovery & FormDataReset>(data: T) => void;
  resetError?: string;
}

export const ResetLayout: React.FC<ResetPageProps> = ({ onSubmit, resetError }) => {
  const location = useLocation();
  const isRecoveryRoute = location.pathname === APP_URL.public.recovery;
  const pageName = isRecoveryRoute ? 'recovery' : 'reset';
  const title = pageName === 'recovery' ? 'Reset Your Password' : 'Change Your Password';
  const submitTitle = pageName === 'recovery' ? 'Reset Password' : 'Change Password';

  const renderFields = () => {
    let content = (
      <FormTextField
        inputProps={{
          color: 'primary',
          label: 'Enter your Email Address',
          variant: 'outlined',
        }}
        fieldProps={{
          name: 'email',
          validate: isValidEmail,
        }}
      />
    );
    if (pageName === 'reset') {
      content = (
        <React.Fragment>
          <FormTextField
            inputProps={{
              color: 'primary',
              label: 'Enter new password',
              variant: 'outlined',
              type: 'password',
            }}
            fieldProps={{
              name: 'password',
              validate: required,
            }}
          />
          <FormTextField
            inputProps={{
              color: 'primary',
              label: 'Commit new password',
              variant: 'outlined',
              type: 'password',
            }}
            fieldProps={{
              name: 'confirmPassword',
              validate: required,
            }}
          />
        </React.Fragment>
      );
    }

    return content;
  };

  return (
    <RouteLayout css={layoutContainerCSS}>
      <Box css={topBoxCSS}>
        <Typography
          css={titleCSS}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {title}
        </Typography>
        {resetError && <FormError text={resetError} />}
        <Form oldCss={formBoxCSS} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Fragment>
              {renderFields()}
              <Box css={buttonsBoxCSS}>
                <Button
                  css={resetButtonCSS}
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  {submitTitle}
                </Button>
                <Box css={returnBoxCSS}>
                  <RouterLink
                    css={loginLinkCSS}
                    absolute={false}
                    underline="none"
                    color="primary"
                    to={APP_URL.public.login}
                  >
                    Return to Login
                  </RouterLink>
                </Box>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </RouteLayout>
  );
};
