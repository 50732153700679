import { useMutation } from '@apollo/client';

import { useCurrentUser } from 'shared/hooks';

import {
  UpdateCampaignAdMutation,
  UpdateCampaignAdMutationVariables,
} from '../../../shared/graphql/__generated__';
import { UPDATE_AD_MUTATION } from '../queries';

export const useUpdateAdLike = () => {
  const { user } = useCurrentUser();
  const [updateAd, { loading }] = useMutation<
    UpdateCampaignAdMutation,
    UpdateCampaignAdMutationVariables
  >(UPDATE_AD_MUTATION);

  const onSetLike = async (adId: string) => {
    const variables = { id: adId, data: { userLikes: { connect: [{ id: user?.id }] } } };

    await updateAd({ variables });
  };

  const onRemoveLike = async (adId: string) => {
    const variables = { id: adId, data: { userLikes: { disconnect: [{ id: user?.id }] } } };

    await updateAd({ variables });
  };

  return {
    addLike: onSetLike,
    removeLike: onRemoveLike,
    loading,
  };
};
