import React from 'react';
import { Paper, Box, css } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Separator, UserAvatar } from 'shared/components/symbols';
import { Typography } from 'shared/components/ui';
import { usePosts } from 'shared/features/posts';
import { CollectiveIntelligenceItemFragment } from 'shared/graphql/';
import { getTextTimeAgo } from 'shared/utils/date';
import { buildUrl } from 'shared/routes';
import { APP_URL } from 'shared/constants';

import { useThreadWasViewed } from '../../hooks/useThreadWasViewed';

const SEPARATOR_DIAMETER = '4px';
const COLUMN_SIZE = 65;
const AVATAR_SIZE = 35;

const noWrapCss = theme => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const threadBoxCss = (isViewed: boolean) => theme =>
  css`
    padding: ${theme.spacing(2.5)}px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    cursor: pointer;
    background: ${isViewed ? '#f0f8ff' : 'inherit'};

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(1.5)}px;
    }
  `;

const threadLeftContentCss = theme => css`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: ${theme.spacing(2)}px;
`;

const threadInfoCss = theme => css`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: ${theme.spacing(0.25)}px;
`;

const threadActivityCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-column-gap: ${theme.spacing(1)}px;
  align-items: center;

  /* ${noWrapCss(theme)}; */
  overflow: hidden;
`;

const threadRightContentCss = theme => css`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: end;
  grid-column-gap: ${theme.spacing(1)}px;

  ${theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const activitiesTextCss = theme => css`
  color: ${theme.palette.primary.icon};
  width: ${COLUMN_SIZE}px;
  text-align: center;
  font-size: ${theme.typography.fontSize - 1}px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 18px;
`;

const descriptionCss = theme => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${theme.typography.fontSize}px;
  letter-spacing: 0.07px;
  line-height: 20px;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const threadUserNameCss = theme => css`
  font-size: ${theme.typography.fontSize - 1}px;
  font-weight: 500;
  letter-spacing: 0.07px;
  ${noWrapCss(theme)};
`;

const threadCreatedAtCss = theme => css`
  font-size: ${theme.typography.fontSize - 1}px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 26px;
  ${noWrapCss(theme)};
`;

const threadTitleCss = theme => css`
  ${noWrapCss(theme)};

  ${theme.breakpoints.down('sm')} {
    word-break: break-word;
    white-space: initial;
  }
`;

const threadAvatarCss = css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

type ItemProps = {
  item: CollectiveIntelligenceItemFragment;
};

export const CollectiveIntelligenceThread = ({ item }: ItemProps) => {
  const history = useHistory();
  const { activity } = usePosts({ postId: item.id });
  const { isViewed } = useThreadWasViewed(item);

  const onOpenThread = React.useCallback(() => {
    if (!item?.id) {
      return;
    }

    const url = buildUrl(APP_URL.user.collective.detail, {
      pathParams: { id: item?.id },
    });

    history.push(url);
  }, [history, item?.id]);

  return (
    <Paper css={threadBoxCss(isViewed)} elevation={5} variant="elevation" onClick={onOpenThread}>
      <Box css={threadLeftContentCss}>
        <UserAvatar css={threadAvatarCss} user={item?.creator} />
        <Box css={threadInfoCss}>
          <Box overflow="hidden">
            <Typography css={threadTitleCss} color="primary" variant="subtitle5">
              {item.title}
            </Typography>
          </Box>
          <Box css={threadActivityCss}>
            <Typography css={threadUserNameCss} color="primary" variant="subtitle5">
              {`${item?.creator?.firstName ?? ''} ${item?.creator?.lastName ?? ''}`}
            </Typography>
            <Separator diameter={SEPARATOR_DIAMETER} />
            <Typography css={threadCreatedAtCss} color="textSecondary" variant="subtitle5">
              {getTextTimeAgo(item?.createdAt || '')}
            </Typography>
          </Box>
          <Typography css={descriptionCss}>{item.text}</Typography>
        </Box>
      </Box>
      <Box css={threadRightContentCss}>
        <Typography css={activitiesTextCss} color="primary" variant="subtitle5">
          {activity?.reaction_counts?.comment || 0}
        </Typography>
        <Typography css={activitiesTextCss} color="primary" variant="subtitle5">
          {item.views?.items.length || 0}
        </Typography>
        <Typography css={activitiesTextCss} color="primary" variant="subtitle5">
          {getTextTimeAgo(
            activity?.latest_reactions?.comment
              ? activity?.latest_reactions?.comment[0]?.updated_at
              : item.createdAt,
          )}
        </Typography>
      </Box>
    </Paper>
  );
};
