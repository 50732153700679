import React from 'react';
import { useMutation, gql } from '@apollo/client';

import {
  SendInboxMessageNotificationRequestMutation,
  SendInboxMessageNotificationRequestMutationVariables,
  SendInboxMessageNotificationRequestInput,
} from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';

const SEND_POST_REACTION_NOTIFICATION_MUTATION = gql`
  mutation SendInboxMessageNotificationRequest($data: SendInboxMessageNotificationRequestInput!) {
    sendInboxMessageNotificationRequest(data: $data) {
      success
    }
  }
`;

export const useNewInboxMessageNotification = () => {
  const { userId } = useCurrentUser();

  const [sendNotification] = useMutation<
    SendInboxMessageNotificationRequestMutation,
    SendInboxMessageNotificationRequestMutationVariables
  >(SEND_POST_REACTION_NOTIFICATION_MUTATION);

  const sendInboxMessageNotification = React.useCallback(
    (data: SendInboxMessageNotificationRequestInput) =>
      sendNotification({
        variables: {
          data: {
            ...data,
            authorId: userId as string,
          },
        },
      }),
    [sendNotification, userId],
  );

  return { sendInboxMessageNotification };
};
