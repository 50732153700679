import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  padding: 1rem;
  border: solid 1px;
  border-color: ${p => p.theme.palette.divider};
  border-radius: 0.5rem;
`;
