import React from 'react';
import { useQuery } from '@apollo/client';

import { PAGE_SIZE } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import {
  RegisteredUsersCountQuery,
  RegisteredUsersCountQueryVariables,
  GroupRegisteredUsersCountFragment,
} from 'shared/graphql/__generated__';

import { REGISTERED_USERS_COUNT_QUERY } from '../queries';

const transformQueryData = (listData: RegisteredUsersCountQuery | undefined) => {
  const items = listData?.usersList?.groups || [];
  return { items };
};

export const useRegisteredUsersCount = () => {
  const registeredUsersRef = React.useRef<GroupRegisteredUsersCountFragment[]>();
  const { isUserTyping } = useSearchContext();

  const onQueryCompleted = React.useCallback((data: RegisteredUsersCountQuery) => {
    registeredUsersRef.current = data?.usersList?.groups;
  }, []);

  const {
    data: listData,
    loading: listLoading,
    refetch: refetchList,
    fetchMore: rawFetchMore,
  } = useQuery<RegisteredUsersCountQuery, RegisteredUsersCountQueryVariables>(
    REGISTERED_USERS_COUNT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted: onQueryCompleted,
      variables: {
        first: PAGE_SIZE,
      },
    },
  );

  const returnData = React.useMemo(() => transformQueryData(listData), [listData]);

  const fetchMore = React.useCallback(
    async (variables: RegisteredUsersCountQueryVariables) => {
      const { data } = await rawFetchMore({ variables });

      onQueryCompleted(data);
    },
    [onQueryCompleted, rawFetchMore],
  );

  const refetch = React.useCallback(() => {
    const refetchListPromise = refetchList({ first: registeredUsersRef.current?.length || 1 });

    return refetchListPromise;
  }, [refetchList]);

  return {
    ...returnData,
    registeredUsersCountLoading: (listLoading && !listData) || isUserTyping,
    fetchMore,
    refetch,
  };
};
