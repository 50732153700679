import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import { Route, RouteLayout } from 'shared/components/ui';

const wrapperCSS = css`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const contentCSS = css`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
`;

type MobileLayoutProps = {
  classes?: Record<string, any>;
  style?: Record<string, any>;
  css?: SerializedStyles;
  className?: string;
  onClick?: (event: MouseEvent | undefined) => void;
  onMouseOver?: (event: MouseEvent | undefined) => void;
  onMouseOut?: (event: MouseEvent | undefined) => void;
  onMouseDown?: (event: MouseEvent | undefined) => void;
  onMouseUp?: (event: MouseEvent | undefined) => void;
  onMouseEnter?: (event: MouseEvent | undefined) => void;
  onMouseLeave?: (event: MouseEvent | undefined) => void;
  onWheel?: (event: MouseEvent | undefined) => void;
  onContextMenu?: (event: MouseEvent | undefined) => void;
  onAuxClick?: (event: MouseEvent | undefined) => void;
  key?: number;
  children?: React.ReactNode;
  path?: any;
  exact?: boolean;
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
};

export const MobileLayout: React.FC<MobileLayoutProps> = symbolProps => {
  return (
    <Route path={symbolProps.path} exact={true} authAccess="any">
      <RouteLayout>
        <div css={wrapperCSS}>
          <div css={contentCSS}>
            {[symbolProps.header, symbolProps.content, symbolProps.footer]}
          </div>
        </div>
      </RouteLayout>
    </Route>
  );
};
