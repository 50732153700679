import { Theme, css } from '@emotion/react';
import { Box, Button } from '@material-ui/core';

import { ROLE_NAMES } from '@jebel/constants';

import { Modal, RouteLayout, Typography } from 'shared/components/ui';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { useModalState } from 'shared/hooks';

import { SiteAdminsList } from './SiteAdminsList';
import { InviteMemberModal } from '../../../../shared/components/dialogs/InviteModal/components/InviteMemberModal';

const containerCss = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: column;
  height: 100%;
`;

const titleContainerCss = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${theme.palette.divider};
  padding: 1rem 2rem;
`;

const titleCss = css`
  flex: 1;
`;

const contentCss = css`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const buttonInviteContainerCss = css`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const SiteAdmins = () => {
  const { show: isInviteModalOpen, open: onModalOpen, close: onModalClose } = useModalState();

  return (
    <RouteLayout css={containerCss}>
      <Modal
        dialogProps={{ open: isInviteModalOpen, onClose: onModalClose }}
        titleProps={{ title: 'Invite Admins' }}
      >
        <InviteMemberModal
          type={ROLE_NAMES.communityAdmin}
          isAdminInviting
          onClose={onModalClose}
        />
      </Modal>

      <Box css={titleContainerCss}>
        <Typography css={titleCss} variant="subtitle4" align="inherit" paragraph={false}>
          Site Admins
        </Typography>

        <Box css={buttonInviteContainerCss}>
          <Button onClick={onModalOpen} disableElevation variant="contained">
            Invite Admins
          </Button>
        </Box>
      </Box>

      <Box css={contentCss}>
        <SpreadsheetProvider>
          <SiteAdminsList />
        </SpreadsheetProvider>
      </Box>
    </RouteLayout>
  );
};
