import { DISCOUNT_CATEGORIES as DISCOUNTS } from '@jebel/constants';

import { SELECT_OPTION_ALL } from './selectOptions';

export const DISCOUNTS_OPTIONS = [
  { value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL },
  { value: DISCOUNTS.automotive, label: 'Automotive' },
  { value: DISCOUNTS.businessSupport, label: 'Business support' },
  { value: DISCOUNTS.construction, label: 'Construction' },
  { value: DISCOUNTS.dental, label: 'Dental' },
  { value: DISCOUNTS.dining, label: 'Dining' },
  { value: DISCOUNTS.distribution, label: 'Distribution' },
  { value: DISCOUNTS.education, label: 'Education' },
  { value: DISCOUNTS.entertainment, label: 'Entertainment' },
  { value: DISCOUNTS.financial, label: 'Financial' },
  { value: DISCOUNTS.homeGarden, label: 'Home garden' },
  { value: DISCOUNTS.insurance, label: 'Insurance' },
  { value: DISCOUNTS.legal, label: 'Legal' },
  { value: DISCOUNTS.marketing, label: 'Marketing' },
  { value: DISCOUNTS.medical, label: 'Medical' },
  { value: DISCOUNTS.personalCare, label: 'Personal care' },
  { value: DISCOUNTS.realEstate, label: 'Real estate' },
  { value: DISCOUNTS.retail, label: 'Retail' },
  { value: DISCOUNTS.sportsFitness, label: 'Sports fitness' },
  { value: DISCOUNTS.technology, label: 'Technology' },
  { value: DISCOUNTS.travel, label: 'Travel' },
  { value: DISCOUNTS.other, label: 'Other' },
];
