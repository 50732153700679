import { Fragment, useState } from 'react';

import { HomeFeedCard, usePostDelete } from 'shared/features/posts';
import { HomeFeedItemFragment } from 'shared/graphql';

import { LegacyPostModalUpdate } from './LegacyPostModalUpdate';

interface Props {
  post: HomeFeedItemFragment;
}

export function LegacyPostsListItem(props: Props) {
  const [showEditModal, setShowEditModal] = useState(false);

  const { onPostDelete } = usePostDelete();

  const onEditClose = () => {
    setShowEditModal(false);
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleRemove = () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm('Are you sure?');

    if (isConfirmed) {
      onPostDelete(props.post.id, 'LegacyFeedPost');
    }
  };

  if (!props.post) {
    return null;
  }

  return (
    <Fragment>
      <LegacyPostModalUpdate
        isOpen={showEditModal}
        onClose={onEditClose}
        selectedPost={props.post as any}
      />

      <HomeFeedCard
        data={props.post}
        hideComments
        hideLikes
        onEdit={handleEdit}
        onRemove={handleRemove}
      />
    </Fragment>
  );
}
