export var MentionType;
(function (MentionType) {
    MentionType["User"] = "user";
    MentionType["Organization"] = "organization";
    MentionType["Group"] = "group";
    MentionType["HashTag"] = "hashtag";
})(MentionType || (MentionType = {}));
export const MENTION_TRIGGER = '@';
export const HASHTAG_TRIGGER = '#';
export const MENTION_MARKUP = `${MENTION_TRIGGER}[__display__](__id__)`;
export const HASHTAG_MARKUP = `${HASHTAG_TRIGGER}[__display__](__id__)`;
export const MENTION_MARKUP_REGEX = /^@\[(?<display>[^\]]+)\]\((?<type>[^\)]+):(?<id>[^\)]+)\)/i;
export const HASHTAG_MARKUP_REGEX = /^#\[(?<display>[^\]]+)\]\((?<id>[^\)]+)\)/i;
