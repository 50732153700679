import { Theme } from '@emotion/react';
import {
  EChartsOption as ChartOptions,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import { alpha } from '@mui/material';

import { BarChartProps } from './BarChart.types';

/**
 * Create the options based on the given `props` and `theme` for a bar chart.
 * @param props Properties given to the component.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Instance of `ChartOptions` useful with `echarts`.
 */
export function createBarChartOptions(props: BarChartProps, theme: Theme): ChartOptions {
  const { fontSize, textStyle, itemStyle } = createChartTheme(theme);

  const options: ChartOptions = {
    textStyle,

    tooltip: {
      trigger: 'item',

      formatter(params: TooltipComponentFormatterCallbackParams) {
        const options = Array.isArray(params) ? params[0] : params;
        const data = props.data[options.dataIndex];

        if (!data) {
          return '(None)';
        }

        const label = props.formatCategory?.(data.category) ?? data.category;
        const value = data.legend ?? props.formatValue?.(data.value) ?? String(data.value);

        return data.legend ?? `<b>${label}</b><br/>${value}`;
      },
    },

    dataset: {
      dimensions: ['timestamp', 'value'],
      source: props.data,
    },

    xAxis: {
      type: 'category',
      axisLabel: {
        fontSize,
        formatter: props.formatCategory,
      },
    },

    yAxis: {
      axisLabel: {
        fontSize,
        formatter: props.formatValue,
      },
    },

    series: {
      type: 'bar',
      dimensions: ['category', 'value'],
      itemStyle,
    },
  };

  return options;
}

/**
 * Create some variables to customize the chart.
 * @param theme Given `theme` by `useTheme` from `@emotion/react`.
 * @returns Useful variables to customize the chart.
 */
function createChartTheme(theme: Theme) {
  const color = theme.palette.secondary.main;

  const borderColor = alpha(theme.palette.secondary.main, 0.3);
  const areaColor = alpha(theme.palette.secondary.main, 0.2);

  const fontFamily = theme.typography.fontFamily;
  const fontSize = theme.typography.fontSize;

  const textStyle: ChartOptions['textStyle'] = {
    fontFamily: theme.typography.fontFamily,
    fontSize,
  };

  const lineStyle: LineSeriesOption['lineStyle'] = {
    color,
  };

  const areaStyle: LineSeriesOption['areaStyle'] = {
    color: areaColor,
  };

  const itemStyle: LineSeriesOption['itemStyle'] = {
    color,
  };

  return {
    color,
    borderColor,
    areaColor,
    fontSize,
    fontFamily,
    textStyle,
    lineStyle,
    areaStyle,
    itemStyle,
  };
}
