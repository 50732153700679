/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { SORT } from '@jebel/constants';

import { SortOrder } from 'shared/graphql';

type Sort = {
  [key: string]: SortOrder | Sort | undefined | null;
};

const SORT_STATEGIES = [SORT.desc, SORT.asc];

export function chooseRamdomSorts<S extends Sort>(
  fields: string[],
  stategies = SORT_STATEGIES,
): S[] {
  const size = pickRandom(fields.length);
  const sorts: Sort[] = [];

  for (let index = 0; index < size; index++) {
    const field = fields[pickRandom(fields.length)];
    const stategy = stategies[pickRandom(stategies.length)];

    sorts.push({ [field]: stategy });
  }

  return sorts as S[];
}

/** Generates a random number between `max` and `min`. */
function pickRandom(max: number, min = 0) {
  return Math.floor(Math.random() * (max - min) + min);
}
