import { forwardRef } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '../KeyboardDateTimePicker';

export type FormKeyboardDateTimePickerProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  dateTimePickerProps?: Omit<KeyboardDateTimePickerProps, 'onChange' | 'value'>;
};

/** @deprecated Use `DateTimePickerField` instead. */
export const FormKeyboardDateTimePicker = forwardRef<
  HTMLInputElement,
  FormKeyboardDateTimePickerProps
>(
  (
    {
      fieldProps,
      dateTimePickerProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField<DateTime | null>(fieldProps);

    const form = useFormikContext();

    const isInvalid = getIsInvalid({ meta, form });
    const errorText = isInvalid && meta.error;

    const onChange = (value: MaterialUiPickersDate) => {
      if (value && !value?.isValid) {
        form.setFieldError(field.name, 'Should be a valid date format');
        return;
      }

      if (value && value?.isValid) {
        helpers.setValue(value);
        return;
      }

      helpers.setValue(null);
    };

    return (
      <FormControl
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      >
        <KeyboardDateTimePicker
          {...dateTimePickerProps}
          value={field.value}
          onChange={onChange}
          error={isInvalid}
          fieldName={field.name}
        />

        {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
      </FormControl>
    );
  },
);
