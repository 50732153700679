import React from 'react';
import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import {
  GroupDetailsUpdateMutation,
  GroupDetailsUpdateMutationVariables,
} from 'shared/graphql/__generated__';

import { GROUP_DETAILS_UPDATE_MUTATION } from '../queries';

export const useGroupDetailsUpdate = () => {
  const [groupDetailsUpdate] = useMutation<
    GroupDetailsUpdateMutation,
    GroupDetailsUpdateMutationVariables
  >(GROUP_DETAILS_UPDATE_MUTATION, {
    refetchQueries: ['GroupSearch'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: Your Group has been Updated',
      [SNACKBAR_ERROR_MESSAGE]: `Error: Your group hasn't been updated. Try again.`,
    },
  });

  const onGroupDetailsUpdate = React.useCallback(
    (data: GroupDetailsUpdateMutationVariables['data']) => {
      return groupDetailsUpdate({
        variables: {
          data,
        },
      });
    },
    [groupDetailsUpdate],
  );

  return {
    onGroupDetailsUpdate,
  };
};
