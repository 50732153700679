import { useCallback, useEffect, useMemo, useState } from 'react';

import { sortExternalLinks, sortLabels, useSchoolConfiguration } from 'shared/hooks';
import {
  SchoolConfigurationLayout,
  SchoolConfigurationExternalLink,
  SchoolConfigurationExternalLinkCreateInput,
} from 'shared/graphql';
import { useSchoolConfigurationExternalLinkCreate } from 'shared/hooks/useSchoolConfigurationExternalLinkCreate';
import { useSchoolConfigurationExternalLinkUpdate } from 'shared/hooks/useSchoolConfigurationExternalLinkUpdate';
import { useSchoolConfigurationLayoutUpdate } from 'shared/hooks/useSchoolConfigurationLayoutUpdate';
import { ROUTES } from 'shared/constants';

export function useSchoolConfigurationLabels() {
  const { configuration, loading: schoolConfigurationQueryIsLoading } = useSchoolConfiguration();
  const [sidebarLabels, setSidebarLabels] = useState<SchoolConfigurationLayout[]>([]);
  const [externalLinks, setExternalLinks] = useState<SchoolConfigurationExternalLink[]>([]);

  const [schoolConfigurationExternalLinkCreate, { loading: externalLinkCreateIsLoading }] =
    useSchoolConfigurationExternalLinkCreate();

  const [schoolConfigurationExternalLinkUpdate, { loading: externalLinkUpdateIsLoading }] =
    useSchoolConfigurationExternalLinkUpdate();

  const [schoolConfigurationLayoutUpdate, { loading: schoolConfigurationLayoutUpdateIsLoading }] =
    useSchoolConfigurationLayoutUpdate();

  const loading =
    schoolConfigurationQueryIsLoading ||
    externalLinkCreateIsLoading ||
    schoolConfigurationLayoutUpdateIsLoading ||
    externalLinkUpdateIsLoading;

  const resetState = useCallback(() => {
    if (configuration?.layout?.items) {
      setSidebarLabels(sortLabels(configuration.layout.items));
    }
    if (configuration?.externalLinks?.items) {
      setExternalLinks(sortExternalLinks(configuration.externalLinks.items));
    }
  }, [configuration]);

  const applyChanges = useCallback(async () => {
    const pagesPromises = sidebarLabels.map((page, index) => {
      return schoolConfigurationLayoutUpdate({
        id: page.id,
        name: page.name,
        sidebarOrderNumber: index,
        navbarOrderNumber: page?.navbarOrderNumber,
        isActive: page.isActive,
      });
    });

    const externalLinksPromises = externalLinks.map((data, index) => {
      const payload: SchoolConfigurationExternalLinkCreateInput = {
        order: data.order ?? index,
        name: data.name as string,
        link: data.link as string,
        isActive: Boolean(data.isActive),
      };

      // eslint-disable-next-line no-underscore-dangle
      if (data.__typename) {
        return schoolConfigurationExternalLinkUpdate({ id: data.id, ...payload });
      }

      payload.configuration = {
        connect: { id: configuration!.id },
      };

      return schoolConfigurationExternalLinkCreate(payload);
    });

    await Promise.all([...pagesPromises, ...externalLinksPromises]);
  }, [
    sidebarLabels,
    externalLinks,
    configuration,
    schoolConfigurationExternalLinkCreate,
    schoolConfigurationExternalLinkUpdate,
    schoolConfigurationLayoutUpdate,
  ]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  const mobileLabelOptions = useMemo(() => {
    return sidebarLabels.filter(label => label.route !== ROUTES.user.home.index);
  }, [sidebarLabels]);

  return {
    sidebarLabels,
    setSidebarLabels,
    /** @deprecated Use `sidebarLabels` instead. */
    mobileLabels: sidebarLabels,
    mobileLabelOptions,
    setMobileLabels: setSidebarLabels,
    externalLinks,
    setExternalLinks,
    loading,
    resetState,
    applyChanges,
  };
}
