import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { StreamClient, connect } from 'getstream';

import { useStreamChatAuthorizationQuery } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { recordDebug } from 'shared/utils/record';

import { StreamChatContext } from './StreamChatContext';

const PUBLIC_STREAMCHAT_API_KEY = process.env.REACT_APP_PUBLIC_STREAMCHAT_API_KEY as string;
const STREAMCHAT_APP_ID = process.env.REACT_APP_STREAMCHAT_APP_ID as string;

export interface StreamChatContextProps {}

export function StreamChatProvider({ children }: PropsWithChildren<StreamChatContextProps>) {
  const [client, setClient] = useState<StreamClient>();

  const { user: currentUser } = useCurrentUser();

  const { data: response } = useStreamChatAuthorizationQuery({
    // Prevents the query to be executed when the user is not logged in.
    skip: !currentUser,
  });

  useLayoutEffect(() => {
    const token = response?.authorization.token;

    if (token) {
      const newClient = connect(PUBLIC_STREAMCHAT_API_KEY, token, STREAMCHAT_APP_ID);

      recordDebug('Setting up the StreamChat client');
      setClient(newClient);
    }
  }, [response]);

  return (
    <StreamChatContext.Provider
      value={{
        streamChatClient: client,
      }}
    >
      {children}
    </StreamChatContext.Provider>
  );
}
