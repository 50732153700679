import { useMemo } from 'react';
import { SelectOption } from '@jebel/constants';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FieldValidator, useField, useFormikContext } from 'formik';

import { composeFieldValidators } from '@jebel/utils';
import { required } from 'shared/utils/form';

import { getIsInvalid } from '../common';

interface BaseProps {
  name: string;
  validate?: FieldValidator;

  /** The label content. */
  label?: string;
  /** The helper text content. */
  helperText?: string;
  /** Array of options. */
  options: SelectOption[];
  /** If `true`, the input element will be disabled. */
  disabled?: boolean;
  /** If `true`, the input element will be required. */
  required?: boolean;
}

type Value = SelectOption['value'];

export interface AutocompleteFieldSingleProps extends BaseProps {
  multiple?: false;

  onChange?(value: Value): void;
}

export interface AutocompleteFieldMultipleProps extends BaseProps {
  /** If `true`, the field allows multiple selections. */
  multiple: true;

  onChange?(value: Value[]): void;
}

export type AutocompleteFieldProps = AutocompleteFieldSingleProps | AutocompleteFieldMultipleProps;

/**
 * Displays an autocomplete field.
 * @param props The component props.
 */
export function AutocompleteField(props: AutocompleteFieldProps) {
  type FieldValue = Value | Value[] | undefined;
  type InputValue = SelectOption | SelectOption[] | null;

  const validate = useMemo(() => {
    return composeFieldValidators(props.required === true ? required : undefined, props.validate);
  }, [props.required, props.validate]);

  const [field, meta, helpers] = useField<FieldValue>({
    name: props.name,
    validate,
  });

  const form = useFormikContext();
  const hasError = getIsInvalid({ meta, form });

  const selected = useMemo(() => {
    if (props.multiple && Array.isArray(field.value)) {
      const values = field.value as Value[];
      const selected = props.options.filter(option => values.find(value => value === option.value));

      return selected;
    }

    if (!props.multiple && field.value) {
      const selected = props.options.find(option => option.value === field.value);
      return selected;
    }

    return null;
  }, [props.multiple, props.options, field.value]);

  const message = useMemo(() => {
    if (hasError) {
      return meta.error;
    }

    return props.helperText;
  }, [hasError, meta]);

  const formatOptionLabel = (value: SelectOption) => {
    return value.label;
  };

  const changeValue = (value: FieldValue) => {
    helpers.setValue(value);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.onChange?.(value as any);
  };

  const handleChange = (event: unknown, selected: InputValue) => {
    if (props.multiple && Array.isArray(selected)) {
      changeValue(selected.map(option => option.value));
      return;
    }

    if (!selected) {
      changeValue(undefined);
      return;
    }

    if ('value' in selected) {
      changeValue(selected.value);
      return;
    }
  };

  return (
    <Autocomplete
      options={props.options}
      getOptionLabel={formatOptionLabel}
      multiple={props.multiple}
      value={selected}
      onChange={handleChange}
      disabled={props.disabled}
      renderInput={params => (
        <TextField
          variant="outlined"
          {...params}
          name={props.name}
          label={props.label}
          onChange={undefined}
          error={hasError}
          helperText={message}
          disabled={props.disabled}
          required={props.required}
        />
      )}
    />
  );
}
