import { useMemo } from 'react';

import { RemoteVariableFragment, useRemoteVariablesQuery } from 'shared/graphql';

type OptionalRemoteVariables = Omit<RemoteVariableFragment, '__typename'>;

type RemoteVariables = Required<{
  [K in keyof OptionalRemoteVariables]: NonNullable<OptionalRemoteVariables[K]>;
}>;

const DEFAULTS: RemoteVariables = {
  minChargeAmount: 1,
  overflowDebtAmount: 100,
  minDebtAmount: 1,
  maxDebtAmount: 25,
  serveAdvertisingUnitAmount: 0.04,
  serveAdvertisingSupportUnitAmount: 0.02,
  jobOfferExpirationDays: 40,
  specialOfferDailyAmount: 2,
  specialOfferDailySupporterAmount: 1,
  showOrganizationBalance: true,
};

/** Gets the remote variables setup for the current environment. */
export function useRemoteVariables() {
  const { data: response, loading } = useRemoteVariablesQuery({
    fetchPolicy: 'cache-first',
  });

  const data: OptionalRemoteVariables = useMemo(() => {
    const [variables] = response?.variables.items ?? [];
    return variables;
  }, [response]);

  return {
    data,
    loading,
  };
}

/**
 * Returns the remote variable value for the given `name`.
 * @param name Name of the requested variable
 * @returns Remote variable value or his default.
 */
export function useRemoteVariable<N extends keyof RemoteVariables>(name: N): RemoteVariables[N] {
  const response = useRemoteVariables();
  const value = response.data?.[name] ?? DEFAULTS[name];

  return value as RemoteVariables[N];
}
