import { GroupKeyFilter, useGroupMemberRemoveMutation } from 'shared/graphql';
import { NormalizedGroupMember } from './useGroupMembers';
import { useCurrentUser, useToast } from 'shared/hooks';
import { recordDebug, recordError } from 'shared/utils/record';

interface UseLeaveGroupOptions {
  group: GroupKeyFilter | undefined | null;
}

export function useLeaveGroup(options: UseLeaveGroupOptions) {
  const { user: currentUser } = useCurrentUser();
  const { showMessage, showSuccess, showError, dismiss } = useToast();

  const [leaveGroupMutation] = useGroupMemberRemoveMutation({
    refetchQueries: ['GroupMembers'],
    awaitRefetchQueries: true,
  });

  const mutate = async () => {
    if (!options.group) {
      recordDebug(`Group is not defined. Cannot leave group.`);
      return;
    }

    if (!currentUser) {
      recordDebug(`Cannot leave a group without a current user.`);
      return;
    }

    const messageLeavingKey = `LEAVE_GROUP_${options.group.id}`;

    try {
      showMessage(`Leaving the group.`, { id: messageLeavingKey });

      await leaveGroupMutation({
        variables: {
          user: { id: currentUser?.id },
          group: { id: options.group.id },
        },
      });

      showSuccess(`You have left the group.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    dismiss(messageLeavingKey);
  };

  return {
    action: mutate,
  };
}
