import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

const dialogCSS = css`{{ symbolProps.dialogProps?.css }}`;

type DialogSymbolProps = {
  classes?: Record<string, any>;
  style?: Record<string, any>;
  css?: SerializedStyles;
  className?: string;
  onClick?: (event: MouseEvent | undefined) => void;
  onMouseOver?: (event: MouseEvent | undefined) => void;
  onMouseOut?: (event: MouseEvent | undefined) => void;
  onMouseDown?: (event: MouseEvent | undefined) => void;
  onMouseUp?: (event: MouseEvent | undefined) => void;
  onMouseEnter?: (event: MouseEvent | undefined) => void;
  onMouseLeave?: (event: MouseEvent | undefined) => void;
  onWheel?: (event: MouseEvent | undefined) => void;
  onContextMenu?: (event: MouseEvent | undefined) => void;
  onAuxClick?: (event: MouseEvent | undefined) => void;
  key?: number;
  children?: React.ReactNode;
  dialogProps?: {
    classes?: Record<string, any>;
    style?: Record<string, any>;
    css?: SerializedStyles;
    className?: string;
    onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseOver?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseOut?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseDown?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseUp?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseEnter?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onMouseLeave?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onWheel?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onContextMenu?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    onAuxClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    key?: number;
    children?: React.ReactNode;
    'aria-describedby'?: string | undefined;
    'aria-labelledby'?: string | undefined;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
    maxWidth?: any;
    onBackdropClick?: (_: any) => void;
    onClose?: (_: any) => void;
    onEnter?: (_: any) => void;
    onEntered?: (_: any) => void;
    onEntering?: (_: any) => void;
    onEscapeKeyDown?: (_: any) => void;
    onExit?: (_: any) => void;
    onExited?: (_: any) => void;
    onExiting?: (_: any) => void;
    open?: any;
    PaperComponent?: any;
    PaperProps?: Record<string, any>;
    scroll?: any;
    TransitionComponent?: any;
    transitionDuration?: Record<string, any>;
    TransitionProps?: Record<string, any>;
  };
  titleProps?: {
    title?: string;
  };
};

export const DialogSymbol: React.FC<DialogSymbolProps> = symbolProps => {
  return (
    <Dialog
      classes={symbolProps.dialogProps?.classes}
      style={symbolProps.dialogProps?.style}
      css={symbolProps.dialogProps?.css}
      className={symbolProps.dialogProps?.className}
      onClick={symbolProps.dialogProps?.onClick}
      onMouseOver={symbolProps.dialogProps?.onMouseOver}
      onMouseOut={symbolProps.dialogProps?.onMouseOut}
      onMouseDown={symbolProps.dialogProps?.onMouseDown}
      onMouseUp={symbolProps.dialogProps?.onMouseUp}
      onMouseEnter={symbolProps.dialogProps?.onMouseEnter}
      onMouseLeave={symbolProps.dialogProps?.onMouseLeave}
      onWheel={symbolProps.dialogProps?.onWheel}
      onContextMenu={symbolProps.dialogProps?.onContextMenu}
      onAuxClick={symbolProps.dialogProps?.onAuxClick}
      aria-describedby={symbolProps.dialogProps?.['aria-describedby']}
      aria-labelledby={symbolProps.dialogProps?.['aria-labelledby']}
      disableBackdropClick={symbolProps.dialogProps?.disableBackdropClick}
      disableEscapeKeyDown={symbolProps.dialogProps?.disableEscapeKeyDown}
      fullScreen={symbolProps.dialogProps?.fullScreen}
      fullWidth={symbolProps.dialogProps?.fullWidth}
      maxWidth={symbolProps.dialogProps?.maxWidth}
      onBackdropClick={symbolProps.dialogProps?.onBackdropClick}
      onClose={symbolProps.dialogProps?.onClose}
      onEnter={symbolProps.dialogProps?.onEnter}
      onEntered={symbolProps.dialogProps?.onEntered}
      onEntering={symbolProps.dialogProps?.onEntering}
      onEscapeKeyDown={symbolProps.dialogProps?.onEscapeKeyDown}
      onExit={symbolProps.dialogProps?.onExit}
      onExited={symbolProps.dialogProps?.onExited}
      onExiting={symbolProps.dialogProps?.onExiting}
      open={symbolProps.dialogProps?.open}
      PaperProps={symbolProps.dialogProps?.PaperProps}
      scroll={symbolProps.dialogProps?.scroll}
      transitionDuration={symbolProps.dialogProps?.transitionDuration}
      TransitionProps={symbolProps.dialogProps?.TransitionProps}
    >
      {symbolProps.titleProps?.title && <DialogTitle>{symbolProps.titleProps.title}</DialogTitle>}
      <DialogContent>{symbolProps.children}</DialogContent>
    </Dialog>
  );
};
