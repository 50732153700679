import { ChangeEvent, forwardRef, ReactNode, Ref } from 'react';
import { SerializedStyles } from '@emotion/react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import { FieldValidator, useField, useFormikContext } from 'formik';

import { isFn } from '@jebel/utils';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';

export type FormRadioGroupProps = ComponentDataProps & {
  label?: string;
  children: ReactNode;
  radioGroupProps?: RadioGroupProps & {
    css: SerializedStyles;
  };
  fieldProps: {
    name: string;
    validate?: FieldValidator;
    onBeforeChange?: (value: string) => unknown;
  };
};

export const FormRadioGroup = forwardRef(
  (
    {
      label,
      children,
      radioGroupProps,
      fieldProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    }: FormRadioGroupProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const form = useFormikContext();

    const [field, meta, helpers] = useField({
      name: fieldProps.name,
      validate: fieldProps.validate,
    });

    const isInvalid = getIsInvalid({ meta, form });
    const errorText = isInvalid && meta.error;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = isFn(fieldProps.onBeforeChange)
        ? fieldProps.onBeforeChange(event.target.value)
        : event.target.value;

      helpers.setValue(value);
    };

    return (
      <FormControl
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      >
        {label && <FormLabel>{label}</FormLabel>}

        <RadioGroup
          {...radioGroupProps}
          name={field.name}
          value={String(field.value)}
          onChange={onChange}
        >
          {children}
        </RadioGroup>

        {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
      </FormControl>
    );
  },
);
