import { useCurrentUser } from 'shared/hooks';
import { useAdViewCreateMutation } from 'shared/graphql';

export function useCreateImpressionMutation() {
  const { user } = useCurrentUser();

  const [createImpression] = useAdViewCreateMutation();

  return async (adId: string) => {
    if (!user?.id) {
      return;
    }

    await createImpression({
      variables: {
        userId: user.id,
        adId,
      },
    });
  };
}
