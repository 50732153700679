import React from 'react';
import * as R from 'ramda';

import { POST_STATUSES, SORT } from '@jebel/constants';

import { PAGE_SIZE } from 'shared/constants';
import { usePostsList } from 'shared/features/posts/hooks';
import { HomeFeedPostsListQuery, HomeFeedPostsCountQuery } from 'shared/graphql/__generated__';

import { HOME_FEED_POSTS_LIST_QUERY, HOME_FEED_POSTS_COUNT_QUERY } from '../queries';

const transformQueryData = (
  listsData: HomeFeedPostsListQuery | undefined,
  countData: HomeFeedPostsCountQuery | undefined,
) => {
  const pinnedPosts = listsData?.pinnedPostsList.items || [];
  const posts = listsData?.homeFeedPostsList.items || [];
  const count = countData?.homeFeedPostsList.count || 0;
  const hasMore = posts.length < count;

  return { items: R.uniqBy(item => item.id, [...pinnedPosts, ...posts]), count, hasMore };
};

const FIELD_NAME = 'homeFeedPostsList';

export const HOME_FEED_POSTS_VARIABLES = {
  filter: {
    status: {
      equals: POST_STATUSES.approved,
    },
  },
  sort: { createdAt: SORT.desc },
  first: PAGE_SIZE,
  isPinned: { equals: true },
  pinnedFilter: {
    status: {
      equals: POST_STATUSES.approved,
    },
  },
};

/** @deprecated */
export const useHomeFeedPostsQuery = (
  authorId?: string | null | undefined,
  customFilter?: { startDate: string; endDate: string },
) => {
  const { listsData, countData, ...rest } = usePostsList<
    HomeFeedPostsListQuery,
    HomeFeedPostsCountQuery
  >({
    listQuery: HOME_FEED_POSTS_LIST_QUERY,
    countQuery: HOME_FEED_POSTS_COUNT_QUERY,
    variables: {
      ...HOME_FEED_POSTS_VARIABLES,
      filter: !customFilter
        ? {
            ...HOME_FEED_POSTS_VARIABLES.filter,
            author: {
              id: {
                equals: authorId,
              },
            },
          }
        : {
            AND: [
              { createdAt: { gte: customFilter?.startDate } },
              { createdAt: { lte: customFilter?.endDate } },
            ],
          },
      pinnedFilter: !customFilter ? HOME_FEED_POSTS_VARIABLES.pinnedFilter : {},
    },
    itemsFieldName: FIELD_NAME,
  });

  const data = React.useMemo(
    () => transformQueryData(listsData, countData),
    [countData, listsData],
  );

  return {
    ...data,
    ...rest,
  };
};
