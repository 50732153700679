import React from 'react';
import { ChromePicker } from 'react-color';

import { Popover } from 'shared/components/ui';

type PickerProps = {
  pickerTrigger: React.ReactNode;
  onChange: (color: string) => void;
};

export const CustomColorPicker = ({ pickerTrigger, onChange }: PickerProps) => {
  const [colorState, setColorState] = React.useState<{ color: string }>({
    color: '#fff',
  });

  const handleChange = React.useCallback(
    color => {
      setColorState(prev => ({ ...prev, color: color.hex }));
      onChange(color.hex);
    },
    [onChange],
  );

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      content={() => <ChromePicker color={colorState.color} onChange={handleChange} />}
      target={pickerTrigger}
    />
  );
};
