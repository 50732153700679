import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENT } from 'shared/graphql';

export const BUSINESS_SEARCH_QUERY = gql`
  query BusinessSearch(
    $filter: OrganizationFilter
    $sort: [OrganizationSort!]
    $first: Int
    $skip: Int
  ) {
    organizationsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      items {
        ...OrganizationItem
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const BUSINESSES_LIST_COUNT_QUERY = gql`
  query BusinessesListCount($filter: BusinessReportFilter) {
    businessReportsList(filter: $filter) {
      count
    }
  }
`;

export const BUSINESS_EDIT_MUTATION = gql`
  mutation BusinessEdit($data: OrganizationUpdateInput!) {
    organizationUpdate(data: $data, force: true, destroyDetached: true) {
      ...OrganizationItem
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

export const INVITE_BUSINESS_MUTATION = gql`
  mutation InviteBusiness($emails: [String!]) {
    inviteBusinessRequest(emails: $emails) {
      success
    }
  }
`;
