import { Box } from '@material-ui/core';

import { SearchProvider } from 'shared/features/search';

import { ClassScorecardHeader } from './ClassScorecardHeader';
import { ClassesList } from './ClassesList';
import { SCORECARD_SORT_INFO_DEFAULT_OPTION } from '../../constants';

export function ClassScorecardContent() {
  return (
    <SearchProvider defaultSortOption={SCORECARD_SORT_INFO_DEFAULT_OPTION}>
      <Box display="grid" gridGap="2rem">
        <ClassScorecardHeader />
        <ClassesList />
      </Box>
    </SearchProvider>
  );
}
