import { Fragment, useCallback, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';

import { Button, Form } from 'shared/components/ui';
import { CurrentUserFragment } from 'shared/graphql';
import { USER_ACTIVITIES_OPTIONS } from 'shared/constants';

import { useActivityClubsUpdate } from '../../hooks';
import { recordError } from 'shared/utils/record';
import { useToast } from 'shared/hooks';

const commonButtonsCSS = (theme: Theme) => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(2)}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
`;

const membersInputCss = theme => css`
  fieldset {
    border-color: ${theme.palette.primary.dark};
  }
`;

interface Props {
  onModalClose: () => void;
  activityClubs: CurrentUserFragment['activityClubs'];
}

export function EditActivityClubsModal({ onModalClose, activityClubs }: Props) {
  const { showError } = useToast();
  const { onActivityClubsUpdate } = useActivityClubsUpdate();

  const [selectedOptions, setSelectedOptions] = useState(
    activityClubs?.map(hobby => ({
      label: USER_ACTIVITIES_OPTIONS.clubs.find(option => option.value === hobby)?.label || '',
      value: hobby || '',
    })),
  );

  const handleChange = useCallback((_, options) => {
    setSelectedOptions(options);
  }, []);

  const onSubmit = async () => {
    try {
      await onActivityClubsUpdate(selectedOptions?.map(selected => selected.value) ?? []);
      onModalClose();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit} validateOnBlur={false}>
      {({ isSubmitting }) => (
        <Fragment>
          <Box display="grid" gridTemplateRows="auto" gap={2} my={2}>
            <Autocomplete
              multiple
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={USER_ACTIVITIES_OPTIONS.clubs}
              onChange={handleChange}
              getOptionLabel={option => option.label}
              defaultValue={activityClubs?.map(hobby => ({
                label:
                  USER_ACTIVITIES_OPTIONS.clubs.find(option => option.value === hobby)?.label || '',
                value: hobby || '',
              }))}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => (
                  <Chip label={option.label} {...getTagProps({ index })} />
                ))
              }
              renderInput={params => {
                return (
                  <Fragment>
                    <TextField
                      {...params}
                      css={membersInputCss}
                      label="Clubs & Sports"
                      placeholder="Enter clubs"
                      variant="outlined"
                    />
                  </Fragment>
                );
              }}
            />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              css={cancelButtonCSS}
              onClick={onModalClose}
              disabled={isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>
            <Button
              loading={isSubmitting}
              css={submitButtonCSS}
              color="primary"
              variant="contained"
              type="submit"
            >
              SAVE CHANGES
            </Button>
          </Box>
        </Fragment>
      )}
    </Form>
  );
}
