import { Box, css } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Theme } from '@emotion/react';

import { Icon, RouterLink, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { useCurrentUserGroups } from 'shared/hooks';
import { buildUrl } from 'shared/routes';
import { PaperLayout } from 'shared/components/layouts';

import { JoinedGroupListItem } from './JoinedGroupListItem';

const titleCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize + 4}px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 25px;
  margin-left: ${theme.spacing(0.8)}px;
`;

const groupsListCss = css`
  padding: 1rem;
  padding-top: 0;
`;

const viewAllLinkCss = (theme: Theme) => css`
  color: ${theme.palette.secondary.light};
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  letter-spacing: -0.09px;
  line-height: 24px;
`;

export function JoinedGroupList() {
  const { push: navigate } = useHistory();
  const { data: myGroups } = useCurrentUserGroups();

  const onClickGroup = (id: string | undefined | null) => {
    if (!id) {
      return;
    }

    const url = buildUrl(APP_URL.user.groups.details, { pathParams: { id } });

    navigate(url);
  };

  if (!myGroups.length) {
    return null;
  }

  return (
    <PaperLayout>
      <Box p={2.75} display="flex" alignItems="center" justifyContent="space-between" mt={2}>
        <Box display="flex" alignItems="center">
          <Icon name="Groups" />
          <Typography css={titleCss} variant="subtitle5">
            My Groups
          </Typography>
        </Box>
        <RouterLink
          css={viewAllLinkCss}
          color="secondary"
          absolute={false}
          to={APP_URL.user.groups.index}
        >
          View All
        </RouterLink>
      </Box>

      <Box css={groupsListCss}>
        {myGroups.map(item => (
          <JoinedGroupListItem
            key={item?.id}
            logo={item?.logo}
            onClick={() => onClickGroup(item.id)}
            title={item?.title}
          />
        ))}
      </Box>
    </PaperLayout>
  );
}
