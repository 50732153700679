import { useState } from 'react';

interface Initial<P> {
  /**
   * Set the initial value for the `show` state.
   * @default false
   */
  show?: boolean;
  
  /**
   * Set the initial value for the `params` state.
   * @default undefined
   */
  params?: P;
}

// eslint-disable-next-line prettier/prettier
export type ModalState<P> = ReturnType<typeof useModalState<P>>;

const ANIMATION_TIMING = 300;

/**
 * Creates a stateful set of features to manage a modal.
 */
export function useModalState<P>(initial?: Initial<P>) {
  const [show, setShow] = useState(initial?.show ?? false);
  const [haveBeenOpened, setHaveBeenOpened] = useState(false);
  const [params, setParams] = useState<P | undefined>(initial?.params);

  const open = (params?: P) => {
    setParams(params);

    setShow(true);
    setHaveBeenOpened(true);
  };

  const reset = () => {
    setShow(false);
    setParams(undefined);
  };

  const close = () => {
    setShow(false);
    
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      // Clear params before a timeout to let the animation to finish
      setParams(undefined);
    }, ANIMATION_TIMING);
  };

  return {
    show,
    /**
     * Indicates if the modal has been opened at least once.
     * This is useful to prevent unnecessary requests.
     */
    haveBeenOpened,
    params,

    open,
    close,
    reset,
  };
}
