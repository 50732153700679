import { ListItemIcon, ListItemText, MenuItem, debounce } from '@material-ui/core';
import { useCallback } from 'react';
import { css } from '@emotion/react';

import { MENTION_MARKUP } from '@jebel/constants';
import { createGroupMentionIdentifier } from '@jebel/utils';

import { createGroupFilter } from 'features/search/utils';
import { GroupsListItemFragment, useGroupsListLazyQuery } from 'shared/graphql';
import {
  MentionDataSource,
  MentionFetcher,
  MentionSuggestion,
  MentionSuggestionRenderer,
} from 'shared/features/mentions/types';
import { GroupAvatar } from 'shared/components/symbols';

const listItemCSS = css`
  max-width: 100%;

  &,
  span,
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const renderSuggestion: MentionSuggestionRenderer<GroupsListItemFragment> = (
  suggestion,
  _search,
  _highlighted,
  index,
  focused,
) => {
  return (
    <MenuItem key={index} selected={focused}>
      <ListItemIcon>
        <GroupAvatar group={suggestion.raw} />
      </ListItemIcon>

      <ListItemText css={listItemCSS} primary={suggestion.display} secondary="Group" />
    </MenuItem>
  );
};

/**
 * Create a `MentionDataSource` with the groups data and configurations needed for `MentionsInput`.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
 * @returns Instance of `MentionDataSource`.
 */
export function useMentionsGroupDataSource(): MentionDataSource {
  const [fetchGroups] = useGroupsListLazyQuery();

  const fetchMentions: MentionFetcher = async (search, setSuggestions) => {
    const filter = createGroupFilter({ search });

    const response = await fetchGroups({
      variables: {
        filter,
        first: search.length > 3 ? undefined : 8,
      },
    });

    const groups = response.data?.groupsList.items ?? [];

    const suggestions = groups.map<MentionSuggestion>(group => {
      return {
        id: createGroupMentionIdentifier(group.id),
        display: group.title as string,
        raw: group,
      };
    });

    setSuggestions(suggestions);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMentionsDebounce = useCallback(debounce(fetchMentions, 300), []);

  return {
    data: fetchMentionsDebounce,
    markup: MENTION_MARKUP,
    renderSuggestion,
  };
}
