import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';
import { useMemo } from 'react';

import { Typography } from 'shared/components/ui';
import { MemberDiscountItemFragment } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';
import { formatTimeAgo } from 'shared/utils/date';
import { useDiscountActivity } from 'features/discounts/hooks/useDiscountActivity';
import { EMPTY_IMAGE } from 'features/settings/assets';

const AVATAR_ICON_WIDTH = 80 * 1.3;
const AVATAR_ICON_HEIGHT = 80;

interface Props {
  discount: MemberDiscountItemFragment;
  onRedeem?(discount: MemberDiscountItemFragment): void;

  /**
   * Show the "redeem" button.
   * @default true
   */
  withRedeem?: boolean;

  /** @default false */
  withExpiration?: boolean;
}

export function DiscountPresentationContent(props: Props) {
  const { addView, addClick } = useDiscountActivity();
  const { configuration: school } = useSchoolConfiguration();

  // eslint-disable-next-line prefer-destructuring
  const discount = props.discount;

  const withRedeem = props.withRedeem ?? false;
  const withExpiration = props.withExpiration ?? false;

  const isOrganizationOffer = Boolean(props.discount?.organization);

  /**
   * Gets the name of the organization or school as fallback.
   * https://8base-dev.atlassian.net/browse/JEB-1267?focusedCommentId=42005
   */
  const offererName = isOrganizationOffer ? props.discount?.organization?.name : school?.fullName;
  /**
   * Gets the logo of the organization or school as fallback.
   * https://8base-dev.atlassian.net/browse/JEB-1267?focusedCommentId=42005
   */
  const offererAvatarSource = isOrganizationOffer
    ? props.discount?.organization?.logo?.downloadUrl
    : school?.images?.logo?.downloadUrl;

  const imageSource = useMemo(() => {
    const [firstImage] = discount?.images?.items ?? [];
    return firstImage?.downloadUrl ?? undefined;
  }, [discount]);

  const expirationDate = useMemo(() => formatTimeAgo(discount.expirationDate), [discount]);

  const handleClick = () => {
    props.onRedeem?.(props.discount);

    if (discount.id) {
      addView(discount.id);
      addClick(discount.id);
    }
  };

  return (
    <Box css={containerCSS} onClick={handleClick}>
      <div css={avatarContainerCSS}>
        <Avatar css={imageCSS} src={imageSource ?? EMPTY_IMAGE} variant="square" />
        <Avatar css={offererAvatarCSS} src={offererAvatarSource ?? EMPTY_IMAGE} variant="square" />
      </div>

      <Box display="grid" gridTemplateRows="auto" alignItems="center" gridGap={4}>
        <Typography color="textSecondary" variant="body2">
          {offererName}
        </Typography>

        <Typography variant="body2" paragraph>
          {discount?.title}
        </Typography>

        {withExpiration && (
          <Typography color="secondary" variant="body2" paragraph>
            Expires {expirationDate}
          </Typography>
        )}

        {withRedeem && (
          <Typography css={redeemButtonCSS} variant="body2" color="secondary">
            Redeem Offer
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const containerCSS = css`
  max-height: ${AVATAR_ICON_HEIGHT};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
`;

const avatarContainerCSS = css`
  position: relative;
`;

const imageCSS = (theme: Theme) => css`
  width: ${AVATAR_ICON_WIDTH}px;
  height: ${AVATAR_ICON_HEIGHT}px;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.palette.divider};
  object-fit: cover;
`;

const offererAvatarCSS = (theme: Theme) => css`
  --size: 2rem;

  width: var(--size);
  height: var(--size);
  border-radius: 0.25rem;
  background-color: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.divider};
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

const redeemButtonCSS = (theme: Theme) => css`
  font-weight: bold;
  color: ${theme.palette.primary.main};
`;
