import React from 'react';

import { PostsContext } from './PostsContext';

/** @deprecated Use `useHomeFeedItemActivity` instead. */
export const usePosts = ({ postId }) => {
  const { activities, loading } = React.useContext(PostsContext);
  const activity = activities.find(activity => activity.foreign_id === postId);

  return {
    loading,
    activity,
    activityId: activity?.id,
  };
};
