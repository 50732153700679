import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';

import { ROUTES } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';
import { useQueryParams, useResponsive } from 'shared/hooks';
import { InboxParams } from 'features/inbox/constants';

import { InboxChatsList } from './InboxChatsList';
import { ChatsContainer, Container, Content, MessagesContainer } from './InboxChats.styles';
import { InboxMessages } from '../InboxMessages';

interface Props {
  currentRoute: string;
}

export const InboxChats = ({ currentRoute }: Props) => {
  const [params] = useQueryParams<InboxParams>();

  const { push: navigate } = useHistory();
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();

  const isAdmin = useMemo(() => {
    return pathname.includes('/admin');
  }, [pathname]);

  const goBack = () => {
    navigate(isAdmin ? ROUTES.admin.mail.index : ROUTES.user.mail.index, { shouldRedirect: false });
  };

  if (isMobile) {
    return (
      <SearchProvider>
        <Container>
          <Button variant="text" startIcon={<KeyboardBackspace />} onClick={goBack}>
            Go Back
          </Button>

          <Content>
            <ChatsContainer>
              <InboxChatsList currentRoute={currentRoute} />
            </ChatsContainer>
          </Content>
        </Container>
      </SearchProvider>
    );
  }

  return (
    <SearchProvider>
      <Container>
        <Content>
          <ChatsContainer>
            <InboxChatsList currentRoute={currentRoute} />
          </ChatsContainer>

          <MessagesContainer>
            {params.inboxId && <InboxMessages currentRoute={currentRoute} />}
          </MessagesContainer>
        </Content>
      </Container>
    </SearchProvider>
  );
};
