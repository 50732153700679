import { useMemo } from 'react';
import { css } from '@emotion/react';
import { List, ListItemText, Box, Button } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import { NavListItemSymbol } from 'shared/components/symbols';
import { Icon, Typography } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { useResponsive } from 'shared/hooks';

import { HEADER_HEIGHT } from '../../constants';

const listItemContainerCss = theme => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(5.5)}px;

  &.Mui-selected {
    border-left: ${theme.spacing(0.5)}px solid ${theme.palette.secondary.light};
    padding-left: ${theme.spacing(5)}px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px;
  }
`;

const listItemIconCss = (theme: any) => css`
  margin-right: ${theme.spacing(1)}px;
  fill: ${theme.palette.text.secondary};
`;

const containerCss = theme => css`
  border-right: 1px solid ${theme.palette.border};
`;

const listCss = (theme: any) => css`
  flex: 1;
  padding-top: 0;
`;

const goBackIconCss = theme => css`
  height: 20px;
  width: 20px;
  margin-right: ${theme.spacing(1)}px;
`;

const goBackCountCss = theme => css`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.palette.text.light};
`;

const goBackButtonCss = theme => css`
  color: ${theme.palette.text.light};
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${listItemContainerCss(theme)}
`;

const headerCss = css`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
`;

const LIST_DATA = (isAdminRoute: boolean) => [
  {
    title: 'Inbox',
    icon: 'MoveToInbox',
    route: isAdminRoute ? APP_URL.admin.mail.inbox : APP_URL.user.mail.inbox,
  },
  {
    title: 'Sent',
    icon: 'Send',
    route: isAdminRoute ? APP_URL.admin.mail.sent : APP_URL.user.mail.sent,
  },
  {
    title: 'Trash',
    icon: 'DeleteForever',
    route: isAdminRoute ? APP_URL.admin.mail.trash : APP_URL.user.mail.trash,
  },
];

export function InboxSidebar() {
  const location = useLocation();
  const history = useHistory();

  const { isDesktop } = useResponsive();

  const isAdminRoute = useMemo(() => location.pathname.includes('/admin'), [location]);

  return (
    <Box flexGrow={1} css={containerCss}>
      {isDesktop && (
        <Box css={headerCss}>
          <Button variant="text" css={goBackButtonCss} onClick={history.goBack}>
            <Icon css={goBackIconCss} name="KeyboardBackspace" variant="filled" />
            <Typography css={goBackCountCss} paragraph={false}>
              Go back
            </Typography>
          </Button>
        </Box>
      )}

      <List css={listCss}>
        {LIST_DATA(isAdminRoute).map(item => (
          <NavListItemSymbol
            css={listItemContainerCss}
            key={item.route}
            path={item.route}
            selected={location.pathname === item.route}
            button
            disableGutters
            startIcon={<Icon name={item.icon} css={listItemIconCss} />}
            listItemText={<ListItemText disableTypography={false}>{item.title}</ListItemText>}
          />
        ))}
      </List>
    </Box>
  );
}
