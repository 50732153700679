import { Fragment, useCallback, useMemo, useState } from 'react';
import {
  Typography,
  IconButton,
  List,
  Box,
  ListItemText,
  ListItem,
  Avatar,
  Chip,
  css,
  useTheme,
  PaperProps,
  Divider,
} from '@mui/material';
import { DateTime } from 'luxon';
import { object, string } from 'yup';

import { ListItemIconSymbol } from 'shared/components/symbols';
import {
  Icon,
  Popover,
  MediaGallery,
  YoutubePreview,
  MediaGalleryPreview,
} from 'shared/components/ui';
import { PaperLayout } from 'shared/components/layouts';
import { useResponsive } from 'shared/hooks';
import { formatPostDate } from 'shared/utils/date';
import { sendToSentry } from 'shared/utils/sentry';
import { MentionsText } from 'shared/features/mentions';

import { PostStreamInteractions, PostStreamInteractionsProps } from './PostStreamInteractions';
import { POST_IMAGE_SIZE } from './Post.constants';
import { CrudPermissionItem } from '../../../../types';

const AVATAR_SIZE = 40;
const AVATAR_MOBILE_SIZE = 30;

const pinnedContentCSS = (isMobile: boolean) => (theme: any) =>
  css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${theme.spacing(isMobile ? 0.5 : 1)}px;
    min-height: 22px;
    margin-bottom: -${theme.spacing(1)}px;
    margin-top: -${theme.spacing(3.5)}px;
  `;

export const ICON_SIZE = 20;
export const ICON_MOBILE_SIZE = 18;

const pinnedIconCss = (isMobile: boolean) => css`
  height: ${isMobile ? ICON_MOBILE_SIZE : ICON_SIZE}px;
  width: ${isMobile ? ICON_MOBILE_SIZE : ICON_SIZE}px;
  margin-right: 2px;
`;

const pinnedTextCss = (isMobile: boolean) => (theme: any) =>
  css`
    text-align: center;
    font-weight: 600;
    font-size: ${theme.typography.fontSize - (isMobile ? 3 : 2)}px;
    text-transform: uppercase;
    line-height: ${theme.typography[isMobile ? 'subtitle2' : 'subtitle3'].lineHeight};
    color: ${theme.palette.secondary.light};
  `;

export const contentContainerCss = theme => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${theme.spacing(1)}px;
`;

const authorAvatarCss = (isMobile: boolean) => css`
  height: ${isMobile ? AVATAR_MOBILE_SIZE : AVATAR_SIZE}px;
  width: ${isMobile ? AVATAR_MOBILE_SIZE : AVATAR_SIZE}px;
`;

export const postHeaderCss = css`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  justify-content: space-between;
`;

export const postHeaderAuthorCss = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const authorNameCss = (isMobile: boolean) => (theme: any) =>
  css`
    font-size: ${isMobile ? 'small' : theme.typography.fontSize + 2};
    font-weight: 500;
    line-height: 21px;
    color: ${theme.palette.primary.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;

export const postDateCss = (isMobile: boolean) => (theme: any) =>
  css`
    font-size: ${isMobile ? 'smaller' : `${theme.typography.fontSize}px`};
    color: ${theme.palette.text.secondary};
    line-height: 18px;
  `;

const popoverTargetCSS = css`
  display: inline-flex;
  justify-content: center;
`;

const popoverCss = css`
  width: 200px;
`;

const popoverItemCss = (theme: any) => css`
  :hover {
    color: #fff;
    background-color: ${theme.palette.primary.light};
    transition: 0.25s;
  }
  padding: ${theme.spacing(1)}px ${theme.spacing(0.75)}px;
`;

const defaultPadding = 4;

const baseContainerStyles = (isMobile: boolean) => ({
  px: isMobile ? 2.25 : defaultPadding,
  pt: isMobile ? 3.5 : defaultPadding,
  pb: isMobile ? 1.5 : defaultPadding,
});

const popoverItemIconCss = css`
  margin-right: 8px;
  font-size: 20px;
`;

export const descriptionContainerCSS = (isMobile: boolean) => (theme: any) =>
  css`
    margin-bottom: ${theme.spacing(isMobile ? 1.25 : 2.5)}px;
  `;

export const descriptionTextCSS = (isMobile: boolean) => theme =>
  css`
    > * {
      margin: 0;
    }
    font-size: ${theme.typography.fontSize + (isMobile ? 1 : 4)}px;
    white-space: pre-line;

    .mention-link {
      color: ${theme.palette.primary.icon};
      text-decoration: none;
    }
  `;

export const dateChipCss = theme => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  height: 20px;
  background-color: ${theme.palette.info.main};
`;

export type PostProps = Omit<PostStreamInteractionsProps, 'commentsAllowed' | 'isAdmin'> & {
  isPinned?: boolean | null;
  commentsAllowed?: boolean | number | null;
  createdAt?: any;
  postDate?: string | null;
  text?: string | null;
  media?:
    | string
    | {
        __typename?: 'FileListResponse';
        items: Array<{
          __typename?: 'File';
          id?: string | null;
          downloadUrl?: string | null;
          meta?: any;
        }>;
      }
    | null;
  author?: unknown;
  onPostEdit?: any;
  onPostDelete?: any;
  onPostFlag?: any;
  withProfilePostSight?: boolean | null;
  withInteractions?: boolean;
  isLegacyPost?: boolean;
  legacyDate?: string;
  legacyChipTheme?: 'blue' | 'red';
  isOwnPost?: boolean;
  isFlaggable?: boolean;
  mentions?: any;
  containerProps?: {
    sx: PaperProps['sx'];
  };
  permissions?: CrudPermissionItem;
  disableComments?: boolean;
  disableReactions?: boolean;
};

type PostInteractionsMap = {
  label: string;
  iconName: string;
  onClick: () => any;
}[];

/** @deprecated Use `HomeFeedCard` instead. */
export function Post({
  legacyDate,
  legacyChipTheme = 'blue',
  isPinned,
  createdAt,
  postDate,
  text,
  media,
  id,
  commentsAllowed,
  onPostDelete,
  onPostEdit,
  onPostFlag,
  onCommentAdd,
  onCommentRemove,
  onLikeAdd,
  onReactionRemove,
  withProfilePostSight = false,
  withInteractions = true,
  isLegacyPost = false,
  isOwnPost = false,
  isFlaggable = false,
  mentions,
  containerProps,
  permissions,
  disableComments = false,
  disableReactions = false,
  ...props
}: PostProps) {
  const [youtubeVideoId, setYoutubeVideoId] = useState<string | undefined>(undefined);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const { palette } = useTheme();
  const { isMobile } = useResponsive();

  const formattedDate = useMemo(() => formatPostDate(postDate ?? createdAt), [createdAt, postDate]);

  const author = useMemo(() => {
    if (!props.author) {
      return null;
    }

    try {
      const schema = object({
        id: string().optional(),
        name: string().optional().nullable(),
        firstName: string().optional().nullable(),
        lastName: string().optional().nullable(),

        avatar: object({
          downloadUrl: string().url().nullable().optional(),
        })
          .nullable()
          .optional(),
      })
        .nullable()
        .optional();

      const content = typeof props.author === 'string' ? JSON.parse(props.author) : props.author;
      return schema.cast(content);
    } catch (err) {
      sendToSentry(err);
    }

    return null;
  }, [props.author]);

  const authorName = useMemo(() => {
    if (author && author.name) {
      return author.name;
    }

    if (author && author.firstName) {
      const firstName = author.firstName ?? '';
      const lastName = author.lastName ?? '';

      return `${firstName} ${lastName}`;
    }

    return '(Unknown)';
  }, [author]);

  const authorAvatarSource = useMemo(() => {
    return author?.avatar?.downloadUrl ?? undefined;
  }, [author]);

  const openPopover = () => {
    setIsOpenPopover(true);
  };

  const closePopover = () => {
    setIsOpenPopover(false);
  };

  const interactions: PostInteractionsMap = useMemo(() => {
    const interactions: PostInteractionsMap = [];

    const canEdit = (!permissions && isOwnPost) || permissions?.edit === true;
    const canRemove = (!permissions && isOwnPost) || permissions?.delete === true;
    const canReport = !isOwnPost && isFlaggable;

    if (onPostEdit && canEdit) {
      interactions.push({
        label: 'Edit Post',
        iconName: 'Edit',
        onClick: onPostEdit,
      });
    }

    if (onPostDelete && canRemove) {
      interactions.push({
        label: 'Delete Post',
        iconName: 'DeleteForever',
        onClick: onPostDelete,
      });
    }

    if (isFlaggable && canReport) {
      interactions.push({
        label: 'Flag as Inappropriate',
        iconName: 'Flag',
        onClick: onPostFlag,
      });
    }

    return interactions;
  }, [isOwnPost, isFlaggable, onPostFlag, permissions, onPostEdit, onPostDelete]);

  const onCheckYoutube = useCallback((youtubeVideoId: string) => {
    setYoutubeVideoId(youtubeVideoId);
  }, []);

  const isMediaExist: boolean = useMemo(() => {
    if (!media) {
      return false;
    }

    if (typeof media === 'string') {
      const parsed = JSON.parse(media);

      if (parsed?.items && Array.isArray(parsed.items)) {
        return parsed.items.length > 0;
      }

      return false;
    }

    return media?.items?.length !== 0;
  }, [media]);

  return (
    <PaperLayout sx={{ ...baseContainerStyles(isMobile), ...containerProps?.sx }}>
      {!!isPinned && (
        <Box css={pinnedContentCSS(isMobile)}>
          <Icon css={pinnedIconCss(isMobile)} name="PushPin" variant="filled" htmlColor="#EF5C5C" />
          <Typography css={pinnedTextCss(isMobile)} variant="subtitle2" paragraph={false}>
            PINNED CONTENT
          </Typography>
        </Box>
      )}

      <Box css={contentContainerCss}>
        <Avatar css={authorAvatarCss(isMobile)} src={authorAvatarSource} />

        <Box css={postHeaderCss}>
          <Box css={postHeaderAuthorCss}>
            <Typography css={authorNameCss(isMobile)} paragraph={false}>
              {authorName}
            </Typography>
            <Typography css={postDateCss(isMobile)} paragraph={false}>
              {formattedDate}
            </Typography>
          </Box>

          {withInteractions && !!interactions.length && (
            <Popover
              forceOpen={isOpenPopover}
              onClose={closePopover}
              target={
                <Box css={popoverTargetCSS} onClick={openPopover}>
                  <IconButton size="medium">
                    <Icon name="MoreHoriz" />
                  </IconButton>
                </Box>
              }
              content={() => (
                <Fragment>
                  <List css={popoverCss} dense={false} disablePadding={false}>
                    {interactions.map(interaction => {
                      const onClick = () => {
                        interaction.onClick();
                        closePopover();
                      };

                      return (
                        <ListItem
                          key={interaction.label}
                          css={popoverItemCss}
                          onClick={onClick}
                          button
                          disableGutters
                        >
                          <ListItemIconSymbol
                            css={popoverItemIconCss}
                            name={interaction.iconName}
                            variant="filled"
                          />
                          <ListItemText>{interaction.label}</ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Fragment>
              )}
            />
          )}
        </Box>
      </Box>

      <Box css={descriptionContainerCSS(isMobile)}>
        <Box css={descriptionTextCSS(isMobile)}>
          {isLegacyPost && (
            <Fragment>
              <Chip
                css={dateChipCss}
                sx={{
                  mr: 1,
                  backgroundColor: legacyChipTheme === 'red' ? palette.error.light : 'inherit',
                }}
                label={DateTime.fromISO(legacyDate ?? '')
                  .setLocale('en-US')
                  .toFormat('DDD')}
              />
              &nbsp;
            </Fragment>
          )}

          <MentionsText text={text ?? ''} />
        </Box>
      </Box>

      {typeof media === 'string' ? (
        <MediaGalleryPreview media={media} height={isMobile ? '100%' : POST_IMAGE_SIZE} />
      ) : (
        <MediaGallery media={media?.items || []} height={isMobile ? '100%' : POST_IMAGE_SIZE} />
      )}

      {youtubeVideoId && !isMediaExist && (
        <Fragment>
          <Box mb={2}>
            <Divider />
          </Box>

          <YoutubePreview embedId={youtubeVideoId} />
        </Fragment>
      )}

      <Box mt={1}>
        {!disableReactions && (
          <PostStreamInteractions
            id={id}
            onCommentAdd={onCommentAdd}
            onLikeAdd={onLikeAdd}
            onReactionRemove={onReactionRemove}
            commentsAllowed={!!commentsAllowed}
            withProfilePostSight={withProfilePostSight}
            onCommentRemove={onCommentRemove}
            disableComments={disableComments}
          />
        )}
      </Box>
    </PaperLayout>
  );
}
