import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';

import { Typography } from 'shared/components/ui';
import {
  GroupPostsListItemQuery,
  HomeFeedPostsListItemQuery,
  LegacyFeedPostsListItemQuery,
  Maybe,
  SchoolNewsPostsListItemQuery,
} from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';

const nameCss = (theme: any) => css`
  color: ${theme.palette.info.light};
`;

const flagReasonCss = css`
  font-weight: 500;
`;

const textCss = css`
  font-weight: 500;
`;

type PostType =
  | GroupPostsListItemQuery['groupPostsList']['items'][0]
  | HomeFeedPostsListItemQuery['homeFeedPostsList']['items'][0]
  | LegacyFeedPostsListItemQuery['legacyFeedPostsList']['items'][0]
  | SchoolNewsPostsListItemQuery['schoolNewsPostsList']['items'][0]
  | undefined;

interface Props {
  post: PostType;
  isAutoReported?: Maybe<boolean>;
}

const REPORT_ENGINE_NAME = 'SightEngine';

export const FlaggedPostDetailsHeader = ({ post, isAutoReported }: Props) => {
  const author = React.useMemo(() => {
    const [flaggedBy] = post?.flaggedBy?.items ?? [];

    if (isAutoReported) {
      return REPORT_ENGINE_NAME;
    }

    return formatUserName(post?.lastFlaggedBy ?? flaggedBy);
  }, [post, isAutoReported]);

  const date =
    post?.flaggedAt && DateTime.fromISO(post?.flaggedAt).setLocale('en-US').toFormat('DDD - t');

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary" css={textCss}>
          Post flagged by
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography css={nameCss} variant="body2">
          {author}
        </Typography>
        <Typography>&nbsp;</Typography>
        {date && (
          <React.Fragment>
            <Typography color="textSecondary" variant="body2" css={textCss}>
              on
            </Typography>
            <Typography>&nbsp;</Typography>
            <Typography color="textSecondary" variant="body2" css={textCss}>
              {`${date.slice(0, date.length - 2) + (date.slice(-2)[0] === 'A' ? 'a.' : 'p.')}m.`}
            </Typography>
          </React.Fragment>
        )}
      </Box>

      {(isAutoReported || post?.flagReason) && (
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" css={textCss}>
            Flag reason:
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="body1" color="primary" css={flagReasonCss}>
            {isAutoReported ? `Autoflagged by ${REPORT_ENGINE_NAME}` : post?.flagReason}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};
