import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

import { Button, Modal } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { useOrganizationById } from 'features/organizations/hooks';
import { SxProp } from 'shared/types/styles';
import { useCrudPermissions } from 'shared/hooks';

import { BusinessManagersSpreadsheet } from './BusinessManagersSpreadsheet';
import { BusinessManagersInviteModal } from '../components/BusinessModals';

type BusinessManagersProps = {
  businessId: string;
};

const titleStyles: SxProp = theme => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  fontSize: theme.typography.fontSize + 7,
});

export const BusinessManagers: React.FC<BusinessManagersProps> = ({ businessId }) => {
  const {
    businessPermissions: { edit: ifEditAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const canInvite = ifEditAccessed && !loadingAddons;

  const [isInviteManagerOpen, setIsInviteManagerOpen] = React.useState(false);

  const { refetch } = useOrganizationById(businessId);

  const onInviteModalOpen = React.useCallback(() => setIsInviteManagerOpen(true), []);
  const onInviteModalClose = React.useCallback(() => setIsInviteManagerOpen(false), []);
  const onInviteSuccess = React.useCallback(() => refetch(), [refetch]);

  return (
    <React.Fragment>
      <Box>
        <Box display="flex" p={3} alignItems="center" justifyContent="space-between">
          <Typography sx={titleStyles}>Managers</Typography>
          {canInvite && (
            <Button variant="outlined" onClick={onInviteModalOpen}>
              Invite managers
            </Button>
          )}
        </Box>

        <Divider />

        <SearchProvider>
          <SpreadsheetProvider>
            <BusinessManagersSpreadsheet id={businessId} />
          </SpreadsheetProvider>
        </SearchProvider>
      </Box>

      <Modal
        isVisibleDivider
        titleProps={{ title: 'Invite Managers' }}
        dialogProps={{ open: isInviteManagerOpen, onClose: onInviteModalClose }}
      >
        <BusinessManagersInviteModal
          onModalClose={onInviteModalClose}
          onRequestSuccess={onInviteSuccess}
          additionalData={{ businessId }}
        />
      </Modal>
    </React.Fragment>
  );
};
