import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import pluralize from 'pluralize';
import { IconButton, Box } from '@material-ui/core';
import { FilterAlt } from '@mui/icons-material';

import { EVENT_STATUSES } from '@jebel/constants';

import {
  Button,
  AsyncContent,
  SearchTextField,
  SortInfoField,
  SortInfoOption,
  Modal,
  Typography,
} from 'shared/components/ui';
import { EVENTS_SORT_INFO, EVENTS_SORT_INFO_NO_ADDRESS, SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { useEventSearch, usePageTitle, useResponsive } from 'shared/hooks';
import { RouteLayout, PaperLayout } from 'shared/components/layouts';
import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { EventsList, EventsCreateModal, EventFilterSidebar } from '../components/Events';
import { EventFilterModal } from '../components/Events/EventFilterModal';
import { EventZipFilterType } from '../types';

const searchSortEventsBox = (isMobile: boolean) => (theme: any) =>
  css`
    padding-top: ${theme.spacing(isMobile ? 1.5 : 3.125)}px;
    display: grid;
    ${!isMobile ? `grid-template-columns: 10fr 4.5fr;` : `grid-template-rows: auto;`}
    grid-gap: ${theme.spacing(2.5)}px;
    align-items: center;
  `;

const sortBoxCSS = css`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 0.5rem;
`;

const eventsCss = css`
  font-weight: 400;
`;

const searchResultCountBox = css`
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: auto auto;
  min-height: auto;
  justify-content: space-between;
`;

const rightColumn = theme => css`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(3.125)}px;
`;

const eventsQuantityFound = (theme: any) => css`
  font-size: 1rem;
  font-weight: 500;
  padding: ${theme.spacing(3.125)}px 0;
  color: ${theme.palette.primary.icon};
  min-height: auto;
  line-height: 19px;
`;

type Status = keyof typeof EVENT_STATUSES;

export function EventsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setModal] = useState(false);
  const [status, setStatus] = useState<Status>('active');
  const [userIsTyping, setIsUserTyping] = useState(false);

  const { isMobile } = useResponsive();

  const title = usePageTitle({ fallback: 'Events' });

  const showPastButton = status !== 'expired';
  const showUpcomingButton = status !== 'active';

  const [zipFilter, setZipFilter] = useState<EventZipFilterType>({
    // Use a empty zip-code to avoid users to think there's no events
    // https://8base-dev.atlassian.net/browse/JEB-1579?focusedCommentId=43487
    zip: '',
    distance: '-',
  });

  const [selectedSortOption, setSelectedSortOption] = useState<SortInfoOption>(
    EVENTS_SORT_INFO.options[0].value,
  );

  const sufix: string = useMemo(() => {
    if (status === 'active') {
      return 'upcoming';
    }

    if (status === 'expired') {
      return 'past';
    }

    return '';
  }, [status]);

  const { data, count, loading, refreshing, hasMore, fetchMore } = useEventSearch({
    searchQuery,
    sortInfo: selectedSortOption,
    zipFilter,
    status,
  });

  const handleChangeFilter = (filter: EventZipFilterType) => {
    setZipFilter(filter);
  };

  const onClose = useCallback(() => {
    setModal(false);
  }, [setModal]);

  const onRequestNewEvent = useCallback(() => {
    setModal(true);
  }, [setModal]);

  const [isEventFilterOpened, setIsEventFilterOpened] = useState(false);

  const onEventFilterOpen = () => {
    setIsEventFilterOpened(true);
  };

  const onEventFilterClose = () => {
    setIsEventFilterOpened(false);
  };

  const swithPast = () => {
    setStatus('expired');
  };

  const swithUpcoming = () => {
    setStatus('active');
  };

  return (
    <RouteLayout
      withMaxWidth
      content={
        <Box width="100%">
          <Modal
            dialogProps={{
              open: isOpen,
              onClose,
              fullWidth: true,
              maxWidth: isMobile ? 'xs' : 'sm',
            }}
            titleProps={{ title: 'Request New Event' }}
            dialogContentWidth={isMobile ? 280 : undefined}
          >
            <EventsCreateModal onModalClose={onClose} />
          </Modal>

          <Modal
            dialogProps={{
              open: isEventFilterOpened,
              onClose: onEventFilterClose,
              fullWidth: true,
              maxWidth: 'xs',
            }}
            titleProps={{ title: 'Filter by' }}
            dialogContentWidth={280}
          >
            <EventFilterModal
              initial={zipFilter}
              onClose={onEventFilterClose}
              onChange={handleChangeFilter}
            />
          </Modal>

          <PaperLayout sx={{ py: 3.125, px: isMobile ? 2 : 3.75 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2" color="primary" css={isMobile && eventsCss}>
                {title}
              </Typography>

              <Button color="primary" variant="contained" size="small" onClick={onRequestNewEvent}>
                Request New Event
              </Button>
            </Box>

            <Box css={searchSortEventsBox(isMobile)}>
              <SearchTextField
                setIsUserTyping={setIsUserTyping}
                sortOption={selectedSortOption}
                placeholder="Search"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />

              <Box css={sortBoxCSS}>
                <SortInfoField
                  activeSortOption={selectedSortOption}
                  setSortOption={setSelectedSortOption}
                  sortInfo={zipFilter?.zip ? EVENTS_SORT_INFO : EVENTS_SORT_INFO_NO_ADDRESS}
                />

                <Box>
                  {isMobile && (
                    <IconButton onClick={onEventFilterOpen}>
                      <FilterAlt />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </PaperLayout>

          <AsyncContent loading={loading || userIsTyping} stretch>
            <InfiniteScroll fetching={refreshing} hasMore={hasMore} fetchMore={fetchMore}>
              <Box css={searchResultCountBox}>
                <Typography
                  css={eventsQuantityFound}
                  variant="inherit"
                  align="inherit"
                  gutterBottom={false}
                  noWrap={false}
                  paragraph={false}
                >
                  {count} {sufix} {pluralize('event', count)}
                </Typography>

                {showPastButton && (
                  <Button color="primary" onClick={swithPast}>
                    View past events
                  </Button>
                )}

                {showUpcomingButton && (
                  <Button color="primary" onClick={swithUpcoming}>
                    View upcoming events
                  </Button>
                )}
              </Box>

              <EventsList eventsList={data} />
            </InfiniteScroll>
          </AsyncContent>
        </Box>
      }
      sidebar={
        <Box css={rightColumn}>
          <PaperLayout sx={{ px: 3.75, py: 3.125 }}>
            <EventFilterSidebar initial={zipFilter} onChange={handleChangeFilter} />
          </PaperLayout>

          <CommunitySidebar items={[SUPPORTERS]} />
        </Box>
      }
    />
  );
}
