import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import React from 'react';

import { Button, Icon, Typography } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import { ZipFilterField } from 'shared/features/search/components/ZipFilterField';

interface DiscountsFiltersModalType {
  onModalClose: () => void;
  currentLocationLoading: boolean;
  onPositionGet: () => Promise<void>;
  setLocalZipQuery: React.Dispatch<React.SetStateAction<string>>;
}

const currentLocationCSS = (theme: any) => css`
  font-weight: 500;
  font-size: ${theme.typography.fontSize}px;
  line-height: 24px;
  color: ${theme.palette.secondary.light};
  white-space: nowrap;
`;

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const iconCss = (theme: any) => css`
  fill: ${theme.palette.secondary.light};
`;

export const DiscountsFiltersModal: React.FC<DiscountsFiltersModalType> = ({
  onModalClose,
  currentLocationLoading,
  onPositionGet,
  setLocalZipQuery,
}) => {
  const { zipQuery } = useSearchContext();

  const onApplyFilter = React.useCallback(() => {
    setLocalZipQuery(zipQuery);
    onModalClose();
  }, [onModalClose, setLocalZipQuery, zipQuery]);

  return (
    <React.Fragment>
      <ZipFilterField {...{ placeholder: 'ZIP Code' }} />
      <Box display="flex" alignItems="center">
        <Icon onClick={onPositionGet} css={iconCss} name="MyLocation" variant="filled" />
        <Button onClick={onPositionGet} loading={currentLocationLoading}>
          <Typography css={currentLocationCSS}>Use current location</Typography>
        </Button>
      </Box>

      <Box display="flex" justifyContent="end">
        <Button css={cancelButtonCSS} onClick={onModalClose} size="medium">
          CANCEL
        </Button>
        <Button css={cancelButtonCSS} onClick={onApplyFilter} size="medium">
          APPLY
        </Button>
      </Box>
    </React.Fragment>
  );
};
