import { useLegacyFeed } from 'features/legacy/hooks';
import { Typography } from 'shared/components/ui';
import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { LegacyPostsListItem } from './LegacyPostsListItem';

export function LegacyPostsList() {
  const {
    data: items,
    hasMore,
    refreshing,
    fetchMore,
  } = useLegacyFeed({
    withSchedule: true,
    withManyPinned: true,
    withPinnedOnTop: false,
  });

  return (
    <InfiniteScroll hasMore={hasMore} fetchMore={fetchMore} fetching={refreshing}>
      {items.map(post => (
        <LegacyPostsListItem key={post.id} post={post} />
      ))}

      {items.length === 0 && <Typography variant="subtitle5">No posts found</Typography>}
    </InfiniteScroll>
  );
}
