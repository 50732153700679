import { GetRoutesFn, ProtectedRoute } from 'shared/routes';
import { APP_URL } from 'shared/constants';
import { InboxPage } from 'features/inbox/pages/InboxPage';

export const getInboxRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.mail.index,
    exact: false,
    component: InboxPage,
  },
];
