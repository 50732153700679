import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  createSnackMessage,
  Modal,
  SNACK_TYPES,
  Typography,
} from 'shared/components/ui';

import { JOB_EMPLOYEE_DELETE_MUTATION } from '../../queries';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from '../../../../shared/constants';
import { sendToSentry } from '../../../../shared/utils/sentry';

type ExperienceDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
};

export const ExperienceDeleteModal = ({ isOpen, onClose, id }: ExperienceDeleteModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [jobEmployeeDelete, { loading: isLoading }] = useMutation(JOB_EMPLOYEE_DELETE_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Experience successfully deleted',
      [SNACKBAR_ERROR_MESSAGE]: 'Could not delete Experience',
    },
    refetchQueries: ['UserPublicInfo'],
  });

  const onDelete = async () => {
    try {
      const response = await jobEmployeeDelete({
        variables: {
          id,
        },
      });

      if (response?.data?.jobEmployeeDelete.success) {
        onClose();
      }
    } catch (e: any) {
      enqueueSnackbar('Could not delete work experience', {
        autoHideDuration: 5000,
        content: createSnackMessage(SNACK_TYPES.error),
      });
      sendToSentry(e);
      onClose();
    }
  };

  return (
    <Modal
      dialogProps={{
        fullWidth: true,
        onClose,
        open: isOpen,
      }}
      isVisibleDivider
      titleProps={{ title: `Delete experience` }}
    >
      <Box display="grid" css={{ gap: 20 }}>
        <Typography>Are you sure you want to delete this work experience?</Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="text" onClick={onClose} disabled={isLoading} css={{ marginRight: 15 }}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onDelete} loading={isLoading}>
            Delete Experience
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
