import { css } from '@emotion/react';
import styled from '@emotion/styled';

const containerCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

// Container

export const Container = styled.div`
  ${containerCSS};

  padding: 0.5rem 1rem;
  background-color: ${p => p.theme.palette.secondary.light};
  font-size: ${p => p.theme.typography.body2.fontSize};
  color: ${p => p.theme.palette.background.light};
  text-align: center;
  position: sticky;
  z-index: 10;
  top: 0;

  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

// Header

export const Header = styled.div`
  ${containerCSS};

  display: inline-flex;
`;

// Title

export const Title = styled.span`
  font-weight: bold;
`;

// Text

export const Text = styled.span``;

// Link

export const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

// Buttons

export const Buttons = styled.div`
  position: absolute;
  right: 1rem;
`;
