import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import {
  useCreateGraduatingYearMutation,
  useEditGraduatingYearMutation,
  useDeleteGraduatingYearMutation,
  GraduatingYearsMetricsQuery,
} from 'shared/graphql';
import { useSpreadsheetContext } from 'shared/features/spreadsheet';
import { useSearchContext, useSpreadsheetSearch } from 'shared/features/search';
import { getFilterUserByName } from 'shared/utils/search';
import { GRADUATING_YEARS_METRICS_QUERY } from 'features/scorecard/queries';

export const useGraduatingYearsSpreadsheetInfo = () => {
  const { filter, queryParams } = useSpreadsheetContext();
  const { searchQuery } = useSearchContext();

  const yearsFilter = !isNaN(Number(searchQuery))
    ? [
        { year: { equals: Number(searchQuery) } },
        { graduateCount: { equals: Number(searchQuery) } },
      ]
    : [];

  const variables = {
    ...queryParams,
    filter: searchQuery
      ? {
          OR: [...yearsFilter, ...getFilterUserByName(searchQuery)],
          ...filter.query,
        }
      : { ...filter.query },
  };

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<GraduatingYearsMetricsQuery>({
      query: GRADUATING_YEARS_METRICS_QUERY,
      searchingFields: [],
      queryVariables: variables,
      fetchPolicy: 'network-only',
    });

  const [createGraduatingYear, { loading: creating }] = useCreateGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been created',
      [SNACKBAR_ERROR_MESSAGE]: `Error! This class already exists in the Class Scorecard. Please review and try again`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  const [editGraduatingYear, { loading: editing }] = useEditGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been edited successfully',
      [SNACKBAR_ERROR_MESSAGE]: `Error while editing class!`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  const [deleteGraduatingYear, { loading: deleting }] = useDeleteGraduatingYearMutation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! The class has been deleted',
      [SNACKBAR_ERROR_MESSAGE]: `Error while deleting class!`,
    },
    refetchQueries: ['GraduatingYearsMetrics'],
  });

  return {
    tableData,
    tableLoading,
    createGraduatingYear,
    creating,
    editGraduatingYear,
    editing,
    deleteGraduatingYear,
    deleting,
    queryVariables,
  };
};
