import { IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';

import { renderImage, renderVideo } from './utils';
import { Media, Image } from './types';
import {
  CarouselButton,
  CarouselContainer,
  CarouselContent,
  Dialog,
  Header,
} from './GalleryCarouselDialog.styles';

interface Props {
  isOpen: boolean;

  /** @deprecated Use `media` instead. */
  images?: Array<Image>;
  media?: Array<Media>;
  activeIndex?: number;

  onClose(): void;
}

export function GalleryCarouselDialog(props: Props) {
  const [index, setIndex] = useState(() => props.activeIndex ?? 0);

  useEffect(() => {
    setIndex(prev => props.activeIndex ?? prev);
  }, [props.activeIndex, setIndex]);

  const elements = useMemo(() => {
    const media = props.media ?? [];
    /** @deprecated Remove when the `image` props does. */
    const images = props.images ?? [];

    return media.concat(images);
  }, [props.images, props.media]);

  const element = useMemo(() => {
    const element = elements[index];

    if (!element) {
      return null;
    }

    return renderMedia(element);
  }, [elements, index]);

  const canGoBack = index > 0;
  const canGoNext = index < elements.length - 1;

  const goBack = () => {
    if (canGoBack) {
      setIndex(prev => prev - 1);
    }
  };

  const goNext = () => {
    if (canGoNext) {
      setIndex(prev => prev + 1);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={props.isOpen} onClose={props.onClose}>
      <Header>
        <IconButton color="inherit" onClick={props.onClose}>
          <Close color="inherit" />
        </IconButton>
      </Header>

      <CarouselContainer>
        {canGoBack && (
          <CarouselButton onClick={goBack}>{canGoBack && <ArrowBack />}</CarouselButton>
        )}

        <CarouselContent>{element}</CarouselContent>

        {canGoNext && (
          <CarouselButton onClick={goNext}>
            <ArrowForward />
          </CarouselButton>
        )}
      </CarouselContainer>
    </Dialog>
  );
}

const renderMedia = ({ mimetype, ...renderOptions }: Media) => {
  return mimetype === 'video' ? renderVideo(renderOptions) : renderImage(renderOptions);
};
