import { ReactNode } from 'react';
import { Edit, Favorite, Forum } from '@material-ui/icons';
import { Button, IconButton, Tooltip } from '@material-ui/core';

import { useResponsive } from 'shared/hooks';

import { Buttons } from './ExplorerJobPostCard.styles';

export interface ExplorerJobPostCardButtonsProps {
  isSaved?: boolean;

  disableSave?: boolean;
  disableEdit?: boolean;

  withMessage: boolean;
  withSave: boolean;
  withEdit: boolean;
  /** Allow to extend the current button options. */
  addons?: ReactNode;

  onOpenMessage?(): void;
  onOpenEdit?(): void;
  onToggleSave?(): void;
}

export function ExplorerJobPostCardButtons(props: ExplorerJobPostCardButtonsProps) {
  return (
    <Buttons>
      {props.withMessage && <MessageButton onClick={props.onOpenMessage} />}

      {props.withSave && (
        <SaveButton
          disabled={props.disableSave}
          isSaved={props.isSaved}
          onClick={props.onToggleSave}
        />
      )}

      {props.withEdit && <EditButton disabled={props.disableEdit} onClick={props.onOpenEdit} />}

      {props.addons}
    </Buttons>
  );
}

function MessageButton(props: { onClick?(): void }) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Button
        size="small"
        color="primary"
        startIcon={<Forum fontSize="small" />}
        onClick={props.onClick}
      >
        Message
      </Button>
    );
  }

  return (
    <Tooltip title="Message">
      <IconButton color="primary" onClick={props.onClick}>
        <Forum fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

function SaveButton(props: { isSaved?: boolean; disabled?: boolean; onClick?(): void }) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Button
        size="small"
        disabled={props.disabled}
        color={props.isSaved ? 'secondary' : 'primary'}
        onClick={props.onClick}
        startIcon={<Favorite fontSize="small" />}
      >
        Save
      </Button>
    );
  }

  return (
    <Tooltip title="Save">
      <IconButton
        disabled={props.disabled}
        color={props.isSaved ? 'secondary' : 'primary'}
        onClick={props.onClick}
      >
        <Favorite fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

function EditButton(props: { disabled?: boolean; onClick?(): void }) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Button
        size="small"
        color="primary"
        disabled={props.disabled}
        startIcon={<Edit fontSize="small" />}
        onClick={props.onClick}
      >
        Edit
      </Button>
    );
  }

  return (
    <Tooltip title="Edit">
      <IconButton disabled={props.disabled} color="primary" onClick={props.onClick}>
        <Edit fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
