import React from 'react';
import { Box, Tabs, Tab, Divider } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import { GroupInfoFragment } from 'shared/graphql/__generated__';

import { useGroupDelete } from '../../hooks';
import { GroupDetailsInformation } from './GroupDetailsInformation';
import { GroupDetailsMembers } from './GroupDetailsMembers';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const GroupDetailsModal = ({
  group,
  onModalClose,
}: {
  group: GroupInfoFragment | undefined;
  onModalClose: () => void;
}) => {
  const [value, setValue] = React.useState<number>(0);
  const { onGroupDelete } = useGroupDelete();
  const handleChange = React.useCallback((_, newValue: number) => setValue(newValue), []);

  const onDeleteGroupClick = React.useCallback(async () => {
    await onGroupDelete(group?.id ?? '');
    onModalClose();
  }, [onModalClose, onGroupDelete, group?.id]);

  return (
    <React.Fragment>
      <Box mt={-1} ml={-3}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Information" />
          <Tab label="Members" />
        </Tabs>
      </Box>
      <Box mb={2} mx={{ xs: 0, md: -3 }}>
        <Divider />
      </Box>
      <TabPanel value={value} index={0}>
        <GroupDetailsInformation
          group={group}
          onModalClose={onModalClose}
          onDeleteGroupClick={onDeleteGroupClick}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GroupDetailsMembers
          group={group}
          onModalClose={onModalClose}
          onDeleteGroupClick={onDeleteGroupClick}
        />
      </TabPanel>
    </React.Fragment>
  );
};
