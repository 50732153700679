import { useMemo } from 'react';

import { POST_REPORT_STATUS } from '@jebel/constants';

import { ChooseStatusChip, ChooseStatusChipOption } from 'shared/components/ui';

interface Props {
  status: string;
  options?: ChooseStatusChipOption[];
}

const POST_STATUS_COLORS = {
  [POST_REPORT_STATUS.postAllowed]: '#01B9A1',
  [POST_REPORT_STATUS.pendingReview]: '#EF5C5C',
  [POST_REPORT_STATUS.postDeleted]: '#FF782A',
};

const POST_STATUS_LABEL = {
  [POST_REPORT_STATUS.postAllowed]: 'Post Allowed',
  [POST_REPORT_STATUS.pendingReview]: 'Pending Review',
  [POST_REPORT_STATUS.postDeleted]: 'Deleted',
};

export function ChooseContentCurationStatusChip(props: Props) {
  const color = useMemo(() => {
    return POST_STATUS_COLORS[props.status];
  }, [props.status]);

  const label = useMemo(() => {
    return POST_STATUS_LABEL[props.status];
  }, [props.status]);

  return <ChooseStatusChip label={label} color={color} options={props.options} />;
}
