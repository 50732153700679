import { gql } from '@apollo/client';

import { GroupStatus } from '@jebel/constants';

const GROUP_MEMBER_FRAGMENT = gql`
  fragment GroupMember on User {
    id
    firstName
    lastName
    email
    affiliation
    avatar {
      id
      downloadUrl
    }
    groupAdminPreferences {
      id
      group {
        items {
          id
        }
      }
    }
  }
`;

export const GROUP_FRAGMENT = gql`
  fragment GroupsItem on Group {
    createdAt
    description
    id
    isApproved
    membersCount
    status
    title
    createdBy {
      id
      lastName
      firstName
    }
    members {
      items {
        ...GroupMember
      }
    }
    posts {
      count
    }
    postsCount
    logo {
      id
      fileId
      downloadUrl
      filename
    }
    groupAdminPreferences {
      items {
        status
        id
        user {
          email
          id
          firstName
          lastName
          affiliation
        }
      }
    }
  }
  ${GROUP_MEMBER_FRAGMENT}
`;

export const ADMIN_GROUP_DETAILS_QUERY = gql`
  query AdminGroupDetails($id: ID!) {
    group(id: $id) {
      ...GroupsItem
    }
  }

  ${GROUP_FRAGMENT}
`;

export const GROUP_MEMBERS_LIST_QUERY = gql`
  query GroupMembersList(
    $first: Int
    $skip: Int
    $id: ID
    $filter: UserFilter
    $sort: [UserSort!]
  ) {
    group(id: $id) {
      members(first: $first, skip: $skip, filter: $filter, sort: $sort) {
        items {
          ...GroupMember
        }
      }
      posts {
        count
      }
      groupAdminPreferences {
        items {
          status
          id
          user {
            email
            id
            firstName
            lastName
            affiliation
          }
        }
      }
    }
  }
  ${GROUP_MEMBER_FRAGMENT}
`;

export const GROUP_MEMBERS_STATS_QUERY = gql`
  query GroupMembersStats($pendingFilter: GroupFilter) {
    groupsCount: groupsList {
      count
      items {
        members {
          count
        }
      }
    }

    groupsPendingCount: groupsList(filter: $pendingFilter) {
      count
    }
  }
`;

export const GROUP_MEMBERS_LIST_COUNT_QUERY = gql`
  query GroupMembersListCount($groupId: ID, $filter: UserFilter) {
    group(id: $groupId) {
      members(filter: $filter) {
        count
      }
    }
  }
`;

export const GROUP_UPDATE_MUTATION = gql`
  mutation GroupUpdate($data: GroupUpdateInput!) {
    groupUpdate(data: $data) {
      ...GroupsItem
    }
  }
  ${GROUP_FRAGMENT}
`;

export const GROUP_APPROVE_MUTATION = gql`
  mutation GroupApprove($groupId: ID!) {
    groupUpdate(data: { id: $groupId, status: "${GroupStatus.Active}" }) {
      id
    }
  }
`;

export const GROUP_REJECT_MUTATION = gql`
  mutation GroupReject($groupId: ID!) {
    groupRejectRequest(groupId: $groupId) {
      success
    }
  }
`;

export const DELETE_ADMIN_PREFERENCE_MUTATION = gql`
  mutation GroupAdminPreferenceDelete($data: GroupAdminPreferenceDeleteInput) {
    groupAdminPreferenceDelete(data: $data) {
      success
    }
  }
`;

export const GROUP_DELETE_MUTATION = gql`
  mutation AdminGroupDelete($data: GroupDeleteInput!) {
    groupDelete(data: $data) {
      success
    }
  }
`;

export const GROUP_ADMIN_PREFERENCES_DELETE = gql`
  mutation AdminPreferencesDelete($data: GroupAdminPreferenceDeleteInput!) {
    groupAdminPreferenceDelete(force: true, data: $data) {
      success
    }
  }
`;

export const GROUP_ADMIN_PREFERENCES_LIST = gql`
  query GroupAdminPreferencesList($filter: GroupAdminPreferenceFilter!) {
    groupAdminPreferencesList(filter: $filter) {
      items {
        id
      }
    }
  }
`;
