import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { SchoolPostsPage } from './pages/SchoolPostsPage';

export const getAdminPostsRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.posts.index,
    exact: true,
    component: SchoolPostsPage,
  },
];
