import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;
