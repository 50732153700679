import { useCurrentLocationZipLazyQuery } from 'shared/graphql';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from '../constants';

export function useCurrentLocationZip() {
  const [searchZip, { data, loading }] = useCurrentLocationZipLazyQuery({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! We found your zip!',
      [SNACKBAR_ERROR_MESSAGE]: `Error: We couldn't find you zip.`,
    },
  });

  const onSearchZip = (longitude: string, latitude: string) => {
    return searchZip({
      variables: {
        data: { longitude, latitude },
      },
    });
  };

  return {
    data,
    loading,

    zip: data?.currentLocationZipRequest.zip,

    onSearchZip,
  };
}
