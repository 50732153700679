import { gql } from '@apollo/client';

import { GROUP_POSTS_LIST_ITEM_FRAGMENT } from 'features/groups/queries';
import { HOME_FEED_POSTS_LIST_ITEM_FRAGMENT } from 'features/home/queries';
import { LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT } from 'features/legacy/queries';
import { SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT } from 'features/news/queries';

const CONTENT_REPORTS_LIST_ITEM_FRAGMENT = gql`
  fragment FullContentReportsListItem on ContentReport {
    id
    text
    category
    legacyDate
    authorId
    authorName
    groupId
    reportStatus
    flagReason
    lastFlaggedByName
    isFlagged
    isAutoReported
    isSupporter
    createdAt
    updatedAt
    flaggedAt
  }
`;

export const CONTENT_REPORTS_LIST_QUERY = gql`
  query FullContentReportsList(
    $first: Int
    $skip: Int
    $sort: [ContentReportSort!]
    $filter: ContentReportFilter
  ) {
    contentReportsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      count
      items {
        ...FullContentReportsListItem
      }
    }
  }
  ${CONTENT_REPORTS_LIST_ITEM_FRAGMENT}
`;

export const CONTENT_CURATION_FLAGGED_GROUP_POSTS_LIST_QUERY = gql`
  query ContentCurationFlaggedGroupPostsList(
    $filter: GroupPostFilter
    $sort: [GroupPostSort!]
    $first: Int
    $skip: Int
  ) {
    reportedGroupPostsList: groupPostsList(
      filter: $filter
      skip: $skip
      sort: $sort
      first: $first
    ) {
      items {
        ...GroupPostsListItem
      }
    }
  }
  ${GROUP_POSTS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_POST_DELETE_MUTATION = gql`
  mutation GroupPostDelete($data: GroupPostDeleteInput) {
    groupPostDelete(data: $data) {
      success
    }
  }
`;

export const CONTENT_CURATION_POST_DELETE_MUTATION = gql`
  mutation ContentCurationPostDelete($data: FlaggedPostDeleteRequestInput!) {
    flaggedPostDeleteRequest(data: $data) {
      success
    }
  }
`;

export const CONTENT_CURATION_GROUP_POST_STATUS_UPDATE_MUTATION = gql`
  mutation ContentCurationGroupPostStatusUpdate($data: GroupPostUpdateInput!) {
    groupPostUpdate(data: $data) {
      id
      status
      reportStatus
    }
  }
`;

export const CONTENT_CURATION_HOME_FEED_POST_STATUS_UPDATE_MUTATION = gql`
  mutation ContentCurationHomeFeedPostStatusUpdate($data: HomeFeedPostUpdateInput!, $id: ID!) {
    homeFeedPostUpdate(data: $data, filter: { id: $id }) {
      id
      status
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;

export const CONTENT_CURATION_SCHOOL_NEWS_POST_STATUS_UPDATE_MUTATION = gql`
  mutation ContentCurationSchoolNewsPostStatusUpdate($data: SchoolNewsPostUpdateInput!, $id: ID!) {
    schoolNewsPostUpdate(data: $data, filter: { id: $id }) {
      id
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;

export const CONTENT_CURATION_LEGACY_FEED_POST_STATUS_UPDATE_MUTATION = gql`
  mutation ContentCurationLegacyFeedPostStatusUpdate($data: LegacyFeedPostUpdateInput!) {
    legacyFeedPostUpdate(data: $data) {
      id
      reportStatus
      text
      media {
        items {
          id
          downloadUrl
          meta
        }
      }
    }
  }
`;

export const GROUP_POSTS_LIST_ITEM_QUERY = gql`
  query GroupPostsListItem($filter: GroupPostFilter) {
    groupPostsList(filter: $filter) {
      items {
        ...GroupPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${GROUP_POSTS_LIST_ITEM_FRAGMENT}
`;

export const HOME_FEED_POSTS_LIST_ITEM_QUERY = gql`
  query HomeFeedPostsListItem($filter: HomeFeedPostFilter) {
    homeFeedPostsList(filter: $filter) {
      items {
        ...HomeFeedPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${HOME_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const SCHOOL_NEWS_POSTS_LIST_ITEM_QUERY = gql`
  query SchoolNewsPostsListItem($filter: SchoolNewsPostFilter) {
    schoolNewsPostsList(filter: $filter) {
      items {
        ...SchoolNewsPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT}
`;

export const LEGACY_FEED_POSTS_LIST_ITEM_QUERY = gql`
  query LegacyFeedPostsListItem($filter: LegacyFeedPostFilter) {
    legacyFeedPostsList(filter: $filter) {
      items {
        ...LegacyFeedPostsListItem
        flaggedBy(sort: { updatedAt: DESC }) {
          items {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${LEGACY_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

const WORDS_BLACKLISTS_LIST_ITEM_FRAGMENT = gql`
  fragment WordsBlacklistsListItem on SchoolConfigurationWordsBlacklist {
    id
    schoolConfiguration {
      id
    }
    blacklistId
    blacklistLabel
  }
`;

export const WORDS_BLACKLISTS_LIST_QUERY = gql`
  query WordsBlacklistsList {
    schoolConfigurationWordsBlacklistsList {
      items {
        ...WordsBlacklistsListItem
      }
    }
  }
  ${WORDS_BLACKLISTS_LIST_ITEM_FRAGMENT}
`;

export const SCHOOL_CONFIGURATION_WORDS_BLACKLIST_UPDATE_MUTATION = gql`
  mutation SchoolConfigurationWordsBlacklistUpdate(
    $data: SchoolConfigurationWordsBlacklistUpdateInput!
  ) {
    schoolConfigurationWordsBlacklistUpdate(data: $data) {
      ...WordsBlacklistsListItem
    }
  }
  ${WORDS_BLACKLISTS_LIST_ITEM_FRAGMENT}
`;

export const FLAGGED_POST_APPROVE_MUTATION = gql`
  mutation FlaggedPostApprove($data: FlaggedPostApproveRequestInput!) {
    flaggedPostApproveRequest(data: $data) {
      success
    }
  }
`;
