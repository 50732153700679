import { css } from '@emotion/react';
import { Typography, Divider, Box, CircularProgress } from '@material-ui/core';
import pluralize from 'pluralize';

import { AsyncContent } from 'shared/components/ui';
import { SearchProvider, useSearchContext } from 'shared/features/search';
import { useExplorerUser } from 'features/explorer/hooks';
import { InfiniteScroll } from 'shared/features/infinite-scroll';

import { UsersFilter } from '../../types';
import { ExplorerUserList } from './ExplorerUserList';
import { ExplorerSupporters } from './ExplorerSupporters';

const resultLoadingBoxCSS = css`
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding: 15px;
`;

const resultCountBoxCSS = css`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 8px;
`;

export function ExplorerContent() {
  const { searchQuery, isUserTyping } = useSearchContext<UsersFilter>();

  const {
    data: users,
    count: usersCount,
    loading: usersLoading,
    refreshing,
    hasMore,
    fetchMore,
  } = useExplorerUser();

  const loading = isUserTyping || usersLoading;

  return (
    <AsyncContent loading={loading} stretch>
      <SearchProvider searchQuery={searchQuery}>
        <ExplorerSupporters />
      </SearchProvider>

      <Divider />

      <Box css={resultLoadingBoxCSS}>
        <Box css={resultCountBoxCSS}>
          <Typography color="primary">
            {pluralize('People', usersCount)} ({usersCount})
          </Typography>
        </Box>
      </Box>

      <Box padding={2}>
        <InfiniteScroll hasMore={hasMore} fetchMore={fetchMore}>
          <ExplorerUserList usersList={users} withAds />
        </InfiniteScroll>

        {refreshing && (
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            <CircularProgress size={26} color="secondary" />
          </Box>
        )}
      </Box>
    </AsyncContent>
  );
}
