/** @deprecated Use `@jebel/utils` instead. */
export const LEGACY_POST_TYPE = 'LegacyPost';
/** @deprecated Use `@jebel/utils` instead. */
export const HOME_POST_TYPE = 'HomePost';
/** @deprecated Use `@jebel/utils` instead. */
export const SCHOOL_POST_TYPE = 'SchoolPost';
/** @deprecated Use `@jebel/utils` instead. */
export const GROUP_POST_TYPE = 'GroupPost';
/** @deprecated Use `@jebel/utils` instead. */
export const DISCOUNT_POST_TYPE = 'DiscountPost';
/** @deprecated Use `@jebel/utils` instead. */
export const EVENT_POST_TYPE = 'EventPost';
/** @deprecated Use `@jebel/utils` instead. */
export const JOB_OFFER_POST_TYPE = 'JobOfferPost';
export const LEGACY_FEED_POST_TYPE = 'LegacyFeedPost';
export const HOME_FEED_POST_TYPE = 'HomeFeedPost';
export const SCHOOL_NEWS_POST_TYPE = 'SchoolNewsPost';
