export const DISCOUNT_TYPES = {
    free: 'free',
    flatPrice: 'flatPrice',
    discount: 'discount',
    amountOff: 'amountOff',
};
export var DiscountStatus;
(function (DiscountStatus) {
    DiscountStatus["Active"] = "active";
    DiscountStatus["Pending"] = "pending";
    DiscountStatus["Expired"] = "expired";
    DiscountStatus["Rejected"] = "rejected";
    DiscountStatus["Inactive"] = "inactive";
    DiscountStatus["Paused"] = "paused";
})(DiscountStatus || (DiscountStatus = {}));
/** @deprecated Use `DiscountStatus` instead. */
export const DISCOUNT_STATUSES = {
    active: DiscountStatus.Active,
    inactive: DiscountStatus.Inactive,
    pending: DiscountStatus.Pending,
    expired: DiscountStatus.Expired,
    rejected: DiscountStatus.Rejected,
};
export const DISCOUNT_STATUS_LABELS = {
    [DiscountStatus.Active]: 'Active',
    [DiscountStatus.Inactive]: 'Inactive',
    [DiscountStatus.Pending]: 'Pending',
    [DiscountStatus.Expired]: 'Expired',
    [DiscountStatus.Rejected]: 'Rejected',
};
