import { useTheme } from '@material-ui/core/styles';
import { Theme, css } from '@emotion/react';
import { Box, Divider, Link, Modal } from '@material-ui/core';
import { generatePath, useHistory } from 'react-router-dom';
import { KeyboardEvent, MouseEvent } from 'react';
import { Close } from '@material-ui/icons';

import { AsyncContent, Typography, Button } from 'shared/components/ui';
import { ROUTES } from 'shared/constants';
import { SearchTextField, useSearchContext } from 'shared/features/search';
import { HomeFeedCard } from 'shared/features/posts';
import {
  useExplorerFeed,
  useExplorerOrganizations,
  useExplorerUser,
} from 'features/explorer/hooks';
import { useResponsive } from 'shared/hooks';

import { OrganizationListItem } from './OrganizationListItem';
import { UserListItem } from './UserListItem';

const SEARCH_RESULTS_COUNT = 3;

const containerCSS = (theme: Theme) => css`
  display: flex;
  max-width: 30rem;
  margin: 0 auto;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    padding: 2rem;
  }
`;

const layoutCSS = (theme: Theme) => css`
  max-height: 70vh;
  background-color: ${theme.palette.background.paper};
  border-radius: 0.5rem;
  overflow-y: auto;

  ${theme.breakpoints.up('md')} {
    height: 40rem;
  }
`;

const contentCSS = css`
  padding: 1rem 0;
`;

const textSearchCSS = (theme: Theme) => css`
  & .MuiInputBase-root {
    background-color: ${theme.palette.background.paper};
  }
`;

const titleBoxCss = theme => css`
  padding: 0 ${theme.spacing(3)}px;
`;

const groupTitleCss = theme => css`
  color: ${theme.palette.primary.icon};
`;

const submitButtonCss = theme => css`
  width: 100%;
  padding: ${theme.spacing(3)}px 0 0;
`;

const seeAllButtonCss = theme => css`
  color: ${theme.palette.primary.light};
  border-color: ${theme.palette.primary.light};
`;

const viewAllCss = theme => css`
  color: ${theme.palette.secondary.light};
  font-size: ${theme.typography.fontSize}px;
  text-transform: capitalize;
  text-decoration: initial;
`;

type ModalProps = {
  onClose: () => void;
  isModalOpen: boolean;
};

export function HomeSearchModal(props: ModalProps) {
  const { push: navigate } = useHistory();
  const { searchQuery, setSearchQuery } = useSearchContext();
  const { isMobile } = useResponsive();

  const theme = useTheme();

  const {
    data: organizations,
    loading: organizationsLoading,
    count: organizationsCount,
  } = useExplorerOrganizations();

  const { data: users, loading: usersLoading, count: usersCount } = useExplorerUser();
  const { data: posts, loading: postsLoading, count: postsCount } = useExplorerFeed();

  const loading = usersLoading || organizationsLoading || postsLoading;

  const close = () => {
    props.onClose();
    setSearchQuery('');
  };

  const onShowAllResults = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const value = (event.target as HTMLInputElement).value ?? searchQuery;
    const pathname = generatePath(ROUTES.user.search.all);

    navigate(`${pathname}?q=${value}`);
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onShowItem = (url: string) => {
    navigate(url);
    close();
  };

  const onShowOrganization = (organizationId: string | undefined | null) => {
    if (!organizationId) return;

    const pathname = generatePath(ROUTES.user.organizations.organization, { id: organizationId });

    onShowItem(pathname);
  };

  const onShowAllPosts = () => {
    const route = generatePath(ROUTES.user.search.posts);

    navigate(`${route}?q=${searchQuery}`);
    close();
  };

  const onShowUser = (userId: string | undefined | null) => {
    if (!userId) return;

    const pathname = generatePath(ROUTES.user.explorer.profile, { id: userId });

    onShowItem(pathname);
  };

  const onPostClick = (postId: string) => {
    const route = generatePath(ROUTES.user.search.posts);

    navigate(`${route}?post=${postId}`);
    close();
  };

  return (
    <Modal open={props.isModalOpen} onClose={close}>
      <div css={containerCSS}>
        <SearchTextField
          autoFocus
          placeholder="Search"
          variant="outlined"
          css={textSearchCSS}
          fullWidth
          InputProps={{
            endAdornment: isMobile ? <Close onClick={close} /> : null,
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              onShowAllResults(event);
            }
          }}
        />

        {searchQuery && (
          <div css={layoutCSS}>
            <AsyncContent
              loading={loading}
              boxProps={{ style: { backgroundColor: '#FFF', borderRadius: theme.spacing(1) } }}
            >
              <Box css={contentCSS}>
                <Box
                  css={titleBoxCss}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography css={groupTitleCss} variant="subtitle5">
                    Organizations ({organizationsCount})
                  </Typography>

                  {organizationsCount > 0 && (
                    <Link
                      onClick={() => {
                        const route = generatePath(ROUTES.user.search.organizations);

                        navigate(`${route}?q=${searchQuery}`);
                        close();
                      }}
                    >
                      <Button color="secondary" variant="text" css={viewAllCss}>
                        View All
                      </Button>
                    </Link>
                  )}
                </Box>

                {organizations.slice(0, SEARCH_RESULTS_COUNT).map(organization => (
                  <Box key={organization.id} onClick={() => onShowOrganization(organization.id)}>
                    <OrganizationListItem key={organization.id} organization={organization} />
                  </Box>
                ))}

                <Box my={3}>
                  <Divider />
                </Box>

                <Box
                  css={titleBoxCss}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography css={groupTitleCss} variant="subtitle5">
                    People ({usersCount})
                  </Typography>

                  {usersCount > 0 && (
                    <Link
                      onClick={() => {
                        const route = generatePath(ROUTES.user.search.people);

                        navigate(`${route}?q=${searchQuery}`);
                        close();
                      }}
                    >
                      <Button color="secondary" variant="text" css={viewAllCss}>
                        View All
                      </Button>
                    </Link>
                  )}
                </Box>

                {users.slice(0, SEARCH_RESULTS_COUNT).map(user => (
                  <Box key={user.id} onClick={() => onShowUser(user.id)}>
                    <UserListItem key={user.id} user={user} />
                  </Box>
                ))}

                <Box my={3}>
                  <Divider />
                </Box>

                <Box
                  css={titleBoxCss}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap="1rem"
                  padding="1rem"
                >
                  <Typography css={groupTitleCss} variant="subtitle5">
                    Posts ({postsCount})
                  </Typography>

                  {postsCount > 0 && (
                    <Link onClick={onShowAllPosts}>
                      <Button color="secondary" variant="text" css={viewAllCss}>
                        View All
                      </Button>
                    </Link>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" gridGap="1rem" paddingX="1rem">
                  {posts.slice(0, 3).map(item => (
                    <HomeFeedCard
                      key={item.id}
                      data={item}
                      hideComments
                      hideLikes
                      hideOptions
                      onClick={() => onPostClick(item.id as string)}
                    />
                  ))}
                </Box>

                <Box
                  css={submitButtonCss}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button variant="outlined" css={seeAllButtonCss} onClick={onShowAllResults}>
                    SEE ALL SEARCH RESULTS
                  </Button>
                </Box>
              </Box>
            </AsyncContent>
          </div>
        )}
      </div>
    </Modal>
  );
}
