import type { Permissions } from '../types';
import { useCurrentUserPermissions } from './useCurrentUserPermissions';

type ResponseType = Permissions & { loading: boolean };

/** @deprecated Use {@linkcode useCurrentUserPermissions} instead. */
export const useCrudPermissions = (): ResponseType => {
  const { permissions, loading } = useCurrentUserPermissions();

  return { ...permissions, loading };
};
