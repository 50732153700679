import React from 'react';
import { css } from '@emotion/react';
import { Box, IconButton, InputAdornment } from '@material-ui/core';

import { Form, FormTextField, Button, Icon } from 'shared/components/ui';
import { required } from 'shared/utils/form';
import { useCurrentUser, useResponsive } from 'shared/hooks';

import { useUserPasswordUpdate, useUserEmailUpdate } from '../hooks';
import { SettingsContentLayout } from '../../../shared/components/layouts';

const formCss = (theme: any) => css`
  display: grid;
  grid-row-gap: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(1)}px;
`;

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = (isMobile: boolean) => css`
  padding: ${isMobile ? BOTTOM_BUTTONS_PADDING / 2 : BOTTOM_BUTTONS_PADDING / 4}px
    ${BOTTOM_BUTTONS_PADDING}px;
  ${!isMobile &&
  `margin-left: ${BOTTOM_BUTTONS_PADDING}px;
  width: 180px;
  height: 45px;`}
`;

const cancelButtonCSS = (isMobile: boolean) => (theme: any) =>
  css`
    ${commonButtonsCSS(isMobile)};
    color: ${theme.palette.text.secondary};
    grid-area: b;
  `;

const submitButtonCSS = (isMobile: boolean) => (theme: any) =>
  css`
    ${commonButtonsCSS(isMobile)};
    background-color: ${theme.palette.primary.dark};
    color: #ffffff;
    grid-area: a;
  `;

const visibilityIconCss = (theme: any) => css`
  color: ${theme.palette.text.secondary};
`;

const buttonsBoxMobileCSS = (theme: any) =>
  css`
    display: grid;
    grid-template-areas: 'a' 'b';
    grid-gap: ${theme.spacing(2)}px;
  `;

const buttonsBoxCSS = (theme: any) =>
  css`
    display: grid;
    grid-template-areas: 'b a';
    justify-content: flex-end;
    grid-gap: ${theme.spacing(2)}px;
  `;

type FormData = {
  email?: string | undefined | null;
  previousPassword?: string | undefined | null;
  proposedPassword?: string | undefined | null;
  confirmProposedPassword?: string | undefined | null;
};

export const SecuritySettingPage: React.FC = () => {
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const { isMobile } = useResponsive();

  const { onUserEmailUpdate } = useUserEmailUpdate();
  const { onUserPasswordUpdate } = useUserPasswordUpdate();
  const { user, loading: userLoading } = useCurrentUser();

  const initialValues = {
    email: user?.email,
    password: '',
    showPassword: false,
  };

  const onSubmit = React.useCallback(
    async (formData: FormData, resetForm: () => void) => {
      formData.email !== user?.email &&
        (await onUserEmailUpdate(
          user?.email ?? '',
          formData?.email ?? '',
          formData?.previousPassword ?? '',
        ));
      formData.proposedPassword &&
        (await onUserPasswordUpdate(
          formData.email ?? '',
          formData.previousPassword ?? '',
          formData.proposedPassword ?? '',
          formData.confirmProposedPassword ?? '',
        ));

      resetForm();
    },
    [onUserEmailUpdate, onUserPasswordUpdate, user?.email],
  );

  const handleClickShowPassword = React.useCallback(() => {
    setValues({ ...values, showPassword: !values.showPassword });
  }, [values, setValues]);

  return (
    <SettingsContentLayout isLoading={userLoading} title="Sign-in & Security">
      <Form
        oldCss={formCss}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        validateOnBlur={false}
      >
        {({ isSubmitting, resetForm }) => (
          <React.Fragment>
            <Box display="grid" gridTemplateRows="auto" gridGap={20}>
              <FormTextField
                inputProps={{
                  label: isMobile ? 'EMAIL ADDRESS / LOGIN ID' : 'Email',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'email', validate: required }}
              />
              <FormTextField
                inputProps={{
                  type: values.showPassword ? 'text' : 'password',
                  label: 'Password',
                  variant: 'outlined',
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          <Icon
                            css={visibilityIconCss}
                            name={values.showPassword ? 'Visibility' : 'VisibilityOff'}
                            variant="filled"
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                fieldProps={{ name: 'previousPassword' }}
              />
              <FormTextField
                inputProps={{
                  label: 'New Password',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'proposedPassword' }}
              />
              <FormTextField
                inputProps={{
                  label: 'Confirm New Password',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'confirmProposedPassword' }}
              />
            </Box>
            <Box css={isMobile ? buttonsBoxMobileCSS : buttonsBoxCSS}>
              <Button
                css={cancelButtonCSS(isMobile)}
                onClick={() => resetForm()}
                disabled={isSubmitting}
                size="medium"
                variant="outlined"
              >
                CANCEL
              </Button>
              <Button
                loading={isSubmitting}
                css={submitButtonCSS(isMobile)}
                disableElevation
                variant="contained"
                type="submit"
              >
                SAVE CHANGES
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Form>
    </SettingsContentLayout>
  );
};
