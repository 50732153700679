import { uniqBy } from 'ramda';

import { OrganizationsListQuery } from 'shared/graphql';
import { PAGE_SIZE } from 'shared/constants';

import { useOrganizations } from './useOrganizationsList';

type Options = Parameters<typeof useOrganizations>[0];

/** List all organizations based on the given `options` paginated. */
export function useOrganizationsPaginated(options?: Options) {
  const { data, count, loading, fetchMore } = useOrganizations({
    ...options,

    variables: {
      ...options?.variables,

      first: options?.variables?.first ?? PAGE_SIZE,
    },
  });

  const next = async () => {
    await fetchMore({
      variables: {
        first: PAGE_SIZE,
        skip: data.length,
      },

      updateQuery(prev, { fetchMoreResult: curr }) {
        const oldest = prev?.organizations.items ?? [];
        const newest = curr?.organizations?.items ?? [];

        const count = curr?.organizations.count ?? 0;
        /** Merge between `oldest` items and `newest` reduced by ID. */
        const items = uniqBy(organization => organization.id, [...oldest, ...newest]);

        const data: OrganizationsListQuery = {
          ...curr,

          organizations: {
            ...prev.organizations,

            count,
            items,
          },
        };

        return data;
      },
    });
  };

  return {
    data,
    count,

    loading: loading && data.length === 0,
    refreshing: loading && data.length > 0,
    hasMore: data.length < count,

    next,
    fetchMore: next,
  };
}
