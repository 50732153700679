import React from 'react';
import { useQuery } from '@apollo/client';
// import { DateTime } from 'luxon';

import { DISCOUNT_STATUSES } from '@jebel/constants/dist/discounts';
import { createFilterBuilder } from '@jebel/utils';

import { PAGE_SIZE, SELECT_OPTION_ALL } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import {
  DiscountsListQuery,
  DiscountsCountQuery,
  DiscountListItemFragment,
  DiscountsListQueryVariables,
  DiscountsCountQueryVariables,
  InputMaybe,
  DiscountFilter,
} from 'shared/graphql/__generated__';
import { useResponsive } from 'shared/hooks';

import { DISCOUNTS_COUNT_QUERY, DISCOUNTS_LIST_QUERY } from '../query';

const transformQueryData = (
  listData: DiscountsListQuery | undefined,
  countData: DiscountsCountQuery | undefined,
) => {
  const items = listData?.discounts.items || [];
  const count = countData?.discounts.count || 0;
  const hasMore = items.length < count;

  return { items, count, hasMore };
};

/** @deprecated */
export const useDiscountsList = () => {
  const disountsRef = React.useRef<DiscountListItemFragment[]>([]);
  const { searchQuery, isUserTyping, sortOption, filter: rawFilter, zipQuery } = useSearchContext();
  const [localZipQuery, setLocalZipQuery] = React.useState('');
  const { isMobile } = useResponsive();

  const filter: InputMaybe<DiscountFilter> = React.useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>({
      status: { equals: DISCOUNT_STATUSES.active },
    });

    if (rawFilter !== SELECT_OPTION_ALL) {
      filter.and({ category: { equals: rawFilter } });
    }

    if (searchQuery) {
      const searchFilter = createFilterBuilder<DiscountFilter>();

      searchFilter.or({ title: { contains: searchQuery } });
      searchFilter.or({ category: { contains: searchQuery } });
      searchFilter.or({ organization: { name: { contains: searchQuery } } });
      searchFilter.or({ terms: { contains: searchQuery } });

      // Search by the specified fields.
      // https://8base-dev.atlassian.net/browse/JEB-1465
      filter.and(searchFilter);
    }

    if (!isMobile && zipQuery) {
      filter.and({
        organization: {
          location: { some: { address: { some: { zip: { equals: zipQuery } } } } },
        },
      });
    }

    if (isMobile && localZipQuery) {
      filter.and({
        organization: {
          location: { some: { address: { some: { zip: { equals: localZipQuery } } } } },
        },
      });
    }

    return filter.build();
  }, [isMobile, localZipQuery, rawFilter, searchQuery, zipQuery]);

  const onQueryCompleted = React.useCallback((data: DiscountsListQuery) => {
    disountsRef.current = data.discounts.items;
  }, []);

  const {
    data: listData,
    loading: listLoading,
    refetch: refetchList,
    fetchMore: rawFetchMore,
  } = useQuery<DiscountsListQuery, DiscountsListQueryVariables>(DISCOUNTS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: onQueryCompleted,
    variables: {
      first: PAGE_SIZE,
      filter,
      sort: sortOption,
    },
  });

  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<DiscountsCountQuery, DiscountsCountQueryVariables>(DISCOUNTS_COUNT_QUERY, {
    variables: { filter },
  });

  const returnData = React.useMemo(
    () => transformQueryData(listData, countData),
    [countData, listData],
  );

  const fetchMore = React.useCallback(
    async (variables: DiscountsListQueryVariables) => {
      const { data } = await rawFetchMore({ variables });

      onQueryCompleted(data);
    },
    [onQueryCompleted, rawFetchMore],
  );

  const refetch = React.useCallback(() => {
    const refetchListPromise = refetchList({
      first: disountsRef.current.length || 1,
    });
    const refetchCountPromise = refetchCount();

    return Promise.all([refetchListPromise, refetchCountPromise]);
  }, [refetchCount, refetchList]);

  return {
    ...returnData,
    setLocalZipQuery,
    loading:
      (listLoading && !listData) || (countLoading && !countData) || (!isMobile && isUserTyping),
    fetchMore,
    refetch,
  };
};
