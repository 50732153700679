import { Fragment, useCallback, useMemo } from 'react';

import { CareerInfoFragment } from 'shared/graphql';
import { Modal } from 'shared/components/ui';
import { EditPostModal, JobDetailsModal } from 'features/career/components';
import { useModalState } from 'shared/hooks';
import { useJobOfferExpiredFilter } from 'shared/features/career';
import { ExplorerJobPostCard } from 'features/explorer/components';

interface OrganizationJobOffersProps {
  offers?: CareerInfoFragment[] | null | undefined;
  isManager: boolean;
}

export function OrganizationJobOffers(props: OrganizationJobOffersProps) {
  const {
    show: showDetailsModal,
    open: openDetailsModal,
    close: closeDetailsModal,
    params: detailsOffer,
  } = useModalState<CareerInfoFragment>();

  const {
    show: showEditModal,
    open: openEditModal,
    close: closeEditModal,
    params: editableOffer,
  } = useModalState<CareerInfoFragment>();

  const isOfferExpired = useJobOfferExpiredFilter();

  const openDetails = useCallback(
    (offer: CareerInfoFragment) => () => {
      openDetailsModal(offer);
    },
    [openDetailsModal],
  );

  const openEdit = useCallback(
    (offer: CareerInfoFragment) => () => {
      openEditModal(offer);
    },
    [openEditModal],
  );

  const offers = useMemo(() => {
    const offers = props.offers ?? [];

    if (props.isManager) {
      return offers;
    }

    return offers.filter(offer => isOfferExpired(offer) === false);
  }, [props.offers, props.isManager, isOfferExpired]);

  if (offers.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: showEditModal,
          onClose: closeEditModal,
          fullWidth: true,
        }}
        titleProps={{ title: 'Edit Job Post' }}
      >
        <EditPostModal onModalClose={closeEditModal} jobOfferInfo={editableOffer} />
      </Modal>

      <JobDetailsModal
        open={showDetailsModal}
        onClose={closeDetailsModal}
        job={detailsOffer}
        authorId={detailsOffer?.createdBy?.id as string}
      />

      {offers.map(offer => (
        <ExplorerJobPostCard
          key={offer.id}
          data={offer}
          onOpenDetails={openDetails(offer)}
          onOpenEdit={openEdit(offer)}
          withEdit
          withMessage
          withSave
        />
      ))}
    </Fragment>
  );
}
