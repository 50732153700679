import React from 'react';

import { useThreadsQuery } from 'features/collective/hooks/useThreadsQuery';
import { AsyncContent } from 'shared/components/ui';
import { useStreamChatReactions } from 'shared/hooks/useStreamChatActivity';

import { CollectiveIntelligenceComments } from './CollectiveIntelligenceComments';

export const CollectiveIntelligenceCommentsList = ({ id }: { id: string }) => {
  const { items, activities, loading } = useThreadsQuery();
  const { onCommentAdd, onLikeAdd, onReactionRemove, onCustomReactionAdd, onCommentRemove } =
    useStreamChatReactions();

  return (
    <AsyncContent loading={loading || (!!items.length && !activities.length)}>
      <React.Fragment>
        <CollectiveIntelligenceComments
          onCustomReactionAdd={onCustomReactionAdd}
          onCommentAdd={onCommentAdd}
          onLikeAdd={onLikeAdd}
          onReactionRemove={onReactionRemove}
          id={id}
          onCommentRemove={onCommentRemove}
        />
      </React.Fragment>
    </AsyncContent>
  );
};
