import React from 'react';
import { debounce } from 'lodash';

import { Select } from 'shared/components/ui';

import { useSearchContext } from '../../providers';

export type FilterFieldProps = {
  renderOptions: JSX.Element[];
  label: string;
};

export const FilterField: React.FC<FilterFieldProps> = ({ renderOptions, label }) => {
  const { filter, setFilter, setIsUserTyping } = useSearchContext();
  const [localFilterQuery, setLocalFilterQuery] = React.useState(filter);

  const fetchFilterResults = React.useCallback(
    (filter: string) => {
      setIsUserTyping(false);

      setFilter(filter);
    },
    [setIsUserTyping, setFilter],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFilterResults = React.useCallback(
    debounce(
      (filter: string) => {
        fetchFilterResults(filter);
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  );

  const onFilterQueryChange = React.useCallback(
    (newFilterQuery, disableRequestDebounce = false) => {
      setIsUserTyping(!!newFilterQuery);
      setLocalFilterQuery(newFilterQuery);

      const skipDebounce = disableRequestDebounce || !newFilterQuery.trim();

      if (skipDebounce) {
        debouncedFetchFilterResults.cancel();
        fetchFilterResults(newFilterQuery);
        return;
      }

      debouncedFetchFilterResults(newFilterQuery);
    },
    [debouncedFetchFilterResults, fetchFilterResults, setIsUserTyping],
  );

  const onChange = React.useCallback(
    event => {
      const newFilterQuery = event.target.value;
      onFilterQueryChange(newFilterQuery);
    },
    [onFilterQueryChange],
  );

  return (
    <Select variant="outlined" onChange={onChange} value={localFilterQuery} label={label}>
      {renderOptions}
    </Select>
  );
};
