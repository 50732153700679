import * as yup from 'yup';

import { requiredStringSchema, requiredNumberSchema } from '@jebel/utils';

import { CreateGraduatingYearFormFields } from 'shared/types/forms';

export const createGraduatingYearFormSchema = yup.object({
  [CreateGraduatingYearFormFields.classYear]: requiredStringSchema,
  [CreateGraduatingYearFormFields.alumniCount]: requiredNumberSchema
    .positive('Must be positive')
    .integer('Must be integer'),
});

export type CreateGraduatingYearFormValues = yup.InferType<typeof createGraduatingYearFormSchema>;
