import { css } from '@emotion/react';
import { Paper, Box, Avatar } from '@mui/material';
import { Switch, Route } from 'react-router-dom';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { guestRoutes } from 'routes';
import { RouteLayout } from 'shared/components/layouts';
import { useSchoolConfiguration } from 'shared/hooks';

const FORM_MIN_WIDTH = 500;

const pageCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const formContainerCss = theme => css`
  padding: ${theme.spacing(5.75)}px ${theme.spacing(11.25)}px ${theme.spacing(3.75)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 50%;
  width: 50%;

  ${theme.breakpoints.down('xs')} {
    max-width: 100%;
    width: 100%;
    padding: ${theme.spacing(3)}px ${theme.spacing(2)}px;
    background: #fff;
  }
`;

const formCss = theme => css`
  padding: ${theme.spacing(5)}px;
  width: 100%;
  min-width: ${FORM_MIN_WIDTH}px;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('xs')} {
    padding: 0;
    box-shadow: none;
    min-width: 100%;
  }
`;

const formInnerCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(2.5)}px;
  width: 100%;
`;

const containImageSize = `
  img {
    object-fit: contain;
  }
`;

const columbusBanner = css`
  height: 1050px;
  width: 100%;
  max-width: 50%;
  object-fit: cover;
  @media (max-width: 1000px) {
    display: none;
  } ;
`;

const columbusTopLogoCss = css`
  height: 127px;
  width: 100%;

  ${containImageSize}
`;

const columbusBottomLogoCss = theme => css`
  margin: ${theme.spacing(7)}px 0 0 0;
  height: 60px;
  width: 100%;
  ${containImageSize}
  ${theme.breakpoints.down('xs')} {
    margin-top: ${theme.spacing(1)}px;
  }
`;

export function OnboardingPagesRouter() {
  const { configuration: school } = useSchoolConfiguration();

  const logoUrl = school?.images?.emailLogo?.downloadUrl ?? undefined;
  const splashImageUrl = school?.images?.splashImage?.downloadUrl ?? undefined;

  return (
    <RouteLayout
      content={
        <Box css={pageCss}>
          <Box css={formContainerCss}>
            <Paper css={formCss}>
              <Box css={formInnerCss}>
                <Box display="grid" alignItems="center" justifyItems="center">
                  <Avatar
                    css={columbusTopLogoCss}
                    src={logoUrl}
                    srcSet={processFilestackUrlSrcSet(logoUrl)}
                    variant="square"
                  />
                </Box>
                <Switch>
                  {guestRoutes.map(
                    ({ routeComponent: RouteComponent = Route, component, path, ...rest }) => (
                      <RouteComponent
                        key={Array.isArray(path) ? path[0] : path}
                        path={path}
                        // @ts-ignore
                        component={component}
                        {...rest}
                      />
                    ),
                  )}
                </Switch>
              </Box>
            </Paper>
            <Avatar
              css={columbusBottomLogoCss}
              src="https://cdn.filestackcontent.com/security=policy:eyJjYWxsIjpbInJlYWQiLCJjb252ZXJ0Il0sImV4cGlyeSI6MjUyNDYwODAwMCwiaGFuZGxlIjoiWDR3SGVxVFJSU0NmN2M0ODN5Z0gifQ==,signature:f992fe0f914cf8d9cc68aa6e223955c7820da8f59cb131f49ad98fdd82386ccc/X4wHeqTRRSCf7c483ygH"
              srcSet={processFilestackUrlSrcSet(
                // TODO: Get Logo Url from database - Settings Backend - Customization Upload (JEB-271)
                'https://cdn.filestackcontent.com/security=policy:eyJjYWxsIjpbInJlYWQiLCJjb252ZXJ0Il0sImV4cGlyeSI6MjUyNDYwODAwMCwiaGFuZGxlIjoiWDR3SGVxVFJSU0NmN2M0ODN5Z0gifQ==,signature:f992fe0f914cf8d9cc68aa6e223955c7820da8f59cb131f49ad98fdd82386ccc/X4wHeqTRRSCf7c483ygH',
                {
                  resize: {
                    height: 60,
                  },
                },
              )}
              variant="rounded"
            />
          </Box>
          <Avatar
            css={columbusBanner}
            src={processFilestackUrlSrcSet(splashImageUrl)}
            variant="rounded"
          />
        </Box>
      }
    />
  );
}
