import { SORT } from '@jebel/constants';

import { SortFieldProps } from 'shared/features/search';

export const LEGACY_SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Legacy Date: Newest First',
        value: {
          legacyDate: SORT.desc,
        },
      },
      {
        label: 'Legacy Date: Oldest First',
        value: {
          legacyDate: SORT.asc,
        },
      },
      {
        label: 'Post Date: Newest First',
        value: {
          createdAt: SORT.desc,
        },
      },
      {
        label: 'Post Date: Oldest First',
        value: {
          createdAt: SORT.asc,
        },
      },
    ],
  },
};

const CONTENT_URL = `${window.location.origin}/legacy-content`;

export const LEGACY_CONTENT_CSV_URL_1980_1988 = `${CONTENT_URL}/yearBook_1980-1988.csv`;
export const LEGACY_CONTENT_CSV_URL_1989 = `${CONTENT_URL}/yearBook_1989.csv`;
