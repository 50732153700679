import React from 'react';
import { Divider, css, Box, CircularProgress } from '@mui/material';
import { IconButton } from '@material-ui/core';

import { EventInfoFragment } from 'shared/graphql/__generated__';
import { useEventPostsQuery } from 'features/events/hooks/useEventPostQuery';
import { CenterLayout } from 'shared/components/layouts';
import { PostsProvider } from 'shared/features/posts';
import { Typography } from 'shared/components/ui';
import { Icon } from 'shared/components/ui/Icon';

import { EventInfoModalDateLocation } from './EventInfoModalDateLocation';
import { EventContent } from './EventContent';

const eventDetailsBoxCSS = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(1.25)}px;
  overflow: hidden;
`;

export type EventInfoModalProps = {
  eventId?: string | null;
  onClose?: () => void;
};

const eventTitleCss = css`
  font-weight: 400;
  line-height: 28px;
`;
const iconsCss = (theme: any) => css`
  color: ${theme.palette.primary};
`;

export const EventInfoModal = ({ eventId, onClose }: EventInfoModalProps) => {
  const {
    posts,
    activities,
    activitiesLoading,
    loading: postsLoading,
  } = useEventPostsQuery(eventId);

  const event: EventInfoFragment = React.useMemo(() => {
    return posts[0];
  }, [posts]);

  return (
    <Box css={eventDetailsBoxCSS}>
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Typography variant="subtitle2" color="primary" css={eventTitleCss}>
          {event?.title}
        </Typography>
        <Box>
          <IconButton size="medium" onClick={onClose}>
            <Icon name="Close" css={iconsCss} variant="filled" fontSize="medium" />
          </IconButton>{' '}
        </Box>
      </Box>
      <EventInfoModalDateLocation event={event} />
      <Divider />
      {!event || postsLoading ? (
        <CenterLayout>
          <CircularProgress />
        </CenterLayout>
      ) : (
        <PostsProvider activities={activities} loading={activitiesLoading}>
          <EventContent event={event} />
        </PostsProvider>
      )}
    </Box>
  );
};
