import React from 'react';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { HOME_FEED_ITEM_TYPES, HomeFeedItemType, POST_STATUSES } from '@jebel/constants';

import { createSnackMessage, SNACK_TYPES } from 'shared/components/ui';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import {
  FlagPostMutationVariables,
  PostsFlaggedByUserListQuery,
  PostsFlaggedByUserListQueryVariables,
  useFlagPostMutation,
} from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';

import { POSTS_FLAGGED_BY_USER_LIST_QUERY } from '../queries';

const usePostsFlaggedByUserList = ({
  userId,
  postType,
}: {
  userId?: string | null;
  postType: HomeFeedItemType;
}) => {
  const filter = React.useMemo(
    () => ({
      flaggedBy: {
        some: {
          id: {
            equals: userId,
          },
        },
      },
      status: { not_equals: POST_STATUSES.reportReviewPending },
    }),
    [userId],
  );

  const variables = React.useMemo(
    () => ({
      groupPostsFilter: filter,
      homePostsFilter: filter,
      homePosts: postType === HOME_FEED_ITEM_TYPES.HOME,
      groupPosts: postType === HOME_FEED_ITEM_TYPES.GROUP,
    }),
    [postType, filter],
  );

  const { data, loading, error } = useQuery<
    PostsFlaggedByUserListQuery,
    PostsFlaggedByUserListQueryVariables
  >(POSTS_FLAGGED_BY_USER_LIST_QUERY, {
    skip: !userId,
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const postsForbiddenToFlag = React.useMemo(() => {
    const groupPostsList = data?.groupPostsList?.items || [];
    const homePostsList = data?.homeFeedPostsList?.items || [];

    return [...groupPostsList, ...homePostsList];
  }, [data]);

  return {
    postsForbiddenToFlag,
    loading: loading && !data,
    error,
  };
};

export const usePostFlag = ({ postType }: { postType: HomeFeedItemType }) => {
  const { userId } = useCurrentUser();
  const { postsForbiddenToFlag } = usePostsFlaggedByUserList({ postType, userId });
  const { enqueueSnackbar } = useSnackbar();

  const [postFlag, { loading, ...rest }] = useFlagPostMutation({
    refetchQueries: ['HomeFeedItems', 'ContentCurationFlaggedGroupPostsList'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: You have flagged the post.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: You haven't flagged the post. Try again later. `,
    },
  });

  const onPostFlag = React.useCallback(
    async (data: Omit<FlagPostMutationVariables['data'], 'postType'>) => {
      if (!userId) {
        return;
      }

      if (postsForbiddenToFlag?.some(availablePost => availablePost.id === data.postId)) {
        enqueueSnackbar("Error: You can't flag this post because you flagged it before.", {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.error),
        });
        return;
      }

      await postFlag({
        variables: {
          data: { ...data, postType },
        },
      });
    },
    [userId, postsForbiddenToFlag, postFlag, postType, enqueueSnackbar],
  );

  return { onPostFlag, loading, ...rest };
};
