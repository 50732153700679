import _ from 'lodash';

import type {
  SchoolConfigurationLayout,
  RoleAddonInfoFragment,
  RoleAddonCreateInput,
  RoleAddonUpdateInput,
  SchoolConfigurationLayoutKeyFilter,
} from 'shared/graphql';

import type { FormMode, RolesEditFormValues } from '../Roles.types';

const findAccessibleLayout = (
  accessibleLayouts: RoleAddonInfoFragment['accessibleLayouts'],
  id: string,
) => {
  if (_.isNil(accessibleLayouts)) {
    return false;
  }

  return Boolean(accessibleLayouts?.items.find(layout => layout.id === id));
};

export const buildSectionsValue = (
  layouts: SchoolConfigurationLayout[],
  accessibleLayouts: RoleAddonInfoFragment['accessibleLayouts'],
): RolesEditFormValues['sections'] => {
  return Object.fromEntries(
    layouts.map(layout => {
      const layoutId = layout.id || '';
      return [layoutId, findAccessibleLayout(accessibleLayouts, layoutId)];
    }),
  );
};

const buildKeyFilterOfLayoutsId = (
  layouts: RolesEditFormValues['sections'],
): SchoolConfigurationLayoutKeyFilter[] => {
  return Object.entries(layouts)
    .filter(layout => layout[1])
    .map(layout => ({ id: layout[0] }));
};

export const buildRoleAddonCreateOnUpdateInput = (
  formValues: RolesEditFormValues,
  formMode: FormMode,
): RoleAddonCreateInput | RoleAddonUpdateInput => {
  return {
    name: formValues.name,
    accessibleLayouts: {
      [formMode === 'create' ? 'connect' : 'reconnect']: buildKeyFilterOfLayoutsId(
        formValues.sections,
      ),
    },
    isDefault: formValues.isDefault,
    role: {
      connect: {
        id: formValues.systemRoleId,
      },
    },
    homePostsPermissions: formValues.homePostsPermissions,
    schoolPostsPermissions: formValues.schoolPostsPermissions,
    membersPermissions: formValues.membersPermissions,
    businessPermissions: formValues.businessPermissions,
    forumPermissions: formValues.forumPermissions,
    groupsPermissions: formValues.groupsPermissions,
  };
};
