import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { CommentForm } from 'shared/features/posts/components';
import { usePosts } from 'shared/features/posts/providers';
import {
  OnCommentAdd,
  OnCommentRemove,
  OnCustomReactionAdd,
  OnLikeAdd,
  OnReactionRemove,
} from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { MentionPosition } from 'shared/features/mentions/types';

import { CollectiveIntelligenceComment } from './CollectiveIntelligenceComment';

export type PostStreamInteractionsProps = {
  id: string;
  onCommentAdd: OnCommentAdd;
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  onCommentRemove: OnCommentRemove;
  onCustomReactionAdd: OnCustomReactionAdd;
};

export const CollectiveIntelligenceComments = ({
  id,
  onCommentAdd,
  onLikeAdd,
  onReactionRemove,
  onCommentRemove,
  onCustomReactionAdd,
}: PostStreamInteractionsProps) => {
  const [comments, setComments] = React.useState<CommentEnrichedReaction[]>([]);
  const textFieldRef = React.useRef<any>(null);
  const { activity } = usePosts({ postId: id });

  const topicAuthorId = React.useMemo(
    () => activity?.actor.toString().split(':')[1],
    [activity?.actor],
  );

  const onCommentPost = React.useCallback(
    async (text: string, media, mentions: MentionPosition[] = []) => {
      if (!activity?.id) {
        return;
      }

      const comment = await onCommentAdd({
        activityId: activity?.id,
        text,
        media,
        foreignId: id,
        parentEntityAuthorId: topicAuthorId,
        parentEntityType: 'topic',
        mentions,
      });

      if (!comment) {
        return;
      }

      setComments([...comments, comment]);
    },
    [activity?.id, comments, id, onCommentAdd, topicAuthorId],
  );

  React.useEffect(() => {
    const newComments = activity?.latest_reactions?.comment || [];

    setComments(newComments);
  }, [activity?.latest_reactions?.comment]);

  if (!activity) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography>No activity was found for this thread</Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {comments.map(comment => (
        <Box key={comment.id} mb={4}>
          <CollectiveIntelligenceComment
            comment={comment}
            onCommentAdd={onCommentAdd}
            onLikeAdd={onLikeAdd}
            onReactionRemove={onReactionRemove}
            onCommentRemove={onCommentRemove}
            onCustomReactionAdd={onCustomReactionAdd}
            postId={id}
          />
        </Box>
      ))}

      <CommentForm type="comment" fieldRef={textFieldRef} onCommentPost={onCommentPost} />
    </React.Fragment>
  );
};
