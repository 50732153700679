import { useMemo } from 'react';

import { GroupInfoFragment } from 'shared/graphql';
import { generateGroupRoute } from 'shared/routes';

import { AuthorNameLink } from './AuthorNameLink';

interface Props {
  group: GroupInfoFragment | undefined | null;

  className?: string;
}

export function GroupNameLink(props: Props) {
  const name = props.group?.title ?? '(Group)';

  const route = useMemo(() => {
    return generateGroupRoute(props.group?.id);
  }, [props.group]);

  return <AuthorNameLink className={props.className} name={name} route={route} />;
}
