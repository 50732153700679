/**
 * Format numbers with decimal places to a string using Intl.NumberFormat
 * @param value
 * @param decimalPlaces
 */
export function formatDecimalNumbers(value: number, decimalPlaces = 2): string {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
}
