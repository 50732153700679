import { forwardRef } from 'react';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';
import { DatePicker, DatePickerProps } from '../DatePicker';

export type FormKeyboardDatePickerProps = ComponentDataProps & {
  fieldProps: {
    name: string;
    validate?: FieldValidator;
  };
  datePickerProps?: Omit<DatePickerProps, 'onChange' | 'value'>;
};

export const FormKeyboardDatePicker = forwardRef<HTMLInputElement, FormKeyboardDatePickerProps>(
  (
    {
      fieldProps,
      datePickerProps,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField<DateTime | null>(fieldProps);

    const form = useFormikContext();

    const isInvalid = getIsInvalid({ meta, form });
    const errorText = isInvalid && meta.error;

    const onChange = (value: MaterialUiPickersDate) => {
      if (value && !value?.isValid) {
        form.setFieldError(field.name, 'Should be a valid date format');
        return;
      }

      if (value && value?.isValid) {
        helpers.setValue(value);
        return;
      }

      helpers.setValue(null);
    };

    return (
      <DatePicker
        ref={ref}
        {...datePickerProps}
        value={field.value}
        onChange={onChange}
        error={isInvalid}
        helperText={errorText}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      />
    );
  },
);
