import React from 'react';
import { css } from '@emotion/react';

import { Box } from 'shared/components/ui';

type SeparatorProps = {
  diameter?: string;
  color?: string;
  children?: React.ReactNode;
  onClick?: (event: any) => void;
};

export const Separator: React.FC<SeparatorProps> = symbolProps => {
  return (
    <Box
      css={css`
        width: 4px;
        height: 4px;
        background-color: #000;
        border-radius: 50%;
        background-color: ${symbolProps.color};
        width: ${symbolProps.diameter};
        height: ${symbolProps.diameter};
        min-width: ${symbolProps.diameter};
        min-height: ${symbolProps.diameter};
        max-width: ${symbolProps.diameter};
        max-height: ${symbolProps.diameter};
      `}
    />
  );
};
