import React from 'react';
import { Avatar, AvatarProps, CircularProgress } from '@mui/material';

import { ONE_MEGABYTE } from 'shared/constants/files';

import { FileInputWrap as FileInput } from '../FileInputWrap';
import { StyledContainer, StyledBackground, StyledIcon } from './styles';

const MAX_FILE_SIZE = ONE_MEGABYTE * 10;

export interface AvatarImageInputProps extends AvatarProps {
  onChange: any;
  loading?: boolean;
}

export const AvatarImageInput: React.FC<AvatarImageInputProps> = ({
  onChange,
  sx,
  loading,
  variant = 'circular',
  ...rest
}) => {
  const onPickImage = React.useCallback((pick: any) => {
    // picker spamming his script into DOM on every pick
    document.getElementById('fs-loader-picker')?.remove();

    pick({
      accept: 'image/*',
      fromSources: ['local_file_system'],
      maxSize: MAX_FILE_SIZE,
    });
  }, []);

  const handleChange = React.useCallback((resultFile: any) => {
    if (!resultFile) {
      return;
    }

    onChange(resultFile);
  }, []);

  return (
    <FileInput maxFiles={1} onChange={handleChange}>
      {({ pick }) => {
        return (
          <StyledContainer variant={variant} loading={loading} onClick={() => onPickImage(pick)}>
            <StyledBackground />
            <StyledIcon />
            {loading && <CircularProgress />}
            <Avatar variant="square" {...rest} />
          </StyledContainer>
        );
      }}
    </FileInput>
  );
};
