import { useEffect, useState } from 'react';
import { Button, CardActions } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { nanoid } from 'nanoid';

import {
  SchoolConfigurationExternalLink,
  SchoolConfigurationExternalLinkCreateInput,
} from 'shared/graphql';
import { reorderItems } from 'shared/utils/array';
import { useModalState } from 'shared/hooks';
import { addHttpsPrefix } from 'shared/utils/url/addHttpsPrefix';

import { MainNavigationCard } from '../MainNavigationCard/MainNavigationCard';
import { ExternalLinksList } from '../ExternalLinksList';
import { ExternalLinkEditModal } from '../ExternalLinkEditModal';

type Props = {
  items: SchoolConfigurationExternalLink[];
  onListUpdate(items: SchoolConfigurationExternalLink[]): void;
};

export function MainNavigationExternalLinks(props: Props) {
  const [links, setLinks] = useState(() => props.items);

  const modalEdit = useModalState<SchoolConfigurationExternalLink>();
  const modalCreate = useModalState<SchoolConfigurationExternalLink>();

  useEffect(() => {
    setLinks(props.items);
  }, [props.items]);

  useEffect(() => {
    props.onListUpdate(links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  const onDragEnd = (result: { source: { index: number }; destination: { index: number } }) => {
    setLinks(reorderItems(links, result.source.index, result.destination.index));
  };

  const changeItem = (selected: SchoolConfigurationExternalLink) => {
    setLinks(prev => prev.map(curr => (curr.id === selected.id ? selected : curr)));
    modalEdit.close();
  };

  const onEditModalSave = (item: SchoolConfigurationExternalLink) => {
    const link = addHttpsPrefix(item.link as string);

    const newest: SchoolConfigurationExternalLink = { ...item, link };

    changeItem(newest);
    modalEdit.close();
  };

  const onExternalLinkAdd = (item: SchoolConfigurationExternalLinkCreateInput) => {
    const id = nanoid();
    const link = addHttpsPrefix(item.link as string);

    const newest: SchoolConfigurationExternalLink = {
      id,
      link,
      name: item.name,
      order: links.length,
      isActive: true,
    };

    setLinks(prev => [...prev, newest]);
    modalCreate.close();
  };

  return (
    <MainNavigationCard title="Custom External Links">
      <ExternalLinksList
        items={links}
        onDragEnd={onDragEnd}
        onItemUpdate={changeItem}
        onModalOpen={modalEdit.open}
      />

      <CardActions>
        <Button color="secondary" startIcon={<Add />} onClick={() => modalCreate.open()}>
          Add another
        </Button>
      </CardActions>

      <ExternalLinkEditModal
        item={modalEdit.params!}
        isOpen={modalEdit.show}
        onClose={modalEdit.close}
        onSave={onEditModalSave}
      />

      <ExternalLinkEditModal
        isOpen={modalCreate.show}
        onClose={modalCreate.close}
        onSave={onExternalLinkAdd}
      />
    </MainNavigationCard>
  );
}
