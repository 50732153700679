export const ADVERTISING_TITLES = {
  all: 'All',
  AdsServe: 'Ads Served',
  Supporters: 'Supporters',
  Discounts: 'Discounts',
} as const;

export const optionToVolume = {
  all: 'totalVolume',
  AdsServe: 'totalAdvertisements',
  Supporters: 'totalSupporters',
  Discounts: 'totalDiscounts',
};

export const optionToRevenue = {
  all: 'totalAllRevenue',
  AdsServe: 'totalAdRevenue',
  Supporters: 'totalSupportersRevenue',
  Discounts: 'totalDiscountsRevenue',
};
