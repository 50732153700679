import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { createFilterBuilder } from '@jebel/utils';

import { HomeFeedCard } from 'shared/features/posts';
import { PAGE_SIZE, ROUTES } from 'shared/constants';
import { useExplorerFeed } from 'features/explorer/hooks';
import { useModalState, useQueryParams } from 'shared/hooks';
import { HomeFeedItemFilter, HomeFeedItemFragment } from 'shared/graphql';
import {
  HomeFeedPostModalFlag,
  HomeFeedPostModalUpdate,
} from 'features/home/components/HomeFeedPostModal';

import { SearchSection } from '../SearchSection';

interface Params {
  q?: string;
  post?: string;
}

interface Props {
  canFetchMore?: boolean;
}

const PREVIEW_COUNT = 3;

export function SearchPagePosts(props: Props) {
  const [params] = useQueryParams<Params>();

  const {
    show: showReportModal,
    params: currentReport,
    open: openReportModal,
    close: closeReportModal,
  } = useModalState<HomeFeedItemFragment>();

  const {
    show: showEditModal,
    params: currentEdit,
    open: openEditModal,
    close: closeEditModal,
  } = useModalState<HomeFeedItemFragment>();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>();

    if (params.post) {
      filter.and({ id: { equals: params.post } });
    }

    return filter.build();
  }, [params]);

  const {
    data: posts,
    count,
    loading,
    hasMore,
    fetchMore,
  } = useExplorerFeed({ first: props.canFetchMore ? PAGE_SIZE : PREVIEW_COUNT, filter });

  const { push: navigate } = useHistory();

  const title = `Posts (${count})`;

  const fetchMorePosts = async () => {
    await fetchMore();
  };

  const showMore = () => {
    const search = new URLSearchParams(params as Record<string, string>);
    const route = `${ROUTES.user.search.posts}?${search}`;

    navigate(route);
  };

  return (
    <SearchSection
      title={title}
      loading={posts.length === 0 && loading}
      fetching={posts.length > 0 && loading}
      hasMore={hasMore}
      canShowMore={props.canFetchMore}
      showMoreLabel="Show all posts"
      onShowMore={showMore}
      onFetchMore={fetchMorePosts}
    >
      {Boolean(currentReport) && (
        <HomeFeedPostModalFlag
          isOpen={showReportModal}
          onModalClose={closeReportModal}
          postId={currentReport!.id as string}
          postType={currentReport!.postType as string}
        />
      )}

      {Boolean(currentEdit) && (
        <HomeFeedPostModalUpdate
          isOpen={showEditModal}
          onClose={closeEditModal}
          selectedPost={currentEdit as HomeFeedItemFragment}
        />
      )}

      {posts.map(post => (
        <HomeFeedCard
          key={post?.id}
          data={post}
          onReport={() => openReportModal(post)}
          onEdit={() => openEditModal(post)}
        />
      ))}
    </SearchSection>
  );
}
