import { Fragment, useCallback, useMemo } from 'react';

import { AsyncContent } from 'shared/components/ui';
import { PostsProvider, HomeFeedCard } from 'shared/features/posts';
import { HomeFeedItemFilter } from 'shared/graphql';
import {
  FEED_APPROVED_FILTER,
  FEED_EXCLUDE_SCHEDULED_FILTER,
  FEED_SORT,
  useHomeFeedItems,
} from 'shared/hooks';
import { createFilterBuilder } from 'shared/queries/filterBuilder';

export interface ExplorerNationProfilePostsListProps {
  authorId: string | undefined | null;
}

export function ExplorerNationProfilePostsList(props: ExplorerNationProfilePostsListProps) {
  const filter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>()
      .and(FEED_APPROVED_FILTER)
      .and(FEED_EXCLUDE_SCHEDULED_FILTER);

    if (props.authorId) {
      filter.and({ authorId: { equals: props.authorId } });
    }

    return filter.build();
  }, [props.authorId]);

  const { data: posts, loading } = useHomeFeedItems({
    skip: !props.authorId,
    fetchPolicy: 'cache-and-network',
    variables: {
      sort: FEED_SORT,
      filter,
    },
  });

  const renderItem = useCallback(
    (data: typeof posts[number]) => {
      if (!data.id) {
        return null;
      }

      return <HomeFeedCard key={data.id} data={data} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <PostsProvider activities={posts as any} loading={loading}>
      <AsyncContent loading={loading}>
        <Fragment>{posts.map(renderItem)}</Fragment>
      </AsyncContent>
    </PostsProvider>
  );
}
