import { RouteLayout } from 'shared/components/layouts';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { SearchProvider } from 'shared/features/search';
import { useResponsive } from 'shared/hooks';

import { SearchPageContent } from './SearchPageContent/SearchPageContent';

export function SearchPage() {
  const { isMobile } = useResponsive();

  return (
    <SearchProvider>
      <RouteLayout content={<SearchPageContent />} sidebar={!isMobile && <CommunitySidebar />} />
    </SearchProvider>
  );
}
