import React from 'react';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import { RouteLayout, Box, RouterLink, TypographyProps, Typography } from 'shared/components/ui';

import { APP_URL } from '../../../shared/constants';

const passwordRecoverySentCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: column;
  height: 100%;
`;

const container434CSS = css`
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: 1fr;
`;

const container432CSS = css`
  display: grid;
  justify-content: center;
  text-align: center;
`;

const typography209CSS = (theme: any) => css`
  font-size: ${theme.typography.fontSize + 6}px;
  font-weight: 600;
  line-height: 26px;
`;

const loginLinkCSS = (theme: any) => css`
  white-space: nowrap;
  font-weight: 500;
  color: ${theme.palette.primary.icon};
`;

const formBlock12CSS = css`
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
  place-content: center;
`;

const Paragraph: React.FC<TypographyProps> = ({ children, color = 'primary' }) => (
  <Typography
    css={typography209CSS}
    variant="inherit"
    align="inherit"
    color={color}
    paragraph={false}
  >
    {children}
  </Typography>
);

interface LocationState {
  blurredEmail?: string;
}

export const PasswordRecoverySentPage: React.FC = () => {
  const location = useLocation<LocationState>();

  return (
    <RouteLayout css={passwordRecoverySentCSS}>
      <Box css={container434CSS}>
        <Box css={container432CSS}>
          <Paragraph>
            <Typography variant="subtitle4">All set! We sent an email to</Typography>
          </Paragraph>
          <Box display="flex" justifyContent="center">
            <Typography color="highlight" variant="subtitle4">
              {location.state?.blurredEmail || 'EMAIL@gmail.com'} &nbsp;
            </Typography>
            <Typography variant="subtitle4" color="primary">
              with
            </Typography>
          </Box>
          <Paragraph>
            <Typography variant="subtitle4">further instructions</Typography>
          </Paragraph>
        </Box>
        <Box css={formBlock12CSS}>
          <RouterLink
            css={loginLinkCSS}
            absolute={false}
            underline="none"
            color="primary"
            to={APP_URL.public.login}
          >
            Return to Login
          </RouterLink>
        </Box>
      </Box>
    </RouteLayout>
  );
};
