/* eslint-disable no-plusplus */
import { PHONE_MASK, PHONE_MASK_WILDCARD } from '@jebel/constants';
/**
 * Format a phone number from 8base using `PHONE_MASK` and `PHONE_MASK_WILDCARD` constants as replacement.
 */
export function formatPhoneNumber(phone) {
    if (!phone) {
        return '(Unknown)';
    }
    const withoutCountryCode = phone.replace(/^\+[0-9]{1,3}\s+/i, '');
    let formatted = '';
    const size = PHONE_MASK.length;
    for (let index = 0, offset = 0; index < size; index++) {
        const char = withoutCountryCode[offset];
        const curr = PHONE_MASK[index];
        if (curr === PHONE_MASK_WILDCARD) {
            formatted += char;
            offset++;
            continue;
        }
        formatted += curr;
    }
    return formatted;
}
