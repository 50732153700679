import React from 'react';
import { useParams } from 'react-router-dom';

import { useThreadsQuery } from 'features/collective/hooks/useThreadsQuery';
import { PostsProvider } from 'shared/features/posts';
import { useCollectiveIntelligenceThreadDetailsQuery } from 'shared/graphql';
import { DetailsContentLayout } from 'shared/components/layouts';

import { CollectiveIntelligenceCommentsList } from './CollectiveIntelligenceCommentsList';
import { CollectiveIntelligencePost } from './CollectiveIntelligencePost';
import { useThreadUpdateViews } from '../../hooks/useThreadUpdateViews';
import { useThreadWasViewed } from '../../hooks/useThreadWasViewed';

export const CollectiveIntelligenceDetailContent = () => {
  const { id } = useParams<{ id: string }>();
  const { activities, loading } = useThreadsQuery();

  const { onThreadView } = useThreadUpdateViews();
  const { data } = useCollectiveIntelligenceThreadDetailsQuery({ variables: { id } });

  const mainPost = data?.collectiveIntelligenceThread ?? undefined;
  const { isViewed } = useThreadWasViewed(mainPost);

  React.useEffect(() => {
    if (!isViewed && mainPost) {
      onThreadView(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewed, id, mainPost]);

  return (
    <DetailsContentLayout
      sections={[
        {
          id: 1,
          content: (
            <PostsProvider activities={activities} loading={loading}>
              <CollectiveIntelligenceCommentsList id={id} />
            </PostsProvider>
          ),
        },
      ]}
    >
      <PostsProvider activities={activities} loading={loading}>
        <CollectiveIntelligencePost id={id} post={mainPost} />
      </PostsProvider>
    </DetailsContentLayout>
  );
};
