import { Fragment, useCallback, useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import { Box, Icon } from 'shared/components/ui';
import { SchoolConfigurationLayout } from 'shared/graphql';
import { NAVIGATION_LABEL_LIST } from 'admin-features/settings/constants';
import { SwitchToggle } from 'shared/components/ui/SwitchToggle';

const containerCSS = (isDragging: boolean) => (theme: Theme) =>
  css`
    display: flex;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${theme.palette.background.paper};

    ${isDragging &&
    css`
      box-shadow: ${theme.shadows[2]};
    `}

    :not(:last-child) {
      border-bottom: 1px solid ${theme.palette.divider};
    }
  `;

const buttonsCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const titleCSS = css`
  ${buttonsCSS};

  white-space: nowrap;
`;

const textCSS = css`
  white-space: nowrap;
`;

const editIconCSS = (theme: Theme) => css`
  color: ${theme.palette.text.light};
`;

interface Props {
  item: SchoolConfigurationLayout;
  provided: any;
  snapshot: any;
  onItemUpdate: (item: SchoolConfigurationLayout) => void;
  disableOptions?: boolean;
  onModalOpen?: () => void;
}

export const Label = ({
  item,
  provided,
  snapshot,
  onItemUpdate,
  onModalOpen,
  disableOptions = false,
}: Props) => {
  const onSwitchChange = useCallback(() => {
    onItemUpdate({ ...item, isActive: !item.isActive });
  }, [item, onItemUpdate]);

  const routeName = useMemo(() => {
    const key = Object.keys(NAVIGATION_LABEL_LIST).find(route => route === item.route?.slice(1));

    if (!key) {
      return '(Unknown Route)';
    }

    return NAVIGATION_LABEL_LIST[key];
  }, [item]);

  return (
    <ListItem
      css={containerCSS(snapshot.isDragging)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      <ListItemIcon>
        <Icon name="DragHandle" variant="filled" fontSize="small" css={editIconCSS} />
      </ListItemIcon>

      <ListItemIcon color="GrayText">
        <Icon name={item.icon} variant="filled" fontSize="small" />
      </ListItemIcon>

      <ListItemText
        css={titleCSS}
        disableTypography
        primary={
          <Fragment>
            <Box css={textCSS}>
              <Typography variant="body2">{item.name}</Typography>

              <Typography variant="caption" color="textSecondary">
                {routeName}
              </Typography>
            </Box>
          </Fragment>
        }
      />

      <Box css={buttonsCSS}>
        <IconButton disabled={disableOptions} onClick={onModalOpen}>
          <Icon name="Edit" variant="filled" fontSize="small" css={editIconCSS} />
        </IconButton>

        <SwitchToggle
          disabled={disableOptions}
          checked={item?.isActive ?? false}
          onChange={onSwitchChange}
        />
      </Box>
    </ListItem>
  );
};
