import React from 'react';

import { CreateGraduatingYearFormValues } from 'shared/validationSchemas';
import { useGraduatingYearsSpreadsheetInfo } from 'shared/hooks';
import { Modal } from 'shared/components/ui';

import { useScorecardStats } from '../hooks/useScorecardStats';
import { CreateOrEditGraduatingYearForm } from './CreateOrEditGraduatingYearForm';

type CreateGraduatingYearModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
  onCreate: () => void | Promise<void>;
};

export const CreateGraduatingYearModal: React.FC<CreateGraduatingYearModalProps> = ({
  isOpen,
  onModalClose,
  onCreate,
}) => {
  const { graduatingYears } = useScorecardStats();
  const { createGraduatingYear } = useGraduatingYearsSpreadsheetInfo();

  const onSubmit = React.useCallback(
    async (formData: CreateGraduatingYearFormValues) => {
      await createGraduatingYear({
        variables: {
          data: {
            graduateCount: formData.alumniCount,
            year: Number(formData.classYear),
          },
        },
      });

      onCreate();
      onModalClose();
    },
    [createGraduatingYear, onCreate, onModalClose],
  );

  return (
    <Modal
      dialogProps={{ open: isOpen, onClose: onModalClose }}
      titleProps={{ title: 'New Class' }}
    >
      <CreateOrEditGraduatingYearForm
        onSubmit={onSubmit}
        onClose={onModalClose}
        existingGraduatingYears={graduatingYears}
      />
    </Modal>
  );
};
