/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { HomeFeedItemType, POST_REPORT_STATUS, POST_STATUSES } from '@jebel/constants';

import { HomeFeedItemFilter, HomeFeedItemFragment, HomeFeedItemSort } from 'shared/graphql';
import { createFilterBuilder } from 'shared/queries/filterBuilder';

import { UseHomeFeedItemsOptions, useHomeFeedItems } from './useHomeFeedItems';

/** Sort all the posts in the feed using business rules. */
export const FEED_SORT: HomeFeedItemSort[] = [{ postDate: 'DESC' }];

/** Filter posts that are already approved to be on the feed. */
export const FEED_APPROVED_FILTER: HomeFeedItemFilter = {
  status: { equals: POST_STATUSES.approved },
  reportStatus: { equals: POST_REPORT_STATUS.postAllowed },
};

/** Filter posts that are already available to be on the feed. */
export const FEED_EXCLUDE_SCHEDULED_FILTER: HomeFeedItemFilter = {
  OR: [{ isFuture: { equals: 0 } }],
};

export interface UsePostsFeedModifersOptions {
  /**
   * Allow to skip or filter by type of allowed pinned posts.
   * https://8base-dev.atlassian.net/browse/JEB-1580?focusedCommentId=44133
   * @default true
   */
  withPinned?: boolean | HomeFeedItemType[];

  /**
   * Allow to override the rule of the first pinned post should be at the top.
   * @default true
   */
  withPinnedOnTop?: boolean;

  /**
   * Includes the scheduled post on the query.
   * @default false
   */
  withSchedule?: boolean;

  /**
   * Allow to have multiple pinned post on the query.
   * @default false
   */
  withManyPinned?: boolean;
}

export type UsePostsFeedOptions = UsePostsFeedModifersOptions & UseHomeFeedItemsOptions;

export function usePostsFeed(options?: UsePostsFeedOptions) {
  const withSchedule = options?.withSchedule ?? false;
  const withPinned = options?.withPinned ?? true;
  const withPinnedOnTop = options?.withPinnedOnTop ?? true;
  const withManyPinned = options?.withManyPinned ?? false;

  const sort = useMemo(() => {
    const selectedSort = options?.variables?.sort;

    if (!selectedSort) {
      return FEED_SORT;
    }

    if (Array.isArray(selectedSort)) {
      return [...FEED_SORT, ...selectedSort];
    }

    return [selectedSort];
  }, [options]);

  const pinnedPostFilter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>(options?.variables?.filter);

    filter
      .and(FEED_APPROVED_FILTER)
      // Exclude scheduled in order to have always the currently pinned.
      // https://8base-dev.atlassian.net/browse/JEB-1552
      .and(FEED_EXCLUDE_SCHEDULED_FILTER)
      .and({ isPinned: { equals: 1 } });

    if (Array.isArray(withPinned)) {
      // Allow to filter the kind of pinned posts allowed on the feed.
      // https://8base-dev.atlassian.net/browse/JEB-1580?focusedCommentId=44133
      filter.and({ postType: { in: withPinned } });
    }

    return filter.build();
  }, [options?.variables?.filter]);

  const { data: pinnedPosts, loading: pinnedLoading } = useHomeFeedItems({
    skip: options?.skip || !withPinned || !withPinnedOnTop,
    variables: {
      first: withManyPinned ? undefined : 1,
      sort: FEED_SORT,
      filter: pinnedPostFilter,
    },
  });

  const postsFilter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>(options?.variables?.filter);

    filter.and(FEED_APPROVED_FILTER);

    if (!withSchedule) {
      // Include the scheduled post on the query.
      filter.and(FEED_EXCLUDE_SCHEDULED_FILTER);
    }

    if (pinnedPosts.length > 0) {
      const excluded = pinnedPosts.map(pinnedPost => pinnedPost.id as string);

      filter.and({
        // This condition filter the pinned posts on the feed.
        // https://8base-dev.atlassian.net/browse/JEB-1382?focusedCommentId=40865
        // Pinned post will be added later.
        id: { not_in: excluded },
      });
    }

    return filter.build();
  }, [options?.variables?.filter, withSchedule, pinnedPosts]);

  const {
    data: posts,
    count: postsCount,
    loading: postsLoading,
    refreshing,
    networkStatus,
    refetch,
    fetchMore,
  } = useHomeFeedItems({
    ...options,

    skip: options?.skip || pinnedLoading,

    variables: {
      ...options?.variables,

      sort,
      filter: postsFilter,
    },
  });

  const data: HomeFeedItemFragment[] = useMemo(() => {
    const queue = [...pinnedPosts];

    // Checks when the first pinned post have been save into the queue.
    let isFirstPinnedSaved = queue.length > 0;

    for (const post of posts) {
      // Put the general feed posts as non-pinned to avoid confusion.
      // https://8base-dev.atlassian.net/browse/JEB-1382?focusedCommentId=40865
      let isPinned = withManyPinned ? post.isPinned : 0;

      if (withPinnedOnTop && !isFirstPinnedSaved && post.isPinned) {
        isPinned = post.isPinned;
        isFirstPinnedSaved = true;
      }

      queue.push({ ...post, isPinned });
    }

    return queue;
  }, [pinnedPosts, posts, withManyPinned]);

  const count = pinnedPosts.length + postsCount;
  const loading = pinnedLoading || postsLoading;

  return {
    data,
    count,
    loading,
    refreshing,
    networkStatus,
    refetch,
    fetchMore,
  };
}
