import React from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';

import { ClassScorecardContent } from '../components/ClassScorecardContent';

export const ClassScorecardPage = () => {
  return (
    <SearchProvider>
      <RouteLayout content={<ClassScorecardContent />} />
    </SearchProvider>
  );
};
