import { gql } from '@apollo/client';

export const POSTS_COUNT_QUERY = gql`
  query PostsCount($jobOffersFilter: JobOfferFilter, $eventsFilter: EventFilter) {
    jobPostsCount: jobOffersList(filter: $jobOffersFilter) {
      items {
        id
        createdAt
        engagementCount
      }
      count
    }
    eventPostsCount: eventsList(filter: $eventsFilter) {
      items {
        id
        createdAt
      }
      count
    }
  }
`;

export const BUSINESSES_COUNT_QUERY = gql`
  query BusinessCount(
    $filter: OrganizationFilter
    $startPointZip: String!
    $radius: String!
    $supporterFilter: OrganizationFilter
  ) {
    businesses: businessFilterByZipRequest(
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
    supporters: businessFilterByZipRequest(
      filter: $supporterFilter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

export const SPECIAL_OFFERS_COUNT_QUERY = gql`
  query SpecialOffersCount($activeFilter: DiscountFilter, $redeemedFilter: DiscountFilter) {
    activeSpecialOffersCount: discountsList(filter: $activeFilter) {
      count
    }
    redeemedSpecialOffersCount: discountsList(filter: $redeemedFilter) {
      items {
        usersRedeemed {
          count
        }
      }
    }
  }
`;

export const MEMBERS_COUNT_BY_AFFILIATION_QUERY = gql`
  query MembersCountByAffiliation(
    $alumniFilter: UserFilter
    $studentFilter: UserFilter
    $staffFilter: UserFilter
    $parentFilter: UserFilter
    $otherFilter: UserFilter
  ) {
    alumniUsers: usersList(filter: $alumniFilter) {
      count
    }
    studentUsers: usersList(filter: $studentFilter) {
      count
    }
    staffUsers: usersList(filter: $staffFilter) {
      count
    }
    parentUsers: usersList(filter: $parentFilter) {
      count
    }
    otherUsers: usersList(filter: $otherFilter) {
      count
    }
  }
`;

export const DASHBOARDS_MEMBERS_LIST_QUERY = gql`
  query DashboardsMembersList($filter: DashboardFilter) {
    dashboardsList(filter: $filter, sort: { createdAt: ASC }) {
      items {
        id
        createdAt
        totalMembers
      }
    }
  }
`;

export const DASHBOARDS_ADVERTISEMENTS_LIST_QUERY = gql`
  query DashboardsAdvertisementsList($filter: DashboardFilter) {
    dashboardsList(filter: $filter, sort: { createdAt: ASC }) {
      items {
        id
        createdAt
        totalAdvertisements
        totalAdRevenue
        totalSupporters
        totalDiscounts
        totalAllRevenue
        totalDiscountsRevenue
        totalSupportersRevenue
        totalVolume
      }
    }
  }
`;
