import { capitalize } from 'lodash';

import { SORT } from '@jebel/constants';

import {
  OrganizationFilter,
  OrganizationItemFragment,
  useOrganizationsListLazyQuery,
} from 'shared/graphql';
import { createFilterBuilder } from 'shared/queries/filterBuilder';
import { useCurrentUserOrganizationsLazy } from 'shared/hooks';

import {
  FormSelectDataSource,
  FormSelectDataSourceOption,
  FormSelectDataSourceOptionText,
  FormSelectDataSourceDefinitionProps,
  FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT,
} from '../FormSelectDataSource';

type BasicProps = FormSelectDataSourceDefinitionProps<OrganizationItemFragment>;

type Props = BasicProps & {
  /**
   * Filter the organizations by "status".
   * @default true
   */
  onlyActive?: boolean;

  /**
   * Filter the organizations by "status".
   * @default false
   */
  onlyManaged?: boolean;
};

export function FormSelectOrganization(props: Props) {
  const [fetchOrganizations] = useOrganizationsListLazyQuery();
  const [fetchManagedOrganizations] = useCurrentUserOrganizationsLazy();

  const onlyActive = props.onlyActive ?? true;
  const onlyManaged = props.onlyManaged ?? true;

  const fetchOptions = async (search: string) => {
    const isSuggestions = search.length === 0;

    const filter = createFilterBuilder<OrganizationFilter>({
      name: { contains: search },
    });

    if (onlyActive) {
      filter.and({ status: { equals: 'active' } });
    }

    const fetchOptions = onlyManaged ? fetchManagedOrganizations : fetchOrganizations;

    const response = await fetchOptions({
      variables: {
        first: isSuggestions ? FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT : undefined,
        sort: [{ name: SORT.asc }],
        filter: filter.build(),
      },
    });

    return response.data?.organizations.items ?? [];
  };

  const extractIdentifier = (option: OrganizationItemFragment) => {
    return option.id as string;
  };

  const extractLabel = (option?: OrganizationItemFragment) => {
    return option?.name ?? '(Unknown)';
  };

  return (
    <FormSelectDataSource<OrganizationItemFragment>
      {...props}
      extractIdentifier={extractIdentifier}
      extractLabel={extractLabel}
      fetchOptions={fetchOptions}
    >
      {(params, option) => (
        <FormSelectDataSourceOption {...params} key={option?.id}>
          <FormSelectDataSourceOptionText>{option.name}</FormSelectDataSourceOptionText>

          {option.industry && (
            <FormSelectDataSourceOptionText isSmall>
              {capitalize(option.industry)}
            </FormSelectDataSourceOptionText>
          )}
        </FormSelectDataSourceOption>
      )}
    </FormSelectDataSource>
  );
}
