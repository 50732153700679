import { extractYoutubeIdentifier, isYouTubeVideoURL } from '@jebel/utils';
import { Container, Content, Description, Image, Link, Title } from './LinkPreview.styles';
import { Skeleton } from '@material-ui/lab';
import { useGenerateLinkPreviewQuery } from 'shared/graphql';
import { useMemo } from 'react';
import { YoutubePreview } from '../YoutubePreview';

export interface LinkPreviewProps {
  url: string;
}

/**
 * Show a preview of the given URL.
 * @ticket https://github.com/jebelapp/jebel/issues/1471
 */
export function LinkPreview(props: LinkPreviewProps) {
  const { data: response, loading } = useGenerateLinkPreviewQuery({
    skip: !props.url,
    variables: { url: props.url },
  });

  const preview = response?.preview;

  const youtubeReference = useMemo(() => {
    const isYoutube = isYouTubeVideoURL(props.url);

    if (!isYoutube) {
      return null;
    }

    return extractYoutubeIdentifier(props.url);
  }, [props.url]);

  if (youtubeReference) {
    return <YoutubePreview embedId={youtubeReference} />;
  }

  if (loading) {
    return (
      <Container>
        <Skeleton variant="rect">
          <Image />
        </Skeleton>

        <Content>
          <Skeleton>
            <Title>Lorem ipsum</Title>
          </Skeleton>

          <Skeleton>
            <Description>Lorem ipsum dolor sit.</Description>
          </Skeleton>

          <Skeleton>
            <Description>Lorem ipsum dolor sit amet consectetur adipisicing.</Description>
          </Skeleton>
        </Content>
      </Container>
    );
  }

  if (!preview) {
    return null;
  }

  return (
    <Container href={props.url} target="_blank">
      {preview.imageURL && <Image src={preview.imageURL} alt={preview.title} />}

      <Content>
        <Title>{preview.title}</Title>

        {preview.description && <Description>{preview.description}</Description>}

        <Link>{props.url}</Link>
      </Content>
    </Container>
  );
}
