import { useMemo } from 'react';

import { ORGANIZATION_STATUS } from '@jebel/constants';

import { ChooseStatusChip, ChooseStatusChipOption } from 'shared/components/ui';

interface Props {
  status: string;
  options?: ChooseStatusChipOption[];
}

const ORGANIZATION_STATUS_COLORS = {
  [ORGANIZATION_STATUS.active]: '#01B9A1',
  [ORGANIZATION_STATUS.pendingApproval]: '#EF5C5C',
  [ORGANIZATION_STATUS.inactive]: '#616DEE',
  [ORGANIZATION_STATUS.invitationSent]: '#FF782A',
};

const ORGANIZATION_STATUS_LABEL = {
  [ORGANIZATION_STATUS.active]: 'Active',
  [ORGANIZATION_STATUS.pendingApproval]: 'Pending Approval',
  [ORGANIZATION_STATUS.inactive]: 'Inactive',
  [ORGANIZATION_STATUS.invitationSent]: 'Invitation Sent',
};

export function ChooseOrganizationStatusChip(props: Props) {
  const color = useMemo(() => {
    return ORGANIZATION_STATUS_COLORS[props.status];
  }, [props.status]);

  const label = useMemo(() => {
    return ORGANIZATION_STATUS_LABEL[props.status];
  }, [props.status]);

  return <ChooseStatusChip label={label} color={color} options={props.options} />;
}
