import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { SORT } from '@jebel/constants';

import { Button, Typography } from 'shared/components/ui';
import { SearchControl, SortFieldProps, useSearchContext } from 'shared/features/search';
import { useCrudPermissions, usePageTitle, useResponsive } from 'shared/hooks';

const containerCSS = css`
  display: block;
  grid-gap: 2rem;
`;

const rowCss = theme => css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

const titleCss = theme => css`
  ${theme.breakpoints.down('xs')} {
    font-size: 22px;
    font-weight: 400;
  }
`;

const SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Alphabetical',
        value: {
          name: SORT.asc,
        },
      },
      {
        label: 'Random',
        value: {
          createdAt: SORT.desc,
        },
      },
    ],
  },
};

interface Props {
  onOpenClaimModal(): void;
}

export function OrganizationsHeader({ onOpenClaimModal }: Props) {
  const title = usePageTitle({ fallback: 'Organizations' });

  const { isTablet } = useResponsive();
  const { setDisplayList, setIsFilterModalOpen } = useSearchContext();
  const { businessPermissions, loading: permissionsLoading } = useCrudPermissions();

  useEffect(() => {
    if (isTablet) {
      setDisplayList(true);
    }

    if (!isTablet) {
      setIsFilterModalOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet]);

  return (
    <Box css={containerCSS}>
      <Box css={rowCss}>
        <Typography css={titleCss} variant="subtitle2" color="primary">
          {title}
        </Typography>

        {businessPermissions.add && (
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={permissionsLoading}
            onClick={onOpenClaimModal}
          >
            {isTablet ? 'claim business' : 'claim your business'}
          </Button>
        )}
      </Box>

      <SearchControl
        withSortField
        withLayoutControl={!isTablet}
        textFieldProps={{ style: { flexShrink: 1 }, placeholder: 'Search Organizations' }}
        sortFieldProps={SORT_INFO}
        elementsWidth={!isTablet ? ['2fr', '1fr', '0fr'] : []}
        elementsGap="20px"
        withFilterModal={isTablet}
      />
    </Box>
  );
}
