import { Skeleton } from '@mui/material';

import { Container } from './PostFeedLoader.styles';

interface Props {
  /** @default 5 */
  count?: number;
}

const FALLBACK_COUNT = 5;

export function PostFeedLoader(props: Props) {
  const items = new Array(props.count ?? FALLBACK_COUNT).fill(null);

  return (
    <Container>
      {items.map((_item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} variant="rectangular" width="100%" height="15rem" />
      ))}
    </Container>
  );
}
