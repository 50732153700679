import { css } from '@emotion/react';

import {
  EVENT_POST_TYPE,
  GROUP_POST_TYPE,
  HOME_POST_TYPE,
  LEGACY_POST_TYPE,
  POST_REPORT_STATUS,
  SCHOOL_POST_TYPE,
} from '@jebel/constants';

import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import { SortOrder } from 'shared/graphql/__generated__';

export const POSTS_REPORT_STATUS = [
  { value: POST_REPORT_STATUS.postAllowed, label: 'Post allowed' },
  { value: POST_REPORT_STATUS.pendingReview, label: 'Pending review' },
  { value: POST_REPORT_STATUS.postDeleted, label: 'Post deleted' },
];

export const CONTENT_CURATION_CATEGORIES = {
  schoolPost: SCHOOL_POST_TYPE,
  groupPost: GROUP_POST_TYPE,
  userPost: HOME_POST_TYPE,
  legacyPost: LEGACY_POST_TYPE,
  eventPost: EVENT_POST_TYPE,
};

export enum ContentCurationReportHeaders {
  post = 'Post',
  author = 'Author',
  flaggedBy = 'Flagged By',
  date = 'Date',
  status = 'Status',
}

export const ContentCurationPostsSpreadsheetHeadlines: HeadlinesType = [
  {
    name: 'post',
    label: 'POST',
    id: 'post',
    sortPath: (order: SortOrder) => ({
      text: order,
    }),
    css: () =>
      css`
        width: 22.9%;
      `,
  },
  {
    name: 'author',
    label: 'AUTHOR',
    id: 'author',
    sortPath: (order: SortOrder) => ({ author: order }),
    css: () =>
      css`
        width: 15.67%;
      `,
  },
  {
    name: 'flaggedBy',
    label: 'FLAGGED BY',
    id: 'flaggedBy',
    sortPath: (order: SortOrder) => ({ LastFlaggedByName: order }),
    css: () =>
      css`
        width: 14.92%;
      `,
  },
  {
    name: 'updatedAt',
    label: 'DATE',
    id: 'updatedAt',
    sortPath: (order: SortOrder) => ({ updatedAt: order }),
    css: () =>
      css`
        width: 18.66%;
      `,
  },
  {
    name: 'reportStatus',
    label: 'STATUS',
    id: 'reportStatus',
    sortPath: (order: SortOrder) => ({
      reportStatus: order,
    }),
    css: () =>
      css`
        width: 15.67%;
      `,
  },
];

export const ContentCurationBlacklistWordsSpreadsheetHeadlines: HeadlinesType = [
  {
    name: 'post',
    label: 'WORD/PHRASE',
    id: 'post',
    sortPath: (order: SortOrder) => ({
      text: order,
    }),
  },
  {
    name: 'author',
    label: 'UPDATED BY',
    id: 'author',
    sortPath: (order: SortOrder) => ({
      author: order,
    }),
  },
  {
    name: 'updatedAt',
    label: 'DATE',
    id: 'updatedAt',
    sortPath: (order: SortOrder) => ({ updatedAt: order }),
  },
  {
    name: 'reportStatus',
    label: 'STATUS',
    id: 'reportStatus',
    sortPath: (order: SortOrder) => ({
      reportStatus: order,
    }),
  },
];

const POST_REPORT_STATUS_OPTIONS = POSTS_REPORT_STATUS.filter(
  ({ value }) => value !== POST_REPORT_STATUS.postAllowed,
).map(({ value, label }) => ({ value, text: label }));

export const ContentCurationSpreadsheetFilters: SpreadsheetFiltersType = [
  {
    type: 'select',
    variants: POST_REPORT_STATUS_OPTIONS,
    label: 'STATUS',
    name: 'status',
    placeholder: 'Post status',
    filterPath: (value: string) => {
      if (value === '') return {};
      return { reportStatus: { equals: value } };
    },
  },
];
