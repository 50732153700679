import React from 'react';
import { css } from '@emotion/react';
import { List, ListItemText } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { NavListItemSymbol, ListItemIconSymbol } from 'shared/components/symbols';

const listItemContainerCss = css`
  padding-left: 1.5rem;
`;

const listItemIconCss = (theme: any) => css`
  margin-right: 10px;
  fill: ${theme.palette.text.secondary};
`;

const containerCss = (theme: any) => css`
  border-bottom-color: ${theme.palette.border};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

type ContentCurationSidebarListItemProps = {
  title: string;
  icon: string;
  route: string;
};

type ContentCurationSidebarListProps = {
  listData: ContentCurationSidebarListItemProps[];
};

export const ContentCurationSidebarList = ({ listData }: ContentCurationSidebarListProps) => {
  const location = useLocation();

  return (
    <List css={containerCss}>
      {listData.map(item => (
        <NavListItemSymbol
          css={listItemContainerCss}
          key={item.route}
          path={item.route}
          selected={location.pathname === item.route}
          button
          disableGutters
          startIcon={
            <ListItemIconSymbol
              css={listItemIconCss}
              name={item.icon}
              variant="filled"
              color="inherit"
            />
          }
          listItemText={<ListItemText disableTypography={false}>{item.title}</ListItemText>}
        />
      ))}
    </List>
  );
};
