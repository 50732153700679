import React from 'react';
import { useQuery, DocumentNode, OperationVariables } from '@apollo/client';
import * as R from 'ramda';

import { useSearchContext } from 'shared/features/search';
import { useStreamChatActivityQuery } from 'shared/hooks/useStreamChatActivity';

import { useGetPinnedPosts } from './useGetPinnedPosts';
import { getPinnedPosts } from '../utils';

type UsePostsListProps = {
  listQuery: DocumentNode;
  countQuery: DocumentNode;
  itemsFieldName: string;
  variables: OperationVariables;
};

export function usePostsList<TListData = any, TCountData = any>({
  listQuery,
  countQuery,
  itemsFieldName,
  variables,
}: UsePostsListProps) {
  const {
    activities,
    loading: activitiesLoading,
    onListQueryComplete: rawOnQueryCompleted,
    postsRef,
  } = useStreamChatActivityQuery();
  const { isUserTyping } = useSearchContext();
  const onCompleted = React.useCallback(
    (data?: TListData, pinnedPostsList?: any) => {
      const pinnedPostItems = pinnedPostsList?.pinnedPostsList?.items;
      const items: Record<string, any>[] | undefined = data?.[itemsFieldName]?.items;

      const pinnedPosts: any[] = getPinnedPosts(pinnedPostItems);

      if (!items || !pinnedPosts) {
        return;
      }
      const returnData = [...(pinnedPosts ? [...pinnedPosts] : []), ...(items ? [...items] : [])];
      rawOnQueryCompleted(returnData);
    },
    [itemsFieldName, rawOnQueryCompleted],
  );
  const { pinnedPostData, pinnedPostFetchMore, pinnedPostLoading, pinnedPostRefetch } =
    useGetPinnedPosts();

  const {
    data: listsData,
    loading: listsLoading,
    fetchMore,
    refetch: refetchList,
  } = useQuery<TListData, any>(listQuery, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<TCountData, any>(countQuery, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const refetch = React.useCallback(() => {
    const refetchListPromise = refetchList({ first: postsRef.current.length || 1 });
    const refetchCountPromise = refetchCount();
    const refetchPinnedPosts = pinnedPostRefetch();

    return Promise.all([refetchListPromise, refetchCountPromise, refetchPinnedPosts]);
  }, [postsRef, refetchCount, refetchList]);

  React.useEffect(() => {
    if (listsData || pinnedPostData) {
      onCompleted(listsData, pinnedPostData);
    }
  }, [listsData, pinnedPostData, onCompleted]);

  return {
    listsData,
    pinnedPostData,
    countData,
    loading: (listsLoading && !listsData) || (countLoading && !countData) || pinnedPostLoading,
    fetchMore,
    refetch,
    activities,
    activitiesLoading,
  };
}
