import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

// Section

export const Section = styled(Container)`
  grid-template-columns: 1fr 1fr;

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

// Terms

export const Terms = styled.div`
  color: ${p => p.theme.palette.grey[500]};
`;
