import { CareerInfoFragment, EventInfoFragment, Maybe } from 'shared/graphql';

import { glueStrings } from '../strings';

export const getOrganizationAddress = (organization: CareerInfoFragment['organization']) => {
  const address = organization?.location?.items[0]?.address?.items[0];
  const city = address?.city ? `${address?.city},` : '';
  const state = address?.state ? `${address?.state},` : '';
  return `${city} ${state} US`;
};

export const getBusinessAddressShort = (
  location?: Maybe<{
    city?: Maybe<string>;
    state?: Maybe<string>;
    street1?: Maybe<string>;
    zip?: Maybe<string>;
  }>,
) => {
  if (!location || !location.state || !location.city) {
    return '(No location found)';
  }

  const city = location.city ?? '';
  // Show state as abbreviation
  // https://8base-dev.atlassian.net/browse/JEB-1594
  const state = location?.state ?? '(All)';
  const street = location?.street1 ?? '';
  // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=46027
  const zip = location?.zip ?? '';

  return glueStrings(', ', street, city, state, zip);
};

export const getEventAddress = (eventLocation: EventInfoFragment['location']) =>
  `${eventLocation?.street1}, ${eventLocation?.city}, ${eventLocation?.state} ${eventLocation?.zip}`;
