import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import { Skeleton } from '@mui/material';

import { GroupNameLink, SchoolNameLink, UserNameLink } from 'shared/components/symbols';

// Container

export const Container = styled.div`
  --author-avatar-size: 2.5rem;

  display: flex;
  gap: 0.5rem;
  flex: 1;
`;

// Content

export const Content = styled.div`
  min-height: var(--author-avatar-size);
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 1;
`;

// AuthorAvatar

const authorAvatarSizeCSS = css`
  min-width: var(--author-avatar-size);
  min-height: var(--author-avatar-size);
`;

export const AuthorAvatar = styled(Avatar)`
  ${authorAvatarSizeCSS};
`;

// AuthorAvatarSkeleton

export const AuthorAvatarSkeleton = styled(Skeleton)`
  ${authorAvatarSizeCSS};
`;

AuthorAvatarSkeleton.defaultProps = {
  variant: 'circular',
};

const authorNameCSS = (props: { theme: Theme }) => css`
  font-weight: bold;
  color: ${props.theme.palette.primary.main};
`;

// AuthorName

export const AuthorName = styled(UserNameLink)`
  ${authorNameCSS};
`;

// SchoolName

export const SchoolName = styled(SchoolNameLink)`
  ${authorNameCSS};
`;

// GroupName

export const GroupName = styled(GroupNameLink)`
  ${authorNameCSS};
`;

// GroupDetailsContainer

export const GroupDetailsContainer = styled.div``;

// GroupAuthorName

export const GroupAuthorName = styled(UserNameLink)`
  margin-right: 0.25rem;
`;

// PostDate

export const PostDate = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;
