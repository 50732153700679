import { Dispatch, Fragment, SetStateAction, useMemo } from 'react';
import { css, Theme } from '@emotion/react';
import { Box, Typography, Skeleton } from '@mui/material';
import styled from '@emotion/styled';

import { OnCommentRemove, OnLikeAdd, OnReactionRemove } from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { useCommentAuthorQuery } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { MediaGallery } from 'shared/components/ui';
import { useCurrentUser, useRelativeDate } from 'shared/hooks';
import { MentionsText } from 'shared/features/mentions';
import { UserAvatar, UserNameLink } from 'shared/components/symbols';

import { LikeButton } from '../LikeButton';
import { CommentButton } from './CommentButton';

const nameCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const postedAtCss = (theme: Theme) => css`
  color: ${theme.palette.text.secondary};
  font-weight: 500;
`;

const AVATAR_SIZE = 32;

const avatarCss = css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const commentContentBoxCss = (theme: Theme) => css`
  background-color: #f6f8fa;
  border-radius: 6px;
`;

const CommentDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CommentDeleteText = styled.span`
  color: ${p => p.theme.palette.grey[500]};
  font-size: ${p => p.theme.typography.body2.fontSize};
  font-weight: 400;
`;

const COMMENT_IMAGE_SIZE = 120;

export type CommentContentProps = {
  comment: CommentEnrichedReaction;
  onCommentAdd: (authorName?: string | undefined) => void;
  onCommentRemove: OnCommentRemove;
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  setComments: Dispatch<SetStateAction<CommentEnrichedReaction[]>>;
  disableComments?: boolean;
};

const DeletedContentPlaceholder = () => (
  <CommentDeleteContainer>
    <CommentDeleteText>This comment was removed by administrator.</CommentDeleteText>
  </CommentDeleteContainer>
);

const LoadingContentPlaceholder = () => (
  <Box display="flex" flexDirection="row" flex={1} gap={1}>
    <Skeleton css={avatarCss} variant="circular" width={40} height={40} />

    <Box flex={1}>
      <Skeleton variant="rectangular" height={50} />

      <Box display="flex" gap={1} mt={1}>
        <Skeleton variant="rectangular" width={65} height={40} />
        <Skeleton variant="rectangular" width={65} height={40} />
      </Box>
    </Box>
  </Box>
);

export function CommentContent({
  comment,
  onCommentAdd,
  onCommentRemove,
  onLikeAdd,
  onReactionRemove,
  setComments,
  disableComments = false,
}: CommentContentProps) {
  const { isAdmin } = useCurrentUser();

  const { data: commentAuthorData, loading: isCommentAuthorLoading } = useCommentAuthorQuery({
    variables: {
      id: comment.user_id,
    },
    skip: !comment.id,
    fetchPolicy: 'cache-and-network',
  });

  const relativeDate = useRelativeDate(comment.created_at);

  const commentAuthor = useMemo(() => {
    return commentAuthorData?.user || undefined;
  }, [commentAuthorData]);

  const authorFullName = useMemo(() => {
    return formatUserName(commentAuthor, 'Deleted User');
  }, [commentAuthor]);

  if (isCommentAuthorLoading) {
    return <LoadingContentPlaceholder />;
  }

  return (
    <Box display="flex" flexDirection="row" flex={1} gap={1}>
      <UserAvatar user={commentAuthorData?.user} />

      <Box flex={1} sx={{ overflow: 'hidden' }}>
        <Box css={commentContentBoxCss} flex={1} px={2} py={1}>
          <Box display="flex" flex={1}>
            <Box display="flex" flex={1} sx={{ overflow: 'hidden' }}>
              <UserNameLink css={nameCSS} user={commentAuthorData?.user} />
            </Box>

            <Typography css={postedAtCss}>{relativeDate}</Typography>
          </Box>

          {!comment.data.deleted ? (
            <Fragment>
              {comment.data.text && <MentionsText text={comment.data.text} paragraph={false} />}

              {(comment.data.media || comment.data.imageUrl) && (
                <MediaGallery
                  height={COMMENT_IMAGE_SIZE}
                  media={comment.data.media ?? [{ downloadUrl: comment.data.imageUrl }]}
                />
              )}
            </Fragment>
          ) : (
            <DeletedContentPlaceholder />
          )}
        </Box>

        <Box display="flex" gap={1} mt={1}>
          {!comment.data.deleted && (
            <LikeButton
              onLikeAdd={onLikeAdd}
              commentId={comment.id}
              onReactionRemove={onReactionRemove}
              likeInfo={comment.latest_children?.like || []}
              parentEntityAuthorId={comment.user_id}
            />
          )}

          <CommentButton
            onComment={() => onCommentAdd(authorFullName)}
            withDelete={isAdmin && !comment.data.deleted}
            commentId={comment.id || ''}
            setComments={setComments}
            onCommentRemove={onCommentRemove}
            disableComments={disableComments}
          />
        </Box>
      </Box>
    </Box>
  );
}
