import { HASHTAG_MARKUP_REGEX, HASHTAG_TRIGGER, MENTION_MARKUP_REGEX, MENTION_TRIGGER, } from '@jebel/constants';
import { URL_REGEX } from '../url';
export function parseHomeFeedItemText(text) {
    let lastToken = 0;
    const tokens = [];
    const createText = (startPosition, endPosition) => {
        const content = text.slice(startPosition, endPosition);
        tokens.push({ token: 'text', content, startPosition, endPosition });
    };
    for (let cursor = 0; cursor < text.length; cursor++) {
        const pointer = text[cursor];
        const section = text.slice(cursor);
        if (pointer === MENTION_TRIGGER) {
            const matches = section.match(MENTION_MARKUP_REGEX);
            if (!matches || !(matches === null || matches === void 0 ? void 0 : matches.groups)) {
                continue;
            }
            const size = matches[0].length;
            const identifier = matches.groups.id;
            const content = matches.groups.display;
            const type = matches.groups.type;
            if (lastToken < cursor) {
                createText(lastToken, cursor);
            }
            tokens.push({
                token: 'mention',
                startPosition: cursor,
                endPosition: cursor + size,
                identifier,
                type,
                content,
            });
            lastToken = cursor += size;
            continue;
        }
        if (pointer === HASHTAG_TRIGGER) {
            const matches = section.match(HASHTAG_MARKUP_REGEX);
            if (!matches || !(matches === null || matches === void 0 ? void 0 : matches.groups)) {
                continue;
            }
            const size = matches[0].length;
            const display = matches.groups.display;
            if (lastToken < cursor) {
                createText(lastToken, cursor);
            }
            tokens.push({
                token: 'hashtag',
                startPosition: cursor,
                endPosition: lastToken,
                content: display,
            });
            lastToken = cursor += size;
            continue;
        }
        const matchURL = section.match(URL_REGEX);
        if (matchURL) {
            const content = matchURL[0];
            const size = content.length;
            if (lastToken < cursor) {
                createText(lastToken, cursor);
            }
            tokens.push({
                token: 'url',
                startPosition: cursor,
                endPosition: lastToken,
                url: content,
            });
            lastToken = cursor += size;
            continue;
        }
    }
    if (lastToken < text.length) {
        createText(lastToken, text.length);
    }
    return tokens;
}
