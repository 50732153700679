import { useCallback } from 'react';

import { LEGACY_POST_TYPE } from '@jebel/constants';

import { LegacyFeedPostUpdateInput, useUpdatePostMutation } from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

export const useLegacyFeedPostUpdate = () => {
  const [postUpdate, mutationData] = useUpdatePostMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'LegacyTimeStamps',
      'LegacyTimelineYears',
      'LegacyTimelineMonths',
      'LegacyTimelinePosts',
      'HomeFeedItems',
    ],
  });

  const onPostUpdate = useCallback(
    async (data: LegacyFeedPostUpdateInput) => {
      if (!data.id) {
        throw new Error('Should sent a ID post to update');
      }

      const response = await postUpdate({
        variables: { postData: data, postId: data.id, postType: LEGACY_POST_TYPE },
        context: {
          [SNACKBAR_SUCCESS_MESSAGE]: `Your post is now available on the feed.`,
          [SNACKBAR_ERROR_MESSAGE]: `Something went wrong updating the post. Try again later.`,
        },
      });

      return response;
    },
    [postUpdate],
  );

  return {
    onPostUpdate,
    ...mutationData,
  };
};
