import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { SORT } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { PAGE_SIZE } from 'shared/constants';
import {
  InboxMessagesListQuery,
  InboxMessagesListQueryVariables,
  InboxMessagesCountQuery,
  InboxMessagesCountQueryVariables,
  InboxMessageFragment,
  InboxMessageCreateInput,
} from 'shared/graphql';

import { createInboxMessageFilterOnLocation } from '../constants';
import { INBOX_MESSAGES_LIST_QUERY, INBOX_MESSAGES_COUNT_QUERY } from '../queries';

export const SORT_OPTION = {
  createdAt: SORT.desc,
};

type UseInboxMessagesListProps = {
  inboxId: string | null | undefined;
};

export function useInboxMessagesList({ inboxId }: UseInboxMessagesListProps) {
  const [items, setItems] = useState<InboxMessageFragment[]>([]);

  const { pathname } = useLocation();

  const onQueryCompleted = useCallback((data: InboxMessagesListQuery) => {
    setItems(data?.inbox?.messages?.items ?? []);
  }, []);

  const filterMessages = useMemo(() => {
    return createInboxMessageFilterOnLocation(pathname);
  }, [pathname]);

  const {
    data: listData,
    loading: listLoading,
    refetch: refetchList,
    fetchMore: rawFetchMore,
  } = useQuery<InboxMessagesListQuery, InboxMessagesListQueryVariables>(INBOX_MESSAGES_LIST_QUERY, {
    onCompleted: onQueryCompleted,
    skip: !inboxId,
    variables: {
      inboxId: inboxId as string,
      firstMessages: PAGE_SIZE,
      sortMessages: SORT_OPTION,
      filterMessages,
    },
  });

  const {
    data: countData,
    loading: countLoading,
    refetch: refetchCount,
  } = useQuery<InboxMessagesCountQuery, InboxMessagesCountQueryVariables>(
    INBOX_MESSAGES_COUNT_QUERY,
    {
      skip: !inboxId,
      variables: { sort: SORT_OPTION, inboxId: inboxId as string, filter: filterMessages },
    },
  );

  const users = listData?.inbox?.users?.items || [];
  const count = countData?.inbox?.messages?.count || 0;
  const subject = listData?.inbox?.subject || '';
  const hasMore = items.length < count;
  const inbox = listData?.inbox || undefined;

  const fetchMore = useCallback(
    async (variables: InboxMessagesListQueryVariables) => {
      const { data } = await rawFetchMore({ variables });

      onQueryCompleted(data);
    },
    [onQueryCompleted, rawFetchMore],
  );

  const refetch = useCallback(() => {
    const refetchListPromise = refetchList({ firstMessages: items.length || 1 });
    const refetchCountPromise = refetchCount();

    return Promise.all([refetchListPromise, refetchCountPromise]);
  }, [items, refetchCount, refetchList]);

  const attachOptimisticMessage = (values: InboxMessageCreateInput) => {
    setItems(prev => {
      const data: InboxMessageFragment = {
        text: values.text,
        author: values.author?.connect,
        createdAt: formatISO8601Date(),
      };

      return [data, ...prev];
    });
  };

  return {
    items,
    count,
    hasMore,
    users,
    subject,
    inbox,
    loading: (listLoading && !listData) || (countLoading && !countData),
    fetchMore,
    refetch,
    attachOptimisticMessage,
  };
}
