import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';

import { AsyncContent } from 'shared/components/ui';
import { useUserById } from 'shared/hooks';

import { MemberInformationSidebar } from '../components';
import { MemberInformationContent } from '../components/MemberInformationContent';
import { APP_URL } from '../../../shared/constants';

const contentCss = css`
  display: grid;
  grid-template-columns: 1fr 4fr;
  min-height: 85vh;
`;

export const MembersAccountInformationPage = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { loading } = useUserById({ id: userId });
  const location = useLocation();
  const isRootRoute = location.pathname === APP_URL.admin.members.memberRoot;

  return (
    <AsyncContent loading={loading}>
      <Paper css={contentCss} elevation={1} square={false} variant="elevation">
        <MemberInformationSidebar />
        <MemberInformationContent />
      </Paper>
      {isRootRoute && <Redirect to={APP_URL.admin.members.information} />}
    </AsyncContent>
  );
};
