export const organizationFilters = filter => ({
  OR: [
    {
      name: {
        contains: filter,
      },
    },
    {
      location: {
        some: {
          address: {
            some: {
              OR: [
                {
                  city: {
                    contains: filter,
                  },
                },
                {
                  state: {
                    contains: filter,
                  },
                },
              ],
            },
          },
        },
      },
    },
  ],
});
