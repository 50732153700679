import { SelectOption } from '@jebel/constants';
import { generateGraduatingYearsOptions } from '@jebel/utils';

import {
  FormSelectDataSource,
  FormSelectDataSourceOption,
  FormSelectDataSourceOptionText,
  FormSelectDataSourceDefinitionProps,
} from '../FormSelectDataSource';

type Props = FormSelectDataSourceDefinitionProps<SelectOption>;

// https://8base-dev.atlassian.net/browse/JEB-1371?focusedCommentId=42770
const years = generateGraduatingYearsOptions();

export function FormSelectGraduatingYear(props: Props) {
  const fetchOptions = async (search: string) => {
    return years.filter(option => option.label.includes(search));
  };

  const extractIdentifier = (option: SelectOption) => {
    return option.value as string;
  };

  const extractLabel = (option?: SelectOption) => {
    return option?.label ?? '(UNKNOWN)';
  };

  return (
    <FormSelectDataSource<SelectOption>
      {...props}
      extractIdentifier={extractIdentifier}
      extractLabel={extractLabel}
      fetchOptions={fetchOptions}
    >
      {(params, option) => (
        <FormSelectDataSourceOption {...params} key={option.value}>
          <FormSelectDataSourceOptionText>{option.label}</FormSelectDataSourceOptionText>
        </FormSelectDataSourceOption>
      )}
    </FormSelectDataSource>
  );
}
