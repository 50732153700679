import * as R from 'ramda';

/**
 * Omits keys deeply in object or array of objects
 * @param keys - keys to omit
 * @param obj - object or array of objects to omit from
 */
export const omitDeep = R.curry(function omitDeep(keys: string[], obj: any): any {
  const shouldOmit = Array.isArray(obj) || (typeof obj === 'object' && !R.isNil(obj));

  if (!shouldOmit) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(el => omitDeep(keys, el));
  }

  const result: Record<any, any> = R.omit(keys, obj);

  Object.entries(result).forEach(([key, value]) => {
    result[key] = omitDeep(keys, value);
  });

  return result;
});

export const transformFormDataForMutations = (data: Record<string, any>) => {
  const shouldTransform = R.is(Object, data);

  if (!shouldTransform) {
    return data;
  }

  const result = data;

  Object.entries(result).forEach(([key, value]) => {
    if (!R.is(Object, value)) {
      return;
    }

    result[key] = { update: transformFormDataForMutations(value) };
  });

  return result;
};

export function mergeDeepObjects<T extends Record<string, unknown>>(base: T, overrides: T): T {
  return R.mergeDeepLeft(base, overrides) as T;
}

/**
 * Checks if an object is empty or undefined.
 *
 * @param {Object | null | undefined} obj - The object to be checked.
 * @returns {boolean} True if the object is empty or undefined, false otherwise.
 */

export function isEmptyObject(obj: { [key: string]: any } | null | undefined): boolean {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0;
}
