import React from 'react';
import { css } from '@emotion/react';
import { Divider, Dialog, Box, makeStyles, DialogTitle, Theme } from '@material-ui/core';
import { DialogContent } from '@mui/material';

import { useThreadCreate } from 'features/collective/hooks/useThreadCreate';
import {
  Form,
  FormTextField,
  Icon,
  Typography,
  Button,
  ImageInput,
  ResultFile,
} from 'shared/components/ui';
import { composeValidators, maxTextSymbols, required } from 'shared/utils/form';
import { CollectiveIntelligenceThreadCreateInput } from 'shared/graphql';

const DROP_ZONE_HEIGHT = 150;
const MESSAGE_INPUT_MIN_HEIGHT = 250;
const UPLOAD_IMAGE_SIZE = 80;

const INITIAL_VALUES: { title: string; text: string } = {
  title: '',
  text: '',
};

const formCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(1.25)}px;
`;

const dropZoneContainer = theme => css`
  background-color: ${theme.palette.background.light};
  border: 1px dashed #bbbbbb;
  height: ${DROP_ZONE_HEIGHT}px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
`;

const dropZoneIconCss = theme => css`
  font-size: 48px;
  fill: ${theme.palette.primary.light};
`;

const dropZoneTextCss = theme => css`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

const formRowCss = theme => css`
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: ${theme.spacing(1.25)}px;
`;

const messageInputCss = theme => css`
  width: 100%;

  .MuiTextField-root {
    width: 100%;
  }

  & textarea {
    min-height: ${MESSAGE_INPUT_MIN_HEIGHT}px;
  }
`;

const footerButtonsCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: ${theme.spacing(2.25)}px;
  padding: ${theme.spacing(3)}px 0;
`;

const cancelButtonCss = (theme: any) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
`;

type FormData = {
  title?: string | null | undefined;
  text?: string | null | undefined;
  images?: ResultFile[] | null | undefined;
};

const MAX_TITLE_LENGTH = 500;

const useStyles = makeStyles<Theme>(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        margin: '24px 8px',
        borderRadius: 0,
        width: '100vw',
      },
    },
  },
}));

export const CollectiveIntelligenceModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const { onThreadCreate } = useThreadCreate();

  const onCreateOrganization = React.useCallback(
    async (formData: FormData) => {
      const trimmedText = (formData.text && formData.text.trim()) as string;
      const trimmedTitle = (formData.title && formData.title.trim()) as string;
      const data: CollectiveIntelligenceThreadCreateInput = {
        text: trimmedText,
        title: trimmedTitle,
      };

      if (formData?.images?.length !== 0) {
        data.images = { create: formData?.images?.map(image => ({ fileId: image.fileId })) };
      }

      if (trimmedText) {
        await onThreadCreate(data);
        onClose();
      }
    },
    [onClose, onThreadCreate],
  );

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Create New Thread</DialogTitle>
      <Box mb={2}>
        <Divider />
      </Box>
      <DialogContent sx={{ maxWidth: { xs: '100%', sm: '600px' } }}>
        <Box css={formRowCss}>
          <Form oldCss={formCss} initialValues={INITIAL_VALUES} onSubmit={onCreateOrganization}>
            {({ isSubmitting, setFieldValue }) => (
              <React.Fragment>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'POST TITLE (maximum 500 characters) *',
                    variant: 'outlined',
                  }}
                  fieldProps={{
                    name: 'title',
                    validate: composeValidators(required, value =>
                      maxTextSymbols(value, MAX_TITLE_LENGTH),
                    ),
                  }}
                />
                <Box css={messageInputCss}>
                  <FormTextField
                    inputProps={{
                      color: 'primary',
                      label: 'MESSAGE *',
                      variant: 'outlined',
                      multiline: true,
                    }}
                    fieldProps={{
                      name: 'text',
                      validate: composeValidators(required, value =>
                        maxTextSymbols(value, MAX_TITLE_LENGTH * 10),
                      ),
                    }}
                  />
                </Box>
                <ImageInput
                  name="images"
                  height={UPLOAD_IMAGE_SIZE}
                  width={UPLOAD_IMAGE_SIZE}
                  maxFiles={5}
                  onChange={image => {
                    setFieldValue(`images`, image);
                  }}
                >
                  <Box css={dropZoneContainer}>
                    <Icon css={dropZoneIconCss} name="FileUpload" variant="filled" />
                    <Typography css={dropZoneTextCss} variant="inherit">
                      Upload pictures or videos here
                    </Typography>
                  </Box>
                </ImageInput>
                <Box css={footerButtonsCss}>
                  <Button variant="text" css={cancelButtonCss} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit" loading={isSubmitting}>
                    Create thread
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
