import React from 'react';
import { DateTime } from 'luxon';
import * as R from 'ramda';

import { POST_STATUSES } from '@jebel/constants';

import { PAGE_SIZE } from 'shared/constants';
import { usePostsList } from 'shared/features/posts/hooks';
import { useSearchContext } from 'shared/features/search';
import {
  GroupPostsListQuery,
  GroupPostsCountQuery,
  GroupPostsListQueryVariables,
} from 'shared/graphql/__generated__';

import { GROUP_POSTS_LIST_QUERY, GROUP_POSTS_COUNT_QUERY } from '../queries';

const transformQueryData = (
  listsData: GroupPostsListQuery | undefined,
  countData: GroupPostsCountQuery | undefined,
) => {
  const pinnedPosts = listsData?.pinnedPostsList.items || [];
  const posts = listsData?.groupPostsList.items || [];
  const count = countData?.groupPostsList.count || 0;
  const hasMore = posts.length < count;

  return { items: R.uniqBy(item => item.id, [...pinnedPosts, ...posts]), count, hasMore };
};

const FIELD_NAME = 'groupPostsList';

/** @deprecated */
export const useGroupPostsQuery = (
  groupId?: string | null | undefined,
  customFilter?: { startDate: string; endDate: string },
) => {
  const { searchQuery, sortOption, dateFilter } = useSearchContext();

  const datesFilter = React.useMemo(() => {
    let filter: GroupPostsListQueryVariables['filter'] = {};

    if (dateFilter === 'All') {
      return filter;
    }

    filter = {
      AND: [],
    };

    if (dateFilter?.startDate) {
      filter.AND?.push({
        createdAt: {
          gte: DateTime.fromISO(dateFilter?.startDate).toISO(),
        },
      });
    }

    if (dateFilter?.endDate) {
      filter.AND?.push({
        createdAt: {
          lte: DateTime.fromISO(dateFilter?.endDate).toISO(),
        },
      });
    }

    return filter;
  }, [dateFilter]);

  const variables = React.useMemo(() => {
    const filter = !customFilter
      ? {
          _fullText: searchQuery || undefined,
          group: groupId && {
            id: {
              equals: groupId,
            },
          },
          status: {
            equals: POST_STATUSES.approved,
          },
          ...datesFilter,
        }
      : {
          AND: [
            { createdAt: { gte: customFilter?.startDate } },
            { createdAt: { lte: customFilter?.endDate } },
          ],
        };

    const pinnedFilter = !customFilter ? { ...filter, isPinned: { equals: true } } : {};

    const restOptions = {
      sort: sortOption,
      first: !customFilter ? PAGE_SIZE : PAGE_SIZE * 10,
    };

    return { filter, pinnedFilter, ...restOptions };
  }, [customFilter, datesFilter, groupId, searchQuery, sortOption]);

  const { listsData, countData, loading, ...rest } = usePostsList<
    GroupPostsListQuery,
    GroupPostsCountQuery
  >({
    listQuery: GROUP_POSTS_LIST_QUERY,
    countQuery: GROUP_POSTS_COUNT_QUERY,
    variables,
    itemsFieldName: FIELD_NAME,
  });

  const { items, hasMore } = React.useMemo(
    () => transformQueryData(listsData, countData),
    [countData, listsData],
  );

  return {
    items,
    hasMore,
    loading,
    count: countData?.groupPostsList?.count ?? 0,
    ...rest,
  };
};
