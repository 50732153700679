import React from 'react';
import { css } from '@emotion/react';
import { Typography, Box, Paper } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { RouteLayout } from 'shared/components/layouts';
import { RouterSwitchSymbol } from 'shared/components/symbols';
import { Route } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';

import { ContentCurationSidebar } from '../components/ContentCurationSidebar';
import { ContentCurationBlacklistPage } from './ContentCurationBlacklistPage';
import { ContentCurationFlaggedPostsPage } from './ContentCurationFlaggedPostsPage';

const contentCss = (theme: any) => css`
  display: grid;
  grid-template-columns: 1fr 4fr;
  box-shadow: 0 3px 20px 0 rgba(171, 171, 171, 0.25);
  margin-bottom: ${theme.spacing(2)};
`;

export const ContentCurationPage: React.FC = () => {
  return (
    <RouteLayout
      content={
        <SpreadsheetProvider defaultPageSize={5}>
          <Box display="flex" flexDirection="column" justifyContent="start">
            <Typography variant="subtitle2" color="primary">
              Content Curation
            </Typography>
            <Paper css={contentCss}>
              <ContentCurationSidebar />
              <RouterSwitchSymbol
                routes={
                  <React.Fragment>
                    <Route
                      path={[APP_URL.admin.contentCuration.flaggedPosts]}
                      title="Flagged Posts"
                      authAccess="any"
                      exact
                    >
                      <ContentCurationFlaggedPostsPage />
                    </Route>
                    <Route
                      path={[APP_URL.admin.contentCuration.blacklist]}
                      title="Blacklist"
                      authAccess="any"
                      exact
                    >
                      <ContentCurationBlacklistPage />
                    </Route>
                    <Redirect to={APP_URL.admin.contentCuration.flaggedPosts} />
                  </React.Fragment>
                }
              />
            </Paper>
          </Box>
        </SpreadsheetProvider>
      }
      withFullWidth
    />
  );
};
