import { gql } from '@apollo/client';

export const SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT = gql`
  fragment SchoolNewsPostsListItem on SchoolNewsPost {
    id
    createdAt
    updatedAt
    text
    isPinned
    postDate
    commentsAllowed
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
    flagReason
    flaggedAt
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    reportStatus
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
  }
`;

export const SCHOOL_NEWS_POST_CREATE_MUTATION = gql`
  mutation SchoolNewsPostCreate($data: SchoolNewsPostCreateInput!) {
    schoolNewsPostCreate(data: $data) {
      ...SchoolNewsPostsListItem
    }
  }

  ${SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT}
`;

export const SCHOOL_NEWS_POST_UPDATE_MUTATION = gql`
  mutation SchoolNewsPostUpdate($data: SchoolNewsPostUpdateInput!, $id: ID!) {
    schoolNewsPostUpdate(data: $data, filter: { id: $id }) {
      ...SchoolNewsPostsListItem
    }
  }

  ${SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT}
`;

export const SCHOOL_NEWS_UPDATE_POST_MUTATION = gql`
  mutation SCHOOL_NEWS_UPDATE_POST_MUTATION($data: JSON!, $id: ID!) {
    updatePost(postData: $data, postId: $id, postType: "schoolPost") {
      successful
    }
  }
`;

export const SCHOOL_NEWS_POSTS_LIST_QUERY = gql`
  query SchoolNewsPostsList(
    $first: Int
    $skip: Int
    $sort: [SchoolNewsPostSort!]
    $filter: SchoolNewsPostFilter
    $pinnedFilter: SchoolNewsPostFilter!
  ) {
    schoolNewsPostsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...SchoolNewsPostsListItem
      }
    }
    pinnedPostsList: schoolNewsPostsList(filter: $pinnedFilter, sort: $sort) {
      items {
        ...SchoolNewsPostsListItem
      }
    }
  }
  ${SCHOOL_NEWS_POSTS_LIST_ITEM_FRAGMENT}
`;

export const SCHOOL_NEWS_POSTS_COUNT_QUERY = gql`
  query SchoolNewsPostsCount($filter: SchoolNewsPostFilter) {
    schoolNewsPostsList(filter: $filter) {
      count
    }
  }
`;
