import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppAuth } from 'providers';
import { useMarkNotificationsAsReadMutation } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';
import { useToast } from 'shared/hooks';

import {
  useCurrentUserNotificationsCount,
  useCurrentUserNotificationsPaginated,
  useCurrentUserNotificationsSubscription,
} from '../../hooks';
import { NormalizedNotification, normalizeNotification } from '../../utils';

const NEW_NOTIFICATIONS_MESSAGE =
  "You have new notifications waiting for you! Don't miss out on important news or updates. Check your notifications now.";

/** Refresh the content every 40 seconds. */
const SUBSCRIPTION_INTERVAL = 1000 * 40;

function useNotificationProvider() {
  const [markAsRead] = useMarkNotificationsAsReadMutation({
    refetchQueries: ['CurrentUserNotifications', 'CurrentUserNotificationsCount'],
  });

  const { hasSession } = useAppAuth();
  const { showMessage } = useToast();
  const { push: navigate } = useHistory();

  const {
    data: response,
    loading,
    fetching,
    hasMore,
    refetch: refetchList,
    fetchMore,
  } = useCurrentUserNotificationsPaginated({
    skip: !hasSession,
    pollInterval: SUBSCRIPTION_INTERVAL,
    variables: { first: 5 },
  });

  const { unread: unreadCount, refetch: refetchCounts } = useCurrentUserNotificationsCount({
    skip: !hasSession,
    pollInterval: SUBSCRIPTION_INTERVAL,
  });

  const notifications = useMemo(() => {
    return response.map(normalizeNotification);
  }, [response]);

  useCurrentUserNotificationsSubscription(async response => {
    const notification = normalizeNotification(response);

    await Promise.allSettled([refetchList(), refetchCounts()]);

    showMessage(notification.title, { onClick: notification.onClick });
  });

  const navigateToNotification = (notification: NormalizedNotification) => {
    const route = notification.redirectUrl;

    if (!route) {
      return;
    }

    if (route.startsWith('/')) {
      navigate(route);
      return;
    }

    window.location.assign(route);
  };

  const markAllNotificationsAsRead = async () => {
    try {
      await markAsRead({
        variables: { filter: { receiver: { is_self: true } } },
      });
    } catch (err) {
      sendToSentry(err);
    }
  };

  const markNotificationsAsRead = async (
    filter: (notification: NormalizedNotification) => boolean,
  ) => {
    const selected = notifications
      .filter(filter)
      .filter(notification => !notification.haveBeenRead)
      .map(notification => notification.id);

    if (selected.length === 0) {
      return;
    }

    try {
      await markAsRead({
        variables: {
          filter: {
            receiver: { is_self: true },
            id: { in: selected },
          },
        },
      });
    } catch (err) {
      sendToSentry(err);
    }
  };

  useEffect(() => {
    if (unreadCount > 0) {
      showMessage(NEW_NOTIFICATIONS_MESSAGE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    notifications,
    unreadCount,
    /** @deprecated Use `unreadCount` instead. */
    unreadNotificationsCount: unreadCount,
    hasMore,
    fetching,
    loading,
    /** @deprecated Use `loading` instead. */
    isLoading: loading,
    fetchMoreNotifications: fetchMore,
    markNotificationsAsRead,
    markAllNotificationsAsRead,
    navigateToNotification,
  };
}

export const NotificationsContext = createContext<ReturnType<typeof useNotificationProvider>>(
  null as any,
);

export function NotificationsProvider(props: PropsWithChildren<unknown>) {
  const values = useNotificationProvider();

  return (
    <NotificationsContext.Provider value={values}>{props.children}</NotificationsContext.Provider>
  );
}
