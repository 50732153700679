import { SORT } from '@jebel/constants';

import { HighSchoolFragment, useHighSchoolsLazyQuery } from 'shared/graphql';

import {
  FormSelectDataSource,
  FormSelectDataSourceOption,
  FormSelectDataSourceOptionText,
  FormSelectDataSourceDefinitionProps,
  FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT,
} from '../FormSelectDataSource';

type Props = FormSelectDataSourceDefinitionProps<HighSchoolFragment>;

export function FormSelectSchool(props: Props) {
  const [fetchSchools] = useHighSchoolsLazyQuery();

  const fetchOptions = async (search: string) => {
    const isSuggestions = search.length === 0;

    const response = await fetchSchools({
      variables: {
        first: isSuggestions ? FORM_SELECT_DATASOURCE_SUGGESTIONS_COUNT : undefined,
        sort: [{ name: SORT.asc }],
        filter: {
          OR: [
            { name: { contains: search } },
            { state: { contains: search } },
            { city: { contains: search } },
            { zip: { contains: search } },
          ],
        },
      },
    });

    return response.data?.schools.items ?? [];
  };

  const extractIdentifier = (option: HighSchoolFragment) => {
    return option.id as string;
  };

  const extractLabel = (option?: HighSchoolFragment) => {
    return option?.name ?? '(Unknown)';
  };

  return (
    <FormSelectDataSource<HighSchoolFragment>
      {...props}
      extractIdentifier={extractIdentifier}
      extractLabel={extractLabel}
      fetchOptions={fetchOptions}
    >
      {(params, option) => (
        <FormSelectDataSourceOption {...params} key={option?.id}>
          <FormSelectDataSourceOptionText>{option.name}</FormSelectDataSourceOptionText>

          <FormSelectDataSourceOptionText isSmall>
            {option.state}, {option.city}, {option.zip}
          </FormSelectDataSourceOptionText>
        </FormSelectDataSourceOption>
      )}
    </FormSelectDataSource>
  );
}
