import { OperationVariables } from '@apollo/client';

import { WriteCSVOptions, downloadCSV, writeCSV } from '@jebel/utils';

import { sendToSentry } from 'shared/utils/sentry';

import {
  UseDownloadLazyQueryDocument,
  UseDownloadLazyQueryOptions,
  useDownloadLazyQuery,
} from './useDownloadLazyQuery';

export type UseDownloadLazyQueryCSVOptions<
  D,
  V extends OperationVariables,
  T,
> = UseDownloadLazyQueryOptions<D, V, T> & WriteCSVOptions<T>;

const FALLBACK_FILENAME = 'jebel.csv';

/** Allow to download a `LazyQuery` to CSV format. */
export function useDownloadLazyQueryCSV<D = any, V extends OperationVariables = any, T = unknown>(
  query: UseDownloadLazyQueryDocument<D, V>,
  options?: UseDownloadLazyQueryCSVOptions<D, V, T>,
) {
  const [generate, params] = useDownloadLazyQuery(query, options);

  const execute = async () => {
    try {
      const response = await generate();
      const csv = writeCSV(response as T[], options);

      downloadCSV(csv, options?.filename ?? FALLBACK_FILENAME);
    } catch (err) {
      sendToSentry(err);
    }
  };

  return [execute, params] as const;
}
