import { Formik } from 'formik';

import { EventZipFilterType } from 'features/events/types';
import { Button } from 'shared/components/ui';

import { EventsFilterForm } from '../EventsFilterForm';
import { Container } from './EventFilterSidebar.styles';

interface Props {
  initial: EventZipFilterType;

  onChange(filter: EventZipFilterType): void;
}

export function EventFilterSidebar(props: Props) {
  const handleSubmit = (filters: EventZipFilterType) => {
    props.onChange(filters);
  };

  return (
    <Formik initialValues={props.initial} onSubmit={handleSubmit}>
      {form => (
        <Container>
          <EventsFilterForm />

          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            onClick={form.submitForm}
          >
            APPLY
          </Button>
        </Container>
      )}
    </Formik>
  );
}
