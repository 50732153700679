import React from 'react';
import { css } from '@emotion/react';

import { Box } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';

import { ContentCurationSidebarList } from './ContentCurationSidebarList';

const containerCss = (theme: any) => css`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${theme.palette.border};
`;

const MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Flagged Posts',
    icon: 'Flag',
    route: APP_URL.admin.contentCuration.flaggedPosts,
  },
  {
    title: 'Words and phrases blacklist',
    icon: 'Spellcheck',
    route: APP_URL.admin.contentCuration.blacklist,
  },
];

export const ContentCurationSidebar = () => {
  return (
    <Box css={containerCss}>
      <ContentCurationSidebarList listData={MAIN_SIDEBAR_LIST_DATA} />
    </Box>
  );
};
