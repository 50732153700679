import { Formik } from 'formik';

import { EventZipFilterType } from 'features/events/types';
import { Button } from 'shared/components/ui';

import { EventsFilterForm } from '../EventsFilterForm';
import { Buttons, Container } from './EventFilterModal.styles';

interface Props {
  initial: EventZipFilterType;

  onClose(): void;
  onChange(filter: EventZipFilterType): void;
}

export function EventFilterModal(props: Props) {
  const handleClean = () => {
    props.onChange({ zip: '', distance: '-' });
    props.onClose();
  };

  const handleSubmit = (filters: EventZipFilterType) => {
    props.onChange(filters);
    props.onClose();
  };

  return (
    <Formik initialValues={props.initial} onSubmit={handleSubmit}>
      {form => (
        <Container>
          <EventsFilterForm />

          <Buttons>
            <Button disabled={!form.dirty} onClick={handleClean} size="medium">
              CLEAR
            </Button>

            <Button onClick={props.onClose} size="medium">
              CANCEL
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              onClick={form.submitForm}
            >
              APPLY
            </Button>
          </Buttons>
        </Container>
      )}
    </Formik>
  );
}
