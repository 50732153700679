import { DateTime } from 'luxon';
import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_TABLE_FORMAT, REGEX_USA_FORMAT, } from '@jebel/constants';
import { isFn, isProp, isStr } from '../types';
export const SECONDS_TO_MILLISECONDS = 1000;
export const MILLISECONDS_TO_SECONDS = 0.001;
/**
 * Parse date from `string`, `number` or `Date` to luxon's {@link DateTime}.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 */
export function parseDate(value, options) {
    if (value instanceof DateTime) {
        return value;
    }
    if (isProp(value, 'toISO') && isFn(value.toISO)) {
        // Allow to parse other versions of luxon by formating into ISO.
        return parseDate(value.toISO(), options);
    }
    if (typeof value === 'number') {
        // Always use milliseconds
        // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=46027
        return DateTime.fromMillis(value, options);
    }
    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }
    if (isStr(value) && REGEX_USA_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_USA_FORMAT, options);
    }
    if (isStr(value) && REGEX_SQL_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_SQL_FORMAT, options);
    }
    if (isStr(value) && REGEX_TABLE_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_TABLE_FORMAT, options);
    }
    if (isStr(value)) {
        return DateTime.fromISO(value, options);
    }
    return DateTime.invalid('(NONE_DATETIME_FORMAT_MATCH)');
}
export const PLATFORM_LOCAL_TIMEZONE = 'America/New_York';
/** Gives the current date-time as {@link DateTime} instance from `luxon`. */
export function currentDateTime(timezone) {
    return DateTime.local({ zone: timezone });
}
