import { ReactNode } from 'react';
import { AccountCircle, Forum } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { Buttons } from './ExplorerUserCard.styles';

interface Props {
  withMessage: boolean;
  withViewProfile: boolean;
  /** Allow to extend the current button options. */
  addons?: ReactNode;

  onOpenProfile(): void;
  onOpenMessage(): void;
}

export function ExplorerUserCardButtons(props: Props) {
  return (
    <Buttons>
      {props.withMessage && <MessageButton onClick={props.onOpenMessage} />}
      {props.withViewProfile && <ViewProfileButton onClick={props.onOpenProfile} />}

      {props.addons}
    </Buttons>
  );
}

function MessageButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Forum fontSize="small" />}
      onClick={props.onClick}
    >
      Message
    </Button>
  );
}

function ViewProfileButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<AccountCircle fontSize="small" />}
      onClick={props.onClick}
    >
      View Profile
    </Button>
  );
}
