import { css } from '@emotion/react';

import { INDUSTRIES, ORGANIZATION_STATUS_FILTER, US_STATES } from '@jebel/constants';

import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import {
  OrganizationFilter,
  OrganizationLocationFilter,
  OrganizationLocationSort,
  SortOrder,
} from 'shared/graphql';

export enum BusinessReportHeaders {
  name = 'Name',
  locationName = 'Location Name',
  location = 'Location',
  industry = 'Industry',
  createdBy = 'Created By',
  createdOn = 'Created On',
  supporter = 'Supporter',
  status = 'Status',
}
export const BusinessSpreadsheetHeadlines: HeadlinesType<OrganizationLocationSort> = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: order => ({
      organization: {
        name: order,
      },
    }),
    css: css`
      min-width: 15rem;
    `,
  },
  {
    name: 'locationName',
    label: 'LOCATION NAME',
    id: 'locationName',
    sortPath: order => ({ locationName: order }),
    css: () =>
      css`
        width: 14.81%;
      `,
  },
  {
    name: 'location',
    label: 'LOCATION',
    id: 'location',
    css: () =>
      css`
        width: 14.81%;
      `,
  },
  {
    name: 'industry',
    label: 'INDUSTRY',
    id: 'industry',
    sortPath: (order: SortOrder) => ({ organization: { industry: order } }),
    css: () =>
      css`
        width: 14.32%;
      `,
  },
  {
    name: 'createdBy',
    label: 'CREATED BY',
    id: 'createdBy',
    sortPath: (order: SortOrder) => ({ organization: { createdBy: { fullName: order } } }),
    css: () =>
      css`
        width: 13.95%;
      `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => ({ createdAt: order }),
    css: () =>
      css`
        width: 12.35%;
      `,
  },
  {
    name: 'supporter',
    label: 'SUPPORTER',
    id: 'supporter',
    css: () =>
      css`
        width: 8.95%;
      `,
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => ({ organization: { status: order } }),
    css: () =>
      css`
        width: 12.41%;
      `,
  },
];

export const BusinessSpreadsheetFilters: SpreadsheetFiltersType<OrganizationLocationFilter> = [
  {
    type: 'select',
    variants: Object.keys(ORGANIZATION_STATUS_FILTER)?.map(value => ({
      text: ORGANIZATION_STATUS_FILTER[value],
      value,
    })),
    label: 'Status',
    name: 'status',
    placeholder: 'Business status',
    filterPath: (value: string) => {
      if (value === '') return {};
      return { organization: { status: { equals: value } } };
    },
  },
  {
    type: 'select',
    variants: [
      { value: true, text: 'Yes' },
      { value: false, text: 'No' },
    ],
    label: 'Supporter?',
    name: 'isSupporter',
    placeholder: 'Supporter?',
    filterPath: value => {
      if (typeof value !== 'boolean') {
        return {};
      }

      if (value === true) {
        return {
          organization: {
            schoolSupporter: {
              some: { id: { is_not_empty: true } },
            },
          },
        };
      }

      return {
        organization: {
          schoolSupporter: {
            none: null,
          },
        },
      };
    },
  },
  {
    type: 'input',
    name: 'city',
    label: 'City',

    filterPath: value => {
      return {
        address: {
          some: {
            city: { contains: value },
          },
        },
      };
    },
  },
  {
    type: 'select',
    variants: US_STATES.map(({ name, abbreviation }) => ({ value: abbreviation, label: name })),
    label: 'State',
    name: 'state',
    placeholder: 'State',
    filterPath: (value: string) => {
      if (!value) {
        return {};
      }

      return {
        address: { some: { state: { contains: value } } },
      };
    },
  },
  {
    type: 'select',
    label: 'Industry',
    name: 'industry',
    placeholder: 'Industry',
    size: 'full',

    variants: Object.values(INDUSTRIES).map(([value, label]) => ({ value, label })),

    filterPath: (value: string) => {
      if (!value) {
        return {};
      }

      return {
        organization: {
          industry: { contains: value },
        },
      };
    },
  },
  {
    type: 'input',
    name: 'createdBy',
    label: 'Created by',
    size: 'full',

    filterPath(value) {
      if (!value) {
        return {};
      }

      return {
        OR: [
          { createdBy: { fullName: { contains: value } } },
          { createdBy: { email: { contains: value } } },
        ],
      };
    },
  },
];

export const ManagersSpeadsheetHeader: HeadlinesType = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: (order: SortOrder) => ({ firstName: order }),
  },
  {
    name: 'email',
    label: 'EMAIL',
    id: 'email',
    sortPath: (order: SortOrder) => ({ email: order }),
  },
  {
    name: 'managerStatus',
    label: 'MANAGER STATUS',
    id: 'managerStatus',
    sortPath: (order: SortOrder) => ({ status: order }),
  },
  {
    name: 'userStatus',
    label: 'USER STATUS',
    id: 'userStatus',
  },
];

export const SORT_TYPES = {
  noSort: 'noSort',
  location: 'location',
};
