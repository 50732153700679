import { useCurrentUser } from 'shared/hooks';
import { SchoolSupporterKeyFilter, useSchoolSupporterSearchCreateMutation } from 'shared/graphql';
import { recordError } from 'shared/utils/record';

export function useCreateSchoolSearchMutation() {
  const { user } = useCurrentUser();

  const [createSearchImpression] = useSchoolSupporterSearchCreateMutation();

  return async (supporter: SchoolSupporterKeyFilter) => {
    if (!user?.id || !supporter?.id) {
      recordError(`No either "user" or "supporter" ID were found to create a business search.`);
      return;
    }

    await createSearchImpression({
      variables: {
        userId: user.id,
        supporterId: supporter.id,
      },
    });
  };
}
