import React from 'react';
import { css } from '@emotion/react';

import { Box, Typography } from 'shared/components/ui';

const titleCss = (theme: any) => css`
  padding-left: ${theme.spacing(6)}px;
  padding-top: ${theme.spacing(2)}px;
  padding-bottom: ${theme.spacing(2)}px;
  border-bottom: 1px solid ${theme.palette.border};
`;

export const SettingsTitle: React.FC = ({ children }) => {
  return (
    <Box css={titleCss}>
      <Typography variant="subtitle4" align="inherit" paragraph={false}>
        {children}
      </Typography>
    </Box>
  );
};
