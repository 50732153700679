import React from 'react';
import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { AsyncContent, Icon, Typography } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import { InboxFragment } from 'shared/graphql/__generated__';
import { CenterLayout } from 'shared/components/layouts';

import { useInboxChatsList } from '../../hooks/useInboxChatsList';
import { InboxChatsListItem } from './InboxChatsListItem';

const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
`;

const chatsContainerCSS = (theme: Theme) => css``;

export const InboxChatsList = ({ currentRoute }: { currentRoute: string }) => {
  const { loading, items, setInboxMessages } = useInboxChatsList();

  const renderItem = React.useCallback((data: InboxFragment) => {
    const inboxId = data.id;

    if (!inboxId) {
      return null;
    }

    const onClick = () => {
      setInboxMessages(inboxId);
    };

    return <InboxChatsListItem key={inboxId} onClick={onClick} {...data} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={containerCSS}>
      <SearchControl
        textFieldProps={{ size: 'small', style: { padding: 24 }, placeholder: 'Search' }}
      />

      {!items.length && !loading ? (
        <CenterLayout>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Icon name="SpeakerNotesOff" />
            <Typography variant="subtitle5" align="center">
              You don&apos;t have {currentRoute.toLowerCase()} messages
            </Typography>
          </Box>
        </CenterLayout>
      ) : (
        <AsyncContent loading={loading}>
          <div css={chatsContainerCSS}>{items.map(renderItem)}</div>
        </AsyncContent>
      )}
    </div>
  );
};
