import { gql } from '@apollo/client';

export const POSTS_FLAGGED_BY_USER_LIST_QUERY = gql`
  query PostsFlaggedByUserList(
    $homePostsFilter: HomeFeedPostFilter!
    $groupPostsFilter: GroupPostFilter!
    $homePosts: Boolean!
    $groupPosts: Boolean!
  ) {
    homeFeedPostsList(filter: $homePostsFilter) @include(if: $homePosts) {
      items {
        id
      }
    }
    groupPostsList(filter: $groupPostsFilter) @include(if: $groupPosts) {
      items {
        id
      }
    }
  }
`;

export const POST_DELETE_MUTATION = gql`
  mutation PostDelete(
    $id: ID!
    $isHomeFeedPost: Boolean!
    $isSchoolNewsPost: Boolean!
    $isLegacyFeedPost: Boolean!
    $isGroupPost: Boolean!
  ) {
    homeFeedPostDelete(data: { id: $id }) @include(if: $isHomeFeedPost) {
      success
    }
    legacyFeedPostDelete(data: { id: $id }) @include(if: $isLegacyFeedPost) {
      success
    }
    schoolNewsPostDelete(data: { id: $id }) @include(if: $isSchoolNewsPost) {
      success
    }
    groupPostDelete(data: { id: $id }) @include(if: $isGroupPost) {
      success
    }
  }
`;

export const FLAG_POST_MUTATION = gql`
  mutation FlagPost($data: FlagPostRequestInput!) {
    flagPostRequest(data: $data) {
      success
    }
  }
`;
