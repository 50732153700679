import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import {
  OrganizationAvatar,
  OrganizationNameLink,
  SchoolAvatar,
  SchoolNameLink,
} from 'shared/components/symbols';

import { HomeFeedCardLayout } from '../HomeFeedCardLayout';

// Container

export const Container = styled(HomeFeedCardLayout)``;

// Header

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

// AuthorContainer

export const AuthorContainer = styled.div`
  --author-avatar-size: 2.5rem;

  display: flex;
  gap: 0.5rem;
  flex: 1;
`;

// AuthorContent

export const AuthorContent = styled.div`
  min-height: var(--author-avatar-size);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const authorAvatarCSS = css`
  min-width: var(--author-avatar-size);
  min-height: var(--author-avatar-size);
`;

export const AuthorOrganizationAvatar = styled(OrganizationAvatar)`
  ${authorAvatarCSS};
`;

export const AuthorSchoolAvatar = styled(SchoolAvatar)`
  ${authorAvatarCSS};
`;

const authorNameCSS = (p: { theme: Theme }) => css`
  font-weight: bold;
  color: ${p.theme.palette.primary.main};
`;

export const AuthorOrganizationName = styled(OrganizationNameLink)`
  ${authorNameCSS};
`;

export const AuthorSchoolName = styled(SchoolNameLink)`
  ${authorNameCSS};
`;

// PostDate

export const PostDate = styled.span`
  color: ${p => p.theme.palette.grey[500]};
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Title

export const Title = styled.span`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
`;

// Label

export const Label = styled.span``;

// Buttons

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;
