import { ROUTES } from 'shared/constants';
import { AdminRoute, GetRoutesFn } from 'shared/routes';

import { SettingsPage } from './pages/SettingsPage';

export const getAdminSettingsRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: ROUTES.admin.settings.index,
    component: SettingsPage,
  },
];
