import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniqBy } from 'ramda';

import { EVENT_STATUSES } from '@jebel/constants';

import { PAGE_SIZE } from 'shared/constants';
import { useSearchContext } from 'shared/features/search';
import { GroupAdminPreferencesListQuery, GroupsQuery, GroupsQueryVariables } from 'shared/graphql';
import { useCurrentUser } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { GROUPS_QUERY } from '../queries';
import { GET_GROUP_ADMINS, GET_GROUP_MEMBERS_REQUEST } from '../components/Groups/mutations';

const transformQueryData = (data: {
  listData: GroupsQuery | undefined;
  userId: string;
  joinRequestsData?: { groupsMembersRequestsList: { items: { id: string }[]; count: number } };
  groupAdminsData?: GroupAdminPreferencesListQuery;
}) => {
  const { listData, userId, joinRequestsData, groupAdminsData } = data;

  const items = listData?.groupsList.items || [];

  const groupsMembersRequestsList: { id: string }[] =
    joinRequestsData?.groupsMembersRequestsList?.items || [];

  const itemsMemberExists = items.map(item => {
    const belongs = !!groupsMembersRequestsList.find(memberItem => memberItem.id === item.id);
    return { ...item, belongs };
  });

  const dataPlusUserAdminData = itemsMemberExists.map(item => {
    const isAdmin = !!groupAdminsData?.groupAdminPreferencesList.items.find(
      adminItem => adminItem.id === userId,
    );

    return { ...item, isAdmin };
  });

  return dataPlusUserAdminData;
};

export const useGroupsList = () => {
  const [refreshing, setRefreshing] = useState(false);

  const { searchQuery, sortOption } = useSearchContext();
  const { userId } = useCurrentUser();

  const groupFilter = useMemo(
    () => ({
      status: { equals: EVENT_STATUSES.active },
      isApproved: { equals: true },
      OR: searchQuery
        ? [{ title: { contains: searchQuery } }, { description: { contains: searchQuery } }]
        : null,
    }),
    [searchQuery],
  );

  const {
    data: response,
    loading,
    refetch,
    fetchMore: next,
  } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      filter: groupFilter,
      sort: [sortOption],
    },
  });

  const { data: joinRequestsData } = useQuery<
    { groupsMembersRequestsList: { items: { id: string }[]; count: number } },
    { userId: string }
  >(GET_GROUP_MEMBERS_REQUEST, {
    variables: {
      userId: userId as string,
    },
  });

  const groupIds = useMemo(() => {
    const groups = response?.groupsList?.items ?? [];
    return groups.map(group => group.id as string).filter(Boolean);
  }, [response]);

  const { data: groupAdminsData } = useQuery<
    GroupAdminPreferencesListQuery,
    { groupIds: string[] }
  >(GET_GROUP_ADMINS, {
    variables: { groupIds },
  });

  const data = useMemo(() => {
    return transformQueryData({
      listData: response,
      joinRequestsData,
      groupAdminsData,
      userId: userId as string,
    });
  }, [response, joinRequestsData, groupAdminsData, userId]);

  const count = response?.groupsList.count ?? 0;

  const fetchMore = async () => {
    setRefreshing(true);

    try {
      await next({
        variables: {
          skip: data.length,
        },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const oldest = prev?.groupsList.items ?? [];
          const newest = curr?.groupsList?.items ?? [];

          const count = curr?.groupsList.count ?? 0;
          /** Merge between `oldest` items and `newest` reduced by ID. */
          const items = uniqBy(group => group.id, [...oldest, ...newest]);

          const data: GroupsQuery = {
            ...curr,

            groupsList: {
              ...prev.groupsList,

              count,
              items,
            },
          };

          return data;
        },
      });
    } catch (err) {
      recordError(err);
    }

    setRefreshing(false);
  };

  return {
    data,
    count,

    loading,
    refreshing,
    hasMore: count > data.length,

    refetch,
    fetchMore,
  };
};
