import { uniqBy } from 'ramda';
import { useState } from 'react';

import {
  HomeFeedItemsQuery,
  useHomeFeedItemsLazyQuery,
  useHomeFeedItemsQuery,
} from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

export type UseHomeFeedItemsOptions = Parameters<typeof useHomeFeedItemsQuery>[0];
export type UseHomeFeedItemsLazyOptions = Parameters<typeof useHomeFeedItemsLazyQuery>[0];

export function useHomeFeedItems(options?: UseHomeFeedItemsOptions) {
  const [refreshing, setRefreshing] = useState(false);

  const {
    data: response,
    loading,
    networkStatus,
    refetch,
    fetchMore: next,
    startPolling,
    stopPolling,
  } = useHomeFeedItemsQuery(options);

  const data = response?.feed.items ?? [];
  const count = response?.feed.count ?? 0;

  const fetchMore = async () => {
    setRefreshing(true);

    try {
      await next({
        variables: {
          skip: data.length,
        },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const oldest = prev?.feed.items ?? [];
          const newest = curr?.feed?.items ?? [];

          const count = curr?.feed.count ?? 0;
          /** Merge between `oldest` items and `newest` reduced by ID. */
          const items = uniqBy(group => group.id, [...oldest, ...newest]);

          const data: HomeFeedItemsQuery = {
            ...curr,

            feed: {
              ...prev.feed,

              count,
              items,
            },
          };

          return data;
        },
      });
    } catch (err) {
      sendToSentry(err);
    } finally {
      setRefreshing(false);
    }
  };

  return {
    data,
    count,

    loading,
    refreshing,
    networkStatus,

    startPolling,
    stopPolling,
    refetch,
    fetchMore,
  };
}
