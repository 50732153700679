import { useMemo } from 'react';

import { File, Maybe } from 'shared/graphql';
import { formatShortDate } from 'shared/utils/date';

import { Aside, Container, Content, Image, LegacyDate, Text } from './LegacyTimelinePost.styles';

interface Props {
  content: Maybe<string> | undefined;
  media: Maybe<File[]> | undefined;
  legacyDate: Maybe<string> | undefined;
}

export function LegacyTimelinePost(props: Props) {
  const legacyDate = formatShortDate(props.legacyDate, { omitYear: false });

  const imageSource = useMemo(() => {
    const [first] = props.media ?? [];

    return first?.downloadUrl;
  }, [props.media]);

  return (
    <Container>
      <Aside>
        <Image src={imageSource as string} alt="Legacy post image" />
      </Aside>

      <Content>
        <Text text={props.content} />
        <LegacyDate>{legacyDate}</LegacyDate>
      </Content>
    </Container>
  );
}
