import { OrganizationItemFragment, useOrganizationsListQuery } from 'shared/graphql';

type Options = Parameters<typeof useOrganizationsListQuery>[0];

/** List all organizations based on the given `options`. */
export function useOrganizations(options?: Options) {
  const { data: response, loading, refetch, fetchMore } = useOrganizationsListQuery(options);

  const data: OrganizationItemFragment[] = response?.organizations.items ?? [];
  const count = response?.organizations.count ?? 0;

  return {
    data,
    count,
    loading,
    refetch,
    fetchMore,
  };
}
