import React, { Dispatch, Fragment, SetStateAction, useCallback, useRef, useState } from 'react';
import { Box } from '@mui/material';

import { formatMilliseconds } from '@jebel/utils';

import { OnCommentAdd } from 'shared/hooks/useStreamChatActivity';
import { CommentEnrichedReaction } from 'shared/types';
import { MentionPosition } from 'shared/features/mentions/types';

import { ViewMoreButton } from '../ViewMoreButton';
import { CommentContent, CommentContentProps } from './CommentContent';
import { CommentForm, CommentImage } from './CommentForm';

export type CommentProps = Pick<
  CommentContentProps,
  'comment' | 'onLikeAdd' | 'onReactionRemove' | 'onCommentRemove'
> & {
  onCommentAdd: OnCommentAdd;
  commentId?: string;
  setComments: Dispatch<SetStateAction<CommentEnrichedReaction[]>>;
  postId?: string;
  disableComments?: boolean;
};

const sortNestedComments = (comment: CommentEnrichedReaction) =>
  comment?.latest_children?.comment?.sort(
    (a, b) => formatMilliseconds(a.created_at) - formatMilliseconds(b.created_at),
  );

export function Comment({
  comment,
  onCommentAdd: rawOnCommentAdd,
  onCommentRemove,
  onLikeAdd,
  onReactionRemove,
  commentId = '',
  setComments,
  postId = '',
  disableComments = false,
}: CommentProps) {
  const [viewAllReplies, setViewAllReplies] = useState(false);
  const [isCommentSectionOpened, setIsCommentSectionOpened] = useState(false);
  const [nestedComments, setNestedComments] = useState(sortNestedComments(comment) || []);
  const fieldRef = useRef<any>();
  const formRef = useRef<any>();
  const onViewAllReplies = useCallback(() => setViewAllReplies(true), []);
  const [initialCommentFieldText, setInitialCommentFieldText] = useState('');

  const onChildComments = useCallback(
    (commentAuthorFullName: string | undefined) => {
      setInitialCommentFieldText('');
      setIsCommentSectionOpened(true);
      onViewAllReplies();

      if (fieldRef.current?.scrollIntoView) {
        setTimeout(() => fieldRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' }));
      }

      if (fieldRef.current?.focus) {
        fieldRef.current.focus();
      }

      const replyingMessage = `${commentAuthorFullName}, `;

      formRef.current?.setFieldValue?.('comment', replyingMessage);
      setInitialCommentFieldText(replyingMessage);
    },
    [onViewAllReplies],
  );

  const onCommentAdd = useCallback(
    async (text: string, media: CommentImage[], mentions: MentionPosition[] = []) => {
      const newComment = await rawOnCommentAdd({
        text,
        media,
        commentId: comment.id,
        parentEntityAuthorId: comment.user_id,
        parentEntityType: 'comment',
        mentions,
      });

      if (!newComment) {
        return;
      }

      setInitialCommentFieldText('');
      setNestedComments(prevState => [...prevState, newComment]);
      onViewAllReplies();
    },
    [rawOnCommentAdd, comment.id, comment.user_id, onViewAllReplies],
  );

  return (
    <Fragment>
      <CommentContent
        comment={comment}
        onCommentAdd={onChildComments}
        onCommentRemove={onCommentRemove}
        onLikeAdd={onLikeAdd}
        onReactionRemove={onReactionRemove}
        setComments={setComments}
        disableComments={disableComments}
      />

      <Box ml={5}>
        {!!nestedComments[0] && (
          <Box mt={1}>
            <CommentContent
              comment={nestedComments[0]}
              onCommentAdd={onChildComments}
              onLikeAdd={onLikeAdd}
              onReactionRemove={onReactionRemove}
              setComments={setNestedComments}
              onCommentRemove={onCommentRemove}
              disableComments={disableComments}
            />
          </Box>
        )}

        {viewAllReplies &&
          nestedComments.slice(1).map(childrenComment => (
            <Box key={childrenComment.id} mt={1}>
              <CommentContent
                comment={childrenComment}
                onCommentAdd={onChildComments}
                onLikeAdd={onLikeAdd}
                onReactionRemove={onReactionRemove}
                setComments={setNestedComments}
                onCommentRemove={onCommentRemove}
                disableComments={disableComments}
              />
            </Box>
          ))}

        {!viewAllReplies && nestedComments.length > 1 && (
          <ViewMoreButton
            onViewMore={onViewAllReplies}
            type="reply"
            amount={nestedComments.length - 1}
          />
        )}

        {isCommentSectionOpened && (
          <CommentForm
            formRef={formRef}
            initialValues={{ comment: initialCommentFieldText }}
            type="reply"
            withAutoFocus
            onCommentPost={onCommentAdd}
            fieldRef={fieldRef}
          />
        )}
      </Box>
    </Fragment>
  );
}
