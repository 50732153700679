import { useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';
import { object, array, string } from 'yup';

import { useFilesListQuery } from 'shared/graphql';
import { sendToSentry } from 'shared/utils/sentry';

import { MAIN_MEDIA_HEIGHT, MediaGallery } from './MediaGallery';

interface Props {
  media: unknown;
  height?: string | number;
}

/** @deprecated Use `HomeFeedCard` instead. */
export function MediaGalleryPreview({ media, height = MAIN_MEDIA_HEIGHT }: Props) {
  const ids = useMemo(() => {
    if (typeof media === 'string') {
      const schema = object({
        items: array(
          object({
            fileId: string().required(),
          }),
        )
          .nullable()
          .optional(),
      });

      try {
        const parsed = JSON.parse(media);
        const payload = schema.cast(parsed);
        const files = payload.items ?? [];

        return files.map(file => file.fileId as string);
      } catch (err) {
        sendToSentry(err);
      }
    }

    return [];
  }, [media]);

  const { data: response, loading } = useFilesListQuery({
    skip: ids.length === 0,
    variables: { ids },
  });

  const data = response?.filesList.items ?? [];

  if (data.length === 0) {
    return null;
  }

  if (loading) {
    return (
      <Box display="flex" flexDirection="row" flex={1} gap={1}>
        <Box flex={1}>
          <Skeleton variant="rectangular" height={200} />

          <Box display="flex" gap={1} mt={1}>
            <Skeleton variant="rectangular" width="33.33333%" height={75} />
            <Skeleton variant="rectangular" width="33.33333%" height={75} />
            <Skeleton variant="rectangular" width="33.33333%" height={75} />
          </Box>
        </Box>
      </Box>
    );
  }

  return <MediaGallery media={data} height={height} />;
}
