import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { SORT } from '@jebel/constants';

import { EventsCreateModal } from 'features/events/components/Events';
import { Typography, Button, Modal } from 'shared/components/ui';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';

import { EventsList } from './EventsList';
import { EventsStatistics } from './EventsStatistics';

const postButtonCss = theme => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(4)}px;
  background-color: ${theme.palette.primary.light};
`;

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export function EventsContent() {
  const [isEventCreateModalOpened, setIsEventCreateModalOpened] = React.useState(false);

  const onModalOpen = () => {
    setIsEventCreateModalOpened(true);
  };

  const onModalClose = () => {
    setIsEventCreateModalOpened(false);
  };

  return (
    <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
      <SearchProvider>
        <Modal
          dialogProps={{
            open: isEventCreateModalOpened,
            onClose: onModalClose,
            fullWidth: true,
          }}
          titleProps={{ title: 'Create New Event' }}
        >
          <EventsCreateModal onModalClose={onModalClose} />
        </Modal>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          css={css`
            min-width: 0;
          `}
        >
          <Box display="flex" justifyContent="space-between" alignItems="start" ml={3} my={2}>
            <Typography variant="subtitle2" color="primary">
              Events
            </Typography>
            <Button
              css={postButtonCss}
              onClick={onModalOpen}
              color="primary"
              variant="contained"
              size="small"
            >
              New event
            </Button>
          </Box>
          <EventsStatistics />
          <EventsList />
        </Box>
      </SearchProvider>
    </SpreadsheetProvider>
  );
}
