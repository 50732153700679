/* eslint-disable react/no-unused-prop-types */
import { PropsWithChildren, createContext } from 'react';

import { HomeFeedItemFragment } from 'shared/graphql';

export interface HomeFeedCardContextDefinition {
  data: HomeFeedItemFragment;
  isHaveBeenRender: boolean;
  isHaveBeenShown: boolean;
}

/** Provides the `data` through components instead of passing by properties. */
export const HomeFeedCardContext = createContext<HomeFeedCardContextDefinition | null>(null);

/** Provides the `data` through components instead of passing by properties. */
export function HomeFeedCardProvider(props: PropsWithChildren<HomeFeedCardContextDefinition>) {
  return (
    <HomeFeedCardContext.Provider value={props}>{props.children}</HomeFeedCardContext.Provider>
  );
}
