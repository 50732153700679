import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Avatar, Box, Divider, Paper, Typography } from '@mui/material';
import { Theme } from '@material-ui/core';

const isLastOf = (index: number, array: unknown[]) => index === array.length - 1;

type CountersType = {
  label: string;
  count: string | number;
};

type Props = {
  title: string;
  children: React.ReactNode;
  icon: React.ReactElement;
  color: string;
  counters: CountersType[];
};
export function DashboardCountStatsItem({ title, children, counters, icon, color }: Props) {
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography variant="body1" fontWeight="medium" lineHeight={1.5}>
          {title}
        </Typography>

        {children}
      </Box>

      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: isSmUp ? 'row' : 'column',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          gap: 1,
          p: 2,
          mt: 1,
          minHeight: '163px',
        }}
      >
        {counters.map(({ count, label }, index) => (
          <React.Fragment key={label}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: 'baseline',
                justifyItems: 'center',
                flex: '1 1 0',
                gap: 1,
              }}
            >
              <Avatar
                alt={label}
                sx={theme => ({
                  width: 50,
                  height: 50,
                  fontSize: theme.spacing(3),
                  backgroundColor: color,
                  color: theme.palette.getContrastText(color),
                })}
              >
                {React.cloneElement(icon, { fontSize: 'inherit' })}
              </Avatar>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" fontWeight="medium" fontSize="50px">
                  {count}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {label}
                </Typography>
              </Box>
            </Box>
            {!isLastOf(index, counters) && (
              <Divider
                variant="fullWidth"
                flexItem
                orientation={isSmUp ? 'vertical' : 'horizontal'}
              />
            )}
          </React.Fragment>
        ))}
      </Paper>
    </Box>
  );
}
