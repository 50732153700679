import React, { forwardRef } from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MaterialRadio,
  RadioProps as MaterialRadioProps,
} from '@material-ui/core';

import { ComponentDataProps } from '../../types';

export type RadioProps = (FormControlLabelProps & MaterialRadioProps & ComponentDataProps) | any;

export const Radio = forwardRef<HTMLDivElement, RadioProps>(
  (
    {
      disabled,
      label,
      labelPlacement = 'end',
      value,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...otherRadioProps
    },
    ref,
  ) => {
    return (
      <div ref={ref}>
        <FormControlLabel
          disabled={disabled}
          label={label}
          labelPlacement={labelPlacement}
          value={value}
          control={<MaterialRadio {...otherRadioProps} />}
          data-test={dataTest}
          data-node-id={dataNodeID}
          data-node-render-path={dataRenderPath}
        />
      </div>
    );
  },
);
