import { useMemo } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FieldValidator, useField, useFormik, useFormikContext } from 'formik';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { composeValidators, mustBePhone, required } from 'shared/utils/form';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';

export type FormPhoneFieldProps = ComponentDataProps & {
  /**
   * Set the prefix for the phone number.
   * @default "+1"
   */
  prefix?: string;

  fieldProps: {
    name: string;
    validate?: FieldValidator;
    required?: boolean;
  };

  inputProps?: NumberFormatProps<TextFieldProps>;

  /** @deprecated */
  formik?: ReturnType<typeof useFormik>;
};

export const PHONE_MASK_WILDCARD = '#';
export const PHONE_MASK = '###-###-####';
/** @deprecated Use `PHONE_MASK_WILDCARD` instead. */
export const MASK_CHAR = '#';
export const DEFAULT_PHONE_PREFIX = '+1';

export function FormPhoneFieldV2(props: FormPhoneFieldProps) {
  const form = useFormikContext();

  const validate = composeValidators(
    mustBePhone,
    props.fieldProps.required === true ? required : undefined,
    props.fieldProps.validate,
  );

  const [field, meta, helpers] = useField<string>({
    ...props.fieldProps,

    validate,
  });

  const isInvalid = getIsInvalid({ meta, form });
  const errorText = isInvalid && meta.error;
  const prefix = props.prefix ?? DEFAULT_PHONE_PREFIX;

  const value: string = useMemo(() => {
    if (!field.value || typeof field.value !== 'string') {
      return '';
    }

    const value = field.value.replace(`${prefix} `, '');
    return value;
  }, [field.value, prefix]);

  const onChange = (values: NumberFormatValues) => {
    const value = `${prefix} ${values.value}`;
    helpers.setValue(value);
  };

  return (
    <NumberFormat<TextFieldProps>
      label="Phone Number"
      sx={{
        ...props.inputProps?.sx,
        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#0000003b' },
      }}
      {...props.inputProps}
      helperText={errorText}
      error={isInvalid}
      name={props.fieldProps.name}
      value={value}
      customInput={TextField}
      placeholder="800-555-1212"
      type="tel"
      mask="#"
      onValueChange={onChange}
      format={PHONE_MASK}
    />
  );
}
