import React from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';

import { OrganizationDetailsContent } from '../components/OrganizationDetails';

export const OrganizationDetailsPage = () => {
  return (
    <RouteLayout
      withMaxWidth
      content={<OrganizationDetailsContent />}
      sidebar={<CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />}
    />
  );
};
