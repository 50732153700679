import { PropsWithChildren, useMemo, useState } from 'react';

import {
  SpreadsheetContextValue,
  SpreadsheetContext,
  DEFAULT_CONTEXT_VALUE,
} from 'shared/features/spreadsheet';

export type SpreadsheetContextProps = {
  defaultSortOption?: SpreadsheetContextValue['sortOption'];
  defaultPageSize?: SpreadsheetContextValue['pageSize'];
  defaultFilters?: SpreadsheetContextValue['filter'];
  defaultPage?: SpreadsheetContextValue['page'];
  defaultCurrentRowId?: SpreadsheetContextValue['currentRowId'];
  defaultSelected?: SpreadsheetContextValue['selected'];
  defaultCustomFilter?: SpreadsheetContextValue['filter'];
  defaultZip?: SpreadsheetContextValue['zip'];
  defaultChipsArray?: SpreadsheetContextValue['chipsArray'];
  defaultRangeValues?: SpreadsheetContextValue['rangeValues'];
};

export function SpreadsheetProvider({
  children,
  defaultSortOption = DEFAULT_CONTEXT_VALUE.sortOption,
  defaultPageSize = DEFAULT_CONTEXT_VALUE.pageSize,
  defaultFilters = DEFAULT_CONTEXT_VALUE.filter,
  defaultPage = DEFAULT_CONTEXT_VALUE.page,
  defaultCurrentRowId = DEFAULT_CONTEXT_VALUE.currentRowId,
  defaultSelected = DEFAULT_CONTEXT_VALUE.selected,
  defaultCustomFilter = DEFAULT_CONTEXT_VALUE.customFilter,
  defaultZip = DEFAULT_CONTEXT_VALUE.zip,
  defaultChipsArray = DEFAULT_CONTEXT_VALUE.chipsArray,
  defaultRangeValues = DEFAULT_CONTEXT_VALUE.rangeValues,
}: PropsWithChildren<SpreadsheetContextProps>) {
  const [sortOption, setSortOption] = useState(defaultSortOption);
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filter, setFilter] = useState(defaultFilters);
  const [currentRowId, setCurrentRowId] = useState(defaultCurrentRowId);
  const [selected, setSelected] = useState<string[]>(defaultSelected);
  const [customFilter, setCustomFilter] = useState(defaultCustomFilter);
  const [zip, setZip] = useState(defaultZip);
  const [rangeValues, setRangeValues] = useState<string>(defaultRangeValues);

  const [chipsArray, setChipsArray] =
    useState<SpreadsheetContextValue['chipsArray']>(defaultChipsArray);

  const queryParams = useMemo(() => {
    return {
      filter: filter.query,
      ...customFilter.query,
      sort: sortOption?.sort,
      first: pageSize,
      skip: pageSize * (page - 1),
    };
  }, [filter.query, customFilter, sortOption?.sort, pageSize, page]);

  const softReset = () => {
    setPage(defaultPage);
    setCurrentRowId(defaultCurrentRowId);
    setSelected(defaultSelected);
  };

  const changeSort: SpreadsheetContextValue['setSortOption'] = option => {
    softReset();
    setSortOption(option);
  };

  const changeFilter: SpreadsheetContextValue['setFilter'] = filter => {
    softReset();
    setFilter(filter);
  };

  const changePaginationSize: SpreadsheetContextValue['setPageSize'] = size => {
    softReset();
    setPageSize(size);
  };

  const changeCustomFilter: SpreadsheetContextValue['setCustomFilter'] = filter => {
    softReset();
    setCustomFilter(filter);
  };

  const reset: SpreadsheetContextValue['reset'] = () => {
    setSortOption(defaultSortOption);
    setPage(defaultPage);
    setFilter(defaultFilters);
    setCurrentRowId(defaultCurrentRowId);
    setSelected(defaultSelected);
    setCustomFilter(defaultCustomFilter);
    setZip(defaultZip);
    setRangeValues(defaultRangeValues);
  };

  return (
    <SpreadsheetContext.Provider
      value={{
        sortOption,
        setSortOption: changeSort,
        page,
        setPage,
        pageSize,
        setPageSize: changePaginationSize,
        filter,
        setFilter: changeFilter,
        queryParams,
        currentRowId,
        setCurrentRowId,
        selected,
        setSelected,
        customFilter,
        setCustomFilter: changeCustomFilter,
        zip,
        setZip,
        chipsArray,
        setChipsArray,
        rangeValues,
        setRangeValues,
        softReset,
        reset,
      }}
    >
      {children}
    </SpreadsheetContext.Provider>
  );
}
