import { Fragment, useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { Button, Typography } from 'shared/components/ui';
import { SpreadsheetStats } from 'shared/components/ui/SpreadsheetStats';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { AdminDiscountFragment } from 'shared/graphql';

import { useDiscountsStats } from '../hooks';
import { DiscountModal } from './DiscountModal';
import { DiscountsSpreadsheet } from './DiscountsSpreadsheet';

export const DiscountsContent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<AdminDiscountFragment>();

  const { discountStats, refetchDiscountsStats } = useDiscountsStats();

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedDiscount(undefined);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
    setSelectedDiscount(undefined);
  };

  const onClickDiscount = useCallback((discount?: AdminDiscountFragment) => {
    setSelectedDiscount(discount);
    setIsModalOpen(true);
  }, []);

  return (
    <Fragment>
      <SpreadsheetProvider>
        {isModalOpen && (
          <DiscountModal
            onModalClose={onModalClose}
            isModalOpen={isModalOpen}
            selectedDiscount={selectedDiscount}
            onDiscountsChange={refetchDiscountsStats}
          />
        )}

        <MembersContentLayout>
          <Header>
            <Typography variant="subtitle2" color="primary">
              Discounts
            </Typography>
            <Button size="medium" variant="contained" color="primary" onClick={onModalOpen}>
              new discount
            </Button>
          </Header>
          <SpreadsheetStats data={discountStats} />
          <DiscountsSpreadsheet
            onSelectDiscount={onClickDiscount}
            refetchDiscountsStats={refetchDiscountsStats}
          />
        </MembersContentLayout>
      </SpreadsheetProvider>
    </Fragment>
  );
};

const MembersContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 0px;
`;
