import styled from '@emotion/styled';
import { DatePicker as DatePickerBase } from '@material-ui/pickers';

// Container

export const Container = styled.div`
  --font-size: 0.8rem;

  display: flex;
  gap: 0.25rem;
  color: ${p => p.theme.palette.secondary.light};
  font-size: var(--font-size);
`;

// DatePicker

export const DatePicker = styled(DatePickerBase)`
  .MuiInput-root {
    color: inherit;

    .MuiInput-input {
      width: auto;
      height: auto;
      max-width: 5rem;
      word-wrap: break-word;
      cursor: pointer;
      text-align: center;
      text-overflow: ellipsis;
      font-size: var(--font-size);
      padding: 0;
    }
  }
`;
