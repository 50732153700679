import { Box, Chip } from '@mui/material';
import React from 'react';
import { css } from '@emotion/react';
import { ApolloQueryResult, useMutation, gql } from '@apollo/client';

import { Button, Form, FormTextField } from 'shared/components/ui';
import { useDataBaseSelectField } from 'shared/components/form/hooks';
import {
  GroupDetailsQuery,
  GroupsItemFragment,
  UserInviteMembersToGroupMutation,
  UserInviteMembersToGroupMutationVariables,
  User,
  UsersListQuery,
  USERS_LIST_QUERY,
  GroupMemberFragment,
} from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { FormSelectUser } from 'shared/components/form';
import { required } from 'shared/utils/form';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(1)}px;
`;

const cancelButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
`;

const chipCss = (theme: any) => css`
  background-color: rgba(9, 167, 250, 0.2);
  border-radius: 4px;
  color: ${theme.palette.primary.light};
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type FormData = {
  message?: string | undefined | null;
  members?: Array<User> | null | undefined;
};

const INVITE_MEMBERS_MUTATION = gql`
  mutation UserInviteMembersToGroup($data: UserInviteMembersToGroupInput!) {
    userInviteMembersToGroup(data: $data) {
      success
    }
  }
`;

export const GroupInviteModal = ({
  onModalClose,
  group,
  refetch,
}: {
  onModalClose: () => void;
  group: GroupsItemFragment;
  refetch: () => Promise<ApolloQueryResult<GroupDetailsQuery>>;
}) => {
  const [inviteMembersToGroup] = useMutation<
    UserInviteMembersToGroupMutation,
    UserInviteMembersToGroupMutationVariables
  >(INVITE_MEMBERS_MUTATION, {
    refetchQueries: ['GroupsList'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Group has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: USERS_LIST_QUERY,
      getQueryItems: 'usersList.items',
    },
    {
      getOptionLabel: (user: User) => `${user.firstName} ${user.lastName}`,
      renderTag: (user: User) => {
        const isExistMember = group?.members?.items.some(member => member.id === user.id);
        if (isExistMember) {
          return null;
        }

        return <Chip css={chipCss} label={`${user?.firstName} ${user?.lastName}`} />;
      },
    },
  );

  const filterMembers = (options: GroupMemberFragment[]) => {
    const rawMembers = group?.members?.items || [];

    // Get members in options that are not in rawMembers based on their ids
    return options.filter(member => !rawMembers.some(otherMember => member.id === otherMember.id));
  };

  const onSubmit = React.useCallback(
    async (formData: FormData) => {
      const rawMembers = group?.members?.items || [];
      const connectMembers =
        formData?.members
          ?.map(member => ({ id: member.id }))
          .filter(member => !rawMembers.some(rawMember => rawMember.id === member.id)) || [];

      const data = {
        id: group.id as string,
        members: { connect: connectMembers },
      };
      await inviteMembersToGroup({ variables: { data } });
      await refetch();
      onModalClose();
    },
    [group?.id, group?.members?.items, onModalClose, refetch, inviteMembersToGroup],
  );

  const initialValues = React.useMemo(
    () => ({
      members: [],
      message: '',
    }),
    [],
  );
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ isSubmitting, setFieldValue }) => (
        <Box display="grid" gridTemplateRows="auto" gap={1}>
          <Box
            display="flex"
            gap={1}
            flex={1}
            css={css`
              & > div {
                width: 100%;
              }
            `}
          >
            <FormSelectUser
              name="members"
              label="Invite members*"
              placeholder="Members"
              variant="outlined"
              validate={required}
              showSuggestions={false}
              allowUnknown="none"
              onlyActive
              multiple
              filterOptions={filterMembers}
            />
          </Box>
          <FormTextField
            inputProps={{
              color: 'primary',
              label: 'Message',
              multiline: true,
              rows: '7',
              rowsMax: '7',
              variant: 'outlined',
            }}
            fieldProps={{ name: 'description' }}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button
              css={cancelButtonCSS}
              onClick={onModalClose}
              disabled={isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>
            <Button
              loading={isSubmitting}
              css={submitButtonCSS}
              variant="contained"
              type="submit"
              color="primary"
            >
              Invite Members
            </Button>
          </Box>
        </Box>
      )}
    </Form>
  );
};
