import React from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { useSearchContext } from 'shared/features/search';
import { OrganizationQuery, OrganizationQueryVariables } from 'shared/graphql';

import { ORGANIZATION_QUERY } from '../queries';

export const useOrganizationById = (id: string) => {
  const { searchQuery, isUserTyping } = useSearchContext();

  const { data, loading, error, refetch } = useQuery<OrganizationQuery, OrganizationQueryVariables>(
    ORGANIZATION_QUERY,
    {
      variables: {
        id,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const managers = React.useMemo(() => {
    const managers = data?.organization?.managers?.items || [];
    if (R.isEmpty(searchQuery)) {
      return managers;
    }

    return managers.filter(manager =>
      `${manager?.user?.firstName}${manager?.user?.lastName}${manager?.user?.email}`
        .toLowerCase()
        .includes(searchQuery),
    );
  }, [data?.organization?.managers, searchQuery]);

  return {
    organization: data?.organization || null,
    loading: loading || !data || isUserTyping,
    error,
    managers,
    refetch,
  };
};
