import styled from '@emotion/styled';
import { FileUpload } from '@mui/icons-material';

import { Typography } from 'shared/components/ui';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// ImageUploaderContainer

export const ImageUploaderContainer = styled.div`
  cursor: pointer;
  background-color: #f6f8fa;
  border-style: dashed;
  border-width: 1px;
  border-color: #bbbbbb;
  height: 150px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;

  &:hover {
    background-color: #abbeff;
  }
`;

// ImageUploaderIcon

export const ImageUploaderIcon = styled(FileUpload)`
  fill: ${p => p.theme.palette.primary.light};
  font-size: 2rem;
`;

export const ImageUploaderText = styled(Typography)`
  color: ${p => p.theme.palette.primary.light};
`;

ImageUploaderText.defaultProps = {
  variant: 'subtitle4',
};
