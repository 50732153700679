import { FormControl, FormHelperText, Slider, SliderProps } from '@material-ui/core';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

import { getIsInvalid } from 'shared/components/ui';

export interface SliderFieldProps extends Omit<SliderProps, 'value' | 'onChange'> {
  helperText?: string;

  name: string;
  defaultValue?: number;
  validate?: FieldValidator;
}

export function SliderField(props: SliderFieldProps) {
  const form = useFormikContext();

  const [field, meta, helpers] = useField<number>({
    name: props.name,
    validate: props.validate,
  });

  const showError = useMemo(() => getIsInvalid({ meta, form }), [meta, form]);

  const message = useMemo(() => {
    return showError ? meta.error : props.helperText;
  }, [showError, meta.error, props.helperText]);

  const handleChange = (_event: unknown, value: number | number[]) => {
    helpers.setValue(value as number);
  };

  return (
    <FormControl>
      <Slider {...props} {...field} onChange={handleChange} />

      {Boolean(message) && <FormHelperText error={showError}>{message}</FormHelperText>}
    </FormControl>
  );
}
