import React, { Fragment } from 'react';
import { css, useTheme } from '@emotion/react';
import { Typography } from '@material-ui/core';

import { useGlobalState } from 'providers';
import { DialogSymbol } from 'shared/components/symbols';
import { Box, Form, FormTextField, Button } from 'shared/components/ui';

const container498CSS = css`
  padding-bottom: 15px;
  padding-top: 15px;
`;

const formBlock13CSS = css`
  display: grid;
  grid-row-gap: 20px;
`;

const container499CSS = css`
  max-width: 500px;
`;

const container500CSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  grid-column-gap: 20px;
`;

export const MarkPostModal: React.FC = () => {
  const theme: any = useTheme();
  const { markPost } = useGlobalState();

  return (
    <DialogSymbol
      dialogProps={{
        fullScreen: false,
        fullWidth: true,
        maxWidth: 'sm',
        onClose: _ => {
          markPost.close();
        },
        open: markPost.isOpened,
      }}
      titleProps={{ title: 'Flag Post as Inappropriate' }}
    >
      <Box css={container498CSS}>
        <Form
          oldCss={formBlock13CSS}
          onSubmit={async (data, actions) => {
            alert(JSON.stringify(data));
          }}
        >
          {formProps => (
            <Fragment>
              <FormTextField
                inputProps={{
                  color: 'primary',
                  label: 'Comments',
                  multiline: true,
                  rows: '7',
                  rowsMax: '7',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'textInput1' }}
              />
              <Box css={container499CSS}>
                <Typography
                  css={css`
                    color: ${theme.palette.secondary.light};
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                  `}
                  variant="inherit"
                  align="inherit"
                  color="initial"
                  paragraph={false}
                >
                  By flagging this post, it will be temporarily removed from the platform and
                  reviewed by an Administrator.
                </Typography>
              </Box>
              <Box css={container500CSS}>
                <Button
                  css={css`
                    letter-spacing: 1px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                    line-height: 17px;
                    color: ${theme.palette.text.secondary};
                  `}
                  onClick={event => {
                    markPost.close();
                  }}
                  color="primary"
                  disabled={false}
                  loading={false}
                  size="medium"
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  css={css`
                    letter-spacing: 1px;
                    background-color: ${theme.palette.secondary.light};
                    padding-top: 9px;
                    padding-bottom: 9px;
                    padding-left: 15px;
                    padding-right: 15px;
                    line-height: 17px;
                    font-size: 14px;
                  `}
                  onClick={event => {
                    markPost.close();
                  }}
                  color="primary"
                  disableElevation
                  variant="contained"
                  type="submit"
                >
                  Flag post
                </Button>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </DialogSymbol>
  );
};
