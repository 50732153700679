import { useMemo } from 'react';

import { OrganizationInfoFragment, OrganizationItemFragment } from 'shared/graphql';
import { generateOrganizationRoute } from 'shared/routes';

import { AuthorNameLink } from './AuthorNameLink';

interface Props {
  organization: OrganizationInfoFragment | OrganizationItemFragment | undefined | null;

  className?: string;
}

export function OrganizationNameLink(props: Props) {
  const name = props.organization?.name ?? '(Business)';

  const route = useMemo(() => {
    return generateOrganizationRoute(props.organization?.id);
  }, [props.organization]);

  return <AuthorNameLink className={props.className} name={name} route={route} />;
}
