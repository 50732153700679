import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Content

export const Content = styled(Container)``;

// Title

export const Title = styled.span`
  color: ${p => p.theme.palette.primary.light};
  font-size: ${p => p.theme.typography.body1.fontSize};
`;
