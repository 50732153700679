import { useMemo } from 'react';

import { GroupUserRequestStatus } from '@jebel/constants';

import {
  ChooseStatusChip,
  ChooseStatusChipOption,
  ChooseStatusChipProps,
} from 'shared/components/ui';
import { useTheme } from '@emotion/react';

interface Props {
  status: GroupUserRequestStatus;
  size?: ChooseStatusChipProps['size'];

  options?: ChooseStatusChipOption[];
}

export function GroupMemberStatus(props: Props) {
  const { palette } = useTheme();

  const color = useMemo(() => {
    const GROUP_STATUS_COLORS = {
      [GroupUserRequestStatus.Approved]: palette.success.main,
      [GroupUserRequestStatus.Pending]: palette.action.active,
      [GroupUserRequestStatus.Rejected]: palette.error.main,
    };

    return GROUP_STATUS_COLORS[props.status];
  }, [props.status, palette]);

  const label = useMemo(() => {
    const GROUP_STATUS_LABEL = {
      [GroupUserRequestStatus.Approved]: 'Approved',
      [GroupUserRequestStatus.Pending]: 'Pending Approval',
      [GroupUserRequestStatus.Rejected]: 'Rejected',
    };

    return GROUP_STATUS_LABEL[props.status];
  }, [props.status]);

  return <ChooseStatusChip size={props.size} label={label} color={color} options={props.options} />;
}
