import { Fragment, useCallback, useMemo } from 'react';
import { Box, Typography, css, CardMedia, SxProps } from '@mui/material';

import { processFilestackUrlSrcSet, isImageFile, isVideoFile, checkMime } from '@jebel/utils';

import { Maybe } from 'shared/graphql';
import { useModalState, useResponsive } from 'shared/hooks';

import { GalleryCarouselDialog } from '../GalleryCarouselDialog';

export const MAIN_MEDIA_HEIGHT = 350;

const containerCSS = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
`;

const mainMediaCss = css`
  width: 100%;
  min-height: 5rem;
  grid-column: 3 span;
  /** https://8base-dev.atlassian.net/browse/JEB-1594 */
  /* height: 25vw; */
  /* max-height: 30rem; */
  overflow: hidden;
  z-index: 1;
`;

const rowMediaCss = css`
  display: flex;
  grid-column: 3 span;
  /** https://8base-dev.atlassian.net/browse/JEB-1594 */
  height: 10rem;
  align-items: center;
  gap: 0.25rem;
`;

const imageCss = css`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  height: 100%;
`;

const mediaCss: SxProps = {
  width: '100%',
  height: '100%',
  cursor: 'pointer',

  img: {
    objectFit: 'cover',
    height: '100%',
  },
};

const smallContentBoxCss = (count: number, isClickable: boolean) =>
  css`
    width: 100%;
    position: relative;
    height: 10rem;
    cursor: ${isClickable ? 'pointer' : 'cursor'};
  `;

const overlayCss = css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const moreMediaCount = css`
  color: #fff;
  font-weight: 600;
`;

interface MediaItem {
  id?: Maybe<string>;
  fileId?: Maybe<string>;
  filename?: Maybe<string>;
  downloadUrl?: Maybe<string>;
  meta?: any | null;
}

interface Props {
  media?: Maybe<MediaItem[]>;
  height?: string | number;
}

const SMALL_IMAGES_COUNT = 4;

const getSrcSet = (downloadUrl: string | null | undefined, height?: number) => {
  let payload = {};

  if (typeof height === 'number') {
    payload = { resize: { height: Math.abs(height) } };
  }

  const res = processFilestackUrlSrcSet(downloadUrl ?? undefined, payload);

  return res;
};

export function MediaGallery(props: Props) {
  const height = props.height ?? MAIN_MEDIA_HEIGHT;

  const media = useMemo(() => {
    const media = props.media ?? [];
    return media.filter(file => Boolean(file?.downloadUrl));
  }, [props.media]);

  const { isMobile } = useResponsive();

  const {
    show: isPicturesModalOpen,
    open: onModalOpen,
    close: onModalClose,
    params: activeIndex,
  } = useModalState<number>();

  const isFileImage = useCallback((file: MediaItem): boolean => {
    if (!file?.filename) {
      return false;
    }

    return isImageFile(file.filename);
  }, []);

  const isFileVideo = useCallback((file: MediaItem): boolean => {
    if (!file?.filename) {
      return false;
    }

    return isVideoFile(file.filename);
  }, []);

  const getMediaForCarousel = useCallback(
    (file: MediaItem) => {
      return {
        id: file.id as string,
        downloadUrl: file.downloadUrl as string,
        mimetype: isFileVideo(file) ? 'video' : 'image',
      } as const;
    },
    [isFileVideo],
  );

  if (!!media && media.length === 0) {
    return null;
  }

  const renderMediaItem = (file: MediaItem, index: number) => {
    if (file?.downloadUrl && file?.filename && isFileVideo(file)) {
      const url = `${file.downloadUrl}#t=0.001`;
      const mime = checkMime(file.filename);

      return (
        <CardMedia
          loop
          muted
          autoPlay
          sx={mediaCss}
          src={url}
          component="video"
          preload="metadata"
          playsInline={index === 0}
          onClick={event => {
            event.preventDefault();

            event.currentTarget.currentTime = 0;
            event.currentTarget.pause();

            onModalOpen(index);
          }}
        >
          <source srcSet={url} type={mime} />
          <img src={file.downloadUrl} alt={file.filename} />
        </CardMedia>
      );
    }

    return (
      <CardMedia
        component="img"
        css={imageCss}
        alt={file?.meta?.filename ?? 'Preview'}
        srcSet={getSrcSet(file?.downloadUrl, +height)}
        src={file?.downloadUrl as string}
        loading="lazy"
      />
    );
  };

  const renderMainMedia = (file: MediaItem, index: number) => {
    return (
      <Box css={mainMediaCss} onClick={() => onModalOpen(0)}>
        {renderMediaItem(file, index)}
      </Box>
    );
  };

  const renderRestMedia = (file: MediaItem, index: number, array: MediaItem[]) => {
    return (
      <Box
        key={file.id}
        css={smallContentBoxCss(array.length, isFileImage(file))}
        onClick={() => onModalOpen(index + 1)}
      >
        {media?.length > SMALL_IMAGES_COUNT && index === array.length - 1 && (
          <Box css={overlayCss}>
            <Typography css={moreMediaCount} variant={isMobile ? 'h4' : 'h2'}>
              +{media?.length - array.length - 1}
            </Typography>
          </Box>
        )}

        {renderMediaItem(file, index)}
      </Box>
    );
  };

  const renderContentLayout = (media: MediaItem[]) => {
    const [firstFile] = media;

    if (media.length === 0) {
      return null;
    }

    if (media.length === 1) {
      return renderMainMedia(firstFile, 0);
    }

    if (media.length === 2) {
      return (
        <Fragment>
          <Box css={rowMediaCss}>{media.slice(0, 2).map(renderRestMedia)}</Box>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {renderMainMedia(firstFile, 0)}
        <Box css={rowMediaCss}>{media.slice(1, 4).map(renderRestMedia)}</Box>
      </Fragment>
    );
  };

  return (
    <Box css={containerCSS}>
      <GalleryCarouselDialog
        isOpen={isPicturesModalOpen}
        onClose={onModalClose}
        activeIndex={activeIndex}
        media={media.map(getMediaForCarousel)}
      />

      {renderContentLayout(media)}
    </Box>
  );
}
