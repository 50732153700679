import React from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@material-ui/core';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import {
  OnReactionRemove,
  LikeAddProps,
  OnCustomReactionAdd,
} from 'shared/hooks/useStreamChatActivity';

const likeContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    padding: 0 0 0 8px;
  }

  &:first-child {
    button {
      padding: 0 8px;
    }
  }
`;

const likeCountCss = isClicked => theme =>
  css`
    font-size: 14px;
    font-weight: 500;
    color: ${isClicked ? theme.palette.secondary.light : theme.palette.primary.light};
  `;

const likeButtonCss = theme => css`
  justify-content: space-between;
  color: ${theme.palette.primary.light};
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  min-width: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export type PostLikeButtonProps = LikeAddProps & {
  onCustomReactionAdd: OnCustomReactionAdd;
  onReactionRemove: OnReactionRemove;
  type: string;
  likeInfo: {
    id: string;
    // eslint-disable-next-line camelcase
    user_id: string;
  }[];
  parentEntityAuthorId?: string;
};

const TEMP_REACTION_ID = 'tempId';

export const HelpfulButton = ({
  onCustomReactionAdd,
  onReactionRemove,
  likeInfo,
  type,
  ...onLikeProps
}: PostLikeButtonProps) => {
  const [reactions, setReactions] = React.useState(likeInfo);
  const { userId: currentUserId } = useCurrentUser();

  const userReaction = React.useMemo(
    () => reactions.find(reaction => reaction.user_id === currentUserId),
    [currentUserId, reactions],
  );

  const onReaction = React.useCallback(async () => {
    if (userReaction) {
      onReactionRemove(userReaction.id);
      setReactions(reactions.filter(reaction => reaction.id !== userReaction.id));

      return;
    }

    // in order to not wait for the response
    if (currentUserId) {
      setReactions([...reactions, { user_id: currentUserId, id: TEMP_REACTION_ID }]);
    }

    const res = await onCustomReactionAdd({ ...onLikeProps, type });

    if (!res) {
      return;
    }

    setReactions(res);
  }, [
    userReaction,
    currentUserId,
    onCustomReactionAdd,
    onLikeProps,
    type,
    onReactionRemove,
    reactions,
  ]);

  return (
    <Box css={likeContainerCss}>
      <Button css={likeButtonCss} onClick={onReaction}>
        <Typography css={likeCountCss(userReaction)} paragraph={false}>
          {type === 'helpful' ? 'Helpful' : 'Not Helpful'}{' '}
          {reactions.length !== 0 && `(${reactions.length})`}
        </Typography>
      </Button>
    </Box>
  );
};
