import { CircularProgress } from '@material-ui/core';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useAppAuth } from 'providers/useAppAuth';
import { CenterLayout } from 'shared/components/layouts';
import { ROUTES } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';

import { renderContent } from './routerUtils';

export type ProtectedRouteProps = RouteProps & {
  redirectTo?: string;
  roles?: any;
};

export const AdminRoute = ({
  component,
  children,
  render,
  redirectTo = ROUTES.public.login,
  roles = [],
  ...rest
}: ProtectedRouteProps) => {
  const { hasSession } = useAppAuth();
  const { loading, isAdmin } = useCurrentUser();

  if (loading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  if (hasSession === false || isAdmin === false) {
    return <Redirect to={ROUTES.public.login} />;
  }

  return (
    <Route {...rest} render={props => renderContent({ component, render, children, props })} />
  );
};
