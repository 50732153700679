import { Fragment } from 'react';
import { Theme, css } from '@emotion/react';
import { Typography, Avatar, Box, Skeleton } from '@mui/material';

import { extractYear, processFilestackUrlSrcSet } from '@jebel/utils';

import { CurrentUserFragment } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { useResponsive } from 'shared/hooks';

const containerCSS = (theme: Theme) => css`
  min-width: 0;
  align-items: center;
  max-width: 200px;
  color: ${theme.palette.primary.dark};
`;

const userInfoContainerCss = css`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

const userInfoNameCss = css`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: normal;
`;

const userInfoGraduatingYearCss = css`
  color: #9b9b9b;
  line-height: normal;
`;

const DEFAULT_AVATAR_SIZE = 40;

export type HeaderNavigationUserProfileProps = {
  user: CurrentUserFragment | undefined | null;
  avatarSize?: number;
  /**
   * Allow to hide the content on desktop.
   * @default false
   */
  isPopover?: boolean;
  userLoading: boolean;
};

export const HeaderNavigationUserProfile = ({
  user,
  userLoading,
  avatarSize = DEFAULT_AVATAR_SIZE,
  isPopover = false,
}: HeaderNavigationUserProfileProps) => {
  const userGraduatingYear = extractYear(user?.graduatingYear);
  const fullname = formatUserName(user, 'Unknown');

  const { isAboveLargeDesktop } = useResponsive();

  const showDescription = isAboveLargeDesktop || isPopover;

  return (
    <Box display="flex" css={containerCSS}>
      <Avatar
        variant="circular"
        src={user?.avatar?.downloadUrl || ''}
        alt={fullname}
        srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
          compress: true,
          resize: { width: avatarSize, height: avatarSize, fit: 'crop' },
        })}
      />

      {showDescription && (
        <Box css={userInfoContainerCss}>
          {userLoading ? (
            <Box display="grid" gap={0.25}>
              <Skeleton variant="rectangular" width={100} />
              <Skeleton variant="rectangular" width={100} />
            </Box>
          ) : (
            <Fragment>
              <Typography paragraph={false} css={userInfoNameCss}>
                {isPopover ? `Hello, ${fullname}` : fullname}
              </Typography>

              {Boolean(userGraduatingYear) && (
                <Typography paragraph={false} css={userInfoGraduatingYearCss}>
                  CLASS OF {userGraduatingYear}
                </Typography>
              )}
            </Fragment>
          )}
        </Box>
      )}
    </Box>
  );
};
