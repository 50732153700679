import { useEffect, useMemo, useState } from 'react';

import { LinkPreview } from 'shared/components/ui';
import { useHomeFeedCardData } from 'shared/features/posts/providers';
import { formatLegacyDate } from 'shared/utils/date';
import { MentionsText } from 'shared/features/mentions';

import { Container, Content, LegacyDate } from './HomeFeedCardContent.styles';
import { HomeFeedCardGallery } from './HomeFeedCardGallery';
import { HomeFeedItemURLToken, parseHomeFeedItemText } from '@jebel/utils';

export function HomeFeedCardContent() {
  const [links, setLinks] = useState<string[]>([]);

  const post = useHomeFeedCardData();

  const legacyDate = useMemo(() => {
    return formatLegacyDate(post.legacyDate);
  }, [post.legacyDate]);

  useEffect(() => {
    const tokens = parseHomeFeedItemText(post.text as string);

    const urls = tokens.filter(
      (section): section is HomeFeedItemURLToken => section.token === 'url',
    );

    setLinks(urls.map(token => token.url));
  }, [post.text]);

  return (
    <Container>
      <Content>
        {post.legacyDate && <LegacyDate label={legacyDate} />}

        <MentionsText text={post.text ?? ''} />
      </Content>

      <HomeFeedCardGallery />

      {links.map(link => (
        <LinkPreview key={link} url={link} />
      ))}
    </Container>
  );
}
