import { DateTime } from 'luxon';
import { Box, Paper, Typography } from '@material-ui/core';
import { useState, useMemo } from 'react';
import { Theme, css } from '@emotion/react';

import { formatMoney } from '@jebel/utils';

import type { DashboardsAdvertisementsListQuery } from 'shared/graphql';
import { AsyncContent } from 'shared/components/ui';
import { LinearChart, LinearTimeChartData } from 'shared/components/charts';
import { formatSQLDate } from 'shared/utils/date';

import { DashboardChartTemplateTop } from './DashboardChartTemplateTop';
import { DateFilterType } from '../types';
import { DashboardFilters } from './DashboardFilters';
import { DashboardAdvertisingSelect } from './DashboardAdvertisingSelect';
import { optionToRevenue, optionToVolume } from '../consts';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const sectionContainerCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const paperContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  flex: 1;
`;

interface DashboardChartsProps {
  dateFilter: DateFilterType;
  data?: DashboardsAdvertisementsListQuery['dashboardsList']['items'];
  loading: boolean;
  setDateFilter: (field: keyof DateFilterType) => (value: DateTime) => void;
}

export const DashboardAdvertisingCharts: React.FC<DashboardChartsProps> = ({
  dateFilter,
  data = [],
  loading,
  setDateFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('all');

  const advertisingChartData = useMemo(() => {
    return data.map<LinearTimeChartData>(item => {
      const amount = item[optionToVolume[selectedOption]] ?? 0;

      return {
        timestamp: formatSQLDate(item.createdAt),
        value: amount,
      };
    });
  }, [data, selectedOption]);

  const revenueChartData = useMemo(() => {
    return data.map<LinearTimeChartData>(item => {
      const amount = item[optionToRevenue[selectedOption]] ?? 0;

      return {
        timestamp: formatSQLDate(item.createdAt),
        value: amount,
      };
    });
  }, [data, selectedOption]);

  return (
    <Box css={containerCSS}>
      <Box display="flex" justifyContent="start" gridGap="1rem" alignItems="baseline">
        <Typography variant="h5">Advertising</Typography>

        <Box display="flex" gridGap="1rem">
          <DashboardFilters
            dateFilter={dateFilter}
            setStartDateFilter={setDateFilter('startDate')}
            setEndDateFilter={setDateFilter('endDate')}
            loading={loading}
          />

          <DashboardAdvertisingSelect
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
      </Box>

      <Box css={sectionContainerCSS}>
        <Paper css={paperContentCSS}>
          <AsyncContent loading={loading} height={300}>
            <DashboardChartTemplateTop title="Volume" date={dateFilter.endDate} />

            <LinearChart type="time" data={advertisingChartData} />
          </AsyncContent>
        </Paper>

        <Paper css={paperContentCSS}>
          <AsyncContent loading={loading} height={300}>
            <DashboardChartTemplateTop title="Revenue" date={dateFilter.endDate} />

            <LinearChart
              type="time"
              data={revenueChartData}
              formatValue={value => formatMoney(value)}
            />
          </AsyncContent>
        </Paper>
      </Box>
    </Box>
  );
};
