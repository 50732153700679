export const DEFAULT_USER_NAME = '(User)';
export const PLATFORM_NAME = 'Jebel';
export const PLATFORM_INBOX_NAME = 'Jebel App';
export const US_STATES = [
    { abbreviation: 'AK', name: 'Alaska' },
    { abbreviation: 'AL', name: 'Alabama' },
    { abbreviation: 'AR', name: 'Arkansas' },
    { abbreviation: 'AZ', name: 'Arizona' },
    { abbreviation: 'CA', name: 'California' },
    { abbreviation: 'CO', name: 'Colorado' },
    { abbreviation: 'CT', name: 'Connecticut' },
    { abbreviation: 'DC', name: 'District of Columbia' },
    { abbreviation: 'DE', name: 'Delaware' },
    { abbreviation: 'FL', name: 'Florida' },
    { abbreviation: 'GA', name: 'Georgia' },
    { abbreviation: 'HI', name: 'Hawaii' },
    { abbreviation: 'IA', name: 'Iowa' },
    { abbreviation: 'ID', name: 'Idaho' },
    { abbreviation: 'IL', name: 'Illinois' },
    { abbreviation: 'IN', name: 'Indiana' },
    { abbreviation: 'KS', name: 'Kansas' },
    { abbreviation: 'KY', name: 'Kentucky' },
    { abbreviation: 'LA', name: 'Louisiana' },
    { abbreviation: 'MA', name: 'Massachusetts' },
    { abbreviation: 'MD', name: 'Maryland' },
    { abbreviation: 'ME', name: 'Maine' },
    { abbreviation: 'MI', name: 'Michigan' },
    { abbreviation: 'MN', name: 'Minnesota' },
    { abbreviation: 'MO', name: 'Missouri' },
    { abbreviation: 'MS', name: 'Mississippi' },
    { abbreviation: 'MT', name: 'Montana' },
    { abbreviation: 'NC', name: 'North Carolina' },
    { abbreviation: 'ND', name: 'North Dakota' },
    { abbreviation: 'NE', name: 'Nebraska' },
    { abbreviation: 'NH', name: 'New Hampshire' },
    { abbreviation: 'NJ', name: 'New Jersey' },
    { abbreviation: 'NM', name: 'New Mexico' },
    { abbreviation: 'NV', name: 'Nevada' },
    { abbreviation: 'NY', name: 'New York' },
    { abbreviation: 'OH', name: 'Ohio' },
    { abbreviation: 'OK', name: 'Oklahoma' },
    { abbreviation: 'OR', name: 'Oregon' },
    { abbreviation: 'PA', name: 'Pennsylvania' },
    { abbreviation: 'PR', name: 'Puerto Rico' },
    { abbreviation: 'RI', name: 'Rhode Island' },
    { abbreviation: 'SC', name: 'South Carolina' },
    { abbreviation: 'SD', name: 'South Dakota' },
    { abbreviation: 'TN', name: 'Tennessee' },
    { abbreviation: 'TX', name: 'Texas' },
    { abbreviation: 'UT', name: 'Utah' },
    { abbreviation: 'VA', name: 'Virginia' },
    { abbreviation: 'VI', name: 'Virgin Islands' },
    { abbreviation: 'VT', name: 'Vermont' },
    { abbreviation: 'WA', name: 'Washington' },
    { abbreviation: 'WI', name: 'Wisconsin' },
    { abbreviation: 'WV', name: 'West Virginia' },
    { abbreviation: 'WY', name: 'Wyoming' },
];
export const DISCOUNT_CATEGORIES = {
    automotive: 'automotive',
    dining: 'dining',
    entertainment: 'entertainment',
    financial: 'financial',
    sportsFitness: 'sportsFitness',
    insurance: 'insurance',
    technology: 'technology',
    businessSupport: 'businessSupport',
    marketing: 'marketing',
    construction: 'construction',
    education: 'education',
    medical: 'medical',
    dental: 'dental',
    homeGarden: 'homeGarden',
    legal: 'legal',
    retail: 'retail',
    realEstate: 'realEstate',
    personalCare: 'personalCare',
    travel: 'travel',
    distribution: 'distribution',
    other: 'other',
};
export const INDUSTRIES = {
    BusinessSupport: 'businessSupport',
    Construction: 'construction',
    Dental: 'dental',
    Dining: 'dining',
    Distribution: 'distribution',
    Education: 'education',
    Entertainment: 'entertainment',
    Financial: 'financial',
    HomeGarden: 'homeGarden',
    Insurance: 'insurance',
    Legal: 'legal',
    Marketing: 'marketing',
    Medical: 'medical',
    PersonalCare: 'personalCare',
    RealEstate: 'realEstate',
    Retail: 'retail',
    SportsFitness: 'sportsFitness',
    Technology: 'technology',
    Travel: 'travel',
    accounting: 'accounting',
    airlines: 'airlines',
    alternativeDisputeResolution: 'alternativeDisputeResolution',
    alternativeMedicine: 'alternativeMedicine',
    animation: 'animation',
    apparelFashion: 'apparelFashion',
    architecturePlanning: 'architecturePlanning',
    artsCrafts: 'artsCrafts',
    automotive: 'automotive',
    aviationAerospace: 'aviationAerospace',
    banking: 'banking',
    biotechnology: 'banking',
    broadcastMedia: 'broadcastMedia',
    buildingMaterials: 'broadcastMedia',
    businessSuppliesEquipment: 'businessSuppliesEquipment',
    capitalMarkets: 'capitalMarkets',
    chemicals: 'chemicals',
    civicSocialOrganization: 'civicSocialOrganization',
    civilEngineering: 'civilEngineering',
    commercialRealEstate: 'civilEngineering',
    computerNetworkSecurity: 'computerNetworkSecurity',
    computerGames: 'computerGames',
    computerHardware: 'computerHardware',
    computerNetworking: 'computerNetworking',
    computerSoftware: 'computerSoftware',
    construction: 'construction',
    consumerElectronics: 'consumerElectronics',
    consumerGoods: 'consumerElectronics',
    consumerServices: 'consumerServices',
    cosmetics: 'cosmetics',
    dairy: 'dairy',
    defenseSpace: 'defenseSpace',
    design: 'design',
    educationManagement: 'educationManagement',
    eLearning: 'eLearning',
    electricalElectronicManufacturing: 'electricalElectronicManufacturing',
    entertainment: 'entertainment',
    environmentalServices: 'environmentalServices',
    eventsServices: 'eventsServices',
    executiveOffice: 'executiveOffice',
    facilitiesServices: 'facilitiesServices',
    farming: 'farming',
    financialServices: 'financialServices',
    fineArt: 'fineArt',
    fishery: 'fishery',
    foodBeverages: 'foodBeverages',
    foodProduction: 'foodProduction',
    fundRaising: 'foodProduction',
    furniture: 'foodProduction',
    gamblingCasinos: 'gamblingCasinos',
    glassCeramicsConcrete: 'glassCeramicsConcrete',
    governmentAdministration: 'glassCeramicsConcrete',
    governmentRelations: 'glassCeramicsConcrete',
    graphicDesign: 'glassCeramicsConcrete',
    healthWellnessFitness: 'healthWellnessFitness',
    higherEducation: 'higherEducation',
    hospitalHealthCare: 'hospitalHealthCare',
    hospitality: 'hospitality',
    humanResources: 'humanResources',
    importExport: 'humanResources',
    individualFamilyServices: 'individualFamilyServices',
    industrialAutomation: 'industrialAutomation',
    informationServices: 'industrialAutomation',
    informationTechnologyServices: 'informationTechnologyServices',
    insurance: 'insurance',
    internationalAffairs: 'internationalAffairs',
    internationalTradeDevelopment: 'internationalAffairs',
    internet: 'internationalAffairs',
    investmentBanking: 'internationalAffairs',
    investmentManagement: 'internationalAffairs',
    judiciary: 'judiciary',
    lawEnforcement: 'lawEnforcement',
    lawPractice: 'lawEnforcement',
    legalServices: 'lawEnforcement',
    legislativeOffice: 'legislativeOffice',
    leisureTravelTourism: 'leisureTravelTourism',
    libraries: 'libraries',
    logisticsSupplyChain: 'logisticsSupplyChain',
    luxuryGoodsJewelry: 'logisticsSupplyChain',
    machinery: 'machinery',
    managementConsulting: 'managementConsulting',
    maritime: 'maritime',
    marketResearch: 'marketResearch',
    marketingAdvertising: 'marketingAdvertising',
    mechanicalIndustrialEngineering: 'mechanicalIndustrialEngineering',
    mediaProduction: 'mediaProduction',
    medicalDevices: 'medicalDevices',
    medicalPractice: 'medicalPractice',
    mentalHealthCare: 'mentalHealthCare',
    military: 'military',
    miningMetals: 'miningMetals',
    motionPicturesFilm: 'motionPicturesFilm',
    museumsInstitutions: 'museumsInstitutions',
    music: 'music',
    nanotechnology: 'nanotechnology',
    newspapers: 'newspapers',
    nonProfitOrganizationManagement: 'nonProfitOrganizationManagement',
    oilEnergy: 'oilEnergy',
    onlineMedia: 'onlineMedia',
    outsourcingOffshoring: 'outsourcingOffshoring',
    packageFreightDelivery: 'packageFreightDelivery',
    packagingContainers: 'packagingContainers',
    paperForestProducts: 'paperForestProducts',
    performingArts: 'performingArts',
    pharmaceuticals: 'pharmaceuticals',
    philanthropy: 'philanthropy',
    photography: 'photography',
    plastics: 'plastics',
    politicalOrganization: 'politicalOrganization',
    primarySecondaryEducation: 'primarySecondaryEducation',
    printing: 'printing',
    professionalTrainingCoaching: 'professionalTrainingCoaching',
    programDevelopment: 'programDevelopment',
    publicPolicy: 'publicPolicy',
    publicRelationsCommunications: 'publicRelationsCommunications',
    publicSafety: 'publicSafety',
    publishing: 'publishing',
    railroadManufacture: 'railroadManufacture',
    ranching: 'ranching',
    realEstate: 'realEstate',
    recreationalFacilitiesServices: 'recreationalFacilitiesServices',
    religiousInstitutions: 'religiousInstitutions',
    renewablesEnvironment: 'renewablesEnvironment',
    research: 'research',
    restaurants: 'restaurants',
    retail: 'retail',
    securityInvestigations: 'securityInvestigations',
    semiconductors: 'semiconductors',
    shipbuilding: 'shipbuilding',
    sportingGoods: 'sportingGoods',
    sports: 'sports',
    staffingRecruiting: 'staffingRecruiting',
    supermarkets: 'supermarkets',
    telecommunications: 'telecommunications',
    textiles: 'textiles',
    thinkTanks: 'textiles',
    tobacco: 'tobacco',
    translationLocalization: 'translationLocalization',
    transportationTruckingRailroad: 'transportationTruckingRailroad',
    utilities: 'utilities',
    ventureCapitalPrivateEquity: 'ventureCapitalPrivateEquity',
    veterinary: 'veterinary',
    warehousing: 'warehousing',
    wholesale: 'wholesale',
    wineSpirits: 'wineSpirits',
    wireless: 'wireless',
    writingEditing: 'writingEditing',
};
export const SORT = {
    asc: 'ASC',
    desc: 'DESC',
};
export const US_TIMEZONES = [
    {
        shortName: 'America/Cancun',
        longName: 'Eastern Standard Time',
        abbreviation: 'EST',
    },
    {
        shortName: 'America/Regina',
        longName: 'Central Standard Time',
        abbreviation: 'CST',
    },
    {
        shortName: 'America/Phoenix',
        longName: 'Mountain Standard Time',
        abbreviation: 'MST',
    },
    {
        shortName: 'US/Pacific',
        longName: 'Pacific Standard Time',
        abbreviation: 'PST',
    },
    {
        shortName: 'US/Alaska',
        longName: 'Alaska Standard Time',
        abbreviation: 'AKST',
    },
    {
        shortName: 'US/Hawaii',
        longName: 'Hawaii Standard Time',
        abbreviation: 'HST',
    },
];
export const DISTANCE_FILTER_IN_MILES = {
    any: '-',
    close3: '5 miles',
    close2: '10 miles',
    close1: '20 miles',
    nearby3: '30 miles',
    nearby2: '40 miles',
    nearby1: '50 miles',
    far: '100 miles',
};
export const PHONE_MASK_WILDCARD = '#';
export const PHONE_MASK = '###-###-####';
