import { css } from '@emotion/react';
import { DateTime } from 'luxon';
import { isBoolean } from 'lodash';

import {
  USER_AFFILIATIONS,
  USER_STATUSES,
  US_STATES,
  DISTANCE_FILTER_IN_MILES,
  SelectOptionType,
  USER_GENDERS,
} from '@jebel/constants';

import { FIELDS_CONFIG, USER_ACTIVITIES_OPTIONS } from 'shared/constants';
import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import { OrganizationInfoFragment, SortOrder, UserFilter, UserSort } from 'shared/graphql';

export enum MembersReportHeaders {
  name = 'Name',
  email = 'Email Address',
  type = 'Type',
  gender = 'Gender',
  birthDate = 'Date Of Birth',
  supporter = 'Supporter?',
  posts = 'Posts',
  roles = 'Roles',
  createdOn = 'Created On',
  status = 'Status',
}
const GENDER_FILTER_OPTIONS: SelectOptionType[] = [
  {
    value: USER_GENDERS.male,
    label: 'Male',
  },
  {
    value: USER_GENDERS.female,
    label: 'Female',
  },
  {
    value: 'EMPTY',
    label: '(Empty)',
  },
];

const STATUSES_FILTER_OPTIONS: SelectOptionType[] = [
  {
    value: USER_STATUSES.active,
    label: 'Active',
  },
  {
    value: USER_STATUSES.inactive,
    label: 'Inactive',
  },
  {
    value: USER_STATUSES.invitationSent,
    label: 'Invitation Sent',
  },
  {
    value: USER_STATUSES.pending,
    label: 'Pending',
  },
  {
    value: USER_STATUSES.rejected,
    label: 'Rejected',
  },
];

const AFFILIATION_FILTER_OPTIONS: SelectOptionType[] = [
  {
    value: USER_AFFILIATIONS.staff,
    label: 'Staff',
  },
  {
    value: USER_AFFILIATIONS.alumni,
    label: 'Alumni',
  },
  {
    value: USER_AFFILIATIONS.student,
    label: 'Student',
  },
  {
    value: USER_AFFILIATIONS.parent,
    label: 'Parent',
  },
  {
    value: USER_AFFILIATIONS.other,
    label: 'Other',
  },
];

export const MembersSpreadsheetHeader: HeadlinesType<UserSort> = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: (order: SortOrder) => [{ firstName: order }, { lastName: order }],
    css: () =>
      css`
        min-width: 10rem;
      `,
  },
  {
    name: 'email',
    label: 'EMAIL ADDRESS',
    id: 'email',
    sortPath: (order: SortOrder) => [{ email: order }],
    css: () =>
      css`
        width: 14.6%;
      `,
  },
  {
    name: 'type',
    label: 'TYPE',
    id: 'type',
    sortPath: (order: SortOrder) => [{ affiliation: order }],
    css: () =>
      css`
        width: 8.5%;
      `,
  },
  {
    name: 'gender',
    label: 'GENDER',
    id: 'gender',
    sortPath: (order: SortOrder) => [{ gender: order }],
  },
  {
    name: 'birthDate',
    label: 'DATE OF BIRTH',
    id: 'birthDate',
    sortPath: (order: SortOrder) => [{ birthDate: order }],
    css: () =>
      css`
        width: 8.75%;
      `,
  },
  {
    name: 'isSupporter',
    label: 'SUPPORTER?',
    id: 'isSupporter',
    css: () =>
      css`
        width: 7.95%;
      `,
  },
  {
    name: 'posts',
    label: 'POSTS',
    id: 'posts',
    css: () =>
      css`
        width: 5.8%;
      `,
  },
  {
    name: 'roles',
    label: 'ROLES',
    id: 'roles',
    withoutSort: true,
    css: () =>
      css`
        width: 8.5%;
      `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => [{ createdAt: order }],
    css: () =>
      css`
        width: 10.3%;
      `,
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => [{ userStatus: order }],
    css: () =>
      css`
        width: 13.96%;
      `,
  },
] as const;

export const memberSpreadsheetFilters = (context: {
  organizations: OrganizationInfoFragment[];
  graduatingYears: SelectOptionType[];
}): SpreadsheetFiltersType<UserFilter> => {
  return [
    {
      type: 'select',
      variants: AFFILIATION_FILTER_OPTIONS,
      label: 'Affiliation',
      name: 'affiliation',
      placeholder: 'User affilation',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          affiliation: { equals: value },
        };
      },
    },

    {
      type: 'select',
      name: 'gender',
      label: 'Gender',
      placeholder: 'Gender',

      variants: GENDER_FILTER_OPTIONS,

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        if (value === 'EMPTY') {
          return {
            gender: { is_empty: true },
          };
        }

        return {
          gender: { equals: value },
        };
      },
    },

    {
      type: 'select',
      label: 'Supporter?',
      name: 'isSupporter',
      placeholder: 'Supporter?',

      variants: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],

      filterPath(equals: boolean) {
        if (isBoolean(equals) === false) {
          return {};
        }

        return {
          ownedOrganizations: {
            some: {
              schoolSupporter: {
                some: {
                  id: { is_not_empty: true },
                  status: { equals: 'active' },
                },
              },
            },
          },
        };
      },
    },
    {
      type: 'select',
      variants: STATUSES_FILTER_OPTIONS,
      label: 'Status',
      name: 'status',
      placeholder: 'User status',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          userStatus: { equals: value },
        };
      },
    },

    {
      type: 'select',
      label: 'Class Year',
      name: 'graduatingYear',
      placeholder: 'Years',
      size: 'full',

      variants: context.graduatingYears.map(option => ({ value: option.value })),

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        const date = DateTime.now().set({ year: Number(value), month: 1, day: 1 });

        return {
          graduatingYear: { equals: date.toSQLDate() },
        };
      },
    },

    {
      type: 'select',
      variants: FIELDS_CONFIG.industry.map(({ label }) => ({ value: label })),
      label: 'Industry',
      name: 'industry',
      placeholder: 'Select Industry',
      size: 'full',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          currentIndustry: {
            equals: FIELDS_CONFIG.industry?.find(({ label }) => label === value)?.value,
          },
        };
      },
    },
    {
      type: 'input',
      filterPath: (value: string) => ({
        userPreferences: { address: { city: { contains: value } } },
      }),
      name: 'city',
      label: 'City',
    },
    {
      type: 'select',
      variants: US_STATES.map(({ abbreviation }) => ({ value: abbreviation })),
      label: 'State',
      name: 'state',
      placeholder: 'Select State',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          userPreferences: { address: { state: { equals: value } } },
        };
      },
    },
    {
      type: 'input',
      customFilterPath: (value: string) => ({
        startPointZip: value,
      }),
      name: 'zip',
      label: 'ZIP Code',
    },
    {
      type: 'select',
      variants: Object.values(DISTANCE_FILTER_IN_MILES)
        .slice(1, 5)
        .map(distance => ({
          value: distance.toString(),
        })),
      label: 'Distance',
      name: 'distance',
      customFilterPath: (value: string) => ({
        radius: value.split(' ')[0],
      }),
    },
    {
      type: 'select',
      variants: context.organizations.map(({ name }) => ({ value: name || '' })),
      label: 'Related organizations',
      name: 'relatedOrganiztions',
      placeholder: 'Select organization',
      size: 'full',

      filterPath(value: string) {
        if (!value) {
          return {};
        }

        return {
          ownedOrganizations: { some: { name: { equals: value } } },
        };
      },
    },
    {
      type: 'input',
      filterPath: (value: string) => ({
        joinedGroups: { some: { title: { contains: value } } },
      }),
      name: 'groups',
      label: 'Groups',
      size: 'full',
    },
    {
      type: 'input',
      filterPath: (value: string) => ({
        education: { some: { degree: { contains: value } } },
      }),
      name: 'education',
      label: 'Education',
      size: 'full',
    },
    {
      type: 'autocomplete',
      variants: USER_ACTIVITIES_OPTIONS.hobbies.map(({ label }) => label) || [],
      name: 'hobbies',
      label: 'HOBBIES & INTERESTS',
      size: 'full',
    },
    {
      type: 'autocomplete',
      variants: USER_ACTIVITIES_OPTIONS.clubs.map(({ label }) => label),
      name: 'clubs',
      label: 'Clubs & Sports',
      size: 'full',
    },
  ];
};
