import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Box, Typography } from '@mui/material';

import { Button } from 'shared/components/ui';
import { DiscountListItemFragment } from 'shared/graphql';
import { EMPTY_IMAGE } from 'features/settings/assets';
import { useSchoolConfiguration } from 'shared/hooks';
import { generateDiscountLabel } from 'shared/utils/discounts';
import { OrganizationNameLink, SchoolNameLink } from 'shared/components/symbols';

const AUTHOR_IMAGE_SIZE = 40;

export type DiscountItemProps = {
  discount: DiscountListItemFragment;
  onReedem: () => void;
};

export function DiscountItem(props: DiscountItemProps) {
  const { configuration: school } = useSchoolConfiguration();

  const title = props.discount?.title;
  const isOrganizationOffer = Boolean(props.discount?.organization);

  /**
   * Gets the logo of the organization or school as fallback.
   * https://8base-dev.atlassian.net/browse/JEB-1267?focusedCommentId=42005
   */
  const offererAvatarSource = isOrganizationOffer
    ? props.discount?.organization?.logo?.downloadUrl
    : school?.images?.logo?.downloadUrl;

  const imageSource = useMemo(() => {
    const [firstImage] = props.discount.images?.items ?? [];
    return firstImage?.downloadUrl;
  }, [props.discount]);

  const description = useMemo(() => {
    return generateDiscountLabel(props.discount?.type, props.discount?.amountPercent);
  }, [props.discount]);

  return (
    <Container backgroundImg={imageSource ?? EMPTY_IMAGE}>
      <Box css={container258CSS} padding={2}>
        <Avatar css={offererAvatarCSS} src={offererAvatarSource ?? EMPTY_IMAGE} variant="square" />

        <Button onClick={props.onReedem} color="primary" size="medium" variant="contained">
          REDEEM
        </Button>
      </Box>

      <Box padding={2} display="grid" css={contentCSS}>
        {isOrganizationOffer ? (
          <OrganizationNameLink organization={props.discount?.organization} />
        ) : (
          <SchoolNameLink />
        )}

        <Typography fontWeight="bold">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Container>
  );
}

const Container = styled(Box)<{ backgroundImg?: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto auto;
  align-content: space-between;
  height: 15rem;
  background-image: ${p => `url(${p.backgroundImg})`};
  color: white;
  overflow: hidden;
`;

const container258CSS = css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;

const contentCSS = (theme: Theme) => css`
  background-color: ${theme.palette.secondary.dark};
`;

const offererAvatarCSS = (theme: Theme) => css`
  width: ${AUTHOR_IMAGE_SIZE}px;
  height: ${AUTHOR_IMAGE_SIZE}px;
  object-fit: cover;
  background-color: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.5rem;
`;
