import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { ReactNode, useMemo } from 'react';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { StyledAvatar } from './AuthorAvatar.styles';

interface Props {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;

  source: string | undefined;
  name: string | undefined | null;
  route?: string;
}

export function AuthorAvatar(props: Props) {
  const name = props.name ?? '';
  const sourceSet = processFilestackUrlSrcSet(props.source);

  const shortName = useMemo(() => {
    return name.slice(0, 2);
  }, [name]);

  const { push: navigate } = useHistory();

  const onClick = () => {
    if (props.route) {
      navigate(props.route);
    }
  };

  return (
    <Tooltip
      title={name}
      disableHoverListener={props.disabled}
      disableFocusListener={props.disabled}
      disableTouchListener={props.disabled}
      arrow
    >
      <StyledAvatar
        src={props.source}
        srcSet={sourceSet}
        className={props.className}
        onClick={onClick}
        aria-disabled={props.disabled}
        disabled={props.disabled}
      >
        {props.children ?? shortName}
      </StyledAvatar>
    </Tooltip>
  );
}
