import { Interval } from 'luxon';
import { currentDateTime, parseDate, } from './parseDates';
/**
 * Checks a `value` as `DateTime` and returns whether is in the future.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `value` is in the future.
 */
export function isFutureDate(value, options) {
    const date = parseDate(value, options);
    const now = currentDateTime(options === null || options === void 0 ? void 0 : options.zone);
    return date > now;
}
/**
 * Checks a `value` as `DateTime` and returns whether is in the past.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `value` is in the past.
 */
export function isPastDate(value, options) {
    const date = parseDate(value, options);
    const now = currentDateTime(options === null || options === void 0 ? void 0 : options.zone);
    return date < now;
}
/**
 * Allow to check whether the given date is the start of the day.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns Whether the given date is the start of the day.
 */
export function isStartDay(value, options) {
    var _a;
    const threshold = options === null || options === void 0 ? void 0 : options.threshold;
    const unit = (_a = options === null || options === void 0 ? void 0 : options.unit) !== null && _a !== void 0 ? _a : 'hour';
    const date = parseDate(value);
    const start = date.startOf('day').startOf(unit);
    let end = date.startOf('day').endOf(unit);
    if (threshold) {
        // Use the `threshold` to add units to the range.
        end = date.startOf('day').plus({ [unit]: threshold });
    }
    const interval = Interval.fromDateTimes(start, end);
    return interval.contains(date);
}
/**
 * Allow to check whether now is the start of the day.
 * @returns Whether now is the start of the day.
 */
export function isNowStartDay(options) {
    return isStartDay(currentDateTime(options === null || options === void 0 ? void 0 : options.zone), options);
}
/**
 * Allow to check whether the given date is the end of the day.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns Whether the given date is the end of the day.
 */
export function isEndDay(value, options) {
    var _a, _b;
    const threshold = (_a = options === null || options === void 0 ? void 0 : options.threshold) !== null && _a !== void 0 ? _a : 1;
    const unit = (_b = options === null || options === void 0 ? void 0 : options.unit) !== null && _b !== void 0 ? _b : 'hour';
    const date = parseDate(value);
    let start = date.endOf('day').startOf(unit);
    const end = date.endOf('day').endOf(unit);
    if (threshold) {
        // Use the `threshold` to add units to the range.
        start = date.endOf('day').minus({ [unit]: threshold });
    }
    const interval = Interval.fromDateTimes(start, end);
    return interval.contains(date);
}
/**
 * Allow to check whether now is the end of the day.
 * @returns Whether now is the end of the day.
 */
export function isNowEndDay(options) {
    return isEndDay(currentDateTime(options === null || options === void 0 ? void 0 : options.zone), options);
}
/**
 * Allow to check whether the given date is the start of the month.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it use the current date-time.
 * @returns Whether the given date is the start of the month.
 */
export function isStartMonth(value, options) {
    var _a;
    const threshold = options === null || options === void 0 ? void 0 : options.threshold;
    const unit = (_a = options === null || options === void 0 ? void 0 : options.unit) !== null && _a !== void 0 ? _a : 'hour';
    const date = parseDate(value);
    const start = date.startOf('month').startOf(unit);
    let end = date.startOf('month').endOf(unit);
    if (threshold) {
        // Use the `threshold` to add units to the range.
        end = date.startOf('month').plus({ [unit]: threshold });
    }
    const interval = Interval.fromDateTimes(start, end);
    return interval.contains(date);
}
/**
 * Allow to check whether now is the start of the day.
 * @returns Whether now is the start of the day.
 */
export function isNowStartMonth(options) {
    return isStartMonth(currentDateTime(options === null || options === void 0 ? void 0 : options.zone), options);
}
/**
 * Allow to check whether the given date is the end of the month.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it use the current date-time.
 * @returns Whether the given date is the end of the month.
 */
export function isEndMonth(value, options) {
    var _a, _b;
    const threshold = (_a = options === null || options === void 0 ? void 0 : options.threshold) !== null && _a !== void 0 ? _a : 1;
    const unit = (_b = options === null || options === void 0 ? void 0 : options.unit) !== null && _b !== void 0 ? _b : 'hour';
    const date = parseDate(value);
    let start = date.endOf('month').startOf(unit);
    const end = date.endOf('month').endOf(unit);
    if (threshold) {
        // Use the `threshold` to add units to the range.
        start = date.endOf('month').minus({ [unit]: threshold });
    }
    const interval = Interval.fromDateTimes(start, end);
    return interval.contains(date);
}
/**
 * Allow to check whether now is the end of the day.
 * @returns Whether now is the end of the day.
 */
export function isNowEndMonth(options) {
    return isEndMonth(currentDateTime(options === null || options === void 0 ? void 0 : options.zone), options);
}
/**
 * Checks a `value` as `DateTime` and returns whether is in between `start` and `end` options.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `value` is in between `start` and `end` options.
 */
export function isBetween(value, options) {
    const date = parseDate(value);
    const start = parseDate(options.start);
    const end = parseDate(options.end);
    const range = Interval.fromDateTimes(start, end);
    return range.contains(date);
}
