import { SORT } from '@jebel/constants';

export const EVENTS_SORT_INFO = {
  displayName: 'Sort by',
  fullWidth: true,
  options: [
    { label: 'Date (Most recent first)', value: { date: SORT.asc } },
    { label: 'Date (Oldest first)', value: { date: SORT.desc } },
    { label: 'Closest first', value: { proximity: SORT.asc } },
  ],
};
export const EVENTS_SORT_INFO_NO_ADDRESS = {
  displayName: 'Sort by',
  fullWidth: true,
  options: [
    { label: 'Date (Most recent first)', value: { date: SORT.desc } },
    { label: 'Date (Oldest first)', value: { date: SORT.asc } },
  ],
};

export const USER_SORT_BY_NAME_INFO = {
  displayName: 'Sort by',
  fullWidth: true,
  options: [
    {
      label: 'First Name (A-Z)',
      value: { firstName: SORT.asc },
    },
    { label: 'Last Name (A-Z)', value: { lastName: SORT.asc } },
  ],
};

export const USER_SORT_INFO = {
  displayName: 'Sort by',
  options: [
    {
      label: 'Newest first',
      value: { createdAt: SORT.desc },
    },
    { label: 'Alphabetical', value: { firstName: SORT.asc } },
  ],
};

export const LEGACY_MONTH_SORT_INFO = {
  displayName: 'Sort by',
  options: [
    {
      label: 'Posting Date: Newest First',
      value: {
        month: SORT.desc,
      },
    },
  ],
};

export const LEGACY_YEAR_SORT_INFO = {
  displayName: 'Sort by',
  options: [
    {
      label: 'Posting Date: Newest First',
      value: {
        legacyYear: SORT.desc,
      },
    },
  ],
};

export const ORGANIZATIONS_SORT_INFO = {
  displayName: 'Sort by',
  options: [{ label: 'Alphabetical', value: { name: SORT.desc } }],
};
