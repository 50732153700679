import { useEffect, useState } from 'react';
import { CircularProgress, Link, Typography } from '@material-ui/core';

import { LOCAL_STORAGE_ROOT_LOADER_ATTEMPTS } from 'shared/constants';
import { useLocalStorageState } from 'shared/hooks';

import { Container } from './RootContentLoader.styles';

const SHOW_REFRESH_DELAY = 1000 * 5; // 5 seconds
const MAX_ATTEMPTS = 2;

export function RootContentLoader() {
  const [showRefresh, setShowRefresh] = useState(false);
  const [attempts, setAttempts] = useLocalStorageState(LOCAL_STORAGE_ROOT_LOADER_ATTEMPTS, 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (attempts < MAX_ATTEMPTS) {
        // Refresh the page automatically on the first load.
        refreshPage();
        return;
      }

      // Show the refresh button after delay.
      // https://8base-dev.atlassian.net/browse/JEB-1559?focusedCommentId=44943
      setShowRefresh(true);
    }, SHOW_REFRESH_DELAY);

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPage = () => {
    setAttempts(prev => prev + 1);
    window.location.reload();
  };

  return (
    <Container>
      <CircularProgress />

      {showRefresh && (
        <Typography align="center">
          <Typography component="span">Stuck in the loader?&nbsp;</Typography>

          <Link underline="always" onClick={refreshPage}>
            Refresh the application
          </Link>

          <Typography component="span">.</Typography>
        </Typography>
      )}
    </Container>
  );
}
