import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { DashboardPage } from './pages/DashboardPage';

export const getDashboardRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.dashboard.index,
    exact: true,
    component: DashboardPage,
  },
];
