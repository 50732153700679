import React from 'react';
import { Box, Button, css } from '@mui/material';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { OnLikeAdd, OnReactionRemove, LikeAddProps } from 'shared/hooks/useStreamChatActivity';
import { Typography } from 'shared/components/ui';

export type PostLikeButtonProps = LikeAddProps & {
  onLikeAdd: OnLikeAdd;
  onReactionRemove: OnReactionRemove;
  parentEntityAuthorId?: string;
  likeInfo: {
    id: string;
    // eslint-disable-next-line camelcase
    user_id: string;
  }[];
};

const TEMP_REACTION_ID = 'tempId';

const textCss = theme => css`
  font-weight: 500;
  text-transform: initial;
  font-size: ${theme.typography.fontSize}px;
`;

export const LikeButton = ({
  onLikeAdd,
  onReactionRemove,
  likeInfo,
  ...onLikeProps
}: PostLikeButtonProps) => {
  const [likes, setLikes] = React.useState(likeInfo);
  const { userId: currentUserId } = useCurrentUser();

  const userLikeReaction = React.useMemo(
    () => likes.find(reaction => reaction.user_id === currentUserId),
    [currentUserId, likes],
  );

  const onLike = React.useCallback(async () => {
    if (userLikeReaction) {
      onReactionRemove(userLikeReaction.id);
      setLikes(likes.filter(reaction => reaction.id !== userLikeReaction.id));

      return;
    }

    // in order to not wait for the response
    if (currentUserId) {
      setLikes([...likes, { user_id: currentUserId, id: TEMP_REACTION_ID }]);
    }

    const res = await onLikeAdd(onLikeProps);

    if (!res) {
      return;
    }

    setLikes(res);
  }, [userLikeReaction, likes, currentUserId, onLikeAdd, onLikeProps, onReactionRemove]);

  return (
    <Box>
      <Button onClick={onLike}>
        <Typography css={textCss} color={userLikeReaction ? 'highlight' : 'primary'}>
          Like
        </Typography>
        &nbsp;
        <Typography css={textCss} color={userLikeReaction ? 'highlight' : 'primary'}>
          {likes.length || ''}
        </Typography>
      </Button>
    </Box>
  );
};
