import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import { Redeem } from '@mui/icons-material';
import { useMemo } from 'react';

import { DiscountPresentationFragment } from 'shared/graphql';
import { MediaGallery } from 'shared/components/ui';
import { generateDiscountExpirationLabel, generateDiscountLabel } from 'shared/utils/discounts';
import { useRelativeDate } from 'shared/hooks';

import {
  AuthorContainer,
  AuthorContent,
  AuthorOrganizationAvatar,
  AuthorOrganizationName,
  AuthorSchoolAvatar,
  AuthorSchoolName,
  Buttons,
  Container,
  Content,
  Header,
  Label,
  PostDate,
  Title,
} from './HomeFeedCardDiscount.styles';

interface Props {
  data: DiscountPresentationFragment;

  onRedeem(discount: DiscountPresentationFragment): void;
}

export function HomeFeedCardDiscount(props: Props) {
  /**
   * Checks whether the current post is authored to the school.
   * https://8base-dev.atlassian.net/browse/JEB-1267?focusedCommentId=42005
   */
  const isOrganizationAuthored = Boolean(props.data.organization);

  const title = props.data.title;
  const images = props.data.images?.items ?? [];

  const creationDate = useRelativeDate(props.data.startDate);

  const expirationLabel = useMemo(() => {
    return generateDiscountExpirationLabel(props.data?.expirationDate);
  }, [props.data]);

  const label = useMemo(() => {
    return generateDiscountLabel(props.data?.type, props.data?.amountPercent);
  }, [props.data]);

  return (
    <Container>
      <Header>
        <AuthorContainer>
          {isOrganizationAuthored ? (
            <AuthorOrganizationAvatar organization={props.data.organization} />
          ) : (
            <AuthorSchoolAvatar />
          )}

          <AuthorContent>
            {isOrganizationAuthored ? (
              <AuthorOrganizationName organization={props.data.organization} />
            ) : (
              <AuthorSchoolName />
            )}

            <PostDate>{creationDate}</PostDate>
          </AuthorContent>
        </AuthorContainer>

        <Tooltip title="Redeem offer">
          <IconButton color="primary" onClick={props.onRedeem}>
            <Redeem />
          </IconButton>
        </Tooltip>
      </Header>

      <Content>
        <Chip color="secondary" label={expirationLabel} />
      </Content>

      {images.length > 0 && <MediaGallery media={images} />}

      <Header>
        <Content>
          <Title>{title}</Title>
          <Label>{label}</Label>
        </Content>

        <Buttons>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<Redeem />}
            onClick={props.onRedeem}
          >
            Redeem Offer
          </Button>
        </Buttons>
      </Header>
    </Container>
  );
}
