/* eslint-disable react/no-array-index-key */
import React, { Fragment, useMemo, useState } from 'react';
import { Avatar, css, Divider } from '@mui/material';
import { Box, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import {
  Typography,
  GalleryCarouselDialog,
  createSnackMessage,
  SNACK_TYPES,
} from 'shared/components/ui';
import { EventInfoFragment } from 'shared/graphql';
import { useResponsive } from 'shared/hooks';

import { EventCommentsList } from './EventCommentsList';
import { APP_URL } from '../../../../../shared/constants';

const TITLE_SPONSOR_AVATAR_SIZE = 90;
const EVENT_IMAGES_MAX_AMOUNT = 3;
const EVENT_IMAGE_SIZE = 240;
const SPONSOR_LOGO_SIZE = 50;

interface EventContentProps {
  event: EventInfoFragment;
}

const descriptionTitleCss = theme =>
  css`
    font-weight: 500;
  `;

const descriptionCss = (isMobile: boolean) => (theme: any) =>
  css`
    ${isMobile && `font-size: ${theme.typography.fontSize}px;`}
  `;

const titleSponsorCss = (isMobile: boolean) => theme =>
  css`
    font-size: ${theme.typography.fontSize + (isMobile ? 0 : 2)}px;
    font-weight: ${isMobile ? 600 : 500};
  `;

const titleSponsorAvatarCss = theme => css`
  height: ${TITLE_SPONSOR_AVATAR_SIZE}px;
  width: ${TITLE_SPONSOR_AVATAR_SIZE}px;
  border-radius: 50%;
  border: 1px solid ${theme.palette.text.secondary};
  cursor: pointer;
`;

const eventImageCss = (hasOneImage: boolean) => theme =>
  css`
    width: ${hasOneImage ? `${EVENT_IMAGE_SIZE}px` : '100%'};
    height: ${EVENT_IMAGE_SIZE}px;
  `;

const overlayCss = css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const morePicturesCount = css`
  color: #fff;
  font-weight: 600;
`;

const sponsorImageCss = theme => css`
  height: ${SPONSOR_LOGO_SIZE}px;
  width: ${SPONSOR_LOGO_SIZE}px;
  cursor: pointer;
`;

const sponsorsBoxCss = (isMobile: boolean) => (theme: any) =>
  css`
    ${!isMobile
      ? `display: flex; max-width: 536px;`
      : `display: grid; grid-template-columns: auto auto auto auto; grid-row-gap: ${theme.spacing(
          1,
        )}px; justify-content: space-around;`}
    overflow-x: auto;
    gap: ${theme.spacing(1)};
    padding: 0 ${theme.spacing(2)}px;
  `;

export function EventContent({ event }: EventContentProps) {
  const [isPicturesModalOpen, setIsPicturesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const { push: navigate } = useHistory();

  const { onModalOpen, onModalClose } = useMemo(() => {
    return {
      onModalOpen: (index: number) => () => {
        setIsPicturesModalOpen(true);
        setActiveIndex(index);
      },
      onModalClose: () => setIsPicturesModalOpen(false),
    };
  }, []);

  const images = event?.images?.items ?? [];
  const otherSponsors = event?.otherSponsors?.items ?? [];
  const eventHasOnlyOneImage = images.length === 1;

  const lines = useMemo(() => {
    const description = event?.description ?? '';

    return description.split('\n');
  }, [event]);

  const redirectToOrganization = id => {
    if (!id) {
      enqueueSnackbar('Error while opening the organization!', {
        autoHideDuration: 3000,
        content: createSnackMessage(SNACK_TYPES.error),
      });
      return;
    }

    navigate(`${APP_URL.user.organizations.index}/${id}`);
  };

  return (
    <Fragment>
      <GalleryCarouselDialog
        isOpen={isPicturesModalOpen}
        onClose={onModalClose}
        images={images}
        activeIndex={activeIndex}
      />

      <Box
        display="grid"
        justifyContent="space-between"
        alignItems="start"
        gridTemplateColumns="4fr 1fr"
        gridGap={1}
      >
        <Box>
          <Typography css={descriptionTitleCss(isMobile)} color="primary">
            Description
          </Typography>

          {lines.map((text, index) => (
            <Typography key={index} css={descriptionCss(isMobile)} variant="body1">
              {text}
            </Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography css={titleSponsorCss(isMobile)} color="textSecondary">
            Title Sponsor
          </Typography>
          <Box>
            <Tooltip title={event?.titleSponsor?.name || ''}>
              <Avatar
                onClick={() => {
                  redirectToOrganization(event?.titleSponsor?.id);
                }}
                css={titleSponsorAvatarCss}
                src={event?.titleSponsor?.logo?.downloadUrl || ''}
                srcSet={processFilestackUrlSrcSet(event?.titleSponsor?.logo?.downloadUrl || '', {
                  resize: {
                    width: TITLE_SPONSOR_AVATAR_SIZE,
                    height: TITLE_SPONSOR_AVATAR_SIZE,
                    fit: 'scale',
                  },
                })}
                variant="circular"
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {images.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns={eventHasOnlyOneImage ? 'auto' : '1fr 1fr 1fr'}
          justifyContent="space-around"
          gridGap={1}
        >
          {images.slice(0, EVENT_IMAGES_MAX_AMOUNT).map((image, index, array) => (
            <Box
              key={image?.id}
              onClick={onModalOpen(index)}
              position="relative"
              css={css`
                cursor: pointer;
              `}
            >
              {images.length > EVENT_IMAGES_MAX_AMOUNT && index === array.length - 1 && (
                <Box css={overlayCss}>
                  <Typography css={morePicturesCount} variant="h2">
                    +{images?.length - array.length}
                  </Typography>
                </Box>
              )}

              <Avatar
                css={eventImageCss(eventHasOnlyOneImage)}
                src={image?.downloadUrl || ''}
                srcSet={processFilestackUrlSrcSet(image?.downloadUrl || '', {
                  resize: {
                    width: EVENT_IMAGE_SIZE,
                    height: EVENT_IMAGE_SIZE,
                  },
                })}
                variant="square"
              />
            </Box>
          ))}
        </Box>
      )}

      {otherSponsors.length !== 0 && (
        <Box>
          <Typography css={titleSponsorCss(isMobile)} color="textSecondary">
            Other sponsors
          </Typography>

          <Box css={sponsorsBoxCss(isMobile)} gridGap={!isMobile && 10}>
            {otherSponsors.map((sponsor, index) => (
              <Tooltip key={index} title={sponsor?.name || ''}>
                <Avatar
                  onClick={() => {
                    redirectToOrganization(sponsor?.id);
                  }}
                  key={sponsor.id}
                  css={sponsorImageCss}
                  src={sponsor?.logo?.downloadUrl || ''}
                  srcSet={processFilestackUrlSrcSet(sponsor?.logo?.downloadUrl || '', {
                    resize: {
                      width: SPONSOR_LOGO_SIZE * 2,
                      height: SPONSOR_LOGO_SIZE * 2,
                    },
                  })}
                  variant="circular"
                />
              </Tooltip>
            ))}
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
        </Box>
      )}
      <EventCommentsList eventId={event?.id} />
    </Fragment>
  );
}
