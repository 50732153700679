import { useMutation } from '@apollo/client';

import { ORGANIZATION_STATUS } from '@jebel/constants';

import {
  OrganizationCreateMutation,
  OrganizationCreateMutationVariables,
} from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

import { ORGANIZATION_CREATE_MUTATION } from '../queries';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from '../../../shared/constants';

export const useCreateOrganization = () => {
  const [organizationCreateMutation] = useMutation<
    OrganizationCreateMutation,
    OrganizationCreateMutationVariables
  >(ORGANIZATION_CREATE_MUTATION, {
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Organization was successfully created',
      [SNACKBAR_ERROR_MESSAGE]: "Error: Organization hasn't been created",
    },
  });

  const { user } = useCurrentUser();

  const organizationCreate = async (
    organizationData: OrganizationCreateMutationVariables['data'],
    refetchQueries?: string[],
  ) => {
    try {
      return await organizationCreateMutation({
        refetchQueries,
        variables: {
          data: {
            ...organizationData,
            owner: {
              connect: {
                id: user?.id,
              },
            },
            managers: {
              create: [
                {
                  user: { connect: { id: user?.id ?? '' } },
                  status: ORGANIZATION_STATUS.active,
                  isAdmin: true,
                },
              ],
            },
          },
        },
      });
    } catch (e: any) {
      sendToSentry(e);
    }
  };

  return {
    organizationCreate,
  };
};
