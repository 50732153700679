import { useLegacyTimeStampsQuery } from 'shared/graphql';

type Options = Parameters<typeof useLegacyTimeStampsQuery>[0];

/** List all the available years and their years. */
export function useLegacyTimeStamps(options?: Options) {
  const { data: response, loading } = useLegacyTimeStampsQuery(options);

  const data = response?.years.items ?? [];

  return {
    data,
    loading,
  };
}
