import { DISCOUNT_CATEGORIES as DISCOUNTS, DISCOUNT_TYPES } from '@jebel/constants';

import { SELECT_OPTION_ALL } from './selectOptions';

export const DISCOUNT_CATEGORIES = [
  { key: DISCOUNTS.automotive, value: 'Automotive', icon: 'DirectionsCar' },
  { key: DISCOUNTS.dining, value: 'Dining', icon: 'Restaurant' },
  { key: DISCOUNTS.entertainment, value: 'Entertainment', icon: 'LocalMovies' },
  { key: DISCOUNTS.financial, value: 'Financial', icon: 'LocalAtm' },
  { key: DISCOUNTS.sportsFitness, value: 'Sports & Fitness', icon: 'SportsFootball' },
  { key: DISCOUNTS.insurance, value: 'Insurance', icon: 'VerifiedUser' },
  { key: DISCOUNTS.technology, value: 'Technology', icon: 'Devices' },
  { key: DISCOUNTS.businessSupport, value: 'Business Support', icon: 'BusinessCenter' },
  { key: DISCOUNTS.marketing, value: 'Marketing', icon: 'Campaign' },
  { key: DISCOUNTS.construction, value: 'Construction', icon: 'Handyman' },
  { key: DISCOUNTS.education, value: 'Education', icon: 'School' },
  { key: DISCOUNTS.medical, value: 'Medical', icon: 'MedicalServices' },
  { key: DISCOUNTS.dental, value: 'Dental', icon: 'LocalHospital' },
  { key: DISCOUNTS.homeGarden, value: 'Home & Garden', icon: 'Cottage' },
  { key: DISCOUNTS.legal, value: 'Legal', icon: 'Gavel' },
  { key: DISCOUNTS.retail, value: 'Retail', icon: 'ShoppingBag' },
  { key: DISCOUNTS.realEstate, value: 'Real Estate', icon: 'LocationCity' },
  { key: DISCOUNTS.personalCare, value: 'Personal Care', icon: 'SelfImprovement' },
  { key: DISCOUNTS.travel, value: 'Travel', icon: 'FlightTakeoff' },
  { key: DISCOUNTS.distribution, value: 'Distribution', icon: 'Loop' },
  { key: DISCOUNTS.other, value: 'Other', icon: 'MoreHoriz' },
] as const;

export const DISCOUNT_CATEGORIES_MOBILE = [
  { key: SELECT_OPTION_ALL, value: SELECT_OPTION_ALL },
  ...DISCOUNT_CATEGORIES,
] as const;

type DiscountType = keyof typeof DISCOUNT_TYPES;

export const DISCOUNT_TYPES_LABELS: Record<DiscountType, string> = {
  [DISCOUNT_TYPES.amountOff]: 'Amount Off',
  [DISCOUNT_TYPES.discount]: 'Discount %',
  [DISCOUNT_TYPES.flatPrice]: 'Flat Price',
  [DISCOUNT_TYPES.free]: 'Free',
};

export const DISCOUNT_TYPE_OPTIONS = Object.keys(DISCOUNT_TYPES).map(key => {
  return {
    label: DISCOUNT_TYPES_LABELS[key],
    value: key,
  };
});
