/* eslint-disable react/no-array-index-key */
import { ChangeEvent, Fragment, useState } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { DateTime } from 'luxon';
import { Add } from '@material-ui/icons';

import { ROLE_NAMES, RoleName, SystemRoleName } from '@jebel/constants';

import {
  useCsvParser,
  useResponsive,
  useToast,
  useUserInvitation,
  useUserRoles,
} from 'shared/hooks';
import { Button, Form, FormFieldArray } from 'shared/components/ui';
import { sendToSentry } from 'shared/utils/sentry';
import { recordError } from 'shared/utils/record';

import type { InviteMemberFormValues, MemberInvitation } from './types';
import { InviteMemberItem } from './InviteMemberItem';
import { DialogFooter, FileInputButton } from './StyledComponents';

const FILE_TYPE = '.csv';

const ERROR_GENERIC_MESSAGE = 'Something goes wrong when creating the user, please try again.';

const roleIs = (roleKey: string, role: string): boolean => {
  return ROLE_NAMES?.[roleKey] === role;
};

const createInitialValues = (): MemberInvitation => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  affiliation: '',
  affiliationDescription: '',
  graduatingYearIso: '',
});

const mapGraduatingYear = (year?: number | string): string | null => {
  if (!year) return null;
  const date = typeof year === 'string' ? DateTime.fromISO(year) : DateTime.utc(year, 1, 1);
  if (!date.isValid) return null;
  return date.toISODate();
};

const initialValues: InviteMemberFormValues = {
  members: [createInitialValues()],
};

/**
 * Modal props
 */
type InviteMemberMeta = {
  businessId: string;
};
type Props = {
  onClose: () => void;
  onSuccessfulSubmit?: () => void;
  isAdminInviting?: boolean;
  meta?: InviteMemberMeta;
  type?: RoleName | SystemRoleName;
};

const UPLOADING_CSV_MESSAGE = `Uploading CSV, please wait until the process finishes.`;
const UPLOADING_CSV_MESSAGE_KEY = `UPLOADING_CSV`;
const INVITATION_SENT_MESSAGE = 'Success! Your invitations have been sent. Thank you.';

export function InviteMemberModal({
  onClose,
  onSuccessfulSubmit,
  isAdminInviting = false,
  type = ROLE_NAMES.communityMember,
  meta,
}: Props) {
  const [uploading, setUploading] = useState(false);

  const { isMobile } = useResponsive();
  const { parseUsers } = useCsvParser();
  const { showError, showMessage, showSuccess, dismiss } = useToast();
  const { isLoading: isRolesLoading } = useUserRoles();

  const showUploadCSV = !isMobile && isAdminInviting;

  const { inviteUsers, inviteAdmins, inviteBusinessManagers } = useUserInvitation({
    refetchQueries: ['MembersWithZipList', 'Members', 'MemberStats', 'MembersList'],
  });

  const onUploadFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (!file.name?.endsWith(FILE_TYPE)) {
      showError(`Wrong file type`);
      return;
    }

    setUploading(true);
    showMessage(UPLOADING_CSV_MESSAGE, { id: UPLOADING_CSV_MESSAGE_KEY });

    Papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      error: () => {
        showError(`Wrong file type`);
        event.target.value = '';
      },
      complete: async (parsedCsv: ParseResult<any>) => {
        try {
          const formData: MemberInvitation[] = parseUsers(parsedCsv);

          if (formData.length > 0) {
            await onSubmit({ members: formData });
          }
        } catch (err) {
          recordError(err);

          if (err instanceof Error) {
            showError(err.message);
          }
        }

        setUploading(false);
        dismiss(UPLOADING_CSV_MESSAGE_KEY);
        event.target.value = '';
      },
    });
  };

  const onSubmit = async ({ members: formData }: InviteMemberFormValues) => {
    const userData = formData.map(data => ({
      ...data,
      graduatingYearIso: mapGraduatingYear(data.graduatingYearIso),
    }));

    try {
      if (userData.length === 0) {
        throw new Error('You haven`t provided needed info to invite person. Review it carefully.');
      }

      if (type && roleIs(type as string, ROLE_NAMES.businessManager)) {
        if (!meta?.businessId) {
          return;
        }

        await inviteBusinessManagers(userData, meta?.businessId);

        showSuccess(INVITATION_SENT_MESSAGE);
        onSuccessfulSubmit?.();
        return;
      }

      if (type === SystemRoleName.CommunityAdmin) {
        await inviteAdmins(userData);

        showSuccess(INVITATION_SENT_MESSAGE);
        onSuccessfulSubmit?.();
        return;
      }

      await inviteUsers(userData, isAdminInviting);

      showSuccess(INVITATION_SENT_MESSAGE);
      onSuccessfulSubmit?.();
    } catch (err) {
      sendToSentry(err);
      showError("Error! Your invitations haven't been sent");

      if (err instanceof Error) {
        showError(err.message);
        return;
      }

      showError(ERROR_GENERIC_MESSAGE);
    } finally {
      onClose();
    }
  };

  return (
    <Form<InviteMemberFormValues>
      initialValues={initialValues}
      // validationSchema={InviteMemberValidationSchema}
      onSubmit={onSubmit as any}
    >
      {form => {
        const isPending = form.isSubmitting || form.isValidating || uploading || isRolesLoading;

        return (
          <Fragment>
            <FormFieldArray name="members">
              {({ remove, push }) => (
                <Fragment>
                  {form.values.members.map((_, index) => (
                    <InviteMemberItem
                      name="members"
                      key={index}
                      index={index}
                      onRemove={() => remove(index)}
                    />
                  ))}

                  <Button
                    startIcon={<Add />}
                    onClick={() => push(createInitialValues())}
                    size="small"
                  >
                    Add another
                  </Button>
                </Fragment>
              )}
            </FormFieldArray>

            <DialogFooter>
              {showUploadCSV && (
                <FileInputButton accept={FILE_TYPE} onChange={onUploadFileChange}>
                  Upload Csv
                </FileInputButton>
              )}

              <Button onClick={onClose} disabled={isPending}>
                CANCEL
              </Button>

              <Button
                loading={isPending}
                disabled={isPending}
                disableElevation
                variant="contained"
                type="submit"
                color="primary"
              >
                SEND INVITES
              </Button>
            </DialogFooter>
          </Fragment>
        );
      }}
    </Form>
  );
}
