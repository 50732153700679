import React from 'react';
import { Divider, Box, Chip, css } from '@mui/material';

import { Icon, Typography } from 'shared/components/ui';
import { useSearchContext } from 'shared/features/search';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

const COLUMN_HEADER_SIZE = 65;

const headerContainerCss = theme => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: ${theme.spacing(2.5)}px ${theme.spacing(6)}px ${theme.spacing(2.5)}px
    ${theme.spacing(5)}px;
  background-color: ${theme.palette.grey[50]};

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px
      ${theme.spacing(1.5)}px;
  }
`;

const headerLeftCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-column-gap: ${theme.spacing(0.5)}px;
`;

const headerTitleCss = css`
  text-transform: uppercase;
  width: ${COLUMN_HEADER_SIZE}px;
  text-align: center;
  font-weight: 500;
`;

const arrowsCss = css`
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  align-content: center;
`;

const chipBoxCss = theme => css`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${theme.spacing(0.5)}px;
`;

const chipCSS =
  (selected = true) =>
  theme =>
    css`
      background-color: ${selected ? theme.palette.primary.icon : theme.palette.disabled};
      color: #ffffff;
      border-radius: ${theme.spacing(0.5)}px;
      cursor: pointer;
    `;

const headerRightCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${theme.spacing(2)}px;
  align-items: center;
  align-content: center;

  ${theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const arrowUpCss = theme => css`
  margin-bottom: -${theme.spacing(1)}px;
`;

const arrowDownCss = theme => css`
  margin-top: -${theme.spacing(1)}px;
`;

const topicBoxCss = theme => css`
  ${theme.breakpoints.down('md')} {
    display: none;
  }

  ${theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

export const CollectiveIntelligenceListHeader: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { userId } = useCurrentUser();
  const { setFilter, filter } = useSearchContext();

  const filterInfo = {
    latest: undefined,
    unread: {
      views: {
        every: {
          id: {
            not_equals: userId,
          },
        },
      },
    },
  };

  const sortBy = (type: 'latest' | 'unread') => {
    setFilter(filterInfo[type]);
  };

  return (
    <React.Fragment>
      <Box css={headerContainerCss}>
        <Box css={headerLeftCss}>
          <Box css={topicBoxCss} display="flex">
            <Typography variant="subtitle5" css={headerTitleCss}>
              Topic
            </Typography>
            <Box css={arrowsCss}>
              <Icon css={arrowUpCss} name="ArrowDropUp" />
              <Icon css={arrowDownCss} name="ArrowDropDown" />
            </Box>
          </Box>
          <Box css={chipBoxCss}>
            <Chip
              css={chipCSS(filter === filterInfo.latest)}
              disabled={loading}
              onClick={() => {
                sortBy('latest');
              }}
              label="Latest"
              size="medium"
              variant="filled"
            />
            <Chip
              css={chipCSS(!(filter === filterInfo.latest))}
              disabled={loading}
              label="Unread"
              size="medium"
              variant="filled"
              onClick={() => {
                if (filter) {
                  sortBy('latest');
                  return;
                }
                sortBy('unread');
              }}
            />
          </Box>
        </Box>
        <Box css={headerRightCss}>
          <Typography css={headerTitleCss}>replies</Typography>
          <Typography css={headerTitleCss}>views</Typography>
          <Typography css={headerTitleCss}>activity</Typography>
        </Box>
      </Box>
      <Divider />
    </React.Fragment>
  );
};
