import { useMemo } from 'react';

import { AdItemFragment, JobOffer, Maybe, OrganizationInfoFragment } from 'shared/graphql';

import { Ad } from '../components/Ad';
import { useUpdateAdClicks } from './useUpdateAdClicks';
import { useCreateImpressionMutation } from './useCreateImpressionMutation';
import { useCampaignAdsList } from './useAdsListQuery';
import { useUpdateAdLike } from './useUpdateAdLike';

interface Options {
  onContactModalOpen?(data: { jobOffer?: JobOffer; organization?: OrganizationInfoFragment }): void;
}

export function useAdsList(context?: Options) {
  const createImpression = useCreateImpressionMutation();
  const updateAdClicks = useUpdateAdClicks();

  const { data, loading } = useCampaignAdsList();
  const { addLike, removeLike, loading: updating } = useUpdateAdLike();

  const onShowedUp = async (ad: AdItemFragment) => {
    // Create the view on the client-side and calculate the rest on the server side
    // https://8base-dev.atlassian.net/browse/JEB-1412?focusedCommentId=41916
    await createImpression(ad.id as string);
  };

  const onClickAction = async (ad: AdItemFragment) => {
    if (!ad.id) {
      return;
    }

    await updateAdClicks(ad.id);
  };

  const onLikeAdd = async (adId: string) => {
    await addLike(adId);
  };

  const onReactionRemove = async (adId: string) => {
    await removeLike(adId);
  };

  const onClickName = async (organization: Maybe<OrganizationInfoFragment> | undefined) => {
    if (!organization) {
      return;
    }

    context?.onContactModalOpen?.({ organization });
  };

  const items = useMemo((): JSX.Element[] => {
    return data.map(ad => {
      const id = ad.id as string;

      return (
        <Ad
          id={id}
          key={id}
          organizationName={ad.sampaign?.advertising?.organization?.name as string}
          createdAt={ad.createdAt}
          image={ad.image}
          text={ad.primaryText as string}
          actionText={ad.callToActionText as string}
          actionLink={ad.callToActionLink as string}
          onShowedUp={() => onShowedUp(ad)}
          onClickAction={() => onClickAction(ad)}
          onLikeAdd={() => onLikeAdd(id)}
          onReactionRemove={() => onReactionRemove(id)}
          onClickName={() => onClickName(ad.sampaign?.advertising?.organization)}
          loading={loading || updating}
        />
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    items,
    loading,
  };
}
