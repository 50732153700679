import React from 'react';
import { useMutation } from '@apollo/client';

import { updatePostsCache } from 'shared/features/posts';
import { useSearchContext } from 'shared/features/search';
import {
  CollectiveIntelligenceThreadCreateMutation,
  CollectiveIntelligenceThreadCreateMutationVariables,
} from 'shared/graphql/__generated__';
import { useCurrentUser } from 'shared/hooks';

import {
  COLLECTIVE_INTELLIGENCE_THREAD_CREATE_MUTATION,
  COLLECTIVE_INTELLIGENCE_THREADS_LIST_QUERY,
  COLLECTIVE_INTELLIGENCE_THREADS_COUNT_QUERY,
} from '../queries';
import { getThreadVariables } from './useThreadsQuery';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from '../../../shared/constants';

const FIELD_NAME = 'collectiveIntelligenceThreadsList';
const MUTATION_FIELD_NAME = 'collectiveIntelligenceThreadCreate';

export const useThreadCreate = () => {
  const { userId } = useCurrentUser();
  const searchData = useSearchContext();

  const { listOptions, countOptions } = React.useMemo(() => {
    const variables = getThreadVariables({ ...searchData });

    return {
      listOptions: {
        query: COLLECTIVE_INTELLIGENCE_THREADS_LIST_QUERY,
        variables,
      },
      countOptions: {
        query: COLLECTIVE_INTELLIGENCE_THREADS_COUNT_QUERY,
        variables,
      },
    };
  }, [searchData]);

  const [postCreate, mutationData] = useMutation<
    CollectiveIntelligenceThreadCreateMutation,
    CollectiveIntelligenceThreadCreateMutationVariables
  >(COLLECTIVE_INTELLIGENCE_THREAD_CREATE_MUTATION, {
    refetchQueries: ['CollectiveIntelligenceThreadsList', 'CollectiveIntelligenceThreadsCount'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The topic has been added.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The topic hasn't been added. Try again later.`,
    },
  });

  const onThreadCreate = React.useCallback(
    async (data: CollectiveIntelligenceThreadCreateMutationVariables['data']) => {
      if (!userId) {
        return;
      }

      await postCreate({
        variables: {
          data: {
            creator: {
              connect: {
                id: userId,
              },
            },
            ...data,
          },
        },
      });
    },
    [postCreate, userId],
  );

  return {
    onThreadCreate,
    ...mutationData,
  };
};
