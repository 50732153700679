import { gql } from '@apollo/client';

const SUPPORTER_INFO_FRAGMENT = gql`
  fragment SupporterInfo on Organization {
    id
    name
    homeFeedViews
    logo {
      id
      downloadUrl
      fileId
      filename
    }
    location {
      items {
        id
        locationName
      }
    }
    owner {
      email
      lastName
      firstName
      id
    }
  }
`;

export const SUPPORTED_SCHOOLS_COUNT_QUERY = gql`
  query SupportedSchoolsCount($id: ID) {
    schoolSupportersList(filter: { supporter: { id: { equals: $id } } }) {
      count
    }
  }
`;

export const SUPPORTER_SEARCH_QUERY = gql`
  query SupporterSearch($filter: OrganizationFilter, $first: Int!, $sort: [OrganizationSort!]) {
    organizationsList(filter: $filter, first: $first, sort: $sort) {
      items {
        ...SupporterInfo
      }
    }
  }

  ${SUPPORTER_INFO_FRAGMENT}
`;
