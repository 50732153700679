import { useOrganizationProfileQuery } from 'shared/graphql';

type Options = Parameters<typeof useOrganizationProfileQuery>[0];

/** Get the organization profile data based on the `variables` from `options`. */
export function useOrganizationProfile(options: Options) {
  const { data: response, loading, refetch, fetchMore } = useOrganizationProfileQuery(options);

  const data = response?.organization;

  return {
    data,
    loading,
    refetch,
    fetchMore,
  };
}
