import { useField, useFormikContext } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { Grid, MenuItem } from '@material-ui/core';

import { FIELDS_CONFIG_USER } from 'shared/constants';
import { useUserByEmailLazyQuery } from 'shared/graphql';
import { FormSelect, FormTextField } from 'shared/components/ui';
import { composeValidators, isValidEmail, required } from 'shared/utils/form';
import { sendToSentry } from 'shared/utils/sentry';

import { Container, CustomButton, HeaderText, Header } from './StyledComponents';
import { MemberInvitation } from './types';

interface Props {
  index: number;
  name: string;
  onRemove: () => void;
}

const mapToMenuItem = (item: { label: string; value: string }) => (
  <MenuItem key={item.value} value={item.value}>
    {item.label}
  </MenuItem>
);

const ERROR_EMAIL_EXISTS_MESSAGE = 'Email address is already registered.';
/** Store a list of emails and if they can be used. */
const RESPONSE_EMAIL_CACHE: Record<string, boolean> = {};

export function InviteMemberItem(props: Props) {
  const [field] = useField<MemberInvitation>(`${props.name}.${props.index}`);
  const [fetchEmail] = useUserByEmailLazyQuery();

  const { setFieldValue } = useFormikContext();

  const FIELD_FIRST_NAME = `${field.name}.firstName`;
  const FIELD_LAST_NAME = `${field.name}.lastName`;
  const FIELD_EMAIL = `${field.name}.email`;
  const FIELD_AFFILIATION = `${field.name}.affiliation`;
  const FIELD_AFFILIATION_DESCRIPTION = `${field.name}.affiliationDescription`;
  const FIELD_GRADUATING_YEAR = `${field.name}.graduatingYearIso`;

  const onAffiliationChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setFieldValue(FIELD_AFFILIATION, event.target.value);
    setFieldValue(FIELD_AFFILIATION_DESCRIPTION, '');
    setFieldValue(FIELD_GRADUATING_YEAR, '');
  };

  const canRegisterEmail = async (email: string) => {
    if (!email) {
      return;
    }

    const cache = RESPONSE_EMAIL_CACHE[email];

    if (typeof cache === 'boolean') {
      return cache;
    }

    try {
      const response = await fetchEmail({ variables: { email } });
      const canBeUsed = !response.data?.user;

      RESPONSE_EMAIL_CACHE[email] = canBeUsed;
      return canBeUsed;
    } catch (err) {
      sendToSentry(err);
    }

    return true;
  };

  const isRegistableEmail = async (email: string) => {
    const canBeUsed = await canRegisterEmail(email);

    if (canBeUsed) {
      return;
    }

    return ERROR_EMAIL_EXISTS_MESSAGE;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateEmail = useCallback(composeValidators(isValidEmail, isRegistableEmail), []);

  return (
    <Container>
      <Header>
        <HeaderText variant="subtitle2">Invite #{props.index + 1}</HeaderText>

        {props.index > 0 && (
          <CustomButton color="secondary" onClick={props.onRemove}>
            Remove
          </CustomButton>
        )}
      </Header>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextField
            fieldProps={{ name: FIELD_FIRST_NAME, validate: required }}
            inputProps={{ label: 'First Name', variant: 'outlined', fullWidth: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            fieldProps={{ name: FIELD_LAST_NAME, validate: required }}
            inputProps={{ label: 'Last Name', variant: 'outlined', fullWidth: true }}
          />
        </Grid>
      </Grid>

      <FormTextField
        fieldProps={{ name: FIELD_EMAIL, validate: validateEmail }}
        inputProps={{ label: 'Email', variant: 'outlined' }}
      />

      <FormSelect
        fieldProps={{ name: FIELD_AFFILIATION, validate: required }}
        selectProps={{ label: 'Affiliation', variant: 'outlined', onChange: onAffiliationChange }}
      >
        {FIELDS_CONFIG_USER.affiliation.map(mapToMenuItem)}
      </FormSelect>

      {field.value.affiliation === 'other' && (
        <FormTextField
          fieldProps={{ name: FIELD_AFFILIATION_DESCRIPTION, validate: required }}
          inputProps={{ label: 'Affiliation Description', variant: 'outlined' }}
        />
      )}

      {field.value.affiliation === 'alumni' && (
        <FormSelect
          fieldProps={{ name: FIELD_GRADUATING_YEAR, validate: required }}
          selectProps={{ label: 'Graduating Year', variant: 'outlined' }}
        >
          {FIELDS_CONFIG_USER.graduatingYear.map(mapToMenuItem)}
        </FormSelect>
      )}
    </Container>
  );
}
