import { gql } from '@apollo/client';

export const HIGH_SCHOOL_FRAGMENTS = gql`
  fragment HighSchool on HighSchoolLocation {
    id
    name
    zip
    state
    city
    street
  }
`;
