import { css } from '@emotion/react';

import { Button, Icon, Typography } from 'shared/components/ui';
import { OrganizationItemFragment } from 'shared/graphql';

import { OrganizationLikeWrapper } from '../OrganizationLikeWrapper';

const ICON_SIZE = 32;
const DEFAULT_COLOR = '#295f7b';
const LIKED_COLOR = '#ef5c5c';

const likeIconCss = theme => css`
  padding-right: ${theme.spacing(0.5)}px;
  font-size: ${theme.typography.fontSize}px;
  width: 24px;
  height: 20px;
`;

const containerCss = theme => css`
  padding-right: ${theme.spacing(1)}px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0px;
  min-width: ${ICON_SIZE}px;
`;

interface Props {
  organization: OrganizationItemFragment;
}

export const LikeButton = (props: Props) => {
  return (
    <OrganizationLikeWrapper organization={props.organization}>
      {context => (
        <Button css={containerCss} onClick={context.onToggle}>
          <Icon
            css={likeIconCss}
            name="ThumbUpAlt"
            variant="filled"
            htmlColor={context.hasUserLike ? LIKED_COLOR : DEFAULT_COLOR}
          />
          <Typography color={context.hasUserLike ? 'secondary' : 'primary'} variant="subtitle5">
            {context.count}
          </Typography>
        </Button>
      )}
    </OrganizationLikeWrapper>
  );
};
