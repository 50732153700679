import React from 'react';
import { useSnackbar } from 'notistack';

import { useJobOfferRenewMutation } from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { createSnackMessage, SNACK_TYPES, SnackMessage } from 'shared/components/ui';
import { sendToSentry } from 'shared/utils/sentry';

export const useJobOfferRenew = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [renewJobOfferMutation, { loading }] = useJobOfferRenewMutation({
    refetchQueries: ['CareerSearch'],
  });

  const renewJobOffer = async (id: string) => {
    try {
      await renewJobOfferMutation({
        variables: {
          id,
        },
        context: {
          [SNACKBAR_SUCCESS_MESSAGE]: 'Your Job Post has been renewed',
          [SNACKBAR_ERROR_MESSAGE]: `Your Job Post hasn't been renewed`,
        },
      });
    } catch (e: any) {
      sendToSentry(`Error via renew job offer ${e}`);
      enqueueSnackbar(e.message, {
        content: createSnackMessage(SNACK_TYPES.error),
      });
    }
  };

  return {
    renewJobOffer,
    loading,
  };
};
