import {
  ChipProps,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { Fragment, useMemo, useState } from 'react';
import { KeyboardArrowDown as ArrowDownIcon } from '@material-ui/icons';

import { Action, Container, Content } from './ChooseStatusChip.styles';
import { Option } from './ChooseStatusChip.types';
import { usePopoverState } from 'shared/hooks';

export interface ChooseStatusChipProps {
  label?: string;
  color?: string;
  size?: ChipProps['size'];

  options?: Option[];
}

export function ChooseStatusChip(props: ChooseStatusChipProps) {
  const [loading, setLoading] = useState(false);

  const { show: open, anchor, open: openMenu, close: closeMenu } = usePopoverState();

  const options = props.options ?? [];
  const isEmpty = options.length === 0;

  const handleClick = async (option: Option) => {
    setLoading(true);
    closeMenu();

    try {
      await option.onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container>
        <Content size={props.size} background={props.color} label={props.label ?? 'Unknown'} />

        <Action size="small" disabled={isEmpty || loading} onClick={openMenu}>
          {loading ? <CircularProgress size="1rem" /> : <ArrowDownIcon fontSize="small" />}
        </Action>
      </Container>

      <Menu
        open={open}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closeMenu}
      >
        {options.map(option => (
          <MenuItem dense key={option.id ?? option.value} onClick={() => handleClick(option)}>
            {Boolean(option.icon) && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText>{option.label ?? option.value}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
