import React from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';

import { Button, Icon, Typography } from 'shared/components/ui';

const CONTAINER_MAX_HEIGHT = 165;

type DescriptionProps = {
  description: string | null | undefined;
};

const Container = styled.div<{ isShowMoreButton: boolean }>`
  max-height: ${props => (props.isShowMoreButton ? `${CONTAINER_MAX_HEIGHT}px` : '100%')};
  overflow: ${props => (props.isShowMoreButton ? `hidden` : 'inherit')};
`;

const moreButtonContainerCss = theme => css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: initial;
  position: relative;
`;

const moreTextCss = theme => css`
  color: ${theme.palette.secondary.light};
`;

const overlayCss = theme => css`
  width: 100%;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  height: 20px;
  position: absolute;
  top: -30px;
`;

export const OrganizationDescription = ({ description }: DescriptionProps) => {
  const theme: any = useTheme();
  const [height, setHeight] = React.useState<number>(0);
  const [isOpenDescription, setIsOpenDesription] = React.useState<boolean>(false);

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const isShowMoreButton = React.useMemo(
    () => height > CONTAINER_MAX_HEIGHT && !isOpenDescription,
    [height, isOpenDescription],
  );

  const onOpenDescription = React.useCallback(() => {
    setIsOpenDesription(true);
  }, []);

  return (
    <React.Fragment>
      <Container isShowMoreButton={isShowMoreButton} ref={measuredRef}>
        {description?.split('\n').map(text => (
          <Typography>{text}</Typography>
        ))}
      </Container>
      {isShowMoreButton && (
        <Box
          position="relative"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box css={overlayCss} />
          <Button onClick={onOpenDescription} css={moreButtonContainerCss}>
            <Typography variant="subtitle5" css={moreTextCss}>
              Show more
            </Typography>
            <Icon
              name="KeyboardArrowDown"
              variant="filled"
              style={{ fill: theme.palette.secondary.light }}
            />
          </Button>
        </Box>
      )}
      <Box mb={3} mt={isShowMoreButton ? 0 : 3}>
        <Divider />
      </Box>
    </React.Fragment>
  );
};
