import { Box } from '@mui/material';

import { SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';

import { LegacyFilter } from './LegacyFilter';

export function LegacyDetailsSidebar() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <LegacyFilter />
      <CommunitySidebar items={[SUPPORTERS]} />
    </Box>
  );
}
