import { useMemo } from 'react';
import { Badge, Box, CircularProgress, css } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { AdminSidebarNavigationItemsList, SidebarExternalLinkItem } from 'shared/types';
import {
  sortExternalLinks,
  useCurrentUser,
  useSchoolConfiguration,
  useSidebarMenuItems,
} from 'shared/hooks';
import { ADMIN_SIDEBAR_NAVIGATION_ITEMS, ROUTES } from 'shared/constants';
import { CenterLayout } from 'shared/components/layouts';
import { InboxUnreadMessagesQuery, InboxUnreadMessagesQueryVariables } from 'shared/graphql';
import { INBOX_UNREAD_MESSAGES } from 'features/inbox/queries';
import logo from 'shared/assets/8base-logo-mobile.svg';
import { useGlobalState } from 'providers';

import { useUnreadSidebarElementsQuery } from '../../hooks';
import { SidebarAbout } from '../SidebarAbout';
import {
  Container,
  ExternalLinkItem,
  NavItem,
  NavItemIcon,
  NavItemText,
  NavList,
} from './SidebarNavigationMobile.styles';
import { SidebarGroups } from '../SidebarGroups';

const logoCSS = css`
  display: flex;
  align-items: center;
  color: white;
  align-self: flex-end;
`;

const logoImageCss = css`
  width: 50px;
  margin-left: 5px;
`;

export function SidebarNavigationMobile() {
  const { user, isAdmin } = useCurrentUser();
  const { sidebarMenuItems, isLoading } = useSidebarMenuItems();
  const { leftMenuDrawer: menu } = useGlobalState();
  const { sidebarUnreadElementsList } = useUnreadSidebarElementsQuery();
  const { configuration } = useSchoolConfiguration();
  const {
    location: { pathname },
    push: navigate,
  } = useHistory();

  const { data: unreadMessages } = useQuery<
    InboxUnreadMessagesQuery,
    InboxUnreadMessagesQueryVariables
  >(INBOX_UNREAD_MESSAGES, {
    skip: !user?.id,
    variables: { id: user?.id as string },
    fetchPolicy: 'cache-first',
  });

  const isAdminRoute = useMemo(() => pathname.startsWith('/admin'), [pathname]);

  const navigationItems: AdminSidebarNavigationItemsList = useMemo(() => {
    if (isAdmin && isAdminRoute) {
      return ADMIN_SIDEBAR_NAVIGATION_ITEMS;
    }

    return sidebarMenuItems;
  }, [isAdmin, isAdminRoute, sidebarMenuItems]);

  const externalLinks: SidebarExternalLinkItem[] = useMemo(() => {
    const externals = configuration?.externalLinks?.items ?? [];

    const links = sortExternalLinks(externals.filter(item => item.isActive));

    return links.map(item => ({
      id: item.id as string,
      name: item.name as string,
      link: item.link as string,
    }));
  }, [configuration]);

  const unreadMessagesCount = unreadMessages?.inboxMessagesList?.count ?? 0;

  const openRoute = (route: string) => {
    menu.close();
    navigate(route);
  };

  if (isLoading) {
    return (
      <Container visible={menu.isOpened}>
        <CenterLayout>
          <CircularProgress />
        </CenterLayout>
      </Container>
    );
  }

  return (
    <Container visible={menu.isOpened}>
      <NavList>
        {navigationItems.map(item => {
          const isSelectedRoute = Boolean(pathname.startsWith(item.route));

          const isMailRoute =
            item.route === ROUTES.admin.mail.index || item.route === ROUTES.user.mail.index;

          const badgeCount = isMailRoute
            ? unreadMessagesCount
            : sidebarUnreadElementsList[item.name];

          return (
            <NavItem
              key={item.route}
              onClick={() => openRoute(item.route)}
              isSelected={isSelectedRoute}
            >
              <Badge
                max={99}
                showZero={false}
                color="secondary"
                badgeContent={badgeCount}
                invisible={!badgeCount}
              >
                <NavItemIcon name={item.icon} />
              </Badge>

              <NavItemText>{item.name}</NavItemText>
            </NavItem>
          );
        })}

        {externalLinks.map(item => (
          <ExternalLinkItem key={item.id} href={item.link} target="_blank">
            <NavItemIcon name="Launch" />
            <NavItemText>{item.name}</NavItemText>
          </ExternalLinkItem>
        ))}
      </NavList>

      <SidebarGroups />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SidebarAbout />

        <Box css={logoCSS}>
          Built on <img src={logo} css={logoImageCss} alt="8base logo" />
        </Box>
      </Box>
    </Container>
  );
}
