/* eslint-disable @typescript-eslint/no-explicit-any */
import { csvToBase64 } from '../csv';
/** @deprecated Do not use this. */
export function isBrowser() {
    return globalThis.window && typeof globalThis.window !== 'undefined';
}
/** @deprecated Use `writeCSV` instead. */
export const formatDataToCsv = (config) => {
    let csvContent = '';
    if (config.titles) {
        csvContent += `${config.titles.join(',')}\n`;
    }
    switch (config.type) {
        case 'object-array':
            config.data.forEach(dataItem => {
                const keys = Object.keys(dataItem);
                keys.forEach((key, i) => {
                    const value = dataItem[key];
                    switch (typeof value) {
                        case 'string':
                            // FIX BUG WITH COMMA IN STRING https://stackoverflow.com/a/34722089
                            csvContent += value.includes(',') ? `"${value}"` : value;
                            break;
                        case 'number':
                            csvContent += value;
                            break;
                        case 'boolean':
                            csvContent += value;
                            break;
                        case 'object':
                            // Array
                            if (Array.isArray(value)) {
                                // you can add more logic
                                csvContent += value.join(',');
                                break;
                            }
                            // TODO: date
                            // Object
                            csvContent += value
                                ? `${`{${Object.keys(value).map(key => `${key}: ${value}`)}}`}`
                                : '';
                            break;
                        default:
                            csvContent += value;
                            break;
                    }
                    csvContent += i < keys.length - 1 ? ',' : '';
                });
                csvContent += '\n';
            });
            break;
        default:
            break;
    }
    return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
};
export function downloadCSV(csv, filename) {
    if (!globalThis.document) {
        return;
    }
    const downloadLink = globalThis.document.createElement('a');
    downloadLink.href = csvToBase64(csv);
    downloadLink.download = filename;
    downloadLink.click();
    downloadLink.remove();
}
/** @deprecated Use `downloadCSV` instead. */
export const downloadCsv = (base64String, fileName) => {
    if (!globalThis.document) {
        return;
    }
    const downloadLink = globalThis.document.createElement('a');
    downloadLink.href = base64String;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
};
