export const ORGANIZATIONS_SORT = {
    random: 'Random',
    distance: 'Distance',
    alphabetical: 'Alphabetical',
};
export var OrganizationStatus;
(function (OrganizationStatus) {
    OrganizationStatus["Active"] = "active";
    OrganizationStatus["InvitationSent"] = "invitationSent";
    OrganizationStatus["PendingApproval"] = "pendingApproval";
    OrganizationStatus["Inactive"] = "inactive";
})(OrganizationStatus || (OrganizationStatus = {}));
/** @deprecated Use `OrganizationStatus` instead. */
export const ORGANIZATION_STATUS = {
    active: OrganizationStatus.Active,
    invitationSent: OrganizationStatus.InvitationSent,
    inactive: OrganizationStatus.Inactive,
    pendingApproval: OrganizationStatus.PendingApproval,
};
export const ORGANIZATION_STATUS_FILTER = {
    active: 'Active',
    invitationSent: 'Invitation Sent',
    inactive: 'Inactive',
    pendingApproval: 'Pending Approval',
};
/** @deprecated Use `OrganizationManagerStatus` instead. */
export const ORGANIZATION_MANAGER_STATUS = {
    active: 'active',
    invited: 'invited',
    inactive: 'inactive',
};
export var OrganizationManagerStatus;
(function (OrganizationManagerStatus) {
    OrganizationManagerStatus["Active"] = "active";
    OrganizationManagerStatus["Invited"] = "invited";
    OrganizationManagerStatus["Inactive"] = "inactive";
})(OrganizationManagerStatus || (OrganizationManagerStatus = {}));
