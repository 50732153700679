import { Fragment, useState } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { AboutModal } from 'shared/components/dialogs';
import { POWERED_BY_JEBEL_LOGO } from 'assets/images';

const logoBoxCSS = css`
  cursor: pointer;
  display: grid;
  justify-items: center;
`;

const IMAGE_HEIGHT = 32;

export function SidebarAbout() {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onOpenAboutModal = () => {
    setIsOpenModal(true);
  };

  const onCloseAboutModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Fragment>
      <AboutModal isOpen={isOpenModal} onClose={onCloseAboutModal} />

      <Box css={logoBoxCSS} onClick={onOpenAboutModal}>
        <img src={POWERED_BY_JEBEL_LOGO} height={IMAGE_HEIGHT} alt="Powered by Jebel" />
      </Box>
    </Fragment>
  );
}
