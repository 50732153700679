const YOUTUBE_URL_REGEX = /^(?:(?:http|https):\/\/)?(?:www\.youtube\.com|youtu\.?be)\/(?:(?:watch\?v=)?(?<id>[a-zA-Z0-9-]{11}))/i;
/**
 * Extract the ID from a YouTube URL.
 * @example `https://www.youtube.com/watch?v=BHACKCNDMW8`
 * @example `https://youtu.be/4rrXR6n0RTY`
 * @returns Identifier from the given URL
 */
export function extractYoutubeIdentifier(url) {
    var _a, _b, _c;
    const matches = url.match(YOUTUBE_URL_REGEX);
    if (!matches) {
        return null;
    }
    return (_c = (_b = (_a = matches.groups) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : matches[1]) !== null && _c !== void 0 ? _c : null;
}
/** Check a given `url` as valid video URL of YouTube. */
export function isYouTubeVideoURL(url) {
    return YOUTUBE_URL_REGEX.test(url);
}
