import _ from 'lodash';

import { POST_REPORT_STATUS } from '@jebel/constants';

import { LegacyFeedPostFilter } from 'shared/graphql/__generated__';

const getLegacyDate = (dateIso: string | null | undefined) => {
  if (_.isNil(dateIso)) return undefined;
  return dateIso.split('T')[0];
};

export const getLegacyFeedPostsSearchFilter = (
  inputSearchQuery: string,
  pinnedPostsFilter: boolean,
  year: string | undefined,
  month: string | undefined,
  legacyStartDate?: string | null | undefined,
  legacyEndDate?: string | null | undefined,
  authorId?: string | undefined | null,
): LegacyFeedPostFilter => {
  return {
    _fullText: inputSearchQuery || undefined,
    reportStatus: { equals: POST_REPORT_STATUS.postAllowed },
    isPinned: pinnedPostsFilter ? { equals: true } : undefined,
    ...(!_.isNil(legacyStartDate) &&
      !_.isNil(legacyEndDate) && {
        AND: [
          { legacyDate: { gte: getLegacyDate(legacyStartDate) } },
          { legacyDate: { lte: getLegacyDate(legacyEndDate) } },
        ],
      }),
    postsMonths: {
      month: _.isFinite(Number(month)) ? { equals: Number(month) } : undefined,
      legacyYears: _.isFinite(Number(year))
        ? {
            legacyYear: { equals: Number(year) },
          }
        : undefined,
    },
    author: authorId ? { id: { equals: authorId || '' } } : undefined,
  };
};
