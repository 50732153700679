import { useMemo } from 'react';
import { EChartsOption as ChartOptions } from 'echarts';
import Chart from 'echarts-for-react';
import { useTheme } from '@emotion/react';

import { Container } from './BarChart.styles';
import { BarChartProps } from './BarChart.types';
import { createBarChartOptions } from './createBarChartOptions';

export function BarChart(props: BarChartProps) {
  const theme = useTheme();

  const options = useMemo<ChartOptions>(() => {
    return createBarChartOptions(props, theme);
  }, [props, theme]);

  return (
    <Container>
      <Chart option={options} notMerge lazyUpdate />
    </Container>
  );
}
