import { useMemo } from 'react';

import { DiscountSort, DiscountsListQueryVariables, useDiscountsListQuery } from 'shared/graphql';
import { chooseRamdomSorts } from 'shared/utils/chooseRandomSorts';

type Options = Parameters<typeof useDiscountsListQuery>[0] & {
  variables?: Omit<DiscountsListQueryVariables, 'sort'>;
};

/**
 * Generates a list of discounts sorted randomly every time that's executed.
 * @url https://8base-dev.atlassian.net/browse/JEB-1457
 * @returns List of discounts sorted randomly.
 */
export function useRandomDiscounts(options?: Options) {
  const sort = useMemo(() => generateSorts(), []);

  const { data: response, loading } = useDiscountsListQuery({
    ...options,

    variables: {
      ...options?.variables,

      sort,
    },
  });

  const data = response?.discounts?.items ?? [];
  const count = response?.discounts?.count ?? 0;

  return {
    data,
    count,
    loading,
  };
}

function generateSorts(): DiscountSort[] {
  const fields: Array<keyof DiscountSort> = [
    'title',
    'terms',
    'category',
    'expirationDate',
    'createdAt',
    'updatedAt',
  ];

  return chooseRamdomSorts<DiscountSort>(fields);
}
