import styled from '@emotion/styled';

import { CenterLayout } from 'shared/components/layouts';

// Container

export const Container = styled(CenterLayout)`
  flex-direction: column;
  text-align: center;
  gap: 2rem;
`;
