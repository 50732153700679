import { createFilterBuilder } from '@jebel/utils';

import { GroupFilter } from 'shared/graphql';

import { createUserFilter } from './createUserFilter';

interface Options {
  initial?: GroupFilter;

  search?: string;
}

export function createGroupFilter(options?: Options) {
  const filter = createFilterBuilder<GroupFilter>(options?.initial);

  if (options?.search) {
    const userFilter = createUserFilter({ search: options.search });
    const searchFilter = createFilterBuilder<GroupFilter>();

    searchFilter
      .or({ title: { contains: options.search } })
      .or({ description: { contains: options.search } })
      .or({ members: { some: userFilter } });

    filter.and(searchFilter);
  }

  return filter.build();
}
