import { Fragment, useMemo } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Button, Form, FormTextField, Modal } from 'shared/components/ui';
import { SchoolConfigurationExternalLink } from 'shared/graphql';
import { composeValidators, isValidLink, required } from 'shared/utils/form';

const commonButtonsCSS = (theme: any) => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(2)}px;
`;

const linkCSS = (theme: any) => css`
  margin-top: ${theme.spacing(2)}px;
`;

interface Values {
  name?: string;
  link?: string;
}

type ExternalLinkEditModalProps = {
  item?: SchoolConfigurationExternalLink;
  isOpen: boolean;

  onClose(): void;
  onSave(item: Values): void;
};

export function ExternalLinkEditModal({
  item,
  isOpen,
  onClose,
  onSave,
}: ExternalLinkEditModalProps) {
  const initials = useMemo(() => {
    return {
      id: item?.id,
      name: item?.name,
      link: item?.link,
      isActive: item?.isActive,
      // eslint-disable-next-line no-underscore-dangle
      __typename: item?.__typename,
    };
  }, [item]);

  return (
    <Modal
      dialogProps={{
        open: isOpen,
        onClose,
        fullWidth: true,
      }}
      titleProps={{
        title: item ? `Edit label — ${item?.name}` : 'Create label',
      }}
      isVisibleDivider
    >
      <Form onSubmit={onSave} initialValues={initials}>
        <Fragment>
          <Box display="grid" gridTemplateRows="auto" gridGap={2} my={2}>
            <FormTextField
              inputProps={{
                label: 'LABEL NAME',
                variant: 'outlined',
              }}
              fieldProps={{
                name: 'name',
                validate: required,
              }}
            />
            <FormTextField
              inputProps={{
                css: linkCSS,
                label: 'LINK URL',
                variant: 'outlined',
              }}
              fieldProps={{
                name: 'link',
                validate: composeValidators(isValidLink, required),
              }}
            />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button css={commonButtonsCSS} onClick={onClose} size="medium">
              CANCEL
            </Button>
            <Button css={commonButtonsCSS} disableElevation variant="contained" type="submit">
              SAVE CHANGES
            </Button>
          </Box>
        </Fragment>
      </Form>
    </Modal>
  );
}
