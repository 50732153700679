import { captureException, captureMessage } from '@sentry/browser';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

/** @deprecated Use `recordError` instead. */
export function sendToSentry(err: unknown, info?: { componentStack: string }) {
  if (!IS_PRODUCTION) {
    console.error(err);
    return;
  }

  if (typeof err === 'string') {
    captureMessage(err);
    return;
  }

  captureException(err);
}
