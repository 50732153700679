import { Dispatch, SetStateAction } from 'react';

import { fileManyRelations } from '@jebel/utils';

import { PostModalCreate, PostModalCreateForm } from 'shared/features/posts';
import { sendToSentry } from 'shared/utils/sentry';
import { HomeFeedPostCreateInput } from 'shared/graphql';
import { prepareMentionsCreateInput } from 'shared/features/mentions/utils';

import { useHomeFeedPostCreate } from '../../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: () => void | undefined;
  initialValues?: PostModalCreateForm;
  setIsPosting: Dispatch<SetStateAction<boolean>>;
}

export function HomeFeedPostModalCreate({
  isOpen,
  onClose,
  onCreated,
  initialValues,
  setIsPosting,
}: Props) {
  const { create: createPost } = useHomeFeedPostCreate();

  const handleSubmit = async (form: PostModalCreateForm) => {
    setIsPosting(true);

    try {
      const data: HomeFeedPostCreateInput = {
        text: form.text ?? '',
        commentsAllowed: Boolean(form.commentsAllowed),
        isPinned: Boolean(form.isPinned),
      };

      if (form.media) {
        data.media = fileManyRelations(form.media);
      }

      if (form.mentions) {
        const createMentions = form.mentions?.map(prepareMentionsCreateInput);
        data.mentions = { create: createMentions };
      }

      await createPost(data);

      onCreated?.();
      onClose();
    } catch (err) {
      sendToSentry(err);
    }

    setIsPosting(false);
  };

  return (
    <PostModalCreate
      initialValues={initialValues}
      onPostCreate={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      withPinned={false}
    />
  );
}
