import { useHistory } from 'react-router-dom';

import { useAppAuth } from '../../../providers/useAppAuth';
import { FormDataRecovery, ResetLayout } from '../../../shared/components/layouts/ResetLayout';
import { APP_URL } from '../../../shared/constants';

export function PasswordResetPage() {
  const history = useHistory();
  const { forgotPassword } = useAppAuth();

  const handleSubmit = async ({ email = '' }: FormDataRecovery) => {
    const blurredEmail = await forgotPassword(email);

    if (blurredEmail) {
      history.push({
        pathname: APP_URL.public.recoverySent,
        state: {
          blurredEmail,
        },
      });
    }
  };

  return <ResetLayout onSubmit={handleSubmit} />;
}
