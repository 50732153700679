import React from 'react';
import { useMutation } from '@apollo/client';

import {
  RedeemDiscountMutation,
  RedeemDiscountMutationVariables,
} from 'shared/graphql/__generated__';

import { REDEEM_DISCOUNT_MUTATION } from '../query';

export const useDiscountsActivationsUpdate = () => {
  const [increaseRedeemCount, { loading }] = useMutation<
    RedeemDiscountMutation,
    RedeemDiscountMutationVariables
  >(REDEEM_DISCOUNT_MUTATION, { refetchQueries: ['DiscountsList'] });

  const redeemDiscount = React.useCallback(
    async (id: string | undefined | null) => {
      if (!id) return;

      await increaseRedeemCount({ variables: { id } });
    },
    [increaseRedeemCount],
  );
  return { loading, redeemDiscount };
};
