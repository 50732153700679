import { Fragment, useMemo, useState } from 'react';
import { CircularProgress, Divider, Box, Avatar, Link, css } from '@mui/material';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import pluralize from 'pluralize';
import { capitalize } from 'lodash';
import { Theme } from '@material-ui/core';
import { Public } from '@material-ui/icons';

import { ORGANIZATION_MANAGER_STATUS } from '@jebel/constants';
import { processFilestackUrlSrcSet } from '@jebel/utils';

import { CenterLayout } from 'shared/components/layouts';
import { Typography, Button, MediaGallery } from 'shared/components/ui';
import { ReedemDiscountModal } from 'features/discounts/components/ReedemDiscountModal';
import { DiscountItem } from 'features/discounts/components/DiscountItem';
import { DiscountListItemFragment, UserKeyFilter } from 'shared/graphql';
import { useCurrentUser, useResponsive, useSchoolConfiguration } from 'shared/hooks';
import { Separator } from 'shared/components/symbols';
import { ROUTES } from 'shared/constants';

import { useOrganizationProfile } from '../../hooks';
import { OrganizationLocations } from './OrganizationLocations';
import { OrganizationDescription } from './OrganizationDescription';
import { LikeButton } from '../LikeButton';
import { OrganizationJobOffers } from './OrganizationJobOffers';

const ORGANIZATION_AVATAR_SIZE = 80;
const SUPPORTERS_ICON_SIZE = 25;

const pageContainerCss = theme => css`
  display: grid;
  grid-template-columns: 2fr;
  width: 100%;
  grid-gap: ${theme.spacing(2.5)}px;
`;

const leftAreaCSS = css`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const contentContainerCss = theme => css`
  display: grid;
  width: 100%;
  flex-direction: column;
  grid-row-gap: ${theme.spacing(2)}px;
`;

const organizationInfoCSS = theme => css`
  background-color: #fff;
  padding: ${theme.spacing(3.75)}px;

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(2.5)}px;
  }
`;

const linkTextCSS = (theme: Theme) => css`
  color: ${theme.palette.secondary.light};
  cursor: pointer;
`;

const memberNameCSS = (theme: Theme) => css`
  ${linkTextCSS(theme)}

  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
  }
`;

const organizationHeadingCss = theme => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: ${theme.spacing(2.5)}px 0px;
  align-items: flex-start;
`;

const organizationAvatarCss = theme => css`
  border-radius: ${theme.spacing(0.5)}px;
  height: ${ORGANIZATION_AVATAR_SIZE}px;
  width: ${ORGANIZATION_AVATAR_SIZE}px;
  border: 1px solid #dbdbdb;
  margin-right: ${theme.spacing(2)}px;
`;

const avatarContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const organizationNameCss = theme => css`
  font-size: ${theme.typography.fontSize * 2}px;
  color: ${theme.palette.primary.dark};
  margin-right: ${theme.spacing(1.25)}px;

  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
  }
`;

const supportersIconCss = theme => css`
  height: ${SUPPORTERS_ICON_SIZE}px;
  width: ${SUPPORTERS_ICON_SIZE}px;
  min-width: ${SUPPORTERS_ICON_SIZE}px;
  min-height: ${SUPPORTERS_ICON_SIZE}px;
  margin-right: ${theme.spacing(1)}px;
`;

const locationTextCss = theme => css`
  font-size: ${theme.typography.fontSize + 2}px;
  color: ${theme.palette.text.secondary};
  font-weight: 500;
`;

const linkContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const contentBoxCss = theme => css`
  background-color: #fff;
  padding: ${theme.spacing(3)}px;
`;

const discountListCss = theme => css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: ${theme.spacing(2)}px;
`;

const sectionTitleCss = theme => css`
  color: ${theme.palette.primary.icon};
  margin-bottom: ${theme.spacing(2)}px;
`;

const careerServicesBoxCss = theme => css`
  grid-gap: ${theme.spacing(3)}px;
  display: grid;
  background-color: #fff;
  padding: ${theme.spacing(3)}px;
`;

export function OrganizationDetailsContent() {
  const { isMobile } = useResponsive();
  const { push: navigate, goBack } = useHistory();
  const { configuration } = useSchoolConfiguration();
  const { userId } = useCurrentUser();
  const { id } = useParams<{ id: string }>();

  const { data: organization, loading } = useOrganizationProfile({ variables: { id } });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discount, setDiscount] = useState<DiscountListItemFragment>();

  const locations = useMemo(() => {
    return organization?.location?.items || [];
  }, [organization]);

  const onCloseDiscount = () => {
    setDiscount(undefined);
    setIsModalOpen(false);
  };

  const onOpenDiscount = (discount: DiscountListItemFragment) => () => {
    setDiscount(discount);
    setIsModalOpen(true);
  };

  const organizationLink = useMemo(() => {
    const location = locations.find(location => Boolean(location.link));

    if (!location || !location.link) {
      return;
    }

    const hasPrefix = location.link?.includes('://');

    if (hasPrefix) {
      return location.link;
    }

    return `https://${location.link}`;
  }, [locations]);

  const isManager = useMemo(() => {
    const managers = organization?.managers?.items ?? [];

    const isOwner = organization?.owner?.id === userId;

    const isManager = managers.some(
      manager =>
        manager?.user?.id === userId && manager.status === ORGANIZATION_MANAGER_STATUS.active,
    );

    return isOwner || isManager;
  }, [organization, userId]);

  const address = useMemo(() => {
    const [location] = locations;
    const [address] = location?.address?.items ?? [];

    if (!address) {
      return;
    }

    const zip = address?.zip;
    const state = address?.state;
    const city = address?.city;

    const paths = [state, city, zip];
    return paths.filter(Boolean).join(', ');
  }, [locations]);

  const industry = useMemo(() => {
    const industry = organization?.industry ?? undefined;

    if (!industry) {
      return;
    }

    return capitalize(industry);
  }, [organization]);

  const discounts = useMemo(() => organization?.discounts?.items ?? [], [organization]);
  const offers = useMemo(() => organization?.jobOffers?.items ?? [], [organization]);

  const activeUsers = useMemo(() => {
    const managers = organization?.managers?.items ?? [];
    return managers.filter(manager => manager.status === ORGANIZATION_MANAGER_STATUS.active);
  }, [organization]);

  const activeAdmins = activeUsers.filter(activeManager => !!activeManager?.isAdmin) || [];
  const activeManagers = activeUsers.filter(activeManager => !activeManager?.isAdmin) || [];

  const openMemberProfile = (member: UserKeyFilter | null | undefined) => {
    if (!member?.id) {
      return;
    }

    const route = generatePath(ROUTES.user.explorer.profile, { id: member.id });

    // // Open the profile of the organization member
    // // https://8base-dev.atlassian.net/browse/JEB-1588
    navigate(route);
  };

  if (!organization || loading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  return (
    <Box css={pageContainerCss}>
      {isModalOpen && (
        <ReedemDiscountModal isOpen={isModalOpen} onClose={onCloseDiscount} discount={discount} />
      )}

      <Box css={leftAreaCSS}>
        <Box css={contentContainerCss}>
          <Box css={organizationInfoCSS}>
            <Button css={linkTextCSS} color="primary" onClick={goBack}>
              Go back
            </Button>
            <Box>
              <Box css={organizationHeadingCss}>
                <Avatar
                  css={organizationAvatarCss}
                  srcSet={processFilestackUrlSrcSet(organization?.logo?.downloadUrl || '', {
                    resize: {
                      width: ORGANIZATION_AVATAR_SIZE * 2,
                      height: ORGANIZATION_AVATAR_SIZE * 2,
                      fit: 'crop',
                    },
                  })}
                  src={organization?.logo?.downloadUrl || ''}
                  variant="square"
                />

                <Box>
                  <Box css={avatarContainerCss}>
                    <Typography css={organizationNameCss}>{organization?.name}</Typography>
                    {(organization?.schoolSupporter?.count || 0) > 0 && (
                      <Avatar
                        css={supportersIconCss}
                        srcSet={processFilestackUrlSrcSet(
                          configuration?.images?.logo?.downloadUrl || '',
                          {
                            resize: {
                              width: SUPPORTERS_ICON_SIZE * 2,
                              height: SUPPORTERS_ICON_SIZE * 2,
                              fit: 'crop',
                            },
                          },
                        )}
                        src={configuration?.images?.logo?.downloadUrl || ''}
                      />
                    )}
                  </Box>

                  <Box display="inline">
                    <Typography css={locationTextCss} display="inline">
                      {address}

                      {!isMobile && <LikeButton organization={organization} />}
                    </Typography>
                  </Box>

                  {isMobile && (
                    <Box display="flex" alignItems="center">
                      <Typography css={locationTextCss}>{industry}</Typography>
                      <Box px={1}>
                        <Separator diameter="4px" color="#8B8B8B" />
                      </Box>
                      <LikeButton organization={organization} />
                    </Box>
                  )}

                  {organizationLink && (
                    <Box css={linkContainerCss}>
                      <Public color="secondary" />

                      <Link
                        css={linkTextCSS}
                        underline="none"
                        color="primary"
                        href={organizationLink}
                        target="_blank"
                      >
                        {organizationLink}
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Box my={3}>
              <Divider />
            </Box>

            <OrganizationDescription description={organization?.description} />

            {!!activeAdmins.length && (
              <Fragment>
                <Box>
                  <Typography variant="h6">Admins</Typography>

                  <Box display="grid" gridTemplateColumns="1fr 1fr">
                    {activeAdmins.filter(Boolean).map(admin => (
                      <Box key={admin?.id}>
                        <Typography
                          css={memberNameCSS}
                          onClick={() => openMemberProfile(admin?.user)}
                        >
                          {admin?.user?.firstName} {admin?.user?.lastName}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>
              </Fragment>
            )}

            {!!activeManagers.length && (
              <Fragment>
                <Box>
                  <Typography variant="h6">Managers</Typography>

                  <Box display="grid" gridTemplateColumns="1fr 1fr">
                    {activeManagers.map(manager => (
                      <Box key={manager?.id}>
                        <Typography
                          css={memberNameCSS}
                          onClick={() => openMemberProfile(manager?.user)}
                        >
                          {manager?.user?.firstName} {manager?.user?.lastName}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>
              </Fragment>
            )}

            {!!organization?.location?.items?.length && (
              <OrganizationLocations locations={organization?.location?.items} />
            )}

            {organization?.media?.items && (
              <MediaGallery media={organization?.media?.items || []} />
            )}
          </Box>

          {discounts.length > 0 && (
            <Box css={contentBoxCss}>
              <Typography variant="subtitle5" css={sectionTitleCss}>
                {pluralize('Discount', discounts.length)} ({discounts.length})
              </Typography>

              <Box css={discountListCss}>
                {discounts.map(discount => (
                  <DiscountItem
                    discount={discount}
                    key={discount.id}
                    onReedem={onOpenDiscount(discount)}
                  />
                ))}
              </Box>
            </Box>
          )}

          {offers.length > 0 && (
            <Box css={careerServicesBoxCss}>
              <Typography variant="subtitle5" css={sectionTitleCss}>
                Career {pluralize('Service', organization?.jobOffers?.items?.length)} (
                {organization?.jobOffers?.items?.length})
              </Typography>

              <OrganizationJobOffers
                offers={organization?.jobOffers?.items}
                isManager={isManager}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
