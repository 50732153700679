import _ from 'lodash';
import React from 'react';

import { ROUTES, USER_SIDEBAR_NAVIGATION_ITEMS } from 'shared/constants';
import type {
  CurrentUserFragment,
  RoleAddonInfoFragment,
  SchoolConfigurationLayout,
} from 'shared/graphql/__generated__';
import {
  sortLabels,
  useCurrentUser,
  useSchoolConfiguration,
  useUserRolesAddons,
} from 'shared/hooks';
import type { SidebarNavigationItemList } from 'shared/types';

import { useAppAuth } from '../../providers/useAppAuth';
import { matchRoute } from '../components/ui';

export const getAccessibleLayouts = (
  user: CurrentUserFragment,
  rolesAddons: RoleAddonInfoFragment[],
): SchoolConfigurationLayout[] => {
  const userRolesAddonsIds = (user.rolesAddons?.items || []).map(roleAddon => roleAddon.id);
  const filteredRoles = rolesAddons.filter(roleAddon => userRolesAddonsIds.includes(roleAddon?.id));
  const allAccessibleLayouts = _.flatten(
    filteredRoles.map(role => role.accessibleLayouts?.items || []),
  );

  return _.uniqBy(allAccessibleLayouts, 'id')
    .concat({ route: ROUTES.user.search.index })
    .concat({ route: ROUTES.user.settings.index });
};

const mergeNavigationItems = (
  configurationItems: SchoolConfigurationLayout[],
  accessibleLayoutsIds: string[],
  staticItems: SidebarNavigationItemList,
): SidebarNavigationItemList => {
  return sortLabels(configurationItems)
    .filter(({ isActive = false, route = '', isAlwaysShown = false, id = '' }) => {
      let isValid = isActive;
      isValid &&= staticItems.some(({ route: staticRoute }) => {
        return matchRoute(staticRoute, route as string, true);
      });
      isValid &&= isAlwaysShown || accessibleLayoutsIds.includes(id || '');
      return isValid;
    })
    .map(item => ({
      name: item.name as string,
      route: item.route as string,
      icon: item.icon as string,
    }));
};

interface SidebarMenuItemsHookResult {
  sidebarMenuItems: SidebarNavigationItemList;
  isLoading: boolean;
}

export const useSidebarMenuItems = (): SidebarMenuItemsHookResult => {
  const { isAuthenticated } = useAppAuth();
  const { user, loading: isUserLoading } = useCurrentUser();
  const { rolesAddons, isLoading: isRolesLoading } = useUserRolesAddons();
  const { configuration, loading: isConfigurationLoading } = useSchoolConfiguration();
  const isLoading = isUserLoading || isRolesLoading || isConfigurationLoading;

  const sidebarMenuItems = React.useMemo((): SidebarNavigationItemList => {
    if (_.isNil(user) || _.isNil(configuration) || !rolesAddons.length || !isAuthenticated) {
      return [];
    }

    return mergeNavigationItems(
      configuration.layout?.items || [],
      getAccessibleLayouts(user, rolesAddons).map(layout => layout.id || ''),
      USER_SIDEBAR_NAVIGATION_ITEMS,
    );
  }, [configuration, rolesAddons, user, isAuthenticated]);

  return {
    sidebarMenuItems,
    isLoading,
  };
};
