import { ListItemIcon, ListItemText, MenuItem, debounce } from '@material-ui/core';
import { useCallback } from 'react';
import { css } from '@emotion/react';

import { MENTION_MARKUP } from '@jebel/constants';
import { createUserMentionIdentifier } from '@jebel/utils';

import { createUserFilter } from 'features/search/utils';
import { MinimalUserFragment, useUsersListLazyQuery } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import {
  MentionDataSource,
  MentionFetcher,
  MentionSuggestion,
  MentionSuggestionRenderer,
} from 'shared/features/mentions/types';
import { UserAvatar } from 'shared/components/symbols';

const listItemCSS = css`
  max-width: 100%;

  &,
  span,
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const renderSuggestion: MentionSuggestionRenderer<MinimalUserFragment> = (
  suggestion,
  _search,
  _highlighted,
  index,
  focused,
) => {
  return (
    <MenuItem key={index} selected={focused}>
      <ListItemIcon>
        <UserAvatar user={suggestion.raw} disabled />
      </ListItemIcon>

      <ListItemText
        css={listItemCSS}
        primary={suggestion.display}
        secondary={suggestion.raw?.email}
      />
    </MenuItem>
  );
};

/**
 * Create a `MentionDataSource` with the users data and configurations needed for `MentionsInput`.
 * @ticket https://8base-dev.atlassian.net/browse/JEB-1458
 * @returns Instance of `MentionDataSource`.
 */
export function useMentionsUserDataSource(): MentionDataSource {
  const [fetchUsers] = useUsersListLazyQuery();

  const fetchUserMentions: MentionFetcher = async (search, setSuggestions) => {
    const filter = createUserFilter({ search });
    const response = await fetchUsers({
      variables: {
        filter,
        first: search.length > 3 ? undefined : 8,
      },
    });

    const users = response.data?.usersList.items ?? [];

    const suggestions = users.map<MentionSuggestion>(user => {
      return {
        id: createUserMentionIdentifier(user.id),
        display: formatUserName(user),
        raw: user,
      };
    });

    setSuggestions(suggestions);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUserMentionsDebounce = useCallback(debounce(fetchUserMentions, 300), []);

  return {
    data: fetchUserMentionsDebounce,
    markup: MENTION_MARKUP,
    renderSuggestion,
  };
}
