import { Formik } from 'formik';

import { UsersFilter } from 'features/explorer/types';
import { Button } from 'shared/components/ui';

import { Container } from './ExplorerFilterSidebar.styles';
import { ExplorerFilterForm } from '../ExplorerFilterForm';

interface Props {
  initial: UsersFilter;

  onChange(filter: UsersFilter): void;
}

export function ExplorerFilterSidebar(props: Props) {
  const handleSubmit = (filters: UsersFilter) => {
    props.onChange(filters);
  };

  return (
    <Formik initialValues={props.initial} enableReinitialize onSubmit={handleSubmit}>
      {form => (
        <Container>
          <ExplorerFilterForm />

          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={!form.dirty}
            onClick={form.submitForm}
          >
            APPLY
          </Button>
        </Container>
      )}
    </Formik>
  );
}
