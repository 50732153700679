import { DISCOUNT_TYPES } from '@jebel/constants';

import { Maybe } from 'shared/graphql';

import { formatRelativeDate } from '../date';

/** Generates a text depending on the `type` and the `amount` of the discount. */
export function generateDiscountLabel(
  type: Maybe<string> | undefined,
  amount: Maybe<number> = 0,
): string {
  if (type === DISCOUNT_TYPES.free || amount === 100) {
    return 'FREE';
  }

  if (type === DISCOUNT_TYPES.flatPrice || type === DISCOUNT_TYPES.amountOff) {
    return `$${amount} OFF`;
  }

  if (type === DISCOUNT_TYPES.discount) {
    return `${amount}% OFF`;
  }

  return 'OFF';
}

/** Used to slice the terms of a discount. */
export const DISCOUNT_DESCRIPTION_SLICE_LENGTH = 50;

/** Generates a text depending on the `type` and the `amount` of the discount. */
export function generateDiscountExpirationLabel(
  expirationDate: Maybe<unknown> | undefined,
): string {
  const relative = formatRelativeDate(expirationDate, { units: ['months', 'days', 'hours'] });

  return `Expires in ${relative}`;
}
