import { Fragment, useMemo, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { IconButton, Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { USER_ACTIVITIES_OPTIONS } from 'shared/constants';
import { Typography, Icon, Modal } from 'shared/components/ui';
import { useCurrentUser } from 'shared/hooks';

import { EditActivityClubsModal } from './EditActivityClubsModal';
import { EditHobbiesModal } from './EditHobbiesModal';

const containerCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const sectionContainerCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  & > * {
    min-width: 0;
  }
`;
const sectionHeaderCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const pointIconCSS = (theme: Theme) => css`
  fill: ${theme.palette.secondary.light};
  font-size: ${theme.typography.fontSize - 7}px;
  margin-right: ${theme.spacing(1)}px;
`;

export function SettingsPublicProfileSectionsInterests() {
  const { user } = useCurrentUser();

  const hobbies = useMemo(() => {
    const hobbies = user?.hobbies ?? [];
    return hobbies.filter(Boolean);
  }, [user]);

  const activityClubs = useMemo(() => {
    const activityClubs = user?.activityClubs ?? [];
    return activityClubs.filter(Boolean);
  }, [user]);

  const [isEditHobbiesModalOpened, setIsEditHobbiesModalOpened] = useState(false);
  const [isActivityClubsModalOpened, setIsActivityClubsModalOpened] = useState(false);

  const onHobbiesModalOpen = () => {
    setIsEditHobbiesModalOpened(true);
  };

  const onHobbiesModalClose = () => {
    setIsEditHobbiesModalOpened(false);
  };

  const onActivitiesModalOpen = () => {
    setIsActivityClubsModalOpened(true);
  };

  const onActivitiesModalClose = () => {
    setIsActivityClubsModalOpened(false);
  };

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: isEditHobbiesModalOpened,
          onClose: onHobbiesModalClose,
          fullWidth: true,
        }}
        titleProps={{ title: 'Hobbies & Interests' }}
        isVisibleDivider
      >
        <EditHobbiesModal onModalClose={onHobbiesModalClose} hobbies={hobbies} />
      </Modal>

      <Modal
        dialogProps={{
          open: isActivityClubsModalOpened,
          onClose: onActivitiesModalClose,
          fullWidth: true,
        }}
        titleProps={{ title: 'Clubs & Sports' }}
        isVisibleDivider
      >
        <EditActivityClubsModal
          onModalClose={onActivitiesModalClose}
          activityClubs={activityClubs}
        />
      </Modal>

      <Box css={containerCSS}>
        <Box css={sectionContainerCSS}>
          <Box css={sectionHeaderCSS}>
            <Typography variant="h6">Hobbies & Interests</Typography>

            <IconButton color="primary" onClick={onHobbiesModalOpen}>
              <Edit fontSize="small" />
            </IconButton>
          </Box>

          {hobbies.length > 0 && (
            <Box
              display="grid"
              gridTemplateRows="auto auto"
              alignItems="center"
              justifyContent="space-between"
            >
              {hobbies.map(
                item =>
                  item && (
                    <Box
                      key={item}
                      display="grid"
                      gridTemplateColumns="auto auto"
                      alignItems="center"
                      justifyContent="start"
                      gridGap={2}
                    >
                      <Icon css={pointIconCSS} name="Circle" viewBox="0 0 24 24" />
                      <Typography>
                        {USER_ACTIVITIES_OPTIONS?.hobbies.find(option => option.value === item)
                          ?.label || ''}
                      </Typography>
                    </Box>
                  ),
              )}
            </Box>
          )}
        </Box>

        <Box css={sectionContainerCSS}>
          <Box css={sectionHeaderCSS}>
            <Typography variant="h6">Clubs & Sports</Typography>

            <IconButton color="primary" onClick={onActivitiesModalOpen}>
              <Edit fontSize="small" />
            </IconButton>
          </Box>

          {activityClubs.length > 0 && (
            <Box
              display="grid"
              gridTemplateRows="auto auto"
              alignItems="center"
              justifyContent="space-between"
            >
              {activityClubs.map(
                item =>
                  item && (
                    <Box
                      key={item}
                      display="grid"
                      gridTemplateColumns="auto auto"
                      alignItems="center"
                      justifyContent="start"
                      gridGap={2}
                    >
                      <Icon css={pointIconCSS} name="Circle" />
                      <Typography>
                        {USER_ACTIVITIES_OPTIONS?.clubs.find(option => option.value === item)
                          ?.label || ''}
                      </Typography>
                    </Box>
                  ),
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
