import { useMutation } from '@apollo/client';

import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { MESSAGE_SEND_REQUEST_MUTATION } from 'shared/graphql';
import {
  MessageSendRequestMutation,
  MessageSendRequestMutationVariables,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { useUpdateIncrementJobOffer } from '../../features/home/hooks/useIncrementJobPost';

type FormData = MessageSendRequestMutationVariables & {
  jobId?: string;
};

type InboxMessageSendProps = {
  withContext: boolean;
};

export const useInboxMessageSend = ({ withContext }: InboxMessageSendProps) => {
  const incrementJobEngagement = useUpdateIncrementJobOffer();

  const [messageSendMutation] = useMutation<
    MessageSendRequestMutation,
    MessageSendRequestMutationVariables
  >(MESSAGE_SEND_REQUEST_MUTATION, {
    context: withContext
      ? {
          [SNACKBAR_SUCCESS_MESSAGE]: 'Your message has been sent!',
          [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been sent. Try again.`,
        }
      : { [SNACKBAR_ERROR_MESSAGE]: `Error: You message hasn't been sent. Try again.` },
  });

  const createMessage = async (formData: FormData) => {
    try {
      await messageSendMutation({ variables: formData });

      if (formData.jobId) {
        await incrementJobEngagement(formData?.jobId);
      }
    } catch (err: any) {
      console.error(err);
      sendToSentry(err);
    }
  };

  return {
    createMessage,
  };
};
