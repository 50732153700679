import React from 'react';

import { GroupsListItemFragment, UserKeyFilter } from 'shared/graphql';

export type InboxModalOptions = {
  isOpen: boolean;
  options?: {
    members?: UserKeyFilter[] | GroupsListItemFragment[] | null;
    messageType?: string | null;
  };
};

export type InboxContextValue = {
  onOpenInboxModal: (data: InboxModalOptions) => void;
};

export const InboxContext = React.createContext<InboxContextValue>({
  onOpenInboxModal: () => null,
});
