import { FormControl, FormHelperText, TextFieldProps } from '@material-ui/core';
import { KeyboardDatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { DATE_TABLE_FORMAT } from '@jebel/constants';
import { formatISO8601Date, parseDate } from '@jebel/utils';

import { getIsInvalid } from '../common';

type BaseProps = Omit<
  KeyboardDatePickerProps & TextFieldProps,
  'value' | 'onChange' | 'renderInput'
>;

export interface DatePickerFieldProps extends BaseProps {
  name: string;
  validate?: FieldValidator;

  onChange?(value: unknown): void;
}

const DEFAULT_DATETIME = DateTime.now().startOf('day');

export function DatePickerField(props: DatePickerFieldProps) {
  const [field, meta, helpers] = useField<DateTime | null>({
    name: props.name,
    validate: props.validate,
  });

  const form = useFormikContext();
  const hasError = getIsInvalid({ meta, form });
  const message = meta.error;
  const format = props.format ?? DATE_TABLE_FORMAT;

  const minDate = useMemo(() => {
    const datetime = parseDate(props.minDate);

    if (!datetime.isValid) {
      // Prevent to block the component with invalid date-time.
      // https://github.com/8base-services/jebel/issues/1440
      return undefined;
    }

    return formatISO8601Date(datetime);
  }, [props.minDate]);

  const maxDate = useMemo(() => {
    const datetime = parseDate(props.maxDate);

    if (!datetime.isValid) {
      // Prevent to block the component with invalid date-time.
      // https://github.com/8base-services/jebel/issues/1440
      return undefined;
    }

    return formatISO8601Date(datetime);
  }, [props.maxDate]);

  const value = useMemo(() => {
    const parsed = parseDate(field.value);

    if (parsed.isValid) {
      return formatISO8601Date(parsed);
    }

    return null;
  }, [field.value]);

  const setValue = (value: DateTime | null) => {
    helpers.setValue(value);
    props.onChange?.(value);
  };

  const handleDateChange = (datetime: unknown) => {
    const parsed = parseDate(datetime);

    if (!parsed.isValid) {
      setValue(null);
      return;
    }

    const current = parseDate(value ?? DEFAULT_DATETIME);
    setValue(current.set({ day: parsed.day, month: parsed.month, year: parsed.year }));
  };

  return (
    <FormControl error={hasError}>
      <KeyboardDatePicker
        label={props.label}
        name={props.name}
        variant="dialog"
        placeholder={format}
        inputVariant={props.inputVariant}
        disabled={props.disabled}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        format={format}
        views={props.views}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        error={hasError}
        onChange={handleDateChange}
      />

      {hasError && <FormHelperText error={hasError}>{message}</FormHelperText>}
    </FormControl>
  );
}
