import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  MutationSchoolConfigurationTopBarCreateArgs,
  MutationSchoolConfigurationTopBarDeleteArgs,
  MutationSchoolConfigurationTopBarUpdateArgs,
  SchoolConfigurationTopBarCreateInput,
  SchoolConfigurationTopBarDeleteInput,
  SchoolConfigurationTopBarUpdateInput,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from '../constants';

export const SCHOOL_CONFIGURATION_TOP_BAR_CREATE_MUTATION = gql`
  mutation SchoolConfigurationTopBarCreate($data: SchoolConfigurationTopBarCreateInput!) {
    schoolConfigurationTopBarCreate(data: $data) {
      id
    }
  }
`;

export const SCHOOL_CONFIGURATION_TOP_BAR_UPDATE_MUTATION = gql`
  mutation SchoolConfigurationTopBarUpdate($data: SchoolConfigurationTopBarUpdateInput!) {
    schoolConfigurationTopBarUpdate(data: $data) {
      id
    }
  }
`;

export const SCHOOL_CONFIGURATION_TOP_BAR_DELETE_MUTATION = gql`
  mutation SchoolConfigurationTopBarDelete($data: SchoolConfigurationTopBarDeleteInput!) {
    schoolConfigurationTopBarDelete(data: $data) {
      success
    }
  }
`;

export const useSchoolConfigurationTopBarCreate = () => {
  const [schoolConfigurationTopBarCreateMutation] = useMutation<
    SchoolConfigurationTopBarCreateInput,
    MutationSchoolConfigurationTopBarCreateArgs
  >(SCHOOL_CONFIGURATION_TOP_BAR_CREATE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The top bar settings has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The top bar settings has not been updated. Try again later.`,
    },
  });

  const schoolConfigurationTopBarCreate = React.useCallback(
    async (schoolConfigurationTopBar: SchoolConfigurationTopBarCreateInput) => {
      try {
        await schoolConfigurationTopBarCreateMutation({
          variables: {
            data: schoolConfigurationTopBar,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schoolConfigurationTopBarCreateMutation],
  );

  return {
    schoolConfigurationTopBarCreate,
  };
};

export const useSchoolConfigurationTopBarUpdate = () => {
  const [schoolConfigurationTopBarUpdateMutation] = useMutation<
    SchoolConfigurationTopBarUpdateInput,
    MutationSchoolConfigurationTopBarUpdateArgs
  >(SCHOOL_CONFIGURATION_TOP_BAR_UPDATE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The top bar settings has been updated.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The top bar settings has not been updated. Try again later.`,
    },
  });

  const schoolConfigurationTopBarUpdate = React.useCallback(
    async (schoolConfigurationTopBar: SchoolConfigurationTopBarUpdateInput) => {
      try {
        await schoolConfigurationTopBarUpdateMutation({
          variables: {
            data: schoolConfigurationTopBar,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schoolConfigurationTopBarUpdateMutation],
  );

  return {
    schoolConfigurationTopBarUpdate,
  };
};

export const useSchoolConfigurationTopBarDelete = () => {
  const [schoolConfigurationTopBarDeleteMutation] = useMutation<
    SchoolConfigurationTopBarDeleteInput,
    MutationSchoolConfigurationTopBarDeleteArgs
  >(SCHOOL_CONFIGURATION_TOP_BAR_DELETE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success: The top bar settings has been deleted.',
      [SNACKBAR_ERROR_MESSAGE]: `Error: The top bar settings has not been deleted. Try again later.`,
    },
  });

  const schoolConfigurationTopBarDelete = React.useCallback(
    async (schoolConfigurationTopBar: SchoolConfigurationTopBarDeleteInput) => {
      try {
        await schoolConfigurationTopBarDeleteMutation({
          variables: {
            data: schoolConfigurationTopBar,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schoolConfigurationTopBarDeleteMutation],
  );

  return {
    schoolConfigurationTopBarDelete,
  };
};
