import { css } from '@emotion/react';

export const CARD_IMAGE_SIZE = 80;
export const SUPPORTERS_ICON_SIZE = 25;
export const IMAGE_WIDTH = 100;

export const cardContainerCss = theme => css`
  box-shadow: 0 7px 21px 0 rgba(171, 171, 171, 0.17);
  border-radius: 8px;
  cursor: pointer;
  padding: ${theme.spacing(2.5)}px;
  overflow: hidden;
`;

export const cardGridContainerCss = theme => css`
  ${cardContainerCss(theme)}
  max-height: 320px;
  min-height: 230px;
`;

export const cardImageCss = theme => css`
  width: ${CARD_IMAGE_SIZE}px;
  height: ${CARD_IMAGE_SIZE}px;
  border-radius: ${theme.spacing(1.33)}px;
  border: 1px solid ${theme.palette.border};
  overflow: hidden;
  margin-right: ${theme.spacing(2)}px;
`;

export const cardNameCss = css`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const cardNameTextCss = theme => css`
  font-weight: 500;
  font-size: 14px;
  padding-right: ${theme.spacing(1)}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

export const supportersIconCss = css`
  height: ${SUPPORTERS_ICON_SIZE}px;
  width: ${SUPPORTERS_ICON_SIZE}px;
`;

export const cardLocationTextCss = theme => css`
  font-weight: 500;
  color: ${theme.palette.text.secondary};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const cardContentCss = theme => css`
  margin-top: ${theme.spacing(1.75)}px;
`;

export const cardDescriptionCss = theme => css`
  color: ${theme.palette.background.dark};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  white-space: pre-line;
`;

export const gridCardDescriptionCss = theme => css`
  ${cardDescriptionCss(theme)}
  -webkit-line-clamp: 4; /* number of lines to show */
`;

export const cardInfoListWrapCss = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const cardInfoListCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const contentContainerCss = {
  width: '100%',
};

const DIVIDER_CONTAINER_WIDTH = 24;
const DIVIDER_CONTAINER_HEIGHT = 32;

export const dividerContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${DIVIDER_CONTAINER_WIDTH}px;
  height: ${DIVIDER_CONTAINER_HEIGHT}px;
`;

const DIVIDER_SIZE = 4;

export const dividerCss = theme => css`
  height: ${DIVIDER_SIZE}px;
  width: ${DIVIDER_SIZE}px;
  border-radius: ${DIVIDER_SIZE / 2}px;
  background-color: ${theme.palette.text.secondary};
`;

export const organizationInfoCss = theme => css`
  overflow: hidden;
  max-height: 80px;

  ${theme.breakpoints.down('sm')} {
    flex: 1;
  }
`;
