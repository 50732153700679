import React from 'react';
import { css } from '@emotion/react';

import { Box, Modal } from 'shared/components/ui';
import { EventInfoFragment, Organization } from 'shared/graphql';
import { useResponsive } from 'shared/hooks';

import { Event } from './Event';
import { EventInfoModal } from './EventInfoModal';
import { useQueryParams } from '../../../../shared/features/queryParams';

const eventsListBox = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(3.125)}px;
  padding-bottom: ${theme.spacing(1.25)}px;
`;

type EventsListProps = {
  eventsList: EventInfoFragment[] | undefined | null;
};

export const EventsList = ({ eventsList }: EventsListProps) => {
  const { isMobile } = useResponsive();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [event, setEvent] = React.useState<EventInfoFragment>();

  const onClose = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const onEventOpen = React.useCallback(
    (item: EventInfoFragment) => {
      setIsModalOpen(true);
      setEvent(item);
    },
    [setIsModalOpen, setEvent],
  );
  const query = useQueryParams();

  React.useEffect(() => {
    const eventId = query.get('details');
    if (eventId) {
      setEvent({ id: eventId });
      setIsModalOpen(true);
    }
  }, []);

  return (
    <Box css={eventsListBox}>
      <Modal
        dialogProps={{
          open: isModalOpen,
          onClose,
          fullWidth: true,
          maxWidth: isMobile ? 'xs' : 'sm',
        }}
        titleProps={undefined}
        isVisibleDivider={false}
        dialogContentWidth={isMobile ? 280 : undefined}
      >
        <EventInfoModal eventId={event?.id} onClose={onClose} />
      </Modal>
      {eventsList?.map(event => (
        <Event
          key={event.id}
          onEventOpen={() => onEventOpen(event)}
          date={event.date}
          images={event.images}
          location={event.location}
          title={event.title}
          timezone={event.timezone}
          titleSponsor={event.titleSponsor as Organization}
        />
      ))}
    </Box>
  );
};
