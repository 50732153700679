import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: grid;
  overflow-x: hidden;

  & > .echarts-for-react {
    width: 100%;
    min-width: 0;
  }
`;
