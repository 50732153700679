import styled from '@emotion/styled';
import { Palette } from '@mui/material';

// Container

interface ContainerProps {
  color: keyof Palette;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 0.5rem;
  color: ${p => p.theme.palette[p.color].main};
`;

// Text

export const Text = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  align-items: center;
  font-size: ${p => p.theme.typography.body2.fontSize};
`;
