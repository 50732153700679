import { useMemo } from 'react';

import { ROUTES } from 'shared/constants';
import { useContentCurationCount } from 'shared/features/posts';

export function useUnreadSidebarElementsQuery() {
  const { data: contentCuration, loading: contentCurationLoading } = useContentCurationCount();

  const sidebarUnreadElementsList = useMemo(() => {
    return {
      [ROUTES.admin.contentCuration.index]: contentCuration.pending,
    };
  }, [contentCuration]);

  return {
    sidebarUnreadElementsList,
    loading: contentCurationLoading,
  };
}
