import { gql } from '@apollo/client';

const MEMBERS_LIST_ITEM_FRAGMENT = gql`
  fragment MembersListItem on User {
    id
    firstName
    lastName
    affiliation
    graduatingYear
    avatar {
      id
      downloadUrl
    }
    jobExperience {
      items {
        id
        positionDescription
        positionTitle
        companyName

        companyLocation {
          state
          city
          street1
          zip
        }

        companyImage {
          downloadUrl
        }
      }
    }
  }
`;

const GROUPS_LIST_ITEM_FRAGMENT = gql`
  fragment GroupsListItem on Group {
    id
    title
    membersCount
    description
    status
    isApproved
    createdAt

    logo {
      id
      downloadUrl
      fileId
      filename
    }

    members {
      items {
        ...MembersListItem
      }
    }

    creator {
      id
      firstName
      lastName
    }

    posts {
      count
    }

    groupAdminPreferences {
      items {
        id
        status
        user {
          id
        }
      }
    }
  }

  ${MEMBERS_LIST_ITEM_FRAGMENT}
`;

export const GROUPS_LIST_QUERY = gql`
  query GroupsList($filter: GroupFilter, $sort: [GroupSort!], $first: Int, $skip: Int) {
    groupsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...GroupsListItem
      }
    }
  }
  ${GROUPS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_POSTS_LIST_ITEM_FRAGMENT = gql`
  fragment GroupPostsListItem on GroupPost {
    id
    createdAt
    text
    isPinned
    commentsAllowed
    author {
      id
      firstName
      lastName
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
    }
    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    group {
      id
    }
    status
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
  }
`;

export const GROUP_POST_CREATE_MUTATION = gql`
  mutation GroupPostCreate($data: GroupPostCreateInput!) {
    groupPostCreate(data: $data) {
      ...GroupPostsListItem
    }
  }

  ${GROUP_POSTS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_POST_UPDATE_MUTATION = gql`
  mutation GroupPostUpdate($data: GroupPostUpdateInput!) {
    groupPostUpdate(data: $data) {
      ...GroupPostsListItem
    }
  }

  ${GROUP_POSTS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_POSTS_LIST_QUERY = gql`
  query GroupPostsList(
    $first: Int!
    $skip: Int
    $sort: [GroupPostSort!]
    $filter: GroupPostFilter
    $pinnedFilter: GroupPostFilter!
  ) {
    groupPostsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...GroupPostsListItem
      }
    }
    pinnedPostsList: groupPostsList(filter: $pinnedFilter, sort: $sort) {
      items {
        ...GroupPostsListItem
      }
    }
  }

  ${GROUP_POSTS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_POSTS_COUNT_QUERY = gql`
  query GroupPostsCount($filter: GroupPostFilter) {
    groupPostsList(filter: $filter) {
      count
    }
  }
`;

export const GROUP_DETAILS_QUERY = gql`
  query GroupDetails($id: ID) {
    group(id: $id) {
      ...GroupsListItem
    }
  }
  ${GROUPS_LIST_ITEM_FRAGMENT}
`;

export const GROUP_CREATE_REQUEST_MUTATION = gql`
  mutation GroupCreateRequest($data: GroupCreateInput!) {
    groupCreateRequest(data: $data) {
      groupId
    }
  }
`;

export const USERS_IDS_SEARCH_QUERY = gql`
  query UsersIdsSearch($filter: UserFilter!) {
    usersList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

export const GROUP_DETAILS_FRAGMENT = gql`
  fragment GroupDetails on Group {
    id
    title
    description
    membersCount
    createdAt
    isApproved

    members {
      items {
        id
      }
    }

    logo {
      id
      fileId
      downloadUrl
      filename
    }

    createdBy {
      id
      lastName
      firstName
    }

    groupAdminPreferences {
      items {
        status
        id
        user {
          email
          id
          firstName
          lastName
          affiliation
        }
      }
    }
  }
`;

export const GROUPS_QUERY = gql`
  query Groups($first: Int, $skip: Int, $filter: GroupFilter, $sort: [GroupSort!]) {
    groupsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count

      items {
        ...GroupDetails
      }
    }
  }

  ${GROUP_DETAILS_FRAGMENT}
`;

/** @deprecated Use {@link GROUPS_QUERY} instead. */
export const GROUPS_LIST_DATA = GROUPS_QUERY;
