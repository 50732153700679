import { APP_URL } from 'shared/constants';
import { GetRoutesFn, GuestRoute, ProtectedRoute } from 'shared/routes/';

import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { EmailRegistrationPage } from './pages/EmailRegistrationPage';
import { PasswordRecoverySentPage } from './pages/PasswordRecoverySentPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { RequestAccessPage } from './pages/RequestAccessPage';
// TODO: lazy loading pages

export const getOnboardingRoutes: GetRoutesFn = () => [
  {
    routeComponent: GuestRoute,
    path: APP_URL.public.changePassword,
    component: ChangePasswordPage,
    exact: true,
  },
  {
    routeComponent: GuestRoute,
    path: APP_URL.public.recovery,
    component: PasswordResetPage,
    exact: true,
  },
  {
    routeComponent: GuestRoute,
    path: APP_URL.public.recoverySent,
    component: PasswordRecoverySentPage,
    exact: true,
  },
  {
    routeComponent: GuestRoute,
    path: APP_URL.public.request,
    component: RequestAccessPage,
    exact: true,
  },
  {
    routeComponent: GuestRoute,
    path: APP_URL.public.register,
    exact: true,
    component: EmailRegistrationPage,
  },
];
