import { SORT } from '@jebel/constants';

export const CAREERS_SORT_INFO = {
  displayName: 'Sort by',
  fullWidth: true,
  options: [
    {
      label: 'Newest first',
      value: { postDate: SORT.desc },
    },
    { label: 'Alphabetical', value: { position: SORT.asc } },
  ],
};
