import { css } from '@emotion/react';
import { Box, Divider } from '@material-ui/core';
import React from 'react';

import { Button, Icon, Typography } from '../ui';

interface MobileShortcutInstructionsModalType {
  onModalClose: () => void;
}

const commonButtonsCSS = (theme: any) => css`
  width: 100%;
  padding: ${theme.spacing(1)}px ${theme.spacing(10)}px;
`;

const submitButtonCSS = (theme: any) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

const iosShareIconCSS = (theme: any) => css`
  color: ${theme.palette.info.main};
  font-weight: 500;
  vertical-align: middle;
`;

export const MobileShortcutInstructionsModal: React.FC<MobileShortcutInstructionsModalType> = ({
  onModalClose,
}) => {
  return (
    <Box display="grid" gridTemplateRows="auto auto auto" gridGap={10}>
      <Typography variant="subtitle4" color="textPrimary">
        iOS / Iphone
      </Typography>
      <Typography color="textSecondary" variant="body2">
        Please note this feature only works with Safari
      </Typography>
      <Typography variant="body2">
        1. Tap the Bookmark icon (<Icon name="IosShare" css={iosShareIconCSS} />) at the bottom of
        the screen.
      </Typography>
      <Box display="flex" flexDirection="row">
        <Typography variant="body2">2. Tap &nbsp; </Typography>
        <Typography css={iosShareIconCSS} variant="body2">
          Add to Home Screen
        </Typography>
        <Typography variant="body2">.</Typography>
      </Box>
      <Box display="grid" gridTemplateRows="auto auto auto auto">
        <Typography variant="body2">3. Accept the suggested name for the </Typography>
        <Typography variant="body2">shortcut, or enter one you like better, then </Typography>
        <Box display="flex">
          <Typography variant="body2"> tap &nbsp;</Typography>
          <Typography css={iosShareIconCSS} variant="body2">
            Add &nbsp;
          </Typography>
          <Typography variant="body2"> to save the new shortcut icon to </Typography>
        </Box>
        <Typography variant="body2"> the home screen.</Typography>
      </Box>
      <Typography variant="body2">
        4. You'll now see the new icon next to all your other app icons on the home screen.
      </Typography>
      <Box mt={2}>
        <Divider />
      </Box>
      <Typography variant="subtitle4" color="textPrimary">
        Android
      </Typography>
      <Box display="flex" flexDirection="row">
        <Typography variant="body2">1. Launch &nbsp;</Typography>
        <Typography css={iosShareIconCSS} variant="body2">
          Chrome app
        </Typography>
        <Typography variant="body2">. </Typography>
      </Box>
      <Typography variant="body2">2. Open the Columbus Connects website. </Typography>

      <Box display="grid" gridTemplateRows="auto auto">
        <Box display="flex">
          <Typography variant="body2" display="inline">
            3. Tap the menu icon (
            <Icon name="MoreHoriz" css={iosShareIconCSS} />) and tap &nbsp;
          </Typography>
          <Typography css={iosShareIconCSS} variant="body2">
            Add to
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Typography css={iosShareIconCSS} variant="body2">
          homescreen
        </Typography>
        <Typography variant="body2">.</Typography>
      </Box>
      <Typography variant="body2">
        4. You'll be able to enter a name for the shortcut and then Chrome will add it to your home
        screen.
      </Typography>
      <Box display="flex" flexDirection="column" mt={2}>
        <Button css={submitButtonCSS} onClick={onModalClose} size="medium" variant="outlined">
          GOT IT
        </Button>
      </Box>
    </Box>
  );
};
