import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import { FileKeyFilter, PostCheckQuery, PostCheckQueryVariables } from 'shared/graphql';
import { POST_CHECK_QUERY } from 'shared/graphql/post';

import { CommentImage } from '../features/posts';
import { useToast } from './useToast';

const ERROR_MESSAGE = 'Error: We found something inappropriate in your comment.';

export function useCheckComment() {
  const { showError } = useToast();

  const {
    refetch: checkPost,
    data,
    loading: checkPostLoading,
    ...rest
  } = useQuery<PostCheckQuery, PostCheckQueryVariables>(POST_CHECK_QUERY, {
    skip: true,
  });

  const onCheckComment = useCallback(
    async (variables: { text?: string; blacklistId: string; media: CommentImage[] }) => {
      const { text, media, blacklistId } = variables;

      const { data: postCheckData } = await checkPost({
        text,
        media: media.map<FileKeyFilter>(file => ({ fileId: file.fileId })),
        blacklistId,
      });

      const isVerified = postCheckData?.postModerateRequest?.verified;

      if (!isVerified) {
        showError(ERROR_MESSAGE);
        return;
      }

      return isVerified;
    },
    [checkPost, showError],
  );

  return {
    onCheckComment,
    checkPostLoading,
    isCommentVerified: data?.postModerateRequest?.verified,
    ...rest,
  };
}
