import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  --header-height: 12rem;
  --title-size: 2rem;

  display: flex;
  gap: 2rem;
`;

// Header

export const Header = styled.div`
  width: 5rem;
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  gap: 0.5rem;
`;

// Title

export const Title = styled.span`
  font-size: var(--title-size);
  font-weight: normal;
  text-align: center;
  line-height: 1;
`;

// Content

export const Content = styled.div`
  --border-size: 2px;

  min-height: var(--header-height);
  padding: 1rem 0;
  padding-left: 2rem;
  border-left: dashed var(--border-size);
  border-left-color: ${p => p.theme.palette.primary.dark};
  position: relative;
  flex: 1;

  &::before {
    --size: 1rem;

    content: '';
    display: block;
    width: var(--size);
    height: var(--size);
    background-color: white;
    border: solid var(--border-size);
    border-color: ${p => p.theme.palette.primary.dark};
    border-radius: var(--size);
    position: absolute;
    top: calc((var(--title-size) / 2) + (var(--size) / 2));
    left: calc((var(--size) + var(--border-size)) * -0.5);
  }
`;

// SeeMoreButton

export const SeeMoreButton = styled.span`
  max-width: 5rem;
  text-align: center;
  cursor: pointer;
  user-select: none;
  line-height: 1.4;
  color: ${p => p.theme.palette.secondary.light};

  &:hover {
    text-decoration: underline;
  }
`;
