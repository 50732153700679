import { array, object, string } from 'yup';
const SCHEMA = object({
    items: array(object({
        fileId: string().nullable().optional(),
        downloadUrl: string().nullable().optional(),
    }))
        .nullable()
        .optional(),
})
    .nullable()
    .optional();
/**
 * Extract the media from the stringified JSON in the `HomeFeedItem`.
 * @throws When the string is not a valid JSON.
 * @throws When the content of the JSON is not valid.
 */
export function extractHomeFeedItemMedia(media) {
    if (typeof media === 'string') {
        const content = JSON.parse(media);
        return SCHEMA.cast(content);
    }
    return SCHEMA.cast(media);
}
