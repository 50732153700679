import { APP_URL } from 'shared/constants';
import { GetRoutesFn, ProtectedRoute } from 'shared/routes/';

import { GroupDetailsPage } from './pages/GroupDetailsPage';
import { GroupsPage } from './pages/GroupsPage';

export const getGroupsRoutes: GetRoutesFn = () => [
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.groups.index,
    exact: true,
    component: GroupsPage,
  },
  {
    routeComponent: ProtectedRoute,
    path: APP_URL.user.groups.details,
    exact: true,
    component: GroupDetailsPage,
  },
];
