import { useMemo } from 'react';

import {
  OrganizationSort,
  OrganizationsListQueryVariables,
  useOrganizationsListQuery,
} from 'shared/graphql';
import { chooseRamdomSorts } from 'shared/utils/chooseRandomSorts';

type Options = Parameters<typeof useOrganizationsListQuery>[0] & {
  variables?: Omit<OrganizationsListQueryVariables, 'sort'>;
};

/**
 * Generates a list of organizations sorted randomly every time that's executed.
 * @url https://8base-dev.atlassian.net/browse/JEB-1457
 * @returns List of organizations sorted randomly.
 */
export function useRandomOrganizations(options?: Options) {
  const sort = useMemo(() => generateSorts(), []);

  const { data: response, loading } = useOrganizationsListQuery({
    ...options,

    variables: {
      ...options?.variables,

      sort,
    },
  });

  const data = response?.organizations?.items ?? [];
  const count = response?.organizations?.count ?? 0;

  return {
    data,
    count,
    loading,
  };
}

function generateSorts(): OrganizationSort[] {
  const fields: Array<keyof OrganizationSort> = [
    'name',
    'description',
    'industry',
    'createdAt',
    'updatedAt',
  ];

  return chooseRamdomSorts<OrganizationSort>(fields);
}
