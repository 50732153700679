import { ROLE_NAMES } from '@jebel/constants';

import { CurrentUserQuery } from 'shared/graphql/__generated__';

export const getHasAdminRole = (user?: CurrentUserQuery['user']) => {
  return (
    user?.roles?.items.some(role => role.name === ROLE_NAMES.communityAdmin) ||
    user?.rolesAddons?.items?.some(addon => addon?.role?.name === ROLE_NAMES.communityAdmin)
  );
};
