import { useRelativeDate } from 'shared/hooks';

import { useHomeFeedCardProvider } from '../../providers';

export function useHomeFeedCreationAgo() {
  const {
    isHaveBeenRender: haveBeenRender,
    data: { createdAt, postDate },
  } = useHomeFeedCardProvider();

  const date = postDate ?? createdAt;

  return useRelativeDate(date, {
    refresh: haveBeenRender,
  });
}
