import { ClassAttributes, ComponentProps, ReactNode } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { isFunction } from 'lodash';

import { withFormikHandle } from 'shared/utils/form';

const StyledForm = styled('form')``;

export type FormProps<T extends FormikValues = any> = Omit<
  FormikConfig<any>,
  'initialValues' | 'render' | 'children' | 'component'
> & {
  initialValues?: FormikValues;
  formikRef?: ClassAttributes<HTMLFormElement>['ref'];
  handleErrors?: boolean;
  boxProps?: BoxProps;
  formNodeProps?: Omit<ComponentProps<typeof StyledForm>, 'ref' | 'onSubmit' | 'css'>;
  children?: ReactNode | ((form: FormikProps<T>) => ReactNode);

  /** @deprecated use formNodeProps */
  oldCss?: any; // "css" prop not working for unknown reasons
};

export function Form<T extends FormikValues = any>({
  boxProps,
  formNodeProps,
  initialValues = {} as T,
  handleErrors = true,
  enableReinitialize = true,
  onSubmit,
  formikRef,
  children,
  oldCss,
  ...props
}: FormProps<T>) {
  return (
    <Box {...boxProps}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleErrors ? withFormikHandle(onSubmit) : onSubmit}
        enableReinitialize={enableReinitialize}
        {...props}
      >
        {formProps => (
          <StyledForm
            ref={formikRef}
            css={oldCss}
            onSubmit={formProps.handleSubmit}
            {...formNodeProps}
          >
            {isFunction(children) ? children(formProps) : children}
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
}
