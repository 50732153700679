import { ParseResult } from 'papaparse';

import { REGEX_SQL_FORMAT, REGEX_TABLE_FORMAT, USER_AFFILIATIONS } from '@jebel/constants';
import { formatISO8601Date } from '@jebel/utils';

import { MemberInvitation } from '../components/dialogs/InviteModal/components/types';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const SINGLE_YEAR_REGEX = /^[0-9]+$/;

const CSV_TEMPLATE_HEADERS = {
  affiliation: 'Affiliation',
  description: 'Affiliation Description',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  graduationYear: 'Graduation Year',
};

export function useCsvParser() {
  const parseUsers = (response: ParseResult<Record<string, string>>): MemberInvitation[] => {
    const invitations: MemberInvitation[] = [];
    const size = response.data.length;

    for (let index = 0; index < size; index++) {
      const data = response.data[index];

      const firstName = data[CSV_TEMPLATE_HEADERS.firstName];
      const lastName = data[CSV_TEMPLATE_HEADERS.lastName];
      const email = data[CSV_TEMPLATE_HEADERS.email];
      const affiliation = data[CSV_TEMPLATE_HEADERS.affiliation];
      const graduationYear = data[CSV_TEMPLATE_HEADERS.graduationYear];
      const description = data[CSV_TEMPLATE_HEADERS.description];
      const phoneNumber = data[CSV_TEMPLATE_HEADERS.phoneNumber];

      const isEmpty = !firstName && !lastName && !email && !affiliation;

      if (isEmpty) {
        continue;
      }

      const isComplete = firstName && lastName && email && affiliation && email.match(EMAIL_REGEX);

      if (!isComplete) {
        throw new Error(
          `Missing required data, either "First Name", "Last Name", "Affiliation" or "Email" are not present in row: ${index}`,
        );
      }

      const lowerAffiliation = affiliation.toLowerCase();

      const noAffiliationFound = !Object.keys(USER_AFFILIATIONS).some(
        affiliationNames => affiliationNames === affiliation?.toLowerCase(),
      );

      if (noAffiliationFound) {
        throw new Error(`No affiliation found for email: ${email}`);
      }

      if (lowerAffiliation === USER_AFFILIATIONS.alumni && !graduationYear) {
        throw new Error(`Alumni should have a "Graduation Year": ${email}`);
      }

      if (lowerAffiliation === USER_AFFILIATIONS.other && !description) {
        throw new Error(`"Other" affiliation should have a "Description": ${email}`);
      }

      const invitation: MemberInvitation = {
        firstName,
        lastName,
        email,
        phoneNumber,
        affiliation: affiliation.toLowerCase(),
        affiliationDescription: description ?? '',
      };

      if (REGEX_SQL_FORMAT.test(graduationYear)) {
        // Transform from SQL format to ISO8601 format.
        invitation.graduatingYearIso = formatISO8601Date(graduationYear);
      }

      if (REGEX_TABLE_FORMAT.test(graduationYear)) {
        // Transform from table format (MM/dd/yyyy) to ISO8601 format.
        invitation.graduatingYearIso = formatISO8601Date(graduationYear);
      }

      if (SINGLE_YEAR_REGEX.test(graduationYear) || typeof graduationYear === 'number') {
        // Transform from single year to ISO8601 format.
        const date = new Date(Number(graduationYear), 1, 1);
        invitation.graduatingYearIso = formatISO8601Date(date);
      }

      invitations.push(invitation);
    }

    return invitations;
  };

  return {
    parseUsers,
  };
}
