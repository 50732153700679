import React from 'react';
import { Badge, css } from '@mui/material';
import { Theme } from '@emotion/react';
import { Notifications } from '@material-ui/icons';

import { PopoverV2 } from 'shared/components/ui';
import { useNotifications } from 'shared/features/notifications';

import { NotificationsPopoverContent } from './NotificationsPopoverContent';

const notificationBellCSS = css`
  cursor: pointer;
`;

const iconCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.dark};

  ${theme.breakpoints.down('sm')} {
    color: ${theme.palette.primary.sideFont};
  }
`;

const NotificationsPopoverRaw = () => {
  const { unreadNotificationsCount } = useNotifications();

  return (
    <PopoverV2
      popoverProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
        elevation: 4,
      }}
      triggerType="icon-button"
      tripperProps={{
        children: (
          <Badge
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={unreadNotificationsCount}
            color="secondary"
            max={99}
            showZero={false}
            variant="standard"
            css={notificationBellCSS}
          >
            <Notifications css={iconCSS} />
          </Badge>
        ),
      }}
    >
      {({ isOpen, handlePopoverClose }) => (
        <NotificationsPopoverContent isOpen={isOpen} handlePopoverClose={handlePopoverClose} />
      )}
    </PopoverV2>
  );
};

export const NotificationsPopover = React.memo(NotificationsPopoverRaw);
