import { DeleteForever, Edit, MoreVert } from '@material-ui/icons';
import { IconButton, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { SyntheticEvent, useRef, useState } from 'react';

import { Container, WrapperIcon } from './GroupListItemOptions.styles';
import { useLeaveGroup } from 'features/groups/hooks';

interface Props {
  onViewDetails?(): void;
  groupId: string | undefined;
  isManagedByAdmin: boolean;
}

export function GroupListItemOptions(props: Props) {
  const [isOpen, setOpen] = useState(false);

  const { action: leaveGroup } = useLeaveGroup({ group: { id: props.groupId } });

  const anchor = useRef<HTMLButtonElement | null>(null);

  const showViewDetails = props.isManagedByAdmin;
  const showLeave = !props.isManagedByAdmin;

  const showOptions = showViewDetails || showLeave;

  const openOptions = () => {
    setOpen(true);
  };

  const closeOptions = () => {
    setOpen(false);
  };

  const handleOpen = (event: SyntheticEvent) => {
    // Prevent to propagate the click and be redirected to the group feed
    // https://8base-dev.atlassian.net/browse/JEB-1497
    event.stopPropagation();
    openOptions();
  };

  const handleClose = (event: SyntheticEvent) => {
    // Prevent to propagate the click and be redirected to the group feed
    // https://8base-dev.atlassian.net/browse/JEB-1497
    event.stopPropagation();
    closeOptions();
  };

  const onViewDetails = (event: SyntheticEvent) => {
    // Prevent to propagate the click and be redirected to the group feed
    // https://8base-dev.atlassian.net/browse/JEB-1497
    event.stopPropagation();

    props.onViewDetails?.();
    closeOptions();
  };

  const onLeave = (event: SyntheticEvent) => {
    // Prevent to propagate the click and be redirected to the group feed
    // https://8base-dev.atlassian.net/browse/JEB-1497
    event.stopPropagation();

    leaveGroup();
    closeOptions();
  };

  if (!showOptions) {
    return null;
  }

  return (
    <Container>
      <IconButton ref={anchor} onClick={handleOpen}>
        <MoreVert />
      </IconButton>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchor.current}
      >
        <List>
          {showViewDetails && (
            <ListItem button onClick={onViewDetails}>
              <WrapperIcon>
                <Edit />
              </WrapperIcon>

              <ListItemText primary="View Details" />
            </ListItem>
          )}

          {showLeave && (
            <ListItem button onClick={onLeave}>
              <WrapperIcon>
                <DeleteForever />
              </WrapperIcon>

              <ListItemText primary="Leave Group" />
            </ListItem>
          )}
        </List>
      </Popover>
    </Container>
  );
}
