import React from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { CommunitySidebar } from 'shared/features/community-sidebar';

import { CollectiveIntelligenceDetailContent } from '../components/CollectiveIntelligenceDetail';

export const CollectiveIntelligenceDetailPage = () => {
  return (
    <RouteLayout
      withMaxWidth
      maxWidth="75%"
      content={<CollectiveIntelligenceDetailContent />}
      sidebar={<CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />}
    />
  );
};
