import { useCallback, useEffect, useMemo, useState } from 'react';
import { Theme, css } from '@emotion/react';
import { Box, MenuItem, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  RouteLayout,
  Select,
  Button,
  createSnackMessage,
  SNACK_TYPES,
  AsyncContent,
} from 'shared/components/ui';
import { useUserRoles, useUserRolesAddons, useSchoolConfiguration } from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';

import { SettingsTitle } from '../SettingsTitle';
import { RoleEditForm } from './RoleEditForm';
import type { FormMode, RolesEditFormValues } from './Roles.types';
import { buildRoleAddonCreateOnUpdateInput } from './utils/utils';

const containerCSS = (theme: Theme) => css`
  --spacing: 2rem;

  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);

  ${theme.breakpoints.down('md')} {
    --spacing: 1rem;
  }
`;

const switchBoxCss = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${theme.spacing(1)}px;
`;

const switchCss = css`
  width: 100%;
`;

const NEW_ROLE_ID = 'new_role';

export function Roles() {
  const { roles, isLoading: isRolesLoading } = useUserRoles();

  const {
    rolesAddons,
    createRoleAddon,
    updateRoleAddon,
    isLoading: isRolesAddonsLoading,
    isUpdating: isRoleAddonUpdating,
    isCreating: isRoleAddonCreating,
  } = useUserRolesAddons();

  const { configuration, loading: isSchoolConfigurationLoading } = useSchoolConfiguration();

  const [currentRoleId, setCurrentRoleId] = useState<string>();
  const [formMode, setFormMode] = useState<FormMode>('edit');

  const { enqueueSnackbar } = useSnackbar();

  const isDataLoading = useMemo(() => {
    return isRolesLoading || isRolesAddonsLoading || isSchoolConfigurationLoading;
  }, [isRolesAddonsLoading, isRolesLoading, isSchoolConfigurationLoading]);

  const currentRoleInfo = useMemo(() => {
    if (formMode === 'create') {
      return { id: NEW_ROLE_ID };
    }

    return rolesAddons.find(role => role.id === currentRoleId);
  }, [currentRoleId, rolesAddons]);

  const layouts = useMemo(() => {
    if (isNil(configuration?.layout)) {
      return [];
    }

    const layoutItems = configuration?.layout.items || [];

    return layoutItems.filter(item => {
      return !item.isAlwaysShown;
    });
  }, [configuration?.layout]);

  useEffect(() => {
    if (!isRolesAddonsLoading && isNil(currentRoleId)) {
      if (rolesAddons.length > 0) {
        setCurrentRoleId(rolesAddons[0].id || '');
      } else {
        setCurrentRoleId(NEW_ROLE_ID);
        setFormMode('create');
      }
    }
  }, [currentRoleId, isRolesAddonsLoading, rolesAddons]);

  const onSubmit = useCallback(
    async (formValues: RolesEditFormValues) => {
      if (!currentRoleId) {
        return undefined;
      }

      if (formMode === 'create') {
        try {
          const newRoleId = await createRoleAddon(
            buildRoleAddonCreateOnUpdateInput(formValues, formMode),
          );
          enqueueSnackbar('Role created successfully', {
            autoHideDuration: 5000,
            content: createSnackMessage(SNACK_TYPES.success),
          });

          if (newRoleId) {
            setCurrentRoleId(newRoleId);
            setFormMode('edit');
          }
        } catch (error: any) {
          console.error(error);
          sendToSentry(`Error while creating role! ${error}`);
          enqueueSnackbar('Error while creating role!', {
            autoHideDuration: 5000,
            content: createSnackMessage(SNACK_TYPES.error),
          });
        }
      } else {
        try {
          await updateRoleAddon(
            currentRoleId,
            buildRoleAddonCreateOnUpdateInput(formValues, formMode),
          );
          enqueueSnackbar('Role updated successfully', {
            autoHideDuration: 5000,
            content: createSnackMessage(SNACK_TYPES.success),
          });
        } catch (error: any) {
          sendToSentry(`Error while updating role! ${error}`);
          enqueueSnackbar('Error while updating role!', {
            autoHideDuration: 5000,
            content: createSnackMessage(SNACK_TYPES.error),
          });
        }
      }
    },
    [currentRoleId, enqueueSnackbar, formMode, createRoleAddon, updateRoleAddon],
  );

  const switchFormMode = useCallback(() => {
    setFormMode(mode => (mode === 'create' ? 'edit' : 'create'));
  }, []);

  if (!currentRoleId) {
    return (
      <Typography variant="body1" padding={2} textAlign="center">
        Error while loading &quot;Roles and Permissions&quot;
      </Typography>
    );
  }

  return (
    <RouteLayout>
      <SettingsTitle>Roles and Permissions</SettingsTitle>

      <Box css={containerCSS}>
        <Box css={switchBoxCss}>
          <Select
            css={switchCss}
            label="Roles"
            variant="outlined"
            disabled={
              isDataLoading || isRoleAddonCreating || isRoleAddonUpdating || formMode === 'create'
            }
            value={currentRoleId}
            onChange={event => setCurrentRoleId(String(event.target.value))}
          >
            {rolesAddons.map(role => (
              <MenuItem key={role.id} value={role.id || undefined}>
                {role.name || role.role?.name || 'Unknown name'}
              </MenuItem>
            ))}
            <MenuItem value={NEW_ROLE_ID} style={{ display: 'none' }}>
              New role
            </MenuItem>
          </Select>

          <Box>
            <Button
              variant="text"
              style={{ whiteSpace: 'nowrap' }}
              disabled={
                isDataLoading ||
                isRoleAddonCreating ||
                isRoleAddonUpdating ||
                rolesAddons.length === 0
              }
              onClick={switchFormMode}
            >
              {formMode === 'create' ? 'Edit roles' : '+ Add new role'}
            </Button>
          </Box>
        </Box>

        <AsyncContent loading={isRolesAddonsLoading}>
          <RoleEditForm
            formMode={formMode}
            currentRoleInfo={currentRoleInfo}
            roles={roles}
            layouts={layouts}
            onSubmit={onSubmit}
            isLoading={isDataLoading || isRoleAddonUpdating || isRoleAddonCreating}
          />
        </AsyncContent>
      </Box>
    </RouteLayout>
  );
}
