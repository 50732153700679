import React from 'react';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';

import { ORGANIZATION_MANAGER_STATUS } from '@jebel/constants';

import { getUserFullName } from 'shared/utils/user';
import { useOrganizationById, useUpdateOrganization } from 'features/organizations/hooks';
import { Icon } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import {
  Spreadsheet,
  SpreadsheetCellAction,
  SpreadsheetCellActions,
} from 'shared/features/spreadsheet';
import { buildUrl } from 'shared/routes';
import { getUserStatusComponent } from 'admin-features/members/components/MembersSpreadsheet';
import { User } from 'shared/graphql';
import { useInboxContext } from 'providers/InboxProvider';
import { useCrudPermissions } from 'shared/hooks';

import { ManagersSpeadsheetHeader } from '../constants';

const managerStatusCss = (theme: any) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  height: 20px;
`;

const managerActiveCss = (theme: any) => css`
  ${managerStatusCss(theme)};
  background-color: ${theme.palette.info.light};
`;

const managerInvitationSendCss = (theme: any) => css`
  ${managerStatusCss(theme)};
  background-color: ${theme.palette.primary.light};
`;

const managerInactiveCss = (theme: any) => css`
  ${managerStatusCss(theme)};
  background-color: ${theme.palette.error.main};
`;

const switchChip = (status: string) => {
  switch (status) {
    case ORGANIZATION_MANAGER_STATUS.active: {
      return <Chip css={managerActiveCss} label="Active" size="medium" />;
    }
    case ORGANIZATION_MANAGER_STATUS.invited: {
      return <Chip css={managerInvitationSendCss} label="Invited" size="medium" />;
    }
  }
  return <Chip css={managerInactiveCss} label="Inactive" size="medium" />;
};

export const BusinessManagersSpreadsheet: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const { loading, managers } = useOrganizationById(id);
  const { organizationUpdate } = useUpdateOrganization();
  const { onOpenInboxModal } = useInboxContext();
  const {
    businessPermissions: { edit: ifEditAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const canEdit = ifEditAccessed && !loadingAddons;

  const spreadsheetActions = React.useMemo((): SpreadsheetCellActions => {
    const deleteAction: SpreadsheetCellAction = {
      id: 'Remove',
      title: 'Remove',
      onClickAction: async (rowId: string) => {
        await organizationUpdate({
          id,
          managers: {
            disconnect: [{ id: rowId }],
          },
        });
      },
      icon: <Icon name="DeleteForever" size={20} />,
    };

    const resultActions = [
      {
        id: 'View Profile',
        title: 'View Profile',
        onClickAction: (id: string) => {
          const managerId = managers.find(manager => manager.id === id)?.user?.id as string;
          const url = buildUrl(APP_URL.user.explorer.profile, { pathParams: { id: managerId } });
          history.push(url);
        },

        icon: <Icon name="AccountCircle" size={20} />,
      },
      {
        id: 'Message',
        title: 'Message',
        onClickAction: (id: string) => {
          const manager = managers.find(manager => manager.id === id)?.user;

          onOpenInboxModal({
            isOpen: true,
            options: {
              members: [manager as User],
              messageType: 'personal',
            },
          });
        },
        icon: <Icon name="ForumTwoTone" size={20} />,
      },
    ];

    if (canEdit) {
      resultActions.push(deleteAction);
    }

    return resultActions;
  }, [history, id, managers, organizationUpdate, canEdit]);

  const newData = React.useMemo(
    () =>
      managers.map(manager => ({
        id: manager.id || '',
        email: manager?.user?.email || '',
        name: getUserFullName(manager?.user),
        managerStatus: switchChip(manager?.status || ''),
        userStatus: getUserStatusComponent(manager?.user as any),
      })) || [],
    [managers],
  );

  const mainToolbarAction = React.useMemo(
    () => ({
      icon: <Icon name="Forum" size={20} color="secondary" />,
      label: 'Message',
      onClick: (ids: string[]) => {
        const members: Array<User> =
          ids
            .map(id => {
              const manager = managers.find(user => user.id === id);
              return manager?.user as User;
            })
            .filter(Boolean) ?? [];

        onOpenInboxModal({
          isOpen: true,
          options: {
            members,
            messageType: 'personal',
          },
        });
      },
    }),
    [managers, history],
  );

  return (
    <React.Fragment>
      <Spreadsheet
        data={newData}
        headlines={ManagersSpeadsheetHeader}
        toolbarOptions={{
          mainToolbarAction,
        }}
        cellActions={spreadsheetActions}
        itemsCount={managers.length || 0}
        loading={loading}
      />
    </React.Fragment>
  );
};
