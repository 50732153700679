import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: solid 1px ${p => p.theme.palette.divider};
  transition: box-shadow 0.2s ease;
  user-select: none;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.3rem;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled(Typography)`
  font-weight: bold;
`;

export const Description = styled(Typography)``;
