import { Fragment, useMemo } from 'react';

import { OrganizationItemFragment } from 'shared/graphql';
import { SUPPORTERS_RESULT_SIZE } from 'features/explorer/constants';
import { ExplorerBusinessCard } from 'features/organizations/components';

interface Props {
  supportersList: OrganizationItemFragment[];
  /** @default true */
  viewAll?: boolean;
}

export function ExplorerSupportersList(props: Props) {
  const supporters = useMemo(() => {
    return props.supportersList ?? [];
  }, [props.supportersList]);

  const showRest = props.viewAll ?? true;

  const previewSupporters = useMemo(() => {
    return supporters.slice(0, SUPPORTERS_RESULT_SIZE);
  }, [supporters]);

  const restSupporters = useMemo(() => {
    return showRest ? supporters.slice(SUPPORTERS_RESULT_SIZE) : [];
  }, [showRest, supporters]);

  return (
    <Fragment>
      {previewSupporters.map(item => (
        <ExplorerBusinessCard key={item?.id} data={item} withMessage withViewProfile />
      ))}

      {restSupporters.map(item => (
        <ExplorerBusinessCard key={item?.id} data={item} withMessage withViewProfile />
      ))}
    </Fragment>
  );
}
