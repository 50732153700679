import { forwardRef, ReactNode, useMemo } from 'react';
import { FieldValidator, useField, useFormikContext } from 'formik';

import { composeValidators, required } from 'shared/utils/form';

import { ComponentDataProps } from '../../types';
import { getIsInvalid } from '../common';
import { Select, SelectProps } from '../Select';

export type FormSelectProps = ComponentDataProps & {
  children?: ReactNode;
  selectProps: SelectProps;
  fieldProps: {
    name: string;
    validate?: FieldValidator;
    onChange?: SelectProps['onChange'];
    required?: boolean;
  };
};

/** @deprecate Use `AutocompleteField` instead. */
export const FormSelect = forwardRef<HTMLDivElement, FormSelectProps>(
  (
    {
      fieldProps,
      selectProps,
      children,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
    },
    ref,
  ) => {
    const form = useFormikContext();

    const validate = useMemo(() => {
      return composeValidators(
        fieldProps.required === true ? required : undefined,
        fieldProps.validate,
      );
    }, [fieldProps.required, fieldProps.validate]);

    const [field, meta] = useField({
      name: fieldProps.name,
      validate,
    });

    const isInvalid = useMemo(() => getIsInvalid({ meta, form }), [meta, form]);
    const errorText = useMemo(() => (isInvalid ? meta.error : ''), [isInvalid, meta.error]);

    return (
      <Select
        {...selectProps}
        name={fieldProps.name}
        value={field.value || undefined}
        onChange={fieldProps?.onChange ?? field.onChange}
        error={isInvalid}
        helperText={errorText}
      >
        {children ?? selectProps.children}
      </Select>
    );
  },
);
