import { gql } from '@apollo/client';

import { MINIMAL_USER_FRAGMENT } from 'shared/graphql';

export const GET_GROUP_MEMBERS_REQUEST = gql`
  query GET_GROUP_MEMBERS_REQUEST($userId: ID!) {
    groupsList(filter: { members: { some: { id: { equals: $userId } } } }) {
      items {
        id
      }
      count
    }
  }
`;
export const GET_GROUP_ADMINS = gql`
  query groupAdmins($groupIds: [ID!]) {
    groupAdminPreferencesList(filter: { group: { some: { id: { in: $groupIds } } } }) {
      items {
        id
        user {
          id
        }
      }
      count
    }
  }
`;

export const GROUP_MEMBERS_QUERY = gql`
  query GroupMembers($id: ID!) {
    group(id: $id) {
      creator: createdBy {
        ...MinimalUser
      }

      invitedUsers: groupToJoin {
        items {
          id
          status
          user {
            ...MinimalUser
          }
        }
      }

      adminUsers: groupAdminPreferences {
        items {
          id
          status
          user {
            ...MinimalUser
          }
        }
      }
    }
  }

  ${MINIMAL_USER_FRAGMENT}
`;
