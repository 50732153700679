import { SnackbarKey, useSnackbar } from 'notistack';

import { SNACK_TYPES, SnackMessage, SnackMessageProps } from 'shared/components/ui';
import { recordException } from 'shared/utils/record';

type Options = Omit<SnackMessageProps, 'message' | 'variant'>;

/** 15 seconds. */
const SNACK_DURATION = 15 * 1000;

export function useToast() {
  const { enqueueSnackbar: createSnack, closeSnackbar } = useSnackbar();

  const clean = () => {
    closeSnackbar();
  };

  const reportError = () => {
    const id = recordException();

    clean();
    showMessage(`Your error has been reported with ID: ${id}`);
  };

  const show = (props: SnackMessageProps) => {
    createSnack(props.message, {
      key: props.id,
      autoHideDuration: SNACK_DURATION,

      content(key, message) {
        return (
          <SnackMessage
            id={key}
            message={message}
            label={props.label}
            reportable={props.reportable}
            variant={props.variant}
            onClick={props.onClick}
            onReport={reportError}
          />
        );
      },
    });
  };

  const showMessage = (message: string, options?: Options) => {
    show({ message, variant: SNACK_TYPES.info, ...options });
  };

  const showSuccess = (message: string, options?: Options) => {
    show({ message, variant: SNACK_TYPES.success, ...options });
  };

  const showWarning = (message: string, options?: Options) => {
    show({ message, variant: SNACK_TYPES.warning, ...options });
  };

  const showError = (message: string, options?: Options) => {
    show({ message, variant: SNACK_TYPES.error, reportable: true, ...options });
  };

  const dismiss = (key: SnackbarKey) => {
    closeSnackbar(key);
  };

  return {
    showMessage,
    showSuccess,
    showWarning,
    showError,
    dismiss,
  };
}
