import { PropsWithChildren, createContext } from 'react';

import { StreamChatEnrichedActivity } from 'shared/types';

export interface StreamActivityContext {
  data?: StreamChatEnrichedActivity;
  loading: boolean;
}

/** Provides the `data` through components instead of passing by properties. */
export const STREAM_ACTIVITY_CONTEXT = createContext<StreamActivityContext>(null as any);

/** Provides the `data` through components instead of passing by properties. */
export function StreamActivityProvider(props: PropsWithChildren<StreamActivityContext>) {
  return (
    <STREAM_ACTIVITY_CONTEXT.Provider value={props}>
      {props.children}
    </STREAM_ACTIVITY_CONTEXT.Provider>
  );
}
