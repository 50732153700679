import React from 'react';

import { SchoolConfigurationLayout } from 'shared/graphql';
import { useModalState } from 'shared/hooks';
import { reorderItems } from 'shared/utils/array';

import { MainNavigationCard } from '../MainNavigationCard/MainNavigationCard';
import { LabelsList } from '../LabelsList';
import { LabelEditModal } from '../LabelEditModal';

type Props = {
  items: SchoolConfigurationLayout[];
  onListUpdate: (items: SchoolConfigurationLayout[]) => void;
};

export function MainNavigationSidebar({ items, onListUpdate }: Props) {
  const { open, close, show: isOpened, params: args } = useModalState<SchoolConfigurationLayout>();

  const onDragEnd = React.useCallback(
    (result: any) => {
      onListUpdate(reorderItems(items, result.source.index, result.destination.index));
    },
    [items, onListUpdate],
  );

  const onItemUpdate = React.useCallback(
    (item: SchoolConfigurationLayout) => {
      onListUpdate(items.map(i => (i.id === item.id ? item : i)));
      close();
    },
    [close, items, onListUpdate],
  );

  const onEditModalSave = React.useCallback(
    (item: SchoolConfigurationLayout) => {
      onItemUpdate(item);
      close();
    },
    [close, onItemUpdate],
  );

  return (
    <MainNavigationCard title="Label Names and Label Order">
      <LabelsList
        items={items}
        onDragEnd={onDragEnd}
        onItemUpdate={onItemUpdate}
        onModalOpen={open}
      />
      {isOpened && (
        <LabelEditModal item={args!} isOpen={isOpened} onClose={close} onSave={onEditModalSave} />
      )}
    </MainNavigationCard>
  );
}
