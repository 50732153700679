import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

export const CONTENT_HEIGHT = 4;

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// Aside

export const Aside = styled.div`
  --aside-width: 5rem;

  width: var(--aside-width);
  display: flex;
  flex-direction: column;
  position: relative;

  ${p => p.theme.breakpoints.down('sm')} {
    --aside-width: 3rem;
  }
`;

// CompanyLogo

export const CompanyLogo = styled(Avatar)`
  width: var(--aside-width);
  height: var(--aside-width);
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${p => p.theme.palette.divider};
  z-index: 1;
`;

CompanyLogo.defaultProps = {
  variant: 'square',
};

// CompanyName

export const CompanyName = styled.span`
  color: ${p => p.theme.palette.grey[600]};
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// ExperienceContent

export const ExperienceContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

// Description

export const Description = styled.span``;

// TimelineContainer

export const TimelineContainer = styled.div`
  --timeline-border-color: ${p => p.theme.palette.divider};
  --timeline-border-size: 2px;
  --timeline-thumb-size: calc(var(--timeline-border-size) * 6);
  --timeline-background-color: ${p => p.theme.palette.background.paper};

  width: var(--aside-width);
  background-color: var(--timeline-background-color);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(${CONTENT_HEIGHT}rem - 1rem);
`;

// TimelineDot

export const TimelineDot = styled.div`
  width: var(--timeline-border-size);
  height: ${CONTENT_HEIGHT}rem;
  background-color: var(--timeline-border-color);
  position: relative;

  &::after {
    content: '';
    display: block;
    width: var(--timeline-thumb-size);
    height: var(--timeline-thumb-size);
    border: solid var(--timeline-border-size) var(--timeline-border-color);
    background-color: var(--timeline-background-color);
    border-radius: var(--timeline-thumb-size);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
`;
