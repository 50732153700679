import React, { CSSProperties, forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { SerializedStyles } from '@emotion/react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  Select as MaterialSelect,
  SelectProps as MaterialSelectProps,
} from '@material-ui/core';

import { ComponentDataProps } from '../../types';

export type SelectProps = Omit<MaterialSelectProps, 'ref' | 'renderValue'> &
  ComponentDataProps &
  Pick<FormControlProps, 'variant' | 'size'> & {
    renderValue?: (value: string | string[] | number | number[]) => React.ReactNode;
    style?: CSSProperties;
    css?: SerializedStyles;
    helperText?: string;
  };

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      disabled,
      label,
      className,
      error,
      variant,
      size,
      renderValue,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      fullWidth,
      value,
      ...otherSelectProps
    },
    ref,
  ) => {
    const valueIsNotEmpty = value !== undefined && value !== null;
    const theme = useTheme();
    return (
      <FormControl
        variant={variant}
        size={size}
        disabled={!!disabled}
        className={className}
        ref={ref}
        fullWidth={!!fullWidth}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
        required={otherSelectProps.required}
      >
        <InputLabel
          style={{ backgroundColor: theme.palette.background.paper, padding: '0 5px' }}
          shrink={valueIsNotEmpty}
          required={otherSelectProps.required}
        >
          {label}
        </InputLabel>

        <MaterialSelect
          {...otherSelectProps}
          value={value}
          label={label}
          renderValue={renderValue as MaterialSelectProps['renderValue']}
          required={otherSelectProps.required}
          error={error}
        />

        {error && <FormHelperText error>{otherSelectProps.helperText}</FormHelperText>}
      </FormControl>
    );
  },
);
