import { Fragment, useEffect } from 'react';
import { css } from '@emotion/react';
import { Box, Divider } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { PaperLayout, RouteLayout } from 'shared/components/layouts';
import { Modal, Typography } from 'shared/components/ui';
import { USER_SORT_BY_NAME_INFO, SUPPORTERS } from 'shared/constants';
import { SearchControl, useSearchContext } from 'shared/features/search';
import { CommunitySidebar } from 'shared/features/community-sidebar';
import { usePageTitle, useResponsive } from 'shared/hooks';

import { UsersFilter } from '../../types';
import { ExplorerFilterModal } from './ExplorerFilterModal';
import { ExplorerFilterSidebar } from './ExplorerFilterSidebar';
import { ExplorerContent } from './ExplorerContent';

interface Params {
  year: string;
}

const topSortAndSearchBoxCSS = (theme: any) => css`
  display: grid;
  flex-direction: column;
  padding: ${theme.spacing(3.125)}px ${theme.spacing(3.75)}px;
`;

const topSortAndSearchBoxMobileCSS = (theme: any) => css`
  display: grid;
  grid-template-rows: auto;
  grid-gap: ${theme.spacing(1)}px;
  padding: ${theme.spacing(1)}px;
`;

const explorerNationCss = (theme: any) => css`
  font-size: ${theme.typography.fontSize + 6}px;
  font-weight: 400;
`;

const SEARCH_CONTROL_PROPS = {
  placeholder: 'Search',
  sortInfo: USER_SORT_BY_NAME_INFO,
};

export function ExplorerNation() {
  const title = usePageTitle({ fallback: 'Explorer Nation' });

  const {
    filter,
    setFilter,
    isFilterModalOpen: showFilterModal,
    setIsFilterModalOpen: setShowFilterModal,
  } = useSearchContext<UsersFilter>();

  const { isMobile } = useResponsive();
  const { year } = useParams<Params>();

  useEffect(() => {
    if (year && filter.graduatingYear === year) {
      return;
    }

    setFilter(prev => ({ ...prev, year }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleChangeFilter = (filter: UsersFilter) => {
    setFilter(filter);
  };

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: showFilterModal,
          onClose: closeFilterModal,
          fullWidth: true,
          maxWidth: 'xs',
        }}
        titleProps={{ title: 'Filter by' }}
        isVisibleDivider
        dialogContentWidth={280}
      >
        <ExplorerFilterModal onClose={closeFilterModal} initial={filter} onChange={setFilter} />
      </Modal>

      <RouteLayout
        withMaxWidth
        content={
          <PaperLayout>
            <Box
              css={isMobile ? topSortAndSearchBoxMobileCSS : topSortAndSearchBoxCSS}
              mx={isMobile && 1}
              mb={isMobile && 2}
            >
              <Typography
                variant={isMobile ? 'subtitle3' : 'subtitle2'}
                color="primary"
                css={isMobile && explorerNationCss}
              >
                {title}
              </Typography>

              <Box pt={!isMobile && 3.125}>
                <SearchControl
                  withTextField
                  withSortField
                  textFieldProps={SEARCH_CONTROL_PROPS}
                  sortFieldProps={SEARCH_CONTROL_PROPS}
                  elementsWidth={!isMobile ? ['1fr', '0.452fr'] : []}
                  withFilterModal={isMobile}
                />
              </Box>
            </Box>

            <Divider />

            <ExplorerContent />
          </PaperLayout>
        }
        sidebar={
          <Box display="grid" gridTemplateRows="auto" gridGap={15}>
            <PaperLayout css={{ padding: '1.5rem' }}>
              <ExplorerFilterSidebar initial={filter} onChange={handleChangeFilter} />
            </PaperLayout>

            <CommunitySidebar items={[SUPPORTERS]} />
          </Box>
        }
      />
    </Fragment>
  );
}
