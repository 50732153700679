import { parseDate } from './parseDates';
/**
 * Extract the `year` of a given `value` formatted as any supported date format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `year` from `value`.
 */
export function extractYear(value) {
    const date = parseDate(value);
    if (date.isValid) {
        return date.year;
    }
    return null;
}
/**
 * Extract the `month` of a given `value` formatted as any supported date format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @returns `month` from `value`.
 */
export function extractMonth(value) {
    const date = parseDate(value);
    if (date.isValid) {
        return date.month;
    }
    return null;
}
