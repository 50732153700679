import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Skeleton,
  Button,
  css,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { SxProp } from 'shared/types/styles';
import { getUserFullName } from 'shared/utils/user';
import { useMinimalUsersListQuery, useGraduatingYearInfoQuery } from 'shared/graphql/__generated__';
import type { MinimalUserFragment } from 'shared/graphql/__generated__';

const BOTTOM_BUTTONS_PADDING = 20;

const MAX_LIST_HEIGHT = 500;

const AVATAR_SIZE = 55;

const avatarStyles: SxProp = {
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
};

const listItemTextStyles: SxProp = theme => ({
  pl: 1,
  fontSize: theme.typography.fontSize,
});

const cancelButtonCSS = (theme: any) => css`
  padding: ${BOTTOM_BUTTONS_PADDING / 5}px ${BOTTOM_BUTTONS_PADDING}px;
  color: ${theme.palette.text.secondary};
`;

const listItemCSS = css`
  cursor: pointer;
  :hover {
    background-color: #abbeff;
  }
`;
interface GraduatedUsersListProps {
  users: MinimalUserFragment[];
  isLoading: boolean;
}

const GraduatedUsersList: React.FC<GraduatedUsersListProps> = ({ users, isLoading }) => {
  const history = useHistory();

  if (isLoading) {
    return (
      <React.Fragment>
        <List>
          {[0, 1, 3].map(key => (
            <ListItem key={key}>
              <ListItemAvatar>
                <Skeleton variant="circular" sx={avatarStyles} />
              </ListItemAvatar>
              <ListItemText primary={<Skeleton variant="rectangular" />} sx={listItemTextStyles} />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }

  if (users.length === 0) {
    return (
      <Typography color="GrayText" textAlign="center" p={2}>
        NO DATA
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <List>
        {users.map(user => (
          <ListItem
            key={user.id}
            onClick={() => {
              history.push(`/explorer/profile/${user.id}`);
            }}
            css={listItemCSS}
          >
            <ListItemAvatar>
              <Avatar
                srcSet={processFilestackUrlSrcSet(user.avatar?.downloadUrl || '', {
                  resize: {
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                  },
                })}
                src={user.avatar?.downloadUrl || ''}
                sx={avatarStyles}
              />
            </ListItemAvatar>
            <ListItemText primary={getUserFullName(user)} sx={listItemTextStyles} />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

interface ClassViewDedailsModalProps {
  classId: string;
  onModalClose: () => void;
}

export const ClassViewDedailsModal: React.FC<ClassViewDedailsModalProps> = ({
  classId,
  onModalClose,
}) => {
  const { data: classData, loading: isClassLoading } = useGraduatingYearInfoQuery({
    variables: { id: classId },
  });

  const graduatingYear = React.useMemo(() => {
    return Number(classData?.graduatingYear?.year || 0);
  }, [classData?.graduatingYear?.year]);

  const { data: usersData, loading: isUserLoading } = useMinimalUsersListQuery({
    variables: {
      filter: {
        AND: [
          { graduatingYear: { gte: `${graduatingYear}-01-01` } },
          { graduatingYear: { lt: `${graduatingYear + 1}-01-01` } },
        ],
      },
    },
    skip: graduatingYear === 0,
  });

  const isLoading = React.useMemo(() => {
    return isClassLoading || isUserLoading;
  }, [isClassLoading, isUserLoading]);

  const users = React.useMemo(() => {
    return usersData?.users.items || [];
  }, [usersData?.users.items]);

  return (
    <Box>
      <Typography variant="h6">Registered Alumni</Typography>

      <Box sx={{ maxHeight: MAX_LIST_HEIGHT, overflowY: 'auto' }}>
        <GraduatedUsersList users={users} isLoading={isLoading} />
      </Box>

      <Box display="flex" justifyContent="flex-end" pb={1}>
        <Button css={cancelButtonCSS} onClick={onModalClose} size="medium">
          CANCEL
        </Button>
      </Box>
    </Box>
  );
};
