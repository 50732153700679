import { useGlobalState } from 'providers';
import { OrganizationUpdateInput, useBusinessEditMutation } from 'shared/graphql';
import { recordError } from 'shared/utils/record';

export function useBusinessEdit() {
  const [mutate, { loading }] = useBusinessEditMutation({
    refetchQueries: ['BusinessSearch'],
  });

  const { toasts } = useGlobalState();

  const onBusinessEdit = async (data: OrganizationUpdateInput) => {
    try {
      await mutate({ variables: { data } });

      toasts.showSuccess('Success: Business details has been updated.');
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        toasts.showError(err.message);
      }
    }
  };

  return {
    onBusinessEdit,
    loading,
  };
}
