import { DateTime } from 'luxon';

export const getFileNameWithTimestamp = (filename: string): string => {
  const now = DateTime.now();

  const timestamp = now.toFormat('MMddyyyy_HHmmss');

  const extension = filename.split('.').pop() || '';

  const baseFilename = filename.replace(`.${extension}`, '');

  const timestampedFilename = `${baseFilename}_Report_${timestamp}.${extension}`;

  return timestampedFilename;
};
