import { useMutation, useLazyQuery } from '@apollo/client';

import { JOB_OFFER_UPDATE, JOB_QUERY } from '../queries';
import type {
  JobOfferByIdQueryVariables,
  JobOffer,
  JobOfferUpdateMutationVariables,
} from '../../../shared/graphql/__generated__';
import React from 'react';

const INCREMENT = 1;

export const useUpdateIncrementJobOffer = () => {
  const [exeQuery] = useLazyQuery<{ jobOffer: JobOffer }, JobOfferByIdQueryVariables>(JOB_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  const [updateJob] = useMutation<JobOffer, JobOfferUpdateMutationVariables>(JOB_OFFER_UPDATE);

  const incrementJobOffer = React.useCallback(async (id: string) => {
    const jobOfferQueryResponse = await exeQuery({ variables: { id } });
    const jobOffer = jobOfferQueryResponse?.data?.jobOffer as JobOffer;

    if (jobOffer?.id) {
      const response = await updateJob({
        variables: {
          data: { id, engagementCount: (jobOffer?.engagementCount || 0) + INCREMENT },
        },
        onCompleted(data) {},
      });
    }
  }, []);

  return incrementJobOffer;
};
