import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  MutationSchoolConfigurationExternalLinkCreateArgs,
  SchoolConfigurationExternalLinkCreateInput,
} from 'shared/graphql/__generated__';
import { sendToSentry } from 'shared/utils/sentry';

const SCHOOL_CONFIGURATION_EXTERNAL_LINK_ITEM_FRAGMENT = gql`
  fragment SchoolConfigurationExternalLinkItem on SchoolConfigurationExternalLink {
    id
    name
    link
    isActive
    order
  }
`;

export const SCHOOL_CONFIGURATION_EXTERNAL_LINK_CREATE_MUTATION = gql`
  mutation SchoolConfigurationExternalLinkCreate(
    $data: SchoolConfigurationExternalLinkCreateInput!
  ) {
    schoolConfigurationExternalLinkCreate(data: $data) {
      ...SchoolConfigurationExternalLinkItem
    }
  }
  ${SCHOOL_CONFIGURATION_EXTERNAL_LINK_ITEM_FRAGMENT}
`;

export const useSchoolConfigurationExternalLinkCreate = () => {
  const [schoolConfigurationExternalLinkCreateMutation, options] = useMutation<
    SchoolConfigurationExternalLinkCreateInput,
    MutationSchoolConfigurationExternalLinkCreateArgs
  >(SCHOOL_CONFIGURATION_EXTERNAL_LINK_CREATE_MUTATION, {
    refetchQueries: ['SchoolConfiguration'],
  });

  const schoolConfigurationExternalLinkCreate = React.useCallback(
    async (schoolConfigurationExternalLink: SchoolConfigurationExternalLinkCreateInput) => {
      try {
        await schoolConfigurationExternalLinkCreateMutation({
          variables: {
            data: schoolConfigurationExternalLink,
          },
        });
      } catch (e: any) {
        sendToSentry(e);
      }
    },
    [schoolConfigurationExternalLinkCreateMutation],
  );

  return [schoolConfigurationExternalLinkCreate, options] as const;
};
