/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Box } from '@mui/material';
import pluralize from 'pluralize';
import { Typography } from '@material-ui/core';

import { formatPhoneNumber } from '@jebel/utils';

import { Maybe, OrganizationLocationItemFragment } from 'shared/graphql';
import { getBusinessAddressShort } from 'shared/utils/address';

const containerCSS = (theme: Theme) => css`
  padding-top: ${theme.spacing(3.75)}px;
`;

const contentCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const locationNameCSS = css`
  font-weight: bold;
`;

const locationsContainerCss = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: ${theme.spacing(3)}px 0;
  grid-gap: ${theme.spacing(2)}px;

  &:not(:last-child) {
    border-bottom: solid 1px;
    border-color: ${theme.palette.divider};
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const nowrapCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const parseOperationsHours = (operationHoursInput: string) => operationHoursInput.split(',');

interface Props {
  locations?: Maybe<OrganizationLocationItemFragment[]>;
}

export function OrganizationLocations(props: Props) {
  const locations = useMemo(() => props.locations ?? [], [props.locations]);

  const title = useMemo(() => {
    const plural = pluralize('Location', locations.length);
    return `${plural} (${locations.length})`;
  }, [locations]);

  return (
    <Box css={containerCSS}>
      <Typography variant="h6">{title}</Typography>

      <Box css={contentCSS}>
        {locations.map((location, index) => {
          const [address] = location.address?.items ?? [];

          return (
            <Box key={location.id ?? index} css={locationsContainerCss}>
              <Box css={nowrapCss}>
                <Typography css={locationNameCSS}>{location.locationName}</Typography>
                <Typography css={nowrapCss}>{getBusinessAddressShort(address)}</Typography>

                {parseOperationsHours(location.operationalHours ?? '').map((line, index) => (
                  <Typography key={index} css={nowrapCss}>
                    {line}
                  </Typography>
                ))}

                {Boolean(location.phoneNumber) && (
                  <Typography>{formatPhoneNumber(location.phoneNumber)}</Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
