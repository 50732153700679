import { GROUP_POST_TYPE, POST_STATUSES } from '@jebel/constants';

import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { GroupPostUpdateInput, useUpdatePostMutation } from 'shared/graphql';

export function useGroupPostUpdate() {
  const [postUpdate, { loading }] = useUpdatePostMutation({
    refetchQueries: ['GroupPostsList', 'HomeFeedItems'],
    awaitRefetchQueries: true,
  });

  const onPostUpdate = async (data: GroupPostUpdateInput) => {
    if (!data.id) {
      throw new Error('Should sent a ID post to update');
    }

    // https://8base-dev.atlassian.net/browse/JEB-1553
    data.status = POST_STATUSES.pending;

    const response = await postUpdate({
      variables: { postData: data, postId: data.id, postType: GROUP_POST_TYPE },
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: `Your post will be moderated, we'll let you know when it's available on the feed.`,
        [SNACKBAR_ERROR_MESSAGE]: `Something went wrong updating the post. Try again later.`,
      },
    });

    return response;
  };

  return {
    onPostUpdate,
    loading,
  };
}
