import { Fragment, PropsWithChildren, useState } from 'react';

import { Modal } from 'shared/components/ui';
import { InboxCreateMessageModal } from 'features/inbox/components/InboxCreate';

import { InboxContext, InboxModalOptions } from './InboxContext';

export const InboxProvider = (props: PropsWithChildren<unknown>) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [options, setOptions] = useState<InboxModalOptions['options']>();

  const onModalClose = () => setIsCreateModalOpen(false);

  const onOpenInboxModal = (data: InboxModalOptions) => {
    setIsCreateModalOpen(data?.isOpen || false);

    setOptions({
      messageType: data?.options?.messageType || 'personal',
      members: data?.options?.members || [],
    });
  };

  return (
    <InboxContext.Provider value={{ onOpenInboxModal }}>
      <Fragment>
        <Modal
          dialogProps={{
            maxWidth: 'sm',
            fullWidth: true,
            open: isCreateModalOpen,
            onClose: onModalClose,
          }}
          titleProps={{ title: 'New Message' }}
          isVisibleDivider
        >
          <InboxCreateMessageModal
            onModalClose={onModalClose}
            members={options?.members as any}
            messageType={options?.messageType as any}
          />
        </Modal>
        {props.children}
      </Fragment>
    </InboxContext.Provider>
  );
};
