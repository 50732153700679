import { SNACKBAR_SUCCESS_MESSAGE, SNACKBAR_ERROR_MESSAGE } from 'shared/constants';
import { JobEmployeeCreateInput, useJobEmployeeCreateMutation } from 'shared/graphql';

export function useProfessionalProfileCreate() {
  const [mutate] = useJobEmployeeCreateMutation({
    refetchQueries: ['UserPublicInfo'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Experience successfully added',
      [SNACKBAR_ERROR_MESSAGE]: 'Experience was not added',
    },
  });

  const createExperience = async (data: JobEmployeeCreateInput) => {
    return mutate({ variables: { data } });
  };

  return {
    createExperience,
  };
}
