import React from 'react';
import { css, IconButton } from '@mui/material';

import { Icon } from 'shared/components/ui';

import { useSearchContext } from '../../providers';

export interface MobileFilterProps {
  onClick?: () => void;
}

const BTN_SIZE = 32;

const btnCss = css`
  padding: 0;
  justify-self: flex-end;
  width: ${BTN_SIZE}px;
  height: ${BTN_SIZE}px;
`;

export const MobileFilter: React.FC<MobileFilterProps> = ({ onClick }) => {
  const { setIsFilterModalOpen } = useSearchContext();

  const handleClick = () => {
    onClick?.();
    setIsFilterModalOpen(true);
  };

  return (
    <IconButton color="default" size="medium" onClick={handleClick}>
      <Icon htmlColor="#283E55" name="FilterAlt" />
    </IconButton>
  );
};
