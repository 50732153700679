import React from 'react';
import { css, Theme } from '@emotion/react';
import { Autocomplete, TextField, Box, Chip } from '@mui/material';

import { Button, Form } from 'shared/components/ui';
import { CurrentUserFragment } from 'shared/graphql';
import { USER_ACTIVITIES_OPTIONS } from 'shared/constants';

import { useHobbiesUpdate } from '../../hooks';

const commonButtonsCSS = (theme: Theme) => css`
  padding: ${theme.spacing(0.5)}px ${theme.spacing(2)}px;
  margin-left: ${theme.spacing(2)}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
`;

const membersInputCss = theme => css`
  fieldset {
    border-color: ${theme.palette.primary.dark};
  }
`;

export const EditHobbiesModal = ({
  onModalClose,
  hobbies,
}: {
  onModalClose: () => void;
  hobbies: CurrentUserFragment['hobbies'];
}) => {
  const { onUpdateHobbies } = useHobbiesUpdate();
  const [selectedOptions, setSelectedOptions] = React.useState<any>(
    hobbies?.map(hobby => ({
      value: hobby || '',
      label: USER_ACTIVITIES_OPTIONS.hobbies.find(option => option.value === hobby)?.label || '',
    })),
  );

  const handleChange = React.useCallback((_, options) => {
    setSelectedOptions(options);
  }, []);

  const onSubmit = React.useCallback(async () => {
    await onUpdateHobbies(selectedOptions?.map(selected => selected.value) ?? []);
    onModalClose();
  }, [onUpdateHobbies, selectedOptions, onModalClose]);

  return (
    <Form onSubmit={onSubmit} validateOnBlur={false}>
      {({ isSubmitting }) => (
        <React.Fragment>
          <Box display="grid" gridTemplateRows="auto" gap={2} my={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              multiple
              options={USER_ACTIVITIES_OPTIONS.hobbies}
              defaultValue={hobbies?.map(hobby => ({
                value: hobby || '',
                label:
                  USER_ACTIVITIES_OPTIONS.hobbies.find(option => option.value === hobby)?.label ||
                  '',
              }))}
              filterSelectedOptions
              onChange={handleChange}
              getOptionLabel={option => option.label}
              renderTags={(
                value: any,
                getTagProps: (option, index: number) => JSX.IntrinsicAttributes,
              ) =>
                value.map((option, index: number) => (
                  <Chip key={option.value} label={option.label} {...getTagProps(option, index)} />
                ))
              }
              renderInput={(params: any) => {
                return (
                  <React.Fragment>
                    <TextField
                      {...params}
                      css={membersInputCss}
                      label="Hobbies & Interests"
                      placeholder="Enter hobbies"
                      variant="outlined"
                    />
                  </React.Fragment>
                );
              }}
            />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              css={cancelButtonCSS}
              onClick={onModalClose}
              disabled={isSubmitting}
              size="medium"
            >
              CANCEL
            </Button>
            <Button
              loading={isSubmitting}
              css={submitButtonCSS}
              color="primary"
              variant="contained"
              type="submit"
            >
              SAVE CHANGES
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Form>
  );
};
