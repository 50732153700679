import { lazy, Suspense } from 'react';
import { CircularProgress } from '@material-ui/core';

import { RouteLayout } from 'shared/components/layouts';
import { PostFeedLoader } from 'shared/components/ui';

const HomeFeedContent = lazy(() => import('../components/HomeFeed/HomeFeedContent'));
const CommunitySidebar = lazy(
  () => import('shared/features/community-sidebar/CommunitySidebarDefaultExport'),
);

export function HomePage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <Suspense fallback={<PostFeedLoader />}>
          <HomeFeedContent />
        </Suspense>
      }
      sidebar={
        <Suspense fallback={<CircularProgress />}>
          <CommunitySidebar />
        </Suspense>
      }
    />
  );
}
