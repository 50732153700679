import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMemo } from 'react';

import { ExplorerUserCard } from 'features/explorer/components';
import { AsyncContent } from 'shared/components/ui';
import { useStreamActivityContext } from 'shared/features/posts/providers';
import { UserFilter, useUserSearchQuery } from 'shared/graphql';

interface Props {
  show: boolean;
  haveBeenOpened?: boolean;

  onClose(): void;
}

export function HomeFeedCardLikedModal(props: Props) {
  const { data: activity } = useStreamActivityContext();

  const reactions = useMemo(() => activity?.latest_reactions?.like ?? [], [activity]);

  const filter = useMemo(() => {
    const ids = reactions.map(reaction => reaction.user_id);
    const filter: UserFilter = { id: { in: ids } };

    return filter;
  }, [reactions]);

  const { data: response, loading: likedUsersLoading } = useUserSearchQuery({
    // Skip when the activity is not loaded, the dialog has not been seen or there are no reactions.
    // This is useful to prevent unnecessary requests.
    skip: !props.haveBeenOpened || !activity?.id || !reactions.length,
    variables: { filter },
  });

  const likedUsers = useMemo(() => {
    return response?.users?.items ?? [];
  }, [response]);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.show} onClose={props.onClose}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gridGap="0.5rem"
        paddingRight={2}
      >
        <DialogTitle>Likes</DialogTitle>

        <IconButton onClick={props.onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent dividers>
        <AsyncContent loading={likedUsersLoading}>
          <Box display="flex" flexDirection="column" gridGap="1rem">
            {likedUsers.length === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="10rem"
              >
                <Typography align="center">No users like the post yet.</Typography>
              </Box>
            ) : (
              likedUsers.map(user => (
                <ExplorerUserCard key={user.id} data={user} withDivider withMessage />
              ))
            )}
          </Box>
        </AsyncContent>
      </DialogContent>
    </Dialog>
  );
}
