import { PropsWithChildren, ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitleProps,
} from '@material-ui/core';

export type ModalProps = {
  dialogProps: DialogProps;
  titleProps?: DialogTitleProps & {
    title?: string;
    titleComponent?: ReactNode;
  };
  contentProps?: DialogContentProps;
  isVisibleDivider?: boolean;
  withPadding?: boolean;
  dialogContentWidth?: number;
};

/** @deprecated use `Dialog` instead. */
export function Modal({
  isVisibleDivider = true,
  withPadding = true,
  children,
  dialogContentWidth = 600,
  ...modalProps
}: PropsWithChildren<ModalProps>) {
  const hasTitle =
    Boolean(modalProps.titleProps?.title) || Boolean(modalProps.titleProps?.titleComponent);

  return (
    <Dialog {...modalProps.dialogProps}>
      {hasTitle && (
        <DialogTitle {...modalProps.titleProps}>
          {modalProps.titleProps?.title || modalProps.titleProps?.titleComponent}
        </DialogTitle>
      )}

      <DialogContent dividers={isVisibleDivider} {...modalProps.contentProps}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
