import { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, MenuItem } from '@mui/material';
import { Box } from '@material-ui/core';

import { formatISO8601Date } from '@jebel/utils';

import { DatePicker, Select, SelectProps } from 'shared/components/ui';
import { ROUTES, SELECT_OPTION_ALL } from 'shared/constants';
import { useQueryParams, useResponsive } from 'shared/hooks';
import { LegacyFilterType } from 'features/legacy/types';
import { useSearchContext } from 'shared/features/search';
import { useLegacyTimelineYears } from 'features/legacy/hooks';

interface RouteParams {
  year?: string;
  month?: string;
}

interface SearchParams {
  start?: string;
  end?: string;
}

export function LegacyFilterFields() {
  const [query, { setParam, removeParam, setParams }] = useQueryParams<SearchParams>();

  const { isMobile } = useResponsive();
  const { push: navigate } = useHistory();
  const { path } = useRouteMatch();
  const { data: optionYears, loading } = useLegacyTimelineYears({ withPagination: false });
  const { filter: filters, setFilter } = useSearchContext<LegacyFilterType>();

  const params = useParams<RouteParams>();

  const selectedYear = params.year ?? SELECT_OPTION_ALL;
  const selectedMonth = params.month;

  const year = Number(selectedYear);
  const month = Number(selectedMonth);

  const minDate = useMemo(() => {
    if (!isNaN(year) && !isNaN(month)) {
      return DateTime.local(year, month).startOf('month').toISO();
    }

    if (!isNaN(year)) {
      return DateTime.local(year).startOf('year').toISO();
    }

    return undefined;
  }, [year, month]);

  const maxDate = useMemo(() => {
    if (!isNaN(year) && !isNaN(month)) {
      return DateTime.local(year, month).endOf('month').toISO();
    }

    if (!isNaN(year)) {
      return DateTime.local(year).endOf('year').toISO();
    }

    return undefined;
  }, [year, month]);

  const selectedStartDate = query?.start ?? minDate;
  const selectedEndDate = query?.end ?? maxDate;

  const isFeed = path === ROUTES.user.legacy.all || path === ROUTES.user.legacy.month;

  const setStartDate = (date: string | undefined) => {
    if (date === undefined) {
      removeParam('start');
      return;
    }

    setParam('start', date);
  };

  const setEndDate = (date: string | undefined) => {
    if (date === undefined) {
      removeParam('end');
      return;
    }

    setParam('end', date);
  };

  useEffect(() => {
    setFilter({
      legacyStartDate: selectedStartDate,
      legacyEndDate: selectedEndDate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  const resetFilters = () => {
    setParams({ start: undefined, end: undefined });
  };

  const openFeed = (params: Record<string, string>) => {
    const search = new URLSearchParams(params);
    navigate(`${ROUTES.user.legacy.all}?${search}`);
  };

  const handleChangeStartDate = (value: DateTime | null) => {
    if (!value) {
      return;
    }

    const formatted = formatISO8601Date(value);

    if (!isFeed) {
      openFeed({ start: formatted });
      return;
    }

    setStartDate(formatted);
  };

  const handleChangeEndDate = (value: DateTime | null) => {
    if (!value) {
      return;
    }

    const formatted = formatISO8601Date(value);

    if (!isFeed) {
      openFeed({ end: formatted });
      return;
    }

    setEndDate(formatted);
  };

  const handleChangeYear: SelectProps['onChange'] = event => {
    const year = String(event.target.value);

    if (year === SELECT_OPTION_ALL) {
      return navigate(ROUTES.user.legacy.all);
    }

    const route = generatePath(ROUTES.user.legacy.year, { year });
    navigate(route);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      {!isMobile && (
        <Select
          disabled={loading}
          label="Year"
          onChange={handleChangeYear}
          value={selectedYear}
          variant="outlined"
        >
          <MenuItem value={SELECT_OPTION_ALL}>All</MenuItem>

          {optionYears.map(option => (
            <MenuItem key={option.year} value={String(option.year)}>
              {option.year}
            </MenuItem>
          ))}
        </Select>
      )}

      <DatePicker
        value={selectedStartDate ?? null}
        onChange={handleChangeStartDate}
        label="Legacy Start Date"
        inputVariant="outlined"
        inputProps={{ readonly: true, disabled: true }}
        minDate={minDate}
        maxDate={maxDate ?? selectedEndDate}
        autoOk={false}
      />

      <DatePicker
        value={selectedEndDate ?? null}
        onChange={handleChangeEndDate}
        label="Legacy End Date"
        inputVariant="outlined"
        inputProps={{ readonly: true, disabled: true }}
        minDate={minDate ?? selectedStartDate}
        maxDate={maxDate}
        autoOk={false}
      />

      {(filters?.legacyStartDate || filters?.legacyEndDate) && (
        <Button onClick={resetFilters}>Clear Filters</Button>
      )}
    </Box>
  );
}
