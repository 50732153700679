import React, { Fragment } from 'react';
import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, MenuItem } from '@material-ui/core';
import { MyLocation } from '@material-ui/icons';

import { SORT } from '@jebel/constants';

import { Icon, Typography, Button, Select } from 'shared/components/ui';
import { SELECT_OPTION_ALL } from 'shared/constants';
import { DISCOUNT_CATEGORIES, DISCOUNT_CATEGORIES_MOBILE } from 'shared/constants/discounts';
import {
  SearchControl,
  SearchTextField,
  SortFieldProps,
  useSearchContext,
} from 'shared/features/search';
import { usePageTitle, useResponsive } from 'shared/hooks';

import { ZipFilterField } from '../../../shared/features/search/components/ZipFilterField';

const SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Most Recent',
        value: {
          createdAt: SORT.desc,
        },
      },
      {
        label: 'Category',
        value: {
          category: SORT.asc,
        },
      },
      {
        label: 'Expiring Soon',
        value: {
          expirationDate: SORT.asc,
        },
      },
    ],
  },
};

const titleCss = (isMobile: boolean) => (theme: any) =>
  css`
    ${isMobile && `font-size: ${theme.typography.fontSize + 6}px;`}
    font-weight: 400;
  `;

interface DiscountHeaderType {
  currentLocationLoading: boolean;
  onPositionGet: () => Promise<void>;
}

export const DiscountsHeader: React.FC<DiscountHeaderType> = ({
  currentLocationLoading,
  onPositionGet,
}) => {
  const { filter, setFilter } = useSearchContext();
  const { isMobile } = useResponsive();

  const title = usePageTitle({ fallback: 'Discounts' });

  const onFilterSelect = React.useCallback(
    (selectedFilter: string) => () => {
      if (filter === selectedFilter) {
        setFilter(SELECT_OPTION_ALL);
        return;
      }

      setFilter(selectedFilter);
    },
    [setFilter, filter],
  );

  const onFilterSelectMobile = React.useCallback(
    selectedFilterEvent => {
      const selectedFilter = selectedFilterEvent.target.value;
      if (filter === selectedFilter) {
        setFilter(SELECT_OPTION_ALL);
        return;
      }

      setFilter(selectedFilter);
    },
    [setFilter, filter],
  );

  return (
    <Box css={containerCSS}>
      <Typography
        variant={isMobile ? 'subtitle3' : 'subtitle2'}
        color="primary"
        css={titleCss(isMobile)}
      >
        {title}
      </Typography>

      <Box css={filtersCSS}>
        {!isMobile && (
          <Fragment>
            <SearchTextField placeholder="Search" />
            <ZipFilterField placeholder="Zip Code" />

            <Button
              color="secondary"
              variant="outlined"
              startIcon={<MyLocation />}
              onClick={onPositionGet}
              loading={currentLocationLoading}
            >
              Use current location
            </Button>
          </Fragment>
        )}

        <SearchControl
          withFilterModal={isMobile}
          withSortField
          withTextField={isMobile}
          textFieldProps={{ placeholder: 'Search', fullWidth: true }}
          sortFieldProps={SORT_INFO}
        />
      </Box>

      {isMobile ? (
        <Select variant="outlined" label="Category" onChange={onFilterSelectMobile} value={filter}>
          {DISCOUNT_CATEGORIES_MOBILE.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Box css={categoriesContainerCSS}>
          {DISCOUNT_CATEGORIES.map(item => (
            <CategoryItem key={item.key} onClick={onFilterSelect(item.key)}>
              <CategoryItemIcon isActive={filter === item.key}>
                <Icon name={item.icon} />
              </CategoryItemIcon>

              <Typography variant="caption">{item.value}</Typography>
            </CategoryItem>
          ))}
        </Box>
      )}
    </Box>
  );
};

const categoriesContainerCSS = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, 5rem);
  justify-content: center;
  gap: 1rem;
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
`;

interface CategoryItemIconProps {
  isActive?: boolean;
}

const CategoryItemIcon = styled.div<CategoryItemIconProps>(p => {
  const backgroundColor = p.isActive
    ? p.theme.palette.primary.main
    : p.theme.palette.secondary.main;

  const color = p.theme.palette.getContrastText(backgroundColor);

  const hoverBackgroundColor = p.isActive
    ? p.theme.palette.primary.dark
    : p.theme.palette.secondary.dark;

  const hoverColor = p.theme.palette.getContrastText(hoverBackgroundColor);

  return css`
    --size: 4rem;

    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    background-color: ${backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${color};

    :hover {
      color: ${hoverColor};
      background-color: ${hoverBackgroundColor};
    }
  `;
});

const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  ${theme.breakpoints.down('sm')} {
    padding: 1rem;
    gap: 0.5rem;
  }
`;

const filtersCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }
`;
