import { Box, css } from '@mui/material';

import { PaperLayout } from 'shared/components/layouts';
import { Icon, Typography } from 'shared/components/ui';
import { SxProp } from 'shared/types/styles';

import { LegacyFilterFields } from './LegacyFilterFields';

const filterByCSS = (theme: any) => css`
  margin-left: ${theme.spacing(0.75)}px;
  font-size: ${theme.typography.fontSize + 4}px;
  font-weight: ${theme.typography.fontWeightMedium};
  line-height: 25px;
`;

const filterFieldsBox: SxProp = theme => ({
  display: 'grid',
  pt: 3.125,
  gridTemplateRows: 'auto',
  gridGap: theme.spacing(3.125),
});

export function LegacyFilter() {
  return (
    <PaperLayout sx={{ py: 3.125, px: 3.75 }}>
      <Box display="flex" alignItems="center">
        <Icon name="FilterAlt" />

        <Typography css={filterByCSS} variant="subtitle5">
          Filter by
        </Typography>
      </Box>

      <Box sx={filterFieldsBox}>
        <LegacyFilterFields />
      </Box>
    </PaperLayout>
  );
}
