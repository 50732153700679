import { css, Theme } from '@emotion/react';
import { List, ListItemText } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { NavListItemSymbol, ListItemIconSymbol } from 'shared/components/symbols';

const listItemContainerCss = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const listItemIconCss = (theme: Theme) => css`
  margin-right: 10px;
  fill: ${theme.palette.text.secondary};
`;

const containerCss = (theme: Theme) => css`
  border-bottom: solid 1px;
  border-bottom-color: ${theme.palette.divider};
`;

type SettingsSidebarListItemProps = {
  title: string;
  icon: string;
  route: string;
};

type SettingsSidebarListProps = {
  listData: SettingsSidebarListItemProps[];
};

export const SettingsSidebarList = ({ listData }: SettingsSidebarListProps) => {
  const location = useLocation();

  return (
    <List css={containerCss}>
      {listData.map(item => (
        <NavListItemSymbol
          css={listItemContainerCss}
          key={item.route}
          path={item.route}
          selected={location.pathname === item.route}
          button
          disableGutters
          startIcon={
            <ListItemIconSymbol
              css={listItemIconCss}
              name={item.icon}
              variant="filled"
              color="inherit"
              fontSize="default"
              viewBox="0 0 24 24"
            />
          }
          listItemText={<ListItemText disableTypography={false}>{item.title}</ListItemText>}
        />
      ))}
    </List>
  );
};
