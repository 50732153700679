export var BillingType;
(function (BillingType) {
    /** Use with charges or deposits related with advertising. */
    BillingType["Advertising"] = "ad";
    /** Use with charges or deposits related with special offers. */
    BillingType["SpecialOffer"] = "specialOffer";
    /** Use with charges or deposits related with school support subscription plan. */
    BillingType["SchoolSupport"] = "schoolSupport";
    /** Use when a new payment method is added to the organization. */
    BillingType["CreatePaymentMethod"] = "createPayment";
})(BillingType || (BillingType = {}));
export var BillingRegisteredSchoolSupportStatus;
(function (BillingRegisteredSchoolSupportStatus) {
    BillingRegisteredSchoolSupportStatus["Paid"] = "paid";
    BillingRegisteredSchoolSupportStatus["NotPaid"] = "notPaid";
})(BillingRegisteredSchoolSupportStatus || (BillingRegisteredSchoolSupportStatus = {}));
export var BillingRegisteredSpecialOfferStatus;
(function (BillingRegisteredSpecialOfferStatus) {
    BillingRegisteredSpecialOfferStatus["Paid"] = "paid";
    BillingRegisteredSpecialOfferStatus["NotPaid"] = "notPaid";
})(BillingRegisteredSpecialOfferStatus || (BillingRegisteredSpecialOfferStatus = {}));
export var BillingRegisteredAdvertisingStatus;
(function (BillingRegisteredAdvertisingStatus) {
    BillingRegisteredAdvertisingStatus["Paid"] = "paid";
    BillingRegisteredAdvertisingStatus["NotPaid"] = "notPaid";
})(BillingRegisteredAdvertisingStatus || (BillingRegisteredAdvertisingStatus = {}));
