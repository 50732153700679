import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from 'shared/constants';

import { SearchPageHeader } from './SearchPageHeader';
import { SearchPagePeople } from './SearchPagePeople';
import { SearchPagePosts } from './SearchPagePosts';
import { Container, Content } from './SearchPageContent.styles';
import { SearchPageOrganizations } from './SearchPageOrganizations';

export function SearchPageContent() {
  return (
    <Container>
      <SearchPageHeader />

      <Content>
        <Switch>
          <Route path={ROUTES.user.search.all}>
            <SearchPagePeople />
            <SearchPageOrganizations />
            <SearchPagePosts />
          </Route>

          <Route path={ROUTES.user.search.organizations}>
            <SearchPageOrganizations canFetchMore />
          </Route>

          <Route path={ROUTES.user.search.people}>
            <SearchPagePeople canFetchMore />
          </Route>

          <Route path={ROUTES.user.search.posts}>
            <SearchPagePosts canFetchMore />
          </Route>

          <Redirect to={ROUTES.user.search.all} />
        </Switch>
      </Content>
    </Container>
  );
}
