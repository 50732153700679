import { useMemo } from 'react';

import type { MembersStats } from '@jebel/constants';

import { useMemberStatsQuery } from 'shared/graphql';

export const useMembersStats = () => {
  const { data, loading, refetch } = useMemberStatsQuery();

  const membersStats = useMemo((): MembersStats => {
    return (
      data?.getMembersStats || {
        totalCount: 0,
        pendingApproval: 0,
        students: 0,
        parents: 0,
        alumni: 0,
        schoolStaff: 0,
      }
    );
  }, [data?.getMembersStats]);

  const refetchStats = async () => {
    await refetch();
  };

  return {
    membersStats,
    loading,
    refetchStats,
  };
};
