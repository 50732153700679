/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { isEmpty } from 'ramda';
import { MenuItem, Box } from '@material-ui/core';
import { FieldArray } from 'formik';

import { formatISO8601Date, parseDate } from '@jebel/utils';

import {
  Button,
  Form,
  FormKeyboardDatePicker,
  FormPhoneFieldV2 as FormPhoneField,
  FormSelect,
  FormTextField,
  RouteLayout,
  Icon,
  AutocompleteField,
} from 'shared/components/ui';
import { FIELDS_CONFIG_USER, US_STATES_OPTIONS } from 'shared/constants';
import { omitDeep } from 'shared/utils/object';
import {
  useCrudPermissions,
  useUserById,
  useUserUpdate,
  useResponsive,
  useToast,
} from 'shared/hooks';
import { SxProp } from 'shared/types/styles';
import { UserUpdateInput, UsersUserPreferencesUpdateRelationInput } from 'shared/graphql';
import { required } from 'shared/utils/form';
import { recordError } from 'shared/utils/record';

const accountInformationCSS = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  flex-direction: column;
  height: 100%;
`;

const formContainerCss = theme => css`
  padding: ${theme.spacing(3.125)}px ${theme.spacing(3.75)}px;
`;

const titleStyles: SxProp = theme => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  fontSize: theme.typography.fontSize + 7,
});

const formCss = css`
  max-width: 600px;
  display: grid;
  grid-gap: 20px;
`;

const rowLayoutCss = (isMobile: boolean) => css`
  display: grid;
  grid-gap: 20px;
  ${isMobile ? `grid-template-rows: 1fr 1fr;` : `grid-template-columns: 1fr 1fr;`}
`;

const buttonsContainerCss = css`
  display: flex;
  justify-content: flex-end;
`;

const commonButtonsCss = css`
  padding-left: 35px;
  padding-right: 35px;
`;

const submitButtonCss = (theme: any) => css`
  ${commonButtonsCss};
  background-color: ${theme.palette.primary.light};
`;

const cancelButtonCss = (theme: any) => css`
  ${commonButtonsCss};
  color: ${theme.palette.primary.dark};
  margin-right: 20px;
`;

const containerCss = (theme: any) => css`
  padding: ${theme.spacing(3.125)}px ${theme.spacing(3.75)}px;
  border-bottom: 1px solid ${theme.palette.border};
`;

type Parent = {
  fullName: string;
  classYear?: string | null;
};

type FormData = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  gender?: string | null | undefined;
  affiliation?: string | null | undefined;
  graduatingYear?: string | null | undefined;
  birthDate?: string | null | undefined;
  parents?: Array<Parent>;
  description?: string | null | undefined;
  userPreferences?:
    | {
        phoneNumber?: string | null | undefined;
        secondaryPhoneNumber?: string | null | undefined;
        address?:
          | {
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export function MemberInformationContent() {
  const {
    membersPermissions: { edit: isEditAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const { id: userId } = useParams<{ id: string }>();
  const { user } = useUserById({ id: userId });
  const { onUserUpdate } = useUserUpdate({ id: userId });
  const { goBack } = useHistory();
  const { isMobile } = useResponsive();
  const { showError } = useToast();

  const [isParentsAvailable, setIsParentsAvailable] = useState<boolean>(
    !isEmpty(user?.parents ?? []),
  );

  const initialValues = useMemo<FormData>(() => {
    const userData = omitDeep(
      [
        '__typename',
        'id',
        'fullName',
        'roles',
        'joinedGroups',
        'ownedOrganizations',
        'rolesAddons',
        'avatar',
        'createdGroups',
        'viewedThreads',
        'adminPreferences',
        'managerPreferences',
        'education',
      ],
      user,
    );

    const initialParents = [
      {
        fullName: '',
        classYear: '',
      },
    ];

    const birthDate = parseDate(user?.birthDate);

    const payload: FormData = {
      ...userData,

      birthDate: null,
      parents: isEmpty(userData?.parents || []) ? initialParents : userData?.parents,

      userPreferences: {
        ...userData?.userPreferences,
        phoneNumber: userData?.userPreferences?.phoneNumber?.length
          ? userData?.userPreferences?.phoneNumber
          : '',
        secondaryPhoneNumber: userData?.userPreferences?.secondaryPhoneNumber?.length
          ? userData?.userPreferences?.secondaryPhoneNumber
          : '',
      },
    };

    if (birthDate.isValid) {
      payload.birthDate = formatISO8601Date(birthDate);
    }

    return payload;
  }, [user]);

  const handleSubmit = async (form: FormData) => {
    const birthDate = parseDate(form.birthDate)?.endOf('day');
    const parents = isParentsAvailable ? form.parents : [];

    const userPreferences: UsersUserPreferencesUpdateRelationInput = {
      create: {
        phoneNumber: form.userPreferences?.phoneNumber,
        secondaryPhoneNumber: form.userPreferences?.secondaryPhoneNumber,

        address: {
          create: form.userPreferences?.address,
        },
      },
    };

    const data: UserUpdateInput = {
      ...form,

      birthDate: null,
      userPreferences,
      parents,
    };

    if (birthDate?.isValid) {
      data.birthDate = birthDate.toSQLDate();
    }

    try {
      await onUserUpdate(data);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onChangeRadio = useCallback(
    e => {
      const { value } = e.target;
      setIsParentsAvailable(value === 'true');
    },
    [setIsParentsAvailable],
  );

  return (
    <RouteLayout css={accountInformationCSS}>
      <Box css={containerCss}>
        <Typography sx={titleStyles}>Account Information</Typography>
      </Box>

      <Box css={formContainerCss}>
        <Form
          oldCss={formCss}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
        >
          {({ isSubmitting, resetForm, values, ...form }) => (
            <Fragment>
              <Box css={rowLayoutCss(isMobile)}>
                <FormTextField
                  disabled={!isEditAccessed}
                  inputProps={{
                    label: 'First Name',
                    variant: 'outlined',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'firstName', validate: required }}
                />

                <FormTextField
                  disabled={!isEditAccessed}
                  inputProps={{
                    label: 'Last Name',
                    variant: 'outlined',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'lastName', validate: required }}
                />

                <FormPhoneField
                  inputProps={{
                    label: 'Primary Phone Number',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'userPreferences.phoneNumber' }}
                />

                <FormPhoneField
                  inputProps={{
                    label: 'Secondary Phone Number',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{
                    name: 'userPreferences.secondaryPhoneNumber',
                    validate: undefined,
                  }}
                />
              </Box>
              <Box css={rowLayoutCss(isMobile)}>
                <FormSelect
                  selectProps={{
                    children: FIELDS_CONFIG_USER.gender.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    label: 'Gender',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'gender' }}
                />
                <FormSelect
                  selectProps={{
                    children: FIELDS_CONFIG_USER.affiliation.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    label: 'Affiliation',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'affiliation', validate: required }}
                />

                {values.affiliation === 'alumni' && (
                  <FormSelect
                    selectProps={{
                      children: FIELDS_CONFIG_USER.graduatingYear.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )),
                      variant: 'outlined',
                      label: 'Graduation Year',
                      disabled: !isEditAccessed,
                    }}
                    fieldProps={{ name: 'graduatingYear', validate: required }}
                  />
                )}

                {values.affiliation === 'other' && (
                  <FormTextField
                    inputProps={{
                      label: 'Affiliation Description',
                      variant: 'outlined',
                      disabled: !isEditAccessed,
                    }}
                    fieldProps={{ name: 'affiliationDescription', validate: required }}
                  />
                )}
              </Box>

              <Box css={rowLayoutCss(isMobile)}>
                <div>
                  <Typography css={{ alignSelf: 'center', fontWeight: 'bold' }}>
                    Parent (current student or alumni)?
                  </Typography>
                </div>
                <FormControl>
                  <RadioGroup
                    defaultValue={null}
                    row
                    onChange={onChangeRadio}
                    value={isParentsAvailable}
                  >
                    <FormControlLabel
                      control={<Radio color="secondary" value="true" />}
                      label="Yes"
                      disabled={!isEditAccessed}
                    />
                    <FormControlLabel
                      control={<Radio color="secondary" value="false" />}
                      label="No"
                      disabled={!isEditAccessed}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {isParentsAvailable && (
                <FieldArray
                  name="parents"
                  render={arrayHelpers => {
                    return (
                      <Fragment>
                        {values.parents?.map((parent, index) => (
                          <Box css={rowLayoutCss(isMobile)} key={index}>
                            <FormTextField
                              disabled={!isEditAccessed}
                              fieldProps={{
                                name: `parents.${index}.fullName`,
                              }}
                              inputProps={{
                                label: 'Full Name',
                                variant: 'outlined',
                              }}
                            />
                            <Box
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <FormSelect
                                selectProps={{
                                  children: FIELDS_CONFIG_USER.graduatingYear.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  )),
                                  variant: 'outlined',
                                  label: 'Class Year',
                                  disabled: !isEditAccessed,
                                }}
                                fieldProps={{
                                  name: `parents.${index}.classYear`,
                                }}
                              />
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                                size="small"
                                color="error"
                                style={{
                                  height: 'min-content',
                                }}
                              >
                                <Icon name="Remove" />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          variant="text"
                          disabled={!isEditAccessed}
                          color="secondary"
                          onClick={() => arrayHelpers.push('')}
                          startIcon={<Icon name="Add" />}
                          style={{
                            width: '130px',
                            textTransform: 'none',
                          }}
                        >
                          Add another
                        </Button>
                      </Fragment>
                    );
                  }}
                />
              )}

              <Box css={rowLayoutCss(isMobile)}>
                <FormKeyboardDatePicker
                  datePickerProps={{
                    allowKeyboardControl: true,
                    disableFuture: true,
                    inputVariant: 'outlined',
                    label: 'Date of birth',
                    variant: 'inline',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'birthDate' }}
                />
                <FormTextField
                  disabled={!isEditAccessed}
                  inputProps={{
                    label: 'ZIP Code',
                    variant: 'outlined',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'userPreferences.address.zip' }}
                />
                <FormTextField
                  disabled={!isEditAccessed}
                  inputProps={{
                    label: 'City',
                    variant: 'outlined',
                    disabled: !isEditAccessed,
                  }}
                  fieldProps={{ name: 'userPreferences.address.city' }}
                />

                <AutocompleteField
                  label="State"
                  name="userPreferences.address.state"
                  options={US_STATES_OPTIONS}
                  disabled={!isEditAccessed}
                />
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Button
                  css={commonButtonsCss}
                  disabled={isSubmitting}
                  size="medium"
                  variant="outlined"
                  onClick={() => goBack()}
                >
                  Go Back
                </Button>

                <Box css={buttonsContainerCss}>
                  <Button
                    css={cancelButtonCss}
                    disabled={isSubmitting || !form.dirty}
                    size="medium"
                    variant="outlined"
                    type="reset"
                    onClick={() => resetForm()}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={isSubmitting || !form.dirty || !isEditAccessed || loadingAddons}
                    css={submitButtonCss}
                    color="primary"
                    disableElevation
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Save changes
                  </Button>
                </Box>
              </Box>
            </Fragment>
          )}
        </Form>
      </Box>
    </RouteLayout>
  );
}
