const REGEX = /(?<type>.+):(?<id>.*)/i;
/**
 * Extract from the given `id` mention from `react-mentions` the type.
 * @example extractMentionType("user:ABCD") === MentionType.User
 * @returns Extracted type or `null` in case of non-matched.
 */
export function extractMentionType(text) {
    const matches = text.match(REGEX);
    if (!matches || !matches.groups) {
        return null;
    }
    return matches.groups.type;
}
/**
 * Extract from the given `id` mention from `react-mentions` the identifier.
 * @example extractMentionIdentifier("user:ABCD") === "ABCD"
 * @returns Extracted identifier or `null` in case of non-matched.
 */
export function extractMentionIdentifier(text) {
    const matches = text.match(REGEX);
    if (!matches || !matches.groups) {
        return null;
    }
    return matches.groups.id;
}
