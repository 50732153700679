import { ORGANIZATION_STATUS } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { OrganizationFilter } from 'shared/graphql';

import { createUserFilter } from './createUserFilter';

interface Options {
  initial?: OrganizationFilter;

  search?: string;
  zip?: string;

  /**
   * Filter the organizations by "status".
   * @default true
   */
  onlyActive?: boolean;
}

export function createOrganizationFilter(options?: Options) {
  const filter = createFilterBuilder<OrganizationFilter>(options?.initial);

  const onlyActive = options?.onlyActive ?? true;

  if (onlyActive) {
    filter.and({
      status: { equals: ORGANIZATION_STATUS.active },
    });
  }

  if (options?.search) {
    const userFilter = createUserFilter({ search: options.search, zip: options.zip });
    const searchFilter = createFilterBuilder<OrganizationFilter>();

    searchFilter
      .or({ name: { contains: options.search } })
      .or({ description: { contains: options.search } })
      .or({ industry: { contains: options.search } })
      .or({ owner: userFilter })
      .or({ managers: { some: { user: userFilter } } });

    filter.and(searchFilter);
  }

  if (options?.zip) {
    filter.and({
      location: {
        some: {
          address: { some: { zip: { equals: options.zip } } },
        },
      },
    });
  }

  return filter.build();
}
