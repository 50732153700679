import { SearchProvider } from 'shared/features/search';
import { SELECT_OPTION_ALL, USER_SORT_BY_NAME_INFO } from 'shared/constants';

import { ExplorerNation } from '../components/Explorer/ExplorerNation';
import { UsersFilter } from '../types';

export function ExplorerNationPage() {
  const filter: UsersFilter = {
    currentIndustry: SELECT_OPTION_ALL,
    graduatingYear: SELECT_OPTION_ALL,
    jobSeekersOnly: false,
  };

  return (
    <SearchProvider<UsersFilter>
      defaultFilterOption={filter}
      defaultSortOption={USER_SORT_BY_NAME_INFO.options[0].value}
    >
      <ExplorerNation />
    </SearchProvider>
  );
}
